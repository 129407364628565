import React from "react";
import states from "./states.json";
import canadaStates from "./canadaStates.json";
import PropTypes from "prop-types";

class StateDropdownComponent extends React.Component {
    render() {
        const { id, className, selectedState, isCanada, disabled } = this.props;
        const options = (isCanada) ? canadaStates : states;
        return (
            <select id={id} name={id} className={className} onChange={this.props.onChange} value={selectedState} disabled={disabled }>
                {options.map((item, idx) => (
                    <option key={idx} value={item.abbreviation} >
                        {item.name}
                    </option>
                ))}

            </select>
        );
    }
}

StateDropdownComponent.propTypes = {
    id: PropTypes.string,
    onChange: PropTypes.func,
    className: PropTypes.string,
    selectedState: PropTypes.string
};

export default StateDropdownComponent;