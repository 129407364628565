import {
    StringFilterComponent as StringFilter,
} from "../../../common/column-filters";
import ExpandableComponent from "../../../common/formatters/Expandable";
import { DisplayDateComponent } from "../../../common/formatters";

export const InvoiceColumns = [
    {
        key: "invoiceId",
        name: "Invoice ID",
        sortable: true,
        hideable: false,
        visible: true,
        filterable: true,
        filterRenderer: StringFilter,
        isAdvancedField: false,
        initWidth: 100,
        resizable: true,
        formatter: ExpandableComponent,
    },
    {
        key: "invoiceDate",
        name: "Date",
        sortable: true,
        hideable: true,
        visible: true,
        filterable: true,
        isDefaultSorter: true,
        defaultSortDirection: "DESC",
        formatter: DisplayDateComponent,
        filterRenderer: StringFilter,
        initWidth: 150,
        resizable: true,
    },
    {
        key: "status",
        name: "Status",
        sortable: true,
        hideable: true,
        visible: true,
        filterable: true,
        filterRenderer: StringFilter,
        isAdvancedField: false,
        initWidth: 100,
        resizable: true,
    },
    {
        key: "type",
        name: "Type",
        sortable: true,
        hideable: true,
        visible: true,
        filterable: true,
        filterRenderer: StringFilter,
        isAdvancedField: false,
        initWidth: 130,
        resizable: true,
    },
    {
        key: "dueDate",
        name: "Due Date",
        sortable: true,
        hideable: true,
        visible: true,
        filterable: true,
        formatter: DisplayDateComponent,
        filterRenderer: StringFilter,
        initWidth: 150,
        resizable: true,
    },
    {
        key: "paidDate",
        name: "Paid Date",
        sortable: true,
        hideable: true,
        visible: true,
        filterable: true,
        formatter: DisplayDateComponent,
        filterRenderer: StringFilter,
        initWidth: 150,
        resizable: true,
    },
    {
        key: "total",
        name: "Total",
        sortable: true,
        hideable: true,
        visible: true,
        filterable: true,
        filterRenderer: StringFilter,
        isAdvancedField: false,
        initWidth: 100,
        resizable: true,
    },
    {
        key: "isHidden",
        name: "",
        sortable: false,
        hideable: false,
        visible: true,
        filterable: false,
        isAdvancedField: false,
        initWidth: 100,
        resizable: true,
        getRowMetaData: row => row,
    },
];
