import React, { Component, Fragment } from "react";
import { appService } from "../../services";
import { Auth } from 'aws-amplify';
import { get } from 'lodash'
import { withCancelable } from "../../common/components/cancelable";
import { Notification } from '../../common/components/notifications';
import PropTypes from 'prop-types';
import KeyCodes from "../../common/components/react-data-grid/src/KeyCodes";
import { persistState, restoreState } from "../../helpers/persist-state";
import { localStorageKeys } from "./stepupConstants";
import moment from "moment";

class ConfirmMFAStepUp extends Component{
    constructor(props){
        super(props)
        this.notificationRef = React.createRef();
        this.state = {
            mfaType: null,
            accessToken: null,
            destination: null,
            errorMessage: null,
            challengeAnswer: null,
        }
    }

    async componentDidMount() {
        this.props.showLoader(true);
        if(!this.props.mfaType || this.props.mfaType === 'SMS_STEP_UP'){
            const user = await Auth.currentAuthenticatedUser();
            let mfaType = null;
            if(this.props.mfaType === 'SMS_STEP_UP'){
                const destination = get(user, 'attributes.phone_number', null);
                const accessToken = get(user, 'signInUserSession.accessToken.jwtToken', null);
                mfaType = 'SMS_STEP_UP'
                this.setState({destination , accessToken}, () => this.props.invokeSendCodeOnAppear && this.sendVerificationCodeOnAppear())
            }else{
                const { preferredMFA } = user;
                if(preferredMFA === 'SMS_MFA'){
                    const destination = get(user, 'attributes.phone_number', null);
                    const accessToken = get(user, 'signInUserSession.accessToken.jwtToken', null);
                    mfaType = 'SMS_STEP_UP'
                    this.setState({destination , accessToken}, () => this.props.invokeSendCodeOnAppear && this.sendVerificationCodeOnAppear())
                }else if(preferredMFA === 'SOFTWARE_TOKEN_MFA'){
                    mfaType = 'SOFTWARE_TOKEN_STEP_UP'
                }
            }
            this.setState({mfaType}, this.props.showLoader())
        }else{
            this.setState({mfaType: this.props.mfaType}, this.props.showLoader())
        }
    }

    sendVerificationCodeOnAppear = () => {
        const { componentKey } = this.props;
        const mfaTime = restoreState(localStorageKeys.SMS_MFA_CODE_SENT_TIME(componentKey))
        const momentMfaTime = moment(mfaTime, true)
        if(!momentMfaTime.isValid()){
            this.sendVerificationCode(false);
            return;
        }
        
        const differenceInSeconds = moment().diff(momentMfaTime, 'seconds')
        if(differenceInSeconds >= 600){
            this.sendVerificationCode(false);
        }
    }
    
    handleConfirmMfa = async (e) => {
        this.props.showLoader(true);
        const { mfaType, challengeAnswer } = this.state
        try {
            await appService.respondToChallenge(mfaType, challengeAnswer, this.props.componentKey);
            this.props.markAsVerified()
        } catch (error) {
            this.setState({ errorMessage: error, challengeAnswer: "" })
        } finally {
            this.props.showLoader(false);
        }
    }

    handleChallengeAnswerChange = (e) => {
        this.setState({ challengeAnswer: e.target.value})
    }


    sendVerificationCode = async (showNotification = true) => {
		const { showLoader, makePendingRequest, componentKey } = this.props;
		const { destination, accessToken } = this.state;

		try {
			showLoader(true);
            await makePendingRequest(
				appService.getUserAttributeVerificationCode(accessToken, 'phone_number'),
				"send"
			);
            persistState(localStorageKeys.SMS_MFA_CODE_SENT_TIME(componentKey), moment().toISOString())
            showNotification && this.notificationRef.current.addNotification({
                success: true,
                message: `Confirmation code successfully sent to ${destination}`
            });

		} catch (e) {
            showNotification && this.notificationRef.current.addNotification({
                success: false,
                message: `There was an error sending the confirmation code. Try again later or contact support.`
            });
		}finally{
            showLoader(false);
        }
	};

    onKeyDownHandler = (event, onClickHandler) => {
        if (event.keyCode === KeyCodes.Enter)  onClickHandler();
    }

    render(){
        const {isLoading} = this.props;
        const { challengeAnswer, errorMessage, mfaType, destination } = this.state;
        const forbidden = !isLoading && (mfaType && mfaType === 'Forbidden')
        const otherError = !isLoading && (mfaType && mfaType.indexOf('One or more errors occurred.') > -1)
        const invalidMfaType = !isLoading && (!forbidden && !otherError && mfaType !== 'SMS_STEP_UP' && mfaType !== 'SOFTWARE_TOKEN_STEP_UP' && mfaType !== 'MAYBE_SOFTWARE_TOKEN_STEP_UP')
        return  (
                <Fragment>
                    <Notification ref={this.notificationRef} />
                    <fieldset disabled={isLoading} className="checkup">
                        <div className="checkup__main">
                            {mfaType && mfaType === "SMS_STEP_UP" ? (
                                    destination && <label className="checkup__main__title">A confirmation code has been sent to you by SMS ({("*".repeat(destination.length - 4) + destination.substring(destination.length - 4))}). Enter the code to continue.</label>
                                ) : (
                                    <label className="checkup__main__title">Enter the confirmation code from your Multi-Factor Authentication (MFA) app.</label>
                                )
                            }

                            <div className="checkup__main__field spc--bottom--sml">
                                <input
                                    name="challengeAnswer"
                                    type="text"
                                    className="input input--med w--200p"
                                    placeholder="000000"
                                    value={challengeAnswer}
                                    onChange={this.handleChallengeAnswerChange}
                                    onKeyDown={e => this.onKeyDownHandler(e, this.handleConfirmMfa)}
                                />
                                <button disabled={this.props.isLoading || invalidMfaType || otherError || forbidden} onClick={this.handleConfirmMfa}  className="btn btn--primary btn--med">Confirm</button>
                                {mfaType === 'SMS_STEP_UP' && <button onClick={this.sendVerificationCode}  className="btn btn--primary btn--med">Resend Code</button>}
                        </div>
                                {forbidden && <div className="display--ib note note--warning">You do not have rights to view residuals.</div>}
                                {errorMessage && <div className="display--ib note note--warning">{errorMessage}</div>}
                                {invalidMfaType && <div className="display--ib note note--warning">Something went wrong when trying to access your MFA settings. Refresh the page and contact support if the problem persists.</div>}
                                {otherError && <div className="display--ib note note--warning">An error occurred. Refresh the page and contact support if the problem persists.</div>}
                        </div>
                    </fieldset>
                </Fragment>
                )
    }
}

ConfirmMFAStepUp.propTypes = {
	mfaType: PropTypes.string,
	showLoader: PropTypes.func,
	componentKey: PropTypes.string,
	makePendingRequest: PropTypes.func,
	invokeSendCodeOnAppear: PropTypes.bool,
};

export default withCancelable(ConfirmMFAStepUp);