import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { each } from 'lodash';
import { DisplayDateComponent } from "../../formatters";
import { CurrencyMap } from '../../../common/utilities';
/*
import {
    DisplayDateComponent,
    CreditCardComponent,
    CurrencyComponent,
    StatusFraudComponent,
} from './../../../Common/components/transactions/formatter';
*/
import Logo from './logo';


class PrintGridData extends Component {

    get currencyCode() {
        let currencyCode = CurrencyMap.resolveCurrency(this.currency);
        if (!currencyCode) {
            currencyCode = '$';
        }
        return currencyCode;
    }

    renderTable = () => {
        const { type, data } = this.props;

        let className = 'fraudprint__table';
        if (type.startsWith('giftCard') || type === 'schedulePreview') {
            className = 'giftprint__table';
        }

        return (
            <div className={className}>
                <table className={className}>
                    <thead>
                        <tr>
                            {this.renderTableHeader()}
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderBody()}
                    </tbody>
                </table>
                <div className="fraudprint__footer">
                    <Logo />
                </div>
            </div>
        );
    };

    renderTableHeader = () => {
        const { columns } = this.props;

        return columns.map(x => {
            return (
                <th key={x.key}>{x.name.replace(/\u00AD/g, '')}</th>
            );
        });
    };

    renderBody = () => {
        const { data, type } = this.props;
        //console.log('in renderBody, data is ' + data);
        let renderedRows = [];
        each(data, (row, idx) => {
            let key = row.xRefNum + row.xEnteredDate;
            if (type === 'leads' || type === 'residuals' || type === 'EApps') {
                key = row.uniqueId;
            } else if (type === 'merchants') {
                key = row.appId;
            } else if (type === 'tickets') {
                key = row.ticketNumber;
            }
            else if (type === 'equipment') {
                key = row.id;
            }

            renderedRows.push(<tr key={key}>{this.renderRow(row)}</tr>);
        });
        //console.log('in renderBody, renderedRows is ' + renderedRows);
        return renderedRows;
    };

    renderRow = (row) => {
        const { columns } = this.props;
        //console.log('columns ' + columns);
        return columns.map(x => {
            return (
                <td key={x.key}>{this.renderCell(row, x)}</td>
            );
        });
    };

    renderCell = (row, col) => {
        const { type } = this.props;
        
        if (col.key === 'dateSigned' || col.key === 'dateApproved' || col.key === 'openDate' ||
            col.key === 'modificationDate' || col.key === 'invoiceDate' || col.key === 'dueDate' ||
            col.key === 'paidDate'
        ) {
            let dateFormats;
            //if (type === 'schedulePreview') {
            //    dateFormats = { inputFormat: process.env.REACT_APP_DATE_FORMAT, outputFormat: process.env.REACT_APP_DISPLAY_DATE_FORMAT };
            //}
            //if (type === 'schedules') {
            //    dateFormats = { inputFormat: process.env.REACT_APP_SHORT_DATE_TIME_FORMAT, outputFormat: process.env.REACT_APP_SHORT_DATE_TIME_FORMAT };
            //}
            //const dependentValues = dateFormats ? merge(dateFormats, row) : row;
            return (<DisplayDateComponent value={row[col.key]}  />);
        }
        /*
        if (col.key === 'xStartDate') {
            return (
                apiToDisplay(row[col.key])
            );
        }

        if (col.key === 'xAmount' || col.key === 'xRemainingBalance' || col.key === 'totalAmount') {
            return (<CurrencyComponent value={row[col.key]} dependentValues={row} />);
        }

        if (col.key === 'xMaskedCardNumber') {
            return (<CreditCardComponent value={row[col.key]} dependentValues={row} />);
        }

        // Fraud specific
        if (type === 'fraud' && col.key === 'xStatus') {
            return (<StatusFraudComponent value={row[col.key]} />);
        }

        if (col.key === 'xIntervalType') {
            return (<FrequencyComponent value={row[col.key]} dependentValues={row} />);
        }*/
        //console.log('key ' + col.key + ' val ' + row[col.key]);
        
        return row[col.key];
    };


    render() {
        const { data, type } = this.props;

        // Report title
        let title = '';
        if (type === 'leads') {
            title = 'Leads List';
        } else if (type === 'merchants') {
            title = 'Merchant List';
        } else if (type === 'tickets') {
            title = 'Tickets'
        } else if (type === 'invoice') {
            title = 'Invoice List';
        } else if (type === 'EApps') {
            title = 'EApps List';
        }
        
        return (
            <div className="fraudprint">
                <div className="fraudprint__header">
                    <div className="row">
                        <div className="col col-sml-3">
                            <p className="type--med type--wgt--medium">{title}</p>
                        </div>
                        <div className="col col-sml-9 type--xsml type--right type--color--grey4">
                            <span className="type--nano">Company name:</span> n/a
                        </div>
                    </div>
                </div>
                <div>
                {data.length > 0 ? (
                    this.renderTable()
                ) : (
                    <div>No data to show</div>
                )}
                </div>
            </div>
        );
    }
}

PrintGridData.propTypes = {
    data: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    type: PropTypes.oneOf(['merchants','leads','tickets','equipment']).isRequired
};

export default PrintGridData;