import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { map, find, sumBy, toNumber, get } from 'lodash';

import { formatCurrency } from '../../helpers/currency-formatter';
import { NumericFormat as NumberFormat } from 'react-number-format';

class HardwarePurchasePlanComponent extends Component {

    get isBillAgentPaymentSchedule() {
        return this.props.paymentSchedule === "BillAgent";
    }

    isBillAgentAndOneTimeFee = (fee) => get(fee, 'feeType') === 'OneTimeFee' && this.isBillAgentPaymentSchedule;

    calculateAccessoriesTotalPrice(){
        return sumBy(this.props.accessories, ({ isSelected, quantity, fees }) => {
            if (isSelected && fees) {
                const fee = find(fees, { feeType: 'OneTimeFee' });
                return (this.isBillAgentPaymentSchedule ? fee.agentCost : (fee.merchantPrice || fee.retailPrice)) * quantity;
            }
            return 0;
        }) || 0;
    }    

    calculateTotalPrice(merchantFee, merchantPrice){
        let agentCost = merchantFee && Object.keys(merchantFee).includes('agentCost') ? toNumber(merchantFee.agentCost) : toNumber(merchantFee.retailPrice);
        let total = this.isBillAgentAndOneTimeFee(merchantFee) ? agentCost : merchantPrice || 0 
        return total;
    }

    render() {
        const { purchaseType, plan, quantity, openAccessoriesPopup, displayAddonsButton } = this.props;
        const accessoriesTotalPrice = this.calculateAccessoriesTotalPrice();
        let totalMerchantPrice = 0;
        const tooltip = this.isBillAgentPaymentSchedule ? 'Merchant Cost is not editable when Payment Schedule is Bill Agent.' : null;

        return (
        <Fragment>
                {
                    plan.fees.filter((fee, i) => fee.purchaseTypes.includes(purchaseType)).map((fee, idx) => {                        
                        if (Object.keys(fee.dependencySettings || {}).length > 0) {
                            let renderDependentFee = false;
                            // check if have option this depends on
                            if (this.props.options) {
                                let dependentOption = Object.keys(this.props.options).find(o => Object.keys(fee.dependencySettings).includes(o));
                                if (dependentOption && fee.dependencySettings[dependentOption].includes(this.props.options[dependentOption])) {
                                    renderDependentFee = true;
                                }
                            }
                            if (!renderDependentFee)
                                return;
                        }

                        let merchantFee = this.props.merchantFees.find((f) => f.feeId == fee.feeId);
                        let merchantPrice = merchantFee && Object.keys(merchantFee).includes('merchantPrice') ? toNumber(merchantFee.merchantPrice) : toNumber(fee.retailPrice);
                        const minFee = get(fee, 'minFee')
                        const maxFee = get(fee, 'maxFee')
                        const isMerchantPriceDisabled = (minFee > 0 && maxFee > 0) && minFee === maxFee;
                        
                        totalMerchantPrice += this.calculateTotalPrice(merchantFee, merchantPrice);

                        return (
                            <Fragment key={idx}>
                                <label className="checkout__list__label">{fee.feeName}</label>
                                <div className="checkout__list__item">
                                    {/* <label className="checkout__list__label">{fee.feeName}</label> */}
                                    <div className="checkout__list__content">
                                        <div className="checkout__list__content__label">Agent Cost</div>
                                        <div className="checkout__list__value">{formatCurrency(fee.agentCost)}</div>
                                    </div>
                                </div>
                                <div className="checkout__list__item">
                                    {/* <label className="checkout__list__label">{fee.feeName}</label> */}
                                    <div className="checkout__list__content">
                                        <div className="checkout__list__content__label">Merchant Cost</div>
                                        <div className="checkout__list__value">
                                            <div className={`${isMerchantPriceDisabled ? "flex--primary flex--nowrap" : ""}`}>
                                                <div
                                                    className="datatooltip--250 datatooltip--left"
                                                    data-tooltip={this.isBillAgentAndOneTimeFee(fee) ? tooltip : null}
                                                >
                                                    <NumberFormat
                                                        id={`fee_${fee.feeId}`}
                                                        className="input input--med input--currency input--currency--sml type--right disabled"
                                                        style={this.isBillAgentAndOneTimeFee(fee) ? { cursor: 'not-allowed', backgroundColor: '#f3f6f9' } : {}}
                                                        value={this.isBillAgentAndOneTimeFee(fee) ? 0 : merchantPrice}
                                                        disabled={this.isBillAgentAndOneTimeFee(fee) || isMerchantPriceDisabled}
                                                        name={"fee_" + idx}
                                                        placeholder="Merchant Cost"
                                                        thousandSeparator={true}
                                                        decimalScale={2}
                                                        fixedDecimalScale={false}
                                                        onValueChange={this.props.onFeeChange(plan.planId, fee.feeId)}
                                                    />
                                                </div>
                                                {isMerchantPriceDisabled && !this.isBillAgentAndOneTimeFee(fee) && <i className="icon icon--tiny icon--info datatooltip--100 spc--left--tny flex--shrink--0" data-tooltip={`Merchant cost must equal ${minFee}`}/>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {(totalMerchantPrice < fee.agentCost) &&
                                    <div className="note note--default">
                                        Note: Merchant Cost is below Agent Cost
                                        <p/>Agent will be billed for Remainder Amount: {formatCurrency(fee.agentCost - totalMerchantPrice)}
                                    </div>
                                }
                            </Fragment>
                        )
                    })}
                    {this.renderAccessioriesInfo()}
                    {displayAddonsButton && <div className="checkout__list__item"><button className="btn btn--med checkout__list__addon__button" onClick={openAccessoriesPopup}>+ Accessories</button></div>}
                <div className="checkout__list__item total-price">
                    <div className="checkout__list__label">Total Price</div>
                    <div className="checkout__list__value is-total">
                        {formatCurrency((quantity * totalMerchantPrice) + accessoriesTotalPrice)}
                    </div>
                </div>            
            </Fragment>
        );
    }

    renderAccessioriesInfo(){
        const { accessories } = this.props;

        return (
            <Fragment>
                {map(accessories, ({ equipmentId, name, quantity, isSelected, fees }) => {
                    const fee = find(fees, { feeType: 'OneTimeFee' });

                    return (isSelected && quantity > 0) && (
                        <div className="checkout__list__addon" key={`${name}.${equipmentId}`}>
                            <div className="checkout__list__label spc--right--sml">
                                {name}
                                <span className="spc--left--tny">
                                    ({quantity})
                                </span>
                            </div>
                            <span className="align--h--right">
                                <i
                                    onClick={() => this.props.toggleAccessory(equipmentId)}
                                    className="icon icon--tiny icon--middle icon--delete cursor--pointer spc--left--tny"
                                ></i>
                            </span>
                            <div className="checkout__list__addon__value">{formatCurrency(this.isBillAgentPaymentSchedule ? fee.agentCost : (fee.merchantPrice || fee.retailPrice))}</div>
                        </div>
                    );
                })}
            </Fragment>
        )
    }

}

HardwarePurchasePlanComponent.propTypes = {
    onFeeChange: PropTypes.func.isRequired,
    merchantFees: PropTypes.array,
    plan: PropTypes.object.isRequired,
    purchaseType: PropTypes.string.isRequired,
    accessories: PropTypes.array,
    toggleAccessory: PropTypes.func.isRequired,
    displayAddonsButton: PropTypes.bool,
    openAccessoriesPopup: PropTypes.func,
    outOfStock: PropTypes.bool,
}

export default HardwarePurchasePlanComponent;