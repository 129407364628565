import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ActiveSelectionComponent extends Component {

	filterRemoved = e => {
		this.props.onFilterRemoved({
			id: 'active',
			emptyValue: false,
		});
    };

    filterText = () => {
        const { active, inactive } = this.props.filter.values;

        if (active) {
            return 'Active';
        } else if (inactive) {
            return 'Inactive';
        } else {
            return 'Unknown';
        }
    };

	render() {
		return (
			<div className="filter__tag">
				<label className="filter__tag__label">Active: </label>
                {this.filterText()}
				<button onClick={this.filterRemoved} className="btn btn--reset filter__tag__clear"></button>
			</div>
		);
	}
}

ActiveSelectionComponent.propTypes = {
	filter: PropTypes.object.isRequired,
	onFilterRemoved: PropTypes.func.isRequired
};

export default ActiveSelectionComponent;