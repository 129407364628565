import React, { Fragment, Component, createRef } from 'react';
import PropTypes from 'prop-types';
import {
	set,
	isEmpty,
	includes,
	replace,
	isArray,
	some,
	transform,
	map,
	filter,
	get,
	noop,
	cloneDeep,
	each,
	repeat,
	isEqual,
	isFunction,
	find,
} from 'lodash';
import { appService } from '../../services/appService';
import { AddressComponent } from '../../common/components/address';
import SingleDatePicker from '../../common/filters/date-picker/single-date-picker';
import { OwnershipDropdownComponent } from '../../common/components/ownership-dropdown';
import { FileDropzoneComponent } from '../../common/components/file-dropzone';
import { canPreviewFile } from '../../common/utilities/canPreviewFile';
import { handleFileDownload } from '../../common/utilities/commonFileHandlingMethods';
import { modalNames } from '../../common/components/modal-wrapper';
import { NumericFormat } from 'react-number-format';
import KeyCodes from '../../common/components/react-data-grid/src/KeyCodes';
import { fileTypes } from '../../common/components/file-dropzone/fileTypeConstants';
import { Toggle, ToggleContainer } from '../../common/components/toggle';

const required = (
	<span data-tooltip="Required" className="form__group__required">
		*
	</span>
);
class AchBaseClass extends Component {
	constructor(props, requiredFields, requiredDocs, conditionalRequiredFields = []) {
		super(props);

		this.allowedFileTypes = [fileTypes.jpeg, fileTypes.png, fileTypes.bmp, fileTypes.txt, fileTypes.pdf];
		this.requiredFields = requiredFields;
		this.conditionalRequiredFields = conditionalRequiredFields;
		this.requiredDocs = requiredDocs;
		this.state = this.initialState;
		this.handleFileDownload = handleFileDownload.bind(this);
		this.maskedFieldRefs = createRef();
		this.maskedFieldRefs.current = {};
	}

	get achWorksheetFieldNames() {
		return {
			agentName: 'Agent Name',
			dba: 'Business DBA name',
			corporateName: 'Business Legal Name',
			goodsOrServicesDescription: 'Describe the business',
			taxID: 'EIN/Federal Tax ID',
			businessStartDate: 'Year business formed',
			businessInformation_ownershipType: 'Business type',
			businessInformation_website: 'Business website',
			businessInformation_businessAddress_streetAddress: 'Business address',
			businessInformation_businessAddress_city: 'Business Address - City',
			businessInformation_businessAddress_state: 'Business Address - State',
			businessInformation_businessAddress_zip: 'Business Address - ZIP',
			businessInformation_businessPhone: 'Business Phone',
			corporateAddress_streetAddress: 'Mailing Address',
			corporateAddress_city: 'Mailing Address - City',
			corporateAddress_state: 'Mailing Address - State',
			corporateAddress_zip: 'Mailing Address - ZIP',
			businessContactName: 'Primary Contact - Name',
			contactPhoneNumber: 'Primary Contact - Phone',
			businessInformation_businessEmail: 'Primary Contact - Email',
			signerInformation_firstName: 'Signer - Name',
			signerInformation_ownershipPercentage: 'Signer - % of Ownership',
			signerInformation_phoneNumber: 'Signer - Home #',
			signerInformation_socialSecurityNumber: 'Signer - SS#',
			signerInformation_address_streetAddress: 'Signer - Home Address',
			signerInformation_address_city: 'Signer - City',
			signerInformation_address_state: 'Signer - State',
			signerInformation_address_zip: 'Signer - ZIP',
			signerInformation_email: 'Signer - Email Address',
			signerInformation_title: 'Primary Contact - Title',
			bankAccountInformation_bankName: 'Name on bank account',
			bankAccountInformation_routingNumber: 'ABA Number',
			bankAccountInformation_accountNumber: 'DDA Number',
			generalTransactionInfo_maxSingle: 'Largest Payment Expected',
			generalTransactionInfo_maxDaily: 'How many payments per day?',
			generalTransactionInfo_maxDailyAmount: 'Largest daily payment total',
			generalTransactionInfo_maxPeriod: 'Payments per month',
			generalTransactionInfo_maxPeriodAmount: 'Largest monthly total',
			generalTransactionInfo_averageTransactionAmount: 'Average transaction amount',
		};
	}

	get initialState() {
		return {
			isNewError: false,
			errorMessage: null,
			errorList: [],
			errorListPaths: [],
			enableSecondaryBankAccountInfo: false,
			enableAlternativeBankAccountInfo: false,
			localFiles: {},
			sameAsBusiness: false,
			modal: {
				name: modalNames.none,
				data: null,
			},
			fields: {
				files: [],
				appId: 0,
				mid: '',
				leadId: '',
				agentId: 0,
				businessContactName: '',
				ownerName: '',
				dba: '',
				processorName: '',
				contactPhoneNumber: '',
				businessStartDate: '',
				corporateName: '',
				goodsOrServicesDescription: '',
				taxID: '',
				statusTitle: '',
				isNewAccount: false,
				isAdditionalLocation: false,
				accountNotes: '',
				submittedBy: 0,
				isPciCompliant: false,
				telemarketerList: [],
				telemarketerEmail: '',
				telemarketerId: 0,
				hasExistingFidelityAccount: null,
				existingAccountDba: '',
				generalTransactionInfo: {
					maxSingle: null,
					maxDaily: null,
					maxDailyAmount: null,
					maxPeriod: null,
					maxPeriodAmount: null,
					averageTransactionAmount: null,
				},
				bankAccountInformation: {
					bankName: '',
					routingNumber: '',
					accountNumber: '',
					secondaryBankName: '',
					secondaryRoutingNumber: '',
					secondaryAccountNumber: '',
					alternateBankName: '',
					alternateRoutingNumber: '',
					alternateAccountNumber: '',
				},
				signerInformation: {
					ownershipPercentage: null,
					socialSecurityNumber: null,
					dateOfBirth: '',
					firstName: null,
					lastName: null,
					email: null,
					title: null,
					phoneNumber: null,
					cellPhoneNumber: null,
					address: {
						streetAddress: '',
						city: '',
						state: '',
						zip: '',
					},
				},
				businessInformation: {
					businessAddress: {
						streetAddress: '',
						city: '',
						state: '',
						zip: '',
						country: '',
					},
					ownershipType: '',
					businessEmail: '',
					businessPhone: '',
					businessFax: '',
					website: '',
				},
				corporateAddress: {
					streetAddress: '',
					city: '',
					state: '',
					zip: '',
					country: '',
				},
			},
		};
	}

	createFormSnapshotObj = () => {
		const { fields, localFiles } = this.state;
		const formSnapshot = {};
		formSnapshot.fields = cloneDeep(fields);
		formSnapshot.localFiles = cloneDeep(localFiles);
		return formSnapshot;
	};

	createFormSnapshot = () => {
		const formSnapshot = this.createFormSnapshotObj();
		this.formSnapshot = JSON.stringify(formSnapshot);
	};

	get isDirty() {
		const current = this.createFormSnapshotObj();
		return this.formSnapshot !== JSON.stringify(current);
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			this.conditionalRequiredFields &&
			(!isEqual(prevState.fields, this.state.fields) ||
				!isEqual(prevState.enableSecondaryBankAccountInfo, this.state.enableSecondaryBankAccountInfo) ||
				!isEqual(prevState.enableAlternativeBankAccountInfo, this.state.enableAlternativeBankAccountInfo))
		) {
			const requiredFieldsCopy = cloneDeep(this.requiredFields);
			each(this.conditionalRequiredFields, crf => {
				if (isFunction(crf.condition) && crf.condition(this.state)) {
					each(crf.fields, field => {
						if (!this.requiredFields.includes(field)) {
							this.requiredFields.push(field);
						}
					});
				} else if (isFunction(crf.condition)) {
					each(crf.fields, field => {
						this.requiredFields = this.requiredFields.filter(item => item !== field);
					});
				}
			});
			if (!isEqual(requiredFieldsCopy, this.requiredFields)) {
				if (this.state.showValidation && this.mpaSchema) {
					const { fields, enableSecondaryBankAccountInfo, enableAlternativeBankAccountInfo } = this.state;
					const errorList = this.mpaSchema.validate(
						Object.assign({}, { ...fields, enableSecondaryBankAccountInfo, enableAlternativeBankAccountInfo })
					);
					const errorListPaths = errorList.map(error => error.path);
					this.setState({ errorList, errorListPaths });
				} else {
					this.forceUpdate();
				}
			}
		}
	}

	validateDocuments = () => {
		const {
			fields: { files },
			localFiles,
		} = this.state;
		return filter(
			this.requiredDocs,
			fileTag => isEmpty(localFiles[fileTag]) && !some(files, file => get(file, 'fileTag') === fileTag)
		);
	};

	handleError = err => {
		console.log('save error', err);
		this.props.showLoader(false);
		this.setState({ errorMessage: 'An error occurred: ' + err, isNewError: true }, () =>
			setTimeout(() => this.scrollTo('api-errors'), 0)
		);
	};

	renderErrors() {
		const { errorMessage } = this.state;
		if (!errorMessage) return null;
		return (
			<div className="type--validation spc--bottom--med" id="api-errors">
				{errorMessage}
			</div>
		);
	}

	saveFiles = () => {
		const filesToSave = cloneDeep(this.state.localFiles);
		return new Promise((resolve, reject) => {
			appService
				.saveMerchantFiles(this.state.appId || this.state.fields.appId, filesToSave)
				.then(resolve)
				.catch(err => {
					console.log('save files error', err);
					this.props.showLoader(false);
					this.setState({
						errorMessage: 'MPA form saved, but files could not be uploaded - an error occurred: ' + err,
						isNewError: true,
					});
					reject(err);
				});
		});
	};

	renderNumberFormat = ({
		name,
		value,
		label,
		decimalScale = 0,
		allowLeadingZeros = true,
		tooltip = null,
		validateFieldsArr,
		disableField = false,
		className = '',
		renderMaskedInput = false,
	}) => {
		const disabled = disableField;

		const componentProps = {
			name,
			value,
			id: name,
			decimalScale,
			allowLeadingZeros,
			inputType: 'numeric',
			allowNegative: false,
			placeholder: label,
			className: `input ${renderMaskedInput ? 'display--n' : ''} input--med${this.validateField(
				validateFieldsArr ? validateFieldsArr : name
			)}`,
			onValueChange: values =>
				this.handleChange({ target: { name, value: allowLeadingZeros ? values.value : values.floatValue } }),
			disabled,
		};

		if (allowLeadingZeros) {
			componentProps.isNumericString = true;
		}

		return (
			<div className="col col-sml-12 col-lrg-6 form__group">
				{this.renderLabel({ tooltip, label, name, disabled })}
				<div id={`${name}_div`} className={`input--masked ${className}`}>
					{renderMaskedInput ? this.renderMaskedInput(componentProps) : <NumericFormat {...componentProps} />}
				</div>
			</div>
		);
	};

	onBlurMaskedInput = fieldKey => {
		this.maskedFieldRefs.current[fieldKey].classList.add('display--n');
		this.maskedFieldRefs.current[`${fieldKey}_mask`].classList.remove('display--n');
	};

	onFocusMaskedInput = fieldKey => {
		this.maskedFieldRefs.current[`${fieldKey}_mask`].classList.add('display--n');
		this.maskedFieldRefs.current[fieldKey].classList.remove('display--n');
		this.maskedFieldRefs.current[fieldKey].focus();
	};

	renderMaskedInput = componentProps => {
		const { name, value } = componentProps;
		componentProps.getInputRef = el => (this.maskedFieldRefs.current[name] = el);
		componentProps.onBlur = () => this.onBlurMaskedInput(name);

		return (
			<Fragment>
				<span>
					<input
						type="text"
						className={`input input--med${this.validateField(name)}`}
						placeholder={componentProps.placeholder}
						id={name}
						name={name}
						value={value ? repeat('X', value.length) : ''}
						onFocus={() => this.onFocusMaskedInput(name)}
						ref={el => (this.maskedFieldRefs.current[`${name}_mask`] = el)}
						disabled={componentProps.disabled}
					/>
				</span>
				<span>
					<NumericFormat {...componentProps} />
				</span>
			</Fragment>
		);
	};

	validateField = name => {
		const { errorListPaths } = this.state;
		const invalidClassName = ' is-invalid';

		if (
			(isArray(name) && some(name, item => includes(errorListPaths, replace(item, /_/gi, '.')))) ||
			includes(errorListPaths, replace(name, /_/gi, '.'))
		) {
			return invalidClassName;
		}

		return '';
	};

	scrollTo = id => {
		let elem = document.getElementById(`${id}_div`);
		if (!elem) {
			id = id.replace(/[.]/g, '_');
			elem = document.getElementById(`${id}_div`);
		}
		if (!elem) {
			elem = document.getElementById(`${id}`);
		}
		if (id.indexOf('signedmpa') > -1 || id.indexOf('signaturepages') > -1) {
			elem = document.getElementById('uploadsignedmpa');
			this.showUploadMPA();
		}
		elem && elem.scrollIntoView({ behavior: 'smooth', block: 'center' });
	};

	focusField = id => {
		let elem = document.getElementById(id);
		if (!elem) {
			id = id.replace(/[.]/g, '_');
			elem = document.getElementById(`${id}`);
		}
		elem && elem.focus();
	};

	onErrorClick = elName => {
		let elementId = elName.path;
		this.scrollTo(elementId);
		this.focusField(elementId);
	};

	handleChange = ({ target: { name, value, checked, type } }, index, callback = noop) => {
		const { fields, enableSecondaryBankAccountInfo, enableAlternativeBankAccountInfo, sameAsBusiness } = this.state;
		const newState = { fields: { ...fields } };
		let newValue;

		if (type === 'checkbox') {
			newValue = checked;
		} else if (type === 'radio') {
		} else {
			newValue = value;
		}

		if (name.indexOf('_') > -1) {
			name = replace(name, /_/g, '.');
		}
		set(newState.fields, name, newValue);

		if (sameAsBusiness && name.indexOf('businessAddress') > -1) {
			newState.fields.corporateAddress = cloneDeep(newState.fields.businessInformation.businessAddress);
		}

		let errorListPaths;
		let errorList;
		if (this.state.showValidation && this.mpaSchema) {
			errorList = this.mpaSchema.validate(
				Object.assign({}, { ...newState.fields, enableSecondaryBankAccountInfo, enableAlternativeBankAccountInfo })
			);
			errorListPaths = errorList.map(error => error.path);
		}

		this.setState(
			{
				fields: {
					...newState.fields,
				},
				errorList,
				errorListPaths,
			},
			callback
		);
	};

	handleTelemarketerChange = e => {
		const telemarketer = find(this.state.fields.telemarketerList, x => x.agentName === e.target.value);
		let telemarketerEmail = get(telemarketer, 'agentName', '');
		let telemarketerId = get(telemarketer, 'agentId', 0);
		this.setState({ fields: { ...this.state.fields, telemarketerEmail, telemarketerId } });
	};

	copyAddress = e => {
		const {
			fields: {
				businessInformation: { businessAddress },
			},
		} = this.state;
		const corporateAddress = e.target.checked
			? businessAddress
			: transform(businessAddress, (acc, _, key) => (acc[key] = ''));
		this.setState({ fields: { ...this.state.fields, corporateAddress }, sameAsBusiness: e.target.checked });
	};

	checkIfShouldDisplayAsterisk = name => {
		return some(this.requiredFields, item => item === name);
	};

	renderLabel = ({ tooltip, label, name, disabled }) => {
		return tooltip ? (
			<div className="form__group__header">
				<label htmlFor={name} className="form__group__label">
					{label}
				</label>
				{this.checkIfShouldDisplayAsterisk(name) && !disabled && required}
				<i className="icon icon--tny icon--regular--info datatooltip--200" data-tooltip={tooltip}></i>
			</div>
		) : (
			<div className="form__group__header">
				<label htmlFor={name} className="form__group__label">
					{label}
				</label>
				{this.checkIfShouldDisplayAsterisk(name) && !disabled && required}
			</div>
		);
	};

	enableBankAccountInformation = e => {
		const {
			target: { name, checked },
		} = e;
		if (name === 'sameAsBankAccountInformationSec') {
			this.setState(prevState => {
				const newState = cloneDeep(prevState);
				newState.enableSecondaryBankAccountInfo = checked;
				if (!checked) {
					newState.fields.secondaryBankName = '';
					newState.fields.secondaryRoutingNumber = '';
					newState.fields.secondaryAccountNumber = '';
				}
				return newState;
			});
		} else {
			this.setState(prevState => {
				const newState = cloneDeep(prevState);
				newState.enableAlternativeBankAccountInfo = checked;
				if (!checked) {
					newState.fields.alternateBankName = '';
					newState.fields.alternateRoutingNumber = '';
					newState.fields.alternateAccountNumber = '';
				}
				return newState;
			});
		}
	};
	renderField = (name, value, label, tooltip = '', disableField = false, className = '', allowZeroValue = false) => {
		const disabled = disableField;
		const containerClassName = 'col col-sml-12 col-lrg-6 form__group';

		return (
			<div id={`${name}_div`} className={`${containerClassName}`}>
				{this.renderLabel({ tooltip, label, name, disabled })}
				<input
					type="text"
					className={`input input--med${this.validateField(name)} ${className}`}
					id={name}
					name={name}
					value={allowZeroValue && value === 0 ? 0 : value || ''}
					placeholder={label}
					onChange={this.handleChange}
					disabled={disabled}
				/>
			</div>
		);
	};

	handleYesNoChange = (e, defaultValues, defaultValue) => {
		const newState = { fields: Object.assign({}, this.state.fields, { [e.target.name]: e.target.checked }) };

		if (e.target.checked === defaultValue) {
			each(defaultValues, (name, value) => {
				newState.fields[name] = value;
			});
		}

		this.setState(newState);
	};
	renderBusinessInfo() {
		const {
			fields: {
				dba,
				taxID,
				corporateName,
				businessStartDate,
				businessInformation: { website, businessFax, businessPhone, businessEmail, businessAddress, ownershipType },
				contactPhoneNumber,
				businessContactName,
				goodsOrServicesDescription,
				telemarketerList,
				telemarketerEmail,
				hasExistingFidelityAccount,
				existingAccountDba,
			},
		} = this.state;

		return (
			<ToggleContainer>
				<div className="card__body">
					<div className="row">
						{this.renderField('corporateName', corporateName, 'Corporate Name')}
						{this.renderField('dba', dba, 'DBA')}
						{this.renderRep2Field(telemarketerList, telemarketerEmail)}
						{this.renderField('contactPhoneNumber', contactPhoneNumber, 'Contact Phone Number')}
						{this.renderField('businessContactName', businessContactName, 'Contact Name')}
						{this.renderField(
							'businessInformation.businessEmail',
							businessEmail,
							'Contact Email',
							'Separate multiple email addresses with a comma'
						)}
						{this.renderField('businessInformation.businessPhone', businessPhone, 'Business Phone Number')}
						{this.renderField('businessInformation.businessFax', businessFax, 'Business Fax Number')}
						{this.renderField('goodsOrServicesDescription', goodsOrServicesDescription, 'Goods/Services Sold')}
						{this.renderField('businessInformation.website', website, 'Website')}
						{this.renderField('taxID', taxID, 'Tax ID (9 digits)')}
						<div id="businessStartDate_div" className="col col-sml-12 col-med-6 form__group">
							<div className="form__group__header">
								<p className="form__group__label">Business Start Date</p>
								{this.checkIfShouldDisplayAsterisk('businessStartDate') && required}{' '}
							</div>
							<SingleDatePicker
								name={'businessStartDate'}
								onChange={date =>
									this.handleChange({ target: { name: 'businessStartDate', value: date, checked: null, type: null } })
								}
								validateField={() => this.validateField('businessStartDate')}
								value={businessStartDate}
							/>
						</div>
						<div className="col col-sml-12 col-lrg-6 form__group">
							<div className="form__group__header">
								<p className="form__group__label">Business Type</p>
								{this.checkIfShouldDisplayAsterisk('businessInformation.ownershipType') && required}
							</div>
							<OwnershipDropdownComponent
								className={`input input--med input--select${this.validateField('businessInformation.ownershipType')}`}
								id="businessInformation_ownershipType"
								value={ownershipType}
								onChange={this.handleChange}
								ownershipOptions={[
									{ value: 'Unknown', label: 'Please select' },
									{ value: 'Partnership', label: 'Partnership' },
									{ value: 'Corporation', label: 'Corporation' },
									{ value: 'SoleOwnership', label: 'Sole Ownership' },
									{ value: 'LLC', label: 'LLC' },
									{ value: 'NonProfit', label: 'Non Profit / Tax Exempt' },
									{ value: 'Other', label: 'Other' },
								]}
							/>
						</div>
						<div className="col col-sml-12 col-med-12">
							<AddressComponent
								streetPrefix="Business"
								requiredFields={this.requiredFields}
								validateField={this.validateField}
								address={businessAddress}
								namePrefix={'businessInformation_businessAddress'}
								onChange={this.handleChange}
								isCanada={false}
							/>
						</div>
						{this.renderHasExistingFidelityAccount(hasExistingFidelityAccount, existingAccountDba)}
					</div>
				</div>
			</ToggleContainer>
		);
	}

	renderAdditionalTransactionInformation() {
		const {
			fields: {
				generalTransactionInfo: {
					maxSingle,
					maxDaily,
					maxDailyAmount,
					maxPeriod,
					maxPeriodAmount,
					averageTransactionAmount,
				},
			},
		} = this.state;
		return (
			<div className="card__body">
				<div className="row">
					{this.renderNumberFormat({
						name: 'generalTransactionInfo.maxSingle',
						value: maxSingle,
						label: 'Max Single',
						tooltip: 'What is the largest transaction size ($) you expect to process?',
					})}
					{this.renderNumberFormat({
						name: 'generalTransactionInfo.maxDaily',
						value: maxDaily,
						label: 'Daily Check Amount',
						tooltip: 'How many payments do you expect to process per day?',
					})}
					{this.renderNumberFormat({
						name: 'generalTransactionInfo.maxDailyAmount',
						value: maxDailyAmount,
						label: 'Max Daily Volume',
						tooltip: 'What is the largest daily payment total ($) you expect?',
					})}
					{this.renderNumberFormat({
						name: 'generalTransactionInfo.maxPeriod',
						value: maxPeriod,
						label: 'Monthly Check Amount',
						tooltip: 'How many payments do you expect to process per month?',
					})}
					{this.renderNumberFormat({
						name: 'generalTransactionInfo.maxPeriodAmount',
						value: maxPeriodAmount,
						label: 'Max Monthly Volume',
						tooltip: 'What is the largest monthly payment total ($) you expect?',
					})}
					{this.renderNumberFormat({
						name: 'generalTransactionInfo.averageTransactionAmount',
						value: averageTransactionAmount,
						label: 'Expected Average Amount',
						decimalScale: 4,
						tooltip: 'What is your expected average transaction size ($)?',
					})}
				</div>
			</div>
		);
	}

	renderMailingInfo() {
		const {
			fields: { corporateAddress },
		} = this.state;
		const sameAsBusiness = get(this.state, 'sameAsBusiness', false);
		return (
			<div className="card__body">
				<div className="form__group input--check--enable-form">
					<input
						id="same-as"
						type="checkbox"
						className="input--check"
						checked={sameAsBusiness}
						onClick={this.copyAddress}
					/>
					<label htmlFor="same-as">Same as Business Address</label>
				</div>
				<fieldset disabled={sameAsBusiness}>
					<AddressComponent
						requiredFields={this.requiredFields}
						validateField={this.validateField}
						streetPrefix="Business Mailing"
						address={corporateAddress}
						namePrefix={'corporateAddress'}
						onChange={this.handleChange}
						isCanada={false}
					/>
				</fieldset>
			</div>
		);
	}

	renderBankingInfo() {
		const {
			enableSecondaryBankAccountInfo,
			enableAlternativeBankAccountInfo,
			fields: { bankAccountInformation },
		} = this.state;
		return (
			<div className="card__body">
				<div className="row">
					{this.renderField('bankAccountInformation.bankName', bankAccountInformation.bankName, 'Bank Name')}

					{this.renderNumberFormat({
						name: 'bankAccountInformation.accountNumber',
						value: bankAccountInformation.accountNumber,
						label: 'Bank Account #',
						renderMaskedInput: true,
					})}

					{this.renderNumberFormat({
						name: 'bankAccountInformation.routingNumber',
						value: bankAccountInformation.routingNumber,
						label: 'Bank Routing #',
						renderMaskedInput: true,
					})}
					<div className="col col-sml-12 form__group spc--top--xlrg">
						<div className="input--check--enable-form">
							<input
								name="sameAsBankAccountInformationSec"
								id="sameAsBankAccountInformationSec"
								type="checkbox"
								className="input--check"
								checked={enableSecondaryBankAccountInfo}
								onClick={this.enableBankAccountInformation}
							/>
							<label htmlFor="sameAsBankAccountInformationSec">Use a different account for fees</label>
						</div>
					</div>
					{this.renderField(
						'bankAccountInformation.secondaryBankName',
						bankAccountInformation.secondaryBankName,
						'Bank Name',
						'',
						!enableSecondaryBankAccountInfo
					)}
					{this.renderNumberFormat({
						name: 'bankAccountInformation.secondaryAccountNumber',
						value: bankAccountInformation.secondaryAccountNumber,
						label: 'Bank Account #',
						renderMaskedInput: true,
						disableField: !enableSecondaryBankAccountInfo,
					})}
					{this.renderNumberFormat({
						name: 'bankAccountInformation.secondaryRoutingNumber',
						value: bankAccountInformation.secondaryRoutingNumber,
						label: 'Bank Routing #',
						renderMaskedInput: true,
						disableField: !enableSecondaryBankAccountInfo,
					})}
					<div className="col col-sml-12 form__group spc--top--xlrg">
						<div className="input--check--enable-form">
							<input
								name="sameAsBankAccountInformationAlt"
								id="sameAsBankAccountInformationAlt"
								type="checkbox"
								className="input--check"
								checked={enableAlternativeBankAccountInfo}
								onClick={this.enableBankAccountInformation}
							/>
							<label htmlFor="sameAsBankAccountInformationAlt">Use a different account for bounced checks</label>
						</div>
					</div>
					{this.renderField(
						'bankAccountInformation.alternateBankName',
						bankAccountInformation.alternateBankName,
						'Bank Name',
						'',
						!enableAlternativeBankAccountInfo
					)}
					{this.renderNumberFormat({
						name: 'bankAccountInformation.alternateAccountNumber',
						value: bankAccountInformation.alternateAccountNumber,
						label: 'Bank Account #',
						renderMaskedInput: true,
						disableField: !enableAlternativeBankAccountInfo,
					})}
					{this.renderNumberFormat({
						name: 'bankAccountInformation.alternateRoutingNumber',
						value: bankAccountInformation.alternateRoutingNumber,
						label: 'Bank Routing #',
						renderMaskedInput: true,
						disableField: !enableAlternativeBankAccountInfo,
					})}
				</div>
			</div>
		);
	}

	renderSignerInformation() {
		const {
			fields: {
				signerInformation: {
					firstName,
					lastName,
					phoneNumber,
					cellPhoneNumber,
					socialSecurityNumber,
					dateOfBirth,
					address,
					ownershipPercentage,
					email,
					title,
				},
			},
		} = this.state;

		return (
			<div className="card__body">
				<div className="row">
					{this.renderField('signerInformation.firstName', firstName, 'Signer First Name')}
					{this.renderField('signerInformation.lastName', lastName, 'Signer Last Name')}
				</div>
				<AddressComponent
					requiredFields={this.requiredFields}
					validateField={this.validateField}
					streetPrefix="Signer Home"
					address={address}
					namePrefix={'signerInformation_address'}
					onChange={this.handleChange}
					isCanada={false}
				/>
				<div className="row">
					{this.renderField('signerInformation.phoneNumber', phoneNumber, 'Phone Number')}
					{this.renderField('signerInformation.cellPhoneNumber', cellPhoneNumber, 'Signer Cell Number')}
					{this.renderField('signerInformation.email', email, 'Signer Email')}
					{this.renderField('signerInformation.title', title, 'Signer Title')}
					{this.renderField('signerInformation.socialSecurityNumber', socialSecurityNumber, 'Social Security Number')}
					{this.renderField(
						'signerInformation.ownershipPercentage',
						ownershipPercentage,
						'Ownership Percentage',
						undefined,
						undefined,
						undefined,
						true
					)}

					<div id="signerInformation.dateOfBirth_div" className="form__group col col-sml-12 col-lrg-6">
						<div className="form__group__header">
							<p className="form__group__label">
								Date of Birth{this.checkIfShouldDisplayAsterisk('signerInformation.dateOfBirth') && required}{' '}
							</p>
						</div>
						<SingleDatePicker
							name={'signerInformation.dateOfBirth'}
							id={'signerInformation.dateOfBirth'}
							className="fs-mask"
							onChange={date =>
								this.handleChange({
									target: { name: 'signerInformation.dateOfBirth', value: date, checked: null, type: null },
								})
							}
							validateField={() => this.validateField('signerInformation.dateOfBirth')}
							value={dateOfBirth}
						/>
					</div>
				</div>
			</div>
		);
	}

	getFilesForTag(tag) {
		const {
			fields: { files },
		} = this.state;
		const fullFileList = files;
		let matchTag = [];

		if (!fullFileList) {
			return null;
		}

		if (Array.isArray(tag)) {
			matchTag = tag;
		} else {
			matchTag.push(tag);
		}
		if (matchTag.includes('BankStatements')) matchTag.push('BankingStatement'); //this line can be removed in a year from now - sweinstock 4/02/2024
		return filter(fullFileList, file => {
			const fileTag = file.fileTag.split('_');
			return fileTag.filter((ft, i) => matchTag.includes(ft)).length > 0;
		});
	}

	handleFileDelete = (appId, fileId, fileName, fileTag) => e => {
		this.openCloseModal({
			name: modalNames.confirmAction,
			data: {
				question: 'Are you sure you want to delete ' + fileName + '?',
				onConfirm: this.deleteFile(appId, fileId, fileTag),
			},
		});
	};

	openCloseModal = modalObj => {
		let state = {
			modal: modalObj,
		};
		this.setState(state);
	};

	deleteFile = (appId, fileId, fileTag) => () => {
		this.props.showLoader(true);
		appService
			.deleteMerchantFile(appId, fileId)
			.then(() => {
				const { fields } = this.state;
				this.fetchData().then(fetchedData => {
					fields.files = fetchedData.files;
					this.setState({ fields }, () => {
						this.props.showLoader(false);
						const addNotification = get(this.notificationRef, 'current.addNotification', noop);
						addNotification({
							message: 'Successfully deleted',
							success: true,
						});
					});
				});
			})
			.catch(ex => {
				this.props.showLoader(false);
				this.setState({ downloadErrorMessage: ex });
			});
	};

	renderFilesForTag(tag) {
		let files = this.getFilesForTag(tag);
		if (files && files.length > 0) {
			return (
				<React.Fragment>
					<p className="type--p3 type--p3--medium spc--top--med">Already Uploaded:</p>
					<div className="upload__list">
						{files.map(file => {
							return (
								<div className="upload__list__item" key={file.fileId}>
									<p className="type--p2">{file.fileName}</p>
									<div className="flex--primary flex--gap--sml align--h--right">
										{canPreviewFile(file.fileName) && (
											<button
												className="btn btn--link datatooltip--auto"
												data-tooltip="View"
												onClick={this.handleFileDownload(
													file.parentId,
													file.fileId,
													file.fileName,
													this.props.showLoader
												)}
												disabled={this.props.isLoading}
											>
												<i className="icon icon--sml icon--view--text"></i>
											</button>
										)}
										<button
											className="btn btn--link datatooltip--auto"
											data-tooltip="Download"
											onClick={this.handleFileDownload(
												file.parentId,
												file.fileId,
												file.fileName,
												this.props.showLoader,
												true
											)}
											disabled={this.props.isLoading}
											download
										>
											<i className="icon icon--sml icon--download "></i>
										</button>
										<button
											className="btn btn--link datatooltip--auto"
											data-tooltip="Delete"
											onClick={this.handleFileDelete(file.parentId, file.fileId, file.fileName, file.fileTag)}
											disabled={this.props.isLoading || this.state.disableSave}
										>
											<i className="icon icon--sml icon--delete"></i>
										</button>
									</div>
								</div>
							);
						})}
					</div>
				</React.Fragment>
			);
		}
	}

	onDropFile = (fileType, files) => {
		const { localFiles } = this.state;
		const fullFileList = cloneDeep(localFiles);
		let newFilesList = files.map((itm, i) => {
			return { file: itm, fileDescription: '' };
		});

		if (fullFileList[fileType]) fullFileList[fileType].push(...newFilesList);
		else fullFileList[fileType] = newFilesList;
		this.setState({ localFiles: fullFileList });
	};

	onEnterKeyDownHandler = (event, onClickHandler) => {
		if (event.keyCode === KeyCodes.Enter) onClickHandler();
	};

	handleRemoveFile = (fileType, i, callback = noop) => {
		const { localFiles } = this.state;
		const fullFileList = cloneDeep(localFiles);
		fullFileList[fileType].splice(i, 1);
		this.setState({ localFiles: fullFileList });
	};

	renderFileDropZone(tag) {
		const { localFiles } = this.state;
		return (
			<FileDropzoneComponent
				tag={tag}
				multiple={true}
				fileList={localFiles}
				onDrop={this.onDropFile}
				onRemoveFile={this.handleRemoveFile}
				validateFileSize={false}
				allowedFileTypes={this.allowedFileTypes}
			/>
		);
	}

	checkIfRequiredFileTag = fileTag => {
		return some(this.requiredDocs, tag => tag === fileTag);
	};

	renderUploadDocumentRow(tag, label, tooltip, showSeparator = true, id = null) {
		const required = this.checkIfRequiredFileTag(tag);
		return (
			<Toggle initialToggle={true}>
				{({ isToggled, handleToggle }) => (
					<div className="card__upload__item" id={id}>
						<button
							className="card__upload__header"
							onKeyDown={e => this.onEnterKeyDownHandler(e, handleToggle)}
							onClick={handleToggle}
						>
							<div className="flex--primary flex--gap--sml">
								<div className="flex--primary flex--gap--tny">
									<span className="type--p2">{label}</span>
									{required && (
										<span className="type--p2 form__group__required" data-tooltip="Required">
											*
										</span>
									)}
								</div>
								{tooltip && (
									<i className="icon icon--sml icon--regular--info datatooltip--200" data-tooltip={tooltip} />
								)}
							</div>
							<i className={`icon icon--sml icon--chevron--${isToggled ? 'down' : 'right'}--light`}></i>
						</button>

						{isToggled && (
							<div className="card__upload__body">
								{this.renderFileDropZone(tag)}
								{this.renderFilesForTag(tag)}
							</div>
						)}
					</div>
				)}
			</Toggle>
		);
	}

	renderOtherNotes() {
		const {
			fields: { accountNotes },
		} = this.state;
		return (
			<div className="card__body">
				<textarea
					placeholder="Notes"
					className="input input--textarea"
					cols={50}
					rows={5}
					name="accountNotes"
					value={accountNotes}
					onChange={this.handleChange}
				></textarea>
			</div>
		);
	}
}

AchBaseClass.propTypes = {
	match: PropTypes.object,
	history: PropTypes.object,
	handleBlockChange: PropTypes.func,
	showLoader: PropTypes.func,
	isLoading: PropTypes.func,
};

export default AchBaseClass;
