import React, {Component} from 'react';
import PropTypes from 'prop-types';

class MIDSelectionComponent extends Component {

    get midValue() {
        return this.props.filter.values.mid;
    }

    filterRemoved = e => {
        this.props.onFilterRemoved({
            id: 'mid',
            emptyValue: null
        });
    };

    render() {
        return (
            <div className="filter__tag">
                <label className="filter__tag__label">MID:</label>
                {this.midValue}
                <button onClick={this.filterRemoved} className="btn btn--reset filter__tag__clear"></button>
            </div>
        );
    }
}

MIDSelectionComponent.propTypes = {
    filter: PropTypes.object.isRequired,
    onFilterRemoved: PropTypes.func.isRequired
};

export default MIDSelectionComponent;