import React, { Fragment, createRef } from 'react';
import {
    filter,
    some,
    first,
    split,
    map,
    isEmpty,
    keys,
    toLower,
    get,
    noop,
    cloneDeep,
    isPlainObject,
    transform,
    each,
    replace,
    includes,
    isArray,
    compact,
    find,
    flatten
} from 'lodash';
import { bool, func } from 'prop-types';
import Select from 'react-select';

import Schema from '../../validation/BaseSchema';
import { bankingServicesFields, categoryOptions as categories, typeOptions as types, submitOptions, contactMethodOptions } from '../../common/components/banking-services/bankingServicesOptions';
import { appService } from '../../services/appService';
import withLoader from '../../common/components/loader/loader-hoc';
import Notification from '../../common/components/notifications/notifications';
import FileDropzoneComponent from '../../common/components/file-dropzone/file-dropzone';
import { emailService, principalService } from '../../services';
import { emailTemplates, isElavon, isFd, validateOnBlur } from '../../common/utilities';
import { bankingServicesFormTemplate, eids } from '../../validation/bankingServices.validation';
import { withContext } from '../../common/components';
import { MerchantContext } from '../MerchantDetails';
import { ValidationError } from '../error';
import { email } from '../../validation/validators';

const required = (
    <span data-tooltip="Required" className="type--color--primary">
        {" "}
        *
    </span>
);

const sortedTypeOptions = types;
const sortedCategoryOptions = categories;

class BankingServices extends React.Component {
    constructor(props) {
        super(props);

        this.topRef = createRef();
        this.notificationRef = createRef();

        this.state = this.initialState;
    }

    get initialState() {
        const category = first(sortedCategoryOptions);
        const type = first(filter(sortedTypeOptions, item => item.category === category.value));
        const principal = principalService.get();
        return {
            isSubmit: false,
            errorMessage: null,
            errorList: [],
            errorListPaths: [],
            isNewError: false,
            categoryOptions: sortedCategoryOptions,
            typeOptions: filter(sortedTypeOptions, item => item.category === category.value),
            ContactMethodOptions: contactMethodOptions,
            category,
            type,
            submitOption: get(first(submitOptions), 'value'),
            fields: this.initialFields,
            files: {},
            fromEmail: principal.email,
        }
    }

    get initialFields() {
        const businessEmail = get(this.props, 'merchant.businessEmail');
        const merchantEmail = includes(businessEmail, '@') ? businessEmail : '';
        return {
            MyMerchantSignatureName: 'Merchant',
            MyMerchantSignatureEmail: merchantEmail,
            ContactEmail: '',
            ContactName: '',
            ContactPhone: '',
            ContactMethod: null,
        };
    }

    get defaultFields() {
        return { title: 'Fields', fields: [] };
    }

    get getFieldsForValidation() {
        const { fields, category, type } = this.state;
        return { fields: { ...fields, ContactMethod: get(fields, 'ContactMethod.value', ''), }, category: category.value, type: type.value }
    }

    componentDidUpdate() {
        if ((this.state.errorMessage || !isEmpty(this.state.errorList)) && this.state.isNewError) {
            setTimeout(() => {
                if (this.topRef.current) {
                    this.topRef.current.scrollIntoView({
                        behavior: "smooth",
                    });
                }
            }, 200);
            this.setState({ isNewError: false });
        }
    }

    handleError = err => {
        this.props.showLoader();
        console.log("error", err);
        this.setState({
            errorMessage: ["Error: " + err],
            isNewError: true,
        });
    };

    submit = () => {
        const { showLoader, merchant } = this.props;
        const { fields, files, type: { value: type }, submitOption: SubmitOption } = this.state;
        const addNotification = get(this.notificationRef, 'current.addNotification', noop);
        const requestFields = transform(fields, (acc, value, key) => {
            if (isPlainObject(value)) {
                acc[key] = value.value;
            } else {
                acc[key] = value;
            }
        });
        const data = {
            AppId: merchant.appId,
            Dba: merchant.dba,
            ChangeType: type,
            SubmitOption,
            RequestFields: requestFields,
            Files: map(files, (value) => first(value).file),
        };

        if (this.validateFields(true, true)) {
            return;
        }
        showLoader(true);
        if (SubmitOption === 'EmailUser') {
            this.prefillFiles(this.selectFilesForPrefill(), { ...requestFields, DBA: merchant.dba, appId: merchant.appId });
        }
        else {
            appService.submitBankingServicesRequest(data)
                .then(({ refNum: ref }) => {
                    this.setState({ errorMessage: null, isNewError: false });
                    showLoader();
                    addNotification({
                        message: 'Banking Services Request Submitted Successfully',
                        success: true,
                        ref,
                        onClose: () => this.setState(this.initialState)
                    });
                })
                .catch(e => this.handleError(e));
        }
    };

    validateSelectOnBlur = name => {
        this.validateFieldOnBlur({ target: { name } });
    };

    validateFieldOnBlur = ({ target: { name } }) => {
        const { isSubmit, errorList } = this.state;
        if (isSubmit) return;
        const formSchema = new Schema(this.getSchemaTemplate(), { strip: false, typecast: true });
        const newState = validateOnBlur(formSchema, this.getFieldsForValidation, errorList, name);
        this.setState(newState);
    };

    validateFields = (scrollToTop = false, isSubmit = false) => {
        const formSchema = new Schema(this.getSchemaTemplate(), { strip: false, typecast: true });
        const errorList = formSchema.validate(Object.assign({}, this.getFieldsForValidation));

        const errorListPaths = errorList.map(e => e.path);
        const hasErrors = !isEmpty(errorList);
        const newState = { errorList, errorListPaths };

        if (scrollToTop) {
            newState.isNewError = hasErrors;
        }

        newState.isSubmit = scrollToTop || isSubmit;

        this.setState(newState);

        return hasErrors;
    };

    validateField = name => {
        const { errorListPaths } = this.state;
        const invalidClassName = ' is-invalid';

        if ((isArray(name) && some(name, item => includes(errorListPaths, replace(item, /_/gi, '.')))) || includes(errorListPaths, name) || includes(errorListPaths, replace(name, /_/gi, '.'))) {
            return invalidClassName;
        }

        return '';
    };

    selectFilesForPrefill = () => {
        const {
            type: { value: type },
        } = this.state;
        const fileNames = {
            Reincorporation: ['FD_Reincorporation_v1.0'],
            BankInfoUpdate: ['FD_BankInformation_v2.0'],
            BusinessDBA_Info: ['FD_DBAInformation_v1.0'],
            Reincorporation_BankInfo: ['FD_Reincorporation_BankInformation_v2.0'],
            WirelessFeeFirstData: ['Omaha_FeesAndGridsForm'],
            WirelessFeeElavon: ['Elavon_WirelessFeeForm'],
        };

        if (
            some(
                [
                    "AmexOptBlue_TieredPricing",
                    "AmexOptBlue_PassThroughPricing",
                    "AmexOptBlue_ErrPricing",
                    "AmexOptBlue_FlatRatePricing",
                ],
                (item) => item === type
            )
        ) {
            return ['Entitlements_AmexOptBlue_v1.0', 'Entitlements_AmexOptBlue_Pg12_v3.0'];
        }

        if (some(['PINDebit', 'EBT', 'AmexESA'], item => item === type)) {
            return ['Add_RemoveEntitlements_v2.0'];
        }

        return fileNames[type];
    };

    mapFileKeyToEmailParam = key => {
        const paramList = {
            'FD_Reincorporation_v1.0': 'reincorporation',
            'FD_BankInformation_v2.0': 'bankInfoUpdate',
            'FD_DBAInformation_v1.0': 'businessDBA_Info',
            'FD_Reincorporation_BankInformation_v2.0': 'reincorporation_BankInfo',
            'Omaha_FeesAndGridsForm': 'wirelessFeeFirstData',
            'Elavon_WirelessFeeForm': 'wirelessFeeElavon',
            'Entitlements_AmexOptBlue_Pg12_v3.0': 'amexOptBlue_Pg12_v3.0',
            'Entitlements_AmexOptBlue_v1.0': 'amexOptBlue_v1.0',
            'Add_RemoveEntitlements_v2.0': 'addRemoveEntitlements',
        };

        return paramList[key];
    };

    prefillFiles = (fileNamesList, data) => {
        const { showLoader } = this.props;
        const { fromEmail } = this.state;
        const prefilledFilesList = {};

        showLoader(true);
        each(fileNamesList, item => {
            appService.downloadPrefilledFile(item, data)
                .then((url) => {
                    let msg = '';

                    if (url.errorMessage) {
                        msg = url.errorMessage;
                    }
                    else {
                        prefilledFilesList[this.mapFileKeyToEmailParam(item)] = url;

                        if (keys(prefilledFilesList).length === fileNamesList.length) {
                            const templateParams = { merchantDba: data.DBA, mid: data.appId, fromEmail, contactEmail: data.ContactEmail };

                            each(prefilledFilesList, (value, key) => templateParams[key] = value);

                            this.sendFilesEmail(templateParams);
                        }
                    }
                    if (msg !== '')
                        this.setState({ errorMessage: [msg] })
                })
                .catch(e => this.handleError(e));
        });
    };

    sendFilesEmail = async templateParams => {
        const addNotification = get(this.notificationRef, 'current.addNotification', noop);
        try {
            this.props.showLoader(true);
            const response = await emailService.send(emailTemplates.bankingServicesDocumentsToSign, templateParams);

            addNotification({
                ref: response.refNum,
                success: true,
                message: 'Banking Services Request Submitted Successfully',
                onClose: () => this.setState(this.initialState),
            });
            this.props.showLoader();
        } catch (e) {
            this.handleError(e);
        }
    };

    handleRemoveFile = fileKey => {
        this.setState({
            files: transform(this.state.files, (acc, value, key) => {
                if (key !== fileKey) {
                    acc[key] = value;
                }
            })
        });
    };

    handleDropFile = (fileType, acceptedFiles) => {
        let newFilesList = acceptedFiles.map((itm, i) => {
            return { file: this.renameFile(itm, fileType + "_" + itm.name), fileDescription: "" };
        });
        let files = cloneDeep(this.state.files);

        if (files[fileType])
            files[fileType].push.apply(files[fileType], newFilesList);
        else
            files[fileType] = newFilesList;

        this.setState({ files });
    };

    renameFile(originalFile, newName) {
        return new File([originalFile], newName, {
            type: originalFile.type,
            lastModified: originalFile.lastModified,
        });
    }

    getCurrentBankingServicesFields = (selectedType) => {
        return bankingServicesFields[selectedType.value] || this.defaultFields;
    }

    handleChange = ({ target: { value, name } }) => {
        const { isSubmit, fields } = this.state;
        const newState = {};

        if (name.indexOf('.') > -1) {
            const [section, key] = split(name, '.');
            newState[section] = { ...this.state[section], [key]: value };
        } else {
            newState[name] = value;
        }

        if (toLower(name) === 'submitoption') {
            newState.fields = { ...fields, MyMerchantSignatureName: '', MyMerchantSignatureEmail: '' };
            const prefillMerchantInfo = toLower(value) === 'signature';

            if (prefillMerchantInfo) {
                newState.fields = { ...newState.fields, ...this.initialFields };
            }

            this.clearMerchantSignatureFile(newState, value);
        }

        this.setState(newState, () => {
            if (isSubmit) {
                this.validateFields(false, isSubmit);
            }
        });
    };

    getSchemaTemplate = () => {
        const { submitOption } = this.state;
        const schemaTemplate = bankingServicesFormTemplate;
        const currentFields = this.getCurrentBankingServicesFields(this.state.type);
        each(schemaTemplate, (value, key) => {
            if (key === 'fields') {
                each(value, (_, fieldKey) => {
                    if (toLower(fieldKey).indexOf('mymerchant') > -1) {
                        const isRequired = submitOption && toLower(submitOption) === 'signature';
                        schemaTemplate[key][fieldKey].required = isRequired;
                        if (toLower(fieldKey).indexOf('email') > -1) {
                            schemaTemplate[key][fieldKey].use.email = email(true);
                        }
                    }
                    if (schemaTemplate[key][fieldKey].use.eids) {
                        schemaTemplate[key][fieldKey].required = includes(map(currentFields.fields, f => f.key), fieldKey) && eids('', this.state)
                    }
                });
            }
        });

        return schemaTemplate;
    };

    clearMerchantSignatureFile = (newState, submitOption) => {
        const { files } = this.state;

        if (submitOption !== 'documentuploaded') {
            newState.files = transform(files, (acc, value, key) => {
                if (toLower(key) !== 'merchantsignature') {
                    acc[key] = value;
                }
            });
        }
    };

    handleReactSelectChange = (selected, changed) => {
        const { isSubmit } = this.state;
        const isCategoryOrType = some(['category', 'type'], item => item === changed.name);
        let option = selected;
        const newState = {};

        if (changed.action === 'clear') {
            option = {};
        }

        if (changed.name.indexOf('.') > -1) {
            const [key, fieldKey] = split(changed.name, '.');
            newState[key] = {
                ...this.state[key],
                [fieldKey]: option
            };
        } else {
            newState[changed.name] = option;
        }

        this.updateTypeOptions(changed, option, newState);

        if (isCategoryOrType && newState[changed.name] !== this.state[changed.name]) {
            this.updateFieldsAndFiles(newState);
        }

        this.setState(newState, () => {
            if (isCategoryOrType && isSubmit) {
                this.validateFields();
            }
        });
    };

    handleSelectChange = ({ target: { value, name } }) => {
        const { isSubmit } = this.state;
        const isCategoryOrType = some(['category', 'type'], item => item === name);
        const optionsKey = name.indexOf('.') > -1 ? split(name, '.')[1] : name;
        const option = find(this.state[`${optionsKey}Options`], item => item.value === value);
        const newState = {};

        if (name.indexOf('.') > -1) {
            const [key, fieldKey] = split(name, '.');
            newState[key] = {
                ...this.state[key],
                [fieldKey]: option
            };
        } else {
            newState[name] = option;
        }

        this.updateTypeOptions(name, option, newState);

        if (isCategoryOrType && newState[name] !== this.state[name]) {
            this.updateFieldsAndFiles(newState);
        }

        this.setState(newState, () => {
            if (isSubmit) {
                this.validateFields(false, isSubmit);
            } else {
                this.validateSelectOnBlur(name);
            }
        });
    };

    updateTypeOptions = (name, option, newState) => {
        if (name === 'category') {
            if (option.exact) {
                newState.typeOptions = filter(sortedTypeOptions, ({ category }) => category === option.value);

                if (toLower(option.value) === 'addremovefees') {
                    newState.typeOptions = filter(newState.typeOptions, ({ forElavonProcessor }) => isElavon(this.props.merchant) ? forElavonProcessor : !forElavonProcessor);
                }

                if (!some(newState.typeOptions, ({ value }) => value === this.state.type.value)) {
                    newState.type = first(newState.typeOptions);
                }
            } else {
                newState.typeOptions = sortedTypeOptions;
            }
        }
    };

    updateFieldsAndFiles = newState => {
        const { fields, submitOption } = this.state;
        newState.fields = { ...transform(fields, (acc, _, key) => acc[key] = '') };
        this.setFieldDefaultValues(newState);
        newState.files = {};

        if (toLower(submitOption) === 'signature') {
            newState.fields = { ...newState.fields, ...this.initialFields };
        }

        if (!get(newState.type, 'displaySubmitOption', false)) {
            newState.submitOption = 'NoSignatureRequired';
        } else {
            newState.submitOption = get(first(submitOptions), 'value');
        }
    };

    setFieldDefaultValues = (newState) => {
        const fields = this.getCurrentBankingServicesFields(newState.type)
        map(fields.fields, (field) => {
            if (newState.fields[field.key] == null && field.defaultValue) {
                newState.fields[field.key] = field.defaultValue;
            }
        })
    }

    renderSection = rows => {
        return map(rows, (row, index) => (
            <tbody>
                <tr>
                    {map(keys(row), key => (
                        <td className="table__cell">
                            {toLower(key) === 'description' ? <div>{rows[index][key]}</div> : this.renderField({ key: rows[index][key], placeholder: key, wrapperClassName: '' })}
                        </td>
                    ))}
                </tr>
            </tbody>
        ));
    };

    renderField = ({
        label = '',
        key = '',
        component,
        type,
        options,
        placeholder = '',
        wrapperClassName = 'col col-sml-12 col-med-4 spc--bottom--sml',
        additionalInfo = '',
    }) => {
        const uniqueKey = `fields.${key}`;

        let labelClassName = 'label';
        let Component = component || 'input';
        let componentProps = {
            className: type ? '' : `input input--med${this.validateField(uniqueKey)}`,
            placeholder: placeholder || label,
            id: uniqueKey,
            name: uniqueKey,
            value: this.state.fields[key],
            type: type || 'text',
            onChange: this.handleChange,
        };

        if (!isEmpty(options)) {
            Component = Select;
            delete componentProps.className;
            delete componentProps.type;
            componentProps.options = options;
            componentProps.value = get(this.state.fields, key) || first(options);
            componentProps.onChange = this.handleReactSelectChange;
        }

        if (type === 'file') {
            Component = FileDropzoneComponent;
            componentProps = {
                multiple: false,
                showDescription: false,
                tag: key,
                fileList: this.state.files,
                onDrop: this.handleDropFile,
                onRemoveFile: this.handleRemoveFile,
            };
        }

        return (
            <div
                key={key}
                className={wrapperClassName}
            >
                {label && <label className={labelClassName}>{label}{this.checkIfShouldDisplayAsterisk(uniqueKey) && required}</label>}
                <Component {...componentProps} />
                {!!additionalInfo && <p className="message message--default spc--top--sml">{additionalInfo}</p>}
            </div>
        );
    };

    renderFields = () => {
        const { feeClass, type } = this.state;
        const { title, fields, section } = this.getCurrentBankingServicesFields(this.state.type);
        const displayFeeClass = get(type, 'displayFeeClass', false);
        return (
            <div className="row">
                <h6 className="col col-sml-12 spc--bottom--sml">{title}</h6>
                {displayFeeClass && (
                    <div className="col col-sml-12 col-med-4 spc--bottom--med">
                        <label className="label">Fee Class</label>
                        <input
                            className="input input--med"
                            placeholder="Fee Class"
                            id="fields.feeClass"
                            name="fields.feeClass"
                            value={feeClass}
                            onChange={this.handleChange}
                        />
                    </div>
                )}
                {isEmpty(fields) && isEmpty(section) ? (
                    <div className="col col-sml-12 spc--bottom--med">
                        No fields for the selected
                        <span className="type--wgt--bold spc--left--nano">Type</span>
                    </div>
                ) : (
                    map(fields, (field) => this.renderField(field))
                )}
                <div className="col col-sml-12 spc--bottom--med">
                    <table className="table table--primary">
                        {!isEmpty(section) && (
                            <Fragment>
                                <thead className="table__header"><tr>{map(section.columns, column => <td className="table__header__cell">{column}</td>)}</tr></thead>
                                {this.renderSection(section.rows)}
                            </Fragment>
                        )}
                    </table>
                </div>
            </div>
        );
    };

    renderSubmitButton = () => {
        const { isLoading } = this.props;

        return (
            <div className="type--right">
                <button
                    className="btn btn--med btn--primary"
                    type="button"
                    disabled={isLoading}
                    onClick={this.submit}
                >
                    Submit
                </button>
            </div>
        );
    };

    renderUploadMerchantSignature = () => {
        const { submitOption } = this.state;

        return toLower(submitOption) === 'documentuploaded' && this.renderField({ key: 'merchantSignature', label: 'Upload Merchant Signature', type: 'file' });
    };

    renderSubmitOptions = () => map(submitOptions, ({ value, label }) => (
        <div key={`submitOption.${value}`} className="spc--bottom--sml">
            <input
                className="input--radio"
                type="radio"
                name="submitOption"
                id={`submitOption.${value}`}
                value={value}
                checked={this.state.submitOption === value}
                onChange={this.handleChange}
            />
            <label htmlFor={`submitOption.${value}`} className="label type--none">{label}</label>
        </div>
    ));

    renderSubmitOptionFields = () => {
        const { submitOption, fields } = this.state;

        return toLower(submitOption) === 'signature' && (
            <div className="separator--grey1 spc--bottom--med">
                <div className="row">
                    <div className="col col-sml-12 spc--bottom--sml">
                        <h6 className="spc--bottom--tny">Merchant Information</h6>
                        <div className="message message--default">
                            <p>Complete the merchant information below.</p>
                        </div>
                    </div>
                    <div className="col col-sml-12 col-med-4 spc--bottom--med">
                        <label className="label">Merchant email to name {this.checkIfShouldDisplayAsterisk('fields.MyMerchantSignatureName') && required}</label>
                        <input
                            className={`input input--med${this.validateField('fields.MyMerchantSignatureName')}`}
                            placeholder="Name"
                            id="fields.MyMerchantSignatureName"
                            name="fields.MyMerchantSignatureName"
                            value={fields.MyMerchantSignatureName}
                            onChange={this.handleChange}
                            onBlur={this.validateFieldOnBlur}
                        />
                    </div>
                    <div className="col col-sml-12 col-med-4 spc--bottom--med">
                        <label className="label">Merchant email to address {this.checkIfShouldDisplayAsterisk('fields.MyMerchantSignatureName') && required}</label>
                        <input
                            type="text"
                            className={`input input--med${this.validateField('fields.MyMerchantSignatureEmail')}`}
                            placeholder="Email Address"
                            id="fields.MyMerchantSignatureEmail"
                            name="fields.MyMerchantSignatureEmail"
                            value={fields.MyMerchantSignatureEmail}
                            onChange={this.handleChange}
                            onBlur={this.validateFieldOnBlur}
                        />
                    </div>
                </div>
            </div>
        );
    };

    renderContactFields = () => {
        const { fields } = this.state;

        return (
            <Fragment>
                <div className="col col-sml-12 spc--bottom--sml">
                    <h6 className="spc--bottom--tny">Contact Information</h6>
                    <div className="message message--default">
                        <p>Complete the contact information below.</p>
                    </div>
                </div>
                <div className="col col-sml-12 col-med-4 spc--bottom--med">
                    <label htmlFor="ContactName" className="label">Contact name {required}</label>
                    <input
                        type="text"
                        className={`input input--med${this.validateField('fields.ContactName')}`}
                        placeholder="Contact Name"
                        id="ContactName"
                        name="fields.ContactName"
                        value={fields.ContactName}
                        onChange={this.handleChange}
                        onBlur={this.validateFieldOnBlur}
                    />
                </div>
                <div className="col col-sml-12 col-med-4 spc--bottom--med">
                    <label htmlFor="ContactEmail" className="label">Contact email address {required}</label>
                    <input
                        className={`input input--med${this.validateField('fields.ContactEmail')}`}
                        placeholder="Contact Email Address"
                        id="ContactEmail"
                        name="fields.ContactEmail"
                        value={fields.ContactEmail}
                        onChange={this.handleChange}
                        onBlur={this.validateFieldOnBlur}
                    />
                </div>
                <div className="col col-sml-12 col-med-4 spc--bottom--med">
                    <label htmlFor="ContactPhone" className="label">Call Back Number {required}</label>
                    <input
                        className={`input input--med${this.validateField('fields.ContactPhone')}`}
                        placeholder="Call Back Number"
                        id="ContactPhone"
                        name="fields.ContactPhone"
                        value={fields.ContactPhone}
                        onChange={this.handleChange}
                        onBlur={this.validateFieldOnBlur}
                    />
                </div>
                <div className="col col-sml-12 col-med-4 spc--bottom--med">
                    {this.renderSelectComponent('fields.ContactMethod', get(fields, 'ContactMethod.value', ''), this.state.ContactMethodOptions, 'Preferred Communication Method Email/Phone', true, 'ContactMethod')}
                </div>
            </Fragment>
        );
    };

    renderSelectComponent = (name, value, options, label, isRequired, id = name) => {
        return (
            <Fragment>
                <label htmlFor={name} className="label">{label} {isRequired && required}</label>
                <select
                    id={id}
                    name={name}
                    className={`input input--med input--select${this.validateField(name)}`}
                    onChange={this.handleSelectChange}
                    value={value}
                >
                    {map(options, option => <option key={option.value} value={option.value}>{option.label}</option>)}
                </select>
            </Fragment>
        );
    };

    renderErrors = () => {
        const {
            errorMessage,
            errorListPaths,
            errorList,
        } = this.state;

        return (
            <Fragment>
                {errorMessage ? (
                    <div className="note note--warning spc--bottom--med">
                        {errorMessage}
                    </div>
                ) : null}
                <ValidationError errorList={errorList} errorListPaths={errorListPaths} scrollTo={this.scrollTo} focusField={this.focusField} />
            </Fragment>
        );
    };

    scrollTo = id => {
        const elem = document.getElementById(id);
        elem && elem.scrollIntoView({ behavior: 'smooth', block: 'center' });
    };

    focusField = id => {
        const elem = document.getElementById(id);
        elem && elem.focus();
    };

    checkIfShouldDisplayAsterisk = name => {
        const requiredFields = compact(flatten(map(this.getSchemaTemplate(), (field, key) => {
            if (key === 'fields') {
                return map(field, (value, fieldKey) => value.required && `${toLower(key)}.${toLower(fieldKey)}`);
            } else {
                return field.required && toLower(key);
            }
        })));

        return some(requiredFields, item => item === toLower(name));
    };

    renderCategoryOptions = () => {
        const { category } = this.state;
        const isFdApp = isFd(this.props.merchant)
        let categoryList = this.state.categoryOptions;
        if (!isFdApp) categoryList = categoryList.filter(c => !c.onlyForFdProcessor)
        return this.renderSelectComponent('category', get(category, 'value', ''), categoryList, 'Banking Services', true)
    }

    render() {
        const { location, merchant } = this.props;
        if (!merchant) return null;
        const { type } = this.state;
        const { dba: merchantDba, appId } = merchant;
        const displayBankingServices = isElavon(merchant) || isFd(merchant);
        const displaySubmitOption = get(type, 'displaySubmitOption', false);

        return (
            <div id="main-div" className="l--content l--content--lrg" ref={this.topRef}>
                {!displayBankingServices ? (
                    <div className="membership__section">
                        <h3>Not found <code>{location.pathname}</code></h3>
                        <div><a href="/">&laquo; Back to homepage</a></div>
                    </div>
                ) : (
                    <Fragment>
                        <div className="header__title spc--right--auto">
                                {merchantDba && appId && (
                                <div className="spc--bottom--sml">
                                        {`${merchantDba} - ${appId}`}
                                </div>
                            )}
                            <h3>Banking Services</h3>
                        </div>
                        <Notification ref={this.notificationRef} />
                        {this.renderErrors()}
                        <div className="card--primary card--sml spc--bottom--med">
                            <div className="separator--grey1 spc--bottom--med">
                                <div className="spc--bottom--sml">
                                    <h6 className="spc--bottom--tny">Banking Service Type and Subtype</h6>
                                    <div className="message message--default">
                                        <p>Select the type of service required for the merchant, then select the service sub-type.</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col col-sml-12 col-med-4 spc--bottom--med">
                                        {this.renderCategoryOptions()}
                                    </div>
                                    <div className="col col-sml-12 col-med-4 spc--bottom--med">
                                        {this.renderSelectComponent('type', get(type, 'value', ''), this.state.typeOptions, 'Subtype', true)}
                                    </div>
                                </div>
                            </div>
                            {displaySubmitOption && (
                                <div className="separator--grey1 spc--bottom--med">
                                    <div className="spc--bottom--sml">
                                        <h6 className="spc--bottom--tny">Form Submission Options</h6>
                                        {displaySubmitOption && (
                                            <div className="message message--default">
                                                <p>Select what happens to this form when you submit this banking services request.</p>
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        {displaySubmitOption && (
                                            <div className="spc--bottom--med">
                                                {this.renderSubmitOptions()}
                                                {this.renderUploadMerchantSignature()}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                            {this.renderSubmitOptionFields()}
                            <div className="separator--grey1 spc--bottom--med">
                                <div className="row">
                                    {this.renderContactFields()}
                                </div>
                            </div>
                            {this.renderFields()}
                        </div>
                        {this.renderSubmitButton()}
                    </Fragment>
                )}
            </div>
        );
    };
};

BankingServices.propTypes = {
    isLoading: bool.isRequired,
    showLoader: func.isRequired,
};

export default withLoader(withContext(BankingServices, MerchantContext, 'merchant'));
