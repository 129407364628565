import React from 'react';
import { NavLink } from 'react-router-dom';


class NotePopup extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            note:this.props.note
        }
    }

    onSave = (e) => {
        this.props.handleChange(this.state.note);
        this.props.closeModal();
    }

    handleChange = (e) => {
        this.setState({ note: e.target.value });
    }

    render() {
        const note = this.state.note;
        return (
            <React.Fragment>
                <div className="popup__header">
                    <h6>Add Note</h6>
                </div>
                <div className="popup__body"> 
                    <div className="form__field">
                        <label className="label">Note</label>
                        <textarea className="input input--textarea" rows="5" placeholder="Type note here" value={note} onChange={this.handleChange}></textarea>
                    </div>
                </div>
                <div className="popup__footer popup__footer--styled"> 
                    <button className="btn btn--med btn--primary" onClick={this.onSave}>Save</button>
                    <a className="btn btn--med btn--ghost spc--left--tny" onClick={this.props.closeModal}>Cancel</a>
                </div>
            </React.Fragment>
        );
    }
}

export default NotePopup;