import { cloneDeep, trim } from 'lodash';

import { MPAInvalidTemplate } from './MPAInvalidTemplate.validation'
import { ascii } from './validators';

const postalCodeRegex = /^[A-Za-z]\d[A-Za-z]\s?\d[A-Za-z]\d$/;
const postalCode = val => !val || postalCodeRegex.test(val);
const length = maxLength => value => !value || trim(value).length === maxLength;

export const CanadaMPAInvalidTemplate = cloneDeep(MPAInvalidTemplate);

CanadaMPAInvalidTemplate.businessInformation.businessAddress.state.message.ascii = '[**Business Province**](javascript:void) contains invalid characters';
CanadaMPAInvalidTemplate.signerInformation.address.state.message.ascii = '[**Signer Province**](javascript:void) contains invalid characters';
CanadaMPAInvalidTemplate.corporateAddress.state.message.ascii = '[**Mailing Province**](javascript:void) contains invalid characters';
CanadaMPAInvalidTemplate.businessInformation.businessAddress.zip.message.ascii = '[**Business Postal Code**](javascript:void) contains invalid characters';
CanadaMPAInvalidTemplate.corporateAddress.zip.message.ascii = '[**Mailing Postal Code**](javascript:void) contains invalid characters';
CanadaMPAInvalidTemplate.signerInformation.address.zip.message.ascii = '[**Signer Postal Code**](javascript:void) contains invalid characters';
CanadaMPAInvalidTemplate.businessInformation.businessAddress.zip.use.postalCode = postalCode;
CanadaMPAInvalidTemplate.businessInformation.businessAddress.zip.message.postalCode = '[**Business Postal Code**](javascript:void) is not in valid format (A1A 1A1)';
CanadaMPAInvalidTemplate.corporateAddress.zip.use.postalCode = postalCode;
CanadaMPAInvalidTemplate.corporateAddress.zip.message.postalCode = '[**Mailing Postal Code**](javascript:void) is not in valid format (A1A 1A1)';
CanadaMPAInvalidTemplate.signerInformation.address.zip.use.postalCode = postalCode;
CanadaMPAInvalidTemplate.signerInformation.address.zip.message.postalCode = '[**Signer Postal Code**](javascript:void) is not in valid format (A1A 1A1)';
CanadaMPAInvalidTemplate.signerInformation.socialSecurityNumber.message.ascii =  '[**Social Insurance Number**](javascript:void) contains invalid characters';

CanadaMPAInvalidTemplate.bankAccountInformation = {
    transitNumber: {
        use: { ascii, length: length(5) },
        type: String,
        message: {
            ascii: '[**Transit Number**](javascript:void) contains invalid characters',
            length: '[**Transit Number**](javascript:void) must be 5 numbers in length',
        },
    },
    institutionNumber: {
        use: { ascii, length: length(3) },
        type: String,
        message: {
            ascii: '[**Institution Number**](javascript:void) contains invalid characters',
            length: '[**Institution Number**](javascript:void) must be 3 numbers in length',
        },
    },
};
