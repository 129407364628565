import React from "react";
import PropTypes from "prop-types";
import { HexColorPicker, HexColorInput } from 'react-colorful';
import { Toggle,ToggleContainer } from "../toggle";
import { debounce, noop } from 'lodash';
import { OutsideClick } from "../../utilities";

class ColorPickerComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            color: this.props.defaultColor,
            identifier: `${this.props.name}_color_picker`,
        }
    }

    onColorChanged = debounce((color) => {this.props.onChange(color)}, 300);

    handleColorChange = (color) =>{
        this.onColorChanged(color);
        this.setState({ color: color })
    }

    handleOutsideClick = (event, action) => {
        const { identifier } = this.state;
        let currentElement = event.target;
        while (currentElement) {
            if (currentElement.id === identifier) {
                return;
            }
            currentElement = currentElement.parentNode;
        }
        action();
    }

    handleResetColor = (event, action) => {
        event.stopPropagation();
        this.handleColorChange(this.props.defaultColor)
        action();
    }

    render() {
        const { name, className } = this.props;
        const { color, identifier } = this.state;
        return (
            <ToggleContainer>
                <Toggle initialToggle={false}>
                    {({ isToggled, handleToggle }) => (
                        <div className={className}>
                            <label className="label spc--right--sml">{name}</label>
                            <div onClick={this.props.disabled ? noop : handleToggle} className='react-colorful__wrapper' id={identifier} role="button" onKeyDown={() => {}}>
                                <div className="react-colorful__selector" style={{ backgroundColor: color }}></div>
                                <div className="react-colorful__preview">
                                    <span className="react-colorful__preview__label">Hex Color</span>
                                    <HexColorInput className="react-colorful__preview__value" prefixed={true} color={color} onChange={this.handleColorChange} />
                                    <button className="btn btn--sml btn--ghost spc--left--tny fullheight datatooltip--155" onClick={(e) => this.handleResetColor(e, isToggled ? handleToggle : noop)}
                                            data-tooltip="Reset Color To Default"><i className="icon icon--sml icon--reset" /></button>
                                </div>
                            </div>
                            {isToggled && <div className="react-colorful__picker">
                                <OutsideClick action={(event) => this.handleOutsideClick(event, handleToggle)}>
                                    <HexColorPicker color={color} onChange={this.handleColorChange} />
                                </OutsideClick>
                            </div>}
                        </div>
                    )}
                </Toggle>
            </ToggleContainer>
        );
    }
}

ColorPickerComponent.propTypes = {
    name: PropTypes.string,
    disabled: PropTypes.string,
    onChange: PropTypes.func,
    className: PropTypes.string,
    defaultColor: PropTypes.string,
};

ColorPickerComponent.defaultProps = {
    disabled: false,
};

export default ColorPickerComponent;