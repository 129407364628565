import React from 'react';
import {
	map,
	cloneDeep,
	find,
	some,
} from 'lodash';
import Menu, { SubMenu, Item as MenuItem } from 'rc-menu';

import MainFilterComponent from './MainFilter';

class SavedFilter extends MainFilterComponent {
	constructor(props) {
		super(props);
		this.state = {
			activeKeys: [],
			displayAdvancedFilter: false,
			filters: cloneDeep(props.filters),
			dates: cloneDeep(props.predefinedDates),
			isLoading: false,
			viewName: '',
			errorMessage: '',
			isSaveViewOpen: false,
			selectedView: null,
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.selectedView === this.props.selectedView) return;
		this.setState({
			selectedView: this.props.selectedView,
		});
	}

	toggleSaveView = () => {
		this.setState({
			isSaveViewOpen: !this.state.isSaveViewOpen,
		});
	};

	handleChange = ({ target: { name, value, checked, type }}) => {
		const newValue = type === 'checkbox' ? checked : value;
		this.setState({
			[name]: newValue,
		});
	};

	applyAgentView = () => {
		const { agentViews, applyAgentView } = this.props;
		const { selectedView } = this.state;
		const { viewJson } = find(agentViews, { viewName: selectedView });
		viewJson && applyAgentView(selectedView, JSON.parse(viewJson));
		this.setState({
			activeKeys: [],
		});
	};

	clearView = (clearDefault) => {
		this.props.clearAgentView(clearDefault);
		this.setState({
			selectedView: null,
		});
	};

	deleteAgentView = async viewName => {
		this.setState({ isLoading: true });
		try {
			await this.props.deleteAgentView(viewName);
			if (this.state.selectedView == viewName) this.clearView();
			this.setState({ isLoading: false });
		} catch (err) {
			this.setState({
				errorMessage: ["Error: " + err],
				isLoading: false,
			});
		}
	};

	selectView = ({ target: { name, checked } }) => {
		const selectedView = checked ? name : null;
		this.setState({
			selectedView,
		});
	};

	saveView = async () => {
		this.setState({ isLoading: true });
		try {
			await this.props.saveCurrentView(this.state.viewName);
			this.setState({ isLoading: false, isSaveViewOpen: false, viewName: '' });
		} catch (err) {
			this.setState({
				errorMessage: ["Error: " + err],
				isLoading: false,
			});
		}
	};

	renderAdditionalFilter = () => {
		const { agentViews } = this.props;
		const {
			isSaveViewOpen,
			viewName,
			isLoading,
			errorMessage,
		} = this.state;
		const isDuplicateView = some(agentViews, { viewName })
		return (
			<div className="filter__select filter__select--view spc--bottom--sml spc--right--xsml" tabIndex={0}>
				<Menu
					disabledOverflow={true}
					className="rc-menu-datepicker-tooltip rc-menu"
					mode={'horizontal'}
					motion={'slide-up'}
					triggerSubMenuAction={'click'}
					openKeys={isSaveViewOpen ? ['saveView'] : []}
					onOpenChange={this.toggleSaveView}
				>
					<SubMenu title={<span className="hide--to--xxlrg">Save View</span>} key="saveView" popupClassName="rc-menu-filter-template">
						<MenuItem key="saveView.menuItem" disabled>
							<div className="w--max--394">
								{errorMessage && <div className="spc--top--sml spc--bottom-med note note--warning" style={{ whiteSpace: 'initial' }}>{errorMessage}</div>}
								<div className="spc--bottom--tny"><b>Save View</b></div>
								<div className="spc--bottom--tny">
									<p>Includes filter settings, column sorting, column selections, </p>
									<p>reordering columns, column sizing, and display amounts</p>
								</div>
								<div className="form-group">
									<input className="input input--med" type="text" name="viewName" placeholder="Name of the view" value={viewName} onChange={this.handleChange} />
								</div>
								{isDuplicateView && (
									<div className="spc--top--sml spc--bottom--sml note note--warning">
										View "{viewName}" already exists
									</div>
								)}
								<div className="spc--top--sml spc--bottom--sml push">
									<button disabled={isLoading} className="btn btn--med btn--ghost" type="button" onClick={this.toggleSaveView}>Close</button>
									<button disabled={isLoading || isDuplicateView} className="btn btn--med btn--primary spc--left--sml" type="button" onClick={this.saveView}>Save</button>
								</div>
							</div>
						</MenuItem>
					</SubMenu>
				</Menu>
			</div>
		);
	};

	renderTopFilter = () => {
		const { agentViews, isLoadingAgentViews } = this.props;
		const { selectedView, isLoading } = this.state;
		return [
			<SubMenu mode={'vertical-left'} title={<span>My Saved Views</span>} key="savedViews">
				{isLoadingAgentViews ? (
					<MenuItem key="loading" disabled={true}>
						<span>Loading...</span>
					</MenuItem>
				) : (
					map(agentViews, ({ viewName }) => (
						<MenuItem key={viewName} disabled>
							<div className="flex--tertiary">
								<div className="flex--grow--1">
									<input id={`agentViews.${viewName}`} className="input input--check" type="checkbox" name={viewName} checked={viewName === selectedView} onChange={this.selectView} />
									<label className="fullwidth" htmlFor={`agentViews.${viewName}`}>{viewName}</label>
								</div>
								<button
									disabled={isLoading}
									onClick={() => this.deleteAgentView(viewName)}
									className="btn btn--icon"
									title="Delete"
								>
									<i className="icon icon--tiny icon--delete" />
								</button>
							</div>
						</MenuItem>
					))
				)}
				<MenuItem key="defaultView" disabled={true} className="rc-menu-abs-footer">
					<div className="rc-menu-footer rc-menu-footer-alt">
						<button type="button" onClick={this.clearView} className="btn btn--reset pull">
							Reset
						</button>
						<button
							type="button"
							disabled={!selectedView}
							onClick={this.applyAgentView}
							className="btn btn--reset anchor anchor--primary push type--uppercase type--wgt--bold"
						>
							Done
						</button>
					</div>
				</MenuItem>
			</SubMenu>,
			<hr key="separator" className="separator separator--grey1 spc--bottom--no" />
		];
	};
}

export default SavedFilter;
