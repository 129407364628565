import { _sharedFunctions } from "./_sharedFunctions";

const handleResponse = _sharedFunctions.handleResponse;
const headers = (cognitoToken) => new Headers({ 'Authorization': 'Bearer ' + cognitoToken, 'Content-Type': 'application/json' });

const getRequestOptions = (template, cognitoToken) => {
    return {
        headers: headers(cognitoToken),
        method: 'POST',
        body: JSON.stringify({...template})
    }
};

export const sendEmail = (template, cognitoToken) =>{
    try {
        const requestOptions = getRequestOptions(template, cognitoToken);
        return fetch(process.env.REACT_APP_MESSAGING_API + 'sendemail', requestOptions)
            .then(handleResponse);
    } catch (error) {
    }
}

export const sendFraudEmail = (template) =>{
    try {
        const requestOptions = getRequestOptions(template);
        return fetch(process.env.REACT_APP_MESSAGING_API + 'sendfraudemail', requestOptions)
            .then(handleResponse);
    } catch (error) {
    }
}
