import { maxLength, phoneNumber, ascii, email, numberOnly } from "./validators";
import { addressValidation } from "./address.validation";

const rep = (val, ctx) => {
    if (!ctx.hasOwnGoPlusTiers &&   ctx.lead.processor === "GoPlus") {
        return val;
    }
    return true;
}
const linked = (val, ctx) => !(ctx.lead.hasAccountWithFidelity && !val);

export const leadFormTemplate = {
    lead: {
        agentSugarId: {
            type: String,
            required: true,
            message: '[**Agent**](javascript:void) is required'
        },
        telemarketerSugarId: {
            type: String,
            use: { rep },
            message: '[**Rep 2**](javascript:void) is required'
        },
        relatedAppId: {
            type: String,
            use: {  linked, numberOnly },
            message: {
                linked: '[**Existing Account App ID**](javascript:void) is required',
                numberOnly: '[**Existing Account App ID**](javascript:void) contains invalid characters',
            }
        },
        generalInfo: {
            dba: {
                type: String,
                use: { length: maxLength(64), ascii },
                required: true,
                message: {
                    required: '[**DBA**](javascript:void) is required',
                    length: '[**DBA**](javascript:void) cannot exceed 64 characters',
                    ascii: '[**DBA**](javascript:void) contains invalid characters',
                },
            },
            phoneNumber: {
                type: String,
                use: { phoneNumber, ascii },
                message:
                {
                    phoneNumber: '[**Phone Number**](javascript:void) is invalid',
                    ascii: '[**Phone Number**](javascript:void) contains invalid characters',
                }
            },
            firstName: {
                type: String,
                use: { length: maxLength(100), ascii },
                message: {
                    length: '[**First Name**](javascript:void) cannot exceed 100 characters',
                    ascii: '[**First Name**](javascript:void) contains invalid characters',
                }
            },
            lastName: {
                type: String,
                use: { length: maxLength(100), ascii },
                message: {
                    length: '[**Last Name**](javascript:void) cannot exceed 100 characters',
                    ascii: '[**Last Name**](javascript:void) contains invalid characters',
                }
            },
            title: {
                type: String,
                use: { ascii },
                message: {
                    ascii: '[**Title**](javascript:void) contains invalid characters',
                },
            },
            email: {
                type: String,
                use: { ascii, email: email(false)},
                message: {
                    ascii: '[**Email**](javascript:void) contains invalid characters',
                    email: '[**Email**](javascript:void) is invalid',
                },
            },
            corporateName: {
                type: String,
                use: { ascii },
                message: {
                    ascii: '[**Corporate Name**](javascript:void) contains invalid characters',
                },
            },
        },
        contactInfo: {
            contactPhone: {
                type: String,
                use: { phoneNumber, ascii },
                message:
                {
                    phoneNumber: '[**Contact Phone Number**](javascript:void) is invalid',
                    ascii: '[**Contact Phone Number**](javascript:void) contains invalid characters',
                },
            },
            alternateContactName: {
                type: String,
                use: { length: maxLength(100), ascii },
                message: {
                    length: '[**Contact Name**](javascript:void) cannot exceed 100 characters',
                    ascii: '[**Contact Name**](javascript:void) contains invalid characters',
                }
            },
            contactTitle: {
                type: String,
                use: { ascii },
                message: {
                    ascii: '[**Contact Title**](javascript:void) contains invalid characters',
                },
            },
        },
        businessInfo: {
            businessFax: {
                type: String,
                use: { phoneNumber, ascii },
                message: {
                    phoneNumber: '[**Business Fax**](javascript:void) must be 10 characters long.',
                    ascii: '[**Business Fax**](javascript:void) contains invalid characters',
                }
            },
            businessAddress: addressValidation('', 128, 100, 'Street '),
            website: {
                type: String,
                use: { length: maxLength(64), ascii },
                message: {
                    length: '[**Website**](javascript:void) is invalid. Maximum number of characters allowed is 64.',
                    ascii: '[**Website**](javascript:void) contains invalid characters',
                }
            }
        },
        addlInfo: {
            custom01: {
                type: String,
                use: { ascii },
                message: {
                    ascii: '[**Custom 01**](javascript:void) contains invalid characters',
                },
            },
            notes: {
                type: String,
                use: { ascii },
                message: {
                    ascii: '[**Note**](javascript:void) contains invalid characters',
                },
            },
        },
    }
}