import React, { Component, Fragment } from 'react';
import {
	each,
	isEmpty,
	map,
	find,
	toLower,
	sumBy,
	split,
	filter,
	some,
	flatMap,
	get,
	noop,
	first,
	includes,
} from 'lodash';
import { object, func, array, string, bool } from 'prop-types';

import { Modal } from '../../common/components/modal';
import { defaultImplicitParse, defaultReactOutput } from 'simple-markdown';
import { Toggle, ToggleContainer } from './../../common/components/toggle';
import { modalNames } from '../../common/components/modal-wrapper/modal-names';
import { ModalWrapper } from '../../common/components/modal-wrapper';
import { withRouter } from 'react-router';
import { renderEquipmentOption } from '../../common/components/equipmentOption/renderEquipmentOption';
import { OutsideClick } from '../../common/utilities';
import { _eappFunctions } from '../../services/_eappFunctions';

const isExpand = false;
const incompleteCross = elem => <React.Fragment>{elem}</React.Fragment>

class MerchantEquipmentFooter extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isItemsModalOpen: false,
			modal: {
				name: modalNames.none,
				data: null,
			},
			deleteItemModal: {
				isOpen: false,
				item: null,
			},
			errorMessage: ''
		};
	}

	removeEquipment = (equipmentId) => {
		const { merchantEquipment } = this.props;
		const item = find(merchantEquipment, { equipmentId });
		const accessories = get(item, 'accessories', []);

		if (!isEmpty(accessories)) {
			each(accessories, (_, index) => {
				accessories[index] = {
					...accessories[index],
					quantity: 0,
					isSelected: false,
				};
			});
		}

		this.props.handleEquipmentChange({
			...item,
			isSelected: false,
			quantity: item.parentEquipmentId ? 0 : 1,
			accessories,
		});
	};

	findEquipmentTab = (equipmentId, category) => {
		const { hardwareList, gatewayList, softwareList } = this.props;

		if (toLower(category).indexOf('accessories') > -1) {
			return 'accessories';
		} else if (toLower(category).indexOf('pos') > -1) {
			return 'posSystems';
		} else if (find(gatewayList, g => g.equipmentId == equipmentId)) {
			return 'gateways';
		} else if (find(softwareList, s => s.equipmentId == equipmentId)) {
			return 'software';
		} else if (find(hardwareList, h => h.equipmentId == equipmentId)) {
			return 'hardware';
		} else {
			return 'other';
		}
	};

	openAddonsModal = parentEquipmentId => {
		const { history, appId, isPopup } = this.props;

		if (isPopup) {
			this.props.updateState({ modal: { name: modalNames.none, data: null } }, () => {
				history.replace({ disableRefresh: true, state: { openAddonsModal: true, parentEquipmentId } });
			});
		} else {
			history.push({
				pathname: `/merchants/${appId}/equipment`,
				state: { openAddonsModal: true, parentEquipmentId },
			});
		}
	};

	displayItem = (equipmentId, category, parentEquipmentId) => () => {
		const { updateState, isPopup } = this.props;

		this.closeItemsModal();

		if (parentEquipmentId) {
			this.openAddonsModal(parentEquipmentId);
		} else {
			if (isPopup) {
				const { history, appId } = this.props;

				history.push({
					pathname: `/merchants/${appId}/equipment-new`,
					state: {
						openSelectedEquipment: true,
						currentTab: this.findEquipmentTab(equipmentId, category),
						selectedEquipmentId: equipmentId
					},
				});
			} else {
				updateState({ currentTab: this.findEquipmentTab(equipmentId, category), selectedEquipmentId: equipmentId });
			}
		}
    }

	adjustQuantity = ({ target: { name } }) => {
		const [equipmentId, action] = split(name, '.');
		const { merchantEquipment } = this.props;
		const item = find(merchantEquipment, { equipmentId });
		let diff = item.quantity > 0 ? -1 : 0;

		if (action === 'increase') {
			diff = 1;
		}

		const changedItem = { ...item, quantity: item.quantity + diff };

		if (changedItem.quantity < 1) {
			this.openDeleteItemModal(changedItem);
		} else {
			this.props.handleEquipmentChange(changedItem);
		}
	};

	removeItem = () => {
		const { merchantEquipment } = this.props;
		const { deleteItemModal: { item } } = this.state;
		const newItem = { ...item };

		newItem.isSelected = false;

		this.props.handleEquipmentChange(newItem);

		const newState = { deleteItemModal: { isOpen: false, item: null } };

		if (!some(merchantEquipment, e => e.isSelected && e.equipmentId != newItem.equipmentId)) {
			newState.isItemsModalOpen = false;
		}

		this.setState(newState);
	};

	openDeleteItemModal = item => {
		this.setState({ deleteItemModal: { isOpen: true, item } });
	};

	closeDeleteItemModal = () => this.setState({ deleteItemModal: { isOpen: false, item: null } });

	openCloseModal = modal => this.setState({ modal });

	openItemsModal = () => {
		if (some(this.props.merchantEquipment, ({ isSelected, quantity }) => isSelected && quantity)) {
			this.setState({ isItemsModalOpen: true });
		}
	};

	closeItemsModal = () => this.setState({ isItemsModalOpen: false });

	openCloseAddonsModal = (masterId, parentEquipmentId, parentEquipmentName, parentPlanName, existingAddons) => () => {
		this.openCloseModal({
			name: modalNames.gatewayAddons,
			data: {
				masterId,
				parentEquipmentId,
				parentEquipmentName,
				parentPlanName,
				gatewayList: this.props.equipmentList,
				merchantEquipment: this.props.merchantEquipment,
				handleChange: this.props.handleEquipmentChange,
				existingAddons,
			},
		});
	};

	toggleSelect = ({ target: { value, name, checked, type } }, saveEquipmentToState = noop) => {
		const { merchantEquipment } = this.props;
		const [equipmentId, action] = split(name, '.');
		const val = type === 'checkbox' ? checked : value;
		let item = find(merchantEquipment, { equipmentId });

		if (!item) {
			item = this.props.createNewEquipment(equipmentId, true, true);
		}

		item[action] = val;

		if (action === 'isSelected' && !item.isSelected) {
			item.quantity = 0;
		} else {
			item.quantity = 1;
		}

		saveEquipmentToState(equipmentId, item);
		this.props.handleEquipmentChange(item);
	};

	getFeesInTotalPrice = (equipmentItem) => {
		let fees = equipmentItem.fees.map((fee) => {
			if (equipmentItem.category != 'Gateway' && Object.keys(fee.dependencySettings || {}).length > 0) {
				if (!!equipmentItem.equipmentOptions) {
					let dependentOption = Object.keys(equipmentItem.equipmentOptions).find((o) =>
						Object.keys(fee.dependencySettings).includes(o)
					);
					if (
						dependentOption &&
						fee.dependencySettings[dependentOption].includes(equipmentItem.equipmentOptions[dependentOption])
					) {
						return { ...fee, includeInTotal: true };
					}
				}
			} else if (toLower(equipmentItem.purchaseType) === 'rental' && includes(['weeklyfee', 'monthlyfee'], toLower(fee.feeType))) {			
				return fee;
			} else if (fee.feeType === 'OneTimeFee') return fee;
		});
		return fees.filter((f) => !!f);
	};

	calculateEquipmentFee(fees, quantity, purchaseType, paymentSchedule){
		return sumBy(
			fees,
			({ merchantPrice, agentCost, feeType, includeInTotal }) =>
				((toLower(purchaseType) === 'rental' && includes(['weeklyfee', 'monthlyfee'], toLower(feeType))) ||
					toLower(feeType) === 'onetimefee' || includeInTotal) && (paymentSchedule === "BillAgent" ? agentCost : merchantPrice) * quantity
		);
	}
	
	calculateAccessoryFee(accessories, paymentSchedule){
		return sumBy(accessories, ({ quantity, fees }) => {
			if (fees) {
				const fee = find(fees, {
					feeType: 'OneTimeFee',
				});
				return (paymentSchedule === "BillAgent" ? fee.agentCost : (fee.merchantPrice || fee.retailPrice)) * quantity;
			}
			return 0;
		})
	}

	calculateItemTotalPrice = (equipmentId, returnFormatted = false) => {
		const item = find(this.props.merchantEquipment, { equipmentId });
		const { quantity, paymentSchedule, purchaseType } = item;
		const fees = [...this.getFeesInTotalPrice(item), ...flatMap(item.subequipment, 'fees')];

		let equipmentTotal = this.calculateEquipmentFee(fees, quantity, purchaseType, paymentSchedule)
		const accessoriesTotal = this.calculateAccessoryFee(item.accessories, paymentSchedule) || 0;

		if(returnFormatted){
			return `$${(equipmentTotal + accessoriesTotal || 0).toFixed(2)}`;
		}

		return equipmentTotal + accessoriesTotal;
	};

	calculateTotalPrice = () => {
		let total = 0;

		each(this.props.merchantEquipment, (item) => {
			let { isSelected, equipmentId } = item;
			if (isSelected) {
				total += this.calculateItemTotalPrice(equipmentId)
			}
		});

		return `$${(total || 0).toFixed(2)}`;
	};

	checkIfEquipmentLocallyComplete = () => {
		const { isValid, existingMerchantEquipment } = this.props;

		return existingMerchantEquipment && existingMerchantEquipment.length && isValid;
	};

	handleSave = (openIncompletePopup = false) => e => {
		const { appStatus, displayMissingFieldsNotification } = this.props;
		const isSetupFormComplete = appStatus.isSetupFormComplete;
        const incompleteDocs = filter(get(appStatus, 'requiredDocuments'), doc => !doc.isComplete);
		const notificationMessage = (openIncompletePopup && (!isEmpty(incompleteDocs) || !isSetupFormComplete) && displayMissingFieldsNotification) ?
			(
				<div>
					<h3 className="popup__header__title spc--bottom--sml">Your information was saved successfully.</h3>
					<div>
						{(!isEmpty(incompleteDocs) || !isSetupFormComplete) && (
							<Fragment>
								<p className="message message--default spc--bottom--sml">Please note that you're still missing the following to complete this application:</p>
								{!isEmpty(incompleteDocs) && (
									<h3 className="popup--notification--error"><span className="type--wgt--bold">MPA</span> is incomplete.</h3>
								)}
								{!isSetupFormComplete && incompleteCross(<h3 className="popup--notification--error"><span className="type--wgt--bold">Setup Form</span> is incomplete.</h3>)}
							</Fragment>
						)}
					</div>
				</div>
			) : null;

		this.props.handleSave(e, false, notificationMessage);
	};

	renderSavedEquipmentSidebar = () => {
		const { existingMerchantEquipment, gatewayList, isEApp } = this.props;
		const { errorMessage } = this.state;
		return (
			<ToggleContainer>
				<Toggle initialToggle={isEApp && !isEmpty(existingMerchantEquipment)}>
					{({ isToggled, handleToggle }) => (
						<OutsideClick action={() => handleToggle(false)}>
							<div className="hardware__footer__item hardware__footer__item--button hardware__footer__item--saved spc--right--sml">
								<div
									className="btn btn--med btn--primary--alt datatooltip--right"
									data-tooltip="Previously Saved Equipment"
									onClick={() => {
										this.setState({ errorMessage: '' });
										handleToggle()
									}}
								>
									Saved Equipment&nbsp;
									<span className="spc--right--sml">{existingMerchantEquipment && `(${existingMerchantEquipment.length})`}</span>
									<i
										className={`icon icon--tiny icon--arrow--down--primary spc--left--tny ${
											isToggled ? 'rotate--180' : ''
										}`}
									></i>
								</div>
								<div className={isToggled ? 'hardware__saved' : 'hardware__saved hidden'}>
									<h6 className="hardware__saved__title  padd--bottom--sml">Saved Equipment</h6>
									{errorMessage && 
										<div className="note note--warning" >{errorMessage}</div>
									}
									<div className="table--eq__wrap">
										<table className="table--eq">
											<thead>
												<tr>
													<th className="w--36p"></th>
													<th>Name</th>
													<th className="type--right">Qty.</th>
													{isEApp && <th className="w--42p"></th>}
												</tr>
											</thead>
											<tbody>
												{map(
													filter(existingMerchantEquipment, ({ parentEquipmentId }) => !parentEquipmentId),
													({ id, equipmentId, quantity, categoryDescription, isActiveEquipment, name, planName }) => {
														const subequipment = get(
															find(gatewayList, {
																equipmentId: String(equipmentId),
															}),
															'subequipment'
														);
														const equipmentAddons = filter(
															existingMerchantEquipment,
															({ parentEquipmentId }) => parentEquipmentId == id
														);

														return (
															<tr key={id}>
																<td>
																	{categoryDescription !== 'Gateway' && (
																		<div
																			className="checkout__img checkout__img__thumb checkout__img__thumb--36"
																			style={{
																				backgroundImage:
																					'url(' +
																					process.env.REACT_APP_CDN_URL +
																					toLower(name)
																						.replace(' ', '_')
																						.replace(/[^a-z0-9_-]/gi, '') +
																					'/thumbnail.png' +
																					')',
																			}}
																			alt={name}
																		></div>
																	)}
																</td>
																<td>
																	{categoryDescription ? `${categoryDescription} - ` : ''}
																	{name} {isActiveEquipment || '(disabled)'}
																	{isActiveEquipment && !isEmpty(subequipment) && (
																		<a
																			type="button"
																			className="btn btn--clear type--sml spc--left--sml align--v--middle"
																			onClick={this.openCloseAddonsModal(
																				id,
																				equipmentId,
																				name,
																				planName,
																				equipmentAddons
																			)}
																		>
																			<i className="icon icon--tiny icon--middle icon--add--primary spc--right--nano"></i>
																			Add-on
																		</a>
																	)}
																	{!isEmpty(equipmentAddons) && (
																		<ul className="hardware__saved__option">
																			{map(equipmentAddons, addon => (
																				<li key={`${addon.id}.${addon.equipmentId}.${addon.name}`} className="hardware__saved__option__item">
																					<span className="type--sml type--wgt--sml spc--right--sml">{addon.name}</span>
																					<span className="type--sml type--wgt--sml">{addon.quantity}</span>
																				</li>
																			))}
																		</ul>
																	)}
																</td>

																<td className="type--right">
																	{categoryDescription === 'Gateway' ? (
																		<React.Fragment> - </React.Fragment>
																	) : (
																		<React.Fragment>{quantity}</React.Fragment>
																	)}
																</td>
																{isEApp &&
																	<td className="type--right">
																		<button className="btn btn--sml btn--clear" onClick={this.cancelEquipment(id)}>
																			<i className="icon icon--xxsml icon--delete"></i>
																		</button>
																	</td>
																}
															</tr>
														);
													}
												)}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</OutsideClick>
					)}
				</Toggle>
			</ToggleContainer>
		);
	};

	cancelEquipment = (equipmentId) => () => {
		this.props.showMainLoader(true);
		_eappFunctions.cancelEquipment(this.props.appId, equipmentId)
			.then(() => {
                this.props.reloadExistingEquipment();
				this.props.showMainLoader(false);
			})
            .catch((err) => {
				console.log(err);
				this.setState({ errorMessage: 'Error deleting equipment!' });
				this.props.showMainLoader(false);
			});
	}

	handleChange = (equipmentId, equipmentOptions) => ({ target: { name, value } }) => {
		const { merchantEquipment } = this.props;
		const item = find(merchantEquipment, e => e.equipmentId == equipmentId);

		if (name === 'quantity') {
			item.quantity = parseInt(value);
		} else {
			const [section, key] = split(name, '_');
			item[section] = {
				...item[section],
				[key]: value,
			};

			const option = find(equipmentOptions, o => toLower(o.name).indexOf(`${toLower(value)} quantity`) > -1);
			item.quantity = parseInt(get(first(option.options), 'value'));
		}

		this.props.handleEquipmentChange(item);
	};

	renderSelectLabel = (isSelected, equipmentId) => (
		<label htmlFor={`${equipmentId}.isSelected`}>{`Select${isSelected ? 'ed' : ''}`}</label>
	);

	renderAdjustQuantityModule = ({
		allowMultiple,
		equipmentId,
		isSelected,
		quantity,
		isPaper,
	}) => {
		const { merchantEquipment, findEquipment } = this.props;
		const merchantAccessories = find(merchantEquipment, e => e.equipmentId == equipmentId);
		const equipmentOptions = get(findEquipment(equipmentId, false, true), 'equipmentOptions');

		return allowMultiple === false ? (
					<div className="gateway__list__radio-button__wrapper">
						<input
							className="gateway__list__radio-button"
							type="checkbox"
							name={`${equipmentId}.isSelected`}
							id={`${equipmentId}.isSelected`}
							value={equipmentId}
							checked={isSelected}
							onChange={this.toggleSelect}
						/>
						{this.renderSelectLabel(isSelected, equipmentId)}
					</div>
				) : (
					<div className="inputgroup">
						{isPaper ? (
							<div className="inputgroup--main">
								<div className="flex--primary">
									<div className="flex--grow--1">
										{renderEquipmentOption('CaseOrRoll', equipmentOptions, merchantAccessories, this.handleChange(equipmentId, equipmentOptions))}
									</div>
									<div className="flex--grow--1">
										{renderEquipmentOption('Quantity', equipmentOptions, merchantAccessories, this.handleChange(equipmentId, equipmentOptions))}
									</div>
								</div>
							</div>
						) : (
							<Fragment>
								<div className="inputgroup--aside">
									<button
										type="button"
										className="btn btn--handler spc--right--1"
										name={`${equipmentId}.decrease`}
										onClick={this.adjustQuantity}
									>
										&#8211;
									</button>
								</div>
								<div className="inputgroup--main inputgroup--main--double">
									<input
										className="input input--med type--center"
										disabled
										type="number"
										min="1"
										name="quantity"
										value={quantity}
									/>
								</div>
								<div className="inputgroup--aside inputgroup--aside--double">
									<button
										type="button"
										className="btn btn--handler spc--left--1"
										name={`${equipmentId}.increase`}
										onClick={this.adjustQuantity}
									>
										+
									</button>
								</div>
							</Fragment>
						)}
					</div>
				)
	};

	renderMerchantEquipment = () => {
		const { merchantEquipment, findEquipment, existingMerchantEquipment } = this.props;

		return map(
			merchantEquipment,
			({
				equipmentId,
				quantity,
				isSelected,
				purchasePlanId,
				errors,
				fees,
				subequipment,
				parentEquipmentId,
				allowMultiple,
				equipmentOptions,
			}) => {
				const isSubequipment = !!parentEquipmentId;
				let item;
				if (isSubequipment) {
					const parentEquipment = find(existingMerchantEquipment, { id: parentEquipmentId });
					if (!parentEquipment) return;
					item = findEquipment(equipmentId, true, false, parentEquipment.equipmentId);
				} else {
					item = findEquipment(equipmentId);
				}

				if (!isSelected || !item) {
					return;
				}
				const { name, category, purchasePlans, subcategory } = item;

				let plan;

				if (category === 'Gateway') {
					plan = find(purchasePlans, { planId: parseInt(purchasePlanId, 10) });
				}

				let errorListPaths = errors && errors.length > 0 && errors.map((e) => e.path);
				const isPaper = toLower(subcategory) === 'paper';

				return (
					<Fragment key={equipmentId}>
						<div className="row f--a--c separator--grey1 spc--bottom--sml">
							<div className="col col-sml-12 col-lrg-5 spc--bottom--sml">
								<label className="hardware__items__label">Name</label>
								<div className="display--f f--a--c">
									{category !== 'Gateway' && (
										<div
											className="checkout__img checkout__img__thumb checkout__img__thumb--70 hardware__items__thumbnail"
											style={{
												backgroundImage:
													'url(' +
													process.env.REACT_APP_CDN_URL +
													toLower(name)
														.replace(' ', '_')
														.replace(/[^a-z0-9_-]/gi, '') +
													'/thumbnail.png' +
													')',
											}}
											alt={name}
										></div>
									)}

									<span
										className="type--wgt--bold type--med type--clickable"
										onClick={this.displayItem(equipmentId,category, parentEquipmentId)}
									>
										{name}
										{plan && ` ${plan.planName}`}
									</span>
								</div>
							</div>
							<div className="col col-sml-12 col-lrg-3 spc--bottom--sml">
								<label className="hardware__items__label">Quantity</label>
								{category === 'Gateway' ? (
									<div className="spc--right--xxxlrg">-</div>
								) : (
									this.renderAdjustQuantityModule({
										allowMultiple,
										equipmentId,
										isSelected,
										quantity,
										isPaper,
										equipmentOptions
									})
								)}
							</div>
							<div className="col col-sml-12 col-lrg-4 spc--bottom--sml">
								<label className="hardware__items__label">Total price</label>
								<div className="display--f f--a--c f--j--sb">
									<span className="type--wgt--bold type--med">{this.state.isItemsModalOpen && this.calculateItemTotalPrice(equipmentId, true)}</span>
									<button className="btn btn--med btn--ghost" onClick={() => this.removeEquipment(equipmentId)}>
										<i className="icon icon--tiny icon--delete--primary icon--middle"></i>
									</button>
								</div>
							</div>
						</div>
						{errorListPaths && (
							<div className="note note--warning spc--bottom--sml">
								<ul>
									{' '}
									{errors.map((elem, i) => {
										let errorPrefix = '';
										let pathArr = elem.path.split('.');
										if (pathArr[0] == 'fees') {
											if(fees.length > 0 && fees[pathArr[1]])
												errorPrefix = fees[pathArr[1]].feeName;
										} else if (pathArr[0] == 'subequipment') {
											let equipId = subequipment[pathArr[1]].equipmentId;
											errorPrefix = item.subequipment.find((s) => s.equipmentId == equipId).name;
										}
										errorPrefix = errorPrefix && '**' + errorPrefix + '**: ';
										return (
											<li key={i}>
												<i className="icon icon--nano icon--text-top icon--alert spc--right--tny"></i>
												{defaultReactOutput(defaultImplicitParse(errorPrefix + elem.message))}
											</li>
										);
									})}
								</ul>
							</div>
						)}
					</Fragment>
				);
			}
		);
	};

	renderItemsModal = () => {
		const { isItemsModalOpen } = this.state;

		return (
			<Modal isOpen={isItemsModalOpen} onClose={this.closeItemsModal} className="popup__content popup__content--xlrg">
				<div>
					<div className="popup__header">
						<h6>Cart</h6>
					</div>
					<div className="popup__body popup__body--xlrg">
						<div className="row spc--bottom--sml hardware__items__label__header">
							<div className="col col-sml-12 col-lrg-5">
								<span className="type--sml type--wide type--wgt--semibold type--uppercase type--color--text--light">
									Name
								</span>
							</div>
							<div className="col col-sml-12 col-lrg-3">
								<span className="type--sml type--wide type--wgt--semibold type--uppercase type--color--text--light">
									Quantity
								</span>
							</div>
							<div className="col col-sml-12 col-lrg-4">
								<span className="type--sml type--wide type--wgt--semibold type--uppercase type--color--text--light">
									Total price
								</span>
							</div>
						</div>
						<div>{this.renderMerchantEquipment()}</div>
					</div>
				</div>
			</Modal>
		);
	};

	renderDeleteItemModal = () => {
		const { deleteItemModal: { isOpen, item } } = this.state;

		return (
			<Modal
				isOpen={isOpen}
                onClose={this.closeDeleteItemModal}
                className="popup__content"
            >
                <div>
                    <div className="popup__body">
						<h6>Are you sure you want to remove {get(item, 'name', 'this item')}?</h6>
                    </div>
                    <div className="popup__footer popup__footer--styled">
                        <button
                            className="btn btn--ghost btn--med spc--left--tny"
                            onClick={this.closeDeleteItemModal}
                        >
                            No
                        </button>
						<button
                            className="btn btn--primary btn--med spc--left--tny"
                            onClick={this.removeItem}
                        >
                            Yes
                        </button>
                    </div>
                </div>
            </Modal>
		);
	};

	render() {
		const {
			merchantEquipment,
			isValid,
			appStatus: { isReadyToSubmit, isMpaComplete, isSetupFormComplete, isEquipmentComplete },
			dirty,
			isEApp,
			isGoPlus,
			isLoading,
			isPopup,
		} = this.props;
		const items = filter(merchantEquipment, ({ isSelected }) => isSelected);
		const buttonsDisabled = (!this.checkIfEquipmentLocallyComplete() && !dirty) || isLoading || !isValid;
		const saveDisabled =
			buttonsDisabled ||
			!isMpaComplete ||
			!isSetupFormComplete ||
			(!isReadyToSubmit
				? isMpaComplete &&
				  isSetupFormComplete &&
				  !isEquipmentComplete &&
				  this.checkIfEquipmentLocallyComplete()
				: !isReadyToSubmit);
		const saveButtonTooltip = isLoading ? 'Saving in progress...' : null;

		return (
			<ToggleContainer>
				<Toggle initialToggle={isExpand}>
					{({ isToggled, handleToggle }) => (
						<div className={`hardware__footer ${isPopup ? 'hardware__footer--modal' : ''} ${isToggled ? '' : 'is-collapsed'}`}>
							<ModalWrapper modal={this.state.modal} onModalClose={this.openCloseModal} />
							<div className="hardware__footer__expand__wrapper" onClick={handleToggle}>
								<div className="hardware__footer__expand">
									<i className="icon icon--tiny icon--arrow--down--primary"></i>
								</div>
							</div>
							<div className="hardware__footer__body">
								<div className="hardware__footer__item--title">
									{this.renderSavedEquipmentSidebar()}
									<div className="hardware__footer__item type--wgt--bold">
										{this.renderItemsModal()}
										{this.renderDeleteItemModal()}
									</div>
								</div>
								<div className="flex--center">
									<div className="hardware__footer__item__border"></div>
									<div className="hardware__footer__item cursor--pointer" onClick={this.openItemsModal}>
										<i className="icon icon--xsml icon--shop-basket align--v--sub spc--right--xsml"></i>
										<a className="anchor anchor--primary">Cart</a>
										<span className="type--wgt--bold spc--left--tny">
											({sumBy(items, (item) => get(item, 'quantity', 1)) || 0})
										</span>
									</div>
									<div className="hardware__footer__item__border"></div>
									<div className="hardware__footer__item">
										Total Price{' '}
										<span className="display--ib type--wgt--bold spc--left--tny">
											{this.calculateTotalPrice()}
										</span>
									</div>
									<div className="hardware__footer__item__border"></div>
									{!isValid && (
										<div className="hardware__footer__item datatooltip--base spc--right--sml">
											<i
												className="icon icon--med icon--notifications--warning"
												data-tooltip="Information is missing for one or more items in your cart"
											></i>
										</div>
									)}
								</div>
								<div className="flex--center">
									<div className="hardware__footer__item hardware__footer__item--button">
										<button
											data-tooltip={saveButtonTooltip}
											className="btn btn--med btn--primary"
											onClick={this.handleSave(!isGoPlus && (!buttonsDisabled || dirty))}
											disabled={buttonsDisabled || !dirty}
										>
											{isEApp ? 'Save' : 'Submit'}
										</button>
									</div>
									{isEApp && !isGoPlus && (
										<div className="hardware__footer__item hardware__footer__item--button">
											<button
												data-tooltip={saveButtonTooltip}
												className="btn btn--med btn--primary"
												onClick={(e) => this.props.handleSave(e, true)}
												disabled={saveDisabled}
											>
												Save and Next Step
											</button>
										</div>
									)}
								</div>
							</div>
						</div>
					)}
				</Toggle>
			</ToggleContainer>
		);
	}
}

MerchantEquipmentFooter.propTypes = {
	appId: string.isRequired,
	merchantEquipment: array.isRequired,
	existingMerchantEquipment: array.isRequired,
	gatewayList: array.isRequired,
	isValid: bool.isRequired,
	appStatus: object.isRequired,
	isEApp: bool.isRequired,
	isGoPlus: bool,
	dirty: bool.isRequired,
	isLoading: bool.isRequired,
	setErrorState: func.isRequired,
	handleEquipmentChange: func.isRequired,
	createNewEquipment: func.isRequired,
	updateState: func.isRequired,
	handleSave: func.isRequired,
	isPopup: bool,
	displayMissingFieldsNotification: bool,
	reloadExistingEquipment: func,
	showMainLoader: func
};

export default withRouter(MerchantEquipmentFooter);
