import React, { Component } from 'react';

class StatusSelectionComponent extends Component {
    filterText = () => {
        const values = this.props.filter.values;

        let textElements = [];

        const indexOfActive = textElements.indexOf('Active');
        const indexOfInactive = textElements.indexOf('Inactive');
        const indexOfSeasonalHold = textElements.indexOf('SeasonalHold');
        const indexOfInReview = textElements.indexOf('InReview');
        const indexOfConditionallyApproved = textElements.indexOf('ConditionallyApproved');
        const indexOfPending = textElements.indexOf('Pending');
        const indexOfIncomplete = textElements.indexOf('Incomplete');

        if (values.Active && indexOfActive === -1) {
            textElements.push('Active');
        }
        if (values.Inactive && indexOfInactive === -1) {
            textElements.push('Inactive');
        }
        if (values.SeasonalHold && indexOfSeasonalHold === -1) {
            textElements.push('Seasonal Hold');
        }
        if (values.InReview && indexOfInReview === -1) {
            textElements.push('In Review');
        }
        if (values.ConditionallyApproved && indexOfConditionallyApproved === -1) {
            textElements.push('Conditionally Approved');
        }
        if (values.Pending && indexOfPending === -1) {
            textElements.push('Pending');
        }
        if (values.Incomplete && indexOfIncomplete === -1) {
            textElements.push('Incomplete');
        }

        return textElements.join(', ');
    };

    filterRemoved = () => {
        this.props.onFilterRemoved({
            id: 'merchantStatus',
            emptyValue: false
        })
    }

    render() {
        return (
            <div className="filter__tag">
                {this.filterText()}
                <button onClick={this.filterRemoved} className="btn btn--reset filter__tag__clear"></button>
            </div>
        );
    }
}

export default StatusSelectionComponent;