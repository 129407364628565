//import config from 'config';
import { authHeader } from '../helpers/auth-header';
import { _sharedFunctions } from './_sharedFunctions';
import { removeProperties } from '../common/utilities';


const handleResponse = _sharedFunctions.handleResponse;
const appRequestHeaders = _sharedFunctions.appRequestHeaders;

export const _leadFunctions = {
    getLeads,
    getLeadDetails,
    deleteLeadFile,
    saveAccountDetails,
    saveLeadDetails,
    saveLeadFiles,
    requestPricingAnalysis,
    convertLeadToApp,
    archiveLead,
    unarchiveLead,
    getPricingAnalysisSummary,
    getTierDetails
};

function getLeads(leadFilter, rowsPerPage, activePage) {
    const pageNumber = activePage || 1;
    const pageSize = rowsPerPage || 20;
    const requestBody = JSON.stringify({
        LeadFilter: leadFilter,
        PageSize: pageSize,
        PageNumber: pageNumber
    });
    
    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/agent/GetAgentLeads' }).then(handleResponse);
}


function getLeadDetails(leadId) {
    const requestBody = JSON.stringify(leadId);

    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/lead/GetAccountDetails' })
        .then(handleResponse)
        .then(
            apilead => {
                //console.log(apilead);
                let lead = {
                    ...apilead,
                    addlInfo: {
                        notes: apilead.notes,
                        custom01: apilead.custom01,
                        files: apilead.files,
                        label: apilead.label,
                        fileDescriptions: []
                    },
                    contactInfo: {
                        alternateContactName: apilead.alternateContactName,
                        contactTitle: apilead.contactTitle,
                        contactPhone: apilead.contactPhone,
                    },
                    generalInfo: {
                        title: apilead.title,
                        firstName: apilead.firstName,
                        lastName: apilead.lastName,
                        phoneNumber: apilead.phoneNumber,
                        email: apilead.email,
                        dba: apilead.dba,
                        leadStatus: apilead.leadStatus,
                        canUpdateStatus: apilead.canUpdateStatus,
                        statusOptions: apilead.statusOptions,
                        corporateName: apilead.corporateName
                    }
                };
                return lead;
            }
        );
}

function deleteLeadFile(leadId, fileId) {
    const requestBody = JSON.stringify({ parentId: leadId, noteId: fileId });

    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/lead/DeleteFile' }).then(handleResponse);
}

function generateFileKey() {
    const arr = new Uint8Array(5);
    window.crypto.getRandomValues(arr);
    return Array.from(arr, dec2hex).join('');
}
function dec2hex(dec) {
    return dec.toString(16).padStart(2, "0");
}

function saveFileList(leadId, files, fileInfo) {
    if (!files || !Array.isArray(files) || !fileInfo || !Array.isArray(fileInfo)) return;
    const promises = [];

    for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fileKey = `${generateFileKey()}.${file.name.substring(file.name.lastIndexOf('.') + 1)}`;
        let url, key, iv;
        promises.push(
            getFileUploadData(fileKey, file.type)
                .then((resp) => {
                    ({ url, key, iv } = resp)
                    return _sharedFunctions.encryptFile(file, key, iv)
                })
                .then((encryptedFile) => {
                    return fetch(url, {
                        method: 'PUT',
                        headers: { 'Content-Type': file.type },
                        body: encryptedFile
                    })
                })
                .then((result) => {
                    if (result.status == "200") {
                        let request = JSON.stringify({ parentId: leadId, fileName: file.name, fileTag: fileInfo[i].fileTag, fileDescription: fileInfo[i].description, fileKey: fileKey, encryptionKey: key });
                        return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: request, action: '/lead/TransferFile' }).then(handleResponse);
                    }
                })
                .catch(err => {
                    console.log('Error uploading file', err)
                    //return rejected promise
                })
        )
    }
    return promises;
}

function saveLeadFiles(leadId, fileList) {
    let files = [];
    let fileInfo = [];

    Object.keys(fileList).map(function (keyName, keyIndex) {
        fileInfo.push.apply(fileInfo, fileList[keyName].map((f, i) => { return { fileTag: keyName, description: f.fileDescription }; }));
        files.push.apply(files, fileList[keyName].map((f, i) => { return f.file; }));
    });
    return Promise.all(saveFileList(leadId, files, fileInfo));
    //let formData = _sharedFunctions.objectToFormData({ parentId: leadId, files, fileInfo });
    // header method is authheaders because content type is not json
    //return _sharedFunctions.callApi({ headerMethod: authHeader, body: formData, action: '/lead/UploadFiles' }).then(handleResponse);    
}

function getFileUploadData(fileName, contentType) {
    const requestBody = JSON.stringify({ fileName, contentType })
    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/merchant/GetFileUploadData' }).then(handleResponse);
}

function saveLeadDetails(updatedLead) {
    // change to call saveLeadFiles separately to upload the files
    let files = updatedLead.addlInfo.fileUploads ? updatedLead.addlInfo.fileUploads.map((fileEntry, i) => { return fileEntry.file; }) : null;
    let descriptions = updatedLead.addlInfo.fileUploads ? updatedLead.addlInfo.fileUploads.map((fileEntry, i) => { return { description: fileEntry.fileDescription, fileTag: fileEntry.fileTag }; }) : null;
    delete updatedLead.addlInfo.fileUploads; // don't copy files over to lead object

    let lead = {
        ...updatedLead,
        ...updatedLead.addlInfo,
        ...updatedLead.generalInfo,
        ...updatedLead.contactInfo,
    };
    delete lead.addlInfo;
    delete lead.generalInfo;
    delete lead.contactInfo;
    delete lead.files; // no need to pass back list of files that were already there - only actual uploads, which go into FileUpload

    // header method is authheaders because content type is not json
    return saveAccountDetails(lead)
        .then(async apilead => {
            if (apilead && apilead.leadId) {
                if (files) await Promise.all(saveFileList(apilead.leadId, files, descriptions))
                return apilead;
            }
            else {
                console.log(apilead);
                return '';
            }
        })
        .then(apilead => { return apilead && apilead.leadId ? apilead.leadId : ''; })
}

function saveAccountDetails(data) {
    let formData = _sharedFunctions.objectToFormData({
        lead: removeProperties(data, ['refNum', 'status'])
    });

    return _sharedFunctions.callApi({ headerMethod: authHeader, body: formData, action: '/lead/SaveAccountDetails' })
    .then(handleResponse)
}

function requestPricingAnalysis(reviewPricing) {
    const requestBody = JSON.stringify(reviewPricing);

    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/lead/RequestPricingAnalysis' }).then(handleResponse);
}

function convertLeadToApp(leadId, hasAccountWithFidelity, relatedAppId, processor) {
    const requestBody = JSON.stringify({ leadId, hasAccountWithFidelity, relatedAppId, processor });

    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/lead/CreateAppFromLead' }).then(handleResponse);
}

function archiveLead(leadId) {
    const requestBody = JSON.stringify(leadId);

    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/lead/Hide' }).then(handleResponse);
}

function unarchiveLead(leadId) {
    const requestBody = JSON.stringify(leadId);

    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/lead/Unhide' }).then(handleResponse);
}

function getPricingAnalysisSummary(leadId) {
    const requestBody = JSON.stringify(leadId);

    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/lead/GetPricingAnalysisSummary' }).then(handleResponse);
}

function getTierDetails(tierName, appId) {
    const requestBody = JSON.stringify({ tierName: tierName, appId:appId});

    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/lead/TierDetails' })
        .then((response) => {
           return handleResponse(response, true);
        })
        .then((response) => {
            return response;
        });
}
