import React, {Component} from 'react';
import PropTypes from 'prop-types';

class SourceKeySelectionComponent extends Component {
    get sourceKeyValue() {
        return this.props.filter.values.sourceKey;
    }

    filterRemoved = e => {
        this.props.onFilterRemoved({
            id: 'sourceKey',
            emptyValue: null
        });
    };

    render() {
        return (
            <div className="filter__tag">
                <label className="filter__tag__label">Source Key:</label>
                {this.sourceKeyValue}
                <button onClick={this.filterRemoved} className="btn btn--reset filter__tag__clear"></button>
            </div>
        );
    }
}

SourceKeySelectionComponent.propTypes = {
    filter: PropTypes.object.isRequired,
    onFilterRemoved: PropTypes.func.isRequired
};

export default SourceKeySelectionComponent;