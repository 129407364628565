import React from 'react';
import PropTypes from 'prop-types';

class IdentificationTypeDropdownComponent extends React.Component {
	handleChange = e => {
		const { onChange } = this.props;
		onChange(e);
	};

	render() {
		const { id, className, value, disabledValue } = this.props;
		return (
			<select id={id} name={id} className={className} onChange={this.handleChange} value={value}>
				<option value="">Please select</option>
				<option disabled={disabledValue === "DriverLicense"} value="DriverLicense">Driver License</option>
				<option disabled={disabledValue === "NexusCard"} value="NexusCard">Nexus Card</option>
				<option disabled={disabledValue === "Passport"} value="Passport">Passport</option>
			</select>
		);
	}
}

IdentificationTypeDropdownComponent.propTypes = {
	id: PropTypes.string,
	onChange: PropTypes.func,
	className: PropTypes.string,
	value: PropTypes.string,
	disabledValue: PropTypes.string,
};

export default IdentificationTypeDropdownComponent;
