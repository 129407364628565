import React, { useEffect } from 'react';
import { includes, split, each, find } from 'lodash';
import PropTypes from 'prop-types';

const hosts = ['.partner.cardknox.com', '.devpartner.cardknox.com'];
const hostSplitBy = find(hosts, (host) => includes(window.location.host, host));
const cdnUrl = `${process.env.REACT_APP_NOT_SECURE_CDN_URL}cobrand/`;

const MerchantLayoutComponent = ({ children, isCobrand = false, logoImgSrc = '/static/media/logo-dark.svg' }) => {
    const [logoUrl, setLogoUrl] = React.useState(null);
    const setCobrandFavicon = async () => {
        // if(true){
        if(isCobrand && hostSplitBy){
            let cobrandDomain = split(window.location.host, hostSplitBy)[0];
            const iconUrl = `${cdnUrl}${cobrandDomain}/icon.png`;
            try {
                const response = await fetch(iconUrl);
                if(response.status === 200){
                    let links = document.querySelectorAll("link[rel~='icon']");
                    each(links, (l) => l.remove());
                    let link = document.createElement('link');
                    link.rel = 'icon';
                    link.href = iconUrl;
                    document.head.appendChild(link);
                }
            } catch (error) {}
        }
    }   

    const loadLogo = async () => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const logoUrlFromParams = params.get('logoUrl');
        if(logoUrlFromParams){
            const decodedLogoUrlFromParams = decodeURIComponent(logoUrlFromParams);
            setLogoUrl(decodedLogoUrlFromParams);
            return;
        }

        // if(true){
        if(isCobrand && hostSplitBy){
            let cobrandDomain = split(window.location.host, hostSplitBy)[0];
            const cobrandLogoUrl = `${cdnUrl}${cobrandDomain}/logo.png`;
            try {
                const response = await fetch(cobrandLogoUrl);
                if(response.status === 200){
                    setLogoUrl(cobrandLogoUrl);
                    return;
                }
            } catch (error) {}
        }
        setLogoUrl(logoImgSrc);
    }

    useEffect(() => {
        setCobrandFavicon();
        loadLogo();
    }, []);

    return (
        <div className="public__container">
            <header className="spc--bottom--med type--center" id="logo-portal">
                {logoUrl && <img id={'logoImg'} src={logoUrl} alt="logo" />}
            </header>
            <div>
                {children}
            </div>
        </div>
    );
};

MerchantLayoutComponent.propTypes = {
    isCobrand: PropTypes.bool, 
    children: PropTypes.element, 
    logoImgSrc: PropTypes.string,
};

export default MerchantLayoutComponent;
