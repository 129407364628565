import { appService, principalService } from '../services';
import { Auth } from 'aws-amplify';
import { authHeader } from '../helpers/auth-header';
import { decode } from 'jsonwebtoken';
import AesJs from 'aes-js';
import { getApiUrl } from '../common/utilities/apiConfiguration';

const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;

export const _sharedFunctions = {
    logout,
    downloadFile,
    encryptFile,
    objectToFormData,
    buildFormData,
    callApi,
    appRequestHeaders,
    appRequestHeadersForMerchant,
    handleResponse,
    flatten
};

function logout() {
    return Auth.signOut()
        .then(() => {
            principalService.clear();
            return true;
        })
        .catch(() => {
            principalService.clear();
            return false;
        });
};

let pendingPromise = null

function createErrorResponse(refNum = '', errorMessage = '') {
    const baseErrorMessage = 'Error downloading file';
    const errorDetails = errorMessage ? `: ${errorMessage}` : '';
    const refNumMessage = refNum ? ` (${refNum})` : '';
    return {
        errorMessage: `${baseErrorMessage}${errorDetails}${refNumMessage}`
    }
}

async function downloadFile(fileUrl, requestOptions, returnBlob = false, detailedErrorMessage = false, readFileName = false) {
    const response = await fetch(new Request(fileUrl, requestOptions));
    if (response.status === 200) {
        const body = await response.blob();
        if (!body) return createErrorResponse();
        if (returnBlob) return body;
        const url = URL.createObjectURL(body);
        if (!readFileName) return url;
        let fileName = '';
        const matches = filenameRegex.exec(response.headers.get('content-disposition'));
        if (matches != null && matches[1]) {
          fileName = matches[1].replace(/['"]/g, '');
        }
        return {
            url,
            fileName,
        }
    }
    try {
        const json = await response.json();
        if (!detailedErrorMessage || !json.message) return createErrorResponse(json.refNum);
        return createErrorResponse(json.refNum, json.message);
    } catch (e) {
        return createErrorResponse();
    }
}

function encryptFile(file, encryptionKey, encryptionIv) {
    return new Promise(resolve => {
        const reader = new FileReader();
        reader.onload = function (f) {
            const contentBytes = new Uint8Array(reader.result);
            const aesCbc = new AesJs.ModeOfOperation.cbc(encryptionKey, encryptionIv);
            let cipherText = aesCbc.encrypt((AesJs.padding.pkcs7.pad(contentBytes)));
            cipherText = new Uint8Array([...encryptionIv, ...cipherText]);
            const encryptedBlob = new Blob([cipherText])
            const encryptedFile = new File([encryptedBlob], file.name, { type: file.type })
            resolve(encryptedFile)
        }
        console.log("File encrypted")
        reader.readAsArrayBuffer(file);
    })
}

function objectToFormData(obj, rootName, ignoreList) {
    const formData = new FormData();

    function appendFormData(data, root) {
        if (!ignore(root)) {
            root = root || '';
            if (data instanceof File) {
                formData.append(root, data);
            } else if (Array.isArray(data)) {
                for (let i = 0; i < data.length; i++) {
                    if (data[i] instanceof File) {
                        formData.append(root, data[i]);
                    } else {
                        appendFormData(data[i], root + '[' + i + ']');
                    }
                }
            } else if (typeof data === 'object' && data) {                
                for (let key in data) {
                    if (data.hasOwnProperty(key)) {
                        if (root === '') {
                            appendFormData(data[key], key);
                        } else {
                            appendFormData(data[key], root + '.' + key);
                        }
                    }
                }
            } else {
                if (data !== null && typeof data !== 'undefined') {
                    formData.append(root, data);
                }
            }
        }
    }

    function ignore(root) {
        return Array.isArray(ignoreList)
            && ignoreList.some(function (x) { return x === root; });
    }

    appendFormData(obj, rootName);

    return formData;
}

function buildFormData(formData, data, parentKey) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
    } else {
        const value = data == null ? '' : data;

        formData.append(parentKey, value);
    }
    
}

function callApi({ baseUrl, headerMethod, action, body }) {
    return refreshTokenIfExpired().then(
        headerMethod )
        .then((h) => {
            const requestOptions = {
                method: 'POST',
                headers: h,
                body: body
            };
            return fetch(new Request((baseUrl || getApiUrl()) + action, requestOptions));
        });
}

function refreshTokenIfExpired() {
    let userObj = principalService.get();
    if (!userObj) return Promise.resolve();
    let isExpired = isTokenExpired(userObj.token);
    if (isExpired) {
        if (!pendingPromise) {
            pendingPromise = refreshLoginToken(userObj.isDropinUser, userObj.email).then(() =>
            pendingPromise = null);
        }
        return pendingPromise;
    }
    return Promise.resolve();
}

function isTokenExpired(token) {
    const decodedToken = decode(token, { complete: true });
    const dateNow = Math.floor(Date.now() / 1000);
    return decodedToken.payload.exp < dateNow;
};

function refreshLoginToken (isDropInUser, email) {
    return Auth.currentAuthenticatedUser().then((user) => {
        if (user) {
            const token =
                (user.signInUserSession && user.signInUserSession.idToken && user.signInUserSession.idToken.jwtToken) ||
                false;
            let username = user.attributes && user.attributes.email;
            if (token && username) {
                return appService.login(token, username)
                    .then(() => {
                        if (isDropInUser) {
                            return appService.dropin(email);
                        }
                        return true;
                    })
                    .catch((err) => {
                        return false;
                    });
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    })
        .catch((err) => {
            return false;
        });
}

async function appRequestHeaders(forDownload){
    const requestHeader = await authHeader();
    requestHeader.append('Content-Type', 'application/json');
    if (!!forDownload && process.env.LOCAL_DEV !== '1') {
        requestHeader.append('Accept', 'image/png,image/jpg,image/bmp,image/gif,image/jpeg,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/zip,application/gzip,application/octet-stream,text/plain');        
    }
    return requestHeader;
}

async function appRequestHeadersForMerchant(token, forDownload){
    let requestHeader;
    if (token) {
        requestHeader = new Headers({ 'X-PP-Authorization': 'Bearer ' + token });
    } else {
        requestHeader = await authHeader();
    } 
    requestHeader.append('Content-Type', 'application/json');
    if (!!forDownload && process.env.LOCAL_DEV !== '1') {
        requestHeader.append('Accept', 'image/png,image/jpg,image/bmp,image/gif,image/jpeg,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/zip,application/gzip,application/octet-stream,text/plain');
    }
    return requestHeader;
}

function handleResponse(response, skipReloadOnUnauthorize) {
    return response.text().then(text => {
        let data = null;
        try {
            data = text && JSON.parse(text);
        } catch (e) { }

        if (!response.ok) {
            const mfaError = response.headers.get("error") || data;
            if (mfaError === "SOFTWARE_TOKEN_STEP_UP" || mfaError === "SMS_STEP_UP" || mfaError === "MAYBE_SOFTWARE_TOKEN_STEP_UP") {
                return Promise.reject({mfaError})
            }
            if ((response.status === 401 || (response.status === 403)) && !skipReloadOnUnauthorize) {
                // auto logout if 401 response returned from api
                logout();
                window.location.reload(true);
            }

            const baseError = (data && data.message) || data || text || response.statusText || response.status;
            const refNum = data && data.refNum ? ` (${data.refNum})` : '';
            const error = `${baseError}${refNum}`;
            console.log('error: ' + error);
            return Promise.reject(error);
        }
        return data;
    });
}

function flatten (data) {
    const result = {};
    function recurse(cur, prop) {
        if (Object(cur) !== cur) {
            result[prop] = cur;
        } else if (Array.isArray(cur)) {
            const l = cur.length;
            for (let i = 0; i < l; i++)
                recurse(cur[i], prop + "[" + i + "]");
            if (l === 0)
                result[prop] = [];
        } else {
            let isEmpty = true;
            for (let p in cur) {
                isEmpty = false;
                recurse(cur[p], prop ? prop + "." + p : p);
            }
            if (isEmpty && prop)
                result[prop] = {};
        }
    }
    recurse(data, "");
    return result;
}
