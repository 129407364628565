import React from 'react';
import { withLoader } from "./../../common/components";
import { appService } from '../../services';
import { identifyFullStoryWithToken, openFile } from '../../common/utilities';

class DownloadFiservApplication extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: this.props.location.search.substr(1),
            errorMessage: '',
            success: false
        };
        identifyFullStoryWithToken(this.state.token);
    }

    componentDidMount() {
        this.props.showLoader(true);
        const isDownload = false;
        appService.getMerchantFiservApplicationFile(this.state.token)
            .then(({ url, fileName, errorMessage }) => {
                if (errorMessage) {
                    this.setState({ errorMessage });
                } else {
                    openFile(url, fileName, !isDownload, false);

                    if (isDownload) {
                        this.setState({ success: true });
                    }
                }
                this.props.showLoader(false);
            });
    }

    renderSuccessMessage() {
        return (
            <div className="note note--success">
                Downloaded successfully
            </div>
        );
    }

    renderResultMessage() {
        return (
            this.state.errorMessage ? (
                <div className="note note--warning">
                    {this.state.errorMessage}
                </div>
            ) : this.state.success && (
                this.renderSuccessMessage()
            )
        );
    }
    render() {
        return (
            <div id="main-div" className="l--content">
                {
                    this.state.errorMessage || this.state.success ? (
                        this.renderResultMessage()
                    ) :
                        <div className="note note--default">
                            Loading File...
                        </div>
                }
            </div>
        );
    }
}

export default withLoader(DownloadFiservApplication);