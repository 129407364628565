import React, { Component, Fragment } from 'react';
import { cloneDeep, first, get, isEmpty, transform } from 'lodash';
import { func, string, object } from 'prop-types';
import { withLoader } from "./../../common/components";

import { FileDropzoneComponent  as FileDropzone } from '../../common/components/file-dropzone';
import { appService } from '../../services/appService';

const defaultFileTag = "MerchantStatement";

class NewFilePopup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            files: [],
        };
    }

    save = () => {
        const { appId, addNotification, closeModal, history, showLoader } = this.props;
        const { files } = this.state;
        showLoader(true);
        appService.saveMerchantFiles(
            appId,
            transform(files, (acc, { file, fileTag = defaultFileTag }) => {
                if (!acc[fileTag]) acc[fileTag] = [];
                acc[fileTag].push({ file, fileTag });
            }))
        .then(response => {
            addNotification({
                success: true,
                ref: get(first(response), 'refNum'),
                message: `File${files.length > 1 ? 's' : ''} saved successfully`,
                onClose: () => history.go(),
            });
            closeModal();
            showLoader();
        }).catch(err => {
            addNotification({
                success: false,
                message: 'An error occurred: ' + err,
                onClose: () => history.go(),
            });
            closeModal();
            showLoader();
        });
    };

    handleUpdateFileTag = (_, index, fileTag) => {
        const { files } = this.state;
        const newFiles = [...files];

        if (!files[index]) {
            return;
        }

        newFiles[index] = {
            ...newFiles[index],
            fileTag,
        };

        this.setState({ files: newFiles });
    }

    handleRemoveFile = (_, fileIndx) => {
        let fullFileList = cloneDeep(this.state.files);
        fullFileList.splice(fileIndx, 1);
        this.setState({ files: fullFileList });
    }

    onDropFile = (_, acceptedFiles) => {
        let fullFileList = cloneDeep(this.state.files);
        let newFilesList = acceptedFiles.map((itm, i) => {
            return { file: itm };
        });

        if (fullFileList) {
            fullFileList.push.apply(fullFileList, newFilesList);
        } else {
            fullFileList = newFilesList;
        }

        this.setState({ files: fullFileList });
    }

    renderAddNewFile = () => {

        return (
            <React.Fragment>
                <div className="form__field">
                    <label className="label">Upload File: </label>
                    <div className="file-upload--sml">
                        <FileDropzone
                            multiple={true}
                            showTagDropdown={true}
                            tag={defaultFileTag}
                            fileList={this.state.files}
                            onDrop={this.onDropFile}
                            onRemoveFile={this.handleRemoveFile}
                            onUpdateTag={this.handleUpdateFileTag}
                            validateFileSize={false}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    render() {
        const { closeModal } = this.props;

        return (
            <Fragment>
                <div className="popup__header">
					<h3 className="popup__header__title">Add File</h3>
				</div>
                <div className="popup__body">
                    {this.renderAddNewFile()}
                </div>
                <div className="popup__footer popup__footer--styled ">
                    <a className="btn btn--med btn--ghost spc--right--xsml" onClick={closeModal}>Cancel</a>
                    <button disabled={isEmpty(this.state.files)} onClick={this.save} className="btn btn--med btn--primary">Save</button>
                </div>
            </Fragment>
        );
    }
}

NewFilePopup.defaultProps = {
    appId: '',
};

NewFilePopup.propTypes = {
    appId: string.isRequired,
    closeModal: func.isRequired,
    addNotification: func.isRequired,
    history: object,
    showLoader: func.isRequired,
};

export default withLoader(NewFilePopup);