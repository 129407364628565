import React, { Component } from "react";
import { func, array, string, any } from "prop-types";
import { each, cloneDeep, isEmpty } from "lodash";

import SoftwareListItemComponent from "./software-list-item";

class SoftwareListComponent extends Component {
    componentWillMount() {
        let { softwareList, merchantEquipment } = this.props;
        let newSoftwareList = cloneDeep(merchantEquipment);
        each(softwareList, (item) => {
            let merchantSoftware = merchantEquipment.find(
                (e, i) => e.equipmentId == item.equipmentId
            );
            if (item.purchasePlans.length > 0 && !merchantSoftware) {
                let newSoftware = this.props.createNewEquipment(
                    item.equipmentId,
                    false
                );
                newSoftwareList.push(newSoftware);
            }
        });
        this.props.onListChange(newSoftwareList);
    }

    componentDidUpdate() {
        if (isEmpty(this.props.merchantEquipment)) {
            this.componentWillMount();
        }
    }

    getAvailablePurchasePlans = (equipmentId) => {
        const { softwareList, merchantEquipment } = this.props;
        const merchantSoftware = merchantEquipment.find(
            (e, i) => e.equipmentId === equipmentId
        );
        const software = softwareList.find(
            (e, i) => e.equipmentId === equipmentId
        );

        if (!merchantSoftware) {
            return software.purchasePlans;
        }

        return software.purchasePlans.filter((plan) =>
            plan.purchaseTypes.includes(merchantSoftware.purchaseType)
        );
    };

    softwareSelect = (software, unselectOther) => {
        if (unselectOther) {
            let newSoftwareList = cloneDeep(this.props.merchantEquipment);
            each(newSoftwareList, (item) => {
                if (
                    item.category === "Software" &&
                    item.equipmentId != software.equipmentId
                ) {
                    item.isSelected = false;
                }
            });
            this.props.onListChange(newSoftwareList);
        }
        this.props.onChange(software);
    };

    onChange = (software, selectSoftware = true) => {
        if (selectSoftware) {
            software.isSelected = true;
        }
        this.props.onChange(software);
    };

    render() {
        const {
            softwareList,
            merchantEquipment,
            selectedEquipmentId,
        } = this.props;

        return (
            <div>
                {softwareList.map((software, idx) => {
                    if (software.purchasePlans.length === 0) return null;

                    let merchantSoftware = merchantEquipment.find(
                        (e) => e.equipmentId == software.equipmentId
                    );
                    if (
                        softwareList.length === 1 ||
                        (!merchantSoftware && idx === 0)
                    ) {
                        software.defaultSelected = true;
                    }

                    return (
                        <div
                            key={idx}
                            className="card--shaded card--sml spc--bottom--med"
                        >
                            <SoftwareListItemComponent
                                software={software}
                                isExpanded={
                                    softwareList.length === 1 ||
                                    selectedEquipmentId == software.equipmentId
                                }
                                merchantSoftware={merchantSoftware}
                                onChange={this.onChange}
                                createNewEquipment={
                                    this.props.createNewEquipment
                                }
                                openCloseModal={this.props.openCloseModal}
                                softwareSelect={this.softwareSelect}
                            />
                        </div>
                    );
                })}
            </div>
        );
    }
}

SoftwareListComponent.propTypes = {
    softwareList: array.isRequired,
    merchantEquipment: array.isRequired,
    onChange: func.isRequired,
    onListChange: func.isRequired,
    openCloseModal: func.isRequired,
    createNewEquipment: func.isRequired,
    onShowEquipment: func.isRequired,
    selectedEquipmentId: any,
    appId: string,
};

export default SoftwareListComponent;
