import React, { Fragment, Component, createRef } from 'react';
import {
	isEqual,
	split,
	toLower,
	isEmpty,
	includes,
	replace,
	isArray,
	some,
	transform,
	map,
	startsWith,
	find,
	findIndex,
	filter,
	get,
	times,
	each,
	sumBy,
	isFunction,
	noop,
	cloneDeep,
	mapKeys,
	set,
	has,
	last,
	repeat,
} from 'lodash';
import { NumericFormat } from 'react-number-format';
import moment from 'moment';
import { defaultImplicitParse, defaultReactOutput } from 'simple-markdown';
import SingleDatePicker from '../../common/filters/date-picker/single-date-picker';
import { FileDropzoneComponent } from '../../common/components/file-dropzone';
import { canPreviewFile } from '../../common/utilities/canPreviewFile';
import { appService } from '../../services/appService';
import { handleFileDownload } from '../../common/utilities/commonFileHandlingMethods';
import { ModalWrapper, modalNames } from '../../common/components/modal-wrapper';
import { apply } from 'json-logic-js';
import { MASKED_VALUE } from '../../validation/validators';
import MaskedValueInputWrapper from '../../common/components/masked-value-input-wrapper/MaskedValueInputWrapper';
import { fileTypes } from '../../common/components/file-dropzone/fileTypeConstants';
import AddressAutocomplete from './AddressAutocomplete';

const required = (
	<span data-tooltip="Required" className="form__group__required">
		*
	</span>
);

const ownershipTypeOptions = [
	{ value: 'Unknown', label: 'Please select' },
	{ value: 'Corporation', label: 'Corporation' },
	{ value: 'SoleProprietor', label: 'Sole Proprietor' },
	{ value: 'NonProfit', label: 'Non Profit / Tax Exempt' },
	{ value: 'GovernmentEntity', label: 'Government Entity' },
	{ value: 'PubliclyTraded', label: 'Publicly Traded Company' },
];
const titleOptions = [
	{ exchangeValue: 'CEO', value: 'CEO', label: 'CEO' },
	{ exchangeValue: 'Director', value: 'COO', label: 'COO/Director' },
	{ exchangeValue: 'Owner', value: 'President', label: 'President/Owner' },
	{ exchangeValue: 'Secretary', value: 'Secretary', label: 'Secretary' },
	{ exchangeValue: 'Treasurer', value: 'Treasurer', label: 'Treasurer' },
	{ exchangeValue: 'Partner', value: 'Vice President', label: 'VP/Partner' },
];

const level1Documents = [];
const level2Documents = [
	{
		tag: 'ProcessingHistory',
		label: 'Processing History',
		tooltip: `If available, please provide 3 months' worth of your business' most recent processing history via merchant statements or a similar document.`,
	},
	{
		tag: 'BankStatements',
		label: 'Bank Statements',
		tooltip: `Please provide 3 months' worth of your business' most recent bank statements.`,
	},
];
const level3Documents = [
	{
		tag: 'BusinessTaxReturns',
		label: 'Business Tax Returns',
		tooltip: `Please attach 2 years' worth of your business' most recent tax returns.`,
	},
	{
		tag: 'BusinessFinancialReports',
		label: 'Business Financial Reports',
		tooltip: `Please attach your business' most recent income statement and balance sheet.`,
	},
	{
		tag: 'GuarantorPersonalTaxReturns',
		label: 'Guarantor Personal Tax Returns',
		tooltip: `If using a guarantor, please attach 2 years' worth of your guarantor’s most recent personal tax returns.`,
	},
	{
		tag: 'GuarantorCurrentBalanceSheet',
		label: 'Guarantor Current Balance Sheet',
		tooltip: `If using a guarantor, please attach your guarantor’s current balance sheet.`,
	},
];
const requiredDocs = ['BankStatements', 'BusinessTaxReturns', 'BusinessFinancialReports'];
const allowedFileTypes = [fileTypes.jpeg, fileTypes.png, fileTypes.bmp, fileTypes.txt, fileTypes.pdf];
class GoPlusBaseClass extends Component {
	constructor(isMerchantMpa, props) {
		super(props);

		this.isMerchantMpa = isMerchantMpa;
		this.dateOfBirthRef1 = createRef();
		this.businessStartDateRef = createRef();
		this.notificationRef = createRef();
		this.topRef = createRef();
		this.accountNumberInputRef = createRef();
		this.confirmAccountNumberInputRef = createRef();
		this.accountRoutingInputRef = createRef();
		this.handleFileDownload = handleFileDownload.bind(this);
		this.outputFormat = process.env.REACT_APP_DISPLAY_DATE_FORMAT;
		this.formSchema = null;

		this.state = this.initialState;
		this.requiredDocs = requiredDocs;
	}

	get initialState() {
		return {
			modal: {
				name: modalNames.none,
				data: null,
			},
			localFiles: {},
			maskedValues: [],
			accountNumberMaskedVisible: true,
			routingNumberMaskedVisible: true,
			confirmAccountNumberMaskedVisible: true,
			accountNumberMasked: '',
			routingNumberMasked: '',
			confirmAccountNumberMasked: '',
			sameAsBusiness: false,
			isNewError: false,
			errorMessage: null,
			errorList: [],
			errorListPaths: [],
			errorApiMessage: null,
			errorApiList: [],
			errorApiListPaths: [],
			numberOfOwners: 1,
			totalOwnershipPercentage: 0,
			dirty: false,
			isECommerce: false,
			isIntegratedWithBank: false,
			isLinkedToAchq: false,
			isLinkedToGoPlus: false,
			isSplitAchq: false,
			processorName: '',
			processingInformation: {
				averageTicket: 0,
				highTicket: 0,
				annualCreditCardSales: 0,
				monthlySalesVolume: 0,
				sicCode: '',
				percentCardPresent: 0,
				percentCardNotPresent: 0,
				totalSales: 0,
				rateStructure: '',
				ccRate: 0,
				ccRateAmex: 0,
				perTranFee: 0,
				perTranAmexFee: 0,
				tranFeeType: '',
				visaRate: 0,
				mcRate: 0,
				discoverRate: 0,
				amexRate: 0,
				visaTranFee: 0,
				mcTranFee: 0,
				discoverTranFee: 0,
				amexTranFee: 0,
				visaInternationalRate: 0,
				mcInternationalRate: 0,
				discoverInternationalRate: 0,
				achEnabled: false,
				achPercentFee: 0,
				achPercentFeeCap: 0,
				achTransactionFee: 0,
				achReturnFee: 0,
				achUnauthorizedReturnFee: 0,
				achMonthlyFee: 0,
				achSetupFee: 0,
				isExchangeTier: false,
			},
			fields: {
				corporateName: '',
				dbaName: '',
				appId: '',
				businessPhone: '',
				businessEmail: '',
				ownershipType: '',
				productSold: '',
				businessStartDate: '',
				website: '',
				taxId: '',
				businessAddress: {},
				mailingAddress: {},
				agentName: '',
				submittedByName: '',
				agentEmail: '',
				submittedByEmail: '',
				agentId: '',
				telemarketerName: '',
				telemarketerId: '',
				tierName: '',
				agentReadonly: true,
				bankingInformation: {
					bankName: '',
					accountType: '',
					routingNumber: '',
					accountNumber: '',
					confirmAccountNumber: '',
				},
				signerInformationList: [this.initSigner],
				totalMinorOwnershipPercent: '',
				fieldsToHide: '',
				files: [],
			},
		};
	}

	get initSigner() {
		return {
			appAdditionalOwnerID: 0,
			ownershipPercentage: '',
			firstName: '',
			lastName: '',
			ssn: '',
			dateOfBirth: '',
			cellPhone: '',
			address: {
				streetAddress: '',
				city: '',
				state: '',
				zip: '',
			},
			title: '',
		};
	}

	get getFieldsForValidation() {
		const {
			fields,
			processingInformation,
			isECommerce,
			numberOfOwners,
			totalOwnershipPercentage,
			totalSales,
			downloadUrl,
			maskedValues,
		} = this.state;
		const fieldsCopy = cloneDeep(fields);
		if (get(this.state, 'maskedValues', []).length > 0) {
			each(maskedValues, ({ path }) => {
				if (get(fieldsCopy, path)) {
					set(fieldsCopy, path, MASKED_VALUE);
				}
			});
		}
		return {
			...fieldsCopy,
			processingInformation,
			isECommerce,
			numberOfOwners,
			totalOwnershipPercentage,
			totalSales,
			downloadUrl,
		};
	}

	async setUnderwritingLevels(token, skipReloadOnUnathorized = false) {
		let underwritingLevelRules = {
			if: [
				{ '>': [{ var: 'monthlyVolume' }, 1000000] },
				3,
				{ or: [{ '>': [{ var: 'monthlyVolume' }, 167000] }, { '>': [{ var: 'averageTicket' }, 50000] }] },
				2,
				1,
			],
		};
		try {
			const responseLevels = await appService.getGoPlusUnderwritingLevels(token, skipReloadOnUnathorized);
			if (responseLevels) {
				underwritingLevelRules = responseLevels;
			}
		} catch (err) {}
		this.setState({ underwritingLevelRules });
	}
	componentDidUpdate(prevProps, prevState) {
		if (
			prevState.processingInformation &&
			this.state.processingInformation &&
			(!isEqual(prevState.fields, this.state.fields) ||
				!isEqual(prevState.processingInformation, this.state.processingInformation)) &&
			!this.state.dirty
		) {
			this.setState({ dirty: true });
			if (this.props.handleBlockChange) {
				this.props.handleBlockChange(true);
			}
		}
		if ((this.state.errorMessage || !isEmpty(this.state.errorList)) && this.state.isNewError) {
			setTimeout(() => {
				if (this.topRef.current) {
					this.topRef.current.scrollIntoView({
						behavior: 'smooth',
						block: 'start',
					});
				}
			}, 200);
			this.setState({ isNewError: false });
		}
	}

	addEventListenersToAccountNumberInputs = () => {
		this.disableCopyPaste(this.accountRoutingInputRef);
		this.disableCopyPaste(this.accountNumberInputRef);
		this.disableCopyPaste(this.confirmAccountNumberInputRef);
	};

	setStateAsync = state => {
		return new Promise(resolve => {
			this.setState(state, resolve);
		});
	};

	mapMaskedValues = (fields, originalFieldValues = {}, newState = null) => {
		//if originalFieldValues is present, mask unchanged fields
		const maskedValues = [];
		if (!this.isMerchantMpa) {
			//masking values only on merchant MPA
			return;
		}
		const maskedValuePaths = ['bankingInformation.accountNumber', 'bankingInformation.routingNumber'];
		each(maskedValuePaths, path => {
			let value = '';
			if (
				has(originalFieldValues, path) &&
				!has(fields, path) &&
				get(originalFieldValues, path) === get(this.state.fields, path)
			) {
				value = get(originalFieldValues, path, '');
			} else {
				value = get(fields, path, '');
			}
			if (value) {
				maskedValues.push({ path, value });
				if (path === 'bankingInformation.accountNumber') {
					maskedValues.push({ path: 'bankingInformation.confirmAccountNumber', value });
				}
			}
		});

		const isSignerInformationListSetFromOriginal =
			has(originalFieldValues, 'signerInformationList') && !has(fields, 'signerInformationList');
		let signerInformationList = [];
		if (isSignerInformationListSetFromOriginal) {
			signerInformationList = get(originalFieldValues, 'signerInformationList', '');
		} else {
			signerInformationList = get(fields, 'signerInformationList', '');
		}
		each(signerInformationList, (signer, index) => {
			let valueDOB = '';
			let valueSSN = '';
			if (isSignerInformationListSetFromOriginal) {
				valueDOB =
					get(signer, 'dateOfBirth', '') === get(this.state.fields, `signerInformationList[${index}].dateOfBirth`)
						? get(signer, 'dateOfBirth', '')
						: '';
				valueSSN =
					get(signer, 'ssn', '') === get(this.state.fields, `signerInformationList[${index}].ssn`)
						? get(signer, 'ssn', '')
						: '';
			} else {
				valueDOB = get(signer, 'dateOfBirth', '');
				valueSSN = get(signer, 'ssn', '');
			}
			if (valueDOB) {
				const isDOBMasked = moment(valueDOB).isSame('1000-01-01', 'year');
				isDOBMasked &&
					maskedValues.push({
						path: `signerInformationList[${index}].dateOfBirth`,
						value: `${moment(valueDOB).format('MM')}/DD/YYYY`,
					});
			}
			if (valueSSN) {
				maskedValues.push({ path: `signerInformationList[${index}].ssn`, value: valueSSN });
			}
		});

		if (newState) {
			newState.maskedValues = maskedValues;
		} else {
			this.setState({ maskedValues });
		}
	};

	mapGoAppToState = (app, callback = noop) => {
		const newState = { ...this.state, dirty: false };
		this.mapMaskedValues(app.goPlusEApp, {}, newState);
		const businessAddress = get(app.goPlusEApp, 'businessAddress');
		const mailingAddress = get(app.goPlusEApp, 'mailingAddress');
		const signerInformationList = get(app.goPlusEApp, 'signerInformationList');
		const bankingInfo = get(app.goPlusEApp, 'bankingInformation');
		const tierName = this.state.fields.tierName || get(app.goPlusEApp, 'tierName', '');
		const processingInfo = get(app.goPlusEApp, 'processingInformation');
		const businessStartDate = get(app.goPlusEApp, 'businessStartDate', '');

		newState.appId = app.goPlusEApp.appId;
		newState.processorName = get(app.goPlusEApp, 'processorName', '');
		newState.fields.appId = get(app.goPlusEApp, 'appId', '');
		newState.fields.agentName = get(app.goPlusEApp, 'agentName', '');
		newState.fields.submittedByName = get(app.goPlusEApp, 'submittedByName', '');
		newState.fields.agentEmail = get(app.goPlusEApp, 'agentEmail', '');
		newState.fields.submittedByEmail = get(app.goPlusEApp, 'submittedByEmail', '');
		newState.fields.agentId = get(app.goPlusEApp, 'agentId', '');
		newState.fields.telemarketerName = get(app.goPlusEApp, 'telemarketerName', '');
		newState.fields.telemarketerId = get(app.goPlusEApp, 'telemarketerId', '');

		newState.fields.agentReadonly = get(app.goPlusEApp, 'agentReadonly', '');
		newState.fields.corporateName = get(app.goPlusEApp, 'corporateName', '');
		newState.fields.dbaName = get(app.goPlusEApp, 'dbaName', '');
		if (businessStartDate != '') {
			newState.fields.businessStartDate = moment(businessStartDate).format('MM/DD/YYYY');
		}
		newState.fields.website = get(app.goPlusEApp, 'website', '');
		newState.fields.taxId = get(app.goPlusEApp, 'taxId', '');
		newState.fields.businessEmail = get(app.goPlusEApp, 'businessEmail', '');
		newState.fields.businessPhone = get(app.goPlusEApp, 'businessPhone', '');
		newState.fields.productSold = get(app.goPlusEApp, 'productSold', '');
		newState.fields.businessAddress = businessAddress;
		newState.fields.mailingAddress = mailingAddress;
		newState.fields.files = get(app.goPlusEApp, 'files', []);

		if (
			businessAddress.streetAddress === mailingAddress.streetAddress &&
			businessAddress.city === mailingAddress.city &&
			businessAddress.state === mailingAddress.state &&
			businessAddress.zip === mailingAddress.zip
		) {
			newState.sameAsBusiness = true;
		}

		this.mapProcessingInfoToState(processingInfo, newState);
		newState.fields.totalMinorOwnershipPercent = get(app.goPlusEApp, 'totalMinorOwnershipPercent', '');

		this.mapSignerInformationListToState(signerInformationList, newState);

		newState.fields.bankingInformation.bankName = get(bankingInfo, 'bankName', '');
		newState.fields.bankingInformation.routingNumber = get(bankingInfo, 'routingNumber', '');
		if (get(bankingInfo, 'routingNumber') !== '') {
			this.updateMaskedValue(newState, 'routingNumber', get(bankingInfo, 'routingNumber', ''));
		}
		if (get(bankingInfo, 'accountNumber') !== '') {
			this.updateMaskedValue(newState, 'accountNumber', get(bankingInfo, 'accountNumber', ''));
			newState.fields.bankingInformation.accountNumber = get(bankingInfo, 'accountNumber');
			this.updateMaskedValue(newState, 'confirmAccountNumber', get(bankingInfo, 'accountNumber', ''));
			newState.fields.bankingInformation.confirmAccountNumber = get(bankingInfo, 'accountNumber');
		}
		newState.fields.bankingInformation.accountType = toLower(get(bankingInfo, 'accountType', ''));

		newState.fields.ownershipType = get(app.goPlusEApp, 'ownershipType', '');

		newState.fields.tierName = tierName;
		newState.isECommerce = get(app.goPlusEApp, 'isECommerce', false);
		newState.isIntegratedWithBank = get(app.goPlusEApp, 'isIntegratedWithBank', '');
		newState.isLinkedToAchq = get(app.goPlusEApp, 'isLinkedToAchq', '');
		newState.isLinkedToGoPlus = get(app.goPlusEApp, 'isLinkedToGoPlus', '');
		newState.isSplitAchq = get(app.goPlusEApp, 'isSplitAchq', '');
		this.requiredDocs = get(app.goPlusEApp, 'disableGoPlusRequiredFiles', false) ? [] : requiredDocs;
		this.setState(newState, () => callback());
	};

	loadSicCodes = async () => {
		const { sicCodes } = this.state;
		if (isEmpty(sicCodes)) {
			const { sicCodes } = await appService.loadSicCodes();
			this.setState({ sicCodes });
		}
	};

	deleteFile = (appId, id, tag) => () => {
		this.props.showLoader(true);
		return appService
			.deleteMerchantFile(appId, id)
			.then(() => {
				this.fetchData();
				const addNotification = get(this.notificationRef, 'current.addNotification', noop);
				addNotification({ message: 'Successfully deleted', success: true });
			})
			.catch(ex => {
				this.props.showLoader(false);
				this.setState({ downloadErrorMessage: ex });
			});
	};

	mapSignerInformationListToState = (signerInformationList, newState) => {
		each(
			signerInformationList,
			(
				{
					firstName = '',
					lastName = '',
					ssn = '',
					dateOfBirth = '',
					cellPhone = '',
					address = { streetAddress: '', city: '', state: '', zip: '' },
					ownershipPercentage = '',
					title = '',
					appAdditionalOwnerID = 0,
				},
				index
			) => {
				this[`dateOfBirthRef${index + 1}`] = createRef();

				if (!newState.fields.signerInformationList[index]) {
					newState.fields.signerInformationList[index] = this.initSigner;
				}

				newState.fields.signerInformationList[index].firstName = firstName;
				newState.fields.signerInformationList[index].lastName = lastName;
				newState.fields.signerInformationList[index].ssn = ssn;
				newState.fields.signerInformationList[index].cellPhone = cellPhone;
				if (dateOfBirth != '') {
					newState.fields.signerInformationList[index].dateOfBirth = moment(dateOfBirth).format('MM/DD/YYYY');
				}
				newState.fields.signerInformationList[index].address = address;
				newState.fields.signerInformationList[index].ownershipPercentage = ownershipPercentage;
				newState.fields.signerInformationList[index].title = title;
				newState.fields.signerInformationList[index].appAdditionalOwnerID = appAdditionalOwnerID;
			}
		);

		newState.numberOfOwners = newState.fields.signerInformationList.length;
	};

	mapProcessingInfoToState = (processingInfo, newState) => {
		const {
			processingInformation: { sicCode },
			fields: { tierName },
		} = this.state;
        let isAchq = this.isAchqOnly();
		newState.processingInformation.monthlySalesVolume = get(processingInfo, isAchq ? 'achMonthlySalesVolume' : 'monthlySalesVolume', '');
		newState.processingInformation.averageTicket = get(processingInfo,'averageTicket', '');
		newState.processingInformation.highTicket = get(processingInfo, isAchq ? 'achHighTicket' : 'highTicket', '');
		newState.processingInformation.annualCreditCardSales = get(processingInfo, 'annualCreditCardSales', '');
		if (newState.processingInformation.monthlySalesVolume <= 0) {
			newState.processingInformation.monthlySalesVolume = newState.processingInformation.annualCreditCardSales / 12;
		}
		newState.processingInformation.rateStructure = get(processingInfo, 'rateStructure', '');
		newState.processingInformation.percentCardNotPresent = get(processingInfo, 'percentCardNotPresent', '');
		newState.processingInformation.percentCardPresent = get(processingInfo, 'percentCardPresent', '');
		if (!this.isGlobalAchTier(tierName) || !sicCode) {
			newState.processingInformation.sicCode = get(processingInfo, 'sicCode', '');
		}
		newState.processingInformation.ccRate = get(processingInfo, 'ccRate', '');
		newState.processingInformation.ccRateAmex = get(processingInfo, 'ccRateAmex', '');
		newState.processingInformation.perTranAmexFee = get(processingInfo, 'perTranAmexFee', '');
		newState.processingInformation.perTranFee = get(processingInfo, 'perTranFee', '');
		newState.processingInformation.tranFeeType = get(processingInfo, 'tranFeeType');
		newState.processingInformation.visaTranFee = get(processingInfo, 'visaTranFee', '');
		newState.processingInformation.visaRate = get(processingInfo, 'visaRate', '');
		newState.processingInformation.mcTranFee = get(processingInfo, 'mcTranFee', '');
		newState.processingInformation.mcRate = get(processingInfo, 'mcRate', '');
		newState.processingInformation.discoverTranFee = get(processingInfo, 'discoverTranFee', '');
		newState.processingInformation.discoverRate = get(processingInfo, 'discoverRate', '');
		newState.processingInformation.amexTranFee = get(processingInfo, 'amexTranFee', '');
		newState.processingInformation.amexRate = get(processingInfo, 'amexRate', '');
		newState.processingInformation.visaInternationalRate = get(processingInfo, 'visaInternationalRate', '');
		newState.processingInformation.mcInternationalRate = get(processingInfo, 'mcInternationalRate', '');
		newState.processingInformation.discoverInternationalRate = get(processingInfo, 'discoverInternationalRate', '');
		newState.fields.fieldsToHide = get(processingInfo, 'fieldsToHide', '');
		newState.processingInformation.achEnabled = get(processingInfo, 'achEnabled');
		newState.processingInformation.achTransactionFee = get(processingInfo, 'achTransactionFee');
		newState.processingInformation.achPercentFee = get(processingInfo, 'achPercentFee');
		newState.processingInformation.achPercentFeeCap = get(processingInfo, 'achPercentFeeCap');
		newState.processingInformation.achReturnFee = get(processingInfo, 'achReturnFee');
		newState.processingInformation.achUnauthorizedReturnFee = get(processingInfo, 'achUnauthorizedReturnFee');
		newState.processingInformation.achMonthlyFee = get(processingInfo, 'achMonthlyFee');
		newState.processingInformation.achSetupFee = get(processingInfo, 'achSetupFee');
		newState.processingInformation.isExchangeTier = get(processingInfo, 'isExchangeTier');
	};

	isAchqOnly = (processor = '') => {
		const { processorName } = this.state;
		return toLower(processor || processorName) === 'achq' || window.location.pathname.includes('achq');
	};

	isGlobalAchTier = tierName => {
		const { achqGlobalTierName } = this.state;
		return toLower(tierName) === toLower(achqGlobalTierName);
	};

	saveFiles = () => {
		const { localFiles } = this.state;
		const appId = this.state.appId || this.state.fields.appId;
		return new Promise((resolve, reject) => {
			appService
				.saveMerchantFiles(appId, cloneDeep(localFiles))
				.then(() => {
					this.setState({ localFiles: {} }, resolve);
				})
				.catch(err => {
					this.props.showLoader(false);
					this.setState({ errorMessage: 'Files could not be uploaded - an error occurred: ' + err, isNewError: true });
					reject(err);
				});
		});
	};

	onDropFile = (fileType, files, callback = noop) => {
		const fullFileList = cloneDeep(this.state.localFiles);
		let newFilesList = files.map((itm, i) => {
			return { file: itm, fileDescription: '' };
		});

		if (fullFileList[fileType]) {
			fullFileList[fileType].push(...newFilesList);
		} else {
			fullFileList[fileType] = newFilesList;
		}
		this.setState({ localFiles: fullFileList }, callback);
	};

	handleRemoveFile = (fileType, i, callback = noop) => {
		const fullFileList = cloneDeep(this.state.localFiles);
		fullFileList[fileType].splice(i, 1);
		this.setState(
			{
				localFiles: fullFileList,
			},
			callback
		);
	};

	scrollTo = id => {
		const elem = document.getElementById(id);
		elem && elem.scrollIntoView({ behavior: 'smooth', block: 'center' });
	};

	focusField = name => {
		const elem = document.getElementsByName(name);
		elem && elem[0] && elem[0].focus();
	};

	getTotalSales = () => {
		const {
			processingInformation: { percentCardNotPresent, percentCardPresent },
		} = this.state;
		const total = parseFloat(percentCardNotPresent || 0) + parseFloat(percentCardPresent || 0);

		if (String(total).indexOf('.') > -1) {
			return total.toFixed(2);
		}

		return total;
	};

	mapSignerInfoErrorMessage = ({ name, newFieldError, index = 0 }) => {
		if (newFieldError && name.indexOf('signerInformationList') > -1) {
			let message = newFieldError.message;

			if (toLower(message).indexOf('must be at least 18 years old') > -1) {
				message = message.replace(/Date of Birth/, 'Signer');
			}

			const startIndex = message.indexOf('[**');
			const endIndex = message.indexOf('**]');
			const fieldLabel = message.slice(startIndex, endIndex);

			const newMessage = `${fieldLabel} ${index + 1} ${message.slice(endIndex)}`;
			newFieldError.message = newMessage;
		}
	};

	validateFieldOnBlur = ({ target: { name } }, index) => {
		const { isSubmit, currentStep } = this.state;
		if (isSubmit) return;
		this.setState(state => {
			if (name === 'ownershipType') name = 'numberOfOwners';
			const newState = { errorList: [...state.errorList] };

			if (isFunction(this.setFormSchema)) {
				this.setFormSchema();
			}

			const newErrorList = this.formSchema.validate(Object.assign({}, this.getFieldsForValidation));
			const newFieldError = find(newErrorList, ({ path }) => path === name);
			const validatedErrorIndex = findIndex(newState.errorList, ({ path }) => path === name);

			this.mapSignerInfoErrorMessage({ name, newFieldError, index });

			if (validatedErrorIndex > -1) {
				if (newFieldError) {
					newState.errorList[validatedErrorIndex] = newFieldError;
				} else {
					this.removeErrorMessage(newState, name);
				}
			} else if (newFieldError) {
				newState.errorList.push(newFieldError);
			}

			this.validatePercentCardField(newState, name);
			this.validateAccountNumbersMustMatch(newState, name, newFieldError);

			if (currentStep) {
				const { steps } = this.state;
				const stepsClone = cloneDeep(steps);
				const currentStepClone = cloneDeep(currentStep);

				currentStepClone.isFormValid = !newErrorList.length;
				map(stepsClone, step => {
					if (step.id === currentStepClone.id) {
						step.isFormValid = currentStepClone.isFormValid;
					}
					return step;
				});
				this.setState({ currentStep: currentStepClone, steps: stepsClone });
			}

			newState.errorListPaths = newState.errorList.map(e => e.path);

			return newState;
		});
	};

	removeErrorMessage = (newState, name, isInPlace = false) => {
		if (isInPlace) {
			const index = findIndex(newState.errorList, { path: name });
			if (index === -1) return;
			newState.errorList.splice(index, 1);
		}
		newState.errorList = filter(newState.errorList, ({ path }) => path !== name);
	};

	validatePercentCardField = (newState, name) => {
		const { processingInformation } = this.state;
		const totalSalesErrorExists = findIndex(newState.errorList, ({ path }) => path === 'totalSales') > -1;

		if (name.indexOf('percentCard') > -1) {
			const percentCardPresent = get(processingInformation, 'percentCardPresent');
			const percentCardNotPresent = get(processingInformation, 'percentCardNotPresent');

			if (this.getTotalSales() !== 100 && (percentCardPresent || percentCardNotPresent) && !totalSalesErrorExists) {
				newState.errorList.push({
					message: '[**Total Card Sales**](javascript:void) must equal 100%',
					path: 'totalSales',
				});
			} else if (this.getTotalSales() === 100 || (!percentCardPresent && !percentCardNotPresent)) {
				this.removeErrorMessage(newState, 'totalSales');
			}
		}
	};

	validateAccountNumbersMustMatch = (newState, name, newFieldError) => {
		const { fields } = this.state;

		if (name.indexOf('accountNumber') > -1 && name.indexOf('secondary') === -1) {
			if (
				fields.bankingInformation.accountNumber &&
				fields.bankingInformation.confirmAccountNumber !== fields.bankingInformation.accountNumber
			) {
				newState.errorList.push({
					message: '[**Account Numbers**](javascript:void) for primary bank must match',
					path: 'bankingInformation.confirmAccountNumber',
				});
			} else if (!newFieldError) {
				this.removeErrorMessage(newState, name);
			}
		}
	};

	validateField = name => {
		const { errorListPaths } = this.state;
		const invalidClassName = ' is-invalid';

		if (
			(isArray(name) && some(name, item => includes(errorListPaths, replace(item, /_/gi, '.')))) ||
			includes(errorListPaths, replace(name, /_/gi, '.'))
		) {
			return invalidClassName;
		}

		return '';
	};

	handleSubmitError = err => {
		this.setState({
			errorMessage: 'An error occurred: ' + err,
			isNewError: true,
		});
	};

	convertToBooleanOrValue = value => {
		if (value === 'true') {
			return true;
		} else if (value === 'false') {
			return false;
		} else {
			return value;
		}
	};

	updateMaskedValue = (newState, name, value) => (newState[`${name}Masked`] = repeat('X', value.length));

	handleNumberOfOwnersChange = ({ target: { name, value, type } }) => {
		const { fields, numberOfOwners } = this.state;
		const numberOfNewOwners = value - numberOfOwners;
		const newState = { [name]: value };
		newState.fields = { ...fields };
		let method = () => {
			let array = [...newState.fields.signerInformationList];
			array.splice(newState.fields.signerInformationList.length - 1, 1);
			newState.fields.signerInformationList = array;
		};

		if (numberOfNewOwners > 0) {
			method = () => {
				newState.fields.signerInformationList = [...newState.fields.signerInformationList, this.initSigner];
			};
		}

		times(Math.abs(numberOfNewOwners), method);
		if (newState.fields.signerInformationList.length === 1) {
			newState.fields.signerInformationList[0].ownershipPercentage = '100';
		}
		if (newState.fields.signerInformationList.length === 2) {
			newState.fields.signerInformationList[0].ownershipPercentage = '50';
			newState.fields.signerInformationList[1].ownershipPercentage = '50';
		}
		times(newState.fields.signerInformationList.length, index => {
			this[`dateOfBirthRef${index + 1}`] = createRef();
		});

		this.setState(newState, () => {
			this.validateSelectOnBlur(name, type);
			this.validateFieldsIfSubmitted();
		});
	};

	handleChange = ({ target: { name, value, checked, type } }, index, callback = noop) => {
		const { sameAsBusiness, fields, processingInformation, totalOwnershipPercentage, currentStep } = this.state;
		const newState = {
			fields: { ...fields },
			processingInformation: { ...processingInformation },
		};
		let newValue;
		if (type === 'checkbox') {
			newValue = checked;
		} else if (type === 'radio') {
			newValue = this.convertToBooleanOrValue(value);
		} else {
			newValue = value;
		}

		if (name === 'ownershipType') {
			if (this.isGovernmentOrPublic(value)) {
				each(fields.signerInformationList, (_, index) => {
					this.handleSignerInfoChange({ newState, name: `signerInformationList.${index}.ssn`, newValue: '' });
				});
			} else if (this.isSoleProprietor(value) || this.isAchqOnly()) {
				newState.fields.signerInformationList = [fields.signerInformationList[0]];
				newState.fields.totalMinorOwnershipPercent = '';
				newState.numberOfOwners = 1;
			}
		}
		if (name === 'processingInformation.monthlySalesVolume' || name === 'processingInformation.averageTicket') {
			const { localFiles } = this.state;
			const documents = this.getDocumentsList();
			const tags = map(documents, 'tag');
			mapKeys(localFiles, (value, key) => {
				if (!includes(tags, key)) {
					localFiles[key] = [];
				}
			});
			newState.localFiles = localFiles;
		}
		//if name is 'website' and value changes from empty to not empty, prepend https://
		else if (name === 'website' && !fields.website && value) {
			newValue = `https://${value}`;
		}

		if (name.indexOf('signerInformationList') > -1) {
			this.handleSignerInfoChange({ newState, name, newValue, value });
		} else if (name.indexOf('.') > -1) {
			this.handleFieldInSectionChange({ newState, name, newValue, value, sameAsBusiness });
		} else {
			newState.fields[name] = newValue;
		}
		this.setState(
			state => ({
				...newState,
				fields: {
					...state.fields,
					...newState.fields,
				},
			}),
			() => {
				this.validateSelectOnBlur(name, type, index);
				this.validateFieldsIfSubmitted();
				if (totalOwnershipPercentage != this.getTotalOwnershipPercentage()) {
					this.setState({ totalOwnershipPercentage: this.getTotalOwnershipPercentage() });
				}
				callback();

				if (currentStep) {
					const { steps } = this.state;
					const stepsClone = cloneDeep(steps);
					const currentStepClone = cloneDeep(currentStep);

					const errors = currentStepClone.formSchema.validate(Object.assign({}, this.getAllFieldsForValidation()));
					currentStepClone.isFormValid = !errors.length;

					map(stepsClone, step => {
						if (step.id === currentStepClone.id) {
							step.isFormValid = currentStepClone.isFormValid;
						}
						return step;
					});
					this.setState({ currentStep: currentStepClone, steps: stepsClone });
				}
			}
		);
	};

	getRequiredDocumentsErrors = (documents = null) => {
		const {
			localFiles,
			fields: { files },
		} = this.state;
		if (documents === null) {
			documents = this.getDocumentsList();
		}
		let errors = [];
		each(documents, document => {
			if (this.checkIfRequiredFileTag(document.tag)) {
				const localFileExists = get(localFiles, document.tag, []).length > 0;
				const savedFileExists = some(files, ({ fileTag }) => fileTag === document.tag);
				if (!(localFileExists || savedFileExists)) {
					errors.push({
						message: `[**${document.label}**](javascript:void) is a required document.`,
						path: `${toLower(document.tag)}_div`,
					});
				}
			}
		});
		return { errors };
	};

	validateSelectOnBlur = (name, type, index) => {
		const { isSubmit } = this.state;
		if (!isSubmit && toLower(type).indexOf('select') > -1) {
			this.validateFieldOnBlur({ target: { name } }, index);
		}
	};

	handleSignerInfoChange = ({ newState, name, newValue }) => {
		const keys = split(name, '.');

		if (keys.length === 4) {
			const [section, index, subSection, fieldKey] = keys;
			newState.fields[section] = [...newState.fields[section]];

			newState.fields[section][index] = {
				...newState.fields[section][index],
				[subSection]: {
					...newState.fields[section][index][subSection],
					[fieldKey]: newValue,
				},
			};
		} else {
			const [section, index, fieldKey] = keys;
			newState.fields[section] = [...newState.fields[section]];

			newState.fields[section][index] = {
				...newState.fields[section][index],
				[fieldKey]: newValue,
			};
		}
	};

	handleFieldInSectionChange = ({ newState, name, newValue, value, sameAsBusiness }) => {
		const keys = split(name, '.');

		if (keys.length === 3) {
			const [section, subSection, fieldKey] = keys;

			newState.fields[section] = {
				...this.state.fields[section],
				[subSection]: {
					...this.state.fields[section][subSection],
					[fieldKey]: newValue,
				},
			};
		} else {
			const [section, fieldKey] = keys;
			const isProcessingInfo = startsWith(toLower(name), 'processinginfo');

			if (isProcessingInfo) {
				newState[section] = {
					...this.state[section],
					[fieldKey]: newValue,
				};
			} else {
				newState.fields[section] = {
					...this.state.fields[section],
					[fieldKey]: newValue,
				};
			}

			if (toLower(fieldKey).indexOf('accountnumber') > -1 || toLower(fieldKey).indexOf('routingnumber') > -1) {
				this.updateMaskedValue(newState, fieldKey, value);
			}

			if (sameAsBusiness && toLower(section) === 'businessaddress') {
				newState.fields.mailingAddress[fieldKey] = newValue;
			}
		}
	};

	setCustomDaySelection = (date, name) => {
		this.handleChange({
			target: { name, value: moment(date.date, this.outputFormat, true).format(this.outputFormat) },
		});
	};

	getTotalOwnershipPercentage = () => {
		if (this.isSoleProprietor(this.state.fields.ownershipType) || this.isAchqOnly()) return 100;
		const total = parseFloat(
			sumBy(this.state.fields.signerInformationList, value => parseFloat(get(value, 'ownershipPercentage') || 0)) || 0
		);
		const totalMinorOwner = parseFloat(this.state.fields.totalMinorOwnershipPercent || 0);
		return parseFloat(total + totalMinorOwner || 0);
	};

	validateFieldsIfSubmitted = () => {
		if (this.state.isSubmit) {
			this.validateFields(false, true);
		}
	};

	copyAddress = ({ target: { checked: sameAsBusiness } }) => {
		const {
			fields,
			fields: { businessAddress, mailingAddress },
		} = this.state;
		const newState = { sameAsBusiness, fields: { ...fields } };

		newState.fields.mailingAddress = sameAsBusiness
			? { ...businessAddress }
			: transform(mailingAddress, (acc, _, key) => (acc[key] = ''));

		this.setState(newState, this.validateFieldsIfSubmitted);
	};

	renderStateDropdown = (name, value) => (
		<select
			className={`input input--med input--select${this.validateField(name)}`}
			id={name}
			name={name}
			value={value || { label: 'Please Select...', value: '' }}
			onChange={this.handleChange}
		>
			<option value="">Please Select...</option>
			<option value="AL">Alabama</option>
			<option value="AK">Alaska</option>
			<option value="AZ">Arizona</option>
			<option value="AR">Arkansas</option>
			<option value="CA">California</option>
			<option value="CO">Colorado</option>
			<option value="CT">Connecticut</option>
			<option value="DE">Delaware</option>
			<option value="DC">District of Columbia</option>
			<option value="FL">Florida</option>
			<option value="GA">Georgia</option>
			<option value="GU">Guam</option>
			<option value="HI">Hawaii</option>
			<option value="ID">Idaho</option>
			<option value="IL">Illinois</option>
			<option value="IN">Indiana</option>
			<option value="IA">Iowa</option>
			<option value="KS">Kansas</option>
			<option value="KY">Kentucky</option>
			<option value="LA">Louisiana</option>
			<option value="ME">Maine</option>
			<option value="MD">Maryland</option>
			<option value="MA">Massachusetts</option>
			<option value="MI">Michigan</option>
			<option value="MN">Minnesota</option>
			<option value="MS">Mississippi</option>
			<option value="MO">Missouri</option>
			<option value="MT">Montana</option>
			<option value="NE">Nebraska</option>
			<option value="NV">Nevada</option>
			<option value="NH">New Hampshire</option>
			<option value="NJ">New Jersey</option>
			<option value="NM">New Mexico</option>
			<option value="NY">New York</option>
			<option value="NC">North Carolina</option>
			<option value="ND">North Dakota</option>
			<option value="OH">Ohio</option>
			<option value="OK">Oklahoma</option>
			<option value="OR">Oregon</option>
			<option value="PA">Pennsylvania</option>
			<option value="PR">Puerto Rico</option>
			<option value="RI">Rhode Island</option>
			<option value="SC">South Carolina</option>
			<option value="SD">South Dakota</option>
			<option value="TN">Tennessee</option>
			<option value="TX">Texas</option>
			<option value="UT">Utah</option>
			<option value="VT">Vermont</option>
			<option value="VA">Virginia</option>
			<option value="WA">Washington</option>
			<option value="WV">West Virginia</option>
			<option value="WI">Wisconsin</option>
			<option value="WY">Wyoming</option>
			<option value="AA">Armed Forces (the) Americas</option>
			<option value="AE">Armed Forces Europe</option>
			<option value="AP">Armed Forces Pacific</option>
			<option value="APO">Army Post Office (U.S. Army and U.S. Air Force)</option>
			<option value="FPO">Fleet Post Office (U.S. Navy and U.S. Marine Corps)</option>
		</select>
	);

	disableCopyPaste = reactRef => {
		const ref = reactRef.current;
		if (ref) {
			ref.addEventListener(
				'select',
				function() {
					ref.selectionStart = ref.selectionEnd;
				},
				false
			);
			ref.addEventListener(
				'copy',
				e => {
					e.preventDefault();
				},
				false
			);
			ref.addEventListener(
				'cut',
				e => {
					e.preventDefault();
				},
				false
			);
			ref.addEventListener(
				'paste',
				e => {
					e.preventDefault();
				},
				false
			);
			ref.addEventListener(
				'contextmenu',
				e => {
					e.preventDefault();
				},
				false
			);
		}
	};

	renderNumberFormat = ({
		name,
		value,
		label,
		decimalScale = 0,
		allowLeadingZeros = true,
		ref,
		tooltip = null,
		index,
		isAllowed,
		validateFieldsArr,
		disableField = false,
		className = '',
		prefix = '',
		suffix = '',
	}) => {
		let disabledFields = ['rateStructure', 'ccRate', 'ccRateAmex', 'perTranFee', 'perTranAmexFee'];
		if (!this.isAchqOnly()) disabledFields.push('sicCode');
		const disabled = some(disabledFields, item => toLower(name).indexOf(toLower(item)) > -1) || disableField;

		const hide = some(this.state.fields.fieldsToHide, item => toLower(name).indexOf(toLower(item)) > -1);

		const componentProps = {
			name,
			value,
			id: name,
			decimalScale,
			allowLeadingZeros,
			inputType: 'numeric',
			allowNegative: false,
			placeholder: label,
			className: `input input--med${this.validateField(validateFieldsArr ? validateFieldsArr : name)}`,
			onValueChange: values =>
				this.handleChange({ target: { name, value: allowLeadingZeros ? values.value : values.floatValue } }),
			onBlur: e => this.validateFieldOnBlur(e, index),
			disabled,
			prefix,
			suffix,
		};
		let renderMaskedInput = false;

		if (ref) {
			componentProps.getInputRef = ref;
			renderMaskedInput = true;
		}

		if (isAllowed !== undefined) {
			componentProps.isAllowed = isAllowed;
		}

		componentProps.disabled = disabled;

		if (allowLeadingZeros) {
			componentProps.isNumericString = true;
		}

		return (
			!hide && (
				<div className="col col-sml-12 col-lrg-6 spc--bottom--med">
					{this.renderLabel({ tooltip, label: index === undefined ? label : `${label} ${index + 1}`, name, disabled })}
					<div className={`input--masked ${className}`}>
						{renderMaskedInput && !disabled ? (
							this.renderMaskedInput(componentProps, ref)
						) : (
							<NumericFormat {...componentProps} />
						)}
					</div>
				</div>
			)
		);
	};

	renderLabel = ({ tooltip, label, name, disabled }) => {
		return tooltip ? (
			<div className="form__group__header">
				<p htmlFor={name} className="form__group__label">
					{label}
				</p>
				{this.checkIfShouldDisplayAsterisk(name) && !disabled && required}
				<i className="icon icon--tny icon--required--info datatooltip--200" data-tooltip={tooltip}></i>
			</div>
		) : (
			<div className="form__group__header">
				<p htmlFor={name} className="form__group__label">
					{label}
				</p>
				{this.checkIfShouldDisplayAsterisk(name) && !disabled && required}
			</div>
		);
	};

	showMaskedInput = (name, value) => () => this.setState({ [`${name}MaskedVisible`]: !!value });

	hideMaskedInput = (name, ref) => () => {
		this.setState({ [`${name}MaskedVisible`]: false }, () => {
			if (ref && ref.current) {
				ref.current.focus();
			}
		});
	};

	createResetValueHandler = (names, resetValue) => () => {
		const excludeMaskedValues = [];
		each(names, name => {
			excludeMaskedValues.push(name);
			let propertyName = last(split(name, '.'));
			if (has(this.state, `${propertyName}MaskedVisible`)) {
				this.setState({ [`${propertyName}MaskedVisible`]: false });
			}
			if (has(this.state, `${propertyName}MaskedVisible`)) {
				this.setState({ [`${propertyName}MaskedVisible`]: false });
			}
		});
		const newMaskedValues = cloneDeep(
			filter(this.state.maskedValues, ({ path }) => !some(excludeMaskedValues, item => item === path))
		);
		this.setState({ maskedValues: newMaskedValues });
		resetValue();
	};

	handleSicCodeChange = ({ target: { value } }) => {
		value = this.getSicCodeOptionValue(value);
		const [sicCode, sicCodeFilter] = this.isDataListValue(value) ? value.split('|') : ['', value];

		this.setState(prevState => ({
			sicCodeFilter,
			processingInformation: {
				...prevState.processingInformation,
				sicCode,
			},
		}));
	};

	isValidSicCode = sicCode => /^[\d]{4}$/.test(sicCode);

	isDataListValue = value => /^[\d]{4}\|.*/.test(value);

	getSicCodeOptionValue = value => {
		const list = document.getElementById('sicCodeOptions');
		const option = find(list.options, opt => opt.textContent === value);
		return option ? option.dataset.value : value;
	};

	clearSicCode = () => {
		this.setState(prevState => ({
			processingInformation: {
				...prevState.processingInformation,
				sicCode: '',
			},
			sicCodeFilter: '',
		}));
	};

	renderMaskedInput = (componentProps, ref) => {
		const { name, value } = componentProps;
		let fieldKey = name;

		if (name.indexOf('.') > -1) {
			fieldKey = split(name, '.')[1];
		}

		if (ref && ref.current) {
			ref.current.onblur = this.showMaskedInput(fieldKey, value);
		}
		const showMaskedInput = this.state[`${fieldKey}MaskedVisible`];

		if (showMaskedInput) {
			componentProps.className += ' hidden';
		}

		const { maskedValues } = this.state;
		const maskedValue = find(maskedValues, { path: name });
		let resetValue = this.createResetValueHandler([name], () => this.handleChange({ target: { name, value: '' } }));
		if (name === 'bankingInformation.accountNumber' || name === 'bankingInformation.confirmAccountNumber') {
			resetValue = this.createResetValueHandler(
				['bankingInformation.accountNumber', 'bankingInformation.confirmAccountNumber'],
				() => {
					const newMaskedValues = filter(
						maskedValues,
						({ path }) =>
							!some(
								['bankingInformation.accountNumber', 'bankingInformation.confirmAccountNumber'],
								item => item === path
							)
					);
					this.setState({
						maskedValues: newMaskedValues,
						fields: {
							...this.state.fields,
							bankingInformation: {
								...this.state.fields.bankingInformation,
								confirmAccountNumber: '',
								accountNumber: '',
							},
						},
					});
				}
			);
		}

		return (
			<MaskedValueInputWrapper
				maskedValue={get(maskedValue, 'value', null)}
				resetValue={resetValue}
				inputClass={'input input--med'}
			>
				<span className={this.getSpanClassName(showMaskedInput)}>
					<input
						type="text"
						className={`input input--med${this.validateField(name)}`}
						id={name}
						name={name}
						value={this.state[`${fieldKey}Masked`]}
						onChange={this.handleChange}
						onFocus={this.hideMaskedInput(fieldKey, ref)}
						placeholder={componentProps.placeholder}
					/>
				</span>
				<span className={this.getSpanClassName(!showMaskedInput)}>
					<NumericFormat {...componentProps} />
				</span>
			</MaskedValueInputWrapper>
		);
	};

	getSpanClassName = display => (display ? '' : 'display--n');

	renderField = (name, value, label, tooltip, index, disableField = false) => {
		const disabled =
			some(
				[
					'averageTicket',
					'highTicket',
					'sicCode',
					'rateStructure',
					'ccRate',
					'ccRateAmex',
					'perTranFee',
					'perTranAmexFee',
				],
				item => toLower(name).indexOf(toLower(item)) > -1
			) || disableField;

		const hide = some(this.state.fieldsToHide, item => toLower(name).indexOf(toLower(item)) > -1);

		return (
			!hide && (
				<div className="col col-sml-12 col-lrg-6 form__group">
					{this.renderLabel({ tooltip, label, name, disabled })}
					<input
						type="text"
						className={`input input--med${this.validateField(name)}`}
						id={name}
						name={name}
						value={value || ''}
						placeholder={label}
						onChange={this.handleChange}
						onBlur={e => this.validateFieldOnBlur(e, index)}
						disabled={disabled}
					/>
				</div>
			)
		);
	};

	renderSalesRep = (disableAgentEmail = false) => {
		const {
			fields: { agentName, agentEmail, telemarketerName, agentReadonly, submittedByName, submittedByEmail },
		} = this.state;
		if (!disableAgentEmail) disableAgentEmail = agentReadonly;
		return (
			<div className="card is-expanded spc--bottom--lrg">
				<div className="card__header">
					<div className="flex--primary flex--gap--sml">
						<h5>Sales Rep</h5>
						<i
							className="icon icon--sml icon--regular--info datatooltip--200"
							data-tooltip="If no sales agent associated with this lead, please input your own contact information"
						></i>
					</div>
				</div>
				<div className="card__body">
					<div className="row">
						{this.renderField(
							'agentName',
							submittedByName ? submittedByName : agentName,
							'Sales Rep Name',
							null,
							null,
							disableAgentEmail
						)}
						{this.renderField(
							'agentEmail',
							submittedByEmail ? submittedByEmail : agentEmail,
							'Sales Rep Email Address',
							null,
							null,
							disableAgentEmail
						)}
						{telemarketerName && this.renderField('telemarketerName', telemarketerName, 'Rep 2', null, null, true)}
					</div>
				</div>
			</div>
		);
	};

	renderOwnershipTypeOptions = () =>
		map(ownershipTypeOptions, ({ value, label }) => (
			<option key={`${value}.${label}`} value={value}>
				{label}
			</option>
		));

	renderOwnershipType = () => {
		const {
			fields: { ownershipType },
		} = this.state;

		return (
			<div className="col col-sml-12 col-lrg-6 form__group">
				<div className="form__group__header">
					<p htmlFor="ownershipType" className="form__group__label">
						Ownership Type
					</p>
					{this.checkIfShouldDisplayAsterisk('ownershipType') && required}
				</div>
				<select
					className={`input input--med input--select${this.validateField('ownershipType')}`}
					id="ownershipType"
					name="ownershipType"
					value={ownershipType}
					onChange={this.handleChange}
				>
					{this.renderOwnershipTypeOptions()}
				</select>
			</div>
		);
	};

	renderSicCodeOptions = () => {
		const { sicCodes, sicCodeFilter } = this.state;

		return map(sicCodes, ([mcc, industry]) =>
			!sicCodeFilter || includes(toLower(industry), toLower(sicCodeFilter)) || includes(mcc, sicCodeFilter) ? (
				<option data-value={`${mcc}|${industry}`}>
					{industry} - {mcc}
				</option>
			) : null
		);
	};

	renderSicCodeList = () => <datalist id="sicCodeOptions">{this.renderSicCodeOptions()}</datalist>;

	getSicCodeInputValue = sicCode => {
		const { sicCodes, sicCodeFilter } = this.state;
		let result;
		if (!this.isValidSicCode(sicCode)) return sicCodeFilter;
		const items = filter(sicCodes, ([mcc]) => mcc === sicCode);
		if (items?.length > 0) {
			let item = null;
			if (items.length > 1) {
				if (sicCodeFilter) {
					item = find(items, ([, industry]) => includes(toLower(industry), toLower(sicCodeFilter)));
				}
				if (!item) {
					return sicCode;
				}
			} else {
				item = items[0];
			}
			result = `${item[1]} - ${item[0]}`;
		}
		return result;
	};

	renderSicCodeField = sicCode => {
		return (
			<div className="col col-sml-12 col-lrg-6 form__group">
				<div className="form__group__header">
					<label htmlFor="sicCode" className="form__group__label">
						SIC Code
					</label>
					{required && <span className="form__group__required">*</span>}
				</div>
				<input
					className={`input input--med input--select${this.validateField('processingInformation.sicCode')}`}
					id="sicCode"
					name="processingInformation.sicCode"
					placeholder="Type to search for SIC Code..."
					value={this.getSicCodeInputValue(sicCode)}
					list="sicCodeOptions"
					onChange={this.handleSicCodeChange}
				/>
				{this.renderSicCodeList()}
			</div>
		);
	};

	renderSicCode = (sicCode, tierName) => {
		return this.isGlobalAchTier(tierName) ? (
			this.renderSicCodeField(sicCode)
		) : (
			<div className="col col-sml-12">
				<div className="row">
					{this.renderNumberFormat({
						name: 'processingInformation.sicCode',
						value: sicCode,
						label: 'SIC Code',
						disableField: true,
					})}
				</div>
			</div>
		);
	};

	renderBusinessStartDate = () => {
		const {
			fields: { businessStartDate },
		} = this.state;

		return (
			<div className="col col-sml-12 col-med-6 form__group">
				<div className="form__group__header">
					<p htmlFor="businessStartDate" className="form__group__label">
						Business Start Date
					</p>
					{this.checkIfShouldDisplayAsterisk('businessStartDate') && required}
				</div>
				<SingleDatePicker
					name={'businessStartDate'}
					onChange={date => this.handleChange({ target: { name: 'businessStartDate', value: date } })}
					validateField={() => this.validateField('businessStartDate')}
					validateFieldOnBlur={this.validateFieldOnBlur}
					value={businessStartDate}
				/>
			</div>
		);
	};

	handleSelectAddress = (address, type) => {
		const { fields } = this.state;
		let addressObject = cloneDeep(get(fields, type));
		addressObject.streetAddress = address.street;
		addressObject.city = address.city;
		addressObject.state = address.state;
		addressObject.zip = address.zip;
		const updateMailingAddress =
			type === 'businessAddress' && this.state.sameAsBusiness
				? () => this.copyAddress({ target: { checked: true } })
				: noop;
		this.handleChange({ target: { name: type, value: addressObject } }, null, updateMailingAddress);
	};

	renderBusinessInfo = () => {
		const {
			fields: {
				corporateName,
				dbaName,
				taxId,
				website,
				businessPhone,
				businessEmail,
				businessAddress,
				mailingAddress,
				productSold,
			},
			sameAsBusiness,
		} = this.state;

		return (
			<React.Fragment>
				<div className="card is-expanded spc--bottom--lrg">
					<div className="card__header">
						<h5>Business Information</h5>
					</div>
					<div className="card__body">
						<div className="row">
							{this.renderField('corporateName', corporateName, 'Company Legal Name')}
							{this.renderField(
								'dbaName',
								dbaName,
								'DBA',
								'DBA is the business name that your customers know you as. This is the name that will display on cardholder’s statements'
							)}
							{this.renderNumberFormat({
								name: 'taxId',
								value: taxId,
								label: 'EIN',
								className: 'fs-mask',
							})}
							{this.renderField('website', website, 'Website')}
							{this.renderNumberFormat({
								name: 'businessPhone',
								value: businessPhone,
								label: 'Primary Business Phone number',
							})}
							{this.renderField('businessEmail', businessEmail, 'Business Email')}
							{this.renderOwnershipType()}
							{this.renderBusinessStartDate()}
							{this.renderField(
								'productSold',
								productSold,
								'Products/Services',
								'The products or services you provide'
							)}
						</div>
					</div>
				</div>
				<div className="card is-expanded spc--bottom--lrg">
					<div className="card__header">
						<h5>Business Address</h5>
					</div>
					<div className="card__body">
						<div className="row">
							<div className="col col-lrg-12 form__group">
								<AddressAutocomplete
									onSelectAddress={address => this.handleSelectAddress(address, 'businessAddress')}
								/>
							</div>
						</div>
						<div className="row">
							{this.renderField('businessAddress.streetAddress', businessAddress.streetAddress, 'Address')}
							{this.renderField('businessAddress.city', businessAddress.city, 'City')}
							<div className="col col-sml-12 col-lrg-6 form__group">
								<div className="form__group__header">
									<label htmlFor="businessAddress.state" className="form__group__label">
										State
									</label>
									{this.checkIfShouldDisplayAsterisk('businessAddress.state') && required}
								</div>
								{this.renderStateDropdown('businessAddress.state', businessAddress.state)}
							</div>
							{this.renderNumberFormat({
								name: 'businessAddress.zip',
								value: businessAddress.zip,
								label: 'Zip Code',
							})}
						</div>
					</div>
				</div>
				<div className="card is-expanded spc--bottom--lrg">
					<div className="card__header">
						<h5>Mailing Address</h5>
					</div>
					<div className="card__body">
						<div className="spc--bottom--med">
							<input
								id="sameAsBusiness"
								type="checkbox"
								className="input--check"
								value={sameAsBusiness}
								checked={sameAsBusiness}
								onChange={this.copyAddress}
							/>
							<label htmlFor="sameAsBusiness">Same as Business Address</label>
						</div>
						<fieldset disabled={sameAsBusiness}>
							{!sameAsBusiness && (
								<div className="row">
									<div className="col col-lrg-12 form__group">
										<AddressAutocomplete
											onSelectAddress={address => this.handleSelectAddress(address, 'mailingAddress')}
										/>
									</div>
								</div>
							)}
							<div className="row">
								{this.renderField(
									sameAsBusiness ? 'businessAddress.streetAddress' : 'mailingAddress.streetAddress',
									mailingAddress.streetAddress,
									'Address'
								)}
								{this.renderField('mailingAddress.city', mailingAddress.city, 'City')}
								<div className="col col-sml-12 col-lrg-6 form__group">
									<div className="form__group__header">
										<label htmlFor="mailingAddress.state" className="form__group__label">
											State
										</label>
										{this.checkIfShouldDisplayAsterisk('mailingAddress.state') && required}
									</div>
									{this.renderStateDropdown('mailingAddress.state', mailingAddress.state)}
								</div>
								{this.renderNumberFormat({
									name: 'mailingAddress.zip',
									value: mailingAddress.zip,
									label: 'Zip Code',
								})}
							</div>
						</fieldset>
					</div>
				</div>
			</React.Fragment>
		);
	};

	renderProcessingInfo = isMerchantMPA => {
		const { processingInformation, isLinkedToAchq } = this.state;

		return (
			<div className="card is-expanded spc--bottom--lrg">
				<div className="card__header">
					<div className="flex--primary flex--gap--sml">
						<h5>Processing Information</h5>
						{isMerchantMPA && (
							<i
								className="icon icon--sml icon--regular--info datatooltip--w--200"
								data-tooltip="Annual Volume is the total amount you can process on an annual basis. You do not need to commit to processing these volumes."
							></i>
						)}
					</div>
				</div>
				<div className="card__body">
					{!(this.isAchqOnly() && isMerchantMPA) && (
						<div className="row">
							{!this.isAchqOnly() && this.renderCCProcessingInfoFields({ ...processingInformation, isMerchantMPA })}
							{!isLinkedToAchq && this.renderACHProcessingInfoFields({ ...processingInformation, isMerchantMPA })}
						</div>
					)}
				</div>
			</div>
		);
	};

	renderFileDropZone(tag) {
		const { localFiles } = this.state;
		return (
			<FileDropzoneComponent
				tag={tag}
				multiple={true}
				fileList={localFiles}
				onDrop={(fileType, files) => this.onDropFile(fileType, files, this.validateFieldsIfSubmitted)}
				onRemoveFile={(fileType, i) => this.handleRemoveFile(fileType, i, this.validateFieldsIfSubmitted)}
				validateFileSize={false}
				allowedFileTypes={allowedFileTypes}
			/>
		);
	}

	checkIfRequiredFileTag = fileTag => {
		return some(this.requiredDocs, tag => tag === fileTag);
	};

	openCloseModal = modalObj => {
		let state = {
			modal: modalObj,
		};
		this.setState(state);
	};

	handleFileDelete = (appId, fileId, fileName, fileTag) => e => {
		this.openCloseModal({
			name: modalNames.confirmAction,
			data: {
				question: 'Are you sure you want to delete ' + fileName + '?',
				onConfirm: this.deleteFile(appId, fileId, fileTag),
			},
		});
	};

	getFilesForTag(tag) {
		const { files } = this.state.fields;
		const fullFileList = files;
		let matchTag = [];
		if (!fullFileList) return null;
		if (Array.isArray(tag)) {
			matchTag = tag;
		} else {
			matchTag.push(tag);
		}
		if (matchTag.includes('BankStatements')) matchTag.push('BankingStatement');
		return filter(fullFileList, file => {
			const fileTag = file.fileTag.split('_');
			return fileTag.filter((ft, i) => matchTag.includes(ft)).length > 0;
		});
	}

	renderFilesForTag(tag) {
		let files = this.getFilesForTag(tag);
		if (files && files.length > 0) {
			return (
				<React.Fragment>
					<label className="type--p3 type--p3--medium spc--top--med">Already Uploaded:</label>
					<div className="upload__list">
						{files.map((file, idx) => {
							return (
								<div key={file.fileId} className="upload__list__item">
									<p className="type--p2">{file.fileName}</p>
									<div className="flex--primary flex--gap--sml align--h--right">
										{canPreviewFile(file.fileName) && (
											<button
												className="btn btn--link datatooltip--auto"
												data-tooltip="View"
												onClick={this.handleFileDownload(
													file.parentId,
													file.fileId,
													file.fileName,
													this.props.showLoader
												)}
												disabled={this.props.isLoading}
											>
												<i className="icon icon--view--text icon--sml"></i>
											</button>
										)}
										<button
											onClick={this.handleFileDownload(
												file.parentId,
												file.fileId,
												file.fileName,
												this.props.showLoader,
												true
											)}
											data-tooltip="Download"
											className="datatooltip--auto btn btn--link"
											download
											disabled={this.props.isLoading}
										>
											<i className="icon icon--sml icon--download "></i>
										</button>
										<button
											onClick={this.handleFileDelete(file.parentId, file.fileId, file.fileName, file.fileTag)}
											disabled={this.props.isLoading || this.state.disableSave}
											className="btn btn--link datatooltip--auto"
											data-tooltip="Delete"
										>
											<i className="icon icon--delete icon--sml"></i>
										</button>
									</div>
								</div>
							);
						})}
					</div>
				</React.Fragment>
			);
		}
	}

	renderUploadDocumentRow(tag, label, tooltip, showSeparator = true, id = null) {
		const { modal } = this.state;
		const required = this.checkIfRequiredFileTag(tag);
		return (
			<div className="card__upload__item" id={`card__upload__item_${id}`}>
				<ModalWrapper modal={modal} onModalClose={this.openCloseModal} />
				<div className="flex--primary flex--gap--sml" id={id}>
					<div className="flex--primary flex--gap--tny">
						<p className="type--p2">{`${label} `}</p>
						{required && (
							<span data-tooltip="Required" className="type--p2 form__group__required">
								*
							</span>
						)}
						{tooltip && <i className="icon icon--sml icon--regular--info datatooltip--200" data-tooltip={tooltip} />}
					</div>
				</div>
				<div className="card__upload__body">
					{this.renderFileDropZone(tag)}
					{this.renderFilesForTag(tag)}
				</div>
			</div>
		);
	}

	renderCCProcessingInfoFields({
		averageTicket,
		highTicket,
		monthlySalesVolume,
		sicCode,
		rateStructure,
		ccRate,
		ccRateAmex,
		perTranFee,
		perTranAmexFee,
		visaRate,
		mcRate,
		discoverRate,
		amexRate,
		tranFeeType,
		visaTranFee,
		mcTranFee,
		discoverTranFee,
		amexTranFee,
		visaInternationalRate,
		mcInternationalRate,
		discoverInternationalRate,
		percentCardPresent,
		percentCardNotPresent,
		isMerchantMPA,
	}) {
		const flatRate = rateStructure == 'Flat Rate';
		const showInternational =
			visaInternationalRate != '0' || mcInternationalRate != '0' || discoverInternationalRate != '0';
		return (
			<Fragment>
				{this.renderNumberFormat({
					name: 'processingInformation.averageTicket',
					value: averageTicket,
					label: 'Average Ticket',
					decimalScale: 2,
					allowLeadingZeros: false,
					prefix: '$',
				})}
				{this.renderNumberFormat({
					name: 'processingInformation.highTicket',
					value: highTicket,
					label: 'High Ticket',
					decimalScale: 2,
					allowLeadingZeros: false,
					prefix: '$',
				})}
				{this.renderNumberFormat({
					name: 'processingInformation.monthlySalesVolume',
					value: monthlySalesVolume,
					label: 'Monthly Sales Volume',
					decimalScale: 2,
					allowLeadingZeros: false,
					prefix: '$',
				})}
				{this.renderField('sicCode', sicCode, 'SIC Code')}
				{this.renderField('rateStructure', rateStructure, 'Rate Structure')}
				{flatRate && (
					<div className="col col-sml-12">
						<div className="row">
							{this.renderNumberFormat({
								name: 'processingInformation.ccRate',
								value: ccRate,
								label: 'CC Rate',
								decimalScale: 2,
								suffix: '%',
							})}
							{this.renderNumberFormat({
								name: 'processingInformation.ccRateAmex',
								value: ccRateAmex,
								label: 'CC Rate Amex',
								decimalScale: 2,
								suffix: '%',
							})}
							{this.renderNumberFormat({
								name: 'processingInformation.perTranFee',
								value: perTranFee,
								label: 'Per Tran Fee',
								decimalScale: 2,
								prefix: '$',
							})}
							{this.renderNumberFormat({
								name: 'processingInformation.perTranAmexFee',
								value: perTranAmexFee,
								label: 'Per Tran Amex Fee',
								decimalScale: 2,
								prefix: '$',
							})}
						</div>
					</div>
				)}
				{!flatRate && (
					<div className="col col-sml-12">
						<div className="row">
							{this.renderNumberFormat({
								name: 'processingInformation.visaRate',
								value: visaRate,
								label: 'Visa Rate',
								decimalScale: 4,
								disableField: isMerchantMPA,
								suffix: '%',
							})}
							{this.renderNumberFormat({
								name: 'processingInformation.mcRate',
								value: mcRate,
								label: 'Mastercard Rate',
								decimalScale: 4,
								disableField: isMerchantMPA,
								suffix: '%',
							})}
							{this.renderNumberFormat({
								name: 'processingInformation.discoverRate',
								value: discoverRate,
								label: 'Discover Rate',
								decimalScale: 4,
								disableField: isMerchantMPA,
								suffix: '%',
							})}
							{this.renderNumberFormat({
								name: 'processingInformation.amexRate',
								value: amexRate,
								label: 'Amex Rate',
								decimalScale: 4,
								disableField: isMerchantMPA,
								suffix: '%',
							})}
						</div>
						<div className="row">
							{this.renderNumberFormat({
								name: 'processingInformation.visaTranFee',
								value: visaTranFee,
								label: `Visa ${tranFeeType} Fee`,
								decimalScale: 2,
								disableField: isMerchantMPA,
								prefix: '$',
							})}
							{this.renderNumberFormat({
								name: 'processingInformation.mcTranFee',
								value: mcTranFee,
								label: `Mastercard ${tranFeeType} Fee`,
								decimalScale: 2,
								disableField: isMerchantMPA,
								prefix: '$',
							})}
							{this.renderNumberFormat({
								name: 'processingInformation.discoverTranFee',
								value: discoverTranFee,
								label: `Discover ${tranFeeType} Fee`,
								decimalScale: 2,
								disableField: isMerchantMPA,
								prefix: '$',
							})}
							{this.renderNumberFormat({
								name: 'processingInformation.amexTranFee',
								value: amexTranFee,
								label: `Amex ${tranFeeType} Fee`,
								decimalScale: 2,
								disableField: isMerchantMPA,
								prefix: '$',
							})}
						</div>
					</div>
				)}
				{showInternational && (
					<div className="col col-sml-12">
						<div className="row">
							{this.renderNumberFormat({
								name: 'processingInformation.visaInternationalRate',
								value: visaInternationalRate,
								label: 'Visa International Rate',
								decimalScale: 4,
								disableField: isMerchantMPA || flatRate,
								suffix: '%',
							})}
							{this.renderNumberFormat({
								name: 'processingInformation.mcInternationalRate',
								value: mcInternationalRate,
								label: 'Mastercard International Rate',
								decimalScale: 4,
								disableField: isMerchantMPA || flatRate,
								suffix: '%',
							})}
							{this.renderNumberFormat({
								name: 'processingInformation.discoverInternationalRate',
								value: discoverInternationalRate,
								label: 'Discover International Rate',
								decimalScale: 4,
								disableField: isMerchantMPA || flatRate,
								suffix: '%',
							})}
						</div>
					</div>
				)}
				<div className="col col-sml-12">
					<div className="row">
						<div className="col col-sml-12 col-lrg-6 form__group">
							<div className="form__group__header">
								<label htmlFor="processingInformation.percentCardPresent" className="form__group__label">
									Percent of Card Present Sales (%)
								</label>
								{this.checkIfShouldDisplayAsterisk('processingInformation.percentCardPresent') && required}
							</div>
							<NumericFormat
								className={`input input--med${this.validateField(['percentCardPresent', 'totalSales'])}`}
								id="processingInformation.percentCardPresent"
								name="processingInformation.percentCardPresent"
								value={percentCardPresent}
								placeholder="Percent of Card Present Sales (%)"
								allowNegative={false}
								decimalScale={2}
								inputType="numeric"
								onValueChange={({ floatValue: value }) =>
									this.handleChange({ target: { name: 'processingInformation.percentCardPresent', value } })
								}
								onBlur={this.validateFieldOnBlur}
							/>
						</div>
						<div className="col col-sml-12 col-lrg-6 form__group">
							<div className="form__group__header">
								<label htmlFor="processingInformation.percentCardNotPresent" className="form__group__label">
									Percent of Card-Not-Present Sales (%)
								</label>
								{this.checkIfShouldDisplayAsterisk('processingInformation.percentCardNotPresent') && required}
							</div>
							<NumericFormat
								className={`input input--med${this.validateField(['percentCardNotPresent', 'totalSales'])}`}
								id="processingInformation.percentCardNotPresent"
								name="processingInformation.percentCardNotPresent"
								value={percentCardNotPresent}
								placeholder="Percent of Card-Not-Present Sales (%)"
								allowNegative={false}
								decimalScale={2}
								inputType="numeric"
								onValueChange={({ floatValue: value }) =>
									this.handleChange({ target: { name: 'processingInformation.percentCardNotPresent', value } })
								}
								onBlur={this.validateFieldOnBlur}
							/>
						</div>
						<div className="col col-sml-12 col-lrg-6 form__group">
							<div className="form__group__header">
								<p className="form__group__label">Total Card Sales (must equal 100%):</p>
							</div>
							<div
								id="totalSales"
								className={
									this.getTotalSales() == 100 ? 'type--h4 type--color--success' : 'type--h4 type--color--error'
								}
							>
								{this.getTotalSales()}%
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}

	renderACHProcessingInfoFields({
		averageTicket,
		monthlySalesVolume,
		achEnabled,
		sicCode,
		achPercentFee,
		achPercentFeeCap,
		achTransactionFee,
		achReturnFee,
		achUnauthorizedReturnFee,
		achMonthlyFee,
		achSetupFee,
		isMerchantMPA,
	}) {
		const {
			fields: { tierName },
		} = this.state;
		const isAchqOnly = this.isAchqOnly();
		return (
			<Fragment>
				{(achEnabled || isAchqOnly) && (
					<div className="col col-sml-12">
						<div className="row">
							{isAchqOnly && this.renderSicCode(sicCode, tierName)}
							{this.renderNumberFormat({
								name: 'processingInformation.achTransactionFee',
								value: achTransactionFee,
								label: 'ACH Transaction Fee',
								decimalScale: 2,
								disableField: isMerchantMPA || !isAchqOnly,
								prefix: '$',
							})}
							{this.renderNumberFormat({
								name: 'processingInformation.achPercentFee',
								value: achPercentFee,
								label: 'ACH Percent Fee',
								decimalScale: 2,
								disableField: isMerchantMPA || !isAchqOnly,
								suffix: '%',
							})}
							{this.renderNumberFormat({
								name: 'processingInformation.achPercentFeeCap',
								value: achPercentFeeCap,
								label: 'ACH Percent Fee Cap',
								decimalScale: 2,
								disableField: isMerchantMPA || !isAchqOnly,
								prefix: '$',
							})}
							{this.renderNumberFormat({
								name: 'processingInformation.achReturnFee',
								value: achReturnFee,
								label: 'ACH Return Fee',
								decimalScale: 2,
								disableField: isMerchantMPA || !isAchqOnly,
								prefix: '$',
							})}
							{this.renderNumberFormat({
								name: 'processingInformation.achUnauthorizedReturnFee',
								value: achUnauthorizedReturnFee,
								label: 'ACH Unauthorized Return Fee',
								decimalScale: 2,
								disableField: isMerchantMPA || !isAchqOnly,
								prefix: '$',
							})}
							{this.renderNumberFormat({
								name: 'processingInformation.achMonthlyFee',
								value: achMonthlyFee,
								label: 'ACH Monthly Fee',
								decimalScale: 2,
								disableField: isMerchantMPA || !isAchqOnly,
								prefix: '$',
							})}
							{this.renderNumberFormat({
								name: 'processingInformation.achSetupFee',
								value: achSetupFee,
								label: 'ACH Setup Fee',
								decimalScale: 2,
								disableField: isMerchantMPA || !isAchqOnly,
								prefix: '$',
							})}
							{isAchqOnly &&
								this.renderNumberFormat({
									name: 'processingInformation.averageTicket',
									value: averageTicket,
									label: 'Average Ticket',
									decimalScale: 2,
									allowLeadingZeros: false,
									disableField: false,
									prefix: '$',
								})}
							{isAchqOnly &&
								this.renderNumberFormat({
									name: 'processingInformation.monthlySalesVolume',
									value: monthlySalesVolume,
									label: 'Monthly Sales Volume',
									allowLeadingZeros: false,
									disableField: false,
									prefix: '$',
								})}
						</div>
					</div>
				)}
			</Fragment>
		);
	}

	renderBankingInfo = () => {
		const {
			fields: { bankingInformation },
		} = this.state;
		return (
			<div className="card is-expanded spc--bottom--lrg">
				<div className="card__header">
					<h5>Banking Information</h5>
					{this.state.isIntegratedWithBank ? (
						<p className="type--p2 type--color--success spc--bottom--med">Account successfully connected to Plaid</p>
					) : null}
				</div>
				<div className="card__body">
					<div className="row">
						{this.renderField(
							'bankingInformation.bankName',
							bankingInformation.bankName,
							'Bank Name',
							null,
							null,
							this.state.isIntegratedWithBank
						)}
						<div id="bankingInformation.accountType" className="col col-sml-12 col-lrg-6 form__group">
							<div
								className={`${this.validateField('bankingInformation.accountType') ? ' border--warning radial' : ''}`}
							>
								<div className="form__group__header">
									<p className="form__group__label">Account Type</p>
									{this.checkIfShouldDisplayAsterisk('bankingInformation.accountType') && required}
								</div>
								<div className="flex--primary flex--gap--xxlrg spc--top--sml">
									<div>
										<input
											type="radio"
											name="bankingInformation.accountType"
											id="checking"
											value="checking"
											checked={bankingInformation.accountType === 'checking'}
											onChange={this.handleChange}
											className="input--radio"
											disabled={this.state.isIntegratedWithBank}
										/>
										<label htmlFor="checking">Checking</label>
									</div>
									<div>
										<input
											type="radio"
											name="bankingInformation.accountType"
											id="savings"
											value="savings"
											checked={bankingInformation.accountType === 'savings'}
											onChange={this.handleChange}
											className="input--radio"
											disabled={this.state.isIntegratedWithBank}
										/>
										<label htmlFor="savings">Savings</label>
									</div>
								</div>
							</div>
						</div>
						{this.renderNumberFormat({
							name: 'bankingInformation.routingNumber',
							value: bankingInformation.routingNumber,
							label: 'Routing Number',
							ref: this.accountRoutingInputRef,
							disableField: this.state.isIntegratedWithBank,
							className: 'fs-mask',
						})}
						{this.renderNumberFormat({
							name: 'bankingInformation.accountNumber',
							value: bankingInformation.accountNumber,
							label: 'Account Number',
							ref: this.accountNumberInputRef,
							disableField: this.state.isIntegratedWithBank,
							className: 'fs-mask',
						})}
						{this.renderNumberFormat({
							name: 'bankingInformation.confirmAccountNumber',
							value: bankingInformation.confirmAccountNumber,
							label: 'Confirm Account Number',
							ref: this.confirmAccountNumberInputRef,
							disableField: this.state.isIntegratedWithBank,
							className: 'fs-mask',
						})}
					</div>
				</div>
			</div>
		);
	};

	renderSignerInfoOwnerDropdown = () => {
		const {
			numberOfOwners,
			fields: { totalMinorOwnershipPercent },
		} = this.state;
		let tooltip =
			'An owner is an individual holding at least 25% ownership, or a principal owning less than 25% ownership in the company. For publicly traded entities, government entities, and entities with no individual beneficial owner holding at least twenty-five percent (25%) ownership interest in the entity, please enter the information for at least one principal or officer who has substantial control over the organization.';
		if (this.isSoleProprietor(this.state.fields.ownershipType))
			tooltip = 'A sole proprietor must own 100% of the business.';
		return (
			<div className="row">
				<div className="col col-sml-12 col-lrg-6 form__group">
					<div className="form__group__header">
						<label htmlFor="numberOfOwners" className="form__group__label">
							Number of Owners or Principals:
						</label>
						<i className="icon icon--tny icon--regular--info" data-tooltip={tooltip}></i>
					</div>
					{!this.isSoleProprietor(this.state.fields.ownershipType) ? (
						<select
							className="input input--med input--select"
							id="numberOfOwners"
							name="numberOfOwners"
							value={numberOfOwners}
							onChange={this.handleNumberOfOwnersChange}
						>
							{times(4, index => (
								<option key={index + 1} value={index + 1}>
									{index + 1}
								</option>
							))}
						</select>
					) : (
						<input id="numberOfOwners" className="input input--med" name="numberOfOwners" value="1" disabled={true} />
					)}
					<p className="type--p4 type--color--text--light spc--top--tny"> With 25% or more ownership</p>
				</div>
				{!this.isSoleProprietor(this.state.fields.ownershipType) && (
					<div className="col col-sml-12 col-lrg-6 form__group">
						<div className="form__group__header">
							<p className="form__group__label">Total Other Ownership or Principal Percentage:</p>
						</div>
						<input
							id="totalMinorOwnershipPercent"
							className="input input--med"
							name="totalMinorOwnershipPercent"
							value={parseFloat(totalMinorOwnershipPercent || 0)}
							onChange={this.handleChange}
						></input>
						<p className="type--p4 type--color--text--light spc--top--tny"> With less than 25% ownership</p>
					</div>
				)}
				<div className="col col-sml-12 form__group">
					<div className="form__group__header">
						<p className="form__group__label">Total Ownership or Principal Percentage: </p>
					</div>
					<div
						id="totalOwnershipPercentage"
						className={
							this.getTotalOwnershipPercentage() === 100
								? 'type--h4 type--color--success'
								: 'type--h4 type--color--error'
						}
					>
						{this.getTotalOwnershipPercentage()}%
					</div>
				</div>
			</div>
		);
	};

	isGovernmentOrPublic = ownershipType => includes(['GovernmentEntity', 'PubliclyTraded'], ownershipType);
	isSoleProprietor = ownershipType => includes(['SoleProprietor'], ownershipType);

	renderSignerInfo = () => {
		const { numberOfOwners } = this.state;

		return (
			<div className="card is-expanded spc--bottom--lrg">
				<div className="card__header">
					<h5>Signer Information</h5>
				</div>
				<div className="card__body">
					{!this.isAchqOnly() && this.renderSignerInfoOwnerDropdown()}
					{!this.isAchqOnly()
						? times(numberOfOwners, index => this.renderSignerInfoContent(index))
						: this.renderSignerInfoContent(0)}
				</div>
			</div>
		);
	};

	checkIfOwnerPercentAllowed = ({ value }) => value < 100.01;

	getDocumentsList = () => {
		const {
			processingInformation: { monthlySalesVolume, averageTicket },
			underwritingLevelRules,
		} = this.state;
		if (!underwritingLevelRules) {
			// will be set to state via API call -> this.setUnderwritingLevels
			return [];
		}
		let level = apply(underwritingLevelRules, { monthlyVolume: monthlySalesVolume, averageTicket: averageTicket });
		const documents = [];
		if (level >= 1) {
			documents.push(...level1Documents);
		}
		if (level >= 2) {
			documents.push(...level2Documents);
		}
		if (level >= 3) {
			documents.push(...level3Documents);
		}
		return documents;
	};

	renderDocumentsSection = () => {
		const documents = this.getDocumentsList();
		if (documents.length === 0) return null;

		return (
			<div className="card is-expanded spc--bottom--lrg">
				<div className="card__header">
					<h5>Documents Upload</h5>
				</div>
				<div className="card__body">
					{map(documents, ({ tag, label, tooltip }, index) =>
						this.renderUploadDocumentRow(tag, label, tooltip, index !== documents.length - 1, `${toLower(tag)}_div`)
					)}
				</div>
			</div>
		);
	};

	renderSignerInfoContent = index => {
		const {
			fields: { signerInformationList, ownershipType },
			processingInformation,
		} = this.state;
		const isExchange = get(processingInformation, 'isExchangeTier');
		const labelNumber = !this.isAchqOnly() ? index + 1 : '';
		const name = `signerInformationList.${index}.title`;

		const { maskedValues } = this.state;
		const maskedValueDOB = find(maskedValues, { path: `signerInformationList[${index}].dateOfBirth` });
		let resetValueDOB = this.createResetValueHandler([`signerInformationList[${index}].dateOfBirth`], () =>
			this.handleChange({ target: { name: `signerInformationList.${index}.dateOfBirth`, value: '' } })
		);
		const maskedValueSSN = find(maskedValues, { path: `signerInformationList[${index}].ssn` });
		let resetValueSSN = this.createResetValueHandler([`signerInformationList[${index}].ssn`], () =>
			this.handleChange({ target: { name: `signerInformationList.${index}.ssn`, value: '' } })
		);

		if (this.isSoleProprietor(ownershipType) || this.isAchqOnly())
			signerInformationList[index].ownershipPercentage = 100;
		return (
			<div key={index}>
				<div className="row">
					{!this.isAchqOnly() &&
						this.renderNumberFormat({
							name: `signerInformationList.${index}.ownershipPercentage`,
							value: signerInformationList[index].ownershipPercentage,
							label: 'Ownership Percentage',
							decimalScale: 0,
							index: !this.isAchqOnly() ? index : undefined,
							isAllowed: this.checkIfOwnerPercentAllowed,
							validateFieldsArr: [`signerInformationList.${index}.ownershipPercentage`, 'totalOwnershipPercentage'],
							disableField: this.isSoleProprietor(ownershipType) || this.isAchqOnly(),
						})}
					{this.renderField(
						`signerInformationList.${index}.firstName`,
						signerInformationList[index].firstName,
						`Signer First Name ${labelNumber}`,
						null,
						index
					)}
					{this.renderField(
						`signerInformationList.${index}.lastName`,
						signerInformationList[index].lastName,
						`Signer Last Name ${labelNumber}`,
						null,
						index
					)}
					<div className="col col-sml-12 col-lrg-6 form__group">
						<div className="form__group__header">
							<label htmlFor={name} className="form__group__label">
								Signer Title {labelNumber}
							</label>
							{this.checkIfShouldDisplayAsterisk(name) && required}
						</div>
						<select
							className={`input input--med input--select${this.validateField(name)}`}
							id={name}
							name={name}
							value={signerInformationList[index].title}
							onChange={e => this.handleChange(e, index)}
						>
							<option value="">Please select</option>
							{map(titleOptions, ({ exchangeValue, value, label }) => (
								<option value={isExchange ? exchangeValue : value}>{label}</option>
							))}
						</select>
					</div>

					{!this.isGovernmentOrPublic(ownershipType) && (
						<div className="col col-sml-12 col-lrg-6 form__group">
							<div className="form__group__header">
								<label htmlFor="" className="form__group__label">
									Signer SSN {labelNumber}
								</label>
								{this.checkIfShouldDisplayAsterisk(`signerInformationList.${index}.ssn`) && required}
							</div>
							<MaskedValueInputWrapper
								maskedValue={get(maskedValueSSN, 'value', null)}
								resetValue={resetValueSSN}
								inputClass={'input input--med'}
							>
								<input
									type="text"
									className={'input input--med fs-mask' + this.validateField(`signerInformationList.${index}.ssn`)}
									id={`signerInformationList.${index}.ssn`}
									name={`signerInformationList.${index}.ssn`}
									value={signerInformationList[index].ssn}
									placeholder={`Signer SSN ${labelNumber}`}
									onChange={e => this.handleChange(e, index)}
								/>
							</MaskedValueInputWrapper>
							<p className="type--p4 type--color--text--light spc--top--tny">(do not include dashes (-))</p>
						</div>
					)}
					<div className="col col-sml-12 col-lrg-6 form__group">
						<div className="form__group__header">
							<label htmlFor={`signerInformationList.${index}.dateOfBirth`} className="form__group__label">
								Date of Birth {labelNumber}
							</label>
							{this.checkIfShouldDisplayAsterisk(`signerInformationList.${index}.dateOfBirth`) && required}
						</div>
						<MaskedValueInputWrapper
							maskedValue={get(maskedValueDOB, 'value', null)}
							resetValue={resetValueDOB}
							inputClass={'input input--med'}
						>
							<SingleDatePicker
								name={`signerInformationList.${index}.dateOfBirth`}
								className="fs-mask"
								onChange={date =>
									this.handleChange({ target: { name: `signerInformationList.${index}.dateOfBirth`, value: date } })
								}
								validateField={() => this.validateField(`signerInformationList.${index}.dateOfBirth`)}
								validateFieldOnBlur={e => this.validateFieldOnBlur(e, index)}
								value={signerInformationList[index].dateOfBirth}
							/>
						</MaskedValueInputWrapper>
					</div>

					<div className="col col-sml-12 col-lrg-6 form__group">
						<div className="form__group__header">
							<label htmlFor="" className="form__group__label">
								Signer Cell Phone Number {labelNumber}
							</label>
							{this.checkIfShouldDisplayAsterisk(`signerInformationList.${index}.cellphone`) && required}
						</div>
						<input
							type="text"
							className={'input input--med' + this.validateField(`signerInformationList.${index}.cellPhone`)}
							id={`signerInformationList.${index}.cellPhone`}
							name={`signerInformationList.${index}.cellPhone`}
							value={signerInformationList[index].cellPhone}
							placeholder={`Signer Cell Phone Number ${labelNumber}`}
							onChange={e => this.handleChange(e, index)}
						/>
					</div>
				</div>
				<h6 className="spc--bottom--lrg">Signer Home Address {labelNumber}</h6>
				<div className="row">
					<div className="col col-lrg-12 form__group">
						<AddressAutocomplete
							onSelectAddress={address => this.handleSelectAddress(address, `signerInformationList.${index}.address`)}
						/>
					</div>
				</div>
				<div className="row">
					{this.renderField(
						`signerInformationList.${index}.address.streetAddress`,
						signerInformationList[index].address.streetAddress,
						`Address ${labelNumber}`,
						null,
						index
					)}
					{this.renderField(
						`signerInformationList.${index}.address.city`,
						signerInformationList[index].address.city,
						`City ${labelNumber}`,
						null,
						index
					)}
					<div className="col col-sml-12 col-lrg-6 form__group">
						<div className="form__group__header">
							<label htmlFor={`signerInformationList.${index}.address.state`} className="form__group__label">
								State {labelNumber}
							</label>
							{this.checkIfShouldDisplayAsterisk(`signerInformationList.${index}.address.state`) && required}
						</div>
						{this.renderStateDropdown(
							`signerInformationList.${index}.address.state`,
							signerInformationList[index].address.state
						)}
					</div>
					{this.renderNumberFormat({
						name: `signerInformationList.${index}.address.zip`,
						value: signerInformationList[index].address.zip,
						label: 'Zip Code',
						index: !this.isAchqOnly() ? index : undefined,
					})}
				</div>
			</div>
		);
	};

	renderErrors = (apiSideValidation = false) => {
		const { errorMessage, errorListPaths, errorList, errorApiMessage, errorApiListPaths, errorApiList } = this.state;

		let list = apiSideValidation ? errorApiList : errorList;
		let message = apiSideValidation ? errorApiMessage : errorMessage;
		let listPaths = apiSideValidation ? errorApiListPaths : errorListPaths;

		return (
			<Fragment>
				{message ? <div className={`notes notes--warning spc--bottom--lrg`}>{message}</div> : null}
				{listPaths.length ? (
					<div className={`notes notes--warning spc--bottom--lrg`}>
						<i className="icon"></i>
						<ul className="type--validation__wrapper">
							{list.map(elem => {
								return (
									<li className="type--validation" key={elem.path}>
										<button
											onClick={() => {
												let elemId = elem.path;
												this.scrollTo(elemId);
												this.focusField(elemId);
											}}
										>
											{defaultReactOutput(defaultImplicitParse(elem.message))}
										</button>
									</li>
								);
							})}
						</ul>
					</div>
				) : null}
			</Fragment>
		);
	};

	render() {
		return <Fragment></Fragment>;
	}
}

export default GoPlusBaseClass;
