import React, { Fragment, createRef } from "react";
import AchBaseClass from "./AchBaseClass";
import { withLoader } from "../../common/components";
import { bool, func } from 'prop-types';
import { appService, signatureService } from "../../services";
import { defaultInvalidDates, identifyFullStoryWithToken } from "../../common/utilities";
import { toLower, split, isObject, each, cloneDeep, some, set, includes, filter, orderBy, groupBy, keys, join, map, startCase, every } from 'lodash';
import { decode } from "jsonwebtoken";
import { Notification } from "../../common/components/notifications";
import AppSummary from "./AppSummary";
import { ToggleContainer } from "../../common/components/toggle";
import ClickWrapComponent from "./../EApp/Clickwrap";
import { Schema, merchantMPATemplate } from "../../validation";
import { defaultImplicitParse, defaultReactOutput } from "simple-markdown";
import { ModalWrapper } from "../../common/components/modal-wrapper";

const conditionalRequiredFields = {
    enableSecondaryBankAccountInfo:{
        condition: (state) => state['enableSecondaryBankAccountInfo'],
        fields: [
            'bankAccountInformation.secondaryBankName',
            'bankAccountInformation.secondaryAccountNumber',
            'bankAccountInformation.secondaryRoutingNumber',
        ]
    },
    alternateBankAccountInfo:{
        condition: (state) => state['enableAlternativeBankAccountInfo'],
        fields: [
            'bankAccountInformation.alternateBankName',
            'bankAccountInformation.alternateAccountNumber',
            'bankAccountInformation.alternateRoutingNumber',
        ]
    }
};

const requiredDocs = ['BankStatements', 'ProofOfAddress', 'VoidedCheck', 'DriversLicense'];

const upnKey = 'upn';

function getRequiredFieldsFromToken(token) {
    const { payload } = decode(token, { complete: true });
    if (!payload[upnKey]) return [];
    const upnData = split(payload[upnKey], '|');
    return upnData;
}

function getFlattenedKeys(object, parentKey = '') {
    const flattenedKeys = [];
    if (parentKey && object && (object.type || object.message)) return flattenedKeys;
    each(object, (val, key) => {
        if (!isObject(val)) return;
        const newKey = parentKey ? `${parentKey}.${key}` : key;
        flattenedKeys.push(newKey);
        flattenedKeys.push(...getFlattenedKeys(val, newKey));
    })
    return flattenedKeys;
}

class AchMerchantMpaComponent extends AchBaseClass {
    constructor(props) {
        super(props, [], requiredDocs, conditionalRequiredFields);
        const token = props.location.search.substr(1);
        identifyFullStoryWithToken(token);
        const requiredFields = getRequiredFieldsFromToken(token);
        const template = cloneDeep(merchantMPATemplate);
        const flattenedKeys = getFlattenedKeys(template);
        each(requiredFields, (key) => {
            const lowerKey = toLower(key);
            const matchedKeys = filter(flattenedKeys, flattenedKey => includes(toLower(flattenedKey), lowerKey) 
                && !includes(toLower(flattenedKey), 'corporateaddress')
                && !some(['secondarybankname', 'alternatebankname', 'alternateaccountnumber', 'secondaryaccountnumber', 'secondaryroutingnumber', 'alternateroutingnumber'], field => includes(toLower(flattenedKey), field)));
            const groupedKeys = groupBy(matchedKeys, matchedKey => split(matchedKey, '.').length);
            const sortedKeys = orderBy(keys(groupedKeys), [groupKey => parseInt(groupKey, 10)], ['desc']);
            const mappedKeys = [];
            each(sortedKeys, sortedKey => {
                each(groupedKeys[sortedKey], fieldKey => {
                    if (some(mappedKeys, mappedKey => includes(mappedKey, fieldKey))) return;
                    mappedKeys.push(fieldKey);
                    requiredFields.push(fieldKey)
                    set(template, `${fieldKey}.required`, true);
                });
            });
        })
        requiredFields.push('dba');
        this.mpaSchema = new Schema(template, { strip: false, typecast: true });
        this.requiredFields = requiredFields;

        this.state = {
            downloadUrl: '',
            ip: '',
            timestamp: '',
            ...this.initialState,
            token: this.props.location.search.substr(1),
            initialLoadingInProgress: true,
        };
        this.notificationRef = createRef();
    }

    componentDidMount() {
        this.fetchAndSetData();
    }

    save = () => {
        const mpa = cloneDeep(this.state.fields);
        const { enableSecondaryBankAccountInfo, enableAlternativeBankAccountInfo } = this.state;
        mpa.signature = {
            ip: this.state.ip,
            timestamp: this.state.timestamp,
        }

        const errorList = this.mpaSchema.validate(Object.assign({}, {...mpa, enableSecondaryBankAccountInfo, enableAlternativeBankAccountInfo}));
        const errorListPaths = errorList.map(e => e.path);
        if (errorList.length > 0 || this.validateDocuments().length > 0) {
            this.setState({ errorList, errorListPaths, showValidation: true });
            return;
        }

        this.props.showLoader(true);

        return signatureService.prepareSignatureFileForTransfer(this.state.fields, ['ClickwrapMPA'], this.state.downloadUrl)
        .then(fileForTransfer => appService.saveAchEApp(mpa, fileForTransfer))
        .then(() => {
            return appService.prefillDocumentFields(this.state.fields, 'ACH_application_worksheet', this.achWorksheetFieldNames, false)
            .then((blob) => {
                const prefilledMpa = { file: new File([blob], `mpa_${this.state.appId}.pdf`), fileDescription: '' };
                return new Promise(resolve => {
                    this.setState({
                        localFiles: {
                            ...this.state.localFiles,
                            Other: [ prefilledMpa ]
                        }
                    }, resolve);
                });
            });
        })
        .then(this.saveFiles)
        .then(() => {
            this.props.showLoader(false);
            const redirectPage = 'thankyou?noSign=true';
            this.props.history.push(`/merchant/${redirectPage}`);
        }).catch(err => {
            this.handleError(err);
        })
    }

    renderUploadSignedMpa() {
        return null;
    }

    renderSendFormToMerchant(){
        return null;
    }

    fetchData = () => {
        const token = this.props.location.search.substr(1);
        return appService.getEAppForMerchant(token);
    }
    fetchAndSetData = () => {
        this.props.showLoader(true);
            this.fetchData()
            .then(mpa => {
                this.props.showLoader(false);
                if (mpa.signatureDoc.status === 'Signed') {
                    this.setState({ errorMessageOnLoad: 'Signature documents have already been submitted successfully'});
                } else {
                    const secondaryBankInfo = [mpa.bankAccountInformation.secondaryBankName, mpa.bankAccountInformation.secondaryAccountNumber, mpa.bankAccountInformation.secondaryRoutingNumber]
                    const alternateBankInfo = [mpa.bankAccountInformation.alternateBankName, mpa.bankAccountInformation.alternateAccountNumber, mpa.bankAccountInformation.alternateRoutingNumber]
                    const enableSecondaryBankAccountInfo = some(secondaryBankInfo, (val) => val);
                    const enableAlternativeBankAccountInfo= some(alternateBankInfo, (val) => val);
                    const sameAsBusiness = every(mpa.corporateAddress, (value, key) => {
                        return toLower(value) === toLower(mpa.businessInformation.businessAddress[key]);
                    });
                    this.setState({enableSecondaryBankAccountInfo, enableAlternativeBankAccountInfo, sameAsBusiness, fields: defaultInvalidDates(mpa, ''), appId: mpa.appId,initialLoadingInProgress: false}, this.createFormSnapshot);
                }
            }).catch(err => {
                console.log('getEAppForMerchant error', err);
                this.props.showLoader(false);
                let errorMessageOnLoad = 'An error occurred: ' + err;
                if (toLower(err) === "unauthorized" || err == 401) {
                    const token = this.props.location.search.substr(1);
                    if (decode(token, { complete: true }).payload.actort) {
                        errorMessageOnLoad = "The link you followed has expired. To complete your application, please reach out to your agent to request a new eApp link."
                    }
                }
                this.setState({ errorMessageOnLoad, initialLoadingInProgress: false });
            });
    }

    renderUploadDocuments  = () => {
        return (
            <Fragment>
                <div className="separator separator--grey1 spc--bottom--sml" />
                <div className={`details__content`}>
                    {this.renderUploadDocumentRow('BankStatements', 'Bank Statements', 'Please include your three most recent bank statements', true, 'bankstatements_div')}
                    {this.renderUploadDocumentRow('VoidedCheck', 'Voided Check', 'Or Bank Letter', true, 'voidedcheck_div')}
                    {this.renderUploadDocumentRow('ProofOfAddress', 'Proof of Address', 'Utility bill or Lease Agreement', true, 'proofofaddress_div')}
                    {this.renderUploadDocumentRow('DriversLicense', 'Drivers License', null, true, 'driverslicense_div')}
                </div>
            </Fragment>
        );
    };
    renderRep2Field = () => {}
    renderAdditionalTransactionInformation=()=>{}
    renderHasExistingFidelityAccount=()=>{}

    renderFooter() {
        const { downloadUrl, showValidation, errorList, errorListPaths, fields } = this.state;
        const incompleteDocs = this.validateDocuments();
        const disableConfirmAndSignButton = (!downloadUrl) || (showValidation && (errorListPaths.length > 0 || incompleteDocs.length > 0));
        const confirmationErrors = [];
        if (incompleteDocs) {
            confirmationErrors.push('Upload required files to save and proceed.');
        }
        if (!downloadUrl) {
            confirmationErrors.push('Terms and Conditions need to be acknowledged before you can save and proceed.');
        }
        const tooltip = join(confirmationErrors, '\n');
        return (
            <React.Fragment>
                <ClickWrapComponent
                    groupKey='group-profitstars'
                    appId={fields.appId.toString()}
                    setInfoToApp={this.setInfoToApp}
                    handleError={this.handleSubmitError}
                    allowDisagreed={true}
                />

                {showValidation && (errorListPaths.length > 0 || incompleteDocs.length > 0) ? (
                    <div className="spc--bottom--med note note--warning">
                        <ul>
                            {errorList.map((elem, i) => {
                                return (<li key={i}>
                                    <div className="anchor"
                                        onClick={() => this.onErrorClick(elem)}
                                        onKeyDown={(e) => this.onEnterKeyDownHandler(e, () => this.onErrorClick(elem))}
                                    >
                                        <i className="icon icon--nano icon--text-top icon--alert spc--right--tny"></i> {defaultReactOutput(defaultImplicitParse(elem.message))}
                                    </div></li>);
                            })}
                            {map(incompleteDocs, (fileTag, index) => (
                                <li className="item" key={`${fileTag}.${index}`}>
                                    <div
                                        className="anchor"
                                        onKeyDown={(e) => this.onEnterKeyDownHandler(e, () => this.scrollTo(toLower(fileTag).replace(/[.]/g, '_') + '_div'))}
                                        onClick={() => this.scrollTo(toLower(fileTag).replace(/[.]/g, '_') + '_div')}
                                    >
                                        <i className="icon icon--nano icon--text-top icon--alert spc--right--tny"></i><b> {startCase(fileTag)}</b> document is required
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                ) : null}
                <div className="type--right">
                    <button className='btn btn--primary btn--med datatooltip--top--left' onClick={this.save} data-tooltip={disableConfirmAndSignButton ? tooltip : null} disabled={disableConfirmAndSignButton || this.props.isLoading}>Confirm</button>
                </div>
            </React.Fragment>
        )
    }

    setInfoToApp = async (downloadUrl, ip, timestamp) => {
        this.setState({ downloadUrl, ip, timestamp });
    };

    handleSubmitError = (e) => 
    {
        this.setState({error: e})
    }

    renderSection(title, component){
        return <Fragment>
            <div className={`card--primary__header`}>
                <div className="flex--primary">
                    <h6 className="spc--bottom--sml">{title}</h6>
                </div>
            </div>
            {component}
        </Fragment>
    }

    render() {
        const { fields, errorMessageOnLoad, initialLoadingInProgress, modal } = this.state;
        const { isLoading } = this.props;
        
        if (errorMessageOnLoad) {
            return <div id="main-div" class="l--content l--content--lrg">
                    <div class="note note--warning">
                        {errorMessageOnLoad}
                    </div>
                </div>
        }
        
        if (initialLoadingInProgress) {
            return null;
        }

        return (
            <div id="main-div" className="l--content l--content--med">
                {this.renderErrors()}
                <Notification ref={this.notificationRef}/>
                <ModalWrapper modal={modal} onModalClose={this.openCloseModal} />
                <fieldset disabled={isLoading}>
                    <div className="align--h--center l--content--med">
                        <div className="flex--tertiary flex--align--bottom">
                            <AppSummary app={fields} isEappWebForm={false} showLeadDetailsLink={false} />
                        </div>
                        <div>
                            <ToggleContainer>
                                <div className="card--primary card--sml spc--bottom--med">
                                    {this.renderSection('Business Information', this.renderBusinessInfo()) }
                                </div>
                                <div className="card--primary card--sml spc--bottom--med">
                                    {this.renderSection('Signer Information', this.renderSignerInformation()) }
                                </div>
                                <div className="card--primary card--sml spc--bottom--med">
                                    {this.renderSection('Banking Information', this.renderBankingInfo()) }
                                </div>
                                <div className="card--primary card--sml spc--bottom--med">
                                    {this.renderSection('Mailing Address', this.renderMailingInfo()) }
                                </div>
                                <div className="card--primary card--sml spc--bottom--med">
                                    {this.renderSection('Upload documents', this.renderUploadDocuments(), 'This will be visible to merchants') }
                                </div>
                                <div className="card--primary card--sml spc--bottom--med">
                                    {this.renderSection('Other Notes', this.renderOtherNotes(), 'This will be visible to merchants') }
                                </div>
                            </ToggleContainer>
                            {this.renderFooter()}   
                        </div>
                    </div>
                </fieldset>
            </div>
        );
    }
}

AchMerchantMpaComponent.propTypes = {
    isLoading: bool,
    showLoader: func.isRequired,
};

export default withLoader(AchMerchantMpaComponent);