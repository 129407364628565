import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {times, some} from 'lodash';

class CustomFieldsSelectionComponent extends Component {

    get customValues() {
        const {values} = this.props.filter;

        let textElements = [];

        const indexOfCustom01 = textElements.findIndex(e => e.key === "Custom01");
        const indexOfCustom02 = textElements.findIndex(e => e.key === "Custom02");
        const indexOfCustom03 = textElements.findIndex(e => e.key === "Custom03");
        const indexOfCustom04 = textElements.findIndex(e => e.key === "Custom04");

        if(values.custom01 != null && indexOfCustom01 === -1) {
            textElements.push({key:'Custom01', value: values.custom01})
        }
        if(values.custom02 != null && indexOfCustom02 === -1) {
            textElements.push({key:'Custom02', value: values.custom02})
        }
        if(values.custom03 != null && indexOfCustom03 === -1) {
            textElements.push({key:'Custom03', value: values.custom03})
        }
        if(values.custom04 != null && indexOfCustom04 === -1) {
            textElements.push({key:'Custom04', value: values.custom04})
        }

        return textElements;
    }

    filterRemoved = e => {
        this.props.onFilterRemoved({
            id:'custom',
            emptyValue: null
        });
    };

    renderCustomField = e => {
        return (
            <span key={e.key} className="spc--right--sml">
                {e.key}: {e.value}
            </span>
        )
    };

    render() {
        return (
            <div className="filter__tag">
                {this.customValues.map(i => {
                    return this.renderCustomField(i);
                })}
                <button onClick={this.filterRemoved} className="btn btn--reset filter__tag__clear"></button>
            </div>
        );
    }
}

CustomFieldsSelectionComponent.propTypes = {
    filter: PropTypes.object.isRequired,
    onFilterRemoved: PropTypes.func.isRequired
};

export default CustomFieldsSelectionComponent;