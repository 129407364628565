import React, { Fragment, Component, createRef } from 'react';
import { map, isEmpty, get, noop } from 'lodash';

import { withContext, withLoader } from './../../common/components';
import { appService } from '../../services/appService';
import { ModalWrapper, modalNames } from './../../common/components/modal-wrapper';
import { Notification } from '../../common/components/notifications';
import { MerchantContext } from '../MerchantDetails';
import { openFile } from '../../common/utilities';
import { canPreviewFile } from '../../common/utilities/canPreviewFile';
import MerchantInfoHeader from '../MerchantEquipment/components/MerchantInfoHeader';

class MerchantFilesComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dirty: false,
			files: null,
			modal: {
				name: modalNames.none,
				data: null,
			},
		};

		this.notificationRef = createRef();
	}

	get addNotification() {
		return get(this.notificationRef, 'current.addNotification', noop);
	}

	componentDidMount() {
		this.fetchData();
	}

	fetchData = () => {
		const {
			showLoader,
			match: {
				params: { appid: appId },
			},
		} = this.props;

		showLoader(true);

		appService
			.getEApp(appId)
			.then(({ files }) => {
				this.setState({ files }, showLoader);
			})
			.catch(err => {
				console.log('getEApp error', err);
				showLoader();
				this.setState({ errorMessage: 'An error occurred: ' + err });
			});
	};

	handleFileDownload = (parentId, fileId, fileName, isDownload = false) => () => {
		const { showLoader } = this.props;
		showLoader(true);
		appService
			.getMerchantFile(parentId, fileId, fileName)
			.then(url => {
				if (url.errorMessage) {
					this.setState({ errorMessage: 'An error occurred: ' + url.errorMessage });
				} else {
					openFile(url, fileName, !isDownload, false);
					if (isDownload) {
						const addNotification = get(this.notificationRef, 'current.addNotification', noop);
						addNotification({
							message: 'Successfully downloaded',
							success: true,
						});
					}
				}
				showLoader();
			})
			.catch(err => {
				showLoader();
				this.setState({ errorMessage: 'An error occurred: ' + err });
			});
	};

	onDelete = ({ fileId, fileName, noteId }) => () => {
		this.openCloseModal({
			name: modalNames.confirmAction,
			data: {
				question: 'Are you sure you want to delete ' + fileName + '?',
				onConfirm: this.deleteFile(fileName, fileId, noteId),
			},
		});
	};

	deleteFile = (fileName, fileId, noteId) => () => {
		const { showLoader, history } = this.props;

		showLoader(true);

		appService
			.deleteMerchantFile(fileId, noteId)
			.then(({ refNum: ref }) => {
				this.addNotification({
					ref,
					success: true,
					message: `${fileName} successfully deleted`,
					onClose: () => history.go(),
				});
				showLoader();
			})
			.catch(ex => {
				console.log('Error deleting file');
				console.log(ex);
				this.setState({ errorMessage: ex }, showLoader);
			});
	};

	openCloseModal = modal => this.setState({ modal });

	renderNewFile = () => {
		const {
			match: {
				params: { appid: appId },
			},
			history,
		} = this.props;
		return (
			<button
				type="button"
				className="btn btn--med btn--primary"
				onClick={() =>
					this.openCloseModal({
						name: modalNames.newFile,
						data: {
							appId,
							history,
							addNotification: this.addNotification,
						},
					})
				}
			>
				<i className="icon icon--sml icon--add--white"></i>
				<span>Add File</span>
			</button>
		);
	};

	renderFileDownloadButtons = (parentId, fileId, fileName) => (
		<Fragment>
			{canPreviewFile(fileName) && (
				<button
					onClick={this.handleFileDownload(parentId, fileId, fileName)}
					className="btn btn--med btn--link datatooltip--left datatooltip--auto"
					data-tooltip="View"
				>
					<i className="icon icon--sml icon--view--light" />
				</button>
			)}
			<button
				onClick={this.handleFileDownload(parentId, fileId, fileName, true)}
				className="btn btn--med btn--link datatooltip--left datatooltip--auto"
				data-tooltip="Download"
			>
				<i className="icon icon--sml icon--save--light" />
			</button>
		</Fragment>
	);

	renderFiles = () => {
		const { files } = this.state;

		return isEmpty(files) ? (
			<tr>
				<td className="pos--rel">
					<div className="table--emptystate">
						<div className="table--emptystate--img"></div>
						<div className="table--emptystate--title">0 Results</div>
						<p className="table--emptystate--text">No files found. Add a new file to view it in the list.</p>
					</div>
				</td>
			</tr>
		) : (
			map(files, ({ parentId, fileName, fileTag, fileDescription, fileId }, index) => {
				return (
					<tr key={fileId}>
						<td>{fileName}</td>
						<td>{fileTag}</td>
						<td className="is-note">{fileDescription}</td>
						<Fragment>
							<td className="type--right">{this.renderFileDownloadButtons(parentId, fileId, fileName)}</td>
						</Fragment>
					</tr>
				);
			})
		);
	};

	render() {
		const { files, errorMessage } = this.state;
		const { merchant } = this.props;

		return (
			<div id="main-div" className="l--content">
				<Notification ref={this.notificationRef} />
				<ModalWrapper modal={this.state.modal} onModalClose={this.openCloseModal} />
				{errorMessage && <div className="type--validation spc--bottom--lrg">{errorMessage}</div>}
				<header>
					<MerchantInfoHeader merchant={merchant} />
					<div className="flex--secondary spc--bottom--xlrg">
						<h4>Files</h4>
						{this.renderNewFile()}
					</div>
				</header>
				{!isEmpty(files) && (
					<div className="table__wrapper">
						<table className="table table--fixed table--primary">
							<colgroup>
								<col width={200} />
								<col width={200} />
								<col width={200} />
								<col width={120} />
							</colgroup>
							<thead>
								<tr>
									<th>File</th>
									<th>File Type</th>
									<th>File Name</th>
									<th></th>
								</tr>
							</thead>
							<tbody>{this.renderFiles(files)}</tbody>
						</table>
					</div>
				)}
			</div>
		);
	}
}

export default withLoader(withContext(MerchantFilesComponent, MerchantContext, 'merchant'));
