import React from 'react';
import PropTypes from 'prop-types';

export default class DropdownContainer extends React.Component{
    constructor(props){
        super(props);

        let selectedValue = this.props.value;
        // check if value is valid
        let selectedOption;
        if (selectedValue) selectedOption = this.props.options.find((val) => val.value == selectedValue);

        // if no valid selection, try to get default
        if (!selectedOption) {
            let defaultOpt = this.props.options.find((val) => val.default);
            if (defaultOpt) selectedValue = defaultOpt.value;
        }
        
        this.state = {
            selectedValue: selectedValue
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.value != this.state.selectedValue && (this.props.disabled || prevProps.value != this.state.selectedValue)) {
            this.setState({
                selectedValue: this.props.value,
            });
        }
    }

    handleChange = (e) => {
        if(this.props.toBooleanTrueFalse && (e.target.value === "true" || e.target.value === "false")){
            let valueBoolean = e.target.value === "true";
            this.setState({ selectedValue: valueBoolean })
            this.props.onChange({target:{name:e.target.name, value: valueBoolean}});
            return;
        }
        this.setState({ selectedValue: e.target.value })
        if (this.props.onChange)
            this.props.onChange(e);
    }

    getSelectedOption = () => {
        if (this.state.selectedValue) {
            return this.props.options.find((val) => val.value == this.state.selectedValue);
        }
        return null;
    }


    render() {
        const { type, divClass, selectClass, labelClass, labelText, wrapperClass, selectName, hideSelect, disabled, tooltip, required } = this.props;
        const { selectedValue } = this.state;
        const selectedOption  = this.getSelectedOption();

        return (
            <React.Fragment>
                <div className={divClass}>
                    <div className={wrapperClass}>
                        <div className={`flex--primary ${required ? "required" : ""}`}>
                            <label className={labelClass}>{labelText}</label>
                            {tooltip && <i className="icon icon--tiny icon--info datatooltip--200 spc--left--tny" data-tooltip={tooltip}/>}
                        </div>
                        {type === 'select' && (hideSelect ? <div className="input input--med input--fake">{selectedValue}</div> : (
                            <select disabled={disabled} id={selectName} name={selectName} className={selectClass} onChange={this.handleChange} value={selectedValue}>
                                {
                                    this.props.options.map((i, idx) => {
                                        return <option key={i.value} value={i.value}>{i.text}</option>
                                    })
                                }
                            </select>
                        ))}
                    </div>
                    {type !== 'select' && (hideSelect ? <div className="input input--med input--fake spc--bottom--med">{selectedValue}</div> : (
                        this.props.options.map((i, idx) => {
                            //console.log(selectedValue + ' - ' + i.value);
                            return (
                                <div key={i.value} className = "display--ib spc--bottom--med spc--right--med">
                                    <input className="input--radio" type='radio' name={selectName} id={selectName + '_' + i.value} value={i.value} onChange={this.handleChange}
                                        checked={selectedValue == i.value} disabled={disabled}/>
                                    <label className="label" htmlFor={selectName + '_' + i.value}>{i.text}</label>
                                </div>
                                )
                        })
                    ))}
                    {selectedOption ? selectedOption.children : null}
                </div>
            </React.Fragment>
        )
    }
}

DropdownContainer.propTypes = {
    selectName: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
    labelText: PropTypes.string,
    divClass: PropTypes.string,
    selectClass: PropTypes.string,
    labelClass: PropTypes.string,
    options: PropTypes.array,
    wrapperClass: PropTypes.string,
    type: PropTypes.oneOf(['radio', 'select']),
    hideSelect: PropTypes.bool,
    disabled: PropTypes.bool,
    tooltip: PropTypes.string,
    required: PropTypes.bool,
}

DropdownContainer.defaultProps = {
    checked: false,
    toBooleanTrueFalse: false,
    divClass: '',
    selectClass: 'select',
    labelClass: 'label',
    wrapperClass: '',
    type: 'select',
    hideSelect: false,
    disabled: false,
    required: false,
}