import React, {Component, createRef, Fragment} from 'react';
import { isEmpty, noop, replace } from 'lodash';
import PropTypes from 'prop-types';
import { DayPicker } from 'react-day-picker';
import { PatternFormat } from 'react-number-format';
import moment from 'moment';
import Menu, { SubMenu, Item as MenuItem } from 'rc-menu';

class SingleDatePickerComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            date: null,
            displaySelector: false,
            errors: {
                date: null
            },
            activeKeys: [],
        }
        this.dateRef = createRef();
    }

    componentDidMount = () => {
        const { value } =  this.props;
        const date = this.getMomentDate(value);
        date && this.setState({date: date.format(this.props.dateFormat) });
    };

    componentWillReceiveProps = (nextProps) => {
        const { value } =  nextProps;
        const date = this.getMomentDate(value);
        date && this.setState({date: date.format(this.props.dateFormat) });
    };

    handleOnChange = (value) =>{
        const { onChange } = this.props;
        let date = this.getMomentDate(value);
        let errors = { date: null }
        if(date == null) 
        {
            if(value !== null && value !== undefined && value !== "") {
                errors.date = "Invalid date"
            }
            onChange(null)
            this.setState({date: null, errors});
            return;
        }
        
        date = this.handleDateIfOutOfRange(date);
        onChange(date.format(this.props.dateFormat));
        this.setState({ errors, activeKeys: [] });
    }

    getMomentDate = (date) => {
        const {dateFormat} = this.props;
        let momentDate = moment(date, dateFormat, true);
        if(!momentDate.isValid()){
            if(replace(date, /\D/g, "").length >= 8){
                momentDate = moment(new Date(date), dateFormat, false);
            }
        }
        return momentDate.isValid() ? momentDate : null; 
    }

    handleDateIfOutOfRange = (date) =>{
        const { disabledDays: { before, after} } = this.props;

        if(after && date.isAfter(after))
        {
            date = this.getMomentDate(after);
        }
        else if(before&& date.isBefore(before))
        {
            date = this.getMomentDate(before);
        }
        return date;
    }

    onOpenChange = activeKeys => {
        this.setState({ activeKeys });
	};

    render() {
        const { name, dateFormat, onBlur, validateField, disabledDays, disabled, className } = this.props;
        const { errors, date, activeKeys } = this.state;

        return (
            <Fragment>
                <Menu
                    disabledOverflow={true}
                    mode={"horizontal"}
                    motion={"slide-up"}
                    triggerSubMenuAction={"click"}
                    openKeys={activeKeys}
                    onOpenChange={this.onOpenChange}
                    disabled={disabled}
                >
                    <SubMenu
                        popupClassName={`rc-menu-calendar ${className}`}
                        className={`DayPickerInput ${className}`}
                        mode={"vertical-right"}
                        key={"custom"}
                        title={
                            <PatternFormat
                                name={name}
                                id={name}
                                value={date}
                                autoComplete={"none"}
                                format="##/##/####"
                                className={`input ${className} ${validateField(errors)}`}
                                placeholder={dateFormat}
                                mask={[
                                    "M",
                                    "M",
                                    "D",
                                    "D",
                                    "Y",
                                    "Y",
                                    "Y",
                                    "Y",
                                ]}
                                onValueChange={(values) => this.handleOnChange(values.formattedValue)}
                                onBlur={onBlur}
                            />
                        }
                    >
                        <MenuItem key="custom.menuItem" disabled>
                            <div className={`inputfromto__body inputfromto__body--noseparator ${className}`}>
                                <style>
                                    {`
                                        ${
                                            isEmpty(disabledDays)
                                                ? `
                                                .DayPicker-Day--disabled:hover:after { display: none; }
                                            `
                                                : `
                                                .DayPicker-Day--disabled:hover:before {
                                                    content: 'You cannot select a future date.';
                                                }
                                            `
                                        }
                                    `}
                                </style>
                                <DayPicker
                                    mode="single"
                                    showOutsideDays
                                    selected={date && new Date(date)}
                                    onDayClick={this.handleOnChange}
                                    disabled={disabledDays}
                                    ref={this.dateRef}
                                />
                            </div>
                        </MenuItem>
                    </SubMenu>
                    <div style={{color: "red"}}>
                        {errors && errors.date}
                    </div>
                </Menu>
            </Fragment>
            
        );
    }
}

SingleDatePickerComponent.propTypes = {
    value: PropTypes.any,
    name: PropTypes.any,
    onChange: PropTypes.func,
    disabledDays: PropTypes.any,
    isFilter: PropTypes.bool,
    validateField: PropTypes.func,
    dateFormat: PropTypes.any,
    onBlur: PropTypes.func,
    className: PropTypes.string,
};

SingleDatePickerComponent.defaultProps = {
    onChange: () => console.warn("Provide onChange function to get changed value"),
    isFilter: true,
    disabledDays:{
        after: moment().subtract(1, 'day').toDate(),
    },
    onBlur: noop,
    validateField: (errors) => errors && errors.date ? "is-invalid" : "",
    dateFormat: process.env.REACT_APP_DISPLAY_DATE_FORMAT,
    className: '',
};

export default SingleDatePickerComponent;