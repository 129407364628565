import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { identity } from 'lodash';

function withBlock(WrappedComponent) {
	class HistoryBlockComponent extends Component {
		constructor(props) {
			super(props);

			this.state = {
				shouldBlock: false,
			};
		}

		componentDidMount = () => {
			const { history } = this.props;

			this.unblock = history.block((location, action) => {
				const { shouldBlock } = this.state;
				if (shouldBlock) {
                    return `Are you sure you want to exit without saving?`;
				}
			});
		};

		componentWillUnmount = () => {
			if (this.unblock) {
				this.unblock();
			}
		};

		handleBlockChange = async shouldBlock => {
			return new Promise(resolve => {
				this.setState({ shouldBlock }, resolve);
			});
		};

		render = () => {
			const {  ...rest } = this.props;
			return <WrappedComponent handleBlockChange={this.handleBlockChange}  {...rest} />;
		};
	}

	HistoryBlockComponent.propTypes = {
		history: PropTypes.object,
	};
	return withRouter(HistoryBlockComponent);    
}
export default withBlock;
