import React from 'react';

import { renderIf } from '../../utilities';

const emptyGrid = ({fetchingData, emptyMessage}) => (
    <div className="loader__holder">
        {fetchingData ? (
            <div>
                <div className="loader--progress"/>
                <div className="emptystate">
                    <p className="emptystate__message">Loading...</p>
                </div>
            </div>
        ) : (
            <div className="emptystate">
                <div className="emptystate__title">
                        0 Results
                </div>
                {renderIf(emptyMessage)(
                    <p className="emptystate__message">
                        {emptyMessage}
                    </p>
                )}
            </div>
        )}
    </div>
);

export default emptyGrid;