import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';
import { get } from 'lodash';

class ZeroAsEmptyNumberFormat extends Component {

    constructor(props){
        super(props)
        this.inputRef = createRef();
    }

    get displayedValue(){
        const { value } = this.props;
        if(value === "0" || value === 0){
            return get(this.inputRef, 'current.value', '');
        }
        return value;
    }

    onChange(values){
        const { onValueChange } = this.props;
        const isValidValue = /^0*\.0*$/.test(values.value)
        if(isValidValue || (values.floatValue === undefined || isNaN(values.floatValue)))
        {
            values.floatValue = 0;
        }
        onValueChange(values);
    }

	render = () => {
        const componentProps = {
            ...this.props,
            value: this.displayedValue,
            onValueChange: (values) =>{
                this.onChange(values)
            },
        };

        return <NumericFormat 
            {...componentProps}
            getInputRef={this.inputRef}
        />;
	};
}

ZeroAsEmptyNumberFormat.propTypes = {
	value: PropTypes.string.isRequired,
	onValueChange: PropTypes.func.isRequired,
	placeholder: PropTypes.string,
	prefix: PropTypes.string,
};

export default ZeroAsEmptyNumberFormat;

