import React from 'react';

const logo = (props) => (
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width={props.width} viewBox="-0.901 328.226 1920.901 424.686"
	 enableBackground="new -0.901 328.226 1920.901 424.686" xmlSpace="preserve">
	<title>dark</title>
	<g id="Layer_2">
		<g id="Layer_1-2">
			<g id="Layer_1-2-2">
				<g id="Logo_1">
					<path d="M590.683,426.607c11.199,4.076,21.541,10.204,30.494,18.07l-22.589,29.703c-6.078-4.717-12.813-8.521-19.99-11.294
						c-6.819-2.396-14.005-3.58-21.233-3.501c-30.645,0-45.967,23.266-45.967,69.798c-1.492,17.903,2.74,35.818,12.085,51.162
						c8.005,10.499,20.7,16.339,33.882,15.586c6.787,0.088,13.529-1.101,19.878-3.501c7.466-3.063,14.614-6.846,21.346-11.294
						l22.589,31.51c-18.51,15.395-41.884,23.72-65.958,23.492c-17.769,0.475-35.32-3.982-50.71-12.875
						c-14.529-8.644-26.104-21.478-33.205-36.819c-8.021-17.716-11.885-37.032-11.294-56.471
						c-0.438-19.681,3.415-39.221,11.294-57.261c7.03-15.83,18.582-29.229,33.205-38.513c15.084-9.296,32.546-14.004,50.258-13.554
						C566.987,420.606,579.15,422.558,590.683,426.607z M799.736,594.324c2.784,3.989,6.727,7.024,11.294,8.697l-10.503,32.753
						c-9.996-0.486-19.725-3.393-28.349-8.471c-7.672-5.116-13.555-12.499-16.828-21.12c-6.482,9.718-15.453,17.519-25.977,22.588
						c-10.955,5.106-22.927,7.655-35.012,7.454c-17.789,0.978-35.206-5.345-48.226-17.506
						c-11.883-11.973-18.298-28.317-17.732-45.176c-1.037-19.878,8.007-38.94,24.057-50.711
						c20.552-12.938,44.66-19.063,68.895-17.506h25.411v-11.294c1.129-10.062-2.168-20.117-9.035-27.558
						c-8.457-6.031-18.793-8.836-29.139-7.906c-18.487,0.82-36.72,4.635-53.986,11.294l-11.294-33.882
						c23.647-9.475,48.843-14.491,74.315-14.796c52.179,0,78.269,23.379,78.269,70.137v85.384
						C795.521,582.821,796.851,588.921,799.736,594.324z M747.444,575.464V534.24h-20.441c-31.85,0-47.662,11.858-47.662,35.576
						c-0.51,8.188,2.115,16.265,7.342,22.588c5.668,5.705,13.554,8.636,21.571,8.02C725.017,600.284,740.23,590.595,747.444,575.464z
						M949.723,426.607l-8.471,47.773c-5.909-1.486-11.978-2.245-18.071-2.259c-11.056-0.636-21.726,4.172-28.573,12.876
						c-8.394,11.784-14.063,25.286-16.603,39.529v108.649h-49.355V429.883h42.805l4.631,40.094
						c3.807-13.041,11.156-24.771,21.232-33.883c8.937-7.508,20.293-11.521,31.963-11.294
						C936.146,424.375,943.037,424.984,949.723,426.607z M1126.814,347.548v285.629h-43.596l-3.501-25.864
						c-6.187,9.579-14.594,17.521-24.508,23.153c-10.337,5.796-22.035,8.721-33.883,8.471c-22.744,1.127-44.451-9.599-57.374-28.349
						c-14.562-23.701-21.536-51.284-19.991-79.059c-0.346-19.041,3.07-37.962,10.053-55.68c5.752-15.186,15.652-28.451,28.573-38.287
						c29.13-21.173,69.385-17.758,94.532,8.019V342.24L1126.814,347.548z M1059.05,596.019c7.557-5.294,13.971-12.054,18.86-19.877
						v-93.854c-5.005-6.383-11.081-11.848-17.957-16.15c-6.336-3.688-13.564-5.564-20.895-5.422
						c-12.73-0.226-24.581,6.478-30.945,17.506c-8.646,16.294-12.558,34.682-11.294,53.083c-1.398,18.636,2.065,37.315,10.051,54.212
						c5.896,10.525,17.198,16.853,29.252,16.376c7.914-0.014,15.678-2.159,22.476-6.212L1059.05,596.019z M1209.261,342.24v290.937
						h-49.468V347.774L1209.261,342.24z M1331.464,429.883l-67.765,90.353l74.315,112.941h-56.471l-70.25-111.474l67.765-92.611
						L1331.464,429.883z M1503.36,440.386c10.646,12.569,16.1,28.727,15.247,45.177v147.614h-49.355V493.468
						c0.861-8.995-1.413-18.013-6.438-25.524c-4.75-5.176-11.638-7.848-18.636-7.229c-8.205,0.012-16.166,2.798-22.588,7.906
						c-7.815,6.361-14.498,13.998-19.765,22.588v141.968h-49.355V429.883h42.805l3.84,26.653
						c14.182-20.287,37.365-32.385,62.118-32.414c15.892-0.946,31.417,5.024,42.578,16.377L1503.36,440.386z M1709.252,449.76
						c17.925,22.314,26.939,50.483,25.299,79.059c0.563,19.437-3.299,38.746-11.294,56.471c-7.012,15.688-18.53,28.938-33.092,38.062
						c-15.446,9.324-33.24,14.027-51.275,13.553c-26.646,1.535-52.568-8.999-70.588-28.687c-17.988-22.293-27.08-50.457-25.525-79.06
						c-0.555-19.435,3.307-38.743,11.295-56.47c7.34-15.661,19.123-28.818,33.882-37.836c15.524-9.235,33.328-13.931,51.389-13.553
						c26.625-1.472,52.501,9.103,70.476,28.8L1709.252,449.76z M1594.503,528.818c-1.178,18.504,2.729,36.978,11.295,53.422
						c12.508,18.196,37.398,22.809,55.596,10.301c0.065-0.045,0.131-0.091,0.197-0.137c3.93-2.799,7.365-6.234,10.164-10.164
						c8.473-16.396,12.374-34.771,11.295-53.195c1.114-18.706-2.867-37.364-11.521-53.986c-6.987-11.235-19.541-17.771-32.753-17.054
						c-29.439,0.15-44.197,23.604-44.273,70.362V528.818z M1786.503,633.854h-54.438l67.766-107.521l-60.537-96.451h56.471
						l33.883,69.007l33.882-69.459h53.647L1786.503,633.854z M1864.319,622.56l5.761,11.295H1920l-49.581-76.01l-3.501,5.647
						c-11.521,17.506-17.506,27.67-2.823,58.391L1864.319,622.56z"/>
					<path fill="#E51646" d="M193.017,328.235C86.983,327.177,0.167,412.275-0.891,518.309
						c-1.059,106.033,84.04,192.849,190.073,193.907c106.033,1.06,192.849-84.039,193.908-190.073
						c0.003-0.297,0.005-0.594,0.007-0.891C383.661,415.563,298.702,329.291,193.017,328.235z M266.88,615.105l-8.471-13.778
						c-8.132-13.028-8.132-29.551,0-42.579l15.36-24.508l49.694,81.092L266.88,615.105z M255.586,536.611
						c-2.598,4.066-5.083,8.245-7.68,12.424c-19.877,32.979-42.466,70.25-96.677,70.25h-1.017
						c-16.534,0.323-32.835-3.938-47.097-12.311c-13.514-8.214-24.254-20.292-30.833-34.674
						c-7.419-16.329-11.012-34.137-10.503-52.065c-0.11-18.12,3.745-36.045,11.294-52.518c6.626-14.622,17.383-26.988,30.946-35.576
						c13.945-8.45,30.005-12.759,46.306-12.424h0.791c27.294-0.577,53.318,11.518,70.476,32.753l1.92,2.146l-25.977,40.772
						l-3.388-5.309c-7.678-15.396-22.566-25.915-39.643-28.01h-1.242c-24.508,0-36.706,18.861-37.045,57.826
						c-1.286,15.024,2.065,30.082,9.6,43.144c6.32,8.448,16.46,13.158,26.993,12.536c21.233,0,33.882-21.346,45.176-38.513
						l3.163-5.082l67.2-106.278h56.471L255.586,536.611z"/>
					<g>
						<g>
							<path d="M860.725,733.355l-3.501-10.729l0,0h-26.429l0,0l-3.388,10.729c-0.188,0.529-0.682,0.889-1.242,0.903h-4.292
								c-0.25,0.121-0.541,0.121-0.791,0c-0.161-0.279-0.161-0.624,0-0.903l19.2-59.068c0.188-0.529,0.682-0.889,1.242-0.903h5.534
								c0.573-0.021,1.087,0.352,1.243,0.903l19.313,59.068l0,0l0,0h-0.678h-4.292c-0.528,0.53-1.386,0.531-1.917,0.003
								C860.727,733.357,860.726,733.356,860.725,733.355z M832.603,717.205h22.588l0,0l-11.294-35.803l0,0l0,0L832.603,717.205
								C832.603,717.205,832.264,717.205,832.603,717.205z"/>
							<path d="M932.781,678.353h-31.736l0,0v21.347l0,0h22.588c0.558-0.066,1.063,0.331,1.13,0.889c0.009,0.08,0.009,0.16,0,0.24
								v3.162c0,0.791,0,1.13-1.13,1.13h-22.588l0,0v26.541c0,0.791,0,1.129-1.13,1.129h-3.953c-0.79,0-1.129,0-1.129-1.129v-57.487
								c0-0.79,0-1.129,1.129-1.129h37.271c0.79,0,1.129,0,1.129,1.129v3.163c0.102,0.552-0.265,1.081-0.817,1.183
								C933.278,678.567,933.003,678.508,932.781,678.353z"/>
							<path d="M942.381,680.725c-0.915-0.837-1.411-2.036-1.354-3.275c-0.055-1.206,0.444-2.37,1.354-3.162
								c0.908-0.867,2.136-1.317,3.389-1.242c1.255-0.089,2.488,0.363,3.388,1.242c0.911,0.792,1.41,1.956,1.355,3.162
								c0.057,1.239-0.439,2.438-1.355,3.275c-0.88,0.921-2.115,1.415-3.388,1.355C944.502,682.111,943.277,681.621,942.381,680.725z
								M942.381,733.129v-42.014c0-0.79,0-1.129,1.13-1.129h3.953c0.79,0,1.129,0,1.129,1.129v42.015c0,0.79,0,1.129-1.129,1.129
								h-3.953c-0.452,0.564-0.791-0.339-0.791-1.13H942.381z"/>
							<path d="M990.946,673.045h3.952c0.791,0,1.13,0,1.13,1.129v58.956c0,0.79,0,1.129-1.13,1.129h-3.952
								c-0.791,0-1.13,0-1.13-1.129v-3.275l0,0l0,0c-1.224,1.695-2.854,3.055-4.743,3.952c-1.979,0.924-4.142,1.388-6.325,1.355
								c-3.667,0.132-7.275-0.939-10.277-3.049c-2.827-2.136-4.856-5.158-5.76-8.584c-0.941-3.688-1.359-7.489-1.243-11.294
								c-0.203-3.801,0.178-7.609,1.13-11.294c0.866-3.505,2.897-6.611,5.76-8.81c2.999-2.214,6.667-3.33,10.391-3.162
								c2.183-0.021,4.343,0.442,6.325,1.355c1.883,0.853,3.517,2.175,4.743,3.84l0,0l0,0v-19.991
								C989.816,673.384,990.155,673.045,990.946,673.045z M989.252,718.221c0.452-4.354,0.452-8.745,0-13.101
								c-0.16-1.479-0.541-2.926-1.13-4.292c-0.666-1.896-1.886-3.548-3.501-4.743c-1.656-1.245-3.689-1.883-5.76-1.808
								c-4.288-0.176-8.205,2.42-9.713,6.438c-0.739,1.333-1.237,2.786-1.469,4.292c-0.411,2.196-0.601,4.43-0.564,6.664
								c-0.145,2.144-0.145,4.294,0,6.438c0.21,1.445,0.629,2.853,1.242,4.179c0.625,2.04,1.943,3.798,3.728,4.97
								c1.882,1.216,4.084,1.845,6.324,1.807c2.107,0.093,4.183-0.546,5.873-1.807c1.628-1.231,2.848-2.923,3.501-4.856
								c0.652-1.172,1.146-2.426,1.469-3.728V718.221z"/>
							<path d="M1033.186,727.821c1.9-1.117,3.431-2.767,4.405-4.743c0,0,0,0,0.678-0.565h0.79l2.598,1.469
								c0.678,0,0.903,0.903,0,1.468c-1.334,2.896-3.495,5.333-6.212,7.003c-3.06,1.732-6.536,2.593-10.052,2.484
								c-3.634,0.135-7.222-0.851-10.277-2.823c-2.836-1.954-4.973-4.765-6.099-8.02c-0.995-3.869-1.376-7.871-1.13-11.858
								c0-2.598,0-4.518,0-6.099c0.098-1.374,0.324-2.735,0.678-4.066c0.815-3.676,2.944-6.929,5.986-9.148
								c3.26-2.401,7.251-3.599,11.294-3.388c4.352-0.283,8.645,1.134,11.972,3.953c3.13,2.986,5.092,6.99,5.534,11.294
								c0.429,2.916,0.617,5.862,0.564,8.81c0.066,0.558-0.332,1.063-0.889,1.129c-0.08,0.01-0.161,0.01-0.24,0h-28.349l0,0
								c-0.296,2.098-0.296,4.228,0,6.325c0.486,2.555,1.943,4.821,4.066,6.324c2.29,1.566,5.021,2.357,7.793,2.259
								C1028.715,729.668,1031.099,729.043,1033.186,727.821z M1018.391,696.988c-1.917,1.486-3.239,3.609-3.728,5.986
								c-0.435,1.928-0.662,3.896-0.677,5.872l0,0h22.588l0,0c0.128-1.842,0.128-3.691,0-5.534c-0.533-2.479-1.886-4.707-3.84-6.324
								c-2.171-1.548-4.789-2.341-7.454-2.259C1022.798,694.705,1020.376,695.499,1018.391,696.988z"/>
							<path d="M1054.983,733.129v-58.955c-0.066-0.558,0.332-1.063,0.89-1.129c0.079-0.01,0.16-0.01,0.24,0h3.952
								c0.791,0,1.13,0,1.13,1.129v58.955c0,0.791,0,1.13-1.13,1.13h-3.952c-0.558,0.066-1.063-0.332-1.13-0.89
								C1054.974,733.29,1054.974,733.209,1054.983,733.129z"/>
							<path d="M1074.861,680.725c-0.915-0.837-1.412-2.036-1.355-3.275c-0.055-1.206,0.444-2.37,1.355-3.162
								c0.883-0.905,2.129-1.362,3.388-1.242c1.254-0.075,2.48,0.375,3.389,1.242c0.911,0.792,1.41,1.956,1.355,3.162
								c0.057,1.239-0.44,2.438-1.355,3.275c-0.896,0.896-2.121,1.387-3.389,1.355
								C1076.975,682.155,1075.732,681.658,1074.861,680.725z M1074.861,733.129v-42.014c0-0.79,0-1.129,1.129-1.129h3.953
								c0.558-0.066,1.063,0.331,1.13,0.889c0.009,0.08,0.009,0.16,0,0.24v42.015c0.066,0.557-0.332,1.063-0.89,1.129
								c-0.08,0.01-0.16,0.01-0.24,0h-3.614c-0.79,0.564-1.129-0.339-1.129-1.13H1074.861z"/>
							<path d="M1113.713,695.182h-9.487l0,0v25.524c-0.225,2.142,0.474,4.277,1.92,5.873c1.679,1.275,3.773,1.88,5.873,1.694h1.92
								c0.558-0.066,1.063,0.331,1.13,0.889c0.009,0.08,0.009,0.16,0,0.24v3.162c0.066,0.558-0.332,1.063-0.89,1.13
								c-0.08,0.009-0.16,0.009-0.24,0h-2.937c-3.356,0.259-6.71-0.53-9.6-2.259c-2.314-1.972-3.638-4.866-3.614-7.906v-27.896l0,0
								h-4.969c-0.558,0.066-1.063-0.332-1.13-0.89c-0.01-0.079-0.01-0.16,0-0.239v-2.937c0-0.791,0-1.13,1.13-1.13h4.969l0,0v-9.826
								c-0.066-0.557,0.332-1.063,0.89-1.129c0.079-0.01,0.16-0.01,0.24,0h3.727c0.558-0.066,1.063,0.332,1.13,0.89
								c0.009,0.079,0.009,0.16,0,0.239v9.036l0,0h9.486c0.791,0,1.242,0,1.242,1.129v2.937c0.207,0.589-0.104,1.233-0.692,1.439
								C1113.778,695.163,1113.746,695.173,1113.713,695.182z"/>
							<path d="M1123.426,750.636v-3.05c0-0.791,0-1.13,0-1.13h0.678c1.788,0.078,3.568-0.271,5.195-1.016
								c1.456-0.71,2.616-1.909,3.275-3.389c1.242-2.373,2.227-4.872,2.937-7.454l0,0l-14.683-42.691l0,0
								c-0.077-0.22-0.077-0.459,0-0.678h0.678h4.179c0.62-0.065,1.176,0.385,1.241,1.005c0.001,0.004,0.001,0.008,0.001,0.012
								l11.295,33.882l0,0l0,0l11.294-33.882c-0.718-0.687-0.743-1.824-0.057-2.542c0.686-0.717,1.823-0.742,2.541-0.056h4.179
								c0.291-0.114,0.613-0.114,0.903,0c0.112,0.329,0.112,0.687,0,1.016l-16.376,47.436c-0.995,3.035-2.321,5.951-3.953,8.696
								c-1.109,1.756-2.724,3.134-4.631,3.953c-2.482,0.778-5.08,1.123-7.68,1.017h-0.678
								C1123.765,751.765,1123.426,751.426,1123.426,750.636z"/>
						</g>
						<g>
							<path d="M1223.831,677.676c3.23,3.307,4.948,7.804,4.743,12.423c0.226,4.578-1.546,9.028-4.856,12.198
								c-3.36,3.207-7.897,4.884-12.537,4.63h-16.94l0,0v25.751c0,0.791,0,1.129-1.13,1.129h-3.953c-0.79,0-1.129,0-1.129-1.129
								v-58.616c0-0.791,0-1.13,1.129-1.13h22.589C1216.249,672.82,1220.606,674.53,1223.831,677.676z M1219.765,698.569
								c2.158-2.232,3.302-5.256,3.162-8.357c0-6.237-5.057-11.294-11.294-11.294h-16.489l0,0v22.588l0,0h16.489
								C1214.626,701.645,1217.551,700.588,1219.765,698.569z"/>
						</g>
						<g>
							<path d="M1265.619,693.261c3.023,2.949,4.594,7.082,4.291,11.295v29.139c0,0.79,0,1.129-1.129,1.129h-3.84
								c-0.791,0-1.13,0-1.13-1.129v-3.162l0,0l0,0c-1.632,1.719-3.643,3.033-5.873,3.84c-2.463,0.878-5.065,1.3-7.68,1.242
								c-3.701,0.131-7.347-0.938-10.391-3.05c-2.874-2.369-4.395-6.003-4.065-9.713c-0.144-4.017,1.614-7.867,4.743-10.391
								c3.859-2.695,8.516-4.009,13.214-3.727h10.165l0,0v-4.405c0.158-2.574-0.739-5.103-2.484-7.002
								c-2.145-1.877-4.952-2.813-7.793-2.598c-2.323-0.084-4.626,0.462-6.664,1.581c-1.717,0.905-2.954,2.513-3.388,4.404
								c0,0.678,0,0.904-1.243,0.791h-4.291c-0.791,0-1.13,0-1.13-1.017c0.669-3.229,2.627-6.043,5.421-7.793
								c3.405-2.043,7.326-3.063,11.295-2.937C1257.929,689.449,1262.18,690.692,1265.619,693.261z M1259.858,727.144
								c2.434-1.432,3.902-4.067,3.84-6.89v-6.776l0,0h-10.051c-3.257-0.169-6.487,0.658-9.262,2.372
								c-2.267,1.389-3.606,3.894-3.501,6.551c-0.129,2.286,0.887,4.487,2.711,5.873c2.027,1.369,4.445,2.043,6.889,1.92
								c3.396,0.053,6.705-1.064,9.374-3.163V727.144z"/>
						</g>
						<g>
							<path d="M1279.172,750.636v-3.05c0-0.791,0-1.13,0.678-1.13h0.564c1.825,0.097,3.647-0.253,5.309-1.016
								c1.435-0.739,2.585-1.93,3.275-3.389c1.193-2.395,2.176-4.889,2.937-7.454l0,0l-14.796-42.691l0,0
								c-0.049-0.224-0.049-0.455,0-0.678h0.791h4.065c0.611-0.127,1.209,0.266,1.336,0.876c0.01,0.047,0.016,0.093,0.02,0.141
								l11.294,33.882l0,0l0,0l11.294-33.882c0-0.678,0-1.017,1.243-1.017h4.179c0,0,0.79,0,0.903,0s0,0,0,1.017l-16.15,46.757
								c-0.946,3.055-2.274,5.977-3.953,8.697c-1.038,1.771-2.624,3.158-4.518,3.952c-2.522,0.772-5.157,1.116-7.793,1.017h-0.564
								C1279.285,752.668,1279.172,751.426,1279.172,750.636z"/>
							<path d="M1376.527,693.261c2.737,3.101,4.121,7.168,3.84,11.295v29.025c0,0.791,0,1.13-1.242,1.13h-3.841
								c-0.557,0.066-1.063-0.332-1.129-0.89c-0.01-0.08-0.01-0.16,0-0.24v-28.235c0.153-2.818-0.822-5.582-2.711-7.68
								c-1.896-1.929-4.526-2.956-7.228-2.823c-2.748-0.131-5.433,0.846-7.454,2.71c-1.946,2.024-2.968,4.764-2.824,7.567v28.009
								c0,0.791,0,1.13-1.242,1.13h-3.84c-0.791,0-1.242,0-1.242-1.13v-27.783c0.153-2.818-0.822-5.582-2.711-7.68
								c-1.889-1.941-4.523-2.971-7.228-2.823c-2.715-0.139-5.368,0.841-7.342,2.71c-1.945,2.024-2.968,4.764-2.823,7.567v28.009
								c0,0.791,0,1.13-1.13,1.13h-3.952c-0.791,0-1.13,0-1.13-1.13v-42.014c0-0.79,0-1.129,1.13-1.129h3.952
								c0.791,0,1.13,0,1.13,1.129v3.05l0,0l0,0c1.269-1.67,2.939-2.99,4.856-3.84c2.13-0.795,4.391-1.179,6.663-1.13
								c2.676-0.099,5.329,0.526,7.681,1.807c2.136,1.199,3.866,3.008,4.969,5.195l0,0c1.233-2.273,3.122-4.123,5.422-5.308
								c2.999-1.238,6.271-1.666,9.486-1.242C1370.248,689.494,1373.82,690.793,1376.527,693.261z"/>
							<path d="M1416.847,727.821c1.9-1.117,3.432-2.767,4.405-4.743c0,0,0,0,0.678-0.565h1.129l2.598,1.469
								c0.678,0,0.903,0.903,0,1.468c-1.412,2.879-3.604,5.305-6.324,7.003c-3.06,1.732-6.537,2.593-10.052,2.484
								c-3.635,0.135-7.223-0.851-10.277-2.823c-2.836-1.954-4.974-4.765-6.1-8.02c-1.171-3.917-1.704-7.997-1.581-12.084
								c0-2.598,0-4.518,0-6.099c0.097-1.374,0.324-2.735,0.678-4.066c0.815-3.676,2.944-6.929,5.986-9.148
								c3.259-2.401,7.251-3.599,11.294-3.388c4.354-0.296,8.649,1.123,11.972,3.953c3.129,2.986,5.091,6.99,5.534,11.294
								c0.428,2.916,0.617,5.862,0.564,8.81c0.066,0.557-0.332,1.063-0.89,1.129c-0.079,0.01-0.16,0.01-0.239,0h-28.01l0,0
								c-0.297,2.098-0.297,4.227,0,6.324c0.486,2.556,1.943,4.822,4.065,6.325c2.29,1.565,5.021,2.357,7.793,2.259
								C1412.431,729.49,1414.771,728.944,1416.847,727.821z M1402.052,696.988c-1.917,1.486-3.238,3.609-3.727,5.986
								c-0.436,1.928-0.662,3.896-0.678,5.872l0,0h22.588l0,0c0.128-1.842,0.128-3.691,0-5.534c-0.533-2.479-1.886-4.707-3.84-6.324
								c-2.164-1.563-4.787-2.357-7.454-2.259C1406.459,694.705,1404.038,695.499,1402.052,696.988z"/>
							<path d="M1468.235,693.487c2.943,2.994,4.465,7.104,4.179,11.294v28.574c0,0.79,0,1.129-1.13,1.129h-3.952
								c-0.558,0.066-1.063-0.331-1.13-0.889c-0.01-0.08-0.01-0.16,0-0.24v-27.558c0.139-2.938-0.874-5.815-2.823-8.019
								c-2.021-1.965-4.751-3.024-7.567-2.937c-2.702-0.091-5.347,0.791-7.454,2.484c-1.926,1.717-3.136,4.096-3.389,6.663v29.14
								c0,0.79,0,1.129-1.129,1.129H1440c-0.791,0-1.13,0-1.13-1.129v-42.015c0-0.79,0-1.129,1.13-1.129h3.84
								c0.79,0,1.129,0,1.129,1.129v3.275l0,0l0,0c2.714-3.443,6.914-5.375,11.295-5.195
								C1460.692,688.782,1465.077,690.354,1468.235,693.487z"/>
							<path d="M1503.699,695.182h-9.487l0,0v25.524c-0.225,2.142,0.474,4.277,1.92,5.873c1.679,1.275,3.772,1.88,5.873,1.694h1.92
								c0.558-0.066,1.063,0.331,1.13,0.889c0.009,0.08,0.009,0.16,0,0.24v3.162c0.065,0.558-0.332,1.063-0.89,1.13
								c-0.08,0.009-0.16,0.009-0.24,0h-2.937c-3.356,0.259-6.711-0.53-9.6-2.259c-2.408-2.259-3.584-5.536-3.163-8.81v-26.993l0,0
								h-4.969c-0.621,0.063-1.175-0.39-1.237-1.011c-0.004-0.039-0.006-0.079-0.005-0.118v-2.937c0-0.791,0-1.13,1.242-1.13h4.969
								l0,0v-9.826c-0.066-0.557,0.332-1.063,0.89-1.129c0.08-0.01,0.16-0.01,0.24,0h3.728c0.557-0.066,1.063,0.332,1.129,0.89
								c0.01,0.079,0.01,0.16,0,0.239v9.036l0,0h9.487c0.79,0,1.242,0,1.242,1.129v2.937c0.194,0.593-0.129,1.23-0.722,1.425
								C1504.052,695.192,1503.873,695.208,1503.699,695.182z"/>
							<path d="M1521.77,733.129c-2.284-0.865-4.306-2.305-5.873-4.179c-1.39-1.492-2.261-3.394-2.485-5.421v-0.79
								c-0.065-0.558,0.332-1.063,0.89-1.13c0.08-0.01,0.161-0.01,0.24,0h3.841h0.79l0,0v0.791c0.104,2.067,1.197,3.959,2.937,5.082
								c2.259,1.427,4.896,2.136,7.566,2.033c2.602,0.115,5.171-0.597,7.342-2.033c1.742-1.158,2.799-3.104,2.823-5.195
								c0.052-1.359-0.483-2.676-1.468-3.614c-0.979-0.931-2.129-1.66-3.389-2.146l-5.986-2.033
								c-2.784-0.712-5.504-1.656-8.131-2.823c-2.033-0.871-3.846-2.183-5.309-3.84c-1.468-1.773-2.231-4.025-2.146-6.325
								c-0.123-3.525,1.532-6.876,4.405-8.922c3.292-2.338,7.26-3.528,11.294-3.389c2.938-0.036,5.853,0.501,8.584,1.581
								c2.305,0.881,4.306,2.41,5.76,4.405c1.334,1.751,2.049,3.896,2.033,6.099c0.066,0.558-0.332,1.063-0.89,1.129
								c-0.08,0.01-0.161,0.01-0.24,0h-3.614c-0.791,0-1.13,0-1.13,0l0,0c-0.083-2.044-1.186-3.91-2.937-4.969
								c-4.602-2.554-10.193-2.554-14.795,0c-1.729,1.051-2.763,2.946-2.711,4.969c-0.03,1.869,0.967,3.604,2.598,4.518
								c2.563,1.4,5.297,2.464,8.132,3.163c2.775,0.696,5.494,1.602,8.132,2.71c2.102,0.864,3.989,2.175,5.534,3.84
								c1.542,1.826,2.348,4.162,2.259,6.551c0.063,3.575-1.571,6.968-4.404,9.148c-3.316,2.286-7.268,3.472-11.295,3.389
								C1527.206,735.38,1524.373,734.499,1521.77,733.129z"/>
						</g>
						<g>
							<path d="M1586.146,732.678c-3.067-1.488-5.594-3.896-7.228-6.89c-1.804-3.076-2.704-6.6-2.598-10.165v-24.056
								c-0.11-3.53,0.791-7.018,2.598-10.052c1.675-2.934,4.193-5.295,7.228-6.776c6.819-3.093,14.641-3.093,21.459,0
								c3.004,1.474,5.516,3.789,7.229,6.663c1.791,2.999,2.692,6.446,2.598,9.938c0,0,0,0.791-1.13,0.904h-3.952l0,0h-0.678l0,0
								v-0.565c0.186-3.729-1.257-7.355-3.953-9.938c-2.877-2.485-6.594-3.778-10.391-3.614c-3.82-0.181-7.557,1.159-10.391,3.728
								c-2.654,2.564-4.092,6.138-3.953,9.825v24.509c-0.156,3.725,1.281,7.339,3.953,9.938c6.039,4.958,14.741,4.958,20.781,0
								c2.723-2.565,4.17-6.204,3.953-9.938c-0.017-0.499,0.375-0.916,0.874-0.933c0.086-0.002,0.172,0.007,0.255,0.029h3.953
								c0.791,0,1.129,0,1.129,0l0,0c0.111,3.529-0.791,7.017-2.598,10.051c-1.681,2.9-4.201,5.224-7.228,6.664
								c-6.802,3.168-14.657,3.168-21.459,0L1586.146,732.678z"/>
						</g>
						<g>
							<path d="M1635.728,731.774c-3.026-2.181-5.218-5.328-6.212-8.923c-1.015-3.674-1.435-7.487-1.243-11.294
								c-0.11-3.574,0.27-7.146,1.13-10.616c1.041-3.542,3.226-6.64,6.212-8.81c3.287-2.337,7.268-3.491,11.294-3.275
								c3.844-0.149,7.625,1.005,10.729,3.275c3.018,2.09,5.213,5.164,6.212,8.696c0.891,3.505,1.309,7.113,1.242,10.729
								c0.13,3.805-0.288,7.608-1.242,11.294c-1.032,3.607-3.263,6.755-6.325,8.923c-3.106,2.168-6.829,3.276-10.616,3.162
								C1642.938,735.117,1639.015,734.008,1635.728,731.774z M1653.798,727.257c2.038-1.582,3.473-3.814,4.066-6.325
								c0.547-2.902,0.774-5.856,0.677-8.81c0.109-2.952-0.118-5.908-0.677-8.81c-0.594-2.511-2.028-4.742-4.066-6.324
								c-2.107-1.588-4.705-2.387-7.342-2.259c-5.315-0.155-10.02,3.42-11.293,8.583c-1.055,5.826-1.055,11.794,0,17.619
								c0.489,2.524,1.897,4.778,3.953,6.325c2.138,1.612,4.779,2.413,7.453,2.259
								C1649.167,729.616,1651.72,728.818,1653.798,727.257L1653.798,727.257z"/>
							<path d="M1731.728,693.261c2.783,3.073,4.174,7.161,3.84,11.295v29.025c0,0.791,0,1.13-1.13,1.13h-3.84
								c-0.79,0-1.13,0-1.13-1.13v-28.235c0.162-2.84-0.859-5.621-2.823-7.68c-1.888-1.941-4.524-2.971-7.228-2.823
								c-2.746-0.118-5.426,0.856-7.455,2.71c-1.945,2.024-2.967,4.764-2.823,7.567v28.009c0,0.791,0,1.13-1.13,1.13h-3.953
								c-0.79,0-1.129,0-1.129-1.13v-27.783c0.153-2.818-0.822-5.582-2.711-7.68c-1.932-1.946-4.603-2.975-7.341-2.823
								c-2.715-0.139-5.368,0.841-7.341,2.71c-1.871,2.063-2.847,4.785-2.711,7.567v28.009c0,0.791,0,1.13-1.242,1.13h-3.84
								c-0.791,0-1.13,0-1.13-1.13v-42.014c0-0.79,0-1.129,1.13-1.129h3.84c0.791,0,1.242,0,1.242,1.129v3.05l0,0l0,0
								c1.314-1.671,3.021-2.99,4.97-3.84c2.126-0.807,4.39-1.189,6.663-1.13c2.675-0.087,5.325,0.536,7.68,1.807
								c2.107,1.2,3.802,3.012,4.856,5.195l0,0c1.142-2.341,3.056-4.217,5.421-5.308c2.475-1.155,5.177-1.734,7.906-1.694
								C1724.548,688.7,1728.766,690.203,1731.728,693.261z"/>
							<path d="M1783.68,712.235c0.167,3.793-0.175,7.592-1.017,11.294c-0.865,3.505-2.896,6.61-5.76,8.81
								c-3.015,2.182-6.672,3.294-10.391,3.162c-2.184,0.032-4.346-0.432-6.324-1.355c-1.898-0.881-3.562-2.196-4.856-3.84l0,0l0,0
								v20.33c0.003,0.623-0.5,1.132-1.124,1.135c-0.039,0-0.079-0.002-0.118-0.006h-3.841c-0.557,0.066-1.063-0.332-1.129-0.89
								c-0.01-0.079-0.01-0.16,0-0.239v-59.521c0-0.79,0-1.129,1.129-1.129h3.841c0.79,0,1.242,0,1.242,1.129v3.389l0,0l0,0
								c1.306-1.617,2.918-2.96,4.743-3.953c2.019-0.926,4.218-1.389,6.438-1.355c3.685-0.133,7.306,0.981,10.278,3.162
								c2.785,2.153,4.772,5.174,5.647,8.584C1783.448,704.616,1783.868,708.429,1783.68,712.235z M1777.017,718.898
								c0.214-2.216,0.214-4.447,0-6.663c0.145-2.144,0.145-4.294,0-6.438c-0.157-1.457-0.578-2.873-1.242-4.179
								c-0.658-2.024-1.969-3.772-3.728-4.97c-1.86-1.264-4.077-1.897-6.324-1.807c-2.108-0.093-4.183,0.545-5.873,1.807
								c-1.6,1.306-2.811,3.024-3.501,4.97c-0.539,1.305-0.919,2.67-1.13,4.065c-0.147,2.182-0.147,4.37,0,6.551
								c-0.144,2.219-0.144,4.444,0,6.663c0.21,1.446,0.629,2.854,1.242,4.18c1.222,3.998,4.97,6.682,9.148,6.55
								c2.153,0.028,4.269-0.56,6.099-1.693c1.682-1.104,2.951-2.732,3.614-4.631c0.828-1.394,1.401-2.924,1.694-4.518V718.898z"/>
							<path d="M1822.758,693.261c3.023,2.949,4.594,7.082,4.292,11.295v29.139c0,0.79,0,1.129-1.13,1.129h-3.953
								c-0.79,0-1.13,0-1.13-1.129v-3.162l0,0l0,0c-1.632,1.719-3.643,3.033-5.872,3.84c-2.585,0.634-5.265,0.787-7.906,0.451
								c-3.665,0.115-7.269-0.954-10.277-3.049c-2.916-2.346-4.481-5.983-4.179-9.713c-0.099-4.033,1.699-7.88,4.856-10.391
								c3.858-2.695,8.515-4.009,13.214-3.728h10.164l0,0v-3.614c0.158-2.574-0.738-5.103-2.484-7.002
								c-2.144-1.877-4.951-2.813-7.793-2.598c-2.323-0.084-4.625,0.462-6.663,1.581c-1.717,0.905-2.954,2.513-3.389,4.404
								c0,0.678,0,0.904-1.242,0.791h-4.292c-0.791,0-1.129,0-1.129-1.017c0.574-3.268,2.557-6.117,5.421-7.793
								c3.399-2.056,7.324-3.075,11.294-2.937C1814.917,689.393,1819.258,690.639,1822.758,693.261z M1816.998,727.144
								c2.433-1.432,3.902-4.067,3.84-6.89v-6.776l0,0h-9.148c-3.257-0.169-6.487,0.658-9.261,2.372
								c-2.268,1.389-3.606,3.894-3.502,6.551c-0.129,2.286,0.888,4.487,2.711,5.873c2.027,1.369,4.445,2.043,6.89,1.92
								c3.084-0.145,6.046-1.251,8.471-3.163V727.144z"/>
						</g>
						<g>
							<path d="M1870.306,693.487c2.866,3.036,4.34,7.128,4.066,11.294v28.574c0,0.79,0,1.129-1.13,1.129h-3.952
								c-0.559,0.066-1.063-0.331-1.13-0.889c-0.01-0.08-0.01-0.16,0-0.24v-27.558c0.139-2.938-0.874-5.815-2.823-8.019
								c-2.021-1.965-4.752-3.024-7.567-2.937c-2.663-0.063-5.264,0.816-7.341,2.484c-2.005,1.655-3.232,4.068-3.389,6.663v29.14
								c0.066,0.557-0.332,1.063-0.889,1.129c-0.08,0.01-0.161,0.01-0.24,0h-3.953c-0.791,0-1.13,0-1.13-1.129v-42.015
								c0-0.79,0-1.129,1.13-1.129h3.953c0.557-0.066,1.063,0.331,1.129,0.889c0.01,0.08,0.01,0.16,0,0.24v3.275l0,0l0,0
								c2.677-3.489,6.902-5.433,11.294-5.195C1862.755,688.844,1867.114,690.407,1870.306,693.487z"/>
						</g>
						<g>
							<path d="M1884.085,750.636v-3.05c0-0.791,0-1.13,0.678-1.13h0.564c1.826,0.097,3.647-0.253,5.309-1.016
								c1.434-0.739,2.585-1.93,3.275-3.389c1.375-2.303,2.547-4.722,3.501-7.229l0,0l-14.796-42.691l0,0
								c-0.049-0.224-0.049-0.454,0-0.678h0.791h4.066c0.648-0.069,1.24,0.374,1.354,1.017l11.294,33.882l0,0l0,0l11.295-33.882
								c0-0.678,0.564-1.017,1.242-1.017h4.179c0,0,0.79,0,0.903,0s0,0,0,1.017l-16.15,46.758c-0.946,3.054-2.274,5.976-3.953,8.696
								c-1.038,1.771-2.623,3.159-4.518,3.953c-2.522,0.771-5.156,1.115-7.793,1.017h-0.564
								C1884.763,752.895,1884.085,751.426,1884.085,750.636z"/>
						</g>
					</g>
				</g>
			</g>
		</g>
	</g>
</svg>
);

export default logo;