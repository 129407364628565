import React, { Component, Fragment, createRef } from 'react';
import PropTypes from 'prop-types';
import { Data } from '../../common/components/react-data-grid-addons';
import { cloneDeep, each, find, unionBy, map, concat, noop, get } from 'lodash';

import { InvoiceColumns as Columns } from './column-filter/invoiceColumns';
import { equipmentFilter as Filter, compileFilter } from './filter/equipmentFilter';
import { appService } from '../../services/appService';
import { GridComponent, ToolbarComponent } from '../../common/components/grid';
import { ModalWrapper, modalNames } from '../../common/components/modal-wrapper';
import MainFilterComponent from '../../common/components/filter/MainFilter';
import { ZebraRenderer } from '../../common/components/row';
import { PopoverGridTooltip } from '../../common/components/tooltips';
import { LoadMoreOptions } from '../../common/utilities';
import {
    collapseSidebar,
    formatColumns,
    getPage,
    hasMoreData,
    mapCellArgs,
    mapData,
    mapDataToGridCommon,
    onInfoHover,
    onLoadMoreLimitChange,
    resolveColumnName
} from '../../common/components/grid/commonGridMethods';
import { withContext, withLoader } from '../../common/components';
import { MerchantContext } from '../MerchantDetails';
import { actionsGridColumn } from '../../common/components/actionsGrid/invoicesActionsGridColumn';
import { Notification } from '../../common/components/notifications';

const loadMoreOptionsWithAll = concat(LoadMoreOptions, [0]);

class InvoiceGrid extends Component {
    constructor(props) {
        super(props);
        this.state = cloneDeep(this.initialState);

        this.gridRef = createRef();
        this.notificationRef = createRef();

        this.setState = this.setState.bind(this);
        this.mapDataToGrid = mapDataToGridCommon.bind(this);
        this.onLoadMoreLimitChange = onLoadMoreLimitChange.bind(this);
    }

    get initialState() {
        const filters =
            unionBy(this.props.location.filters, Filter, 'key') || Filter;
        filters.push({ key: 'appId', values: { appId: this.props.match.params.appid }, clearable: false });

        return {
            data: null,
            filteredRows: [],
            fullFilteredRows: [],
            filters: filters,
            activeFilters: cloneDeep(filters),
            inlineFilters: {},
            expanded: {},
            maxGridHeight: 0,
            columns: Columns,
            defaultColumns: cloneDeep(Columns),
            fetchingData: false,
            fetchingAdditionalData: false,
            showFilters: true,
            lastApiRefNum: null,
            activePage: 1,
            rowsPerPage: 20,
            totalRowCount: 0,
            modal: {
                name: modalNames.none,
                data: null,
            },
        };
    }

    componentDidMount() {
        const sidebarExpanded = JSON.parse(sessionStorage.getItem('sidebarExpanded'));
        if (sidebarExpanded !== null) {
            sessionStorage.setItem('collapseSidebar', true);
            this.setState({ wasSidebarExpanded: sidebarExpanded !== null ? sidebarExpanded : true });
        }
    };

    getSnapshotBeforeUpdate = (prevProps) =>
        prevProps.location.key !== this.props.location.key;

    componentDidUpdate = (_, __, snapshot) => {
        if (snapshot) {
            this.setState(this.initialState, () => {
                this.gridRef.current.reset();
            });
        }
    };

    componentWillUnmount() {
        collapseSidebar(this.state.wasSidebarExpanded)
    }

    handlePageChange = (pageNumber) => {
        //console.log('active page is ' + pageNumber);
        this.handleChange([{ key: 'activePage', value: pageNumber }]);
    };

    fetchData = async (filters) => {
        const filter = compileFilter(filters);
        //console.log('fetching data');
        this.setState({
            fetchingData: true,
            data: null,
            filteredRows: [],
            fullFilteredRows: [],
            expanded: {},
            lastApiRefNum: null,
            totalRowCount: 0,
        });

        let lastApiRefNum = null;
        const { rowsPerPage, activePage } = this.state;
        //console.log('rows per page: ' + rowsPerPage + ' active page ' + activePage);

        let data = await appService.getInvoices(filter.appId); //(filter);

        // check for gridRef.current to make sure page didn't unload while data was loading
        if (this.gridRef.current)
        {
            lastApiRefNum = data.refNum;
            let dataObj = { xReportData: data.invoices };

            if (dataObj && dataObj.xReportData) {
                dataObj.xReportData = map(dataObj.xReportData, this.mapRow);
            }

            const formattedColumns = formatColumns(
                this.state.columns,
                cloneDeep(filter)
            );
            mapData(dataObj, (e, d = noop) => this.setState(e, d), this.gridRef);
            this.mapDataToGrid(
                dataObj,
                data.tickets,
                activePage,
                rowsPerPage,
                formattedColumns,
                lastApiRefNum
            );
        }
    };

    getCollectionItem = (collection, key) => {
        return find(collection, { key });
    };

    mapRow = (row) => ({
        ...row,
        isCanadian: get(this.props.merchant, 'isCanadian', false),
        isExpandable: false,
        refreshGridData: this.gridRef.current ? this.gridRef.current.refreshGridData : null,
        onInfoHover: onInfoHover((e, d = noop) => this.setState(e, d), this.gridRef),
        openCloseModal: this.openCloseModal,
        notificationRef: this.notificationRef,
        showLoader: this.props.showLoader
    });

    calculatePopupLeft = () => {
        return this.state.infoDimensions.width - 36;
    };

    calculatePopupTop = () => {
        let offset = 0;
        if (
            this.gridRef.current &&
            this.gridRef.current.gridHolderRef.current
        ) {
            offset =
                this.gridRef.current.gridHolderRef.current.getBoundingClientRect()
                    .y -
                this.gridRef.current.gridHolderRef.current.scrollTop -
                25;
        }
        return this.state.infoDimensions.height - offset;
    };

    renderTooltip = () =>
        this.state.tooltip ? (
            <div
                style={{
                    left: this.calculatePopupLeft(),
                    top: this.calculatePopupTop(),
                    position: 'absolute',
                    backgroundColor: '#fff',
                    zIndex: 99,
                }}
            >
                {this.state.tooltip}
            </div>
        ) : null;

    refetchData = () => {
        this.fetchData(this.state.activeFilters);
    };

    handleChange = (changes) => {
        const newState = {};
        each(changes, ({ key, value }) => {
            if (
                key === 'data' ||
                key === 'inlineFilters' ||
                key === 'activePage'
            ) {
                let filters, data, activePage;
                if (key === 'data') {
                    filters = this.state.inlineFilters;
                    activePage = 1;
                    data = value;
                } else if (key === 'inlineFilters') {
                    filters = value;
                    data = this.state.data;
                    activePage = 1;
                } else {
                    activePage = value;
                    data = this.state.data;
                    filters = this.state.inlineFilters;
                }
                let filteredRows =
                    data && data.xReportData
                        ? Data.Selectors.getRows({
                              rows: data.xReportData,
                              filters,
                          })
                        : [];
                const pagedFilteredRows = getPage(
                    filteredRows,
                    activePage,
                    this.state.rowsPerPage
                );
                newState.filteredRows = pagedFilteredRows;
                newState.fullFilteredRows = filteredRows;
                newState.totalRowCount = filteredRows.length;
            }
            newState[key] = value;
        });
        return new Promise((resolve) => {
            this.setState(newState, resolve);
        });
    };

    openCloseModal = (modalObj, ...rest) => {
        let state = {
            modal: modalObj,
        };
        this.setState(state);
    };

    renderTitle = ({ title, className }) => {
        const { merchant } = this.props;

        return (
            <div className={className}>
                {merchant && merchant.dba && merchant.appId && (
                    <div className="type--sml--plus type--uppercase type--color--text spc--bottom--sml">
                        {`${merchant.dba} - ${merchant.appId}`}
                    </div>
                )}
                {title}
            </div>
        );
    };

    renderHeader = ({ refreshGridData }) => (
        <div className=""></div>
    );

    renderGridHeader = ({ refreshGridData }) => (
        <div className=""></div>
    );

    render = () => {
        const {
            modal,
            fetchingAdditionalData,
            fetchingData,
            filteredRows,
            fullFilteredRows,
            columns,
            data,
            inlineFilters,
            expanded,
            filters,
            activeFilters,
            lastApiRefNum,
            defaultColumns,
            activePage,
            rowsPerPage,
            totalRowCount,
            tooltipProps,
        } = this.state;

        return (
            <Fragment>
                <div className="l--content--grid">
                <Notification ref={this.notificationRef} />
                <ModalWrapper
                    modal={modal}
                    onModalClose={this.openCloseModal}
                />
                <GridComponent
                    emptyMessage="No invoices found for this merchant"
                    fetchingData={fetchingData}
                    fetchingAdditionalData={fetchingAdditionalData}
                    filteredRows={filteredRows}
                    fullFilteredRows={fullFilteredRows}
                    loadMoreOptions={loadMoreOptionsWithAll}
                    onLoadMoreLimitChange={this.onLoadMoreLimitChange}
                    loadMoreLimit={rowsPerPage}
                    columns={columns}
                    fixHeader={true}
                        data={data}
                        resolveColumnName={resolveColumnName}
                    inlineFilters={inlineFilters}
                    components={{
                        title: this.renderTitle,
                        toolbar: ToolbarComponent,
                        header: this.renderHeader,
                        gridHeader: this.renderGridHeader,
                        //modal: ActionsModal,
                        filter: MainFilterComponent,
                        rowRenderer: ZebraRenderer,
                        //gridBody: this.renderScheduleDetails,
                        tooltip: PopoverGridTooltip,
                        }}
                    onChange={this.handleChange}
                    rowKey="id"
                    isExpandable={false}
                    expanded={expanded}
                    title="Invoices"
                    enableExport={true}
                    enablePrint={true}
                    enableFilters={false}
                    type="invoice"
                    filters={filters}
                    activeFilters={activeFilters}
                    showFilters={true}
                    fetchData={this.refetchData}
                    lastApiRefNum={lastApiRefNum}
                    showResults={true}
                    mapCellArgs={mapCellArgs}
                    showExportDropdown={false}
                    showPrintDropdown={false}
                    filterColumns={true}
                    defaultColumns={defaultColumns}
                    ref={this.gridRef}
                    useInlineFilters={true}
                    hasPaging={true}
                    hasMoreData={hasMoreData(rowsPerPage, totalRowCount)}
                    handlePageChange={this.handlePageChange}
                    pagination={{ activePage, rowsPerPage, totalRowCount }}
                    tooltipProps={tooltipProps}
                    actionsGridColumn={actionsGridColumn}
                />
                </div>
            </Fragment>
        );
    };
}

InvoiceGrid.propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
};

export default withLoader(withContext(InvoiceGrid, MerchantContext, 'merchant'));
