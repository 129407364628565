import { map, identity, mapKeys, some, find, isFunction, keys, each, set } from 'lodash';

import {
    StringFilterComponent as StringFilter,
} from "../../../common/column-filters";
import {
    CurrencyComponent,
} from "../../../common/formatters";
import createGridColumn from '../../../common/components/grid/createGridColumn';

export const defaultResidualsColumns = map([
    createGridColumn("type", "Type", 100, {
        filterRenderer: StringFilter
    }),
    createGridColumn("dba_name", "DBA Name", 100, {
        filterRenderer: StringFilter
    }),
    createGridColumn("mid", "MID", 100, {
        filterRenderer: StringFilter
    }),
    createGridColumn("transaction_count", "Transaction Count", 150, {
        filterRenderer: StringFilter,
    }),
    createGridColumn("bankcard_volume", "Bankcard Volume", 150, {
        filterRenderer: StringFilter,
        getRowMetaData: identity,
        formatter: CurrencyComponent,
    }),
    createGridColumn("profit", "Profit", 150, {
        formatter: CurrencyComponent,
        getRowMetaData: identity,
        filterRenderer: StringFilter
    }),
    createGridColumn("agent_share", "Agent Share", 150, {
        formatter: CurrencyComponent,
        getRowMetaData: identity,
        filterRenderer: StringFilter
    }),
    createGridColumn("pci_compliance", "PCI Compliance", 150, {
        // formatter: ReviewPricingStatusComponent,
        getRowMetaData: (row) => row,
        filterRenderer: StringFilter
    }),
    createGridColumn("income_source", "Income Source", 150, {
        getRowMetaData: (row) => row,
        filterRenderer: StringFilter
    }),
], (column, index) => ({
    ...column,
    order: column.order || index,
}));

const shouldIgnoreColumn = (columnName) => {
    const ignoreColumnRules = [/^\s+id$/, /^isExpandable/, /^index/, /^gridRowNumber/, /^agent_name/, /^agent_number/, /^month/, /^sharepoint_id/];
    return some(ignoreColumnRules, rule => rule.test(columnName));
}

export const extractColumnsFromData = (data) => {
    const keysObject = {}
    each(data, item => {
        each(keys(item), key => {
            if(!shouldIgnoreColumn(key) && !isFunction(item[key]))
            {
                set(keysObject, key, null)
            }
        });
    })

    const columns = [];
    mapKeys(keys(keysObject), key => {
        let defaultColumn = find(defaultResidualsColumns, x => x.key === key)
        if (defaultColumn) {
            columns.push(defaultColumn)
        }
        else {
            columns.push(createGridColumn(key, key, 100, {
                filterRenderer: StringFilter,
            }))
        }
    });
    return columns;
}
