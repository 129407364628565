import React, { Component } from 'react'
import { get, identity, filter, toLower, map } from 'lodash';
import { Toggle } from '../../common/components/toggle'
import PropTypes from 'prop-types';

export default class AchPlanConfirm extends Component {
    onKeyDownHandler = (event, onClickHandler) => {
        if(event.keyCode === 13){
            onClickHandler();
        }
    }

    render() {
        const { fees } = this.props;

        const monthlyFees = filter(fees,(fee) => toLower(fee.feeType) === 'monthlyfee');
        const setupFees = filter(fees,(fee) => toLower(fee.feeType) === 'onetimefee');
        const check21TransactionFees = filter(fees,(fee) => ((toLower(fee.feeType) === 'transactionfee' || toLower(fee.feeType) === 'discountfee') && fee.achTransactionType == "Check21"));
        const achTransactionFees = filter(fees,(fee) =>((toLower(fee.feeType) === 'transactionfee' || toLower(fee.feeType) === 'discountfee') && fee.achTransactionType == "Ach"));

        return (
            <Toggle initialToggle={true}>
                {({ isToggled, handleToggle }) => (
                        <div className="card--primary card--sml spc--bottom--med">
                            <div
                                className={`card--primary__header ${isToggled
                                        ? "is-expanded"
                                        : "is-collapsed"
                                    }`}
                            >
                                <h6 className="spc--bottom--sml">Plans</h6>
                                <div className="card--primary__header__expand" onKeyDown={e => this.onKeyDownHandler(e, () => handleToggle)} onClick={handleToggle} >
                                    <i
                                        className={`icon icon--arrow icon--tiny icon--arrow--down--primary ${isToggled ? "rotate--180" : ""
                                            }`}
                                    ></i>
                                </div>
                            </div>
                            <div
                                className={`details__content ${isToggled ? "" : "display--n"
                                    }`}
                            >
                                <h6 className="spc--bottom--sml">Monthly Fees</h6>
                                <div className="row">
                                    {this.renderFeesForType(monthlyFees)}
                                </div>

                                <h6 className="spc--bottom--sml">Setup Fees</h6>
                                <div className="row">
                                    {this.renderFeesForType(setupFees)}
                                </div>

                                <h6 className="spc--bottom--sml">Check 21 Fees</h6>
                                <div className="row">
                                    {this.renderFeesForType(check21TransactionFees)}
                                </div>
                                
                                <h6 className="spc--bottom--sml">ACH Fees</h6>
                                <div className="row">
                                    {this.renderFeesForType(achTransactionFees)}
                                </div>
                            </div>
                        </div>
                )}
            </Toggle>
        )
    }

    renderFeesForType = (fees) => {
        return (
            <React.Fragment>
                {map(fees, fee => {
                    const merchantPrice = get(fee,'merchantPrice', 0)
                    const feeName = get(fee,'feeName', 0)
                     return (<div className="col col-sml-6 col-med-6">
                        <div className="form__field spc--bottom--med">
                            <label className="label">{feeName} </label>
                            <div className={`input input--med input--fake`}>
                                {merchantPrice}
                            </div>
                        </div>
                    </div>)}
                )}
            </React.Fragment>
        );
    }
}
AchPlanConfirm.propTypes = {
    fees: PropTypes.array.isRequired,
};