import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { trim, toLower } from 'lodash';

import { principalService } from '../../services';
import { getRedirectRoute } from './loginUtils';

class ConfirmRegistrationComponent extends Component {
	constructor(props) {
		super(props);

		const username = (props.location.state && props.location.state.username) || '';

		this.state = {
			username: username,
			code: '',
			errorMessage: null,
			successMessage: null,
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentWillMount() {
		if (principalService.get()) {
			this.redirect();
		}
	}

	handleChange(event) {
		this.setState({
			[event.target.name]: event.target.value,
		});
	}

	async handleSubmit(event) {
		event.preventDefault();
		if (!this.formValidation()) {
			return;
		}

		const { history } = this.props;
		let { username, code } = this.state;
		username = trim(toLower(username));
		code = trim(code);

		Auth.confirmSignUp(username, code, {
			forceAliasCreation: true,
		})
			.then(data => {
				if (data === 'SUCCESS') {
					history.push({
						pathname: '/login',
						state: { username: username },
					});
				}
			})
			.catch(err => {
				let message;
				switch (err && err.code) {
					case 'AliasExistsException': {
						message = 'An account with this email already exists.';
						break;
					}
					case 'CodeMismatchException': {
						message = 'The provided code does not match the expected value.';
						break;
					}
					case 'ExpiredCodeException': {
						message = 'The provided code has expired.';
						break;
					}
					case 'InvalidParameterException': {
						message = err.message;
						break;
					}
					case 'TooManyFailedAttemptsException': {
						message = 'You have made too many failed attempts.';
						break;
					}
					case 'UserNotFoundException': {
						message = 'User with provided email does not exist.';
						break;
					}
					case 'NotAuthorizedException': {
						message = 'User is already confirmed.';
						break;
					}
					default: {
						message = 'Something went wrong. Please try again.';
						break;
					}
				}
				this.setState({
					errorMessage: message,
					successMessage: null,
				});
			});
	}

	formValidation = () => {
		const { username, code } = this.state;

		if (username.length <= 0) {
			this.setState({
				errorMessage: 'Please enter your email',
				successMessage: null,
			});
			return false;
		}

		if (code.length <= 0) {
			this.setState({
				errorMessage: 'Please enter confirmation code sent to your email address',
				successMessage: null,
			});
			return false;
		}

		return true;
	};

	redirectToLogin = () => {
		const { history } = this.props;
		history.push('/login');
	};

	redirect() {
		const { history, location } = this.props;
		let redirectUrl = getRedirectRoute();

		if (location.state && location.state.returnUrl) {
			redirectUrl = location.state.returnUrl;
		}

		history.push(redirectUrl);
	}

	resendCode = () => {
		const { username } = this.state;

		if (username.length <= 0) {
			this.setState({
				errorMessage: 'Please enter your email',
				successMessage: null,
			});
			return false;
		}

		Auth.resendSignUp(username)
			.then(() => {
				this.setState({
					errorMessage: null,
					successMessage: 'Code resent successfully',
				});
			})
			.catch(err => {
				let message;
				switch (err && err.code) {
					case 'CodeDeliveryFailureException': {
						message = 'Code failed to deliver succefully.';
						break;
					}
					case 'LimitExceededException': {
						message = 'Limit exceeded for requested resource.';
						break;
					}
					case 'InvalidParameterException': {
						message = err.message;
						break;
					}
					case 'TooManyRequestsException': {
						message = 'You have made too many resend attempts.';
						break;
					}
					case 'UserNotFoundException': {
						message = 'User with provided email does not exist.';
						break;
					}
					case 'NotAuthorizedException': {
						message = 'User is already confirmed.';
						break;
					}
					default: {
						message = 'Something went wrong. Please try again.';
						break;
					}
				}
				this.setState({
					errorMessage: message,
					successMessage: null,
				});
			});
	};

	render() {
		const { username, code, errorMessage, successMessage } = this.state;

		return (
			<div>
				<form className="form" onSubmit={this.handleSubmit}>
					<div className="membership__section">
						<h2 className="membership__title">Confirm registration</h2>
						<div className="membership__message">
							<img
								src="/static/media/message.jpg"
								className="membership__message__image"
								title="Thank you"
								alt="Thank you"
							/>
							<div className="membership__message__content">
								<h4 className="membership__message__title">
									Thank you for registering<span className="hide--to--xsml--inline"> with Cardknox</span>!
								</h4>
								<p className="membership__message__description">
									We have sent you an email with a confirmation code. To complete your registration, check your email and copy the code. Paste the code into the Confirmation Code field below and click the Confirm Registration button.
								</p>
							</div>
						</div>
						<div className="membership__spacer">
							<label className="membership__label">Email address</label>
							<input
								name="username"
								type="text"
								className="input input--med"
								placeholder="user@gmail.com"
								value={username}
								onChange={this.handleChange}
							/>
						</div>
						<div className="membership__spacer">
							<label className="membership__label">Confirmation code</label>
							<input
								name="code"
								type="text"
								className="input input--med"
								placeholder="000000"
								value={code}
								onChange={this.handleChange}
							/>
						</div>
						{errorMessage ? <div className="spc--bottom--med note notewarning">{errorMessage}</div> : null}
						{successMessage ? <div className="spc--bottom--med note note--success">{successMessage}</div> : null}
						<div className="membership__spacer">
							<button type="submit" className="btn btn--primary btn--med">
								<span className="spc--right--tny">Confirm</span><span className="hide--to--sml--inline">Registration</span>
							</button>
							<a href="javascript:void(0)" onClick={this.resendCode} className="btn btn--ghost btn--med spc--left--sml">
								Resend Code
							</a>
						</div>
						<div className="membership--main__action">
							<span className="membership--main__label">Do you have an account?</span>{' '}
							<button
								type="button"
								onClick={this.redirectToLogin}
								className="btn membership--main__btn type--wgt--medium"
							>
								Log in
							</button>
						</div>
					</div>
				</form>
			</div>
		);
	}
}

ConfirmRegistrationComponent.propTypes = {
	location: PropTypes.object,
	history: PropTypes.object,
};

export default ConfirmRegistrationComponent;
