import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const ColumnFilterFooter = ({ onReset, onApply, filterButtonDisabled }) => {
		return (
			<Fragment>
				<div className="group">
					<button type="button" onClick={onReset} className="btn btn--reset pull">
						Reset
					</button>
					<button
						type="button"
						onClick={onApply}
						disabled={filterButtonDisabled}
						className="btn btn--reset anchor anchor--primary push type--wgt--bold type--uppercase"
					>
						Done
					</button>
				</div>
			</Fragment>
		);
};

ColumnFilterFooter.propTypes = {
	onApply: PropTypes.func.isRequired,
	onReset: PropTypes.func.isRequired,
	filterButtonDisabled: PropTypes.bool,
};

export default ColumnFilterFooter;
