import React from 'react';
import { toLower } from 'lodash';
import { appService, signatureService } from '../../services';
import { withLoader } from "./../../common/components";
import { identifyFullStoryWithToken, isGoPlus } from '../../common/utilities';

class MerchantSignComponent extends React.Component {
    constructor(props) {
        super(props);
        //console.log('token=' + this.props.location.search.substr(1)); //('?'));
        this.state = {
            token: this.props.location.search.substr(1),
            appId: null,
            mpa: null,
            downloadUrl: '',
            errorMessage: null,
            isGoPlus: false,
            displaySignatureDocErrorMessage: false,
        };
        identifyFullStoryWithToken(this.state.token);
    }

    beforeUnloadListener = (event) => {
        console.log('unloading page');
        return event.returnValue = ""
    }

    componentDidMount() {
        signatureService.initializeSignatureLib();
        this.load();
        window.addEventListener("beforeunload", this.beforeUnloadListener);
        /*
         this.setState({ appId: 143924, downloadUrl: 'https://public.pactsafe.com/v1.1/download/requests/5dcc31a847d3f209102c96f0/record?expires=0&r=5dcc31a847d3f209102c96f0&s=5dcb7322642d9c2a86ae8dfa&signature=M9DhfHvWM9foNTXBnMApTjg6WMUGa2XluSYNoMzWcnoz4dARR8DLv8iEoYFf-GqbE97xwxaPDWUW3c1Z6xaz7VVizT6inYQNEDolhQ0sUSsAFp-BQkqINX5uUpM4u7Gb~msrGBuZYKAj0cKe1Ddi~B5YFZsCkqqToyXr8JJ~C1jedm-5lSVn2qLrr0bsvqZZVMuvVAAORrX9VpAGfPDH4ax~GLyqzpIYgxdvyRzcDpBrhHjafKPCONKiWTZhPwfpVbPzWJ3Zp9K6uoyRbURH6wdCqI3dt6hLJMWS0ehMRdinoKc~YCG6OFnNfgY4HeKLwFuSMstE9SZFx7j84KJqLg__' },
            () => { this.handleSignatureEvents({ event: 'request_signed' }); });
        */
    }

    load = () => {
        this.props.showLoader(true);
        appService.getEAppForMerchant(this.state.token)
            .then(mpa => {
                console.log('loaded MPA');
                this.props.showLoader(false);
                this.setState({ isGoPlus: isGoPlus(mpa) });
                this.loadSignatureDoc(mpa);
            }).catch(err => {
                console.log('load error', err);
                this.props.showLoader(false);
                const errorMessage = (toLower(err) === "unauthorized" || err == 401) ?
                    "The link you followed has expired. To complete your application, please reach out to your agent to request a new eApp link."
                    : 'An error occurred: ' + err
                this.setState({ errorMessage, isNewError: true });
            });
    }

    loadSignatureDoc = (mpa) => {
        signatureService.loadSignaturePage(mpa, this.handleSignatureEvents).then(respData => {
            console.log('loaded signature page, download url is ' + respData.download_request_url);
            this.setState({ downloadUrl: respData.download_request_url, mpa, appId: mpa.appId, requestRefId: respData.request_id });
            appService.updateSignatureStatus(mpa.appId, mpa.signatureDoc.id, respData.request_id, 'Sent');
        }).catch(err => {
            console.log('load signature doc error', err);
            this.props.showLoader(false);
            if (err && err.isPatchError) return this.setState({ errorMessage: 'There was an error updating the signature document. This can occur due to system firewalls or filters. Please try again using another device.' });
            if (err.error.message == 'Request has already been completed by all signers.') return this.setState({ errorMessage: 'Signature documents have already been submitted successfully.' });
            this.displaySignatureDocErrorMessage(err,mpa);
        });
    }
    displaySignatureDocErrorMessage = (err,mpa) => { this.setState({ displaySignatureDocErrorMessage: true, pactsafeError:err,mpa:mpa});};

    onAfterSignatureFileSave = (promise) => {
        const { history } = this.props;
        return promise.then(() => {
            console.log('finished downloading signed document');
            window.removeEventListener("beforeunload", this.beforeUnloadListener);
            history.push('/merchant/thankyou');
        })
        .catch(err => {
            console.log('download and save error', err);
            this.setState({ errorMessage: 'Thank you, your signature was recorded.  There was a system error saving the files to our system.  Please contact your agent with this message. ' })
        });
    };

    handleSignatureEvents = (mpa, requestRefId, downloadUrl) => (data) => {
        console.log('handling signature event', data.event);
        const { history } = this.props;

        if (data.event == 'canceled') {
            history.push('/merchant/mpa');
        }
        else if (data.event == 'request_signed') {
            signatureService.closeSignature();
            // download signed PDF and save to app            
            let ticketId;
            if (mpa.signatureDoc.documentCategory == 'BankingServices' && mpa.signatureDoc.documentInfo) {
                ticketId = JSON.parse(mpa.signatureDoc.documentInfo).TicketId
            }
            if (!ticketId) {
                const searchParams = new URLSearchParams(new URL(downloadUrl).search);
                const signature = searchParams.get('signature');
                const expires = searchParams.get('expires');
                return appService.completeSignature(mpa.appId, requestRefId, signature, expires, mpa.signatureDoc.id)
                    .then(() => {
                        this.onAfterSignatureFileSave(Promise.resolve());
                    })
                    .catch((err) => {
                        console.log(err);
                        this.onAfterSignatureFileSave(Promise.reject(err));
                    });
            }
            appService.updateSignatureStatus(mpa.appId, mpa.signatureDoc.id, requestRefId, 'Signed');
            return this.onAfterSignatureFileSave(signatureService.downloadAndSaveSignedDocument(mpa, mpa.signatureDoc.includedContracts, downloadUrl, ticketId, this.state.isGoPlus));
        }
    }

    renderSignatureDocErrorMessage = (error) => {
        window.removeEventListener("beforeunload", this.beforeUnloadListener);
        return (
            <div>
                <h2>An error occurred while signing your document</h2><br />
                <h5>
                    Please&nbsp;
                    <a
                        href={`mailto:agentsupport@fidelitypayment.com?subject=PactsafeError%20for%20${this.state.mpa.dba}&body=${this.getBody(error)}`}
                        className="anchor anchor--primary"
                    >
                        click here&nbsp;
                    </a>
                    to contact Support
                </h5><br />
            </div>
        );
    }
    renderData = (data, showError) => {
        const { pactsafeError } = this.state;
        return (
            showError ? this.renderSignatureDocErrorMessage(pactsafeError) :
            <div className="note note--default">
                {data}
            </div>);
    }

    getBody = (error) => {
        const { mpa }= this.state;
        const newline = '\r\n\r\n';
        const errorInfo = `Error Message: ${(error.error.message)}`;
        const accountInfo = `Account : ${mpa.appId} - ${mpa.dba}`;
        return encodeURIComponent(`url:${window.location.href}${newline}${errorInfo}${newline}${accountInfo}`);
}
    render() {
        const { errorMessage, displaySignatureDocErrorMessage, downloadUrl } = this.state;
        const data = downloadUrl === '' ? 'Please wait while we process your signature documents...' :
            'Please wait while we process your application. Please do not close this browser until you see a success message displayed on the screen. Thank You.'
        return (

            <div id="main-div" className="l--content">
                {
                    errorMessage ? (
                        <div className="note note--warning" ref={this.errorRef}>
                            {errorMessage}
                        </div>
                    ) : this.renderData(data, displaySignatureDocErrorMessage)
                }
            </div>
        );
    }
}

export default withLoader(MerchantSignComponent);