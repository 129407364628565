import React from 'react';
import PropTypes from 'prop-types';
import { BaseStatusComponent } from '../../../common/formatters';

class LeadStatusComponent extends BaseStatusComponent {

    constructor(props) {
        super(props);
        this.statusClasses = {
            'dead': this.styleInactive,
            'follow up later': this.styleInactive,

            'new': this.stylePending,
            'pricing analysis': this.stylePending,

            'qualified': this.styleIncomplete,
            'contacted': this.styleIncomplete,
            'waiting for info': this.styleIncomplete,
        }
    }

    render = () => {
        const { value } = this.props;
        return value ? (
            <span ref={el => this.info = el} className={this.getStyle()}>
                {value}
            </span>
        ) : null;
    }
}

export default LeadStatusComponent;

LeadStatusComponent.propTypes = {
    value: PropTypes.string
};