import React, { Component, Fragment } from "react";
import { find, findIndex, filter, cloneDeep, isEmpty, get, each, isFunction, isNaN } from "lodash";
import { func, string, number, array, bool } from "prop-types";

import AddonListComponent from "../Equipment/addon-list";
import { modalNames, ModalWrapper } from "../../common/components/modal-wrapper";

class GatewayAddons extends Component {
    constructor(props) {
        super(props);

        this.state = {
            merchantGateway: null,
            subequipment: [],
            gateway: null,
            modal: {
                name: modalNames.none,
                data: null
            },
        };
    }

    get availablePurchasePlans() {
        if (!this.state.merchantGateway) {
            return this.state.gateway.purchasePlans;
        }

        return this.state.gateway.purchasePlans.filter((plan) =>
            plan.purchaseTypes.includes(this.state.merchantGateway.purchaseType)
        );
    }

    componentDidMount() {
        const { gatewayList, merchantEquipment, parentEquipmentId, masterId, closeItemsModalAndValidate } = this.props;
        const gateway = find(
            gatewayList,
            ({ equipmentId }) => equipmentId == parentEquipmentId
        );
        const merchantGateway = cloneDeep(find(merchantEquipment, ({ equipmentId, id }) => {
            if (id) {
                return equipmentId == parentEquipmentId && id == masterId;
            } else {
                return equipmentId == parentEquipmentId;
            }
        }));
        const merchGatewayPlan = gateway.purchasePlans.find((p) => p.planName == this.props.parentPlanName);
        if (!!merchGatewayPlan && !!merchantGateway) merchantGateway.purchasePlanId = merchGatewayPlan.planId;

        const subequipment = filter(
            merchantEquipment,
            (e) => e.parentEquipmentId == masterId
        );

        this.setState({ gateway, merchantGateway, subequipment }, () => {
            if (isFunction(closeItemsModalAndValidate)) {
                closeItemsModalAndValidate();
            }
        });
    }

    handlePaymentScheduleChange = equipmentId => ({ target: { value } }) => {
        const { subequipment, gateway } = this.state;
        const items = !isEmpty(subequipment)
            ? subequipment
            : gateway.subequipment;
        const newSubequipment = [...items];
        const index = findIndex(items, (e) => e.equipmentId == equipmentId);

        if (index < 0) {
            return;
        }

        newSubequipment[index] = {
            ...newSubequipment[index],
            paymentSchedule: value,
        };

        this.setState({ subequipment: newSubequipment }, this.saveLocallyOnChange);
    };

    handleSave = () => {
        const { subequipment, merchantGateway } = this.state;
        const { handleChange, closeModal, displayEquipmentFooter } = this.props;
        handleChange([merchantGateway, ...subequipment]);

        if (!displayEquipmentFooter) {
            closeModal();
        }
    };

    saveLocallyOnChange = () => {
        if (this.props.displayEquipmentFooter) {
            this.handleSave();
        }
    };

    openCloseModal = (modalObj) => {
        let state = {
            modal: modalObj,
        };
        this.setState(state);
    };

    copyFees(sourceObj, destObj, purchaseType) {
        if (!sourceObj || !destObj) {
            return;
        }
        purchaseType = purchaseType || this.state.merchantGateway.purchaseType;

        destObj.fees = cloneDeep(
            sourceObj.fees.filter((f) => f.purchaseTypes.includes(purchaseType))
        );
        destObj.fees.forEach((fee) => {
            fee.merchantPrice = fee.retailPrice;
        });
    }

    handleSubNote = (subequipmentId) => (notes) => {
        const { subequipment, gateway } = this.state;
        const items = !isEmpty(subequipment)
            ? subequipment
            : gateway.subequipment;
        const newSubequipment = [...items];
        const index = findIndex(items, (e) => e.equipmentId == subequipmentId);

        if (index < 0) {
            return;
        }

        newSubequipment[index] = {
            ...newSubequipment[index],
            notes,
        };

        this.setState({ subequipment: newSubequipment }, this.saveLocallyOnChange);
    };

    handleSubFeeChange = (subequipmentId, feeId, updatedValue) => {
        const { subequipment, gateway } = this.state;
        const items = !isEmpty(subequipment)
            ? subequipment
            : gateway.subequipment;
        const newSubequipment = [...items];
        const index = findIndex(items, (e) => e.equipmentId == subequipmentId);
        
        if (index < 0) {
            return;
        }

        const feeIndex = findIndex(
            newSubequipment[index].fees,
            (fee) => fee.feeId == feeId
        );

        if (feeIndex < 0) {
            return;
        }

        const isValidValue = /^0*\.0*$/.test(updatedValue.value)
        if(isValidValue || (updatedValue.floatValue === undefined ||isNaN(updatedValue.floatValue)))
        {
            newSubequipment[index].fees[feeIndex] = {
                ...newSubequipment[index].fees[feeIndex],
                merchantPrice: null,
            };
        }
        else
        {
            newSubequipment[index].fees[feeIndex] = {
                ...newSubequipment[index].fees[feeIndex],
                merchantPrice: updatedValue.floatValue,
            };
        }
        this.setState({ subequipment: newSubequipment }, this.saveLocallyOnChange);
    };

    handleFeeChange = (planId, feeId, updatedValue) => {
        //console.log('fee change for plan ' + planId + ' fee ' + feeId);
        let gateway = cloneDeep(this.state.merchantGateway);
        let fee = gateway.fees.find((fee, i) => fee.feeId == feeId);

        // can be just switching plans, so another plan's fees are triggering a value change, but don't need to update state in this case
        if (!fee) return;

        const isValidValue = /^0*\.0*$/.test(updatedValue.value)
        if(isValidValue || (updatedValue.floatValue === undefined ||isNaN(updatedValue.floatValue)))
        {
            fee.merchantPrice = null;
        }
        else
        {
            fee.merchantPrice = updatedValue.floatValue;
        }
        //console.log(e.target.value);
        //console.log(fee.merchantPrice);
        this.setState({ merchantGateway: gateway }, this.saveLocallyOnChange);
    };

    setGatewaySubequipment(equipmentId, selected) {
        const { gateway, subequipment } = this.state;
        const merchGateway = cloneDeep(this.state.merchantGateway);
        
        const items = cloneDeep(
            !selected ? subequipment : gateway.subequipment
        );
        let subequip = find(items, (e) => e.equipmentId == equipmentId);

        if (!subequip) return;

        if (selected) {
            let planId = subequip.defaultPlanId;
            if (!!subequip.hasPlanMapping) {
                let mappedRules = subequip.planMapping.find(
                    (map, i) => map.parentEquipmentPlanId == merchGateway.purchasePlanId
                );
                if (mappedRules && mappedRules.excludeSubequipment) {
                    // can't add subequipment - exit
                    return;
                }
                
                if (mappedRules && !!mappedRules.planIdForFirstEquipment) {
                    const isFirstAddon = !this.props.existingAddons.find((a) => a.equipmentId == equipmentId);
                    
                    if (isFirstAddon) {
                        planId = mappedRules.planIdForFirstEquipment;
                    }
                }
            }
            let selectedPlan = subequip.purchasePlans.find(
                (pln, i) => pln.planId == planId
            );

            let sub = {
                parentEquipmentId: this.props.masterId,
                equipmentId: subequip.equipmentId,
                purchasePlanId: selectedPlan.planId,
                purchaseType:
                    Object.keys(subequip.purchaseTypes).length > 0
                        ? Object.keys(subequip.purchaseTypes)[0]
                        : merchGateway.purchaseType,
                category: subequip.category,
                allowMultiple: subequip.allowMultiple,
                isSelected: true,
                quantity: 1,
                equipmentOptions: {}
            };

            this.copyFees(selectedPlan, sub, sub.purchaseType);
            each(subequip.equipmentOptions, option => {
                if (!!option.defaultValue && !option.dependentOnName) {
                    sub.equipmentOptions[option.name] = option.defaultValue;
                }
            })

            const result = [...subequipment];
            const index = findIndex(
                subequipment,
                (e) => e.equipmentId == equipmentId
            );

            if (index < 0) {
                result.push(sub);
            } else {
                result[index] = sub;
            }

            return result;
        } else {
            subequip.isSelected = false;
            subequip.quantity = 0;
            return items;
        }
    }

    selectSubequipment = (e) => {
        let equipmentId = e.target.value;
        let selected = e.target.checked;
        const subequipment = this.setGatewaySubequipment(equipmentId, selected);
        this.setState({ subequipment }, this.saveLocallyOnChange);
    };

    subOptionSelect = (equipmentId) => (e) => {
        const { subequipment, gateway } = this.state;
        const items = !isEmpty(subequipment)
            ? subequipment
            : gateway.subequipment;
        const newSubequipment = [...items];
        const index = findIndex(items, (e) => e.equipmentId == equipmentId);

        if (index < 0) {
            return;
        }

        if (!newSubequipment[index].equipmentOptions) {
            newSubequipment[index].equipmentOptions = {};
        }

        if (e.target.checked) {
            newSubequipment[index].equipmentOptions = {
                ...newSubequipment[index].equipmentOptions,
                [e.target.value]: "1",
            };
        } else if (
            Object.keys(subequipment.equipmentOptions).includes(e.target.value)
        ) {
            delete newSubequipment[index].equipmentOptions[e.target.value];
        }

        this.setState({ subequipment: newSubequipment }, this.saveLocallyOnChange);
    };

    subOptionChange = (equipmentId) => (e) => {
        const { subequipment, gateway } = this.state;
        const items = !isEmpty(subequipment)
            ? subequipment
            : gateway.subequipment;
        const newSubequipment = [...items];
        const index = findIndex(items, (e) => e.equipmentId == equipmentId);

        if (index < 0) {
            return;
        }

        if (!newSubequipment[index].equipmentOptions) {
            newSubequipment[index].equipmentOptions = {};
        }

        const inputName = e.target.name;
        const optionName = inputName.substr(
            inputName.indexOf("_equipmentOptions__") +
                "_equipmentOptions__".length
        );

        newSubequipment[index].equipmentOptions = {
            ...newSubequipment[index].equipmentOptions,
            [optionName]: e.target.value,
        };

        this.setState({ subequipment: newSubequipment }, this.saveLocallyOnChange);
    };

    optionSelect = (e) => {
        let gateway = cloneDeep(this.state.merchantGateway);
        if (!gateway.equipmentOptions) {
            gateway.equipmentOptions = {};
        }
        if (e.target.checked) {
            gateway.equipmentOptions[e.target.value] = "1"; // true?
        } else if (
            Object.keys(gateway.equipmentOptions).includes(e.target.value)
        ) {
            delete gateway.equipmentOptions[e.target.value];
        }
        this.setState({ merchantGateway: gateway }, this.saveLocallyOnChange);
    };

    optionSetMoreInfo = (optKey, optValue) => (e) => {
        let gateway = cloneDeep(this.state.merchantGateway);
        gateway.equipmentOptions[optKey] = `${optValue}|${e.target.value}`;
        this.setState({ merchantGateway: gateway }, this.saveLocallyOnChange);
    };

    suboptionSetMoreInfo = (equipmentId, optKey, optValue) => (e) => {
        const { subequipment, gateway } = this.state;
        const items = !isEmpty(subequipment)
            ? subequipment
            : gateway.subequipment;
        const newSubequipment = [...items];
        const index = findIndex(items, (e) => e.equipmentId == equipmentId);

        if (index < 0) {
            return;
        }

        if (!newSubequipment[index].equipmentOptions) {
            newSubequipment[index].equipmentOptions = {};
        }

        newSubequipment[index].equipmentOptions = {
            ...newSubequipment[index].equipmentOptions,
            [optKey]: `${optValue}|${e.target.value}`,
        };

        this.setState({ subequipment: newSubequipment }, this.saveLocallyOnChange);
    };

    renderPaymentScheduleOptions = (sub, merchantSub) => {
        let options = get(sub, 'paymentSchedules');
        const value = get(merchantSub, 'paymentSchedule');
        const disabled = !get(merchantSub, 'isSelected');
        const equipmentId = get(merchantSub, 'equipmentId');

        if (isEmpty(options)) {
            options = get(this.state.gateway, 'paymentSchedules', {});
        }

        return (
            <div>
                <label className="label label--theme type--sml--plus spc--bottom--tny" htmlFor="paymentSchedule">
                    Payment Schedule
                    {!disabled && <span className="type--color--warning" data-tooltip="Required"> *</span>}
                </label>
                <div>
                    <select
                        className="input input--med input--select"
                        name="paymentSchedule"
                        id="paymentSchedule"
                        value={value}
                        onChange={this.handlePaymentScheduleChange(equipmentId)}
                        disabled={disabled}
                    >
                        <option value="">Please select...</option>
                        {Object.keys(options).map((opt, idx) => {
                            return (
                                <option key={idx} value={opt}>
                                    {options[opt]}
                                </option>
                            );
                        })}
                    </select>
                </div>
            </div>
        );
    };

    onMerchangSubEqpChange = (equipmentId, value, field) => {
        const { subequipment } = this.state;
        const items = subequipment;
        const newSubequipment = [...items];
        const index = findIndex(items, (e) => e.equipmentId == equipmentId);
        if (index < 0) {
            return;
        }

        newSubequipment[index] = {
            ...newSubequipment[index],
            [field]: value
        };

        this.setState({ subequipment: newSubequipment }, this.saveLocallyOnChange);
    };

    render() {
        const { closeModal, parentEquipmentName, handleChange, displayEquipmentFooter, renderEquipmentFooter } = this.props;
        const { gateway, merchantGateway, subequipment } = this.state;

        return (
            <div>
                <div className="popup__header">
                    <h6>{parentEquipmentName} - Add-ons</h6>
                </div>
                <div className="popup__body popup__body--horizontal">
                    <ModalWrapper
                        modal={this.state.modal}
                        onModalClose={this.openCloseModal}
                    />
                    {gateway && merchantGateway && (
                        <AddonListComponent
                            gateway={gateway}
                            merchantGateway={{
                                ...merchantGateway,
                                subequipment: [
                                    ...filter(
                                        subequipment,
                                        ({ isSelected }) => isSelected
                                    ),
                                ],
                            }}
                            availablePurchasePlans={
                                this.availablePurchasePlans
                            }
                            selectSubequipment={this.selectSubequipment}
                            handleSubOptionSelect={this.subOptionSelect}
                            handleSubOptionChange={this.subOptionChange}
                            handleSubOptionMoreInfo={
                                this.suboptionSetMoreInfo
                            }
                            handleSubFeeChange={this.handleSubFeeChange}
                            handleSubNote={this.handleSubNote}
                            openCloseModal={this.openCloseModal}
                            handleGatewayOptionSelect={
                                this.optionSelect
                            }
                            handleGatewayChange={handleChange}
                            handleOptionMoreInfo={
                                this.optionSetMoreInfo
                            }
                            handleFeeChange={this.handleFeeChange}
                            hideEquipmentOptions={true}
                            hideDisabledPurchasePlans={true}
                            renderAddonsPaymentScheduleOptions={this.renderPaymentScheduleOptions}
                            onMerchangSubEqpChange={this.onMerchangSubEqpChange}
                            suffix="_popup"
                            isPopup={true}
                            existingAddons={this.props.existingAddons}
                        />
                    )}
                </div>
                <div className={`popup__footer popup__footer--styled ${displayEquipmentFooter ? 'popup__footer--hardware' : ''}`}>
                    {displayEquipmentFooter ? renderEquipmentFooter() : (
                        <Fragment>
                            <button
                                className="btn btn--ghost btn--med spc--right--tny"
                                onClick={closeModal}
                            >
                                Close
                            </button>
                            <button
                                className="btn btn--primary btn--med"
                                onClick={this.handleSave}
                            >
                                Save
                            </button>
                        </Fragment>
                    )}
                </div>
            </div>
        );
    }
}

GatewayAddons.propTypes = {
    handleChange: func.isRequired,
    closeModal: func.isRequired,
    parentEquipmentName: string.isRequired,
    parentPlanName: string.isRequired,
    masterId: number.isRequired,
    parentEquipmentId: number.isRequired,
    appId: string.isRequired,
    existingAddons: array.isRequired,
    displayEquipmentFooter: bool,
    renderEquipmentFooter: func,
    closeItemsModalAndValidate: func,
};

export default GatewayAddons;
