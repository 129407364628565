import React from 'react';

import USMpaConfirm from './USMpaConfirm';
import { get } from 'lodash';

export default class CanadaMpaConfirm extends USMpaConfirm {
	renderStateAndZip(mpa, addressField) {
		return (
			<React.Fragment>
				{this.renderField(mpa, `${addressField}.state`, 'Province')}
				{this.renderField(mpa, `${addressField}.zip`, 'Postal Code')}
			</React.Fragment>
		);
	}

	renderRoutingNumber(mpa, fieldName) {
		if (fieldName.indexOf('second') > -1) {
			return (
				<div className="col col-sml-12">
					<div className="row ">
						{this.renderField(
							mpa,
							'bankAccountInformation.secondaryInstitutionNumber',
							'Secondary Institution Number',
							undefined,
							'fs-mask'
						)}
						{this.renderField(
							mpa,
							'bankAccountInformation.secondaryTransitNumber',
							'Secondary Transit Number',
							undefined,
							'fs-mask'
						)}
					</div>
				</div>
			);
		} else {
			return (
				<div className="col col-sml-12">
					<div className="row ">
						{this.renderField(
							mpa,
							'bankAccountInformation.institutionNumber',
							'Institution Number',
							undefined,
							'fs-mask'
						)}
						{this.renderField(mpa, 'bankAccountInformation.transitNumber', 'Transit Number', undefined, 'fs-mask')}
					</div>
				</div>
			);
		}
	}

	renderSocialSecurityNumber(mpa) {
		if (!this.props.showSSN) return null;
		return this.renderField(
			mpa,
			'signerInformation.socialSecurityNumber',
			'Social Insurance Number',
			undefined,
			'fs-mask'
		);
	}

	renderAdditionalSignerInfo(mpa) {
		return (
			<div className="col col-sml-12">
				<div className="row">
					<p className="col col-sml-12 type--p2 type--p2--medium spc--bottom--lrg">Identification Data</p>
					{this.renderField(mpa, 'signerInformation.identificationInformation.identificationType', 'Identification Type')}
					{this.renderField(mpa, 'signerInformation.identificationInformation.identificationNumber', 'Identification Number')}
					{this.renderField(mpa, 'signerInformation.identificationInformation.identificationIssueDate', 'Identification Issue Date')}
					{this.renderField(mpa, 'signerInformation.identificationInformation.identificationExpirationDate', 'Identification Expiration Date')}
					<p className="col col-sml-12 type--p2 type--p2--medium spc--bottom--lrg">Identification 2 Data</p>
					{this.renderField(mpa, 'signerInformation.identificationInformation2.identificationType', 'Identification Type')}
					{this.renderField(mpa, 'signerInformation.identificationInformation2.identificationNumber', 'Identification Number')}
					{this.renderField(mpa, 'signerInformation.identificationInformation2.identificationIssueDate', 'Identification Issue Date')}
					{this.renderField(mpa, 'signerInformation.identificationInformation2.identificationExpirationDate', 'Identification Expiration Date')}
					<div className="form__group spc--left--med">
						<input
							name="signerInformation.signerAgreement"
							type="checkbox"
							id="signerInformation.signerAgreement"
							className="input--check"
							checked={get(mpa,'signerInformation.signerAgreement')}
							disabled
						/>
						<label htmlFor="signerInformation.signerAgreement">
							I certify that the signer listed above is listed on the business' corporate documents.
						</label>
					</div>
				</div>
			</div>
		);
	}

	renderEbtNumber() {
		return null;
	}
}
