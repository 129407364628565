import { each, find, get, findIndex, cloneDeep, isArray, isEmpty, map, orderBy, first, last, some, toLower, has } from 'lodash';
import moment from 'moment';
import { Data } from '../react-data-grid-addons';
import { parse, stringify } from 'query-string';
import { appService } from '../../../services';
import gatewaysWithFreeTransactions from '../../constants/gatewaysWithFreeTransactions';
import { equipmentDB, equipmentDatabaseKeys as keys } from '../../../helpers/indexedDB';

export function collapseSidebar(wasSidebarExpanded) {
    sessionStorage.setItem('collapseSidebar', !wasSidebarExpanded);
}

function loadMore ({ rowsPerPage, fullFilteredRows }, setState, gridRef) {
    const pagedFilteredRows = getPage(
        fullFilteredRows,
        1,
        rowsPerPage || fullFilteredRows.length
    );
    const newState = {
        filteredRows: pagedFilteredRows,
        activePage: 1,
    };
    setState(newState, () => {
        if (gridRef.current) {
            gridRef.current.scrollTo({ top: 0, left: 0 });
        }
    });
}
export function getPage(data, activePage, rowsPerPage) {
    if (rowsPerPage === 0) rowsPerPage = data.length;
    const startIndx = (activePage - 1) * rowsPerPage;
    const endIndx = activePage * rowsPerPage;
    if (data.length < startIndx + 1) {
        return [];
    }
    return data.slice(startIndx, endIndx);
}
export function formatDate(inDate) {
    const inputFormat = process.env.REACT_APP_API_RESPONSE_DATE_TIME_FORMAT;
    const outputFormat = process.env.REACT_APP_DISPLAY_DATE_TIME_FORMAT;
    if (inDate) {
        const date = moment(inDate, inputFormat);
        if (date.year() === 1)
            return '';
        return (
            date.format(outputFormat)
        );
    }
}
export function resolveColumnName(column) {
    let key = column;
    switch (column) {
        case 'enteredDateDisplay':
            key = 'enteredDate';
            break;
        case 'deactivatedDateDisplay':
            key = 'deactivatedDate';
            break;
        default:
            break;
    }
    return key;
}
export function mapData(data, setState, gridRef) {
    let i = 0;
    if (data && data.xReportData && data.xReportData.length > 0) {
        each(data.xReportData, (item) => {
            item.enteredDateDisplay = formatDate(item.enteredDate);
            item.deactivatedDateDisplay = formatDate(item.deactivatedDate);
            item.openActions = openActions(setState, gridRef);
            item.gridRowNumber = i;
            item.index = i;
            i++;
        });
    }
}
export const openActions = (setState, gridRef) => (infoDimensions, tooltip) => {
    setState({ tooltipProps: { infoDimensions, tooltip } }, () => {
        if (gridRef.current) {
            gridRef.current.forceUpdate();
        }
    });
}
export const hasMoreData = (rowsPerPage, totalRowCount) => () => {
    return rowsPerPage && (totalRowCount || 0) > rowsPerPage;
}
export function formatColumns(columns, appliedFilter = null) {
    if (appliedFilter) {
        for (let prop in appliedFilter) {
            if (appliedFilter.hasOwnProperty(prop)) {
                let column = find(columns, (i) => {
                    return (
                        i.key.toLowerCase() === prop.toLowerCase() &&
                        !i.visible
                    );
                });
                if (column) {
                    column.visible = true;
                }
            }
        }
    }
    return columns;
}
export function removeOrphanedDependencies(eqp, sourceEquipment) {
    let needAnotherCleanup = false;
    each(Object.keys(get(sourceEquipment, 'equipmentOptions', {})).filter(e => !!sourceEquipment.equipmentOptions[e].dependentOnName),
        option => {
        let sourceOption = sourceEquipment.equipmentOptions[option];
        let merchOption = eqp.equipmentOptions[sourceOption.name];
        if (merchOption) {
            let merchParentOption = eqp.equipmentOptions[sourceOption.dependentOnName];
            if (!merchParentOption || merchParentOption != sourceOption.dependentOnValue) {
                needAnotherCleanup = needAnotherCleanup || !!Object.keys(eqp.equipmentOptions).find(d => sourceEquipment.equipmentOptions[d].dependentOnName == sourceOption.name
                    && eqp.equipmentOptions[sourceOption.name] == sourceEquipment.equipmentOptions[d].dependentOnValue);
                delete eqp.equipmentOptions[sourceOption.name];
            }
        }
    });
    if (eqp.subequipment) {
        removeSubequipmentOrphanedDependencies(eqp, sourceEquipment);
    }
    if (needAnotherCleanup) {
        removeOrphanedDependencies(eqp, sourceEquipment);
    }
}
export function removeSubequipmentOrphanedDependencies(eqp, sourceEquipment) {
    each(eqp.subequipment, sub => {
        let sourceSub = sourceEquipment.subequipment.find(s => s.equipmentId == sub.equipmentId);
        each(Object.keys(sourceSub.equipmentOptions).filter(e => !!sourceSub.equipmentOptions[e].dependentOnName),
            option => {
            let sourceOption = sourceEquipment.equipmentOptions[option];
                let merchOption = sub.equipmentOptions[sourceOption.name];
            if (merchOption) {
                let merchParentOption = eqp.equipmentOptions[sourceOption.dependentOnName];
                if (!merchParentOption || merchParentOption != sourceOption.dependentOnValue) {
                    delete eqp.equipmentOptions[sourceOption.name];
                }
            }
        });
    });
}
export function setDefaultValues(eqp, sourceEquipment) {
    each(get(sourceEquipment, 'equipmentOptions'), option => {
        let merchOption = get(eqp, 'equipmentOptions', {})[option.name];
        if (!merchOption && !!option.defaultValue && !!option.dependentOnName) {
            let merchParentOption = eqp.equipmentOptions[option.dependentOnName];
            if (merchParentOption === option.dependentOnValue) {
                eqp.equipmentOptions[option.name] = option.defaultValue;
            }
        }
    });
    if (eqp.subequipment) {
        setSubequipmentDefaultValues(eqp, sourceEquipment);
    }
}
export function setSubequipmentDefaultValues(eqp, sourceEquipment) {
    each(eqp.subequipment, sub => {
        let sourceSub = sourceEquipment.subequipment.find(s => s.equipmentId == sub.equipmentId);
        each(sourceSub.equipmentOptions, option => {
            let merchOption = sub.equipmentOptions ? sub.equipmentOptions[option.name] : null;
            if (!merchOption && !!option.defaultValue && !!option.dependentOnName) {
                let merchParentOption = sub.equipmentOptions[option.dependentOnName];
                if (merchParentOption === option.dependentOnValue) {
                    sub.equipmentOptions[option.name] = option.defaultValue;
                }
            }
        });
    });
}
export const handleEquipmentChange = (findEquipment, setState, state, closeItemsModalAndValidate) => equipment => {
    let merchEquipment = cloneDeep(state.merchantEquipment);
    if (!isArray(equipment)) {
        equipment = [equipment];
    }
    each(equipment, eqp => {
    let sourceEquipment = findEquipment(eqp.equipmentId, !!eqp.parentEquipmentId);
        setDefaultValues(eqp, sourceEquipment);
        removeOrphanedDependencies(eqp, sourceEquipment);
        const equipmentIndex = findIndex(merchEquipment, equip => {
            if (eqp.id) {
                return eqp.id == equip.id && eqp.equipmentId == equip.equipmentId;
            } else if (eqp.parentEquipmentId) {
                return eqp.parentEquipmentId == equip.parentEquipmentId && eqp.equipmentId == equip.equipmentId;
            } else {
                return eqp.equipmentId == equip.equipmentId;
            }
        });
        if (equipmentIndex < 0) {
            merchEquipment.push(eqp);
        } else {
            merchEquipment[equipmentIndex] = eqp;
        }
    });
    const eqpWithoutErrors = merchEquipment.map(({ errors, attachment, ...keepAttrs }) => keepAttrs);
    equipmentDB.setEquipment(keys.merchantEquipment, { appId: state.appId, equipment: eqpWithoutErrors });
    setState({ merchantEquipment: merchEquipment }, closeItemsModalAndValidate);
}
function mapSubequipment(selectedEqp, merchantEquipment, parentPlanId) {
    let planId = selectedEqp.defaultPlanId;
    if (!!selectedEqp.hasPlanMapping) {
        const parentEquipmentPlanId = parentPlanId || !isEmpty(merchantEquipment) && merchantEquipment.find(e => e.equipmentId == selectedEqp.parentEquipmentId).purchasePlanId;
        const mappedRules = selectedEqp.planMapping.find((mapping, i) => mapping.parentEquipmentPlanId == parentEquipmentPlanId);

        if (mappedRules && mappedRules.excludeSubequipment) {
            return;
        }
        if (mappedRules && !!mappedRules.planIdForFirstEquipment) {
            planId = mappedRules.planIdForFirstEquipment;
        }
    }
    return {
        purchasePlan: selectedEqp.purchasePlans.find((pln, i) => pln.planId == planId),
        paymentSchedule: 'a',
    };
}

function getMerchantEquipment(state, equipmentId, isAccessory) {
    return cloneDeep(state.merchantEquipment.find((e, i) => e.equipmentId == equipmentId && !isAccessory));
}

export const createNewEquipment = (state, findEquipment) => {
    const createNewEquipmentHandler = (equipmentId, isSelected, isSubequipment, isAccessory, parentEquipmentId, parentPurchasePlanId, defaultQuantity = 1) => {
        let eqp = getMerchantEquipment(state, equipmentId, isAccessory);
        if (eqp) {
            eqp.isSelected = isSelected;
        } else {
            let selectedEqp = findEquipment(equipmentId, isSubequipment, isAccessory, parentEquipmentId);
            if (!selectedEqp) return {};
            let purchaseType = selectedEqp.purchaseTypes && Object.keys(selectedEqp.purchaseTypes)[0];
            const planGetter = selectedEqp.name === 'Cardknox' ? last : first;
            let purchasePlan = planGetter(selectedEqp.purchasePlans, plan => plan.purchaseTypes.includes(purchaseType));
            let paymentSchedule = '';
            if (isSubequipment) {
                const mappedSubequipment = mapSubequipment(selectedEqp, state.merchantEquipment, parentPurchasePlanId);
                if (mappedSubequipment) {
                    purchasePlan = mappedSubequipment.purchasePlan;
                    paymentSchedule = mappedSubequipment.paymentSchedule;
                }
            }
            eqp = {
                equipmentId: selectedEqp.equipmentId,
                country: selectedEqp.country,
                appId: state.appId,
                category: selectedEqp.category,
                name: selectedEqp.name,
                isSelected,
                quantity: defaultQuantity,
                purchaseType,
                purchasePlanId: purchasePlan && purchasePlan.planId,
                purchasePlanTag: purchasePlan && purchasePlan.planTag,
                paymentSchedule,
                fees: cloneDeep(get(purchasePlan, 'fees', []).filter((f) => f.purchaseTypes.includes(purchaseType))),
                additionalFees: cloneDeep(get(purchasePlan, 'additionalFees', []).filter((f) => f.purchaseTypes.includes(purchaseType))),
                equipmentOptions: {},
                shippingAddress: {},
                subequipment: [],
            };
            if (purchasePlan?.defaultFreeTransactions) {
                eqp.numberOfFreeTransactions = toLower(eqp.category) !== "gateway" || some(gatewaysWithFreeTransactions, name => name === toLower(eqp.name)) ? purchasePlan.defaultFreeTransactions : "0";
            }
            selectedEqp.autoEnabledEquipment && each(selectedEqp.autoEnabledEquipment, subequipmentId => {
                const subequipment = createNewEquipmentHandler(subequipmentId, false, true, false, equipmentId)
                eqp.subequipment.push(subequipment);
            });
            if (isSubequipment) {
                eqp.parentEquipmentId = selectedEqp.parentEquipmentId;
                eqp.allowMultiple = selectedEqp.allowMultiple;
            }
            each([...eqp.fees, ...eqp.additionalFees], (fee) => {
                fee.merchantPrice = fee.retailPrice;
            });
            each(selectedEqp.equipmentOptions, (option) => {
                if (!!option.defaultValue && !option.dependentOnName) {
                    eqp.equipmentOptions[option.name] = option.defaultValue;
                }
            });
        }
        return eqp;
    }
    return createNewEquipmentHandler;
};
export function mapCellArgs(
    rowId,
    row,
    _,
    openOnly = false,
    focusSchedule = false
) {
    if (rowId < 0) {
        return;
    }
    const args = {
        rowData: row,
        expandArgs: {
            children: [
                {
                    isDetails: true,
                    row: row,
                    focusSchedule: focusSchedule,
                },
            ],
        },
        openOnly: openOnly,
    };
    return args;
}
export const onInfoHover = (setState, gridRef) => (infoDimensions, tooltip) => {
    setState({ infoDimensions, tooltip }, () => {
        if (gridRef.current) {
            gridRef.current.forceUpdate();
        }
    });
};

export const handleChange = (setState, state) => (changes) => {
    const newState = {};
    each(changes, ({ key, value }) => {
        if (
            key === 'data' ||
            key === 'inlineFilters' ||
            key === 'activePage'
        ) {
            let filters, data, activePage;
            if (key === 'data') {
                filters = state.inlineFilters;
                activePage = 1;
                data = value;
            } else if (key === 'inlineFilters') {
                filters = value;
                data = state.data;
                activePage = 1;
            } else {
                activePage = value;
                data = state.data;
                filters = state.inlineFilters;
            }
            let filteredRows =
                data && data.xReportData
                    ? Data.Selectors.getRows({
                          rows: data.xReportData,
                          filters,
                      })
                    : [];
            const pagedFilteredRows = getPage(
                filteredRows,
                activePage,
                state.rowsPerPage
            );
            newState.filteredRows = pagedFilteredRows;
            newState.fullFilteredRows = filteredRows;
            newState.totalRowCount = filteredRows.length;
        }
        newState[key] = value;
    });
    return new Promise((resolve) => {
        setState(newState, resolve);
    });
};

export function queryFilterValues(filters, query = '', initialExpandRow = false) {
    const {
        history,
        location: { search: oldSearch },
    } = this.props;
    let result = {};

    if (query) {
        result = parse(query);

        if (!!result.expandedRow && initialExpandRow) {
            this.setState({ expandedRow: result.expandedRow });
        }
    }

    each(filters, ({ values, hasSelection }) => {
        if (!hasSelection) return;
        each(values, (value, key) => {
            if (!value || key === 'fieldName') return;
            if (moment(value, process.env.REACT_APP_DATE_PICKER_FORMAT, true).isValid()) {
                result[key] = value.format(process.env.REACT_APP_DATE_PICKER_FORMAT);
            } else {
                result[key] = value;
            }
        });
    });
    delete result.refresh;
    const search = stringify(result);
    if (`?${search}` !== oldSearch) {
        const updateParameters = {
            [this.updateParameters]: this.props.location[this.updateParameters],
        };
        history.replace({ search, ...updateParameters });
    }
}

export function parseQueriesToFilters(fetchAgentViewsWhenDone = true) {
    const queryParams = parse(this.props.location.search);
    const originalFilters = this.state.filters;
    const filters = parseQueryFilters(this.state.filters, queryParams);
    const activeFilters = parseQueryFilters(this.state.activeFilters, queryParams);
    this.queryFilterValues(filters, this.props.location.search, true);
    this.setState({ filters, activeFilters }, () => {
        // There is no need to set the default agent view if we already
        // passed in parameters through the URL
        if (originalFilters !== filters || !fetchAgentViewsWhenDone) {
            return resetGrid.bind(this)();
        }
        this.fetchAgentViews(true);
    });
};

export const parseQueryFilters = (filters, params) => {
    if (isEmpty(params)) {
        return filters;
    }
    const newFilters = cloneDeep(filters);
    let anyChanged = false;

    each(newFilters, ({ values }, index) => {
        each(values, (_, key) => {
            const value = params[key];
            if (value) {
                const date = moment(value, process.env.REACT_APP_DATE_PICKER_FORMAT, true);

                newFilters[index].hasSelection = true;
                newFilters[index].values[key] = date.isValid() ? date : value;
                anyChanged = true;
            }
        });
    });

    return anyChanged ? newFilters : filters;
};

export function mapDataToGridCommon(
    dataObj,
    data,
    activePage,
    rowsPerPage,
    formattedColumns,
    lastApiRefNum
) {
    const filteredRows = data
    ? Data.Selectors.getRows({
        rows: dataObj.xReportData,
        filters: this.state.inlineFilters,
    })
    : [];

    const pagedFilteredRows = getPage(
        filteredRows,
        activePage,
        rowsPerPage
    );

    this.gridRef.current.scrollTo({ top: 0, left: 0 });

    this.setState(
        {
            data: dataObj,
            fullFilteredRows: filteredRows,
            filteredRows: pagedFilteredRows,
            fetchingData: false,
            columns: formattedColumns,
            lastApiRefNum: lastApiRefNum,
            totalRowCount: filteredRows.length,
        },
        () => {
            if (this.gridRef.current) {
                this.gridRef.current.handleInitialSort();
            }
        }
    );
}

export function onLoadMoreLimitChange(rowsPerPage) {
    this.setState({ rowsPerPage: parseInt(rowsPerPage || this.state.fullFilteredRows.length, 10) }, () => loadMore(this.state, this.setState, this.gridRef));
}

export async function deleteAgentView(viewName) {
    await appService.deleteAgentView(viewName, this.viewType);
    await this.fetchAgentViews(true);
}

export async function saveCurrentView(viewName) {
    const { filters, rowsPerPage, columns } = this.state;
    const viewJson = {
        rowsPerPage,
        columns: map(columns, ({ sortDirection, width, key, visible, order, isAdvancedField }) => ({
            key,
            sortDirection,
            initWidth: width,
            order,
            visible,
            isAdvancedField,
        })),
        filters: map(filters, ({ key, values, hasSelection }) => {
            const parsedValues = { ...values };
            each(values, (value, valueKey) => {
                if (!value || !value.isValid || !value.isValid()) return;
                parsedValues[valueKey] = value.format(process.env.REACT_APP_DATE_PICKER_FORMAT);
            })
            return {
                key,
                hasSelection,
                values: parsedValues,
            };
        }),
    };

    await appService.saveAgentView(viewName, viewJson, this.viewType);
    await this.fetchAgentViews(true);
}

export async function clearAgentView(resetDefaultView) {
    if (resetDefaultView) {
        await appService.updateDefaultAgentView(null, this.viewType);
    }
    this.setState(this.initialState, () => {
        this.fetchAgentViews(false);
        this.gridRef.current.clearFilters();
        this.gridRef.current.reset();
    });
}

export async function applyAgentView(viewName, agentView, saveAsDefault = true) {
    const customRowsPerPage = get(agentView, 'rowsPerPage');
    const customColumns = get(agentView, 'columns');
    const customFilters = get(agentView, 'filters');
    const columns = updateColumnsWithCustomData(this.state.columns, customColumns);
    const filters = updateFiltersWithCustomData(this.state.filters, customFilters);
    this.queryFilterValues(filters, '', true);
    this.setState(
        {
            columns,
            filters,
            activeFilters: cloneDeep(filters),
            filterProps: {
                ...this.state.filterProps,
                selectedView: viewName,
            },
            rowsPerPage: customRowsPerPage != null ? parseInt(customRowsPerPage, 10) : this.state.rowsPerPage,
        },
        async () => {
            const reset = resetGrid.bind(this);
            if (!saveAsDefault) return reset();
            await appService.updateDefaultAgentView(viewName, this.viewType);
            return reset();
        }
    );
}

export async function fetchAgentViews(applyDefaultView) {
    this.setState({
        filterProps: {
            ...this.state.filterProps,
            isLoadingAgentViews: true,
        }
    });
    try {
        const { agentViews } = await appService.getAgentViews(this.viewType);
        this.setState({
            filterProps: {
                ...this.state.filterProps,
                isLoadingAgentViews: false,
                agentViews,
            }
        }, () => {
            if (!applyDefaultView) return;
            const defaultView = find(this.state.filterProps.agentViews, { isDefault: true });
            if (!defaultView) return resetGrid.bind(this)();
            this.applyAgentView(defaultView.viewName, JSON.parse(defaultView.viewJson), false);
        });
    } catch (e) {
        if (this.props.handleError(e)) {
            this.setState({
                filterProps: {
                    ...this.state.filterProps,
                    isLoadingAgentViews: false,
                }
            });
        }
    }
}


function updateColumnsWithCustomData(columns, customColumns) {
    if (isEmpty(customColumns)) return columns;
    const newColumns = [...columns];
    each(customColumns, ({ key, ...customConfig }) => {
        const index = findIndex(columns, { key });
        if (index === -1) return;
        newColumns[index] = {
            ...columns[index],
            ...customConfig,
            order: customConfig.order || index,
        };
    });
    return orderBy(newColumns, 'order');
}

function updateFiltersWithCustomData(filters, customFilters) {
    if (isEmpty(customFilters)) return filters;
    const newFilters = [...filters];
    each(customFilters, ({ key, values, hasSelection }) => {
        const index = findIndex(filters, { key });
        if (index === -1) return;
        const parsedValues = {
            ...values,
        };
        each(parsedValues, (value, key) => {
            const date = moment(value, process.env.REACT_APP_DATE_PICKER_FORMAT, true);
            if (!date.isValid()) return;
            parsedValues[key] = date;
        })
        const newValues = {
            ...filters[index].values,
        };
        
        each(parsedValues, (value, key) => {
            if(has(newValues, key)) {
                newValues[key] = value;
            }
        })
        newFilters[index] = {
            ...filters[index],
            values: newValues,
            hasSelection,
        };
    });
    return newFilters;
}

function resetGrid() {
    if (this.gridRef.current) {
        this.gridRef.current.reset();
    }
}