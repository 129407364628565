import React, { Component, Fragment, createRef } from 'react';
import PropTypes from 'prop-types';
import { Data } from '../../common/components/react-data-grid-addons';
import { cloneDeep, each, find, unionBy, map, get, noop, concat, toLower, includes } from 'lodash';

import { MerchantColumns as Columns } from './column-filter/merchantColumns';
import { merchantsFilter as Filter, compileFilter } from './filter/merchantsFilter';
import { appService } from '../../services/appService';
import withError from '../../common/components/error/error-hoc';
import { GridComponent, ToolbarComponent } from '../../common/components/grid';
import { ModalWrapper, modalNames } from './../../common/components/modal-wrapper';
import { ZebraRenderer } from '../../common/components/row';
import { actionsGridColumn } from '../../common/components/actionsGrid/merchantsActionsGridColumn';
import { Notification } from '../../common/components/notifications';
import { LoadMoreOptions } from '../../common/utilities';
import { GridTooltip } from '../../common/components/tooltips';
import {
    formatColumns,
    getPage,
    hasMoreData,
    mapCellArgs,
    mapDataToGridCommon,
    onInfoHover,
    onLoadMoreLimitChange,
    openActions,
    queryFilterValues,
    parseQueriesToFilters,
    saveCurrentView,
    deleteAgentView,
    applyAgentView,
    clearAgentView,
    fetchAgentViews,
} from '../../common/components/grid/commonGridMethods';
import { SavedFilterComponent } from '../../common/components/filter';
import { principalService } from '../../services';
import { getProcessorDisplayName } from '../../common/utilities/processorDisplayName';

const loadMoreOptionsWithAll = concat(LoadMoreOptions, [0]);

class MerchantGrid extends Component {
    constructor(props) {
        super(props);
        this.state = cloneDeep(this.initialState);
        this.viewType = 'Merchant'

        this.gridRef = createRef();
        this.notificationRef = createRef();

        this.setState = this.setState.bind(this);
        this.mapDataToGrid = mapDataToGridCommon.bind(this);
        this.onLoadMoreLimitChange = onLoadMoreLimitChange.bind(this);
        this.parseQueriesToFilters = parseQueriesToFilters.bind(this);
        this.queryFilterValues = queryFilterValues.bind(this);
        this.saveCurrentView = saveCurrentView.bind(this);
        this.deleteAgentView = deleteAgentView.bind(this);
        this.applyAgentView = applyAgentView.bind(this);
        this.clearAgentView = clearAgentView.bind(this);
        this.fetchAgentViews = fetchAgentViews.bind(this);
    }

    get initialState() {
        const principal = principalService.get();
        const filters =
            unionBy(this.props.location.filters, Filter, 'key') || Filter;

        return {
            data: null,
            filteredRows: [],
            fullFilteredRows: [],
            filters: filters,
            activeFilters: cloneDeep(filters),
            inlineFilters: {
                isHidden: {
                    column: this.getCollectionItem(Columns, 'isHidden'),
                    filterTerm: 'false',
                },
            },
            expanded: {},
            maxGridHeight: 0,
            columns: Columns,
            defaultColumns: cloneDeep(Columns),
            fetchingData: true,
            fetchingAdditionalData: false,
            showFilters: true,
            lastApiRefNum: null,
            activePage: 1,
            rowsPerPage: 20,
            totalRowCount: 0,
            modal: {
                name: modalNames.none,
                data: null,
            },
            filterProps: {
                isLoadingAgentViews: false,
                agentViews: [],
                clearAgentView: (...params) => this.clearAgentView(...params),
                applyAgentView: (...params) => this.applyAgentView(...params),
                saveCurrentView: viewName => this.saveCurrentView(viewName),
                deleteAgentView: viewName => this.deleteAgentView(viewName),
            },
            isLinkedUserSupportRep: principal.isLinkedUserSupportRep,
        };
    }

    componentDidMount() {
        this.parseQueriesToFilters();
	};

    componentDidUpdate = (prevProps) => {
        if (prevProps.location.key !== this.props.location.key && includes(this.props.location.search, 'refresh=true')) {
            this.clearAgentView();
        }
    };

    handlePageChange = (pageNumber) => {
        //console.log('active page is ' + pageNumber);
        this.handleChange([{ key: 'activePage', value: pageNumber }]);
    };

    fetchData = async (filters) => {
        const filter = compileFilter(filters);
        //console.log('fetching data');
        this.setState({
            fetchingData: true,
            data: null,
            filteredRows: [],
            fullFilteredRows: [],
            expanded: {},
            lastApiRefNum: null,
            totalRowCount: 0,
        });

        let lastApiRefNum = null;
        const { rowsPerPage, activePage } = this.state;
        //console.log('rows per page: ' + rowsPerPage + ' active page ' + activePage);

        let data = await appService.getMerchants(filter); //(filter);

        // check for gridRef.current to make sure page didn't unload while data was loading
        if (this.gridRef.current) {
            lastApiRefNum = data.refNum;
            let dataObj = { xReportData: data.merchants };

            if (dataObj && dataObj.xReportData) {
                dataObj.xReportData = map(dataObj.xReportData, this.mapRow);
            }

            const formattedColumns = formatColumns(
                this.state.columns,
                cloneDeep(filter)
            );
            this.mapData(dataObj);
            this.mapDataToGrid(
                dataObj,
                data.merchants,
                activePage,
                rowsPerPage,
                formattedColumns,
                lastApiRefNum
            );
        }
    };

    mapData = (data) => {
        let i = 0;
        if (data && data.xReportData && data.xReportData.length > 0) {
            each(data.xReportData, (item) => {
                item.openActions = openActions((e, d = noop) => this.setState(e, d), this.gridRef);
                item.gridRowNumber = i;
                item.index = i;
                i++;
            });
        }
    };

    getCollectionItem = (collection, key) => {
        return find(collection, { key });
    };

    mapRow = (row) => ({
        ...row,
        isExpandable: true,
        refreshGridData: this.gridRef.current ? this.gridRef.current.refreshGridData : null,
        processorName: getProcessorDisplayName(row.processorName),
        onInfoHover: onInfoHover((e, d = noop) => this.setState(e, d), this.gridRef),
        unhide: (appId) =>  appService.unhideMerchant(appId),
        hide: (appId) => appService.hideMerchant(appId),
        openCloseModal: this.openCloseModal,
        refetchData: this.refetchData
    });

    onRowClick = (...params) => {
        if (params[0] < 0 || params[2].key === 'isHidden') {
            return;
        }
        const { history } = this.props;
        const row = params[1];
        history.push(this.createDetailsLink(row));
    };

    createDetailsLink = (row) => {
        let status = row.merchantStatus;
        let detailsLink = `/merchants/${row.appId}/account`;
        if (status === 'Incomplete') {
            detailsLink = '/eapp/mpa/' + row.appId;
        }
        return detailsLink;
    }

    calculatePopupLeft = () => {
        return this.state.infoDimensions.width - 36;
    };

    calculatePopupTop = () => {
        let offset = 0;
        if (
            this.gridRef.current &&
            this.gridRef.current.gridHolderRef.current
        ) {
            offset =
                this.gridRef.current.gridHolderRef.current.getBoundingClientRect()
                    .y -
                this.gridRef.current.gridHolderRef.current.scrollTop -
                25;
        }
        return this.state.infoDimensions.height - offset;
    };

    renderTooltip = () =>
        this.state.tooltip ? (
            <div
                style={{
                    left: this.calculatePopupLeft(),
                    top: this.calculatePopupTop(),
                    position: 'absolute',
                    backgroundColor: '#fff',
                    zIndex: 99,
                }}
            >
                {this.state.tooltip}
            </div>
        ) : null;

    refetchData = () => {
        this.fetchData(this.state.activeFilters);
    };

    handleChange = (changes) => {
        const newState = {};
        each(changes, ({ key, value }) => {
            if (
                key === 'data' ||
                key === 'inlineFilters' ||
                key === 'activePage'
            ) {
                let filters, data, activePage;
                if (key === 'data') {
                    filters = this.state.inlineFilters;
                    activePage = 1;
                    data = value;
                } else if (key === 'inlineFilters') {
                    filters = value;
                    data = this.state.data;
                    activePage = 1;
                } else {
                    activePage = value;
                    data = this.state.data;
                    filters = this.state.inlineFilters;
                }
                let filteredRows =
                    data && data.xReportData
                        ? Data.Selectors.getRows({
                              rows: data.xReportData,
                              filters,
                          })
                        : [];
                const pagedFilteredRows = getPage(
                    filteredRows,
                    activePage,
                    this.state.rowsPerPage
                );
                newState.filteredRows = pagedFilteredRows;
                newState.fullFilteredRows = filteredRows;
                newState.totalRowCount = filteredRows.length;
                newState.activePage = activePage;
            }
            newState[key] = value;
        });
        return new Promise((resolve) => {
            this.setState(newState, resolve);
        });
    };

    openCloseModal = (modalObj, ...rest) => {
        let state = {
            modal: modalObj,
        };
        this.setState(state);
    };

    renderNewLeadPopup = () => {
        const { isLinkedUserSupportRep } = this.state;

        return (
            <Fragment>
                {!isLinkedUserSupportRep &&
                <button
                    type="button"
                    className="btn btn--primary btn--med"
                    onClick={() => this.openCloseModal({ name: modalNames.newLead, data: { history: this.props.history, addNotification: get(this.notificationRef, 'current.addNotification', noop) } })} >
                    Add <span className="hide--to--sml--inline spc--left--tny">New Account</span>
                </button>}
            </Fragment>
        );
    };

    renderHeader = ({ refreshGridData }) => (
        <div className="push">
            {this.renderNewLeadPopup()}
        </div>
    );

    renderGridHeader = ({ refreshGridData }) => (
        <Fragment>{this.renderNewLeadPopup()}</Fragment>
    );

    render = () => {
        const {
            modal,
            fetchingAdditionalData,
            fetchingData,
            filteredRows,
            fullFilteredRows,
            columns,
            data,
            inlineFilters,
            expanded,
            filters,
            activeFilters,
            lastApiRefNum,
            defaultColumns,
            activePage,
            rowsPerPage,
            totalRowCount,
            tooltipProps,
            filterProps,
        } = this.state;

        return (
            <Fragment>
                <div className="l--content--grid">
                <Notification ref={this.notificationRef} />
                <ModalWrapper
                    modal={modal}
                    onModalClose={this.openCloseModal}
                />
                <GridComponent
                    emptyMessage="You should change your filter options"
                    fetchingData={fetchingData}
                    fetchingAdditionalData={fetchingAdditionalData}
                    filteredRows={filteredRows}
                    fullFilteredRows={fullFilteredRows}
                    loadMoreOptions={loadMoreOptionsWithAll}
                    onLoadMoreLimitChange={this.onLoadMoreLimitChange}
                    loadMoreLimit={rowsPerPage}
                    columns={columns}
                    fixHeader={true}
                    data={data}
                    inlineFilters={inlineFilters}
                    components={{
                        toolbar: ToolbarComponent,
                        header: this.renderHeader,
                        gridHeader: this.renderGridHeader,
                        filter: SavedFilterComponent,
                        rowRenderer: ZebraRenderer,
                        tooltip: GridTooltip,
                    }}
                    onChange={this.handleChange}
                    rowKey="AppId"
                    isExpandable={true}
                    expanded={expanded}
                    title="Merchants"
                    enableExport={true}
                    enablePrint={true}
                    type="merchants"
                    filters={filters}
                    activeFilters={activeFilters}
                    showFilters={true}
                    fetchData={this.refetchData}
                    lastApiRefNum={lastApiRefNum}
                    initialFetch={false}
                    showResults={true}
                    mapCellArgs={mapCellArgs}
                    showExportDropdown={false}
                    showPrintDropdown={false}
                    filterColumns={true}
                    defaultColumns={defaultColumns}
                    onRowClick={this.onRowClick}
                    createDetailsLink={this.createDetailsLink}
                    enableFilters={true}
                    ref={this.gridRef}
                    useInlineFilters={true}
                    hasPaging={true}
                    hasMoreData={hasMoreData(rowsPerPage, totalRowCount)}
                    handlePageChange={this.handlePageChange}
                    pagination={{ activePage, rowsPerPage, totalRowCount }}
                    actionsGridColumn={actionsGridColumn}
                    syncQueryFilters={true}
                    queryFilterValues={this.queryFilterValues}
                    tooltipProps={tooltipProps}
                    filterProps={filterProps}
                    canReorderColumns={true}
                />
                </div>
            </Fragment>
        );
    };
}

MerchantGrid.propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
};

export default withError(MerchantGrid);
