import sectionKeys from './../routing/sections';
import { decode } from 'jsonwebtoken';
import {  get, isFunction } from 'lodash';
 import { Auth, Hub } from 'aws-amplify';
import { getApiConfiguration, setApiConfiguration } from '../common/utilities/apiConfiguration';
import { deleteIDB } from '../helpers/indexedDB';

const principalKey = 'PRINCIPAL';
let lastEmail = '';

function updateFS({ payload: { event, data } }) {
    const isFSLoaded = isFunction(get(window, 'FS.identify'));
    if (event === 'signOut') {
		if (!isFSLoaded) return;
        lastEmail = '';
		window.FS.identify(false);
        return;
    }
    if (event !== 'signIn') {
        return;
    }
    const email = get(data, 'attributes.email', '');
    if (lastEmail === email) {
        return;
    }
    lastEmail = email;
    const username = get(data, 'username');
    if (isFSLoaded && username) {
        window.FS.identify(username, { email });
    }
}

Hub.listen('auth', updateFS);
Auth.currentAuthenticatedUser()
    .then(data => {
        if (data) {
            updateFS({ payload: { event: 'signIn', data } });
        }
    })
    .catch(() => {
        return;
    });

class Principal{
    constructor({ agentId, agentSugarId, name, emailAddress, token, roles = [], agentList, agentSubId, agentSubName, agentSubEmail, userType, redirectToSecurity = false, isSamlLogin = false }) {
        this.agentId = agentId;
        this.agentSugarId = agentSugarId;
        this.name = name;
        this.email = emailAddress;
        this.token = token;
        this.roles = roles;
        this.agentList = agentList;
        this.agentSubId = agentSubId;
        this.agentSubName = agentSubName;
        this.agentSubEmail = agentSubEmail;
        this.isQaUser = roles.includes("QuickAssist");
        this.redirectToSecurity = redirectToSecurity;
        this.isSamlLogin = isSamlLogin;
        this.userType = userType

        const agentGoPlusLookup = {};
        agentGoPlusLookup[this.agentSugarId] = {canBoardGoPlus: this.canBoardGoPlus, hasOwnGoPlusTiers: this.hasOwnGoPlusTiers};
        if (this.agentList) {
            for (let i = 0; i < this.agentList.length; i++) {
                const agent = this.agentList[i]
                agent.principal = new Principal(agent)
                agentGoPlusLookup[agent.agentSugarId] = { canBoardGoPlus: agent.principal.canBoardGoPlus, hasOwnGoPlusTiers: agent.principal.hasOwnGoPlusTiers }
            }
        }
        this.agentGoPlusLookup = agentGoPlusLookup;

        // Permissions based on account key flags  
        this.hasAccess = {};
        this.hasAccess[sectionKeys.merchants] = !this.isMerchant;
        this.hasAccess[sectionKeys.leads] = !this.isMerchant;
        this.hasAccess[sectionKeys.tickets] = !this.isMerchant;
        this.hasAccess[sectionKeys.merchanteapp] = this.isMerchant;
        this.hasAccess[sectionKeys.admin] = this.isAdmin || this.isDropinUser;
        this.hasAccess[sectionKeys.settings] = !this.isMerchant;
        this.hasAccess[sectionKeys.validpartner] = this.isValidPartner;
        this.hasAccess[sectionKeys.cobrand] = this.isCobrand;
    }

    get isAdmin () {
        //console.log(this.roles.includes("Admin"));
        return this.roles.includes("Admin");
    }
    get isInternal() {
        return this.roles.includes("Internal");
    }
    get isMerchant() {
        return this.roles.includes("Merchant");
    }
    get isAgent() {
        return this.roles.includes("Agent");
    }
    get isValidPartner() {
        return this.roles.includes("Partner");
    }
    get isAgentSubUser() {
        return this.agentSubId > 0;
    }
    get canBoardGoPlus() { 
        return this.roles.includes("GoPlusAssociatedRep") || this.roles.includes("GoPlusRep"); 
    }
    get hasOwnGoPlusTiers() { 
        return this.roles.includes("GoPlusRep"); 
    }
    get isResidual() { 
        return this.roles.includes("Residuals"); 
    }
    get isCobrand() { 
        return this.roles.includes("Cobrand"); 
    }
    get isDropinUser() {
        const decodedToken = decode(this.token, { complete: true });
        //console.log(decodedToken.payload);
        if (decodedToken && !!decodedToken.payload.actort) {
            return true;
        }
        return false;
    }
    get isLinkedUserSupportRep() {
        return this.roles.includes("LinkedUser_SupportRep"); 
    }
    get isLinkedUserSubAgent() {
        return this.roles.includes("LinkedUser_SubAgent"); 
    }
    get isLinkedUserExecutive() {
        return this.roles.includes("LinkedUser_Executive"); 
    }
    get isLinkedUser() {
        return this.userType =="utype45";
    }
    get impersonatorUsername() {
        const decodedToken = decode(this.token, { complete: true });
        //console.log(decodedToken.payload);
        if (!!decodedToken.payload.actort) {
            return decodedToken.payload.actort;
        }
        return '';
    }
    get emailFromToken() {
        const decodedToken = decode(this.token, { complete: true });
        
        if (!!decodedToken.payload.email) {
            return decodedToken.payload.email;
        }
        return '';
    }
}

class PrincipalService {
    principal = null;
    
    set(principalData) {
        if (principalData) {
            this.principal = new Principal(principalData);
            localStorage.setItem(principalKey, JSON.stringify(principalData));            
        }
    }

    get() {
        if (this.principal) {
			return this.principal;
		}
        const storedPrincipalData = JSON.parse(localStorage.getItem(principalKey));
        if (storedPrincipalData && storedPrincipalData.token) {
            return new Principal(storedPrincipalData);
        } else {
            return false;
        }
    }

    clear() {
        this.principal = null;
        // API configuration is also stored in localStorage so we need to restore it after clearing
        const currentConfiguration = getApiConfiguration();
        localStorage.clear();
        deleteIDB();
        setApiConfiguration(currentConfiguration);
    }
}


const principalService = new PrincipalService();

export default principalService;

