import React from "react";
import PropTypes from "prop-types";
import { every, mapValues } from "lodash";

import ToggleContext from "./ToggleContext";

class ToggleContainer extends React.Component {
  constructor(props) {
    super(props);
    const toggles = [];
    this.state = {
      toggles,
      isAllToggled: false,
      get: (key) => this.state.toggles[key],
      create: this.createToggleState,
      destroy: this.removeToggleState,
      toggleAll: this.toggleAll,
    };
  }

  isAllToggled = (toggles) => {
    return every(toggles, { isToggled: true });
  };

  toggleAll = () => {
    const { toggles } = this.state;
    const isAllToggled = this.isAllToggled(toggles);
    const newToggles = mapValues(toggles, ({ isToggled, ...rest }) => ({
      ...rest,
      isToggled: !isAllToggled
    }));
    this.setState({
      toggles: newToggles,
      isAllToggled: !isAllToggled,
    });
  }

  createToggleState = (key, isToggled = false) => {
    this.setState(({ toggles }) => ({
      toggles: {
        ...toggles,
        [key]: {
          isToggled,
          handleToggle: toggle => {
            const { toggles } = this.state;
            const newToggles = {...toggles};
            newToggles[key] = {
              ...newToggles[key],
              isToggled: toggle !== undefined && !toggle.target ? toggle : !newToggles[key].isToggled
            };
            this.setState({
              toggles: newToggles,
              isAllToggled: this.isAllToggled(newToggles),
            });
          },
        }
      },
      isAllToggled: this.state.isAllToggled && isToggled,
    }));
  };

  removeToggleState = key => {
    this.setState(({ toggles }) => {
      const newToggles = {...toggles};
      delete newToggles[key];
      return {
        toggles: newToggles,
        isAllToggled: this.isAllToggled(newToggles),
      }
    });
  };

  render() {
    const { children } = this.props;
    return (
      <ToggleContext.Provider value={this.state}>
        {children}
      </ToggleContext.Provider>
    )
  }
}

ToggleContainer.propTypes = {
  children: PropTypes.any
};

export default ToggleContainer;
