import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

export default function MerchantInfoHeader({ merchant }) {
	if (!get(merchant, 'dba') && !get(merchant, 'appId')) {
		return null;
	}

	return (
		<h5 className="type--color--text--light spc--bottom--med">
			{merchant.dba} - {merchant.appId}
		</h5>
	);
}

MerchantInfoHeader.propTypes = {
	merchant: PropTypes.shape({
		dba: PropTypes.string,
		appId: PropTypes.string,
	}),
};
