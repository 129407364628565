import React, { Component } from 'react';
import PropTypes from 'prop-types';

class BusinessContactNameSelectionComponent extends Component {

    get businessContactNameValue() {
        return this.props.filter.values.businessContactName;
    }

    filterRemoved = e => {
        this.props.onFilterRemoved({
            id: 'businessContactName',
            emptyValue: null
        });
    };

    render() {
        return (
            <div className="filter__tag">
                <label className="filter__tag__label">Contact Name: </label>
                {this.businessContactNameValue}
                <button onClick={this.filterRemoved} className="btn btn--reset filter__tag__clear"></button>
            </div>
        );
    }
}

BusinessContactNameSelectionComponent.propTypes = {
    filter: PropTypes.object.isRequired,
    onFilterRemoved: PropTypes.func.isRequired
};

export default BusinessContactNameSelectionComponent;