import { map, split, zip, find } from 'lodash';

const toParts = date => {
    return map(split(date, '-'), n => parseInt(n));
};

// check if YYYY-MM-DD date is after another YYYY-MM-DD
export const isAfter = (a, b) => { // not using moment to support hebrew calendar dates
    if (!a) {
        return;
    }
    const parts = zip(toParts(a), toParts(b));
    const differentValuesPart = find(parts, part => part[0] !== part[1]);
    return differentValuesPart && differentValuesPart[0] > differentValuesPart[1];
};