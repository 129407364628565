import React, {Component} from 'react';
import PropTypes from 'prop-types';

class LabelFilterComponent extends Component {

    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
    }

    filterChange = e => {
        const value = e.target.value ? e.target.value : null;

        this.onFilterChange(value)
    };

    onFilterChange(value) {
        this.props.onFilterChanged({
            id: 'label',
            values: [{key: 'label', value: value}],
            emptyValue: null
        });
    };

    componentDidUpdate = () => {
        if (this.inputRef.current) {
            this.inputRef.current.focus();
        }
    };    

    render() {
        const label = this.props.filter.values.label;

        return (
            <div className="pos--rel">
                <input
                    placeholder="Label"
                    className="input input--number input--med"
                    value={label === null ? '' : label}
                    id="label"
                    onChange={this.filterChange}
                    ref={this.inputRef}
                    autoFocus={true}
                />
            </div>
        );
    }
}

LabelFilterComponent.propTypes = {
    filter: PropTypes.object.isRequired,
    onFilterChanged: PropTypes.func.isRequired
};

export default LabelFilterComponent;