export const categoryOptions = [
    { value: 'None', label: 'Select...', exact: true },
    { value: 'Reincorporation_BankInfo', label: 'Reincorporation Bank Info', exact: true },
    { value: 'BusinessInfoUpdate', label: 'Business Info Update', exact: true },
    { value: 'AddRemoveEntitlements', label: 'Add/Remove Entitlements', exact: true },
    { value: 'AddRemoveFees', label: 'Add/Remove Fees', exact: true },
    { value: 'OnlineAccess', label: 'Online Access', exact: true, onlyForFdProcessor: true }
];

export const typeOptions = [
    { value: 'None', label: 'Select...', category: 'None' },
    { value: 'BusinessDBA_Info', label: 'Business DBA Info', category: 'BusinessInfoUpdate' },
    { value: 'Reincorporation', label: 'Reincorporation', category: 'Reincorporation_BankInfo', displaySubmitOption: true },
    { value: 'BankInfoUpdate', label: 'Bank Info Update', category: 'Reincorporation_BankInfo', displaySubmitOption: true },
    { value: 'Reincorporation_BankInfo', label: 'Reincorporation + Bank Info Update', category: 'Reincorporation_BankInfo', displaySubmitOption: true },
    { value: 'AmexOptBlue_TieredPricing', label: 'Amex Opt Blue Tiered Pricing', category: 'AddRemoveEntitlements', displayFeeClass: true, displaySubmitOption: true  },
    { value: 'AmexOptBlue_PassThroughPricing', label: 'Amex Opt Blue Pass Through Pricing', category: 'AddRemoveEntitlements', displayFeeClass: true, displaySubmitOption: true  },
    { value: 'AmexOptBlue_ErrPricing', label: 'Amex Opt Blue Err Pricing', category: 'AddRemoveEntitlements', displayFeeClass: true, displaySubmitOption: true  },
    { value: 'AmexOptBlue_FlatRatePricing', label: 'Amex Opt Blue Flat Rate Pricing', category: 'AddRemoveEntitlements', displayFeeClass: true, displaySubmitOption: true  },
    { value: 'AmexReversePIP', label: 'Amex Reverse PIP', category: 'AddRemoveEntitlements'  },
    { value: 'PINDebit', label: 'PIN Debit', category: 'AddRemoveEntitlements', displaySubmitOption: true },
    { value: 'EBT', label: 'EBT', category: 'AddRemoveEntitlements', displaySubmitOption: true  },
    { value: 'AmexESA', label: 'Amex ESA', category: 'AddRemoveEntitlements', displaySubmitOption: true  },
    { value: 'WirelessFeeFirstData', label: 'Wireless Fee First Data', category: 'AddRemoveFees', displaySubmitOption: true, forElavonProcessor: false },
    { value: 'WirelessFeeElavon', label: 'Wireless Fee Elavon', category: 'AddRemoveFees', displaySubmitOption: true, forElavonProcessor: true },
    { value: 'EIDSEnrollment', label: 'EIDS Enrollment', category: 'OnlineAccess'}
];

export const submitOptions = [
    { value: 'Signature', label: 'Upon submitting this request, the form will be emailed to the merchant for signature.' },
    { value: 'EmailUser', label: 'Upon submitting this request, the form will be emailed to the user for signature. The merchant will not be emailed for signature.' },
    { value: 'DocumentUploaded', label: 'Uploaded document has merchant\'s signature. A ticket will be created' },
];

export const contactMethodOptions = [
    { value: '', label: 'Select...' },
    { value: '1', label: 'Email' },
    { value: '2', label: 'Phone' },
];

const reincorporationFields= [
    {
        label: 'New IRS Name',
        key: 'IRS_Name',
    },
    {
        label: 'New Taxpayer ID',
        key: 'TaxpayerID',
    },
    {
        label: 'New TIN Type',
        key: 'TinType',
        options: [
            { label: 'Select...', value: '' },
            { label: 'SSN', value: 'SSN' },
            { label: 'EIN', value: 'EIN' },
        ],
    },
    {
        label: 'Tax Effective Year',
        key: 'TaxEffectiveYear',
    },
    {
        label: 'Change Effective Date',
        key: 'ChangeEffectiveDate',
    },
    {
        label: 'New Legal Business Name',
        key: 'LegalBusinessName_New',
    },
    {
        label: 'Tax Document',
        key: 'TaxDocument',
        type: 'file',
    },
]

const bankInfoFields = [
    {
        label: 'Bank Name',
        key: 'BankName',
    },
    {
        label: 'New ABA',
        key: 'ABA_New',
    },
    {
        label: 'New DDA',
        key: 'DDA_New',
    },
    {
        label: 'Voided Check',
        key: 'VoidedCheck',
        type: 'file',
    },
]

export const bankingServicesFields = {
    Reincorporation: {
        title: 'Reincorporation Info',
        fields: reincorporationFields,
    },
    BusinessDBA_Info: {
        title: 'Business Info',
        fields: [
            {
                label: 'Descriptor - Retail',
                key: 'Descriptor_Retail_New',
            },
            {
                label: 'Descriptor - Bankcard',
                key: 'Descriptor_Bankcard_New',
            },
            {
                label: 'Email Address',
                key: 'EmailAddress_New',
            },
            {
                label: 'Website URL',
                key: 'WebsiteUrl_New',
            },
            {
                label: 'Customer Service Phone',
                key: 'CustomerServicePhone_New',
            },
            {
                label: 'DBA Outlet Name',
                key: 'DBAOutletName_New',
            },
            {
                label: 'DBA Street Address',
                key: 'DBAStreetAddress_New',
            },
            {
                label: 'DBA City',
                key: 'DBACity_New',
            },
            {
                label: 'DBA State',
                key: 'DBAState_New',
            },
            {
                label: 'DBA Zip',
                key: 'DBAZip_New',
            },
            {
                label: 'DBA Phone Number',
                key: 'DBAPhoneNumber_New',
            },
            {
                label: 'DBA Fax Number',
                key: 'DBAFaxNumber_New',
            },
            {
                label: 'Corporate Street',
                key: 'CorporateStreet_New',
            },
            {
                label: 'Corporate City',
                key: 'CorporateCity_New',
            },
            {
                label: 'Corporate State',
                key: 'CorporateState_New',
            },
            {
                label: 'Corporate Zip',
                key: 'CorporateZip_New',
            },
        ],
    },
    BankInfoUpdate: {
        title: 'Bank Info',
        fields: bankInfoFields,
    },
    Reincorporation_BankInfo: {
        title: 'Reincorporation + Bank Info',
        fields: [...reincorporationFields, ...bankInfoFields]
    },
    AmexOptBlue_TieredPricing: {
        title: 'AMEX Opt Blue Tiered Pricing',
        section: {
            columns: ['Description', 'Discount Rate', 'Other Volume %'],
            rows: [
                {
                    'Description': 'Amex Opt Blue Qualified Credit',
                    'Discount Rate': 'Tiered_Qual_DiscountRate',
                    'Other Volume %': 'Tiered_Qual_OtherVolumePerc',
                },
                {
                    'Description': 'Amex Opt Blue Mid-Qualified Credit',
                    'Discount Rate': 'Tiered_MidQual_DiscountRate',
                    'Other Volume %': 'Tiered_MidQual_OtherVolumePerc',
                },
                {
                    'Description': 'Amex Opt Blue Non-Qualified Credit',
                    'Discount Rate': 'Tiered_NonQual_DiscountRate',
                    'Other Volume %': 'Tiered_NonQual_OtherVolumePerc',
                },
            ],
        },
        fields: [
            {
                label: 'TDG (Credit Only)',
                key: 'OptBlue_TDG',
            }
        ],
    },
    AmexOptBlue_PassThroughPricing: {
        title: 'AMEX Opt Blue Pass Through Pricing',
        section: {
            columns: ['Description', 'Discount Rate'],
            rows: [
                {
                    'Description': 'Amex Opt Blue Credit',
                    'Discount Rate': 'PassThru_DiscountRate',
                },
            ],
        },
    },
    AmexOptBlue_ErrPricing: {
        title: 'AMEX Opt Blue Err Pricing',
        section: {
            columns: ['Description', 'Discount Rate', 'Err %'],
            rows: [
                {
                    'Description': 'Amex Opt Blue Credit',
                    'Discount Rate': 'ERR_DiscountRate',
                    'Err %': 'ERR_Perc',
                },
            ],
        },
    },
    AmexOptBlue_FlatRatePricing: {
        title: 'AMEX Opt Blue Flat Rate Pricing',
        section: {
            columns: ['Description', 'Discount Rate'],
            rows: [
                {
                    'Description': 'Amex Opt Blue Credit',
                    'Discount Rate': 'FlatRate_DiscountRate',
                },
            ],
        },
    },
    PINDebit: {
        title: 'PIN Debit',
        fields: [
            {
                label: 'Transaction Fee',
                key: 'PINDebit_TransFee',
            },
            {
                label: 'Percent Markup',
                key: 'PINDebit_PercentMarkup',

            },
        ],
    },
    EBT: {
        title: 'EBT',
        fields: [
            {
                label: 'Transaction Fee',
                key: 'EBT_TransFee',
            },
            {
                label: 'FNS#',
                key: 'EBT_FNS',
            },
        ],
    },
    AmexESA: {
        title: 'Amex ESA',
        fields: [
            {
                label: 'Amex SE #',
                key: 'Amex_SE',
            },
            {
                label: 'Other Item Rate',
                key: 'Amex_OtherRate',
            },
        ],
    },
    WirelessFeeElavon: {
        title: 'Wireless Fee Elavon',
        fields: [
            {
                label: 'Number of Terminals',
                key: 'WirelessTerminalCount',
            },
            {
                label: 'Wireless Fee',
                key: 'WirelessFee',
            },
        ],
    },
    WirelessFeeFirstData: {
        title: 'Wireless Fee First Data',
        fields: [
            {
                label: 'UDG Grid',
                key: 'WirelessFee_UDGGrid',
            },
        ],
    },
    EIDSEnrollment: {
        title: 'Enroll With EIDS (Electronic Integrated Disputes SystemSM)',
        fields: [
            {
                label: 'Enter Price To Bill Merchant (Cost is $3.95):',
                key: 'EIDS_MerchantPrice',
                defaultValue: '3.95',
                additionalInfo: 'Please note that if you would like to bill more than $5.95 you will need to obtain a signature from the merchant authorizing the charge.  Banking Services will follow up with you for a signed form.',
                wrapperClassName: 'col col-sml-12 col-lrg-12 col-xlrg-6 spc--bottom--med',
            },
            {
                label: 'Enter the email the login credentials will be sent to:',
                key: 'EIDS_Email',
                additionalInfo: 'It usually takes up to 2-3 days to get an approval and before the dispute reporting kicks in.',
                wrapperClassName: 'col col-sml-12 col-lrg-12 col-xlrg-6 spc--bottom--med',
            }
        ]
    }
};
