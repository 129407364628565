import React, { forwardRef } from 'react';

function withContext(WrappedComponent, Context, contextKey) {
	return forwardRef((props, ref) => (
        <Context.Consumer>
            {value => {
                const newProps = {
                    ...props,
                    [contextKey]: value
                };
                return (
                    <WrappedComponent {...newProps} ref={ref} />
                );
            }}
        </Context.Consumer>
    ))
}
export default withContext;
