import { includes, join, filter } from 'lodash';
import { sendEmail, sendFraudEmail } from '../../services/messagingApiService';

const loginAttemptsThreshold = 3;
const loginAttemptsPersistenceKey = 'fqa_4pkh';

let loginAttempts = JSON.parse(localStorage.getItem(loginAttemptsPersistenceKey) || '[]');
let knownLoginAttempts = [];
let successfulLoginAttempts = [];

function exceedsThreshold() {
	return loginAttempts.length % loginAttemptsThreshold === 0 && loginAttempts.length !== 0;
}

export function logLoginAttempt(username) {
	if (!includes(knownLoginAttempts, username)) knownLoginAttempts.push(username);
	const hashedUsername = createNonSecureHash(username);
	if (includes(loginAttempts, hashedUsername)) return;
	loginAttempts.push(hashedUsername);
	localStorage.setItem(loginAttemptsPersistenceKey, JSON.stringify(loginAttempts));

	// Check for the number of failed attempts and send email if it exceeds the threshold
	if (exceedsThreshold()) {
		sendFailedAttemptEmail(username, hashedUsername);
	}
}

export async function logSuccessfulLogin(username, cognitoToken) {
	const hashedUsername = createNonSecureHash(username);
	successfulLoginAttempts.push(hashedUsername);
	loginAttempts = filter(loginAttempts, attempt => hashedUsername !== attempt);
	if (exceedsThreshold()) {
		sendFailedAttemptEmail(username, hashedUsername, cognitoToken);
	}
    loginAttempts = [];
	knownLoginAttempts = [];
	successfulLoginAttempts = [];
	localStorage.removeItem(loginAttemptsPersistenceKey);
}

async function sendFailedAttemptEmail(username, hashedUsername, cognitoToken) {
	const isSuccessfulLogin = successfulLoginAttempts.includes(hashedUsername);
	const sanitizedUsername = isSuccessfulLogin ? username : '';

	const template = {
		Template: {
			FailedAttempts: loginAttempts.length,
			KnownAttempts: join(knownLoginAttempts, ', '),
			Username: sanitizedUsername,
		},
	};

	if (knownLoginAttempts.length > 0) {
		template.templateName  = sanitizedUsername ? 'LoginAttemptsSuccessWithKnown' : 'LoginAttemptsFailedWithKnown';
	} else { //this condition is never met; Are LoginAttemptsSuccess and LoginAttemptsFailed needed?
		template.templateName   = sanitizedUsername ? 'LoginAttemptsSuccess' : 'LoginAttemptsFailed';
	}

    try {
        sanitizedUsername ? await sendEmail(template, cognitoToken) : await sendFraudEmail(template);
    	} catch (error) {
    }
}
// This does not need to be cryptographically secure.
// Just a short and simple implementation of a hash function.
function createNonSecureHash(str) {
	let hash = 0;
	for (let i = 0, len = str.length; i < len; i++) {
		let chr = str.charCodeAt(i);
		hash = (hash << 5) - hash + chr;
		hash |= 0;
	}
	return hash.toString(16);
}
