import React from 'react';
import PropTypes from 'prop-types';
import { BaseStatusComponent } from '../../../common/formatters';

class TicketStatusComponent extends BaseStatusComponent {
    constructor(props) {
        super(props);
        
        this.statusClasses = {
            'new': this.styleActive,

            'waiting for sub ticket': this.stylePending,
            'waiting for agent': this.stylePending,
            'needs follow up': this.stylePending,

            'closed - resolved': this.styleInactive,
            'closed - unresolved': this.styleInactive,
            'deleted [closed]': this.styleInactive,
        }
    }

    render = () => {
        const { value, dependentValues } = this.props;
        return value ? (
            <span ref={el => this.info = el} className={this.getStyle()} 
                title={dependentValues && dependentValues.statusTitle ? dependentValues.statusTitle : value}>
                {value}
            </span>
        ) : null;
    }
}

export default TicketStatusComponent;

TicketStatusComponent.propTypes = {
    value: PropTypes.string,
    dependentValues: PropTypes.shape({
        statusTitle: PropTypes.string,
    }),
};