import React from 'react';
import { UserAccountPanel } from './../../common/components/user-account-panel';

class EAppCompleteComponent extends React.Component {
    constructor(props) {
        super(props);        
    }

    render() {
        return (
            <div id="main-div" className="l--content">
                <div className="note note--success">
                    Thank you - your application has been submitted successfully.
                </div>                
            </div>
        );
    }
}

export default EAppCompleteComponent;