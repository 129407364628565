import React from "react";
import PropTypes from "prop-types";
import Dropzone from 'react-dropzone';
import { startCase, isEmpty, map, memoize } from 'lodash';
import { fileTypes, getFileTypeExtensions } from "./fileTypeConstants";

const fileTags = [
    'VoidedCheck',
    'MerchantStatement',
    'ProofOfAddress',
    'TaxExemption',
    'CompanyLogo',
    'Other'];

class FileDropzoneComponent extends React.Component {
    handleDrop = (tag) => (acceptedFiles) => {
        this.props.onDrop(tag, acceptedFiles);
    }

    handleRemove = (fileType, indx) => () => {
        this.props.onRemoveFile(fileType, indx);
    }

    handleUpdateDescription = (fileType, indx) => (e) => {
        this.props.onUpdateDescription(fileType, indx, e.target.value);
    }

    handleUpdateTag = (fileType, indx) => (e) => {
        this.props.onUpdateTag(fileType, indx, e.target.value);
    }

    fileTypeExtensions = memoize(getFileTypeExtensions);

    render() {
        const { multiple, fileList, descriptionPlaceholder, showDescription, showTagDropdown, tag, validateFileSize } = this.props;
        const maxSize = validateFileSize ? 5242880 : Infinity; // 5 MB // 10 = 10485760
        let fileTagPrefix = tag ? tag + '_' : '';
        let displayFileList = fileList;
        // when showing tag dropdown, that means we are displaying all files
        if (fileList && tag && !showTagDropdown) {
            displayFileList = fileList[tag];
        }
        return (
            <React.Fragment>
                <Dropzone
                    accept={this.fileTypeExtensions(this.props.allowedFileTypes)}
                    multiple={multiple}
                    onDrop={this.handleDrop(tag)}
                    minSize={0}
                    maxSize={maxSize}>
                    {({ getRootProps, getInputProps, isDragActive, isDragReject, rejectedFiles }) => {
                        const isFileTooLarge = validateFileSize && rejectedFiles && rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;
                        return (
                            <React.Fragment>
                                <div className="file-upload__container file-upload__container--sml">
                                    <div {...getRootProps()} className="fullwidth">
                                        <input {...getInputProps()} />

                                        {!isDragActive && (
                                            <div className="file-upload__btn horizontal">
                                                <i className='icon icon--xsml icon--upload spc--right--sml'/>
                                                <p>Choose a file or drag it here</p>
                                            </div>
                                        )}
                                        {isDragActive && !isDragReject && (
                                            <div className="file-upload__btn file-upload__drop">
                                                <p>Drop file here</p>
                                            </div>
                                        )}
                                        {isDragReject && (
                                            <div className="file-upload__btn file-upload__drop--invalid">
                                                <p>File type not accepted, sorry!</p>
                                            </div>
                                        )}
                                        {(!isEmpty(rejectedFiles) && !isFileTooLarge) && (
                                            <div className="spc--top--tny">
                                                <p className="type--color--warning type--wgt--medium">File type not accepted, sorry!</p>
                                            </div>
                                        )}
                                        {isFileTooLarge && ( <div className="file-upload__btn file-upload__drop--invalid">
                                                <p>File is too large.</p>
                                            </div>
                                        )}
                                    </div>
                                </div>

                            </React.Fragment>
                        )
                    }}
                </Dropzone>
                {
                    displayFileList && displayFileList.length > 0 ? (
                        <ul className="file-upload__list">
                            {displayFileList.map((fileEntry, i) => {
                                // can either be just the actual file object or an object that contains the file + description
                                let fileName = fileEntry.file ? fileEntry.file.name : fileEntry.name;
                                return (
                                    <li key={i} className="file-upload__list__item">

                                        <label className="file-upload__list__name spc--right--auto">{fileName}</label>

                                        {showTagDropdown && (
                                            <div>
                                                <select className="input input--med input--select" name={fileTagPrefix + "fileTag"}
                                                    id={'fileTag' + i}
                                                    value={fileEntry.fileTag || tag} onChange={this.handleUpdateTag(tag, i)} >
                                                    <option value="">Select...</option>
                                                    {fileTags.map((tag) => <option value={tag}>{startCase(tag)}</option>)}
                                                </select>
                                            </div>
                                        )}

                                        {showDescription && (
                                            <div>
                                                <input type="text" className="input input--med spc--left--xsml" name={fileTagPrefix + "fileDescription"} id={fileTagPrefix + 'fileDescription_' + i}
                                                    value={fileEntry.fileDescription} onChange={this.handleUpdateDescription(fileEntry.fileTag || tag, i)} placeholder={descriptionPlaceholder} />
                                            </div>
                                        )}

                                        <div className="file-upload__list__button spc--left--xsml">
                                            <a href='javascript:void(0);' onClick={this.handleRemove(fileEntry.fileTag || tag, i)}>
                                                <i className="icon icon--tiny icon--wastebasket align--v--sub"></i>
                                            </a>
                                        </div>

                                    </li>
                                )
                            })}
                        </ul>
                    ) : null
                }
            </React.Fragment>
        );
    }
}

FileDropzoneComponent.propTypes = {
    onRemoveFile: PropTypes.func,
    onDrop: PropTypes.func.isRequired,
    showDescription: PropTypes.bool,
    showTagDropdown: PropTypes.bool,
    onUpdateDescription: PropTypes.func,
    multiple: PropTypes.bool,
    fileList: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array
    ]),
    tag: PropTypes.string,
    descriptionPlaceholder: PropTypes.string,
    validateFileSize: PropTypes.bool,
    allowedFileTypes: PropTypes.array
};

FileDropzoneComponent.defaultProps = {
    multiple: false,
    showDescription: false,
    tag: '',
    descriptionPlaceholder: 'File Description',
    fileTagDefault: 'MerchantStatement',
    validateFileSize: true,
    allowedFileTypes: map(fileTypes, (val, key) => val)
}

export default FileDropzoneComponent;