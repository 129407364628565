export default {
	userPoolId: 'us-west-2_9KLSf4zTa',
	userPoolWebClientId: '7eis7jkt2pak13aqst9igllik7',
	oauth: {
		domain: 'signin.cardknox.com',
		clientID: '7eis7jkt2pak13aqst9igllik7',
		redirectSignIn: 'https://partner.cardknox.com/saml-login',
		redirectUri: 'https://partner.cardknox.com/saml-login',
		audience: 'urn:amazon:cognito:sp:us-west-2_9KLSf4zTa',
		responseType: 'token', // id_token', // for now we only support implicit grant flow
		scope: ['email', 'openid', 'profile', 'phone', 'aws.cognito.signin.user.admin'], // the scope used by your app
		redirectSignOut: 'https://partner.cardknox.com/',
	},

}