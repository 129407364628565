import {map, range} from 'lodash';
export const validInlineFilterValues = {
	status: [
		// ticket statuses
		{ key: 'New', label: 'New' },
		{ key: 'Closed - Resolved', label: 'Closed - Resolved' },
		{ key: 'Closed - Unresolved', label: 'Closed - Unresolved' },
		{ key: 'Deleted [Closed]', label: 'Deleted [Closed]' },
		{ key: 'Waiting For Agent', label: 'Waiting For Agent' },
		{ key: 'Waiting For Sub Ticket', label: 'Waiting For Sub Ticket' },
		{ key: 'Needs Follow Up', label: 'Needs Follow Up' },
	],
	merchantStatus: [
		{ key: 'Active', label: 'Active' },
		{ key: 'Inactive', label: 'Inactive' },
		{ key: 'Pending', label: 'Pending' },
		{ key: 'Incomplete', label: 'Incomplete' },
		{ key: 'SeasonalHold', label: 'Seasonal Hold' },
		{ key: 'InReview', label: 'In Review' },
		{ key: 'PendUnderwriting', label: 'Pend Underwriting' },
		{ key: 'ConditionallyApproved', label: 'Conditionally Approved' },
	],
	processorName: [
		{ key: 'CK-Go', label: 'CK-Go' },
		{ key: 'Cardknox Go', label: 'Cardknox Go' },
		{ key: 'ACHQ', label: 'ACHQ' },
		{ key: 'First Data', label: 'First Data' },
		{ key: 'FD Canada', label: 'FD Canada' },
		{ key: 'Elavon Canada', label: 'Elavon Canada' },
		{ key: 'Elavon Canada USD', label: 'Elavon Canada USD' },
		{ key: 'ProfitStars Ach', label: 'ProfitStars Ach' },
		{ key: 'FD North FACS', label: 'FD North FACS' },
		{ key: 'Elavon', label: 'Elavon' },
		{ key: 'Vantiv', label: 'Vantiv' },
		{ key: 'Developer', label: 'Developer' },
	],
	leadStatus: [
		{ key: 'New', label: 'New' },
		{ key: 'Qualified', label: 'Qualified' },
		{ key: 'Contacted', label: 'Contacted' },
		{ key: 'Pricing Analysis', label: 'Pricing Analysis' },
		{ key: 'Waiting For Info', label: 'Waiting For Info' },
		{ key: 'Future Reference', label: 'Follow Up Later' },
		{ key: 'Dead', label: 'Dead' },
		{ key: 'eApp Started', label: 'eApp Started' },
		{ key: 'eApp Submitted', label: 'eApp Submitted' },
		{ key: 'Pending FD Sig', label: 'Pending FD Sig' },
		{ key: 'Pending Elavon Sig', label: 'Pending Elavon Sig' },
		{ key: 'Pend Underwriting', label: 'Pend Underwriting' },
		{ key: 'Pend Bank Approval', label: 'Pend Bank Approval' },
		//{ key: 'Unknown', label: 'Unknown' }
	],
	reviewStatus: [
		{ key: 'Complete', label: 'Complete' },
		{ key: 'Waiting', label: 'Waiting' },
		{ key: 'NotSubmitted', label: 'Not Submitted' },
		{ key: 'Unknown', label: 'Unknown' },
		{ key: 'Pending', label: 'Pending' },
		{ key: 'NoReview', label: 'NoReview' },
		{ key: 'Incomplete', label: 'Incomplete' },
	],
	statusDisplayName: [
		{ key: 'eApp Started', label: 'eApp Started' },
		{ key: 'eApp Submitted', label: 'eApp Submitted' },
		{ key: 'Pending FD Sig', label: 'Pending FD Sig' },
		{ key: 'Pending Elavon Sig', label: 'Pending Elavon Sig' },
		{ key: 'Pend Bank Approval', label: 'Pend Bank Approval' },
		{ key: 'Pend Underwriting', label: 'Pend Underwriting' },
	],
	isPciCompliant: [
		{ key: 'true', label: 'Yes' },
		{ key: 'false', label: 'No' },
	],
	active: [
		{ key: 'true', label: 'Active' },
		{ key: 'false', label: 'Inactive' },
	],
	isMPASigned: [
		{ key: 'true', label: 'Yes' },
		{ key: 'false', label: 'No' },
	],
	priority: [
		{ key: 'Low', label: 'Low' },
		{ key: 'Medium', label: 'Medium' },
		{ key: 'High', label: 'High' },
	],
	month: [
		{ key: 'January', label: 'January' },
		{ key: 'February', label: 'February' },
		{ key: 'March', label: 'March' },
		{ key: 'April', label: 'April' },
		{ key: 'May', label: 'May' },
		{ key: 'June', label: 'June' },
		{ key: 'July', label: 'July' },
		{ key: 'August', label: 'August' },
		{ key: 'September', label: 'September' },
		{ key: 'October', label: 'October' },
		{ key: 'November', label: 'November' },
		{ key: 'December', label: 'December' },
	],
	year:[
		...map(range(1950, new Date().getFullYear() + 1), year => ({ key: year.toString(), label: year.toString() }))
	]
};
