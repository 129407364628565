import { ascii, email } from './validators'

export const ShareMpaFormTemplate = {
    merchantEmail: {
        type: String,
        use: { ascii, email: email(false) },
        message: {
            ascii: '[**Merchant Email**](javascript:void) contains invalid characters',
            email: '[**Merchant Email**](javascript:void) is invalid',
        },
    },
    ccEmail: {
        type: String,
        use: { ascii, email: email(true) },
        message: {
            ascii: '[**CC**](javascript:void) contains invalid characters',
            email: '[**CC**](javascript:void) is invalid',
        },
    },
}
