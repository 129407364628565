import {has} from 'lodash';

class CardTypeImage {
    get cardNames() {
        return {
            'Visa': 'visa',
            'Amex': 'amex',
            'MasterCard': 'mastercard',
            'Diners': 'diners',
            'Discover': 'discover',
            'EBT': 'ebt',
            'GiftCard': 'giftcard',
            'Gift': 'gift',
            'JCB': 'jcb'
        }
    };

    getPath(cardType) {
        const cardPath = '/static/media/credit-cards/';
        if (has(this.cardNames, cardType)) {
            return cardPath + this.cardNames[cardType] + '.svg';
        } else {
            return cardPath + 'unknown.svg';
        }
    }
}

const cardTypeImagePath = new CardTypeImage();

export default cardTypeImagePath;