import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {times, cloneDeep, map} from 'lodash';

class CustomFieldsFilterComponent extends Component {
    constructor(props) {
        super(props);

        this.inputRef = React.createRef();
    }

    componentDidUpdate() {
        if (this.inputRef.current) {
            this.inputRef.current.focus();
        }
    }

    onFilterChange = e => {
        const {name, value} = e.target;
        const {values} = this.props.filter;

        let valuesArr = map(values, (v,k) => {
            return {key: k, value: v};
        });

        const objIndex = valuesArr.findIndex(o => o.key === name);

        valuesArr[objIndex].value = value;

        this.props.onFilterChanged({
            id: 'custom',
            values: valuesArr,
            emptyValue: null
        });
    };

    renderInputField = (idx) => {
        idx = idx.toString().padStart(2, '0');
        return (
            <div key={idx} className="spc--bottom--sml">
                <div>
                    <label htmlFor={idx}>{"Custom"+ idx}</label>
                </div>
                <input 
                    type="text" 
                    className="input input--med" 
                    id={idx} name={"custom" + idx} 
                    placeholder={"Custom" + idx}
                    value={this.props.filter.values["custom" + idx] == null ? '' : this.props.filter.values["custom" + idx]} 
                    onChange={this.onFilterChange}
                    ref={idx == 1 ? this.inputRef : null}
                    autoFocus={idx == 1 ? true :  false}
                />
            </div>
        );
    };


    render() {
        const {numberOfCustomFields} = this.props;

        return (
            <div>
                {times(numberOfCustomFields, (idx) => {
                    return this.renderInputField(idx + 1);
                })}
            </div>
        );
    }
}

CustomFieldsFilterComponent.propTypes = {
    numberOfCustomFields: (props, propName, componentName) => {
        if (props[propName] < 1 || props[propName] > 21) {
            return new Error("There can be between 1 and 20 custom fields");
        }
    },
    filter: PropTypes.object.isRequired,
    onFilterChanged: PropTypes.func.isRequired
};

export default CustomFieldsFilterComponent;