//import config from 'config';
import { authHeader } from '../helpers/auth-header';
import principalService from '../services/principalService';
import { _sharedFunctions } from './_sharedFunctions';
import moment from 'moment';
import { each, get, map, replace, isEmpty, transform, trim, includes, startCase, startsWith } from 'lodash';
import { PDFDocument, PDFName, PDFArray, PDFBool, PDFHexString, PDFNumber } from 'pdf-lib';
import { defaultInvalidDates } from '../common/utilities/defaultInvalidDates';
import AesJs from 'aes-js';
import { getApiUrl } from '../common/utilities/apiConfiguration';
import emailService from './emailService';
import { emailTemplates, removeProperties } from '../common/utilities';

const handleResponse = _sharedFunctions.handleResponse;
const appRequestHeaders = _sharedFunctions.appRequestHeaders;

export const _eappFunctions = {
    /* eApp */
    getEApps,
    getEApp,
    getEappStatus,
    getEAppForMerchant,
    getGoPlus,
    updateSignatureStatus,
    completeSignature,
    downloadPrefilledMPA,
    prefillDocumentFields,
    downloadPrefilledFile,
    shareMPAForm,
    sendMerchantAchqTerms,
    getMerchantFile,
    deleteMerchantFile,
    getMerchantSetupForm,
    getProcessorListByAppId,
    getProcessorListByCountry,
    saveEApp,
    saveAchEApp,
    getAssociatedPartners,
    submitGoPlus,
    getGoPlusTiers,
    getAllowAchqApplication,
    saveGoPlus,
    saveMerchantSetupForm,
    saveMerchantFiles,
    getMerchantEquipment,
    getInvoices,
    getInvoiceFieldsData,
    saveMerchantEquipment,
    saveEquipmentTemplate,
    saveMerchantVar,
    saveMerchantAchPlan,
    getAchPlan,
    getMerchantPlan,
    getEquipmentList,
    getEquipmentListByProcessor,
    getVarList,
    getHardwareList,
    getGatewayList,
    getAddonList,
    validateSerialNumber,
    cancelEquipment,
    getShippingRates,
    submitApplication,
    getMerchantFiservApplicationFile,
    prepareMerchantFileForTransfer,
    getPlaidLinkToken,
    exchangePlaidAccessToken,
    cloneEApp,
    getGoPlusUnderwritingLevels,
};

function getEApps(EAppsFilter, rowsPerPage, activePage) {   
    const pageNumber = activePage || 1;
    const pageSize = rowsPerPage || 20;
    const requestBody = JSON.stringify({
        MerchantFilter: EAppsFilter,
        PageSize: pageSize,
        PageNumber: pageNumber
    });
    
    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/agent/GetEApps' }).then(handleResponse);
}

function getEAppForMerchant(token) {
    const requestBody = JSON.stringify(0);

    return _sharedFunctions.callApi({ headerMethod: function () { return _sharedFunctions.appRequestHeadersForMerchant(token); }, body: requestBody, action: '/merchant/GetEAppForMerchant' })
        .then((resp) => {
            if (resp.status === 401) {
                throw resp.status;
            }
            return handleResponse(resp, true);
        })
        .then(resp => {
            if (token) {
                principalService.set({ agentId: 0, name: '', emailAddress: '', token, roles: ['Merchant'] });
            }
            return resp;
        });
}

function updateSignatureStatus(appId, requestId, refId, status, signatureFile) {
    const requestBody = JSON.stringify({ appId: appId, id: requestId, referenceId: refId, status: status, signatureFile });

    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/merchant/UpdateSignatureRequest' }).then(handleResponse);
}

function completeSignature(appId, referenceId, signature, expires, requestId) {
    const requestBody = JSON.stringify({ appId, referenceId, signature, expires, requestId });
    
    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/merchant/CompleteSignature' }).then(handleResponse);
}

function getEappStatus(appId) {
    const requestBody = JSON.stringify(appId);

    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/merchant/GetEappCompletionStatus' }).then(handleResponse);
}

function getEApp(appId) {
    const requestBody = JSON.stringify(appId);

    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/merchant/GetEApp' }).then(handleResponse);
}


function getGoPlus(appId) {
    const requestBody = JSON.stringify(appId);

    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/merchant/GoPlusEApp' }).then(handleResponse);
}

function getMerchantFiservApplicationFile(token) {
    return _sharedFunctions.appRequestHeadersForMerchant(token, true) // true = forDownload 
        .then((header) => {
            const requestOptions = {
                method: 'POST',
                headers: header
            };
            return _sharedFunctions.downloadFile(getApiUrl() + '/merchant/DownloadFiservApplication', requestOptions, false, true, true);
        });
}

function getMerchantFile(appId, fileId, fileName, returnBlob) {
    const defaultError = { errorMessage: 'Error downloading file' };
    const requestBody = JSON.stringify({ parentId: appId, fileId: fileId, fileName: fileName })
    let encryptionKey;
    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/merchant/GetFileDownloadData' }).then(handleResponse)
        .then(response => {
            ({ encryptionKey } = response.downloadData);
            return fetch(response.downloadData.presignedUrl, {
                method: 'GET'
            })
        })
        .then(response => {
            if (response.status === 200) {
                const body = response.blob();
                if (!body) return defaultError;
                return body;
            }
            else {
                return defaultError;
            }
        })
        .then(blob => {
            return decryptFile(blob, encryptionKey)                
        })
        .then(decryptedBlob => {
            console.log('return blob', returnBlob);
            console.log(decryptedBlob)
            if (returnBlob) return decryptedBlob;
            const url = URL.createObjectURL(decryptedBlob);
            console.log(url)
            return url
        });
    
}

function deleteMerchantFile(parentId, fileId) {
    const requestBody = JSON.stringify({ parentId, fileId });

    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/merchant/DeleteFile' }).then(handleResponse);
}

function shareMPAForm(appInfo, ccEmail, shouldSendAgentCopy, includedContracts, requiredFields, getLink = false) {

    const data = {
        appId: appInfo.appId,
        sendToAddress: appInfo.merchantEmail,
        signerName: appInfo.merchantName,
        copyToAddress: ccEmail,
        shouldSendCopyToAgent: shouldSendAgentCopy,
        fieldsRequiredForMerchant: requiredFields,
        dba: appInfo.merchantDba
    };

    if (!isEmpty(includedContracts)) {
        data.includedContracts = includedContracts;
    }

    const requestBody = JSON.stringify(data);
    const action = getLink ? '/merchant/GetMerchantMPALink' : '/merchant/SendMerchantMPALink';
    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: action }).then(handleResponse);
}

function saveEApp(mpa, signatureFile) {
    const withInvalidDates = defaultInvalidDates(removeProperties(mpa, ['refNum', 'status']));
    withInvalidDates.signatureFile = signatureFile;
    const requestBody = JSON.stringify(withInvalidDates);
    const action = mpa.isCanadian ? '/merchant/SaveCaEApp' : '/merchant/SaveUsEApp';
    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: action }).then(handleResponse);
}

function saveAchEApp(mpa, signatureFile) {
    const withInvalidDates = defaultInvalidDates(removeProperties(mpa, ['refNum', 'status', 'telemarketerList']));
    withInvalidDates.signatureFile = signatureFile;
    const requestBody = JSON.stringify(withInvalidDates);
    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/merchant/SaveAchEApp' }).then(handleResponse);
}

function getAssociatedPartners() {
    const requestBody = '';
    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/agent/GetGoPlusPartners' }).then(handleResponse);
}

function submitGoPlus(data) {
    const requestBody = JSON.stringify(data);

    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/merchant/SubmitGoPlus' }).then(handleResponse);
}

function getGoPlusTiers(appId, achSupportingOnly = false, checkExistenceOnly = false) {
    const requestBody = '';    
    let action = `/agent/GetGoPlusTiers/${appId}/${achSupportingOnly}/${checkExistenceOnly}`;

    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: action })
        .then(handleResponse)
        .catch(() => { //this catch can be removed when the client and api are both published and stable with GetGoPlusTiersForTelemarketer
            return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, action: '/agent/GetGoPlusTiers' })
                .then(handleResponse)
        });
}

function getAllowAchqApplication(){
    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, action: '/agent/AllowAchqStandaloneApplication' }).then(handleResponse);
}

function saveGoPlus(data) {
    const requestBody = JSON.stringify(data);

    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/merchant/SaveGoPlusEApp' }).then(handleResponse);
}

function generateFileKey() {
    const arr = new Uint8Array(5);
    window.crypto.getRandomValues(arr);
    return Array.from(arr, dec2hex).join('');
}
function dec2hex(dec) {
    return dec.toString(16).padStart(2, "0");
}

function saveFileList(appId, files, fileInfo, additionalAppIds) {
    console.log("saveFileList enter")
    if (!files || !Array.isArray(files) || !fileInfo || !Array.isArray(fileInfo)) return;
    const promises = [];
    for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const ext = file.name.substring(file.name.lastIndexOf('.') + 1);
        const fileKey = `${generateFileKey()}.${ext}`;
        let url, key;
        promises.push(
            getFileUploadData(fileKey, file.type)
                .then((resp) => {
                    ({ url, key } = resp)
                    return _sharedFunctions.encryptFile(file, key, resp.iv)
                })
                .then((encryptedFile) => {
                    return fetch(url, {
                        method: 'PUT',
                        headers: { 'Content-Type': file.type },
                        body: encryptedFile
                    })
                })
                .then((result) => {
                    if (result.status == "200") {
                        let request = JSON.stringify({ parentId: appId, fileName: file.name, fileTag: fileInfo[i].fileTag, fileKey: fileKey, encryptionKey: key, additionalParentIds: additionalAppIds });
                        return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: request, action: '/merchant/TransferFile' }).then(handleResponse)
                        .then((response) => {
                            //add fileTag to response for tracking
                            response.fileTag = fileInfo[i].fileTag;
                            return response;
                        });
                    }
                    else {
                        console.log("Error uploading file to s3. Status: ", result.status)
                        throw  new Error("Error uploading file to s3. Status: " + result.status)
                    }
                })
        )
    }
    return promises;
}

function decryptFile(blob, encryptionKey) {
    return new Promise(resolve => {
        blob.arrayBuffer()
            .then(buffer => {
                const contentBytes =  new Uint8Array(buffer, 0, buffer.length);

                const iv = [];
                for (let i = 0; i <= 15; i++) {
                    iv.push(contentBytes[i])
                }

                const bytesWithoutIv = new Uint8Array(contentBytes.length - 16);
                for (let j = 16; j <= contentBytes.length - 1; j++) {
                    bytesWithoutIv[j - 16] = (contentBytes[j])
                }

                const aesCbc = new AesJs.ModeOfOperation.cbc(encryptionKey,iv);
                const cipherText = aesCbc.decrypt(bytesWithoutIv);

                const paddedBytesLength = cipherText[cipherText.length - 1];
                const fileBytes = new Uint8Array(cipherText.length - paddedBytesLength);

                for (let k = 0; k <= cipherText.length - 1 - paddedBytesLength; k++) {
                    fileBytes[k] = (cipherText[k])
                }
                const decryptedBlob = new Blob([new Uint8Array(fileBytes)], {type: blob.type})
                resolve(decryptedBlob)
            })
    })
}


function getFileUploadData(fileName, contentType) {
    const requestBody = JSON.stringify({ fileName, contentType })
    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/merchant/GetFileUploadData' }).then(handleResponse);
}

function prepareMerchantFileForTransfer(parentId, file, fileTag) {
    const ext = file.name.substring(file.name.lastIndexOf('.') + 1);
    const fileKey = `${generateFileKey()}.${ext}`;
    let url, key;
    return getFileUploadData(fileKey, file.type)
        .then((resp) => {
            ({ url, key } = resp)
            return _sharedFunctions.encryptFile(file, key, resp.iv)
        })
        .then((encryptedFile) => {
            return fetch(url, {
                method: 'PUT',
                headers: { 'Content-Type': file.type },
                body: encryptedFile
            })
        })
        .then((result) => {
            if (result.status == "200") {
                return { parentId, fileName: file.name, fileTag, fileKey, encryptionKey: key };
            }
            else {
                console.log("Error uploading file to s3. Status: ", result.status)
                throw  new Error("Error uploading file to s3. Status: " + result.status)
            }
        })
}

function saveMerchantFiles(appId, fileList, mpa, isGo, additionalAppIds, retryCount = 1) {
    console.log("saveMerchantFiles enter")
    let files = [];
    let fileInfo = [];


    Object.keys(fileList).forEach(function (keyName) {
        fileInfo.push.apply(fileInfo, fileList[keyName].map((f, i) => { return { fileTag: keyName, description: f.fileDescription }; }));
        files.push.apply(files, fileList[keyName].map((f, i) => { return f.file; }));
    });
    if (files.length > 0) {
        const maxRetries = 3
        return Promise.allSettled(saveFileList(appId, files, fileInfo, additionalAppIds)).then(results => {
            console.log('saveFileList - allSettled');

            //if there were no rejections, then return a resolved promise, otherwise, remove the files that were successfully uploaded before retrying
            const rejected = results.filter(r => r.status === 'rejected');
            if (rejected.length === 0) return Promise.resolve();
            const filesToRetry = []
            map(results, (result, i) => {
                if (result.status === 'rejected' && !startsWith(result.reason,"Invalid file type for MPA")) {
                    filesToRetry.push(fileList[i])
                }
            })
            if (filesToRetry.length === 0) return Promise.reject("Failed to Upload Files. Invalid file Types Uploaded");
            if (retryCount > maxRetries) {
                let sendEmail = false;
                let documentName = '';
                Object.keys(fileList).forEach(function (keyName) {
                    if (includes(['SignaturePages', 'SignedMPA'], keyName)) {
                        documentName = keyName;
                        sendEmail = true;
                    }
                });
                const e = rejected[0];

                if (sendEmail) {
                        console.log(e)
                        let templateParams = {
                            emailTo: isGo ? process.env.REACT_APP_GO_APPLICATIONS_EMAIL : process.env.REACT_APP_APPLICATIONS_EMAIL,
                            dba: mpa?.dba || mpa?.dbaName || '',
                            appId,
                            signatureDoc: documentName,
                            error: e.reason,
                        }
                        emailService.send(emailTemplates.fileUploadError, templateParams)
                    }
                    else {
                        console.log(e);
                        return Promise.reject("Failed to Upload Files. " + e.reason);
                    }
            }
            else {
                return saveMerchantFiles(appId, filesToRetry, mpa, isGo, additionalAppIds, retryCount +1)
            }
    })
        //let formData = _sharedFunctions.objectToFormData({ parentId: appId, files, fileInfo });
        // header method is authheaders because content type is not json
        //return _sharedFunctions.callApi({ headerMethod: authHeader, body: formData, action: '/merchant/UploadFiles' }).then(handleResponse);
    }
    return Promise.resolve();
}

function getProcessorListByAppId(appId) {
    const requestBody = JSON.stringify(appId);
    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/merchant/GetProcessorListByAppId' }).then(handleResponse);
}

function getProcessorListByCountry(country) {
    const requestBody = JSON.stringify(country);
    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/merchant/GetProcessorListByCountry' }).then(handleResponse);
}

function getMerchantSetupForm(appId) {
    const requestBody = JSON.stringify(appId);

    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/merchant/GetMerchantSetup' }).then(handleResponse);
}

function saveMerchantSetupForm(setup) { 
    const requestBody = JSON.stringify(setup);
    const action = setup.isCanadian ? '/merchant/SaveCaMerchantSetup' : '/merchant/SaveUsMerchantSetup';
    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: action }).then(handleResponse);
}

function getMerchantEquipment(appId) {
    const requestBody = JSON.stringify(appId);

    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/merchant/GetMerchantEquipment' }).then(handleResponse);
}

function saveMerchantEquipment(appId, equipment) {
    const files = equipment.map(e => e.attachment && e.attachment.file);
    each(equipment, e => {
        if (e.attachment) {
            e.attachmentName = e.attachment.name;
            delete e.attachment;
        }

        e.equipmentOptions = transform(e.equipmentOptions, (acc, value, key) => {
            if (trim(value)) {
                acc[key] = value;
            }
        });
    });

    const requestJsonBody = JSON.stringify({ appId: appId, equipmentList: equipment });

    let formData = new FormData();
    formData.set("json", requestJsonBody);
    each(files, (f, i) => {
        if (f) {
            formData.append(`equipmentList[${i}].attachment`, f);
        }
    })

    // Display the key/value pairs
    for (let pair of formData.entries()) {
        console.log(pair[0], '=', pair[1]);
    }
    // header method is authheaders because content type is not json
    return _sharedFunctions.callApi({ headerMethod: authHeader, body: formData, action: '/merchant/SaveMerchantEquipment' }).then(handleResponse);
    //const requestBody = JSON.stringify();
    //return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/merchant/SaveMerchantEquipment' }).then(handleResponse); 
}

async function sendMerchantAchqTerms(appInfo){
    
    const data = {
        appId: appInfo.appId,
        sendToAddress: appInfo.merchantEmail,
        signerName: appInfo.merchantName,
        dba: appInfo.merchantDba,
        includedContracts: ['FDGoConfirmation']
    };

    const requestBody = JSON.stringify(data);
    const action = '/merchant/SendMerchantAchqTermsLinkEmail';
    const response = await _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: action });
    return handleResponse(response);
}

function saveEquipmentTemplate(equipment) {
    //each(equipment, e => {
    //    e.equipmentOptions = transform(e.equipmentOptions, (acc, value, key) => {
    //        if (trim(value)) {
    //            acc[key] = value;
    //        }
    //    });
    //});
    const requestJsonBody = JSON.stringify({ equipmentList: equipment });
    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestJsonBody, action: '/agent/SaveEquipmentTemplate' }).then(handleResponse);
}

function getInvoices(appId) {
    const requestBody = JSON.stringify(appId);

    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/merchant/GetInvoices' }).then(handleResponse);
}

function getInvoiceFieldsData(invoiceId) {
    const requestBody = JSON.stringify(invoiceId);

    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/merchant/GetInvoiceFieldsData' })
    .then(handleResponse)
}

function saveMerchantVar(appId, merchantVar) {
    const file = get(merchantVar, 'attachment.file');

    if (merchantVar.attachment) {
        merchantVar.attachmentName = merchantVar.attachment.file.name
    } else {
        delete merchantVar.attachment;
    }

    const requestJsonBody = JSON.stringify({ appId: appId, merchantVar });

    let formData = new FormData();
    formData.set("json", requestJsonBody);

    if (file) {
        formData.append(`merchantVar.attachment`, file);
    }

    for (let pair of formData.entries()) {
        console.log(pair[0], '=', pair[1]);
    }

    return _sharedFunctions.callApi({ headerMethod: authHeader, body: formData, action: '/merchant/SaveMerchantVar' }).then(handleResponse);
}
function getEquipmentListByProcessor(processor) {
    const requestBody = JSON.stringify(processor);

    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/equipment/GetEquipmentListByProcessor' }).then(handleResponse);
}
function saveMerchantAchPlan(appId,merchantPlan,plan) {
    const requestBody = JSON.stringify({ appId: appId, planId:plan, Fees: merchantPlan});

    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/equipment/SaveMerchantAchPlan' }).then(handleResponse);
}
function getMerchantPlan(appId) {
    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: appId, action: '/equipment/GetMerchantAchPlan' }).then(handleResponse);
}
function getAchPlan(appId) {
    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: appId  , action: '/equipment/GetAchPlan' }).then(handleResponse);
}
function getEquipmentList(appId) {
    const requestBody = JSON.stringify({ appId: appId });

    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/equipment/GetEquipmentList' }).then(handleResponse);
}
function getVarList(appId) {
    const requestBody = JSON.stringify({ appId: appId });

    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/equipment/GetVar' }).then(handleResponse);
}

function getHardwareList(appId) {
    const requestBody = JSON.stringify({ appId: appId });

    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/equipment/GetHardwareList' }).then(handleResponse);
}

function getGatewayList(appId) {
    const requestBody = JSON.stringify({ appId: appId });

    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/equipment/GetGatewayList' }).then(handleResponse);
}

function getAddonList(appId) {
    const requestBody = JSON.stringify({ appId: appId });

    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/equipment/GetAddons' }).then(handleResponse);
}

function validateSerialNumber({ serialNumber, category, subcategory, purchaseType, appWithActiveSerialNumber, reasonToTransferFromAnotherAgent, appId }) {

    const requestBody = JSON.stringify({
        serialNumber,
        appId,
        equipmentCategory: category,
        equipmentSubcategory :subcategory,
        purchaseType,
        appWithActiveSerialNumber,
        reasonToTransferFromAnotherAgent
    });

    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/equipment/ValidateSerialNumber' }).then(handleResponse);
}


function cancelEquipment(appId, equipmentId) {
    const requestBody = JSON.stringify({ appId, equipmentId });
    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/equipment/CancelEquipment' }).then(handleResponse);
}

function getShippingRates(senderAddress, shippingAddress, shippingWeight, satDelivery, serviceType) {
    let requestObj = {
        fromAddress: senderAddress,
        toAddress: shippingAddress,
        shippingWeight: shippingWeight,
        saturdayDelivery: satDelivery,
        serviceType: serviceType
    };
    const requestBody = JSON.stringify(requestObj);

    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/equipment/GetShippingCost' }).then(handleResponse);
}

function submitApplication(appId, isRequestingRush = false, rushReason = '') {
    const requestBody = JSON.stringify({ appId, isRequestingRush, rushReason });

    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/merchant/SubmitApplication' }).then(handleResponse);
};

const getAcroForm = pdfDoc => {
    return pdfDoc.catalog.lookup(PDFName.of('AcroForm'));
};

const getAcroFields = pdfDoc => {
    const acroForm = getAcroForm(pdfDoc);
    if (!acroForm) return [];

    const fieldRefs = acroForm.lookupMaybe(PDFName.of('Fields'), PDFArray);
    if (!fieldRefs) return [];

    const fields = new Array(fieldRefs.size());
    for (let idx = 0, len = fieldRefs.size(); idx < len; idx++) {
        fields[idx] = fieldRefs.lookup(idx);
    }
    return fields;
};


const fieldNames = {
    agentName: 'agent_name',
    corporateName: 'name',
    dba: 'business_dba',
    businessInformation_businessAddress_streetAddress: 'location_address',
    businessInformation_businessAddress_city: 'City',
    businessInformation_businessAddress_state: 'State',
    businessInformation_businessAddress_zip: 'Zip',
    businessInformation_businessPhone: 'Phone',
    businessInformation_businessFax: 'Fax',
    businessInformation_website: 'Website Address',
    businessInformation_businessEmail: 'Email address',
    corporateAddress_streetAddress: 'mailing_address',
    corporateAddress_city: 'City_2',
    corporateAddress_state: 'State_2',
    corporateAddress_zip: 'Zip_2',
    businessStartDate: 'Business Start Date',
    taxID: 'Tax ID',
    bankAccountInformation_accountNumber: 'account_dda',
    goodsOrServicesDescription: 'desc of goods / svc',
    signerInformation_firstName: 'signer name',
    signerInformation_title: 'title',
    signerInformation_address_streetAddress: 'signer address',
    signerInformation_address_city: 'City_3',
    signerInformation_address_state: 'State_3',
    signerInformation_address_zip: 'Zip_3',
    signerInformation_phoneNumber: 'Signer Home Phone',
    signerInformation_cellPhoneNumber: 'Cell',
    signerInformation_socialSecurityNumber: 'ssn',
    signerInformation_dateOfBirth: 'dob',
    amexDetails_esaNumber: 'Existing American Express Account Number'
};

const usFieldNames = {
    ...fieldNames,
    bankAccountInformation_routingNumber: 'routing#',
    ebtNumber: 'ebt#'}

const caFieldNames = {
    ...fieldNames,
    bankAccountInformation_transitNumber: 'transit',
    bankAccountInformation_institutionNumber: 'inst',
    signerInformation_identificationType: 'ID type',
    signerInformation_identificationNumber: 'ID',
    signerInformation_identificationIssueDate: 'Issuance Date',
    signerInformation_identificationExpirationDate: 'expiry',
}

const checkboxFields = {
    businessInformation_ownershipType: 'Corporation,Partnership,Sole Proprietor',
    amexDetails_status: {
        trueOption: 'Yes', trueValue: 'On', falseOption: 'Do you currently accept AMEX', falseValue: 'No'
    },
    isNewAccount: { trueOption: 'NEW ACCOUNT', trueValue: 'On' },
    isAdditionalLocation: { trueOption: 'ADDITIONAL LOCATION  Existing MID', trueValue: 'On' }
    //amexStatus: 'undefined3'
}

const usCheckboxFields = {
    ...checkboxFields,
    acceptsACH: { trueOption: 'ACH', trueValue: 'On' },
    hasGiftLoyalty: { trueOption: 'GiftLoyalty Cards', trueValue: 'On' },
    doesAcceptEbt: {
        trueOption: 'ebt_yes', trueValue: 'Yes', falseOption: 'ebt_no', falseValue: 'Yes'
    }
}

const dateFields = [
    'signerInformation_dateOfBirth',
    'businessStartDate',
    'signerInformation_identificationIssueDate',
    'signerInformation_identificationExpirationDate',
];

const fillInField = (pdfDoc, fieldName, text) => {
    const field = findAcroFieldByName(pdfDoc, fieldName);
    if (!field) {
        console.log(`Missing AcroField: ${fieldName}`);
    } else {
        fillAcroTextField(field, text);
    }
    // type btn is checkbox
};

const checkCheckbox = (pdfDoc, fieldName, fieldValue) => {
    const field = findAcroFieldByName(pdfDoc, fieldName);
    if (!field) throw new Error(`Missing AcroField: ${fieldName}`);
    field.set(PDFName.of('V'), PDFName.of(fieldValue));
    field.set(PDFName.of('AS'), PDFName.of(fieldValue));
}

const fillAcroTextField = (acroField, text) => {
    try {
        acroField.set(PDFName.of('V'), PDFHexString.fromText(text));
    }
    catch (ex) {
        console.log(`Exception filling acrofield ${acroField.get(PDFName.of('T'))} `);
        console.log(ex);
    }
    //acroField.set(PDFName.of('Ff'), PDFNumber.of(1 << 12 /* Multiline */));
};

const findAcroFieldByName = (pdfDoc, name) => {
    const acroFields = getAcroFields(pdfDoc);
    return acroFields.find(acroField => {
        const fieldName = acroField.get(PDFName.of('T'));
        return !!fieldName && fieldName.value === name;
    });
};

const logAcroFieldNames = (pdfDoc) => {
    const acroFields = getAcroFields(pdfDoc);
    acroFields.forEach((acroField) => {
        //console.log(acroField);
        console.log(
            'Field Name:',
            acroField.get(PDFName.of('T')).toString(),
            'Field Type:',
            acroField.get(PDFName.of('FT')).toString()
        );
    });
};

function getFieldValue(mpa, fieldName, splitKey = true) {
    let itemToGet, itemKey;

    if (splitKey && fieldName.indexOf('_') > 0) {
        let keyList = fieldName.split('_');
        itemToGet = keyList.reduce((prev, curItem, idx) => {
            if (idx < keyList.length - 1) {
                return prev[curItem];
            }
            return prev;
        }, mpa);
        itemKey = keyList[keyList.length - 1];
    }
    else {
        itemToGet = mpa;
        itemKey = fieldName;
    }
    //console.log('form item was ' + e.target.name);
    //console.log('updating ' + itemKey + ' property of the ' + itemToSet + ' object');
    if (typeof itemToGet[itemKey] === 'number') {
        return itemToGet[itemKey].toString();
    }
    return itemToGet[itemKey];
}


function downloadPrefilledMPA(mpa, returnUrl = true) {
    const isCanada = mpa.isCanadian;
    const fields = isCanada ? caFieldNames : usFieldNames;
    const checkboxes = isCanada ? checkboxFields : usCheckboxFields;
    const url = isCanada ? '/assets/Canadian_MPA.PDF' : '/assets/fidelitympa.pdf';
    return fetch(url)
        .then(res => res.arrayBuffer())
        .then((bytes) => PDFDocument.load(bytes))
        .then((pdfDoc) => {
            const acroForm = getAcroForm(pdfDoc);
            acroForm.set(PDFName.of('NeedAppearances'), PDFBool.True);
            //logAcroFieldNames(pdfDoc);
            Object.keys(fields).forEach((key) => {
                if (key === 'agentName') {
                    let agent = principalService.get();
                    fillInField(pdfDoc, fieldNames.agentName, agent.name);
                }
                else if (key === 'taxID') {
                    if (mpa.taxID && mpa.taxID.length > 0) {
                        mpa.taxID.split('').forEach((c, i) => {
                            //console.log(i);
                            fillInField(pdfDoc, `${fieldNames[key]}${i + 1}`, c);
                        });
                    }
                }
                else if (key === 'signerInformation_firstName') {
                    // add last name as well
                    fillInField(pdfDoc, fieldNames.signerInformation_firstName,
                        `${getFieldValue(mpa, 'signerInformation_firstName')} ${getFieldValue(mpa, 'signerInformation_lastName')}`);
                }
                else if (key === 'signerInformation_identificationType') {
                    fillInField(pdfDoc, fields[key], startCase(getFieldValue(mpa, key)))
                }
                else if (includes(dateFields, key)) {
                    let formattedVal = '';
                    let dateVal = getFieldValue(mpa, key);
                    if (dateVal) {
                        const date = moment(dateVal, process.env.REACT_APP_API_RESPONSE_DATE_TIME_FORMAT);
                        if (date.year() === 1)
                            formattedVal = '';
                        else
                            formattedVal = date.format(process.env.REACT_APP_DISPLAY_DATE_FORMAT);
                    }
                    fillInField(pdfDoc, fields[key], formattedVal);
                } else {
                    fillInField(pdfDoc, fields[key], getFieldValue(mpa, key));
                }
            });
            Object.keys(checkboxes).forEach((key) => {
                if (checkboxes[key] == checkboxes.businessInformation_ownershipType) {
                    if (mpa.businessInformation.ownershipType == 'Partnership') {
                        checkCheckbox(pdfDoc, 'Partnership', 'On');
                    }
                    else if (mpa.businessInformation.ownershipType == 'Corporation') {
                        checkCheckbox(pdfDoc, 'Corporation', 'On');
                    }
                    if (mpa.businessInformation.ownershipType == 'SoleOwnership') {
                        checkCheckbox(pdfDoc, 'Sole Proprietor', 'On');
                    }
                } 
                else if (checkboxes[key] == checkboxes.amexDetails_status) {
                    if (mpa.amexDetails.status === 'Existing') {
                        checkCheckbox(pdfDoc, 'Yes', 'On');
                    }
                }
                else {
                    let val = getFieldValue(mpa, key);

                    if (val) {
                        checkCheckbox(pdfDoc, checkboxes[key].trueOption, checkboxes[key].trueValue);
                    } else if (checkboxes[key].falseOption) {
                        checkCheckbox(pdfDoc, checkboxes[key].falseOption, checkboxes[key].falseValue);
                    }
                }
            });
            return pdfDoc.save()
        })
        .then((bytes) => {
            const blob = new Blob([bytes], { type: 'application/pdf' });
            if (!returnUrl) return blob;
            return URL.createObjectURL(blob);
        });
}

const lockField = acroField => {
    acroField.set(PDFName.of('Ff'), PDFNumber.of(1));
  };

function prefillDocumentFields(data, fileName, fields, disableFields = false) {
    const url = `/assets/${fileName}.pdf`;
    return fetch(url)
        .then(res => res.arrayBuffer())
        .then((bytes) => PDFDocument.load(bytes))
        .then((pdfDoc) => {
            // logAcroFieldNames(pdfDoc);
            const acroForm = getAcroForm(pdfDoc);
            acroForm.set(PDFName.of('NeedAppearances'), PDFBool.True);
            Object.keys(fields).forEach((key) => fillInField(pdfDoc, fields[key], getFieldValue(data, key)));
            if (fileName == 'varSheetTemplate') {
                fillInField(pdfDoc, 'Deployment Email', 'deploymentgroup@fidelitypayment.com');
                fillInField(pdfDoc, 'Deployment Date', moment().format(process.env.REACT_APP_DISPLAY_DATE_FORMAT));
            }
            if(fileName === 'ACH_application_worksheet'){
                checkCheckbox(pdfDoc, 'Bank Accout1', data.enableSecondaryBankAccountInfo ? 'Choice2' : 'Choice1');
                if(data.enableSecondaryBankAccountInfo){
                    fillInField(pdfDoc, 'Name on bank account Fees', data.bankAccountInformation.secondaryBankName);
                    fillInField(pdfDoc, 'ABA number_2', data.bankAccountInformation.secondaryRoutingNumber);
                    fillInField(pdfDoc, 'DDA number_2', data.bankAccountInformation.secondaryAccountNumber);

                }
                checkCheckbox(pdfDoc, 'Bounced Checks', data.enableAlternativeBankAccountInfo ? 'Choice2' : 'Choice1');
                if(data.enableAlternativeBankAccountInfo){
                    fillInField(pdfDoc, 'Name on bank account_2', data.bankAccountInformation.alternateBankName);
                    fillInField(pdfDoc, 'ABA number_3', data.bankAccountInformation.alternateRoutingNumber);
                    fillInField(pdfDoc, 'DDA number_3', data.bankAccountInformation.alternateAccountNumber);
                }
            }
            if (fileName === 'CardknoxGo_Mpa') {
                SetSignerInformation(pdfDoc, data)

                const taxID = trim(data.taxId)
                for (let i = 0; i < 9; i++) {
                    fillInField(pdfDoc,`TaxID_${i+1}`, taxID[i]);
                }
                if(includes(['Corporation', 'Partnership', 'SoleProprietor'], data.ownershipType)){
                    checkCheckbox(pdfDoc, data.ownershipType, "Yes");
                }
            }
            if(disableFields){
                const acroFields = getAcroFields(pdfDoc);
                acroFields.forEach(field => lockField(field));
            }
            return pdfDoc.save()
        })
        .then((bytes) => {
            return new Blob([bytes], { type: 'application/pdf' });
        });
}
function SetSignerInformation(pdfDoc, data) {
    const signerInfo = data.signerInformationList[0]
    fillInField(pdfDoc, 'ContactName', `${signerInfo.firstName} ${signerInfo.lastName}`);
    fillInField(pdfDoc, 'SignerName', `${signerInfo.firstName} ${signerInfo.lastName}`);
    fillInField(pdfDoc, 'SignerTitle', signerInfo.title);
    fillInField(pdfDoc, 'CellPhoneNumber', signerInfo.cellPhone);
    fillInField(pdfDoc, 'DOB', signerInfo.dateOfBirth);
    fillInField(pdfDoc, 'SSN', signerInfo.ssn);
    fillInField(pdfDoc, 'SignerHomeAddress', signerInfo.address.streetAddress);
    fillInField(pdfDoc, 'SignerCity', signerInfo.address.city);
    fillInField(pdfDoc, 'SignerState', signerInfo.address.state);
    fillInField(pdfDoc, 'SignerZip', signerInfo.address.zip);
}

function downloadPrefilledFile(fileName, data) {
    const url = `/assets/${fileName}.pdf`;

    return fetch(url)
        .then(res => res.arrayBuffer())
        .then((bytes) => PDFDocument.load(bytes))
        .then((pdfDoc) => {
            const acroForm = getAcroForm(pdfDoc);
            acroForm.set(PDFName.of('NeedAppearances'), PDFBool.True);
            const acroFieldNames = map(getAcroFields(pdfDoc), acroField => replace(acroField.get(PDFName.of('T')).toString(), /[\(\))]/gi, ''));

            each(acroFieldNames, fieldName => fillInField(pdfDoc, fieldName, getFieldValue(data, fieldName, false)));

            return pdfDoc.save();
        })
        .then((bytes) => {
            return new Promise((fulfill, reject) => {
                let reader = new FileReader();
                reader.onerror = reject;
                reader.onload = (e) => fulfill(reader.result);
                reader.readAsDataURL(new Blob([bytes], { type: 'application/pdf' }));
            })
        }).then(dataURL => dataURL);
}

//Plaid
function getPlaidLinkToken(appId) {
    const requestBody = JSON.stringify({appId})
    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/merchant/GetLinkToken' }).then(handleResponse);
};
function exchangePlaidAccessToken(appId, publicToken, linkToken) {
    const requestBody = JSON.stringify({appId, publicToken, linkToken})
    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/merchant/SavePersistentAccessToken' }).then(handleResponse);
};

function cloneEApp(appId, country, processor) {
    const requestBody = JSON.stringify({appId, country, processor})
    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/merchant/CloneEApp' }).then(handleResponse);
};

function getGoPlusUnderwritingLevels(token){
    let headerMethod = appRequestHeaders;
    if(token){
        headerMethod = () => _sharedFunctions.appRequestHeadersForMerchant(token);
    }
    return _sharedFunctions.callApi({ headerMethod, action: '/merchant/GetGoPlusUnderwritingLevels' }).then(handleResponse);  
}