import React, {Component} from 'react';
import PropTypes from 'prop-types';

class TicketIssueFilterComponent extends Component {
    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
    }

    componentDidUpdate() {
        if (this.ticketIssue) {
            this.ticketIssue.focus();
        }
    }
    filterChange = e => {
        const value = e.target.value ? e.target.value : null;

        this.onFilterChange(value)
    };

    onFilterChange(value) {
        this.props.onFilterChanged({
            id: 'ticketIssue',
            values: [{key:'ticketIssue', value: value}],
            emptyValue: null
        });
    };

    render() {
        const ticketIssue = this.props.filter.values.ticketIssue;

        return (
            <div className="pos--rel">
                <input
                    type="text"
                    placeholder="Ticket Issue"
                    className="input input--number input--med"
                    value={ticketIssue === null ? '' : ticketIssue}
                    id="ticketIssue"
                    onChange={this.filterChange}
                    ref={this.inputRef}
                    autoFocus={true}
                />
            </div>
        );        
    }
}

TicketIssueFilterComponent.propTypes = {
    filter: PropTypes.object.isRequired,
    onFilterChanged: PropTypes.func.isRequired
};

export default TicketIssueFilterComponent;