import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isObject, map } from 'lodash';

class FormErrorsComponent extends Component {
    renderError = (error, key) => {
        if (isObject(error)) {
            return map(error, (subError, subKey) => this.renderError(subError, key ? `${key}.${subKey}` : subKey));
        }
        if (error) {
            return (
                <p className="type--error padd--bottom--sml" key={key}>{error}</p>
            );
        }
        return null;
    };

    render = () => {
        const {errors} = this.props;
        return (
            <div className="padd--top--sml padd--right--med padd--left--med bg--warning--faded border--warning radial">
                {this.renderError(errors)}
            </div>
        );
    };
}

FormErrorsComponent.propTypes = {
    errors: PropTypes.object,
};

export default FormErrorsComponent;