import React, { Fragment } from 'react'
import {NumericFormat as NumberFormat} from 'react-number-format'
import PropTypes from 'prop-types';
import {map, find, toLower, filter, get, cloneDeep, some } from 'lodash';
import { appService } from '../../services';
import { withLoader } from '../../common/components';
import { modalNames , ModalWrapper } from '../../common/components/modal-wrapper';
import { planTemplate, Schema } from '../../validation';
import { defaultImplicitParse, defaultReactOutput } from 'simple-markdown';
import { withCancelable } from '../../common/components/cancelable';

const additionalMarkupAchId = 3489;
const setupFeeIds = [12, 13];

class AchPlan extends React.Component {
constructor(props){
    super(props);
    this.state = {
        equipmentPlan: {},
        merchantPlan:{},
        merchantFees:{},
        errorMessage: '',
        merchantFeesSaved : false,
        planId:'',
        modal: {
            name: modalNames.none,
            data: null,
        },
        appId: this.props.match.params.appid,
        disableSave: false,
        errorList: [],
    }
}

    scrollToTop = () => {
        const topElement = document.querySelector('#topOfPage');

        if (topElement) {
            topElement.scrollTop = 0;
        }
    }

    async componentDidMount(){
        try {
            this.props.showLoader(true);
            const { makePendingRequest, match } = this.props;
            let appId = match.params.appid;
            this.planSchema = new Schema(planTemplate, { strip: false, typecast: true });
            await makePendingRequest(
                appService.getAchPlan(appId).then(({equipmentPlan}) => {
                    return appService.getMerchantPlan(appId).then(({merchantAchPlanResponse}) => {
                        const disableFeeInputs = (merchantAchPlanResponse?.fees && merchantAchPlanResponse.fees?.length > 0);
                        this.setState({ equipmentPlan, merchantPlan: merchantAchPlanResponse, merchantFeesSaved:disableFeeInputs, planId:equipmentPlan.planId}, this.mapPlans);
                        this.props.showLoader(false);
                    });
                 })
                 .catch((error) => {
                    console.log(error);
                    this.setState({errorMessage: "An Error Ocurred Loading plans"});
                    this.props.showLoader(false);
                 }),
                'getAchPlan'
            );
        } catch (error) {
            this.props.showLoader(false);            
        }
        this.scrollToTop();
    }

    mapPlans = () => {
        const {equipmentPlan, merchantPlan } = this.state;
        let relevantFees = [
            ...this.filterMonthlyFees([...equipmentPlan.fees, ...equipmentPlan.additionalFees]),
            ...this.filterSetupFees([...equipmentPlan.fees, ...equipmentPlan.additionalFees]),
            ...this.filterCheck21TransactionFees([...equipmentPlan.fees, ...equipmentPlan.additionalFees]),
            ...this.filterAchTransactionFees([...equipmentPlan.fees, ...equipmentPlan.additionalFees]), 
        ];

        let merchantFees = get(merchantPlan, 'fees', []);
        const fees = relevantFees.map((fee) => {
            const merchantFee = find(merchantFees, (f) => fee.feeId === f.feeId );
            return {
                feeId: fee.feeId,
                feeName: fee.feeName,
                feeType: fee.feeType,
                minFee: get(merchantFee, 'minFee', fee.minFee),
                maxFee: get(merchantFee, 'maxFee', fee.maxFee),
                agentCost: get(merchantFee, 'agentCost', fee.agentCost),
                freeTransactions: fee.freeTransactions,
                achTransactionType: fee.achTransactionType,
                merchantPrice: get(merchantFee, 'merchantPrice', fee.retailPrice),
            };
        });
        this.setState({merchantFees: fees});
    }

    handleFeeChange = (feeId, event) => {
        const updateValue = event.floatValue;

        let fees = cloneDeep(this.state.merchantFees);
        let fee = fees.find((fee, i) => fee.feeId == feeId);
        const invalidValue = /^0*\.0*$/.test(updateValue);
        if(invalidValue || (updateValue === undefined ||isNaN(updateValue)))
        {
            fee.merchantPrice = null;
        }
        else
        {
            fee.merchantPrice = updateValue;
        }
        this.validateFees(fees)
        this.setState({merchantFees: fees});
    }

    openCloseModal = (modalObj, ...rest) => {
        let state = {
            modal: modalObj,
        };
        this.setState(state);
    };

    onKeyDownHandler = (event, onClickHandler) => {
        if (event.keyCode === 13)  onClickHandler();
    }

    getFeesForValidation = (fees) => {
        const feesToExcludeIfMerchantCostIsZero = filter([
                                    ...this.filterMonthlyFees(fees),
                                    ...this.filterCheck21TransactionFees(fees),
                                    ...filter(fees, fee =>some([additionalMarkupAchId], id => id === fee.feeId))], (fee) => !fee.merchantPrice);
        const feesToValidate = filter(fees, fee => !some(feesToExcludeIfMerchantCostIsZero, {feeId: fee.feeId}));
        return feesToValidate;
    }

    validateFees = (fees)=>{
        let errorList = [];
        const feesForValidation = [...this.getFeesForValidation(fees)];
        const setupFees = filter(feesForValidation, fee => some(setupFeeIds, id => id === fee.feeId));
        errorList = this.planSchema.validate(Object.assign({}, {fees: feesForValidation}));
        if(!some(setupFees, fee => fee.merchantPrice > 0)){
            errorList.splice(0, 0, {message: '[**Application Fee or Setup Fee**](javascript:void) is required', path: 'fees.SetupFees'})
        }
        this.setState({errorList});
        return errorList.length;
    }
    
    handleSavePlan = (goToNextStep) => {
        const { merchantFees } = this.state;
        if (this.state.disableSave) return;

        const validationResult = this.validateFees(merchantFees)
        if (validationResult > 0){
            return;
        }

        this.openCloseModal({
            name: modalNames.confirmAction,
            data: {
                question: 'Please confirm Plan details, fees cannot be adjusted once saved',
                onConfirm: this.savePlan(goToNextStep)
            }
        })
    }

    savePlan = (goToNextStep) => () => {
        const { merchantFees, planId } = this.state;
        this.props.showLoader(true);
        this.setState({ disableSave: true });
        const merchantFeesToSave = filter(merchantFees, fee => fee.merchantPrice);
        const feesToSave = merchantFeesToSave && merchantFeesToSave.map(fee => {
                return { merchantPrice: fee.merchantPrice, feeId: fee.feeId, freeTransactions :fee.freeTransactions }
            });
        appService.saveMerchantAchPlan(this.props.match.params.appid, feesToSave, planId).then(() => {
            this.setState({ disableSave: true });
            this.props.showLoader(false);
            if (goToNextStep) this.tabGoTo('ach-confirm');
            this.setState({merchantFeesSaved: true, errorMessage: ''});
        }).catch((error) => {
            console.log(error);
            this.setState({errorMessage: `An Error Ocurred Saving plan: ${error}`});
            this.props.showLoader(false);
            this.setState({ disableSave: false});
        })
    }

    renderFeesForType = (fees) => {
        const {merchantFeesSaved} = this.state;
        return (
            <React.Fragment>
                {map(fees, fee => {
                    const merchantPrice = get(fee,'merchantPrice', 0)
                     return (<tr key={get(fee,'feeId', 0)}>
                        <td>{get(fee,'feeName')}</td>
                        <td>
                            <NumberFormat
                                value={get(fee,'agentCost', 0)}
                                prefix={'$'}
                                decimalScale={4}
                                className="input input--med type--center"
                                id={`agentCost_${get(fee,'feeId', 0)}`}
                                disabled={true}
                            />
                        </td>
                        <td>
                            <NumberFormat
                                value={merchantPrice}
                                prefix={'$'}
                                decimalScale={4}
                                fixedDecimalScale = {false}
                                className="input input--med type--center"
                                id={`merchantPrice_${get(fee,'feeId', 0)}`}
                                disabled={merchantFeesSaved}
                                onValueChange={(e) => this.handleFeeChange(get(fee,'feeId', 0), e)}
                            />
                        </td>
                    </tr>)}
                )}
            </React.Fragment>
        );
    }

    renderErrors() {
        const { errorMessage } = this.state;
        if (!errorMessage) return null;
        return (
            <div className="note note--warning">
                {errorMessage}
            </div>
        )
    }

    tabGoTo = (path) => {
        const { appid } = this.props.match ? this.props.match.params : '';
        if(appid)
            this.props.history.push(`/eapp/${path}/${appid}`)
    }

    renderTabs() {
        return (
            <ul className="tabs--primary spc--bottom--lrg">
                <li className="tabs--primary__item"
                    onKeyDown={(e) => this.onKeyDownHandler(e, () => this.tabGoTo('ach'))}
                    onClick={() => this.tabGoTo('ach')}>MPA</li>
                <li className="tabs--primary__item is-active">Fees</li>
                <li className="tabs--primary__item"
                    onKeyDown={(e) => this.onKeyDownHandler(e, () => this.tabGoTo('ach-confirm'))}
                    onClick={() => this.tabGoTo('ach-confirm')}
                >Confirm</li>
            </ul>
        );
    }

    renderFeeHeader = (feeType) => {
        return (
            <tr>
                <th className="table--gateway__group__wrapper">
                    <div className="table--gateway__group">
                        <div className="title--tertiary" id={feeType.split(' ').join('')}>{feeType}</div>
                    </div>
                </th>
                <th className={`table--gateway__group__wrapper`}>
                    <div className="table--gateway__group">
                       <span className="title">Agent Cost:</span>
                    </div>
                </th>
                <th className={`table--gateway__group__wrapper`}>
                    <div className="table--gateway__group">
                        <span className="title">Merchant Cost:</span>
                    </div>
                </th>
            </tr>
        );
    }
    renderValidationErrors=()=>{
        const {errorList} = this.state;
        return(
            errorList?.length ? (
            <div className="spc--bottom--med note note--warning">
                <ul>
                    {errorList.map((elem, i) => {
                        let elemSplit = elem.path.split('.');
                        let elemId = elemSplit[1];
                        return (<li key={i}>
                            <div className="anchor"
                                onKeyDown={(e) => this.onKeyDownHandler(e, () => this.scrollAndFocusField(elemId))}
                                onClick={() => {this.scrollAndFocusField(elemId) }}>
                                <i className="icon icon--nano icon--text-top icon--alert spc--right--tny"></i> {defaultReactOutput(defaultImplicitParse(elem.message))}
                            </div></li>);
                    })}</ul>
            </div>
        ) : null
        )
    }

    scrollAndFocusField = (id) => {
        const fee = this.getFeesForValidation(this.state.merchantFees)[id];
        let elem = null;
        if (!fee){
            elem = document.getElementById(id); //case when id is not for merchantPrice
        }else{
            elem = document.getElementById('merchantPrice_' + fee.feeId);
        };
        if (elem) {
            elem.focus();
            elem.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    filterCheck21TransactionFees = (fees) => filter(fees,(fee) => ((toLower(fee.feeType) === 'transactionfee' || toLower(fee.feeType) === 'discountfee') && fee.achTransactionType == "Check21"));
    filterAchTransactionFees = (fees) => filter(fees,(fee) =>((toLower(fee.feeType) === 'transactionfee' || toLower(fee.feeType) === 'discountfee') && fee.achTransactionType == "Ach"));
    filterMonthlyFees = (fees) => filter(fees,(fee) => toLower(fee.feeType) === 'monthlyfee');
    filterSetupFees = (fees) => filter(fees,(fee) => toLower(fee.feeType) === 'onetimefee');

    render(){

        const {disableSave, merchantFeesSaved, merchantFees:fees, modal} = this.state;
        const monthlyFees = this.filterMonthlyFees(fees);
        const setupFees = this.filterSetupFees(fees);
        const check21TransactionFees = this.filterCheck21TransactionFees(fees);
        const achTransactionFees = this.filterAchTransactionFees(fees);
        const disableSaveButtons = disableSave || merchantFeesSaved || this.state.errorList?.length;
        
        return (
            <div id="main-div" className="l--content l--content--med">
                {this.renderErrors()}
                {this.renderTabs()}
                {merchantFeesSaved && <div className="note note--default note--no-margin spc--bottom--med">
                Fees are not adjustable once they have been saved. </div>}  
                <ModalWrapper modal={modal} onModalClose={this.openCloseModal} />
                {!fees?.length ? <div></div> :
                (<div>
                <div className="table--gateway--primary__wrapper spc--bottom--med">
                <table className="table--gateway table--gateway--primary">
                    <thead>
                    <tr>
                        <th colSpan={3}>Fees</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.renderFeeHeader('Monthly Fees')}
                    {this.renderFeesForType(monthlyFees)}
                    {this.renderFeeHeader('Setup Fees')}
                    {this.renderFeesForType(setupFees)}
                    {this.renderFeeHeader('Check 21 Fees')}
                    {this.renderFeesForType(check21TransactionFees)}
                    {this.renderFeeHeader('ACH Fees')}
                    {this.renderFeesForType(achTransactionFees)}
                    </tbody>
                </table>
                </div>
                {this.renderValidationErrors()}
                <div className="type--right">
                {merchantFeesSaved ? <button className="btn btn--primary btn--med"onClick={() => this.tabGoTo('ach-confirm')}>Next Step</button> :
                    <Fragment>
                        <button className='btn btn--primary btn--med spc--right--tny' disabled={disableSaveButtons} onClick={() => this.handleSavePlan(false)}>Save</button>
                        <button className='btn btn--primary btn--med' disabled={disableSaveButtons} onClick={() => this.handleSavePlan(true)}>Save and Next Step</button>
                    </Fragment>
                }
                </div>
            </div>)}
            </div>
        );
        }
}
export default withLoader(withCancelable(AchPlan));

AchPlan.propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,
    showLoader: PropTypes.func,
    makePendingRequest: PropTypes.func,
}

