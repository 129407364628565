import React, { Component } from 'react';
import { BrowserRouter as Router, withRouter } from 'react-router-dom';
import { routes, renderRoutes } from './routing';
import { GlobalErrorHandler } from './common';
import { decode } from 'jsonwebtoken';
import { some } from 'lodash';

// NOTICE: this is mandatory!!! Do not delete
import './config/aws-exports';
import { Auth, Hub } from 'aws-amplify';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import PaymentPointsContext from './common/components/user-account-panel/PaymentPointsContext';
import { appService, principalService } from './services';
import PaymentPointsUpdateContext from './common/components/user-account-panel/PaymentPointsUpdateContext';

const RouterAwareGlobalErrorHandler = withRouter(GlobalErrorHandler);
class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            agentPointsBalance: {
                points: 0,
                lastUpdated: ''
            }
        }
    }
    componentDidMount() {
        Hub.listen('auth', this.updateUserId);
        this.AuthenticateUser();
        let principal = principalService.get();
        if (this.isUserAuthenticated(principal)) { this.getPaymentPoints();}
    }
    AuthenticateUser = () => {
        Auth.currentAuthenticatedUser()
            .then(data => {
                if (data) {
                    this.updateUserId({ payload: { event: 'signIn', data } });
                }
            })
            .catch(() => {
                // Intentionally empty catch block since we don't really want to do anything when
                // the user is not logged in on initial app load
            });
    }
    updateUserId = ({ payload: { event, data } }) => {
        if (event === 'signIn') {
            window.dataLayer.push({ event: 'uidAvailable', uid: data.username });
        }
    }
    getPaymentPoints = () => {
        if (principalService.get().isMerchant || principalService.get().isQaUser || !some(principalService.get().roles, role => role !== "Residuals")) return;
        appService.getPoints().then(points => this.setState({
            agentPointsBalance: { points: points.agentPoints, lastUpdated: new Date().toLocaleString() }
         })).catch((err) => {
            console.log(err);
         });
    }
    isUserAuthenticated = (userObj) => {
        let isExpired = false;
        if (!!userObj) {
            const token = userObj.token;
            isExpired = this.isTokenExpired(token);
        }
        return !!userObj && (userObj.agentId > 0 || userObj.isMerchant) && !isExpired;
    }
    isTokenExpired(token) {
        const decodedToken = decode(token, { complete: true });
        const dateNow = Math.floor(Date.now() / 1000);
        return decodedToken.payload.exp < dateNow;
    }

    render() {

        return (
            <DndProvider backend={HTML5Backend}>
                <Router>
                    <RouterAwareGlobalErrorHandler>
                        <PaymentPointsUpdateContext.Provider value={this.getPaymentPoints}>
                            <PaymentPointsContext.Provider value={this.state.agentPointsBalance}>
                                {renderRoutes(routes)}
                            </PaymentPointsContext.Provider>
                        </PaymentPointsUpdateContext.Provider>
                    </RouterAwareGlobalErrorHandler>
                </Router>
            </DndProvider>
        )
    }
}
export default App;
