import React from 'react'
import { map } from 'lodash'
import moment from 'moment';

function MonthSelector(props) {
    const { months, currentMonth, onChange } = props;
    return (
        <div className="flex--primary">
            <label htmlFor="monthSelector" className="type--color--primary type--sml--plus type--wgt--semibold spc--right--med spc--bottom--sml">Select the Month</label>
            <div className="w--220p">
                <select className="input input--med input--select spc--bottom--sml" id="monthSelector" value={currentMonth || ''} onChange={onChange}>
                    {!currentMonth && <option value="">Please select...</option>}
                    {map(months, month => <option key={month} value={month}>{moment(month).format(process.env.REACT_APP_MONTH_DISPLAY_FORMAT)}</option>)}
                </select>
            </div>
        </div>
    )

}

export default MonthSelector;