import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { OutsideClick } from '../../common/utilities';
import { withContext } from '../../common/components';
import { MerchantContext } from '../MerchantDetails';

class EquipmentTabsComponent extends Component { 
    get tabs() {
        const tabs = {
            gateways: 'Gateways and Add-ons',
            software: 'Software',
            hardware: 'Hardware',
            accessories: 'Accessories'
        };
        if (this.props.merchant && !this.props.merchant.isCanadian) {
            tabs.posSystems = 'POS Systems';
        }
        tabs.other = 'Other';
        return tabs;
    }
    state = {
        isNavOpen: false,
    };

    renderTab(tab,i) {
        return (
            <li key={i} className={this.props.currentTab == tab ? "tabs--primary__item is-active" : "tabs--primary__item"}
                onClick={() => this.onTabSelect(tab)} >{this.tabs[tab]}</li>
        );
    }

    onTabSelect = tab => {
        this.props.onTabSelect(tab);
        this.onNavClick();
    };

    onNavClick = () => {
        if (!this.state.isNavOpen) return;
        this.openCloseNav();
	};

    openCloseNav = () => {
		this.setState({
			isNavOpen: !this.state.isNavOpen,
		});
	};

    render = () => {
        const tabs = this.tabs;
        const navigationList = Object.keys(tabs).map((t,i) => { return this.renderTab(t,i) });
        return (
            <React.Fragment>
                <ul className="tabs--primary spc--bottom--lrg hide--to--med">
                    {navigationList}
                </ul>
                <div className="hide--from--med">
                    <OutsideClick action={this.onNavClick}>
                        <div className="pos--rel spc--bottom--med">
                            <div className="input input--med input--select" onClick={this.openCloseNav}>
                                {tabs[this.props.currentTab]}
                            </div>
                            <ul className={this.state.isNavOpen ? 'tabs--primary__dropdown' : 'display--n'}>{navigationList}</ul>
                        </div>
                    </OutsideClick>
                </div>
            </React.Fragment>
        );
    };
}

EquipmentTabsComponent.propTypes = {
    onTabSelect: PropTypes.func.isRequired,
    currentTab: PropTypes.string
};


export default withContext(EquipmentTabsComponent, MerchantContext, 'merchant');