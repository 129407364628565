import React, { Component, Fragment } from 'react'
import { noop, every, toLower, get, isEmpty, map, identity } from 'lodash';

import { CheckboxTxtInput } from '../../common/components/checkbox-text-input';
import { Toggle } from '../../common/components/toggle'

export default class USMpaConfirm extends Component {
    render() {
        const { mpa } = this.props;

        const mailingAddressSameAsLocationAddress = every(mpa.corporateAddress, (value, key) => {
            return toLower(value) === toLower(mpa.businessInformation.businessAddress[key]);
        });
        return (
            <Toggle initialToggle={true}>
                {({ isToggled, handleToggle }) => (
                    <Fragment>
                        <div className="card--primary card--sml spc--bottom--med">
                            <div
                                className={`card--primary__header ${isToggled
                                        ? "is-expanded"
                                        : "is-collapsed"
                                    }`}
                            >
                                <h6 className="spc--bottom--sml">MPA Form</h6>
                                <div className="card--primary__header__expand" onClick={handleToggle}>
                                    <i
                                        className={`icon icon--arrow icon--tiny icon--arrow--down--primary ${isToggled ? "rotate--180" : ""
                                            }`}
                                    ></i>
                                </div>
                            </div>
                            <div
                                className={`details__content ${isToggled ? "" : "display--n"
                                    }`}
                            >
                                <div className="card--primary__subheader">
                                    <strong>Business Information</strong>
                                    {this.props.renderChangeLink(this.props.redirectToMpa)}
                                </div>
                                <div className="row grid-align-middle">
                                    {this.renderField(mpa, 'corporateName', 'Corporate Name')}
                                    {this.renderField(mpa, 'dba', 'DBA')}
                                    {this.renderField(mpa, 'contactPhoneNumber', 'Contact Phone Number')}
                                    {this.renderField(mpa, 'businessContactName', 'Contact Name')}
                                    {this.renderBusinessEmail(mpa)}
                                    {this.renderBusinessPhoneNumber(mpa)}
                                    {this.renderField(mpa, 'businessInformation.businessFax', 'Business Fax Number')}
                                    {this.renderField(mpa, 'businessStartDate', 'Business Start Date')}
                                </div>
                                <div className="row grid-align-middle">
                                    {this.renderField(mpa, 'businessInformation.businessAddress.streetAddress', 'Business Address')}
                                    {this.renderField(mpa, 'businessInformation.businessAddress.city', 'City')}
                                    {this.renderStateAndZip(mpa, 'businessInformation.businessAddress')}
                                </div>
                                <div className="card--primary__subheader">
                                    <strong>Mailing Address</strong>
                                    {this.props.renderChangeLink(this.props.redirectToMpa)}
                                </div>
                                <div className="form__field spc--bottom--med">
                                    <input
                                        name="mailingAddressSameAsLocationAddress"
                                        type="checkbox"
                                        id="mailingAddressSameAsLocationAddress"
                                        className="input--check input--check--preview"
                                        checked={mailingAddressSameAsLocationAddress}
                                        value={mailingAddressSameAsLocationAddress}
                                        onChange={noop}
                                    />
                                    <label
                                        className="label"
                                        htmlFor="mailingAddressSameAsLocationAddress"
                                    >
                                        Mailing address same as location
                                        address.
                                    </label>
                                </div>
                                {this.renderMailingAddress(mailingAddressSameAsLocationAddress)}
                                <div className="card--primary__subheader">
                                    <strong>Additional Information</strong>
                                    {this.props.renderChangeLink(this.props.redirectToMpa)}
                                </div>
                                <div className="row grid-align-middle">
                                    {this.renderField(mpa, 'goodsOrServicesDescription', 'Goods/Services Sold')}
                                    {this.renderField(mpa, 'businessInformation.website', 'Website')}
                                    {this.renderField(mpa, 'businessInformation.ownershipType', 'Ownership Type')}
                                    {this.renderField(mpa, 'taxID', 'Tax ID (9 digits)', this.renderLastFourOrValue, 'fs-mask')}
                                </div>
                                <div className="card--primary__subheader">
                                    <strong>Bank Information</strong>
                                    {this.props.renderChangeLink(this.props.redirectToMpa)}
                                </div>
                                <div className="row grid-align-middle">
                                    {this.renderField(mpa, 'bankAccountInformation.bankName', 'Bank Name')}
                                    {this.renderField(mpa, 'bankAccountInformation.accountNumber', 'Account DDA #', this.renderLastFourOrValue, 'fs-mask')}
                                    {this.renderRoutingNumber(mpa, 'bankAccountInformation.routingNumber', 'Bank Routing #')}
                                </div>
                                {(mpa.bankAccountInformation.secondaryAccountNumber || mpa.bankAccountInformation.secondaryRoutingNumber) &&
                                    <div className="row grid-align-middle">
                                        {this.renderField(mpa, 'bankAccountInformation.secondaryBankName', 'Secondary Bank Name')}
                                        {this.renderField(mpa, 'bankAccountInformation.secondaryAccountNumber', 'Secondary Account DDA #', this.renderLastFourOrValue, 'fs-mask')}
                                        {this.renderRoutingNumber(mpa, 'bankAccountInformation.secondaryRoutingNumber', 'Secondary Bank Routing #')}
                                    </div>
                                }
                                {this.renderAlternateBankInfo(mpa)}
                                <div className="card--primary__subheader">
                                    <strong>Signer Information</strong>
                                    {this.props.renderChangeLink(this.props.redirectToMpa)}
                                </div>
                                <div className="row grid-align-middle">
                                    {this.renderField(mpa, 'signerInformation.firstName', 'Signer First Name')}
                                    {this.renderField(mpa, 'signerInformation.lastName', 'Signer Last Name')}
                                </div>
                                <div className="row grid-align-middle">
                                    {this.renderField(mpa, 'signerInformation.address.streetAddress', 'Street Address')}
                                    {this.renderField(mpa, 'signerInformation.address.city', 'City')}
                                    {this.renderStateAndZip(mpa, 'signerInformation.address')}
                                </div>

                                <div className="row">
                                    {this.renderField(mpa, 'signerInformation.phoneNumber', 'Phone Number')}
                                    {this.renderField(mpa, 'signerInformation.cellPhoneNumber', 'Signer Cell Number')}
                                    {this.renderSocialSecurityNumber(mpa)}
                                    {this.renderField(mpa, 'signerInformation.dateOfBirth', 'Date of Birth', undefined, 'fs-mask')}
                                    {this.renderAdditionalSignerInfo(mpa)}
                                </div>

                                {this.renderAdditionalPaymentDetails(mpa)}
                                <div className="card--primary__subheader">
                                    <strong>Other Notes</strong>
                                    {this.props.renderChangeLink(this.props.redirectToMpa)}
                                </div>
                                <div className="form__field">
                                    <label className="label">
                                        Note
                                    </label>
                                    <div className="input input--textarea input--fake input--fake--no-ellipsis">
                                        {mpa.accountNotes}
                                    </div>
                                </div>
                                {this.renderUploadDocuments()}
                            </div>
                        </div>
                    </Fragment>
                )}
            </Toggle>
        )
    }
    renderAlternateBankInfo=()=>{}
    renderBusinessEmail(mpa){
        return this.renderField(mpa, 'businessInformation.businessEmail', 'Business Email')
    }
    renderAdditionalPaymentDetails(mpa) {
        if(!get(mpa, 'amexDetails', false)){
            return null;
        }

        return <div>
            <div className="card--primary__subheader">
                <strong>Additional Payment Details</strong>
                {this.props.renderChangeLink(this.props.redirectToMpa)}
            </div>
            <div className="row">
                {this.renderEsaNumber(mpa)}
                {this.renderEbtNumber(mpa)}
            </div>
        </div>
    }

    renderEbtNumber(mpa) {
        return <div className="col col-sml-12 col-med-6 spc--bottom--med">
            <CheckboxTxtInput
                disabled={true}
                labelText='Do you have an existing EBT account number?'
                checkboxName='doesAcceptEbt'
                inputName='ebtNumber'
                inputPlaceholder='EBT Number'
                checked={mpa.doesAcceptEbt}
                value={mpa.ebtNumber}
                checkboxClass='input--check input--check--preview'
                inputClass="input input--med"
                toggleId="2" />
        </div>;
    }

    renderEsaNumber(mpa) {
        return <div className="col col-sml-12 col-med-6 spc--bottom--med">
            <CheckboxTxtInput
                disabled={true}
                labelText='Do you have an existing Amex ESA number?'
                checkboxName='amexDetails_status'
                inputName='amexEsaNumber'
                inputPlaceholder='ESA Number'
                checked={get(mpa, 'amexDetails.status') === 'Existing'}
                value={get(mpa, 'amexDetails.esaNumber')}
                checkboxClass='input--check input--check--preview'
                inputClass="input input--med"
                toggleId="1" />
        </div>;
    }

    renderField(form, field, label, renderer = identity, className = '') {
        return (
            <div className="col col-sml-12 col-med-6">
                <div className="form__field spc--bottom--med">
                    <label className="label">{label} </label>
                    <div className={`input input--med input--fake${className ? ` ${className}` : ''}`}>
                        {renderer(get(form, field))}
                    </div>
                </div>

            </div>
        )
    }

    renderBusinessPhoneNumber(mpa) {
        return this.renderField(mpa, 'businessInformation.businessPhone', 'Business Phone Number');
    }

    renderFiles(files) {
        if (isEmpty(files)) return null;
        return map(files, (file, idx) => (
            <div key={idx} className="file-upload__container file-upload__container--sml">
                <div className="file-upload__btn horizontal">
                    <i className='icon icon--xsml icon--upload spc--right--sml'/>
                    <p className="type--wgt--medium">{file.fileName}</p>
                </div>
            </div>
        ));
    }

    renderUploadDocumentRow(tag, label) {
        const id = `MPAFileTag.${tag}`;
        const files = this.props.getFilesForTag(tag);
        if (!files.length && label == 'Signature') return;

        return (
            <React.Fragment>
                <div className="col col-sml-12 col-lrg-6 spc--bottom--med">
                    <div className="form__field spc--bottom--xsml">
                        <input
                            name={id}
                            type="checkbox"
                            id={id}
                            className="input--check input--check--preview"
                            checked={!isEmpty(files)}
                            value={!isEmpty(files)}
                            onChange={noop}
                        />
                        <label className='type--wgt--medium' htmlFor={id}>
                            {label}
                        </label>
                    </div>
                    {this.renderFiles(files)}
                </div>
            </React.Fragment>
        )
    }

    renderUploadDocuments() {
        return (
            <Fragment>
                <div className="card--primary__subheader">
                    <strong>Upload Documents</strong>
                    {this.props.renderChangeLink(this.props.redirectToMpa)}
                </div>
                <div className="row">
                    {this.renderUploadDocumentRow('ProofOfAddress', 'Proof of Address')}
                    {this.renderUploadDocumentRow('SignaturePages', 'Signature')}
                    {this.renderUploadDocumentRow('VoidedCheck', 'Voided Check')}
                    {this.renderUploadDocumentRow('CompanyLogo', 'Marketing')}
                    {this.renderUploadDocumentRow('MerchantStatement', 'Recent Processing Statements')}
                    {this.renderUploadDocumentRow('BankStatements', 'Bank Statements')}
                    {this.renderUploadDocumentRow('TaxExemption', 'Tax Exempt Docs')}
                    {this.renderUploadDocumentRow('CorporateDocs', 'Corp Docs')}
                    {this.renderDriversLicense()}
                </div>
            </Fragment>
        );
    }
    renderDriversLicense=()=> {}

    renderStateAndZip(mpa, addressField) {
        return (
            <React.Fragment>
                {this.renderField(mpa, `${addressField}.state`, 'State')}
                {this.renderField(mpa, `${addressField}.zip`, 'ZIP')}
            </React.Fragment>
        )
    }

    renderMailingAddress(mailingAddressSameAsLocationAddress) {
        const { mpa } = this.props;
        const { streetAddress, city, state, zip } = mpa.corporateAddress;
        const showMailingAddress = !mailingAddressSameAsLocationAddress && (streetAddress || city || state || zip)
        if (!showMailingAddress) return null;
        return (
            <Fragment>
                <div className="row grid-align-middle">
                    {this.renderField(mpa, 'corporateAddress.streetAddress', 'Street Address')}
                    {this.renderField(mpa, 'corporateAddress.city', 'City')}
                    {this.renderStateAndZip(mpa, 'corporateAddress')}
                </div>
            </Fragment>
        )
    }

    renderLastFourOrValue = value => {
        if (this.props.displayLastFourOnly && value) {
            return `**** ${value.slice(-4)}`;
        }

        return value;
    }

    renderRoutingNumber(mpa, fieldName, labelText) {
        return this.renderField(mpa, fieldName, labelText, this.renderLastFourOrValue, 'fs-mask');
    }

    renderSocialSecurityNumber(mpa) {
        if (!this.props.showSSN) return null;
        return this.renderField(mpa, 'signerInformation.socialSecurityNumber', 'Social Security Number', undefined, 'fs-mask');
    }

    renderAdditionalSignerInfo() {
        return null;
    }
    

}