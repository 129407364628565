import React from 'react';
import { filter, toLower, includes } from 'lodash';
import PropTypes from 'prop-types';

import { EquipmentTabComponent, GatewayListComponent, HardwareListComponent, AccessoriesListComponent, PosSystemListComponent } from '.';
import SoftwareListComponent from './software-list';
import VarListComponent from './var-list';

class EquipmentComponent extends React.Component {
    state = { isExpanded: false }

    getHardware = data => {
        return filter(data, ({ category }) => toLower(category) !== 'accessories');
    };

    getAccessories = data => {
        return filter(data, ({ category }) => toLower(category) === 'accessories');
    };

    optionIsAvailableForRules = (opt, merchantEquipment) => {
        let isAvailable = true;
        opt.rules && opt.rules.every((r) => {
            switch (r.ruleType) {
                case 'app': //implemented on API
                    break;
                case 'equipment':
                    isAvailable = this.optionIsAvailableForEquipmentRules(r, merchantEquipment);
                    break;
                case 'srv_setting':
                    isAvailable = this.optionIsAvailableForSrvSettingRules(r, merchantEquipment);
                    break;
                default:
                    return true;
            }
          return isAvailable;
      })
        opt.isNotAvailable = !isAvailable;
        return isAvailable;
    }

    optionIsAvailableForEquipmentRules = (rule, merchantEquipment) => {
        const { fieldName, fieldValues, showSetting } = rule;
        let hasValue = false;
        switch (fieldName) {
            case 'ptype':
                hasValue = includes(fieldValues, merchantEquipment.purchaseType)
                break;
            default:
                return true;
        }
        if (hasValue) {
            return showSetting;
        }
        else {
            return !showSetting;
        }
    }
    optionIsAvailableForSrvSettingRules = (rule, merchantEquipment) => {
        const { fieldName, fieldValues, showSetting } = rule;
        let selectedEquipMatchesRule = false;
        selectedEquipMatchesRule = merchantEquipment.equipmentOptions[fieldName] && includes(fieldValues, merchantEquipment.equipmentOptions[fieldName]);
        if (selectedEquipMatchesRule) {
            return showSetting;
        }
        else {
            return !showSetting;
        }
    }

    render() {
        const {
            hardwareList,
            merchantEquipment,
            merchantPosSystemList,
            softwareList,
            varList,
            appId,
            handleEquipmentChange,
            handleListChange,
            openCloseModal,
            createNewEquipment,
            selectedEquipmentId,
            onShowEquipment,
            downloadPrefilledVarSheet,
            isEApp,
            fixTab,
            selectedTab: currentTab,
            merchant,
            downloadPrefilledAuthorizationForm,
            getAuthorizationFormName,
        } = this.props;

        if (!merchant) return null;

        return (
            <div>
                {!fixTab && currentTab && <EquipmentTabComponent currentTab={currentTab} onTabSelect={this.props.onSelectTab}    />}
                        {
                    currentTab == 'gateways' ?
                        this.props.gatewayList &&
                        <GatewayListComponent gatewayList={this.props.gatewayList}
                            merchantEquipment={this.props.merchantEquipment}
                            appId={this.props.appId}
                            onChange={this.props.handleEquipmentChange}
                            onListChange={this.props.handleListChange}
                            openCloseModal={this.props.openCloseModal}
                            createNewEquipment={this.props.createNewEquipment}
                            selectedEquipmentId={this.props.selectedEquipmentId}
                            onShowEquipment={this.props.onShowEquipment}
                            isEApp={isEApp}
                            downloadPrefilledGatewaySetupForm={this.props.downloadPrefilledGatewaySetupForm}
                        />

                        : currentTab === 'hardware' ?
                            this.props.hardwareList && 
                            <HardwareListComponent
                                hardwareList={this.getHardware(hardwareList)}
                                merchantEquipment={this.getHardware(merchantEquipment)}
                                appId={this.props.appId}
                                physicalAddress={merchant.businessAddress}
                                corporateAddress={merchant.corporateAddress}
                                onChange={this.props.handleEquipmentChange}
                                createNewEquipment={this.props.createNewEquipment}
                                openCloseModal={this.props.openCloseModal}
                                selectedEquipmentId={this.props.selectedEquipmentId}
                                onShowEquipment={this.props.onShowEquipment}
                                downloadPrefilledAuthorizationForm={downloadPrefilledAuthorizationForm}
                                getAuthorizationFormName={getAuthorizationFormName}
                                optionIsAvailableForRules={this.optionIsAvailableForRules}
                            />
                        : currentTab === 'accessories' ? this.props.hardwareList &&
                            <AccessoriesListComponent
                                accessoriesList={this.getAccessories(hardwareList)}
                                merchantEquipment={this.getAccessories(merchantEquipment)}
                                appId={this.props.appId}
                                physicalAddress={merchant.businessAddress}
                                corporateAddress={merchant.corporateAddress}
                                onChange={this.props.handleEquipmentChange}
                                createNewEquipment={this.props.createNewEquipment}
                                openCloseModal={this.props.openCloseModal}
                                selectedEquipmentId={this.props.selectedEquipmentId}
                                onShowEquipment={this.props.onShowEquipment}
                                isEApp={isEApp}
                                optionIsAvailableForRules={this.optionIsAvailableForRules}

                            />
                        : currentTab === 'posSystems' ?
                            <PosSystemListComponent
                                merchantPosSystemList={merchantPosSystemList}
                                onChange={this.props.handlePosSystemChange}
                                clearMerchantPosSystemList={this.props.clearMerchantPosSystemList}
                            />
                        : currentTab === 'software' && softwareList ?
                        <SoftwareListComponent
                            softwareList={softwareList}
                            merchantEquipment={merchantEquipment}
                            appId={appId}
                            onChange={handleEquipmentChange}
                            onListChange={handleListChange}
                            openCloseModal={openCloseModal}
                            createNewEquipment={createNewEquipment}
                            selectedEquipmentId={selectedEquipmentId}
                            onShowEquipment={onShowEquipment}
                        />
                        : currentTab === 'other' &&
                        (
                            <React.Fragment>
                                {varList && (
                                    <VarListComponent
                                        varList={varList}
                                        merchantEquipment={this.props.merchantEquipment}
                                        appId={this.props.appId}
                                        onChange={this.props.handleEquipmentChange}
                                        onListChange={this.props.handleListChange}
                                        openCloseModal={this.props.openCloseModal}
                                        createNewEquipment={this.props.createNewEquipment}
                                        selectedEquipmentId={this.props.selectedEquipmentId}
                                        onShowEquipment={this.props.onShowEquipment}
                                        downloadPrefilledVarSheet={downloadPrefilledVarSheet}
                                    />
                                )}
                            </React.Fragment>
                        )
                        }
                </div>
        );
    }
}
EquipmentComponent.propTypes = {
    appId: PropTypes.string.isRequired,
    selectedTab: PropTypes.string,
    onSelectTab: PropTypes.func.isRequired,
    onShowEquipment: PropTypes.func.isRequired,
    downloadPrefilledVarSheet: PropTypes.func.isRequired,
    downloadPrefilledAuthorizationForm: PropTypes.func.isRequired,
    getAuthorizationFormName: PropTypes.func.isRequired,
    isEApp: PropTypes.bool,
    fixTab: PropTypes.bool,
}
EquipmentComponent.defaultProps = {
    selectedTab: 'gateways'
}
export default EquipmentComponent;