import React, { Fragment } from "react";
import { replace, isNumber, isNaN } from 'lodash'
import PropTypes from 'prop-types';

class MaskedValueInputWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isReadOnly: true,
        };
    }

    ChangeMaskedValue = () => {
        this.setState({ isReadOnly: false });
        if(this.props.resetValue) {
            this.props.resetValue();
        }
    }

    render() {
        return (
            <Fragment>
                {
                    this.props.maskedValue && this.state.isReadOnly ?
                        (
                            <div>
                                <input className={this.props.inputClass} disabled type="text" value={replace(this.props.maskedValue, new RegExp('\\*','g'), 'X')} style={{width: '80%'}}/>
                                <button className="btn btn--grid spc--left--tny" onClick={this.ChangeMaskedValue}>Change</button>
                            </div>
                        )
                    : (this.props.children)
                }
            </Fragment>
        );
    }
}

MaskedValueInputWrapper.propTypes = {
    versionIndex: PropTypes.number,
    maskedValue: PropTypes.string,
    inputClass: PropTypes.string,
    resetValue: PropTypes.func,
    children: PropTypes.object,
}

export default MaskedValueInputWrapper;