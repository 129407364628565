import { includes, toLower } from 'lodash'
export function canPreviewFile(fileName) {
    const fileExtension = toLower(getExtension(fileName))
    const extensions = [
        '.png',
        '.jpg',
        '.jpeg',
        '.jpe',
        '.jfif',
        '.jfi',
        '.svg',
        '.gif',
        '.pdf',
        '.mp4',
        '.m4a',
        '.webm',
        '.webp',
        '.mp3',
        '.flac',
        '.wav',
        '.txt']
    return includes(extensions, fileExtension)
}

function getExtension(fileName) {
    return '.' + fileName.split('.').pop();
}
