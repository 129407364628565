import { cloneDeep } from 'lodash';
import { isElavonCad, isFdCad } from "../common/utilities/processorType";

import { setupFormTemplate, setupFormInvalidTemplate } from './setupform.validation';

export const canadaSetupFormTemplate = cloneDeep(setupFormTemplate);

const isPositiveNumber = val => !val || val >= 0;

const arrProcessingMethods = (ctx) => [ctx.processingDetails.swipedTransactionsPercentage, ctx.processingDetails.keyedTransactionsPercentage, ctx.processingDetails.internetTransactionsPercentage].sort(function (a, b) { return b - a });

const processingMethods2 = (val, ctx) => {
    const sortedPercentages = arrProcessingMethods(ctx);
    return (ctx.processingDetails.internetTransactionsPercentage || ctx.processingDetails.keyedTransactionsPercentage || ctx.processingDetails.swipedTransactionsPercentage) && sortedPercentages[0] !== sortedPercentages[1];
}

const min = minimumValue => val => (val === null || val === undefined) || val >= minimumValue

const pciMonthlyFeeRequired = (val, ctx) =>  isFdCad(ctx) ? true : !!val
const pciMonthlyFeeMin = minimumValue => (val, ctx) =>  isFdCad(ctx) ? true : val >= minimumValue

canadaSetupFormTemplate.eidsEnrollmentDetails = null;
canadaSetupFormTemplate.amexDetails.pricingStructureType = null;


canadaSetupFormTemplate.otherProcessorReason.use.reason = (val, ctx) => isElavonCad(ctx) ? true : !!val;

canadaSetupFormTemplate.proposedFees.pciMonthlyFee = {
    use: { pciMonthlyFeeRequired },
    message: {
        pciMonthlyFeeRequired: '[**PCI Monthly Fee**](javascript:void) is required'
    }
}

canadaSetupFormTemplate.pricingStructure.shouldSetupPinDebit = {
    type: String,
    required: true,
    message: '[**Setup merchant with Pin Debit**](javascript:void) is required'
};

canadaSetupFormTemplate.processingDetails.internetTransactionsPercentage = {
    type: Number,
    use: { processingMethods2 },
    message: {
        processingMethods2: '[**Processing Methods**](javascript:void) must have one processing method percentage that is higher than the other processing method percentages'
    }
};

canadaSetupFormTemplate.pricingStructure.debitRefundCap = {
    use: { isPositiveNumber },
    type: String,
    message: {
        isPositiveNumber: '[**Debit Refund Cap**](javascript:void) cannot be negative'
    }
};
export const canadaSetupFormInvalidTemplate = cloneDeep(setupFormInvalidTemplate);
canadaSetupFormInvalidTemplate.proposedFees = {
    monthlyMinimumFee: {
        use: { min: min(15) },
        message: {
            min: '[**Monthly Minimum Fee**](javascript:void) must be greater than or equal $15',
        }
    }, statementFee: {
        use: { min: min(5) },
        message: {
            min: '[**Statement Fee**](javascript:void) must be greater than or equal $5',
        }
    }, chargebackFee: {
        use: { min: min(25) },
        message: {
            min: '[**Chargeback Fee**](javascript:void) must be greater than or equal $25',
        }
    }, pciMonthlyFee: {
        use: { min: pciMonthlyFeeMin(8.95) },
        message: {
            min: '[**PCI Monthly Fee**](javascript:void) must be greater than or equal $8.95',
        }
    }, applicationFee: {
        use: { min: min(25) },
        message: {
            min: '[**Application Fee**](javascript:void) must be greater than or equal $25',
        }
    }
};

