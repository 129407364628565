import React, { Component, Fragment } from "react";
import { decode } from "jsonwebtoken";
import { get, toLower } from "lodash"
import { appService, signatureService } from "../../services";
import ClickWrapComponent from "./../EApp/Clickwrap";
import { withLoader } from "../../common/components";
import principalService from "../../services/principalService";

class MerchantAgreement extends Component {
    constructor(props){
        super(props)
        let token = this.props.location.search.substr(1);
        if (token) {
            principalService.set({ agentId: 0, name: '', emailAddress: '', token, roles: ['Merchant'] });
        }
        const decodedToken = decode(token, { complete: true });
        this.state = {
            token: token,
            downloadUrl: null,
            isSubmitted: null,
            wasSubmitted: false,
            isExpired: false,
            appId: get(decodedToken, "payload.nameid", null),
            certserialnumber: get(decodedToken, "payload.certserialnumber", null),
            error: null
        }
    }

    componentDidMount = async () => {
        this.props.showLoader(true);
        await this.loadMPAFromService();
        this.props.showLoader(false);
    }

    loadMPAFromService = async () => {
        try {
            await appService.getEAppForMerchant(this.state.token);
            this.setState({ isSubmitted: false });
        }
        catch (err) {
            let error = 'An error occurred: ' + err;
            if (toLower(err).startsWith("signature documents have already been submitted successfully")) {
                this.setState({ isSubmitted: false, wasSubmitted: true });
                return;
            }
            if (toLower(err) === "unauthorized" || err === 401) {
                if (decode(this.state.token, { complete: true }).payload.actort) {
                    this.setState({
                        isExpired: true
                    });
                    return;
                }
                error = "The link you followed has expired. Please reach out to your agent to request a new link."
            }
            this.setState({ error, isSubmitted: false });
        }
    }

    setInfoToApp = async (url) => {
        this.setState({downloadUrl: url})
    }

    handleConfirmTerms = async  () => {
        const { downloadUrl, appId, certserialnumber } = this.state;
        try {
            this.props.showLoader(true);
            await appService.updateSignatureStatus(appId, certserialnumber, 'n/a', 'Signed');
            await signatureService.downloadAndSaveSignedDocument({ appId: appId, dba: '' }, ['FDGoConfirmation'], downloadUrl, undefined, true);
            this.setState({ isSubmitted: true });
        } 
        catch (e) {
            this.setState({error: e})
        }
        finally{
            this.props.showLoader(false);
        }
    }

    handleSubmitError = (e) => 
    {
        this.setState({error: e})
    }

    renderAlreadySubmitted = () => {
        return (
            <div className="note note--success">
                Terms and conditions have been accepted already.
            </div>
        )
    }

    renderLoading = () => {
        return (
            <div>
                Loading....
            </div>
            )
    }

    renderAgreement = isSubmitted => {
        return (
            <Fragment>
                {!isSubmitted ? this.renderTermsAndConditions() :
                    <div className="note note--success">
                        Thank you, your signature has been received!
                    </div>
                }
            </Fragment>
         )
    } 

    renderTermsAndConditions = () => {
        const { appId, error, downloadUrl, wasSubmitted } = this.state;
        return (
            <Fragment>
                {wasSubmitted ? this.renderAlreadySubmitted() :
                    <div id="MerchantAgreement" className="membership__section w--max--970">
                        <ClickWrapComponent
                            groupKey='group-goplus'
                            appId={appId.toString()}
                            setInfoToApp={this.setInfoToApp}
                            handleError={this.handleSubmitError}
                            allowDisagreed={true}
                        />
                        <div className="flex--tertiary">
                            <button type="button" className="btn btn--primary btn--med align--h--right" onClick={this.handleConfirmTerms} disabled={!downloadUrl || this.props.isLoading}>Continue</button>
                        </div>
                        {error && <div id="submit_error">
                            <span>
                                {`Error: ${get(error, "message")}`}
                            </span>
                        </div>
                        }
                    </div>
                }
            </Fragment>
        )
    }

    renderData(isLoaded, isSubmitted) {
        return (
            <Fragment>
                {!isLoaded ? this.renderLoading() : this.renderAgreement(isSubmitted)}
            </Fragment>
        )
    }

    render(){
        const { isSubmitted, error } = this.state;
        const isLoaded = isSubmitted != null;
        return (
            <div id="main-div" className="l--content">
                {
                    error ? (
                        <div className="note note--warning" ref={this.errorRef}>
                            {error}
                        </div>
                    ) : this.renderData(isLoaded, isSubmitted)
                }
            </div>
        )
    }
}

export default withLoader(MerchantAgreement);