import React from 'react';
import { includes } from 'lodash';

class ContactInfo extends React.Component {
    state = { isExpanded: true }
    
    toggleExpand = () => {
        this.setState({ isExpanded: !this.state.isExpanded });
    }
    
    handleChange = (e) => {
        const { name, value } = e.target;
        this.props.onChange('contactInfo', name, value, null);
    }

    render() {
        const { errorListPaths, validateFieldOnBlur, contactInfo: info } = this.props;
        
        return (
            <div className="form--popup">
                <div  className={`flex--primary ${this.state.isExpanded ? 'separator--grey1 spc--bottom--med' : ''}`}>
                    <h6 className="spc--bottom--sml cursor--pointer" onClick={this.toggleExpand}>Additional Contact Information</h6>
                    <i className={`spc--left--auto cursor--pointer icon icon--arrow icon--tiny icon--arrow--down--primary card--expand ${this.state.isExpanded ? 'rotate--180' : ''}`}  onClick={this.toggleExpand} href="javascript:void(0)"></i>
                </div>
                <div className={`details__content ${this.state.isExpanded ? '' : 'display--n'}`}>
                    <div className="row">
                        <div className="col col-sml-12 col-lrg-6">
                            <div className="form__field spc--bottom--med">
                                <label className="label">Name:</label>
                                <input disabled={this.props.disabled} type="text" className="input input--med" name="alternateContactName" id="contactInfo_alternateContactName" value={info.alternateContactName} onChange={this.handleChange}></input>                               
                            </div>
                        </div>
                        <div className="col col-sml-12 col-lrg-6">
                            <div className="form__field spc--bottom--med">
                                <label className="label">Title:</label>
                                <input disabled={this.props.disabled} type="text" className="input input--med" name="contactTitle" id="contactInfo_contactTitle" value={info.contactTitle} onChange={this.handleChange}></input>
                            </div>
                        </div>                      
                    </div>

                    <div className="row">
                        <div className="col col-sml-12 col-lrg-6">
                            <div className="form__field">
                                <label className="label">Phone Number:</label>
                                <div className={`inputgroup${includes(errorListPaths, 'contactInfo.contactPhone') ? ' is-invalid' : ''}`}>
                                    <div className="inputgroup--aside inputgroup--aside--bordered inputgroup--aside--bordered--reverse">
                                        <div className="fakeinput fakeinput--form fakeinput--med">+1</div>
                                    </div>
                                    <div className="inputgroup--main inputgroup--main--bordered inputgroup--main--bordered--reverse">
                                        <input disabled={this.props.disabled} type="text" className="input input--med" name="contactPhone" id="contactInfo_contactPhone" value={info.contactPhone} onChange={this.handleChange} onBlur={validateFieldOnBlur}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ContactInfo;