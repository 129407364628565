import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { trim, toLower, some, toUpper } from 'lodash';
import { principalService } from '../../services';
import { PasswordInput } from '../../common/components/password-input';
import { getRedirectRoute } from './loginUtils';

const specialCharactersRegex = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

class ConfirmNewPasswordComponent extends Component {
	constructor(props) {
		super(props);

		const username = props.location.state.username ? props.location.state.username : '';

		this.state = {
			username: username,
			password: '',
			password2: '',
			code: '',
			errorMessage: null,
		};
	}

	componentWillMount = () => {
		if (principalService.get()) {
			this.redirect();
		}
	};

	setErrorMessage = errorMessage => {
		this.setState({ errorMessage });
	};

	handleChange = event => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	handleSubmit = async event => {
		event.preventDefault();
		if (!this.formValidation()) {
			return;
		}

		const { history } = this.props;
		const { password } = this.state;
		let { username, code } = this.state;
		username = trim(toLower(username));
		code = trim(code);

		Auth.forgotPasswordSubmit(username, code, password)
			.then(() => {
				history.push({
					pathname: '/login',
					state: { username: username },
				});
			})
			.catch(err => {
				let message;
				switch (err && err.code) {
					case 'CodeMismatchException': {
						message = 'The provided code does not match the expected value.';
						break;
					}
					case 'ExpiredCodeException': {
						message = 'The provided code has expired.';
						break;
					}
					case 'InvalidParameterException': {
						message = err.message;
						break;
					}
					case 'UserNotFoundException': {
						message = 'User with provided email does not exist.';
						break;
					}
					case 'InvalidPasswordException': {
						message =
							'Your password needs to be at least 8 characters long and contain at least one uppercase character, one lowercase character, one special character and one number.';
						break;
					}
					default: {
						message = 'Something went wrong. Please try again.';
						break;
					}
				}
				this.setState({
					errorMessage: message,
				});
			});
	};

	resendConfirmationCode = async () => {
		let { username } = this.state;
		username = trim(toLower(username));
		let { history } = this.props;

		Auth.forgotPassword(username).catch(err => {
			let message;
			switch (err && err.code) {
				case 'UserNotConfirmedException': {
					history.push({
						pathname: '/confirm-registration',
						state: { username: username },
					});
					break;
				}
				case 'UserNotFoundException': {
					message = 'User with provided email does not exist.';
					break;
				}
				case 'LimitExceededException': {
					message = 'Attempt limit exceeded, please try after some time.';
					break;
				}
				default: {
					message = 'Something went wrong. Please try again.';
					break;
				}
			}
			//eslint-disable-next-line
			console.log(err);
			this.setState({
				errorMessage: message,
			});
		});
	};

	formValidation = () => {
		const { username, password, password2, code } = this.state;

		if (username.length <= 0) {
			this.setState({
				errorMessage: 'Please enter your email',
			});
			return false;
		}

		if (password.length <= 0 || password2.length <= 0) {
			this.setState({
				errorMessage: 'Please enter your password',
			});
			return false;
		}

		if (code.length <= 0) {
			this.setState({
				errorMessage: 'Please enter code',
			});
			return false;
		}

		if (password !== password2) {
			this.setState({
				errorMessage: 'Passwords do not match',
			});
			return false;
		}

		if (password.length < 8) {
			this.setState({
				errorMessage: 'Your password needs to contain at least 8 characters',
			});
			return false;
		}

		if (!some(password, char => char === toUpper(char))) {
			this.setState({
				errorMessage: 'Your password needs to contain at least one uppercase character',
			});
			return false;
		}

		if (!some(password, char => char === toLower(char))) {
			this.setState({
				errorMessage: 'Your password needs to contain at least one lowercase character',
			});
			return false;
		}

		if (!some(password, char => !isNaN(parseInt(char)))) {
			this.setState({
				errorMessage: 'Your password needs to contain at least one number',
			});
			return false;
		}

		if (!specialCharactersRegex.test(password)) {
			this.setState({
				errorMessage: 'Your password needs to contain at least one special character',
			});
			return false;
		}

		return true;
	};

	redirectToLogin = () => {
		const { history } = this.props;
		history.push('/login');
	};

	redirectToForgotPassword = () => {
		const { history } = this.props;
		history.push('/forgot-password');
	};

	redirect = () => {
		const { history, location } = this.props;
		let redirectUrl = getRedirectRoute();

		if (location.state && location.state.returnUrl) {
			redirectUrl = location.state.returnUrl;
		}

		history.push(redirectUrl);
	};

	render = () => {
		const { username, password, password2, code, errorMessage } = this.state;

		return (
			<form className="auth__form" onSubmit={this.handleSubmit}>
				<h2 className="auth__form__title">Set new password</h2>

				<div className="auth__form__description flex--primary">
					<p className="spc--right--tny">Do you have an account?</p>
					<button type="button" onClick={this.redirectToLogin} className="btn btn--sml btn--link">
						Sign in
					</button>
				</div>

				<div className="form__group">
					<div className="form__group__header">
						<label className="form__group__label">Email address</label>
					</div>
					<input
						name="username"
						disabled={true}
						type="text"
						className="input input--med"
						placeholder="user@gmail.com"
						value={username}
						onChange={this.handleChange}
						tabIndex="1"
					/>
				</div>
				<div className="form__group">
					<div className="form__group__header">
						<label className="form__group__label">
							Code<span className="spc--right--med spc--left--xsml">-</span>
							<span>Email with code has been sent</span>
						</label>
					</div>
					<input
						name="code"
						type="text"
						className="input input--med"
						placeholder="000000"
						value={code}
						onChange={this.handleChange}
						tabIndex="2"
					/>
				</div>
				<div className="form__group">
					<div className="form__group__header">
						<label className="form__group__label">New password</label>
					</div>
					<PasswordInput
						value={password}
						onChange={this.handleChange}
						tabIndex="3"
						setErrorMessage={this.setErrorMessage}
					/>
				</div>
				<div className="form__group">
					<div className="form__group__header">
						<label className="form__group__label">Confirm Password</label>
					</div>
					<PasswordInput
						placeholder="Confirm your password"
						name="password2"
						value={password2}
						onChange={this.handleChange}
						tabIndex="4"
						setErrorMessage={this.setErrorMessage}
					/>
					{errorMessage ? (
						<div className="spc--top--sml spc--bottom--med type--validation spc--bottom--lrg">{errorMessage}</div>
					) : null}
				</div>

				<div className="flex--primary flex--column flex--gap--sml">
					<button type="submit" className="btn btn--lrg btn--primary fullwidth" tabIndex="5">
						Save new password
					</button>
					<button
						type="button"
						className="btn btn--lrg btn--secondary fullwidth"
						onClick={this.resendConfirmationCode}
						tabIndex="6"
					>
						Resend code
					</button>
					<button
						type="button"
						className="btn btn--lrg btn--link fullwidth"
						onClick={this.redirectToForgotPassword}
						tabIndex="7"
					>
						Back
					</button>
				</div>
			</form>
		);
	};
}

ConfirmNewPasswordComponent.propTypes = {
	history: PropTypes.object,
	location: PropTypes.object,
};

export default ConfirmNewPasswordComponent;
