import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';
import { replace, get, some } from 'lodash';
import { AddressComponent } from '../../common/components/address';
import { CheckboxTxtInput } from '../../common/components/checkbox-text-input';
import { FileDropzoneComponent } from '../../common/components/file-dropzone';
import { Toggle, ToggleContainer } from '../../common/components/toggle';
import { canPreviewFile } from '../../common/utilities/canPreviewFile';
import { OwnershipDropdownComponent } from '../../common/components/ownership-dropdown';
import SingleDatePicker from '../../common/filters/date-picker/single-date-picker';
import ZeroAsEmptyNumberFormat from '../../common/components/zero-as-empty-number-format/ZeroAsEmptyNumberFormat';
import { fileTypes } from '../../common/components/file-dropzone/fileTypeConstants';

const allowedFileTypes = [fileTypes.jpeg, fileTypes.png, fileTypes.bmp, fileTypes.txt, fileTypes.pdf];
class BaseMPA extends Component {
	dotRegex = /\./g;
	startDateRef = createRef();
	dateOfBirthRef = createRef();
	fixFieldName = field => replace(field, this.dotRegex, '_');

	render() {
		const { mpa, mpafiles } = this.props;
		return (
			<ToggleContainer>
				{this.renderToggleableSection((isToggled, handleToggle) =>
					this.renderBusinessInfo(mpa, isToggled, handleToggle)
				)}
				{this.renderToggleableSection((isToggled, handleToggle) =>
					this.renderMailingInfo(mpa, isToggled, handleToggle)
				)}
				{this.renderToggleableSection((isToggled, handleToggle) =>
					this.renderAdditionalInformation(mpa, isToggled, handleToggle)
				)}
				{this.props.isMerchant &&
					this.renderUploadSingleDocumentType('VoidedCheck', 'Add Voided Check', false, 'Check Name', true)}
				{this.renderToggleableSection((isToggled, handleToggle) =>
					this.renderSignerInformation(mpa, isToggled, handleToggle)
				)}
				{this.renderToggleableSection((isToggled, handleToggle) =>
					this.renderAdditionalPaymentDetails(mpa, isToggled, handleToggle)
				)}
				{this.props.isMerchant &&
					this.renderUploadSingleDocumentType('BankingStatement', 'Attach Bank Statements', true)}
				{!this.props.isMerchant &&
					this.renderToggleableSection((isToggled, handleToggle) =>
						this.renderUploadDocuments(mpafiles, isToggled, handleToggle)
					)}
				{this.renderToggleableSection((isToggled, handleToggle) => this.renderOtherNotes(mpa, isToggled, handleToggle))}
			</ToggleContainer>
		);
	}

	renderFilesForTag(tag) {
		let files = this.props.getFilesForTag(tag);
		if (files && files.length > 0) {
			return (
				<React.Fragment>
					<p className="type--p3 type--p3--medium spc--top--med">Already Uploaded:</p>
					<div className="upload__list">
						{files.map(file => {
							return (
								<div className="upload__list__item" key={file.fileId}>
									<p className="type--p2">{file.fileName}</p>
									<div className="flex--primary flex--gap--sml align--h--right">
										{canPreviewFile(file.fileName) && (
											<button
												className="btn btn--link datatooltip--left datatooltip--auto"
												data-tooltip="View"
												onClick={this.props.handleFileDownload(
													file.parentId,
													file.fileId,
													file.fileName,
													this.props.showLoader
												)}
												disabled={this.props.disableDelete}
											>
												<i className="icon icon--sml icon--view--light"></i>
											</button>
										)}
										<button
											className="btn btn--link datatooltip--left datatooltip--auto"
											data-tooltip="Download"
											onClick={this.props.handleFileDownload(
												file.parentId,
												file.fileId,
												file.fileName,
												this.props.showLoader,
												true
											)}
											disabled={this.props.disableDelete}
											download
										>
											<i className="icon icon--sml icon--download--light"></i>
										</button>
										<button
											className="btn btn--link datatooltip--left datatooltip--auto"
											data-tooltip="Delete"
											onClick={this.props.handleFileDelete(file.parentId, file.fileId, file.fileName, file.fileTag)}
											disabled={this.props.disableDelete}
										>
											<i className="icon icon--sml icon--delete--light"></i>
										</button>
									</div>
								</div>
							);
						})}
					</div>
				</React.Fragment>
			);
		}
	}

	renderCardHeader(title, isToggled, handleToggle, tooltip) {
		return (
			<button
				className={`card__header${!this.props.isMerchant ? ' card__header--expandable' : ''}`}
				onClick={handleToggle}
			>
				<div className="flex--primary flex--gap--sml">
					<h5>{title}</h5>
					{tooltip && <i className="icon icon--sml icon--regular--info" data-tooltip={tooltip}></i>}
				</div>
				{!this.props.isMerchant && (
					<i className={`icon icon--lrg icon--chevron--${isToggled ? 'top' : 'right'}--primary`}></i>
				)}
			</button>
		);
	}

	renderFileDropZone(tag) {
		return (
			<FileDropzoneComponent
				tag={tag}
				multiple={true}
				fileList={this.props.files}
				onDrop={this.props.onDropFile}
				onRemoveFile={this.props.handleRemoveFile}
				validateFileSize={false}
				allowedFileTypes={allowedFileTypes}
			/>
		);
	}

	renderToggleableSection(renderSection) {
		return (
			<Toggle initialToggle={true}>{({ isToggled, handleToggle }) => renderSection(isToggled, handleToggle)}</Toggle>
		);
	}

	renderOtherNotes(mpa, isToggled, handleToggle) {
		return (
			<div className={`card${isToggled ? ' is-expanded' : ''} spc--bottom--lrg`}>
				{this.renderCardHeader(
					'Other Notes',
					isToggled,
					handleToggle,
					this.props.isMerchant ? undefined : 'This will be visible to merchants.'
				)}
				{isToggled && (
					<div className="card__body">
						<textarea
							placeholder="Notes"
							className="input input--textarea"
							cols={50}
							rows={5}
							name="accountNotes"
							value={mpa.accountNotes}
							onChange={this.props.handleChange}
						></textarea>
					</div>
				)}
			</div>
		);
	}

	renderUploadDocumentRow(tag, label, tooltip, showSeparator = true, id = null) {
		const requiredDocs = this.props.getRequiredDocs();
		const required = some(requiredDocs, file => file.fileTag === tag);

		return (
			<Toggle initialToggle={false}>
				{({ isToggled, handleToggle }) => (
					<div className="card__upload__item" id={id}>
						<button className="card__upload__header" onClick={handleToggle}>
							<div className="flex--primary flex--gap--sml">
								<div className="flex--primary">
									<span className="type--p2">{label}</span>
									{required && (
										<span className="type--p2 form__group__required" data-tooltip="Required">
											*
										</span>
									)}
								</div>
								{tooltip && (
									<i className="icon icon--sml icon--regular--info datatooltip--200" data-tooltip={tooltip} />
								)}
							</div>
							<i className={`icon icon--sml icon--chevron--${isToggled ? 'top' : 'right'}--light`}></i>
						</button>
						{isToggled && (
							<div className="card__upload__body">
								{this.renderFileDropZone(tag)}
								{this.renderFilesForTag(tag)}
							</div>
						)}
					</div>
				)}
			</Toggle>
		);
	}

	renderUploadDocuments(mpafiles, isToggled, handleToggle) {
		return (
			<div className={`card${isToggled ? ' is-expanded' : ''} spc--bottom--lrg`}>
				{this.renderCardHeader('Upload Documents', isToggled, handleToggle)}
				{isToggled && (
					<div className="card__body">
						{mpafiles && mpafiles.length > 0 ? (
							<div className="spc--bottom--lrg">
								<h6 className="spc--bottom--lrg">Signature Documents</h6>
								{this.renderFilesForTag(['SignedMPA', 'SignaturePages'])}
							</div>
						) : null}
						<div className="card__upload">
							{this.renderUploadDocumentRow(
								'BankStatements',
								'Bank Statements',
								'Please include your three most recent bank statements'
							)}
							{this.renderUploadDocumentRow('VoidedCheck', 'Voided Check', 'Or Bank Letter', true, 'voidedcheck_div')}
							{this.renderUploadDocumentRow('ProofOfAddress', 'Proof of Address', 'Utility bill or Lease Agreement')}
							{this.renderUploadDocumentRow('TaxExemption', 'Tax Exempt Docs')}
							{this.renderUploadDocumentRow('CorporateDocs', 'Corp Docs', undefined, undefined, 'corporatedocs_div')}
							{this.renderUploadDocumentRow('MerchantStatement', 'Recent Processing Statement')}
							{this.renderUploadDocumentRow('CompanyLogo', 'Marketing', 'Flyer, Brochure or Company Website', false)}
						</div>
					</div>
				)}
			</div>
		);
	}

	renderUploadSingleDocumentType(tag, label, multiple, descriptionPlaceholder, required) {
		return (
			<div className="card is-expanded spc--bottom--lrg">
				{this.renderCardHeader(label, true)}
				<div className="card__body">
					<div className="form__group__header">
						<p className="form__group__label">Upload File: </p>
						{required && (
							<span className="form__group__required" data-tooltip="Required">
								*
							</span>
						)}
					</div>
					<FileDropzoneComponent
						multiple={multiple}
						showDescription={!!descriptionPlaceholder}
						descriptionPlaceholder={descriptionPlaceholder}
						tag={tag}
						fileList={this.props.files}
						onDrop={this.props.onDropFile}
						onRemoveFile={this.props.handleRemoveFile}
						onUpdateDescription={this.props.handleUpdateFileDescription}
						validateFileSize={false}
						allowedFileTypes={allowedFileTypes}
					/>
					{this.renderFilesForTag(tag)}
				</div>
			</div>
		);
	}

	renderTransactionInformationField(form, field, label) {
		const name = this.fixFieldName(field);
		let value = get(form, field);

		let componentProps = {
			className: 'input input--med',
			name: name,
			value: value,
			placeholder: '$0',
			thousandSeparator: true,
			fixedDecimalScale: false,
			prefix: '$',
			decimalScale: 2,
			onValueChange: ({ floatValue }) =>
				this.props.handleChange({
					target: {
						value: floatValue,
						name: name,
					},
				}),
		};
		return (
			<div className="col col-sml-12 col-lrg-4 form__group">
				<div className="form__group__header">
					<label className="form__group__label">{label} </label>
				</div>
				<ZeroAsEmptyNumberFormat {...componentProps} />
			</div>
		);
	}

	renderPaymentDetailField(form, checkboxName, inputName, label, placeholder) {
		return (
			<div id={`${inputName}_div`} className="col col-sml-12 col-med-6 spc--bottom--med">
				<CheckboxTxtInput
					labelText={label}
					checkboxName={checkboxName}
					inputName={inputName}
					placeholder={placeholder}
					checked={get(form, checkboxName)}
					value={get(form, inputName)}
					checkboxClass="input--check"
					inputClass={`input input--med${this.props.validateField(inputName)}`}
					labelClass="type--p2"
					format="NumberFormat"
					decimalScale={0}
					onCheckChanged={this.props.handleChange}
					onValueChanged={this.props.handleChange}
					allowNegative={false}
				/>
			</div>
		);
	}

	handleAmexEsaChange(e) {
		if (e.target.checked) {
			this.props.handleChange({ target: { name: 'amexDetails_status', value: 'Existing' } }, () => {
				this.props.handleChange({ target: { name: 'amexDetails_program', value: 'ESA' } });
			});
		} else {
			this.props.handleChange({ target: { name: 'amexDetails_status', value: '' } }, () => {
				this.props.handleChange({ target: { name: 'amexDetails_program', value: 'Unknown' } });
			});
		}
	}

	renderAmexEsa(mpa) {
		return (
			<div id={`amexDetails_esaNumber_div`} className="col col-sml-12 col-med-6 form__group">
				<div className="form__group__header spc--bottom--med">
					<div className="display--f spc--right--tny">
						<input
							type="checkbox"
							name={'amexDetails_status'}
							id={'existingEsaNumber'}
							onChange={e => this.handleAmexEsaChange(e)}
							checked={get(mpa, 'amexDetails.status') === 'Existing'}
							className="input--check"
						/>
						<label htmlFor={'existingEsaNumber'}></label>
					</div>
					<p className="type--p2">Do you have an existing Amex ESA number?</p>
				</div>
				<NumericFormat
					type={'text'}
					decimalScale={0}
					name={'amexDetails_esaNumber'}
					value={get(mpa, 'amexDetails.esaNumber')}
					disabled={get(mpa, 'amexDetails.status') !== 'Existing'}
					onChange={this.props.handleChange}
					className={`input input--med${this.props.validateField('amexDetails_esaNumber')}`}
					placeholder={'Enter ESA Number'}
					allowNegative={false}
				/>
			</div>
		);
	}

	renderAdditionalPaymentDetails(mpa, isToggled, handleToggle) {
		return (
			<div className={`card${isToggled ? ' is-expanded' : ''} spc--bottom--lrg`}>
				{this.renderCardHeader('Additional Payment Details', isToggled, handleToggle)}
				{isToggled && (
					<div className="card__body">
						<div className="row">
							{this.renderAmexEsa(mpa)}
							{this.renderPaymentDetailField(
								mpa,
								'doesAcceptEbt',
								'ebtNumber',
								'Do you have an existing EBT account number?',
								'Enter EBT Number'
							)}
						</div>
					</div>
				)}
			</div>
		);
	}

	renderSignerInformation(mpa, isToggled, handleToggle) {
		return (
			<div className={`card${isToggled ? ' is-expanded' : ''} spc--bottom--lrg`}>
				{this.renderCardHeader('Signer Information', isToggled, handleToggle)}
				{isToggled && (
					<div className="card__body">
						<div className="input--check--enable-form form__group">
							<input
								id="sameAsContactInformation"
								type="checkbox"
								className="input--check"
								onClick={this.props.copyContactInformation}
							/>
							<label htmlFor="sameAsContactInformation">Same as Contact Info</label>
						</div>
						<div className="row">
							{this.renderField(mpa, 'signerInformation.firstName', 'Signer First Name')}
							{this.renderField(mpa, 'signerInformation.lastName', 'Signer Last Name')}
						</div>

						<AddressComponent
							requiredFields={this.props.requiredFields}
							validateField={this.props.validateField}
							streetPrefix="Signer Home"
							address={mpa.signerInformation.address}
							namePrefix={'signerInformation_address'}
							onChange={this.props.handleChange}
							isCanada={mpa.isCanadian}
						/>

						<div className="row">
							{this.renderField(mpa, 'signerInformation.phoneNumber', 'Phone Number')}
							{this.renderField(mpa, 'signerInformation.cellPhoneNumber', 'Signer Cell Number')}
							{this.renderSocialSecurityNumber(mpa)}
							{this.renderDateOfBirth(mpa)}
							{this.renderIdentificationInfo(mpa)}
							{this.renderSignerAgreement(mpa)}
						</div>
					</div>
				)}
			</div>
		);
	}

	renderAdditionalInfo(mpa) {
		return (
			<div className="row">
				{this.renderField(mpa, 'goodsOrServicesDescription', 'Goods/Services Sold')}
				{this.renderField(mpa, 'businessInformation.website', 'Website')}
				<div className="col col-sml-12 col-med-6 form__group">
					<div className="form__group__header">
						<span className="form__group__label">Ownership Type </span>
						{this.props.requiredFields['businessInformation.ownershipType'] && (
							<span className="form__group__required">*</span>
						)}
					</div>
					<OwnershipDropdownComponent
						className={`input input--med input--select${this.props.validateField('businessInformation.ownershipType')}`}
						id="businessInformation_ownershipType"
						value={mpa.businessInformation.ownershipType}
						onChange={this.props.handleChange}
						ownershipOptions={[
							{ value: 'Unknown', label: 'Please select' },
							{ value: 'Partnership', label: 'Partnership' },
							{ value: 'Corporation', label: 'Corporation' },
							{ value: 'SoleOwnership', label: 'Sole Ownership' },
							{ value: 'LLC', label: 'LLC' },
							{ value: 'NonProfit', label: 'Non Profit / Tax Exempt' },
							{ value: 'Other', label: 'Other' },
						]}
					/>
				</div>
				{this.renderField(mpa, 'taxID', 'Tax ID (9 digits)', undefined, 'fs-mask')}
				{this.renderField(mpa, 'bankAccountInformation.bankName', 'Bank Name')}
				{this.renderField(mpa, 'bankAccountInformation.accountNumber', 'Bank Account #', undefined, 'fs-mask')}
				{this.renderRoutingNumber(mpa)}
			</div>
		);
	}

	renderAdditionalInformation(mpa, isToggled, handleToggle) {
		return (
			<div className={`card${isToggled ? ' is-expanded' : ''} spc--bottom--lrg`}>
				{this.renderCardHeader('Additional Information', isToggled, handleToggle)}
				{isToggled && <div className="card__body">{this.renderAdditionalInfo(mpa)}</div>}
			</div>
		);
	}

	renderMailingInfo(mpa, isToggled, handleToggle) {
		const sameAsBusiness = get(this.state, 'sameAsBusiness', false);
		return (
			<div className={`card${isToggled ? ' is-expanded' : ''} spc--bottom--lrg`}>
				{this.renderCardHeader('Mailing Address', isToggled, handleToggle)}
				{isToggled && (
					<div className="card__body">
						{!this.props.isMerchant ? (
							<div className="input--check--enable-form form__group">
								<input
									id="same-as"
									type="checkbox"
									className="input--check"
									onClick={e => {
										this.props.copyAddress(e, this.setState({ sameAsBusiness: e.target.checked }));
									}}
								/>
								<label htmlFor="same-as">Same as Business Address</label>
							</div>
						) : (
							<div className="form__group">
								<input id="same-as" type="checkbox" className="input--check" onClick={this.props.copyAddress} />
								<label htmlFor="same-as">Mailing address same as location address</label>
							</div>
						)}
						<fieldset disabled={sameAsBusiness}>
							<AddressComponent
								requiredFields={this.props.requiredFields}
								validateField={this.props.validateField}
								streetPrefix="Business Mailing"
								address={mpa.corporateAddress}
								namePrefix={'corporateAddress'}
								onChange={this.props.handleChange}
								isCanada={mpa.isCanadian}
							/>
						</fieldset>
					</div>
				)}
			</div>
		);
	}

	renderField(form, field, label, inputProps = {}, className = '') {
		const name = this.fixFieldName(field);
		return (
			<div className="col col-sml-12 col-med-6 form__group" id={`${name}_div`}>
				<div className="form__group__header">
					<label className="form__group__label">{label} </label>
					{this.props.requiredFields[field] && (
						<span className="form__group__required" data-tooltip="Required">
							*
						</span>
					)}
				</div>
				<input
					type="text"
					className={`input input--med${this.props.validateField(field)} ${className}`}
					name={name}
					value={get(form, field)}
					onChange={this.props.handleChange}
					autoComplete="off"
					{...inputProps}
				/>
			</div>
		);
	}

	renderBusinessPhoneNumber(mpa) {
		return this.renderField(mpa, 'businessInformation.businessPhone', 'Business Phone Number');
	}

	renderBusinessInfo(mpa, isToggled, handleToggle) {
		const isCad = mpa.isCanadian
		return (
			<div className={`card${isToggled ? ' is-expanded' : ''} spc--bottom--lrg`}>
				{this.renderCardHeader('Business Information', isToggled, handleToggle)}
				{isToggled && (
					<div className="card__body">
						<div className="row">
							{this.renderField(mpa, 'corporateName', 'Corporate Name')}
							{this.renderField(mpa, 'dba', 'DBA')}
							{this.renderBusinessPhoneNumber(mpa)}
							{this.renderField(mpa, 'contactPhoneNumber', 'Contact Phone Number')}
							{this.renderField(mpa, 'businessInformation.businessFax', 'Business Fax Number')}
							{this.renderField(
								mpa,
								'businessInformation.businessEmail',
								<div className="flex--primary flex--gap--tny">
									Business Email{' '}
									{isCad && (<span className="form__group__required" data-tooltip="Required">
										*
									</span>)}
									<i
										className="icon icon--tny icon--regular--info datatooltip--200"
										data-tooltip="Separate multiple email addresses with a comma."
									/>
								</div>
							)}
							{this.renderBusinessStartDate(mpa)}
						</div>

						<AddressComponent
							streetPrefix="Business"
							requiredFields={this.props.requiredFields}
							validateField={this.props.validateField}
							address={mpa.businessInformation.businessAddress}
							namePrefix={'businessInformation_businessAddress'}
							onChange={this.props.handleChange}
							isCanada={mpa.isCanadian}
						/>
					</div>
				)}
			</div>
		);
	}

	renderBusinessStartDate(mpa) {
		return (
			<div className="col col-sml-12 col-med-6 form__group" id="businessStartDate_div">
				<div className="form__group__header">
					<p className="form__group__label">Business Start Date </p>
					{this.props.requiredFields['businessStartDate'] && <span className="form__group__required">*</span>}
				</div>
				<SingleDatePicker
					name={'businessStartDate'}
					onChange={date => this.props.handleChange({ target: { name: 'businessStartDate', value: date } })}
					validateField={() => this.props.validateField('businessStartDate')}
					value={get(mpa, 'businessStartDate', '')}
				/>
			</div>
		);
	}

	renderRoutingNumber(mpa) {
		return this.renderField(mpa, 'bankAccountInformation.routingNumber', 'Bank Routing #', undefined, 'fs-mask');
	}

	renderDateOfBirth(mpa) {
		return (
			<div className="col col-sml-12 col-med-6 form__group">
				<div className="form__group__header">
					<p className="form__group__label" id="signerInformation_dateOfBirth_div">
						Date of Birth
					</p>
					{this.props.requiredFields['signerInformation.dateOfBirth'] && (
						<span className="form__group__required" data-tooltip="Required">
							*
						</span>
					)}
				</div>
				<SingleDatePicker
					name={'signerInformation_dateOfBirth'}
					className="fs-mask"
					onChange={date => this.props.handleChange({ target: { name: 'signerInformation_dateOfBirth', value: date } })}
					validateField={() => this.props.validateField('signerInformation.dateOfBirth')}
					value={get(mpa, 'signerInformation.dateOfBirth', '')}
				/>
			</div>
		);
	}

	renderSocialSecurityNumberLabel() {
		return 'Social Security Number';
	}

	renderSocialSecurityNumber(mpa) {
		return (
			<div className="col col-sml-12 col-med-6 form__group" id="signerInformation_socialSecurityNumber_div">
				<div className="form__group__header">
					<span className="form__group__label">{this.renderSocialSecurityNumberLabel()} </span>
					{this.props.requiredFields['SocialSecurityNumber'] && (
						<span className="form__group__required" data-tooltip="Required">
							*
						</span>
					)}
				</div>
				<NumericFormat
					className={`input input--med fs-mask${this.props.validateField('signerInformation.socialSecurityNumber')}`}
					name="signerInformation_socialSecurityNumber"
					value={mpa.signerInformation.socialSecurityNumber}
					thousandSeparator={false}
					fixedDecimalScale={false}
					allowLeadingZeros={true}
					decimalScale={0}
					onValueChange={({ formattedValue }) =>
						this.props.handleChange({
							target: {
								value: formattedValue,
								name: 'signerInformation_socialSecurityNumber',
							},
						})
					}
				/>
			</div>
		);
	}

	renderIdentificationInfo() {
		return null;
	}
	renderSignerAgreement() {
		return null;
	}
}
BaseMPA.propTypes = {
	mpa: PropTypes.object.isRequired,
	mpafiles: PropTypes.array.isRequired,
	files: PropTypes.array.isRequired,
	requiredFields: PropTypes.object.isRequired,
	validateField: PropTypes.func.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleFileDelete: PropTypes.func.isRequired,
	handleFileDownload: PropTypes.func.isRequired,
	handleRemoveFile: PropTypes.func.isRequired,
	handleUpdateFileDescription: PropTypes.func.isRequired,
	onDropFile: PropTypes.func.isRequired,
	copyContactInformation: PropTypes.func.isRequired,
	copyAddress: PropTypes.func.isRequired,
	getFilesForTag: PropTypes.func.isRequired,
	showLoader: PropTypes.func.isRequired,
	isMerchant: PropTypes.bool.isRequired,
	getRequiredDocs: PropTypes.func.isRequired,
};

export default BaseMPA;
