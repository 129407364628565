import React, { Component, Fragment } from 'react';
import { findIndex, each, map, noop } from 'lodash';
import PropTypes from 'prop-types';
import { invokeIfFunction, OutsideClick } from '../utilities';

class BaseGridActionsComponent extends Component {
	constructor(props, instances) {
        super(props);
        this.instances = instances;
        this.instances.push(this);
        this.shouldRenderActions = true;
		
        this.state = {
            isDisplayMenuOpen: false,
		};
	}

	componentDidUpdate = prevProps => {
		if (prevProps.isScrolling !== this.props.isScrolling) {
			this.closeDisplayMenu();
		}
	};

	componentWillUnmount() {
		const index = findIndex(this.instances, this);
		if (index > -1) {
			this.instances.splice(index, 1);
		}
	}

	openDisplayMenu = () => {
		this.setState({ isDisplayMenuOpen: true }, this.displayMenu);
	};

	closeDisplayMenu = (callback = noop) => {
		setTimeout(() => this.setState({ isDisplayMenuOpen: false }, () => {
            this.displayMenu();
            callback();
        }), 70);
	};

    onKeyDownHandler = (event, onClickHandler) => {
        if(event.keyCode === 13){
            onClickHandler();
        }
    }

    createMenuItem = ({isVisible, title, onClickHandler, iconClassName, href, index}) => {
        if(!invokeIfFunction(isVisible)){
            return null;
        }
        return <li className="buttondropdown__item buttondropdown__item--withlink" key={`action_menu_item_${index}`} onClick={onClickHandler} onKeyDown={e => this.onKeyDownHandler(e, onClickHandler)}>
            {href ? <a
                className="buttondropdown__link"
                href={href}
                target="_blank"
                >
                    {invokeIfFunction(title)}
                </a>
                : <a className="buttondropdown__link">
                    {iconClassName && <i className={iconClassName}></i>}
                    <span className={iconClassName ? 'spc--left--xsml' : ''}>{invokeIfFunction(title)}</span>
                </a>
            }
        </li>;
    }

    displayMenu = () => {
        const { openActions } = this.props.dependentValues;
        const { isDisplayMenuOpen } = this.state;
        const actions = isDisplayMenuOpen ? (
            <OutsideClick action={() => this.closeDisplayMenu()} className="buttondropdown buttondropdown--left">
                <ul className="buttondropdown__list">
                   {map(this.menuItems, (item, index) => this.createMenuItem({...item, index}))}
                </ul>
            </OutsideClick>
        ) : null;
        if (this.info) {
            const rect = this.info.getBoundingClientRect();
            openActions(
                {
                    width: (rect.left || 0) + (rect.width / 2 || 0),
                    height: (rect.top || 0) + 30,
                },
                actions
            );
        }
        if (isDisplayMenuOpen) {
            each(this.instances, instance => {
                if (instance.state.isDisplayMenuOpen && instance !== this) {
                    instance.setState({ isDisplayMenuOpen: false });
                }
            });
        }
    };

    render = () => {
        return (
            <Fragment>
                {this.shouldRenderActions &&
                    < div
                        className={'grid__actions__items'}
                        ref={el => (this.info = el)}
                        onClick={this.openDisplayMenu}
                    >
                        <i className={`icon icon--tiny ${this.state.isDisplayMenuOpen ? 'icon--dots--primary' : 'icon--dots'}`}></i>
                    </div>
                }
            </Fragment >
        );
    }
}

BaseGridActionsComponent.propTypes = {
    dependentValues: PropTypes.object,
    isScrolling: PropTypes.bool,
};

export default BaseGridActionsComponent;
