import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { map, find, noop, startsWith, replace, filter } from 'lodash';
import { Switch, NavLink, Redirect } from 'react-router-dom';
import sectionKeys from '../../routing/sections';
import { withError } from '../../common/components/error';
import { Security } from '../../components/Login';
import { OutsideClick, invokeIfFunction } from '../../common/utilities';
import Route from '../../routing/Route';
import { principalService } from '../../services';
import { withLoader } from '../../common/components';
import EquipmentTemplate from '../EquipmentTemplate/EquipmentTemplate';
import PartnerSettings from './PartnerSettings';
import CobrandingSettings from './CobrandingSettings';

const PlaceholderComponent = ({ location }) => (
	<div className="settings--main settings--main--alt">
		<h3 className="spc--top--sml">
			Placeholder For <code>{location.pathname}</code>
		</h3>
	</div>
);
PlaceholderComponent.propTypes = { location: PropTypes.object.isRequired };

const routes = [
    {
        path: '/equipment-template',
        label: 'Default Equipment',
        component: EquipmentTemplate,
        isDefault: true,
        isPublic: false,
        section: sectionKeys.settings
    },
    {
        path: '/partner-settings',
        label: 'General Settings',
        component: PartnerSettings,
        isDefault: false,
        isPublic: false,
        section: sectionKeys.validpartner
    },
    {
        path: '/cobranding-settings',
        label: 'Cobranding Settings',
        component: CobrandingSettings,
        isDefault: false,
        isPublic: false,
        section: sectionKeys.cobrand
    },
    {
        path: '/security',
        label: 'Security',
        component: Security,
        isDefault: false,
        isPublic: false,
        section: sectionKeys.settings
    },
];

class Settings extends Component {
    constructor(props) {
        super(props);
        this.principal = principalService.get();
        this.routes = [...routes];
        this.state = {
            isNavOpen: false,
        };

        this.routes.push({
			path: '',
			exact: true,
			component: () => {
                return <Redirect to={{pathname: this.defaultRoute}} />;
			},
		});
    }

    async componentDidMount() {
        if(this.principal.isDropinUser){
            const { history } = this.props;
            history.push('/');
        }
    }

    get routesWithAccess()
    {
        return filter(routes, ({ section }) => this.authorize(section))
    }

	get defaultRoute() {
		const defRoute = find(this.routes, ({ isDefault }) => isDefault);
		if (defRoute) {
			return `${this.props.match.url}${defRoute.path}`;
		}
		return '';
	};

    get redirectRoute() {
		const { match } = this.props;
		if (replace(match.path, /\//g, '') === replace('settings', /\//g, '')) return '';
		return 'settings/';
	};

    openCloseNav = () => {
		this.setState({
			isNavOpen: !this.state.isNavOpen,
		});
	};

    authorize = (section) => {
        const { principal } = this;
		return !section || (principal && principal.hasAccess && !!principal.hasAccess[section]);
    };

	renderHeaderPanel() {
        return (
            <header	className="header header--settings">
                <div className="header__title">Settings</div>
            </header>
        );
    }

	renderRoutes() {
        return (
            <Switch>
                {map(this.routes, (routeData, i) => {
                    return invokeIfFunction(routeData.hidden) || routeData.disabled ? null : (
                        <Route
                            key={i}
                            {...routeData}
                            defaultRoute={this.defaultRoute}
                            path={`${this.props.match.path}${routeData.path}`}
                        />
                    );
                })}
                <Redirect to={{ pathname: this.redirectRoute }} />
            </Switch>
        );
    }

	renderShowSidebar(onNavClick, navigationList, navClasses, pathname, url) {
        return (
            <div className="settings--aside">
                <div className="settings__dropdown">
                    <OutsideClick action={onNavClick}>
                        <Fragment>
                            <div className="pos--rel">
                                <div className="input input--med input--select" onClick={this.openCloseNav}>
                                    {
                                        (
                                            find(this.routes, ({ exact, path }) =>
                                                exact ? pathname === `${url}${path}` : startsWith(pathname, `${url}${path}`)
                                            ) || { label: 'Account Settings'}
                                        ).label
                                    }
                                </div>
                                <ul className={navClasses}>{navigationList}</ul>
                            </div>
                        </Fragment>
                    </OutsideClick>
                </div>
                <ul className="settings__nav">{navigationList}</ul>
            </div>
        );
	}

	render() {
		const {
			match: { url },
			location: { pathname },
		} = this.props;
		const { isNavOpen } = this.state;
		const sideBarClassName = '';
		const navClasses = isNavOpen ? 'settings__nav is-active' : 'settings__nav';
		const onNavClick = isNavOpen ? this.openCloseNav : noop;

		const navigationList = map(
			this.routesWithAccess,
			({ path, label }) =>(
                    <li key={path} className="settings__nav__item" onClick={onNavClick}>
                        <NavLink className="settings__nav__link" activeClassName="is-active" to={`${url}${path}`}>
                            <span className="settings__nav__link__text">{label}</span>
                        </NavLink>
                    </li>
				)
		);

		return (
			<Fragment>
				{this.renderHeaderPanel()}
				<div className={sideBarClassName}>
					<div className="settings">
						{this.renderShowSidebar(onNavClick, navigationList, navClasses, pathname, url)}
						<div className="settings--main--wrap">{this.renderRoutes()}</div>
					</div>
				</div>
			</Fragment>
		);
	}
}

Settings.propTypes = {
	match: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	handleError: PropTypes.func.isRequired,
	makePendingRequest: PropTypes.func.isRequired,
};

export default withError(withLoader(Settings));
