import React, { Component, Fragment } from 'react';
import { isEmpty, toLower, map, noop, get } from 'lodash';
import PropTypes from 'prop-types';
import { Toggle } from '../../common/components/toggle';
import { YesNoRadio } from '../../common/components/yes-no-radio';
import AmexRates from './amex-rates';
import { DropdownContainer } from '../../common/components/dropdown-container';
import { CheckboxContainer } from '../../common/components/checkbox-container';
import { CheckboxTxtInput } from '../../common/components/checkbox-text-input';
import { isFd, isAch } from '../../common/utilities';

export default class USSetupConfirm extends Component {
    render() {
        const { setup, mpa, displayRates } = this.props;

        return (
            <Toggle initialToggle={true}>
                {({ isToggled, handleToggle }) =>
                    !isEmpty(setup) && (
                        <Fragment>
                            <div className="card--primary card--sml spc--bottom--med">
                                <div
                                    className={`card--primary__header ${
                                        isToggled
                                            ? "is-expanded"
                                            : "is-collapsed"
                                    }`}
                                >
                                    <h6 className="spc--bottom--sml">Setup Form</h6>
                                    <div className="card--primary__header__expand" onClick={handleToggle}>
                                        <i
                                            className={`icon icon--arrow icon--tiny icon--arrow--down--primary ${
                                                isToggled ? "rotate--180" : ""
                                            }`}
                                        ></i>
                                    </div>
                                </div>
                                <div
                                    className={`details__content ${
                                        isToggled ? "" : "display--n"
                                        }`}
                                >
                                    <div className="row">
                                        <div className="col col-sml-12 col-med-6">
                                            <div className="form__field spc--bottom--med">
                                                <label className="label">
                                                    Rep 2
                                                </label>
                                                <div className="input input--med input--fake">
                                                    {
                                                        setup.telemarketerEmail
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <label className="card--primary__subtitle">Check whatever is applicable:</label>
                                    <div className="row">
                                        <div className="col col-sml-12">
                                            <label className="label">The signer or business has, or has previously had, an account with Fidelity</label>
                                            <YesNoRadio radioClass="input--radio input--radio--preview" name="hasExistingFidelityAccount" yes={setup.hasExistingFidelityAccount} onChange={noop} />
                                            {setup.hasExistingFidelityAccount && (
                                                <div className="row">
                                                    {this.renderFakeInput({
                                                        name: 'DBA/MID',
                                                        value: setup.existingAccountDba,
                                                    })}
                                                </div>
                                            )}

                                            <div className="separator--grey1 spc--bottom--med"></div>
                                            {get(setup, 'additionalSetupDetails') && this.renderAcceptingCreditCards()}
                                        </div>
                                        <div className="col col-sml-12 col-med-6">
                                            <div className="form__field spc--bottom--med">
                                                <label className="label">
                                                    Goods/Services Sold
                                                </label>
                                                <div className="input input--med input--fake">
                                                    {
                                                        setup.goodsOrServicesDescription
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {this.renderProcessingMethod()}
                                    {this.props.showSubmit && this.renderAmexRates(setup)}
                                    {get(setup, 'additionalSetupDetails.isCurrentlyAcceptingCreditCards', false) && toLower(setup.proposedSavings.type) !== 'unknown' && (
                                        <Fragment>
                                            <div className="separator--grey1 spc--bottom--sml"></div>
                                            <div className="card--primary__header card--primary__sub flex--primary separator--grey1 spc--bottom--med">
                                                <strong>
                                                    Proposed Savings (only if applicable)
                                                </strong>
                                                {this.props.renderChangeLink(this.props.redirectToSetup)}
                                            </div>
                                            <div className="row">
                                                <div className="col col-sml-12 col-med-6">
                                                    <DropdownContainer
                                                        hideSelect={true}
                                                        labelText='Proposed Savings Type'
                                                        divClass="row"
                                                        labelClass='label'
                                                        selectClass='input input--med input--select'
                                                        wrapperClass='col col-sml-12 form__field spc--bottom--med'
                                                        selectName='proposedSavings_type'
                                                        value={setup.proposedSavings.type}
                                                        onChange={noop}
                                                        options={[
                                                            {
                                                                value: 'MonthlyDollarSavings',
                                                                text: 'Monthly Dollar Savings',
                                                                children: map(
                                                                    [
                                                                        {
                                                                            name: 'Monthly Dollar Savings',
                                                                            value: setup.proposedSavings.monthlyDollarSavingsAmounts,
                                                                        },
                                                                    ],
                                                                    (item) => this.renderFakeInput(item)
                                                                ),
                                                            },
                                                            {
                                                                value: 'EffectiveRateReduction',
                                                                text: 'Effective Rate Reduction',
                                                                children:
                                                                    <DropdownContainer
                                                                        hideSelect={true}
                                                                        labelText='Effective Rate Reduction Type'
                                                                        divClass="row col col-sml-12"
                                                                        labelClass='label'
                                                                        selectClass='input input--med input--select'
                                                                        wrapperClass='col col-sml-12 form__field spc--bottom--sml'
                                                                        selectName='proposedSavings_rateReductionType'
                                                                        value={setup.proposedSavings.rateReductionType || ''}
                                                                        onChange={noop}
                                                                        options={[
                                                                            {
                                                                                value: 'Combined',
                                                                                text: 'Combined',
                                                                                default: true,
                                                                                children: map(
                                                                                    [
                                                                                        {
                                                                                            name: 'Combined',
                                                                                            value: setup.proposedSavings.combinedSavingsPercentage,
                                                                                        },
                                                                                    ],
                                                                                    (item) => this.renderFakeInput(item)
                                                                                ),
                                                                            },
                                                                            {
                                                                                value: 'Individual',
                                                                                text: 'Individual',
                                                                                children: map(
                                                                                    [
                                                                                        {
                                                                                            name: 'Bankcard',
                                                                                            value: setup.proposedSavings.bankcardSavingsPercentage,
                                                                                        },
                                                                                        {
                                                                                            name: 'Amex',
                                                                                            value: setup.proposedSavings.amexSavingsPercentage,
                                                                                        },
                                                                                    ],
                                                                                    (item) => this.renderFakeInput(item)
                                                                                ),
                                                                            }
                                                                        ]} >
                                                                    </DropdownContainer>
                                                            },
                                                        ]}>
                                                    </DropdownContainer>
                                                </div>
                                            </div>
                                        </Fragment>
                                    )}

                                    {displayRates && this.renderDisplayRates()}
                                    {this.renderOnlineReportingSection(mpa, setup)}
                                    {!isAch(mpa) &&
                                        (
                                            <div>
                                    <div className="card--primary__subheader">
                                        <strong>Internal Notes</strong>
                                        {this.props.renderChangeLink(this.props.redirectToSetup)}
                                    </div>
                                    <div className="row">
                                        <div className="col col-sml-12 col-lrg-12">
                                            <div className="form__field">
                                                <label className="label">
                                                    Note
                                                </label>
                                                <div className="input input--textarea input--fake">
                                                    {setup.internalNotes}
                                                </div>
                                            </div>
                                        </div>
                                            </div>
                                    </div>
                                    )}
                                </div>
                            </div>
                        </Fragment>
                    )
                }
            </Toggle>
        );
    }

    renderAmexRates(setup) {
        return (
            <div className="row">
                <AmexRates amexDetails={setup.amexDetails} renderRateStructure={this.renderRateStructure}/>
            </div>
        );
    }

    renderOnlineReportingSection(mpa, setup) {
        return (
            <React.Fragment>
                {isFd(setup) &&
                    <Fragment>
                        <div className="separator--grey1 spc--bottom--sml" />
                        <div className="spc--bottom--sml">
                            <label className="label type--color--text--dark">Online reporting (Check whichever is applicable)</label>
                        </div>
                    </Fragment>
                }
                <div className="row spc--bottom--med">
                    {isFd(setup) &&
                        <div className="col col-sml-12 col-med-6 spc--bottom--sml">
                            <input className="input--check input--check--preview" type="checkbox" name="onlineReportingOptions_isEnabled" id="onlineReportingOptions_isEnabled" checked={setup.onlineReportingOptions.isEnabled} readOnly/>
                            <label className="label" htmlFor="onlineReportingOptions_isEnabled">Enable Access One</label>
                        </div>
                    }
                    {get(setup, 'onlineReportingOptions.isEnabled', false) && <div className="col col-sml-12 spc--bottom--med">
                        <div className="spc--bottom--tny">
                            <input disabled={true} type="radio" classname="input--radio input--check--preview" id="radMarkupFeeFree" name="onlineReportingOptions_markupFee" value="0" checked={setup.onlineReportingOptions.markupFee === '0'} onChange={noop} />
                            <label className="label" htmlFor="radMarkupFeeFree">Free ($0)</label>
                        </div>
                        <div className="spc--bottom--tny">
                            <input disabled={true} type="radio" classname="input--radio input--check--preview" id="radMarkupFee5" name="onlineReportingOptions_markupFee" value="5" checked={setup.onlineReportingOptions.markupFee === '5'} onChange={noop} />
                            <label className="label" htmlFor="radMarkupFee5">$5</label>
                        </div>
                        <div>
                            <input disabled={true} type="radio" classname="input--radio input--check--preview" id="radMarkupFee10" name="onlineReportingOptions_markupFee" value="10" checked={setup.onlineReportingOptions.markupFee === '10'} onChange={noop} />
                            <label className="label" htmlFor="radMarkupFee10">$10</label>
                        </div>
                    </div>}
                </div>
                {this.renderEidsEnrollment(mpa, setup)}
                {this.renderTaxExemption()}
            </React.Fragment>
        )
    }

    renderEidsEnrollment(mpa, setup) {
        if (!setup.eidsEnrollmentDetails || !isFd(mpa)) return null;
        return (
            <div><div className="card--primary__subheader">
                <strong>EIDS Enrollment</strong>
                {this.props.renderChangeLink(this.props.redirectToSetup)}
                </div>
                    <div>
                        <div className="spc--bottom--med">
                            <input className="input--check input--check--preview" id="eidsEnrollmentDetails_shouldEnrollEids" name="eidsEnrollmentDetails_shouldEnrollEids" type="checkbox" checked={setup.eidsEnrollmentDetails.shouldEnrollEids} readOnly/>
                            <label className="label" htmlFor="eidsEnrollmentDetails_shouldEnrollEids">Enroll with EIDS (Electronic Integrated Disputes SystemSM)</label>
                        </div>
                            {setup.eidsEnrollmentDetails.shouldEnrollEids && <div className="row">
                            <div className="col col-sml-12 col-lrg-6 spc--bottom--sml">
                                <div className="spc--bottom--sml">
                                    <label className="label" htmlFor="eidsEnrollmentDetails_eidsMerchantPrice">Enter price to bill merchant (cost is $3.95):</label>
                                    <div>
                                <input className="input input--med input--fake" id="eidsEnrollmentDetails_eidsMerchantPrice" name="eidsEnrollmentDetails_eidsMerchantPrice" type="decimal" value={setup.eidsEnrollmentDetails.eidsMerchantPrice} />
                                    </div>
                                </div>
                                <div className="spc--bottom--sml">
                                    <div className="message message--default message--default--alt">
                                        Please note, if you would like to bill more than $5.95, you'll need to obtain a signed form from the merchant authorizing the charge. Banking Services will follow up with you accordingly for a signature.
                                    </div>
                                </div>
                            </div>

                            <div className="col col-sml-12 col-lrg-6 spc--bottom--sml">
                                <div className="spc--bottom--sml required">
                                    <label className="label" htmlFor="eidsEnrollmentDetails_eidsMerchantEmail">Enter the email the login credentials will be sent to:</label>
                                    <div>
                                <input className="input input--med input--fake" id="eidsEnrollmentDetails_eidsMerchantEmail" name="eidsEnrollmentDetails_eidsMerchantEmail" type="text" value={setup.eidsEnrollmentDetails.eidsEmail} />
                                    </div>
                                </div>
                                <div className="spc--bottom--sml">
                                    <div className="message message--default message--default--alt">
                                        It usually takes up to 2-3 days to get an approval and before the dispute reporting kicks in.
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
        );
    }

    renderAcceptingCreditCards() {
        const { setup: {additionalSetupDetails} } = this.props;
        return (
            <Fragment>
                <label className="label">Merchant currently accepts credit cards</label>
                <YesNoRadio radioClass="input--radio input--radio--preview" name="additionalSetupDetails_isCurrentlyAcceptingCreditCards" yes={additionalSetupDetails.isCurrentlyAcceptingCreditCards} onChange={noop} />
                <div className="spc--bottom-med">
                    {additionalSetupDetails.isCurrentlyAcceptingCreditCards ? (
                        <YesNoRadio
                            radioClass="input--radio input--radio--preview"
                            name="additionalSetupDetails_hadStatementsReviewed"
                            yes={additionalSetupDetails.hadStatementsReviewed}
                            onChange={noop}
                            yesLabel="Statements reviewed"
                            noLabel="No statements reviewed"
                        />
                    ) : (
                        <YesNoRadio
                            radioClass="input--radio input--radio--preview"
                            name="additionalSetupDetails_requiresPricingAnalysis"
                            yes={additionalSetupDetails.requiresPricingAnalysis}
                            onChange={noop}
                            yesLabel="Standard industry rates"
                            noLabel="Use rates provided by agent"
                        />
                    )}
                    {additionalSetupDetails.isCurrentlyAcceptingCreditCards && !additionalSetupDetails.hadStatementsReviewed && (
                        <div className="spc--bottom--med">
                            <YesNoRadio
                                radioClass="input--radio input--radio--preview"
                                name="additionalSetupDetails_requiresPricingAnalysis"
                                yes={additionalSetupDetails.requiresPricingAnalysis}
                                onChange={noop}
                                yesLabel="Standard industry rates"
                                noLabel="Use rates provided by agent"
                            />
                        </div>
                    )}
                    {additionalSetupDetails.isCurrentlyAcceptingCreditCards && additionalSetupDetails.hadStatementsReviewed && (
                        <div className="col col-sml-12 col-med-6 form__field">
                            <div>
                                <label className="label">DBA As Reviewed </label>
                                <div className="input input--med input--fake">
                                    {
                                        additionalSetupDetails.statementsReviewedDba
                                    }
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="separator--grey1 spc--bottom--med"></div>
            </Fragment>
        );
    }

    renderDisplayRates() {
        const { setup , mpa} = this.props;
        return (
            <Fragment>
                <div className="card--primary__subheader">
                    <strong>Pricing Structure</strong>
                    {this.props.renderChangeLink(this.props.redirectToSetup)}
                </div>
                <div className="row">
                    <div className="col col-sml-12 col-lrg-6">
                        <div className="form__field spc--bottom--med">
                            <label className="label">
                                Bank
                            </label>
                            <div className="input input--med input--fake">
                                {
                                    mpa.processorName
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderRateStructure('pricingStructure', '')}
                <div className="separator separator--grey1 spc--bottom--med"></div>
                <div className="row">
                    <div className="col col-sml-12 col-lrg-6">
                        <div className="form__field spc--bottom--med">
                            <label className="label">
                                Authorization Fee
                            </label>
                            <div className="input input--med input--fake input--currency input--currency--sml">
                                {
                                    setup.pricingStructure
                                        .authorizationFee
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col col-sml-12 col-lrg-6">
                        <div className="form__field spc--bottom--med">
                            <label className="label">
                                V/MC/Disc Transaction Fee:
                            </label>
                            <div className="input input--med input--fake input--currency input--currency--sml">
                                {
                                    setup.pricingStructure
                                        .bankCardTransactionFee
                                }
                            </div>
                        </div>
                    </div>
                    {this.renderPinDebit()}
                </div>
                <div className="row">
                    {!setup.pricingStructure || !setup.pricingStructure.bankName || toLower(setup.pricingStructure.bankName).indexOf('firstdata') === 0 && (
                        <div className="col col-sml-12 col-lrg-6">
                            <div className="form__field spc--bottom--sml">
                                <label className="label">
                                    Discount Method
                                </label>
                            </div>
                            <div className="form__field spc--bottom--med">
                                <input
                                    name="discountMethod"
                                    id="discountMethod"
                                    type="checkbox"
                                    className="input--radio input--radio--preview"
                                    checked={
                                        setup
                                            .pricingStructure
                                            .discountMethod
                                    }
                                    value={
                                        setup
                                            .pricingStructure
                                            .discountMethod
                                    }
                                    onChange={noop}
                                />
                                <label htmlFor="discountMethod">
                                    {
                                        setup
                                            .pricingStructure
                                            .discountMethod
                                    }
                                </label>
                            </div>
                        </div>
                    )}
                    {setup.pricingStructure && setup.pricingStructure.bankName && toLower(setup.pricingStructure.bankName) === 'elavon' && (
                        <Fragment>
                            <div className="row">
                                <div className="col col-sml-12 col-med-6">
                                    <input
                                        className="input--check input--check--preview"
                                        type="checkbox"
                                        id="pricingStructure_shouldRequireNextDayFunding"
                                        name="pricingStructure_shouldRequireNextDayFunding"
                                        value={setup.pricingStructure.shouldRequireNextDayFunding}
                                        onChange={noop}
                                    />
                                    <label
                                        htmlFor="pricingStructure_shouldRequireNextDayFunding"
                                        className="label"
                                    >
                                        Next Day Funding Required ($5 monthly fee applies)
                                    </label>
                                </div>
                            </div>
                            <div className="separator separator--grey1 spc--bottom--med"></div>
                        </Fragment>
                    )}
                </div>

                <div className="card--primary__subheader">
                    <strong>Proposed Fees</strong>
                    {this.props.renderChangeLink(this.props.redirectToSetup)}
                </div>
                <div className="row">
                    <div className="col col-sml-12 col-lrg-6">
                        <div className="form__field spc--bottom--med">
                            <label className="label">
                                Batch Fee
                            </label>
                            <div className="input input--med input--fake input--currency input--currency--sml">
                                {
                                    setup.proposedFees
                                        .batchFee
                                }
                            </div>
                        </div>
                    </div>
                    {this.renderRetrievalCharge(setup)}
                    <div className="col col-sml-12 col-lrg-6">
                        <div className="form__field spc--bottom--med">
                            <label className="label">
                                Statement Fee
                            </label>
                            <div className="input input--med input--fake input--currency input--currency--sml">
                                {
                                    setup.proposedFees
                                        .statementFee
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col col-sml-12 col-lrg-6">
                        <div className="form__field spc--bottom--med">
                            <label className="label">
                                Monthly Minimum Fee
                            </label>
                            <div className="input input--med input--fake input--currency input--currency--sml">
                                {
                                    setup.proposedFees
                                        .monthlyMinimumFee
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col col-sml-12 col-lrg-6">
                        <div className="form__field spc--bottom--med">
                            <label className="label">
                                Annual Fee
                            </label>
                            <div className="input input--med input--fake input--currency input--currency--sml">
                                {
                                    setup.proposedFees
                                        .annualFee
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-sml-12 col-lrg-6">
                        <div className="form__field spc--bottom--med">
                            <label className="label">
                                Chargeback Fee
                            </label>
                            <div className="input input--med input--fake input--currency input--currency--sml">
                                {
                                    setup.proposedFees
                                        .chargebackFee
                                }
                            </div>
                        </div>
                    </div>
                    {this.renderPciFee(setup)}
                    {setup.doesAcceptEbt ?
                        <div className="col col-sml-12 col-lrg-6">
                            <div className="form__field spc--bottom--med">
                                <label className="label">
                                    EBT Transaction Fee
                                </label>
                                <div className="input input--med input--fake input--currency input--currency--sml">
                                    {setup.proposedFees.ebtTransactionFee}
                                </div>
                            </div>
                        </div>
                        : null
                    }
                </div>
                <div className="row">
                    <div className="col col-sml-12 spc--bottom--sml">
                        <label className="label type--color--text--dark">Early Termination Fee (ETF)</label>
                    </div>
                    <div className="col col-sml-12 col-med-6">
                        <CheckboxTxtInput
                            disabled={true}
                            labelText='Not Waived Fee'
                            checkboxClass='input--check input--check--preview'
                            checkboxName='proposedFees_isEarlyTerminationFeeWaived'
                            inputName='proposedFees_earlyTerminationFee'
                            inputPlaceholder=''
                            checked={!setup.proposedFees.isEarlyTerminationFeeWaived}
                            value={String(setup.proposedFees.earlyTerminationFee)}
                            onCheckChanged={noop}
                            onValueChanged={noop}
                        />
                    </div>
                    {this.renderFakeInput({
                        name: 'Waived',
                        value: setup.proposedFees.isEarlyTerminationFeeWaived,
                    })}
                </div>
            </Fragment>
        );
    }

    renderPciFee(setup) {
        return <div className="col col-sml-12 col-lrg-6">
            <div className="form__field spc--bottom--med">
                <label className="label">
                    PCI Annual Fee
                </label>
                <div className="input input--med input--fake input--currency input--currency--sml">
                    {setup.proposedFees
                        .pciAnnualFee}
                </div>
            </div>
        </div>;
    }

    renderRetrievalCharge(setup) {
        return <div className="col col-sml-12 col-lrg-6">
            <div className="form__field spc--bottom--med">
                <label className="label">
                    Retrieval Charge
                </label>
                <div className="input input--med input--fake input--currency input--currency--sml">
                    {setup.proposedFees
                        .retrievalCharge}
                </div>
            </div>
        </div>;
    }

    renderPinDebit() {
        const { setup } = this.props;
        return (
            <div className="col col-sml-12 spc--bottom--sml">
                <CheckboxContainer
                    labelText='Setup merchant with Pin Debit '
                    checkboxClass='input--check input--check--preview'
                    checkboxName='pricingStructure_shouldSetupPinDebit'
                    checked={setup.pricingStructure.shouldSetupPinDebit}
                    onCheckChanged={noop}
                >
                    {this.renderPinDebitStructure()}
                </CheckboxContainer>
            </div>
        );
    }

    renderPinDebitInput(name, value) {
        return this.renderFakeInput({ className: 'col col-sml-12 col-med-4', name, value });
    }

    renderPinDebitStructure() {
        const { setup } = this.props;
        return (
            <div className="row spc--top--sml grid-align-middle spc--bottom--med">
                {this.renderPinDebitInput('Proposed Structure', setup.pricingStructure.pinDebitStructureType)}
                {this.renderPinDebitInput('PIN Based Debit Trans Fee', setup.pricingStructure.pinDebitTransactionFee)}
                {this.renderPinDebitInput('PIN Based Debit Markup', setup.pricingStructure.pinDebitMarkupAmount)}
            </div>
        );
    }

    renderRateStructure = (rateObjectName, labelPrefix, _, __, tooltip) => {
        const { setup } = this.props;
        const namePrefix = rateObjectName + "_";

        // temporary code to allow ui to work without api
        if (!setup[rateObjectName]) {
            setup[rateObjectName] = {};
        }

        const rateObj = setup[rateObjectName];

        return (
            <DropdownContainer
                hideSelect={true}
                labelText={labelPrefix + " Rate Structure"}
                labelClass="label"
                wrapperClass='col col-sml-12 col-med-3 form__field'
                divClass='row'
                selectClass="input input--med input--select"
                selectName={namePrefix + "pricingStructureType"}
                required={true}
                value={rateObj.pricingStructureType}
                onChange={() => {}}
                tooltip={tooltip}
                options={[
                    {
                        value: 'PassThrough',
                        text: 'Pass Through',
                        children: map(
                            [
                                {
                                    name: 'Pass Through',
                                    value: rateObj.passThroughPercentage,
                                    labelPrefix,
                                    className: 'col col-sml-12 col-med-3 form__field',
                                },
                            ],
                            (item) => this.renderFakeInput(item)
                        ),
                    },
                    {
                        value: 'FlatRate',
                        text: 'Flat Rate',
                        children: map(
                            [
                                {
                                    name: 'Flat Rate',
                                    value: rateObj.flatRatePercentage,
                                    labelPrefix,
                                    className: 'col col-sml-12 col-med-3 form__field',
                                },
                            ],
                            (item) => this.renderFakeInput(item)
                        ),
                    },
                    {
                        value: "Tiered",
                        text: "Tiered",
                        children: map(
                                [
                                    {
                                        name: 'Qual',
                                        value: rateObj.tieredRatePercentages.qualified,
                                        className: 'col col-sml-12 col-med-3 form__field',
                                    },
                                    {
                                        name: 'Mid-Qual',
                                        value: rateObj.tieredRatePercentages.midQualified,
                                        className: 'col col-sml-12 col-med-3 form__field',
                                    },
                                    {
                                        name: 'Non-Qual',
                                        value: rateObj.tieredRatePercentages.nonQualified,
                                        shouldRenderInput: () => this.shouldHideNonQual(rateObjectName),
                                        className: 'col col-sml-12 col-med-3 form__field',
                                    },
                                    {
                                        name: 'Rewards',
                                        value: rateObj.tieredRatePercentages.rewards,
                                        shouldRenderInput: () => rateObjectName.indexOf("amex") !== 0,
                                        className: 'col col-sml-12 col-med-3 form__field',
                                    },
                                ],
                                (item) => this.renderFakeInput(item)
                                ),
                        },
                    {
                        value: "BillBack",
                        text: "Bill Back",
                        children: map(
                            [
                                {
                                    name: 'Base Rate',
                                    value: rateObj.billBackRatePercentages.base,
                                    labelPrefix,
                                    className: 'col col-sml-12 col-med-3 form__field',
                                },
                                {
                                    name: 'Enhanced Rate',
                                    value: rateObj.billBackRatePercentages.enhanced,
                                    labelPrefix,
                                    shouldRenderInput: () => namePrefix.indexOf("amex") !== 0,
                                    className: 'col col-sml-12 col-med-3 form__field',
                                },
                            ],
                            (item) => this.renderFakeInput(item)
                        ),
                    },
                ]}
            />
        );
    };

    shouldHideNonQual = () => false;

    renderFakeInput({ name, value, labelPrefix = '', shouldRenderInput = () => true, className = 'col col-sml-12 col-med-6 form__field', valueClassName = 'input input--med input--fake' }) {
        if (!shouldRenderInput()) return null;
        return (
            <div key={`${name}.${value}`} className={className}>
                <label className="label">
                    {labelPrefix ? `${labelPrefix} ` : ''}{name}{" "}
                </label>
                <div className={valueClassName}>
                    {value}
                </div>
            </div>
        );
    }

    renderProcessingDetailsField(value, name, props) {
        return this.renderFakeInput({ name, value, className: 'col col-sml-12 col-lrg-3 form__field', ...props });
    }

    renderVMField(value, name) {
        return (
            <div className="col col-sml-12 col-lrg-4">
                <div className="form__field spc--bottom--med">
                    <label className="label">{name} </label>
                    <div className="input input--med input--fake">
                        {value}
                    </div>
                </div>
            </div>
        )
    }

    renderProcessingMethod() {
        const { setup } = this.props;
        const processingDetails = get(setup, 'processingDetails', null);
        if(!processingDetails){
            return null;
        }
        const { internetTransactionsPercentage, keyedTransactionsPercentage, swipedTransactionsPercentage } = processingDetails;
        const processingDetailsTotalPercentage =
            internetTransactionsPercentage +
            keyedTransactionsPercentage +
            swipedTransactionsPercentage;

        return (
            <Fragment>
                <div className="card--primary__subheader">
                    <div className="required">
                        <strong className="label">Processing method (Must equal 100%)</strong>
                    </div>
                    {this.props.renderChangeLink(this.props.redirectToSetup)}
                </div>
                <div className="row row-align-middle">
                    {this.renderProcessingDetailsField(swipedTransactionsPercentage, 'Swiped')}
                    {this.renderProcessingDetailsField(keyedTransactionsPercentage, 'Keyed')}
                    {this.renderProcessingDetailsField(internetTransactionsPercentage, 'Internet')}
                    {this.renderProcessingDetailsField(processingDetailsTotalPercentage, 'Total', { valueClassName: 'form__total form__total--success' })}
                </div>
                {this.renderTransactionVolume()}
                {this.renderTransactionInformation()}
            </Fragment>
        );
    }

    renderTransactionVolume() {
        const { setup } = this.props;
        const processingDetails = get(setup, 'processingDetails', null);
        if(!processingDetails){
            return null;
        }
        const { averageTransactionAmount, maxTransactionAmount, yearlyVmcVolume } = processingDetails;
        return (
            <React.Fragment>
                <div className="card--primary__subheader">
                    <strong>V/M Transaction Information:</strong>
                </div>
                <div className="row row-align-middle">
                    {this.renderVMField(yearlyVmcVolume, 'V/M Average Annual Volume')}
                    {this.renderVMField(averageTransactionAmount, 'V/M Average transaction dollar amount')}
                    {this.renderVMField(maxTransactionAmount, 'V/M highest transaction dollar amount')}
                </div>
            </React.Fragment>
        );
    }

    renderTransactionInformation() {
        return null;
    }

    renderTaxExemption() {
        const { setup } = this.props;
        if(!get(setup, 'additionalSetupDetails', false)){
            return null;
        }
        const { additionalSetupDetails:{isTaxExemptionRequested, } } = setup;

        return (
            <div className="row col col-sml-12 col-lrg-6">
                <div className="form__field spc--bottom--med">
                    <input
                        name="additionalSetupDetails_isTaxExemptionRequested"
                        id="isTaxExemptionRequested"
                        type="checkbox"
                        className="input--check input--check--preview"
                        checked={
                            isTaxExemptionRequested
                        }
                        value={
                            isTaxExemptionRequested
                        }
                        onChange={noop}
                    />
                    <label
                        htmlFor="isTaxExemptionRequested"
                        className="label"
                    >
                        Request Tax Exemption
                    </label>
                </div>
            </div>
        );
    }
}

USSetupConfirm.propTypes = {
    additionalSetupDetails: PropTypes.shape({
        isTaxExemptionRequested: PropTypes.bool,
        isCurrentlyAcceptingCreditCards: PropTypes.bool,
        requiresPricingAnalysis: PropTypes.bool,
        hadStatementsReviewed: PropTypes.bool,
        statementsReviewedDba: PropTypes.string,
    }),
};