import { maxLength } from "./validators";
const fromNameRegex = /^[a-zA-Z 0-9 -.@\/_;]*$/;
const fromName = (value) => {
    return value ? fromNameRegex.test(value) : true;
}
export const cobrandingSettingsTemplate = {
    fromName: {
        type: String,
        use: { fromName, maxLength: maxLength(64) },
        message: {
            fromName: '[**From Name**](javascript:void) contains invalid characters.',
            maxLength: '[**From Name**](javascript:void) is invalid. Maximum number of characters allowed is 64.',
        },
    },
};