import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {ExportToCsv} from 'export-to-csv';
import { cloneDeep, find, map } from 'lodash';

import {renderIf, OutsideClick} from './../../utilities';
import {exportService} from './exportService';

import {exportOptions} from './export-options';
import ExportTypeDropdown from './export-dropdown';

import { generateFileName } from './helpers';

class ExportComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expand: false,
        };
    }

    getCollectionItem = (collection, key) => {
        return find(collection, {key});
    };

    onOpen = e => {
        if (this.props.data && this.props.data.length > 0) {
            this.setState({
                expand: true
            });
        }
    };

    onClose = e => {
        this.setState({
            expand: false
        });
    };

    export = async option => {
        const {type: {key, name}, all} = option;
        this.showLoader(true);
        let fields = null;
        if (this.props.columns && this.props.columns.length > 0) {
            fields = map(this.props.columns, (field) => {
                return field.key;
            });
            fields = fields.join(',');
        }

        if (key === 'csv') {
            await this.exportToCsv(all, fields);
        }
        this.showLoader(false);
    };

    exportToCsv = async (all, fields = null) => {
        let data = null;
        let options = exportOptions;
        options.filename = generateFileName(all);

        if (all) {
            if (this.props.exportType === 'merchants') {
                data = await exportService.getMerchantData(this.props.filters);
            }
            else if (this.props.exportType === 'leads') {
                data = await exportService.getLeadData(this.props.filters);
            }
            else if (this.props.exportType === 'tickets') {
                data = await exportService.getTicketData(this.props.filters);
            }
            else if (this.props.exportType === 'equipment') {
                data = await exportService.getEquipmentData(this.props.filters);
            }
            data = this.replaceColumns(data, this.props.columns);
        }
        else
        {
            data = cloneDeep(this.props.data);
            if (this.props.exportType === 'merchants') {
                exportService.mapMerchantData(data);
            } 
            else if (this.props.exportType === 'leads') {
                exportService.mapLeadData(data);
            } 
            else if (this.props.exportType === 'tickets') {
                exportService.mapTicketData(data);
            } 
            else if (this.props.exportType === 'equipment') {
                exportService.mapEquipmentData(data);
            }
            else if (this.props.exportType === 'residuals') {
                exportService.mapResidualsData(data);
            }
            data = this.replaceColumns(data, this.props.columns);
        }

        const exporter = new ExportToCsv(options);
        exporter.generateCsv(data);
    };

    fixEndDateFilter = (propfilters) => {
        let filters = cloneDeep(propfilters);
        const dateFilter = this.getCollectionItem(filters, 'date');
        if (dateFilter && dateFilter.values && dateFilter.values.endDate) {
            let endDateFilter = dateFilter.values.endDate;
            endDateFilter.endOf('day');
        }
        return filters;
    };

    replaceColumns = (data, columnsSource) => {
        const columns = this.getColumns(columnsSource);
        if (data && data.length > 0) {
            let newData = [];
            for (let obj of data) {
                let newObj = {};
                const columnKeys = Object.keys(columns);
                for (let colKey of columnKeys) {
                    const newPropName = columns[colKey];
                    newObj[newPropName] = obj[colKey] === undefined || obj[colKey] === null ? '' : obj[colKey];
                }
                newData.push(newObj);
            }
            return newData;
        }
        return [];
    };

    getColumns = (columns) => {
        let returnObj = [];
        for (let col of columns) {
            returnObj[col.key] = col.name.replace(/\u00AD/g, '');
        }
        return returnObj;
    }

    showLoader = (show) => {
        this.props.showLoaderMethod(show);
    };

    render() {
        const { exportType, data, showDropdown } = this.props;
        const enableExport = data && data.length > 0;

        let allTitle = 'All merchants';
        
        return showDropdown ? (
            <React.Fragment>
                <button className="btn btn--ghost btn--med datatooltip--bottom" onClick={this.onOpen} disabled={!enableExport} data-tooltip="Export"><i className="icon icon--xsml icon--download align--v--sub" /></button>
                {renderIf(this.state.expand)(
                    <OutsideClick action={this.onClose}>
                        <div className="buttondropdown">
                            <ExportTypeDropdown title="Current view" selectedExport={this.export} />
                            <ExportTypeDropdown title={allTitle} selectedExport={this.export} all={true} />
                        </div>
                    </OutsideClick>
                )}
            </React.Fragment>
        ) : (
            <button
                className="btn btn--ghost btn--med datatooltip--bottom datatooltip--200"
                onClick={() => this.export({type: {key: 'csv', name: 'CSV'}, all: false})}
                disabled={!enableExport}
                data-tooltip="Please note if you're using a shared device, the file will be available to anyone using this device."
            >
                <i className="icon icon--xsml icon--download align--v--sub" />
            </button>
        );
    }
}

ExportComponent.defaultProps = {
    showDropdown: true,
};

ExportComponent.propTypes = {
    data: PropTypes.array,
    columns: PropTypes.array,
    visibleColumns: PropTypes.array,
    filters: PropTypes.array,
    exportType: PropTypes.oneOf(['merchants','leads','tickets','equipment']),
    showLoaderMethod: PropTypes.func.isRequired,
    showDropdown: PropTypes.bool,
};

export default ExportComponent;