import React, { Component, Fragment, createRef } from 'react';
import { withLoader } from "../../common/components";
import { withStepup } from "../stepup-mfa"
import PropTypes from 'prop-types';
import { Data } from '../../common/components/react-data-grid-addons';
import { cloneDeep, each, find, unionBy, map, noop, concat, identity, mapValues, get, sortBy, flatMap, toArray, camelCase, toNumber, round, size } from 'lodash';
import { defaultResidualsColumns as Columns, extractColumnsFromData } from './column-filter/residualsColumns';
import { residualsFilter as Filter, compileFilter } from './filter/residualsFilter';
import { appService } from '../../services/appService';
import { GridComponent, ToolbarComponent } from '../../common/components/grid';
import { ModalWrapper, modalNames } from './../../common/components/modal-wrapper';
import { ZebraRenderer } from '../../common/components/row';
import { GridTooltip } from '../../common/components/tooltips';
import { Notification } from '../../common/components/notifications';
import { LoadMoreOptions } from '../../common/utilities';

import {
    getPage,
    hasMoreData,
    onLoadMoreLimitChange,
    openActions,
    parseQueriesToFilters,
    queryFilterValues,
    mapCellArgs
} from '../../common/components/grid/commonGridMethods';
import ResidualsFilterComponent from './ResidualsFilterComponent';
import { componentKeys } from '../stepup-mfa/stepupConstants';
const loadMoreOptionsWithAll = concat(LoadMoreOptions, [0]);
const agentLimitForConcurrentDownload = 10;

class ResidualsGrid extends Component {
    constructor(props) {
        super(props);
        this.associatedAgentsResidualsData = {};
        this.associatedAgentsResidualsPromises = {};
        this.concurrentDownloadMarker = null;
        this.state = cloneDeep(this.initialState);

        this.gridRef = createRef();
        this.notificationRef = createRef();

        this.setState = this.setState.bind(this);
        this.onLoadMoreLimitChange = onLoadMoreLimitChange.bind(this);
        this.queryFilterValues = queryFilterValues.bind(this);
        this.parseQueriesToFilters = parseQueriesToFilters.bind(this);
    }

    get initialState() {
        const filters = unionBy(this.props.location.filters, Filter, 'key') || Filter;
        const columns = cloneDeep(Columns);

        return {
            data: null,
            dataOriginal: null,
            title: null,
            filteredRows: [],
            fullFilteredRows: [],
            filters: filters,
            activeFilters: cloneDeep(filters),
            inlineFilters: {},
            expanded: {},
            maxGridHeight: 0,
            columns,
            defaultColumns: columns,
            fetchingData: true,
            fetchingAdditionalData: false,
            showFilters: true,
            lastApiRefNum: null,
            activePage: 1,
            rowsPerPage: 0,
            totalRowCount: 0,
            isMfaInitialized: this.props.isMfaInitialized,
            modal: {
                name: modalNames.none,
                data: null
            },
            filterProps: {
                isLoadingAgents: false,
                agents: [],
                setResidualsData: this.setResidualsData,
                enableFetch: this.enableFetch,
            },
            fetchEnabled: false,
        };
    }

    componentDidMount() {
        this.parseQueriesToFilters(false);
    };

    setStateAsync = async newState => {
		return new Promise(resolve => {
			this.setState(newState, resolve);
		});
	};
    
    handlePageChange = (pageNumber) => {
        this.handleResidualGridChange([{ key: 'activePage', value: pageNumber }]);
    };

    enableFetch = () => this.setState({fetchEnabled: true})

    setResidualsData = async (agent = null) => {
        const { dataOriginal, rowsPerPage, activePage, filters, isResidualAdmin } = this.state;
        const filterCompiled = compileFilter(filters);
        let dataObj = null;
        this.setState({fetchingData: true, filteredRows:[]})
        if(!isResidualAdmin){
            if(agent === null){
                dataObj = dataOriginal;
            }else{
                if (!this.associatedAgentsResidualsPromises[agent.agentNumber] && !this.associatedAgentsResidualsData[agent.agentNumber]) {
                    this.associatedAgentsResidualsPromises[agent.agentNumber] = new Promise(resolve => {
                        filterCompiled.agentId = agent.id;
                        filterCompiled.agentNumber = agent.agentNumber;
                        appService.getResidualDataForAMonth(filterCompiled)
                        .then((response) => {
                            this.associatedAgentsResidualsData[agent.agentNumber] = response.data;
                        })
                        .then(resolve)
                    });
                    await this.associatedAgentsResidualsPromises[agent.agentNumber];
                } else if (!this.associatedAgentsResidualsData[agent.agentNumber]) {
                    await this.associatedAgentsResidualsPromises[agent.agentNumber];
                }
                const residualData = this.associatedAgentsResidualsData[agent.agentNumber];
                dataObj = { xReportData: residualData };
            }
        }else{
            filterCompiled.agentId = agent ? agent.id : null;
            filterCompiled.agentNumber = agent ? agent.agentNumber : null;
            filterCompiled.agentName = agent ? agent.agentName : null;
            let data = (await appService.getResidualDataForAMonth(filterCompiled)).data;
            dataObj = { xReportData: flatMap(data, identity) };
        }
        
        const columnsCopy = extractColumnsFromData(dataObj.xReportData); 
        const formattedColumns = this.formatColumns(columnsCopy, cloneDeep(filterCompiled));

        const filteredRows = dataObj ? Data.Selectors.getRows({
            rows: dataObj.xReportData,
            filters: this.state.inlineFilters,
        }) : [];
        const pagedFilteredRows = getPage(filteredRows, activePage, rowsPerPage);
        this.setState({data: dataObj, fullFilteredRows: filteredRows, filteredRows: pagedFilteredRows, columns: formattedColumns,fetchingData:false })
    }

    fetchData = async (filters) => {
        this.concurrentDownloadMarker = {};
        const filter = compileFilter(filters);
        this.setState({
            fetchingData: true,
            data: null,
            filteredRows: [],
            fullFilteredRows: [],
            expanded: {},
            lastApiRefNum: null,
            totalRowCount: 0,
        });
        this.props.showLoader(true);

        let lastApiRefNum = null;
        const { rowsPerPage, activePage, filterProps:{agents}, isResidualAdmin } = this.state;
        let data = null;
        let isAdminFromFetch = null;

        const dataFetchPromise = appService.getResidualDataForAMonth(filter);
        let promises = [dataFetchPromise];
        isAdminFromFetch = await this.fetchAgentRPermissionsData(filter, agents);
        if(!(isResidualAdmin || isAdminFromFetch)){
            promises.push(this.fetchAssociatedAgentsResidualsData(filter));
        }
        await Promise.all(promises);
        this.setState({ isMfaInitialized: true }, () => {
            this.gridRef.current.calculateColumnWidths();
        });
        data = (await dataFetchPromise).data;

        if (this.gridRef.current && data) {
            lastApiRefNum = data.refNum;
            let dataObj = { xReportData: flatMap(data, identity) };
            
            if (get(dataObj, "xReportData", false)) {
                dataObj.xReportData = map(dataObj.xReportData, this.mapRow);
            }
            const columns = extractColumnsFromData(dataObj.xReportData);

            const formattedColumns = this.formatColumns(columns, cloneDeep(filter));
            this.mapData(dataObj);
            const filteredRows = data ? Data.Selectors.getRows({
                rows: dataObj.xReportData,
                filters: this.state.inlineFilters,
            }) : [];

            const pagedFilteredRows = getPage(filteredRows, activePage, rowsPerPage);

            if (this.gridRef.current) {
                this.gridRef.current.scrollTo({ top: 0, left: 0 });
            }

            this.setState({
                data: dataObj,
                dataOriginal: dataObj,
                fullFilteredRows: filteredRows,
                filteredRows: pagedFilteredRows,
                columns: formattedColumns,
                lastApiRefNum: lastApiRefNum,
                totalRowCount: filteredRows.length
            }, () => {
                this.props.showLoader(false);
                if (this.gridRef.current) {
                    this.gridRef.current.handleInitialSort();
                }
            });
        }
        this.setState({
            fetchingData: false,
        });
    };

    fetchAgentRPermissionsData = async (filter, agents) =>{
        const {filterProps} = this.state;
        let agentRPermissionsData = null;
        let isAdmin = false;
        if(!get(filter, "agentId", null) && agents.length === 0){
            await this.setStateAsync({
                title:"My Residuals",
                filterProps: {
                    ...filterProps,
                    isLoadingAgents: true,
                    agents: [],
                },
            })
            agentRPermissionsData = await appService.getAgentResidualsPermissions();
            agentRPermissionsData.agentsList = mapValues(agentRPermissionsData.agentsList, (data) => {
                return { id: data.item1, agentNumber: data.item2, name: data.item3}
            });
            sortBy(agentRPermissionsData.agentsList, agent => agent.name);
            isAdmin = agentRPermissionsData.isAdmin;
            await this.setStateAsync({
                filterProps: {
                    ...filterProps,
                    isLoadingAgents: false,
                    agents: agentRPermissionsData.agentsList,
                },
                isResidualAdmin: isAdmin
            },)
        }else if(get(filter, "agentId", null)){
            let filterPropsClone = cloneDeep(filterProps);
            filterPropsClone.singleAgentName = filter.agentName;
            await this.setStateAsync({title:"Residuals", filterProps:filterPropsClone})
        }
        return isAdmin;
    }

    fetchAssociatedAgentsResidualsData = async(filter) =>{
        const concurrentDownloadMarker = {};
        this.concurrentDownloadMarker = concurrentDownloadMarker;
        this.associatedAgentsResidualsData = {};
        this.associatedAgentsResidualsPromises = {};
        const numberOfAgents = size(this.state.filterProps.agents);
        if (numberOfAgents > agentLimitForConcurrentDownload || numberOfAgents === 0) return;
        const filterCompiled = cloneDeep(filter);
        for (let agent of toArray(this.state.filterProps.agents)) {
            if (concurrentDownloadMarker !== this.concurrentDownloadMarker) return;
            if (this.associatedAgentsResidualsPromises[agent.agentNumber] || this.associatedAgentsResidualsData[agent.agentNumber]) continue;
            filterCompiled.agentId = agent.id;
            filterCompiled.agentName = agent.agentName;
            filterCompiled.agentNumber = agent.agentNumber;
            this.associatedAgentsResidualsPromises[agent.agentNumber] = new Promise(resolve => {
                appService.getResidualDataForAMonth(filterCompiled)
                .then(residualData => {
                    if (concurrentDownloadMarker !== this.concurrentDownloadMarker) return;
                    this.associatedAgentsResidualsData[agent.agentNumber] = residualData.data;
                })
                .then(resolve);
            });
            await this.associatedAgentsResidualsPromises[agent.agentNumber];
        }
    }

    mapData = (data) => {
        let i = 0;
        if (get(data, "xReportData", false) && data.xReportData.length > 0) {
            each(data.xReportData, item => {
                item.uniqueId = i;
                item.openActions = openActions((e, d = noop) => this.setState(e, d), this.gridRef);
                item.showLoader = this.props.showLoader;
                item.gridRowNumber = i;
                item.index = i;
                i++;
            });
        }
    };

    formatColumns = (columns, appliedFilter = null) => {
        if (appliedFilter) {
            for (let prop in appliedFilter) {
                if (appliedFilter.hasOwnProperty(prop)) {
                    let column = find(columns, i => {
                        return i.key.toLowerCase() === prop.toLowerCase() && !i.visible
                    });

                    if (column) {
                        column.visible = true;
                    }
                }
            }
        }
        return columns;
    };

    mapRow = (row) => ({
        ...row,
        isExpandable: true,
        refreshGridData: this.gridRef.current ? this.gridRef.current.refreshGridData : null,
        onInfoHover: this.onInfoHover,
        openCloseModal: this.openCloseModal,
    });

	onInfoHover = (infoDimensions, tooltip) => {
		this.setState({ tooltipProps: { infoDimensions, tooltip } });
	};

    refetchData = async () => {
        await this.fetchData(this.state.activeFilters);
    };

    handleResidualGridChange = (changes) => {
        const newState = {};
        each(changes, ({ key, value }) => {
            if (key === 'data' || key === 'inlineFilters' || key === 'activePage') {
                let filters, data, activePage;
                if (key === 'data') {
                    filters = this.state.inlineFilters;
                    activePage = 1;
                    data = value;
                } else if (key === 'inlineFilters') {
                    filters = value;
                    data = this.state.data;
                    activePage = 1;
                } else {
                    activePage = value;
                    data = this.state.data;
                    filters = this.state.inlineFilters;
                }
                let filteredRows = get(data, "xReportData", false) ? Data.Selectors.getRows({
                    rows: data.xReportData,
                    filters,
                }) : [];
                const pagedFilteredRows = getPage(filteredRows, activePage, this.state.rowsPerPage);
                newState.filteredRows = pagedFilteredRows;
                newState.fullFilteredRows = filteredRows;
                newState.totalRowCount = filteredRows.length;
                newState.activePage = activePage;
            }
            newState[key] = value;
        });
        return this.setStateAsync(newState);
    };

    renderGridFooter = () =>{
        return (
			<Fragment>
                <div className="grid__footer__details">
                    <span className="spc--right--tny type--wgt--bold">Grand Total:</span>
                    <span>${this.getGrandTotal()}</span>
                    <a
                        href="javascript:void(0)"
                        className="anchor anchor--primary type--wgt--medium spc--left--sml"
                        onClick={() => this.openCloseModal({
                            name: modalNames.groupedTotals,
                            data: {
                                totals: this.getAmountsByType(),
                                columns: ['Total'],
                                emptyMessage: 'No Data',
                                seperateCountColumn: true,
                                typeList: this.getDistinctTypes(),
                                addNotification: this.addNotification,
                                type: 'residualTotals',
                            },
                        })}
                    >
                        Total by Income Source
                    </a>
                </div>
			</Fragment>
		);
    }
    getGrandTotal = () => {
        const data = Data.Selectors.getRows({
            rows: this.state.data.xReportData,
        })
        let total = 0;
        each(data, (row) => {
            total += round(toNumber(row['agent_share']), 2)
        });
        return round(total, 2);
    };
    getAmountsByType = () => {
        const data = Data.Selectors.getRows({
            rows: this.state.data.xReportData,
        })
        const totals = {};
        each(data, (row) => {
            const agentShare = row['agent_share']
            const type = camelCase(row['income_source']);
            if (find(totals, (_, key) => key === `${type}TotalCount`)) {
                totals[`${type}TotalCount`]++;
                totals[`${type}TotalAmount`] += round(toNumber(agentShare), 2);
            } else {
                totals[`${type}TotalCount`] = 1;
                totals[`${type}TotalAmount`] = toNumber(agentShare);
            }
            totals[`${type}TotalAmount`] = toNumber(totals[`${type}TotalAmount`].toFixed(2));
        });
        return totals;
    };

    getDistinctTypes = () => {
        const data = Data.Selectors.getRows({
            rows: this.state.data.xReportData,
        })
        const distinctTypes = [...new Set(data.map((row) => row['income_source']))]
        return distinctTypes
    }

    openCloseModal = (modalObj, ...rest) => {
        let state = {
            modal: modalObj
        };
        this.setState(state);
    };

    renderModals = props => <ModalWrapper {...props} />;

    render = () => {
        const {
            modal,
            title,
            fetchingAdditionalData,
            fetchingData,
            filteredRows,
            fullFilteredRows,
            columns,
            data,
            inlineFilters,
            expanded,
            filters,
            activeFilters,
            lastApiRefNum,
            defaultColumns,
            activePage,
            rowsPerPage,
            totalRowCount,
            tooltipProps,
            filterProps,
            isMfaInitialized,
        } = this.state;

        return (
           <Fragment>
               <div className={`l--content--grid fs-exclude ${!isMfaInitialized ? 'display--n' : ''}`}>
               <Notification ref={this.notificationRef} />
               <ModalWrapper modal={modal} onModalClose={this.openCloseModal} />
               <GridComponent
                   emptyStateClassName="grid__empty-state--top"
                   emptyMessage="There is no data to display"
                   fetchingData={fetchingData}
                   fetchingAdditionalData={fetchingAdditionalData}
                   filteredRows={filteredRows}
                   fullFilteredRows={fullFilteredRows}
                   loadMoreOptions={loadMoreOptionsWithAll}
                   onLoadMoreLimitChange={this.onLoadMoreLimitChange}
                   loadMoreLimit={rowsPerPage}
                   columns={columns}
                   fixHeader={true}
                   data={data}
                   inlineFilters={inlineFilters}
                   components={{
                       toolbar: ToolbarComponent,
                       filter: ResidualsFilterComponent,
                       rowRenderer: ZebraRenderer,
                       tooltip: GridTooltip,
                       modal: this.renderModals,
                       gridFooter: this.renderGridFooter
                   }}
                   onChange={this.handleResidualGridChange}
                   rowKey="uniqueId"
                   isExpandable={true}
                   title={title}
                   enableExport={true}
                   enablePrint={true}
                   type="residuals"
                   filters={filters}
                   activeFilters={activeFilters}
                   minColumnWidth={150}
                   showFilters={true}
                   fetchData={this.state.fetchEnabled ? this.refetchData : noop}
                   lastApiRefNum={lastApiRefNum}
                   showResults={true}
                   mapCellArgs={mapCellArgs}
                   showExportDropdown={false}
                   showPrintDropdown={false}
                   filterColumns={true}
                   defaultColumns={defaultColumns}
                   expanded={expanded}
                   enableFilters={true}
                   ref={this.gridRef}
                   useInlineFilters={true}
                   initialFetch={false}
                   hasPaging={false}
                   hasMoreData={hasMoreData(rowsPerPage, totalRowCount)}
                   handlePageChange={this.handlePageChange}
                   pagination={{ activePage, rowsPerPage, totalRowCount }}
                   tooltipProps={tooltipProps}
                   filterProps={filterProps}
                   syncQueryFilters={true}
                   queryFilterValues={this.queryFilterValues}
                   onModalToggle={this.handleModalToggle}
                   canReorderColumns={true}
               />
               </div>
           </Fragment>
       );
    };
}

ResidualsGrid.propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    showLoader: PropTypes.func.isRequired,
    isMfaInitialized: PropTypes.bool.isRequired,
};
export default withLoader(withStepup(ResidualsGrid, componentKeys.RESIDUALS, true));