import React, { createRef } from 'react';
import { withRouter } from 'react-router-dom';
import { get } from "lodash"
import { withLoader } from '../../common/components';
import withBlock from '../../common/components/block/block-hoc';
import { IdentificationTypeDropdownComponent } from '../../common/components/identification-type-dropdown'
import { CanadaMerchantMPATemplate, CanadaMPAInvalidTemplate, CanadaMPARequiredTemplate } from '../../validation';
import BaseMPA from './BaseMPA';
import withMerchantMpa from './withMerchantMpa';
import withMpa from './withMpa';
import SingleDatePicker from '../../common/filters/date-picker/single-date-picker';
import moment from 'moment';

class CanadaMPA extends BaseMPA {
    identificationIssueDateRef = createRef();
    identificationExpirationDateRef = createRef();

    renderRoutingNumber(mpa) {
        return (
            <div className="col col-sml-12">
                <div className="row grid-align-middle">
                    {this.renderField(mpa, 'bankAccountInformation.institutionNumber', 'Institution Number', { maxLength: 3 }, 'fs-mask')}
                    {this.renderField(mpa, 'bankAccountInformation.transitNumber', 'Transit Number', { maxLength: 5 }, 'fs-mask')}
                </div>
            </div>
        )
    }

    renderAdditionalPaymentDetails(mpa, isToggled, handleToggle) {
        return (
            <div className="card--primary card--sml spc--bottom--med">
                {this.renderCardHeader('Additional Payment Details', isToggled, handleToggle)}
                <div className={`details__content ${isToggled ? '' : 'display--n'}`}>
                    <div className="row flex--align--bottom">
                        {this.renderAmexEsa(mpa)}
                    </div>
                </div>
            </div>
        );
    }

    renderIdentificationInfo(mpa) {
        return (
            <div className="col col-sml-12">
                <div className="row">
                    <div className="col col-sml-12 col-med-6 spc--bottom--sml">
                        <div className={`form__field spc--bottom--med ${this.props.requiredFields['IdentificationType'] ? ' required' : ''}`}>
                            <label className="label">Identification Type </label>
                            <IdentificationTypeDropdownComponent
                                className={`input input--med input--select${this.props.validateField('signerInformation.identificationType')}`}
                                id="signerInformation_identificationType"
                                value={mpa.signerInformation.identificationType}
                                onChange={this.props.isMerchant ? this.props.handleChange : this.props.handleIdNumberAndTypeChange}
                            />
                        </div>
                    </div>
                    <div className="col col-sml-12 col-med-6">
                        <div className={`form__field spc--bottom--med ${this.props.requiredFields['IdentificationNumber'] ? ' required' : ''}`}>
                            <label className="label">Identification Number </label>
                            <input
                                type="text"
                                className={`input input--med${this.props.validateField('signerInformation.identificationNumber')}`}
                                name='signerInformation_identificationNumber'
                                value={mpa.signerInformation.identificationNumber}
                                onChange={this.props.isMerchant ? this.props.handleChange : this.props.handleIdNumberAndTypeChange}
                            />
                        </div>
                    </div>
                    {this.renderIdentificationIssueDate(mpa, this.props.requiredFields['IdentificationIssueDate'])}
                    {this.renderIdentificationExpirationDate(mpa, this.props.requiredFields['IdentificationExpirationDate'])}
                </div>
            </div>
        );
    }

    renderIdentificationIssueDate(mpa, required) {
        return (
            <div className="col col-sml-12 col-med-6">
                <div id="signerInformation_identificationIssueDate_div" className={"form__field spc--bottom--med"}>
                    <div className={`flex--primary ${required ? 'required' : ""}`} >
                        <label className="label">Identification Issue Date</label>
                        {<i class="icon icon--tiny icon--info spc--left--tny datatooltip--right datatooltip--200" data-tooltip={"Issue Date must precede Expiration Date"}></i>}
                    </div>
                    <SingleDatePicker
                        name={"signerInformation_identificationIssueDate"}
                        onChange={(date) => this.props.handleChange({ target: { name: "signerInformation_identificationIssueDate", value: date } })}
                        validateField={() => this.props.validateField('signerInformation.identificationIssueDate')}
                        value={get(mpa,"signerInformation.identificationIssueDate", "")}
                        disabledDays={
                                {
                                    after: get(mpa,"signerInformation.identificationExpirationDate", null) && moment(get(mpa,"signerInformation.identificationExpirationDate", null)).subtract(1, "day").toDate(),
                                }
                            }
                        />
                </div>
            </div>
        );
    }

    renderIdentificationExpirationDate(mpa, required) {
        return (
            <div className="col col-sml-12 col-med-6">
                <div id="signerInformation_identificationExpirationDate_div" className={`form__field spc--bottom--med ${required ? ' required' : ''}`}>
                    <label className="label">Identification Expiration Date </label>
                    <SingleDatePicker
                        name={"signerInformation_identificationExpirationDate"}
                        onChange={(date) => this.props.handleChange({ target: { name: "signerInformation_identificationExpirationDate", value: date } })}
                        validateField={() => this.props.validateField('signerInformation.identificationExpirationDate')}
                        value={get(mpa,"signerInformation.identificationExpirationDate", "")}
                        disabledDays={
                            {
                                before: get(mpa,"signerInformation.identificationIssueDate", null) && moment(get(mpa,"signerInformation.identificationIssueDate", null)).add(1, "day").toDate(),
                            }
                        }
                        />
                </div>
            </div>
        );
    }

    renderSocialSecurityNumberLabel() {
        return 'Social Insurance Number';
    }

    isSSNRequired(mpa) {
        const missingIdInfo = !mpa.signerInformation.identificationIssueDate && !mpa.signerInformation.identificationExpirationDate && !mpa.signerInformation.identificationNumber && !mpa.signerInformation.identificationType
        return this.props.requiredFields['signerInformation.socialSecurityNumber'] && missingIdInfo ? ' required' : ''
    }

}

export const CanadaAgentMPA = withBlock(withLoader(withMpa(CanadaMPA, CanadaMPAInvalidTemplate, CanadaMPARequiredTemplate, {
    'bankAccountInformation.institutionNumber': true,
    'bankAccountInformation.transitNumber': true,
})));
export const CanadaMerchantMPA = withRouter(withLoader(withMerchantMpa(CanadaMPA, CanadaMerchantMPATemplate)));