import React from 'react';
import { string } from 'prop-types';
import { decode } from 'jsonwebtoken';

import { emailService } from '../../services';
import { emailTemplates } from '../../common/utilities';

class RequestNewLink extends React.Component {
    state = {
        isLoading: false,
        errorMessage: null,
        isCompleted: false,
    };

    showLoader = (value = false) => this.setState({ isLoading: value });

    sendEmail = async () => {
        this.showLoader(true);
        try {
            const { payload: { email, actort, nameid, uniquename } } = decode(this.props.token, { complete: true });
            await emailService.send(emailTemplates.requestNewLink, {
                appId: nameid,
                email,
                agentEmail: actort,
                agentName: uniquename,
            });
            this.setState({
                isCompleted: true,
            });
        } catch (e) {
            this.setState({ errorMessage: e.message });
        } finally {
            this.showLoader(false);
        }
    };

    render() {
        const { isLoading, errorMessage, isCompleted } = this.state;
        if (isCompleted) {
            return (
                <div className="spc--top--sml spc--bottom--sml note note--success">
                    We’ve forwarded your request for an updated application link to your Sales Agent. They will send an updated email at their earliest availability.  If you need additional assistance, you can reach our customer service team via email at <a className="anchor anchor--primary" href="mailto:cs@cardknox.com">cs@cardknox.com</a>.
                </div>
            )
        }
        if (isLoading) {
            return (
                <div className="spc--bottom--lrg">
                    <div className="loader__spinner"></div>
                </div>
            )
        }
        if (errorMessage) {
            return (
                <div className="spc--top--sml spc--bottom--sml note note--warning">
                    {errorMessage}
                </div>
            )
        }
        return (
            <div>
                <div className="note note--warning spc--bottom--med">Your link expired. Please submit a new request below, and your Sales Agent will send out an updated link.</div>
                <div className="clearfix push">
                    <button className="btn btn--med btn--primary" type="button" onClick={this.sendEmail}>Request New Link</button>
                </div>
            </div>
        );
    }
}

RequestNewLink.propTypes = {
    token: string.isRequired,
};

export default RequestNewLink;