import React from 'react';
import { NavLink } from 'react-router-dom';
import { withLoader } from "./../../common/components";
import { appService } from '../../services/appService';
import { default as NewNote } from './new-note';

class NewNotePopup extends React.Component {
    constructor(props) {
        super(props);
        //console.log(this.props);
        this.state = {
            notesList: ['',''],
            saveResults: null
        }
    }

    onNoteChange = (idx) => (e) => {
        //console.log('updating');
        const notesArray = [...this.state.notesList]; 
        notesArray[idx] = e.target.value;
        this.setState({ notesList: notesArray });
    }

    onSave = () => {
        this.props.showLoader(true);
        const { appid } = this.props;
        const notes = this.state.notesList;
        let saveResults = [];
        
        let allNotes = notes.map((note, idx) => {
            if (note && note.trim().length > 0) {
                return appService.addNote(appid, note)
                    .then(() => {
                        saveResults.push( { index: idx, result: true });
                    })
                    .catch(err => {
                        saveResults.push({ index: idx, result: false, msg: err });
                    })
            }
            return Promise.resolve(null);
        });
        Promise.all(allNotes).then(() => {
            let errorMessage = '',
                successMessage = '';
            this.props.showLoader(false);
            if (saveResults.find(s => !s.result) && saveResults.find(s => s.result)) {
                errorMessage = 'Some notes could not be saved - ' + saveResults.find(s => !s.result).msg;
            }
            else {
                if (saveResults[0].result)
                    successMessage = 'Saved successfully';
                else
                    errorMessage =  'Error saving notes = ' + saveResults[0].msg;
            }
            this.setState({ errorMessage, successMessage });
        });
        //console.log(saveResults);        
    }

    onDelete = (idx) => () => {
        //console.log('deleting');
        const notesArray = [...this.state.notesList]; 
        notesArray.splice(idx, 1);
        this.setState({ notesList: notesArray });
    }

    onAdd = () => {
        //console.log('adding');
        const notesArray = [...this.state.notesList];
        notesArray.push('');
        this.setState({ notesList: notesArray });
    }

    renderNotesList(notes) {
        return (!notes) ? null : (
            notes.map((note,idx) => {
                return (
                    <NewNote key={idx} index={idx} noteText={note} onTextChange={this.onNoteChange} onDelete={this.onDelete} />
                );
            })
        );
    }

    render() {
        const { notesList, errorMessage, successMessage } = this.state;

        return (
            <React.Fragment>

                <div ref={this.detailsRef}>
                    {errorMessage ? (
                        <div className="popup__body">
                            <div className="note note--warning note--no-margin">{errorMessage}</div>
                        </div>
                    ) : null}

                    {successMessage ? (  
                        <div className="popup__body">  
                            <div className="note note--success note--no-margin">{successMessage}</div>
                        </div>
                    ) : null}

                    {!errorMessage && !successMessage && notesList ? ( 
                        <React.Fragment>
                            <header className="popup__header">
                                <h4 className="popup__header__title">Add Note</h4>
                            </header>
                            <div className="popup__body">                            
                                <div className="spc--bottom--med">
                                    {this.renderNotesList(notesList)}
                                </div>
                                <div className="type--center">
                                    <a className="anchor--primary anchor--underline" href="javascript:void(0);" onClick={this.onAdd}>+ Add New Note</a>
                                </div>
                            </div>
                            <div className="popup__footer popup__footer--styled">
                                <div className="type--right">
                                    {!this.props.hideViewAll &&
                                        <NavLink className="btn btn--med btn--ghost spc--right--tny" to={`/merchants/${this.props.appid}/notes`}>View All Notes</NavLink>
                                    }
                                    <button className="btn btn--med btn--ghost spc--right--tny" onClick={this.props.closeModal}>Cancel</button>
                                    <button onClick={this.onSave} className="btn btn--med btn--primary">Save</button>
                                </div>
                            </div>
                            
                            
                        </React.Fragment>
                    ) : null}
                </div>
            </React.Fragment>
        );
    }
}

export default withLoader(NewNotePopup);