import React, { Component, Fragment, createRef } from 'react';
import PropTypes from 'prop-types';
import { Data } from '../../common/components/react-data-grid-addons';
import { cloneDeep, each, find, unionBy, map, get, noop, remove, concat, sortBy, toLower, includes } from 'lodash';
import moment from 'moment';
import { TicketColumns as Columns } from './column-filter/ticketColumns';
import { ticketsFilter as Filter, compileFilter } from './filter/ticketsFilter';
import { appService } from '../../services/appService';
import { GridComponent, ToolbarComponent } from '../../common/components/grid';
import { ModalWrapper, modalNames } from './../../common/components/modal-wrapper';
import { SavedFilterComponent } from '../../common/components/filter';
import { ZebraRenderer } from '../../common/components/row';
import { actionsGridColumn } from '../../common/components/actionsGrid/ticketsActionsGridColumn';
import { Notification } from '../../common/components/notifications';
import { GridTooltip } from '../../common/components/tooltips';
import { LoadMoreOptions } from '../../common/utilities';
import {
    formatColumns,
    getPage,
    hasMoreData,
    mapCellArgs,
    mapDataToGridCommon,
    onLoadMoreLimitChange,
    parseQueriesToFilters,
    queryFilterValues,
    saveCurrentView,
    deleteAgentView,
    applyAgentView,
    clearAgentView,
    fetchAgentViews,
} from '../../common/components/grid/commonGridMethods';
import withError from '../../common/components/error/error-hoc';
import { withContext } from '../../common/components';
import { MerchantContext } from '../MerchantDetails';

const loadMoreOptionsWithAll = concat(LoadMoreOptions, [0]);

class TicketGrid extends Component {
    constructor(props) {
        super(props);
        this.viewType = 'Ticket';
        this.state = cloneDeep(this.initialState);

        this.gridRef = createRef();
        this.notificationRef = createRef();

        this.setState = this.setState.bind(this);
        this.mapDataToGrid = mapDataToGridCommon.bind(this);
        this.onLoadMoreLimitChange = onLoadMoreLimitChange.bind(this);
        this.parseQueriesToFilters = parseQueriesToFilters.bind(this);
        this.queryFilterValues = queryFilterValues.bind(this);
        this.saveCurrentView = saveCurrentView.bind(this);
        this.deleteAgentView = deleteAgentView.bind(this);
        this.applyAgentView = applyAgentView.bind(this);
        this.clearAgentView = clearAgentView.bind(this);
        this.fetchAgentViews = fetchAgentViews.bind(this);
    }

    get initialState() {
        const filters =
            unionBy(this.props.location.filters, cloneDeep(Filter), 'key') || cloneDeep(Filter);
        let gridColumns = cloneDeep(Columns);
        if (this.props.match.params && this.props.match.params.appid) {
            filters.push({ key: 'appId', values: { appId: this.props.match.params.appid }, clearable: false });
            remove(filters, (val) => val.key == 'mid' || val.key == 'dba');

            const midColumn = find(gridColumns, { key: 'mid' });	 
            const dbaColumn = find(gridColumns, { key: 'dba' });            
            if (!!midColumn) midColumn.visible = midColumn.hideable = false;                
            if (!!dbaColumn) dbaColumn.visible = dbaColumn.hideable = false;
        }

        return {
            data: null,
            filteredRows: [],
            fullFilteredRows: [],
            merchantOptions: [],
            isFetchingMerchants: true,
            filters: filters,
            activeFilters: cloneDeep(filters),
            inlineFilters: {},
            expanded: {},
            maxGridHeight: 0,
            columns: gridColumns,
            defaultColumns: cloneDeep(gridColumns),
            fetchingData: true,
            fetchingAdditionalData: false,
            showFilters: true,
            lastApiRefNum: null,
            activePage: 1,
            rowsPerPage: 20,
            totalRowCount: 0,
            filterProps: {
                isLoadingAgentViews: false,
                agentViews: [],
                clearAgentView: (...params) => this.clearAgentView(...params),
                applyAgentView: (...params) => this.applyAgentView(...params),
                saveCurrentView: viewName => this.saveCurrentView(viewName),
                deleteAgentView: viewName => this.deleteAgentView(viewName),
            },
            modal: {
                name: modalNames.none,
                data: null,
            },
        };
    }

    async componentDidMount() {
        this.parseQueriesToFilters();
        if (this.props.merchant) {
            this.setState({
                isFetchingMerchants: false,
            });
            return;
        }
        const { merchants } = await appService.getMerchants({});
        this.setState({ isFetchingMerchants: false, merchantOptions: map(sortBy(merchants, ({ dba }) => toLower(dba)), merchant => ({ label: merchant.dba, value: merchant })) });
    }

    componentDidUpdate (prevProps) {
        if (prevProps.location.key !== this.props.location.key && includes(this.props.location.search, 'refresh=true')) {
            this.clearAgentView();
        }
    }

    handlePageChange = (pageNumber) => {
        //console.log('active page is ' + pageNumber);
        this.handleChange([{ key: 'activePage', value: pageNumber }]);
    };

    fetchData = async (filters) => {
        const filter = compileFilter(filters);
        //console.log('fetching data');
        this.setState({
            fetchingData: true,
            data: null,
            filteredRows: [],
            fullFilteredRows: [],
            expanded: {},
            lastApiRefNum: null,
            totalRowCount: 0,
        });

        let lastApiRefNum = null;
        const { rowsPerPage, activePage } = this.state;
        //console.log('rows per page: ' + rowsPerPage + ' active page ' + activePage);

        let data = await appService.getTickets(filter); //(filter);

        // check for gridRef.current to make sure page didn't unload while data was loading
        if (this.gridRef.current)
        {
            lastApiRefNum = data.refNum;
            let dataObj = { xReportData: data.tickets };

            if (dataObj && dataObj.xReportData) {
                dataObj.xReportData = map(dataObj.xReportData, this.mapRow);
            }

            const formattedColumns = formatColumns(
                this.state.columns,
                cloneDeep(filter)
            );
            this.mapData(dataObj);
            this.mapDataToGrid(
                dataObj,
                dataObj.xReportData,
                activePage,
                rowsPerPage,
                formattedColumns,
                lastApiRefNum
            );
        }
    };

    formatDate(inDate) {
        const inputFormat = process.env.REACT_APP_API_RESPONSE_DATE_TIME_FORMAT;
        const outputFormat = process.env.REACT_APP_DISPLAY_DATE_TIME_FORMAT;
        if (inDate) {
            const date = moment(inDate, inputFormat);
            if (date.year() === 1)
                return 'N/A';
            return (
                date.format(outputFormat)
            );
        }
    }

    resolveColumnName = column => {
        let key = column;
        switch (column) {
            case 'dateOpenedDisplay':
                key = 'dateOpened';
                break;
            case 'modifiedDisplay':
                key = 'modified';
                break;
            case 'followUpDateDisplay':
                key = 'followUpDate';
                break;
            default:
                break;
        }
        return key;
    };


    mapData = (data) => {
        let i = 0;
        if (data && data.xReportData && data.xReportData.length > 0) {
            each(data.xReportData, (item) => {
                item.dateOpenedDisplay = this.formatDate(item.dateOpened);
                item.modifiedDisplay = this.formatDate(item.modified);
                item.followUpDateDisplay = this.formatDate(item.followUpDate);
                item.gridRowNumber = i;
                item.index = i;
                i++;
            });
        }
    };

    getCollectionItem = (collection, key) => {
        return find(collection, { key });
    };

    mapRow = (row) => ({
        ...row,
        isExpandable: true,
        refreshGridData: this.gridRef.current ? this.gridRef.current.refreshGridData : null,
        //onRowClick: this.gridRef.current.onRowClick,
        onInfoHover: this.onInfoHover,
        openNewTicketPopup: () => this.openCloseModal({
                name: modalNames.newTicket,
                data: {
                    addNotification: get(this.notificationRef, 'current.addNotification', noop),
                    dba: row.dba,
                    mid: row.mid,
                    appId: row.appId,
                },
            }),
    });

    onRowClick = (...params) => {
        if (params[0] < 0 || params[2].key === 'isHidden' || params[2].key === 'dba') {
            return;
        }
        this.openCloseModal({
            name: modalNames.ticketDetails,
            data: {
                row: params[1],
                addNotification: get(this.notificationRef, 'current.addNotification', noop),
            },
        });
    };

    onInfoHover = (infoDimensions, tooltip) => {
		this.setState({ tooltipProps: { infoDimensions, tooltip } });
	};

    calculatePopupLeft = () => {
        return this.state.infoDimensions.width - 36;
    };

    calculatePopupTop = () => {
        let offset = 0;
        if (
            this.gridRef.current &&
            this.gridRef.current.gridHolderRef.current
        ) {
            offset =
                this.gridRef.current.gridHolderRef.current.getBoundingClientRect()
                    .y -
                this.gridRef.current.gridHolderRef.current.scrollTop -
                25;
        }
        return this.state.infoDimensions.height - offset;
    };

    renderTooltip = () =>
        this.state.tooltip ? (
            <div
                style={{
                    left: this.calculatePopupLeft(),
                    top: this.calculatePopupTop(),
                    position: 'absolute',
                    backgroundColor: '#fff',
                    zIndex: 99,
                }}
            >
                {this.state.tooltip}
            </div>
        ) : null;

    refetchData = () => {
        this.fetchData(this.state.activeFilters);
    };

    handleChange = (changes) => {
        const newState = {};
        each(changes, ({ key, value }) => {
            if (
                key === 'data' ||
                key === 'inlineFilters' ||
                key === 'activePage'
            ) {
                let filters, data, activePage;
                if (key === 'data') {
                    filters = this.state.inlineFilters;
                    activePage = 1;
                    data = value;
                } else if (key === 'inlineFilters') {
                    filters = value;
                    data = this.state.data;
                    activePage = 1;
                } else {
                    activePage = value;
                    data = this.state.data;
                    filters = this.state.inlineFilters;
                }
                let filteredRows =
                    data && data.xReportData
                        ? Data.Selectors.getRows({
                              rows: data.xReportData,
                              filters,
                          })
                        : [];
                const pagedFilteredRows = getPage(
                    filteredRows,
                    activePage,
                    this.state.rowsPerPage
                );
                newState.filteredRows = pagedFilteredRows;
                newState.fullFilteredRows = filteredRows;
                newState.totalRowCount = filteredRows.length;
            }
            newState[key] = value;
        });
        return new Promise((resolve) => {
            this.setState(newState, resolve);
        });
    };

    openCloseModal = (modalObj, ...rest) => {
        let state = {
            modal: modalObj,
        };
        this.setState(state);
    };

    renderNewTicketButton = () => {
        return (
            <button
                disabled={this.state.isFetchingMerchants}
                className="btn btn--primary btn--med"
                onClick={() => this.openCloseModal({
                    name: modalNames.newTicket,
                    data: {
                        addNotification: get(this.notificationRef, 'current.addNotification', noop),
                        dba: get(this.props, 'merchant.dba'),
                        mid: get(this.props, 'merchant.mid'),
                        appId: get(this.props, 'merchant.appId'),
                        merchantOptions: this.state.merchantOptions,
                    },
                })}
            >
                New
                <span className="hide--to--sml spc--left--nano">Ticket</span>
            </button>
        );
    };

    renderTitle = ({ title, className }) => {
        const { merchant } = this.props;

        return (
            <div className={className}>
                {merchant && merchant.dba && merchant.appId && (
                    <div className="type--sml--plus type--uppercase type--color--text spc--bottom--sml">
                        {`${merchant.dba} - ${merchant.appId}`}
                    </div>
                )}
                {title}
            </div>
        );
    };

    renderHeader = ({ refreshGridData }) => (
        <Fragment>
            {this.renderNewTicketButton()}
        </Fragment>
    );

    renderGridHeader = ({ refreshGridData }) => (
        <Fragment>
            {this.renderNewTicketButton()}
        </Fragment>
    );

    render = () => {
        const {
            modal,
            fetchingAdditionalData,
            fetchingData,
            filteredRows,
            fullFilteredRows,
            columns,
            data,
            inlineFilters,
            expanded,
            filters,
            activeFilters,
            lastApiRefNum,
            defaultColumns,
            activePage,
            rowsPerPage,
            totalRowCount,
            tooltipProps,
            filterProps,
        } = this.state;

        return (
            <Fragment>
                <Notification ref={this.notificationRef} />
                <div className="l--content--grid">
                <ModalWrapper
                    modal={modal}
                    onModalClose={this.openCloseModal}
                />
                <GridComponent
                    emptyMessage="You should change your filter options"
                    fetchingData={fetchingData}
                    fetchingAdditionalData={fetchingAdditionalData}
                    filteredRows={filteredRows}
                    fullFilteredRows={fullFilteredRows}
                    loadMoreOptions={loadMoreOptionsWithAll}
                    onLoadMoreLimitChange={this.onLoadMoreLimitChange}
                    loadMoreLimit={rowsPerPage}
                    columns={columns}
                    fixHeader={true}
                        data={data}
                        resolveColumnName={this.resolveColumnName}
                    inlineFilters={inlineFilters}
                    components={{
                        title: this.renderTitle,
                        toolbar: ToolbarComponent,
                        header: this.renderHeader,
                        gridHeader: this.renderGridHeader,
                        filter: SavedFilterComponent,
                        rowRenderer: ZebraRenderer,
                        tooltip: GridTooltip,
                        }}
                        classes={{
                        }}
                    onChange={this.handleChange}
                    rowKey="TicketId"
                    isExpandable={true}
                    expanded={expanded}
                    title="Tickets"
                    enableExport={true}
                    enablePrint={true}
                    type="tickets"
                    filters={filters}
                    activeFilters={activeFilters}
                    showFilters={true}
                    fetchData={this.refetchData}
                    lastApiRefNum={lastApiRefNum}
                    showResults={true}
                    mapCellArgs={mapCellArgs}
                    showExportDropdown={false}
                    showPrintDropdown={false}
                    filterColumns={true}
                    defaultColumns={defaultColumns}
                    onRowClick={this.onRowClick}
                    enableFilters={true}
                    ref={this.gridRef}
                    useInlineFilters={true}
                    hasPaging={true}
                    hasMoreData={hasMoreData(rowsPerPage, totalRowCount)}
                    handlePageChange={this.handlePageChange}
                    pagination={{ activePage, rowsPerPage, totalRowCount }}
                    actionsGridColumn={actionsGridColumn}
                    syncQueryFilters={true}
                    queryFilterValues={this.queryFilterValues}
                    tooltipProps={tooltipProps}
                    filterProps={filterProps}
                    initialFetch={false}
                    canReorderColumns={true}
                />
                </div>
            </Fragment>
        );
    };
}

TicketGrid.propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
};

export default withContext(withError(TicketGrid), MerchantContext, 'merchant');
