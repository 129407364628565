import React, { Component, createRef, Fragment } from 'react';
import { map, find, get, noop, isEmpty, toLower, startsWith, replace, some, each } from 'lodash';
import Select from 'react-select';
import { func, bool, object } from 'prop-types';
import { QRCodeSVG } from 'qrcode.react';
import { dialCodesByCountryList } from './constants';
import { appService, principalService } from '../../services';
import { Auth } from 'aws-amplify';
import { cognitoErrorMap } from '../../common/utilities';
import { mapPasswordRequirements } from './loginUtils';
import { Notification } from '../../common/components/notifications';
import withError from '../../common/components/error/error-hoc';
import { withLoader } from '../../common/components';
import { withCancelable } from '../../common/components/cancelable';
import withBlock from '../../common/components/block/block-hoc';
import ConfirmPasswordBody from './ConfirmPasswordBody';
import { componentKeys, localStorageKeys } from '../stepup-mfa/stepupConstants';
import { removeState } from '../../helpers/persist-state';
import { withStepup } from '../stepup-mfa';

const requestKeys = {
	FETCH: 'fetch',
	SAVE: 'save',
	CONFIRM: 'confirm',
	SEND: 'send',
	SAVE_VERIFY: 'saveVerify',
};

const internationalPhoneNumberRegex = /(\+\d{1,3})(\d{4,14})$/;

const phoneNumberTooltip = `Phone numbers must follow these formatting rules:
	A phone number can only contain digits. You must remove any other characters from a phone number,
	such as parentheses, spaces, or dashes (-) before submitting the value. For example,
	a United States-based phone number must follow this format: 4325551212.`;

const dialCodesList = map(dialCodesByCountryList, ({ dialCode, name }) => ({
	label: `${name} ${dialCode}`,
	value: dialCode,
}));

class Security extends Component {
	principal = principalService.get();
	constructor(props) {
		super(props);
		this.notificationRef = createRef();

		this.state = {
			isMfaRequired: get(props, 'location.state.isMfaRequired', false),
			username: this.principal.email,
			password: '',
			password2: '',
			isSamlLogin: this.principal.isSamlLogin,
			countryDialCode: '+1',
			accessToken: '',
			isSoftwareMfaEnabled: false,
			isSmsMfaEnabled: false,
			enableSmsMfa: false,
			enableSoftwareMfa: false,
			phoneNumberVerified: false,
			phoneNumber: '',
			errorMessages: [],
			user: null,
			code: '',
			mfaCode: '',
			challengeAnswer: '',
			confirmationCode: '',
			displayConfirmCode: false,
			errorMessage: '',
		};
	}

	get getCountryDialCode() {
		const { countryDialCode } = this.state;

		return find(dialCodesList, { value: countryDialCode }) || dialCodesList[0];
	}

	componentDidMount() {
		this.fetchData();
	}

	setComponentState = newState => {
		this.setState(newState);
	};

	fetchData = async () => {
		const { showLoader, makePendingRequest } = this.props;

		try {
			showLoader(true);
			await makePendingRequest(
				Promise.all([
					Auth.currentAuthenticatedUser().then(async user => {
						this.getUserData(user);
					}),
				]),
				requestKeys.FETCH
			);
			showLoader();
		} catch (e) {
			this.handleError(e, true);
		}
	};

	getCognitoUserDataAsync = user => {
		return new Promise((resolve, reject) => {
			user.getUserData(
				(err, data) => {
					if (err) {
						reject(err);
						return;
					}
					resolve(data);
				},
				{ bypassCache: true }
			);
		});
	};

	getCognitoUserAttributesAsync = user => {
		return new Promise((resolve, reject) => {
			user.getUserAttributes((err, result) => {
				if (err) {
					reject(err);
					return;
				}
				resolve(result);
			});
		});
	};

	getUserData = async user => {
		const accessToken = get(user, 'signInUserSession.accessToken.jwtToken', '');
		try {
			const data = await this.getCognitoUserDataAsync(user);
			const { PreferredMfaSetting, UserMFASettingList } = data;
			const isSoftwareMfaEnabled = UserMFASettingList && UserMFASettingList.includes('SOFTWARE_TOKEN_MFA');
			const isSmsMfaEnabled = UserMFASettingList && UserMFASettingList.includes('SMS_MFA');
			const enableSoftwareMfa = PreferredMfaSetting === 'SOFTWARE_TOKEN_MFA';
			const enableSmsMfa = PreferredMfaSetting === 'SMS_MFA';

			const newState = {
				user,
				isSoftwareMfaEnabled,
				enableSoftwareMfa,
				isSmsMfaEnabled,
				enableSmsMfa,
			};
			this.setState(newState);
		} catch (err) {
			this.handleError(err, true);
		}

		try {
			const result = await this.getCognitoUserAttributesAsync(user);
			this.mapUserResponseToState(result, accessToken, user);
		} catch (err) {
			this.handleError(err, true);
		}

		/*
		Future - add display of current device
		user.getCachedDeviceKeyAndPassword();
		user.getDevice({
			onSuccess: function(result) {
				console.log('getDevice call result: ');
				console.log(result);
			},
			onFailure: function(err) {
				console.log('GetDevice error: ');
				console.log(err.message || JSON.stringify(err));
			},
		});*/
	};

	initializeSoftwareToken = async (useLoader = true) => {
		const showLoader = useLoader ? this.props.showLoader : noop;

		showLoader(true);
		Auth.setupTOTP(this.state.user).then(mfaCode => {
			this.setState({ mfaCode }, showLoader);
		});
	};

	updatePhoneNumber = async phoneNumber => {
		const { user, countryDialCode } = this.state;
		const phone_number = phoneNumber ? countryDialCode + phoneNumber : phoneNumber;

		this.setState({ prevSavedPhoneNumber: phoneNumber, successMessage: '', errorMessage: '' });
		await Auth.updateUserAttributes(user, { phone_number });
	};

	save = async () => {
		const { showLoader } = this.props;
		const { user, enableSoftwareMfa } = this.state;
		const addNotification = get(this.notificationRef, 'current.addNotification', noop);
		let error = null;
		let ref = null;

		try {
			showLoader(true);
			if (enableSoftwareMfa) {
				await Auth.setPreferredMFA(user, 'TOTP');
			} else {
				await Auth.setPreferredMFA(user, 'SMS');
			}

			if (this.principal.redirectToSecurity) {
				principalService.set({ ...this.principal, redirectToSecurity: false });
				this.props.history.push({ pathname: '/' });
			}
		} catch (e) {
			error = this.handleError(e, true, { delayMessage: true });
			if (error) {
				showLoader(true);
			}
		}

		await this.refreshData(true, error);

		if (!error) {
			addNotification({
				message: `Multi Factor Authentication Settings Updated`,
				success: true,
				ref,
			});
			showLoader();
			each(componentKeys, (value, key) => {
				removeState(localStorageKeys.MFA_CONFIRMED_TIME(value));
				removeState(localStorageKeys.SMS_MFA_CODE_SENT_TIME(value));
			});
		}
	};

	refreshData = async (refreshData, error) => {
		const { showLoader } = this.props;
		const { errorMessages } = this.state;

		if (refreshData) {
			try {
				if (isEmpty(errorMessages) && error) {
					error.show();
				}
				this.fetchData();
			} catch (e) {
				showLoader();
				this.handleError(e);
			}
		}
	};

	mapUserResponseToState = (UserAttributes, accessToken, user) => {
		const phone_number = get(
			find(UserAttributes, ({ Name }) => toLower(Name) === 'phone_number'),
			'Value',
			''
		);
		const countryDialCode = get(
			find(dialCodesList, ({ value }) => value && startsWith(phone_number, value)),
			'value',
			'+1'
		);
		const phoneNumber = replace(phone_number, countryDialCode, '');
		const phoneNumberVerified = get(
			find(UserAttributes, ({ Name }) => toLower(Name) === 'phone_number_verified'),
			'Value',
			'false'
		);

		const newState = {
			user,
			accessToken,
			phoneNumber,
			prevSavedPhoneNumber: phoneNumber,
			countryDialCode,
			phoneNumberVerified: phoneNumberVerified === 'true',
			prevSavedPhoneVerified: phoneNumberVerified === 'true',
		};

		this.setState(newState);
	};

	validateFields = () => {
		const { phoneNumber, enableSmsMfa, countryDialCode } = this.state;
		const newState = { errorMessages: [] };

		if (!internationalPhoneNumberRegex.test(countryDialCode + phoneNumber)) {
			if (enableSmsMfa) {
				newState.errorMessages.push({ key: 'phoneNumber', message: 'Valid Phone Number is required.' });
			} else {
				newState.phoneNumber = '';
				newState.countryDialCode = '+1';
			}
		}

		this.setState(newState);

		return !isEmpty(newState.errorMessages);
	};

	validateField = fieldKey => {
		const { errorMessages } = this.state;
		let className = '';

		if (some(errorMessages, ({ key }) => key === fieldKey)) {
			className = ' is-invalid';
		}

		return className;
	};

	handleSubmitConfirmationCode = ({ target: { value, name } }) => {
		const newState = { [name]: value };

		this.setState(newState, this.validateField);
	};

	handleSubmitTotp = async event => {
		event.preventDefault();
		if (!this.formValidation(this.state.challengeAnswer)) {
			return;
		}

		this.props.showLoader(true);
		let { user, challengeAnswer } = this.state;

		Auth.verifyTotpToken(user, challengeAnswer)
			.then(async () => {
				await Auth.setPreferredMFA(user, 'TOTP');

				this.setState({
					errorMessage: null,
					successMessage: 'MFA set up successfully',
					isSoftwareMfaEnabled: true,
				});

				if (this.principal.redirectToSecurity) {
					principalService.set({ ...this.principal, redirectToSecurity: false });
					this.props.history.push({ pathname: '/' });
				}
				this.props.showLoader();
			})
			.catch(e => this.handleError(e));
	};

	handleSelectChange = ({ value }, { name }) => this.handleMfaChange({ target: { name, value } });

	handlePhoneNumberChange = ({ target: { name, value } }) => {
		const digitRegex = /^\d*$/gi;
		if (digitRegex.test(value)) {
			this.handleMfaChange({ target: { name, value } });
		}
	};

	handleMfaChange = ({ target: { name, value, type, checked } }) => {
		try {
			const { mfaCode, isSoftwareMfaEnabled, prevSavedPhoneNumber, prevSavedPhoneVerified } = this.state;
			const newState = { [name]: type === 'checkbox' ? checked : value };

			if (type === 'checkbox') {
				newState[name] = checked;

				newState.successMessage = '';
				newState.errorMessage = '';

				if (name === 'enableSmsMfa') {
					newState.enableSoftwareMfa = false;
				} else {
					newState.enableSmsMfa = false;
					newState.displayConfirmCode = false;
				}
			}

			if (newState.enableSoftwareMfa && !mfaCode && !isSoftwareMfaEnabled) {
				this.initializeSoftwareToken();
			}

			if (newState.phoneNumber) {
				newState.phoneNumberVerified = prevSavedPhoneNumber === newState.phoneNumber && prevSavedPhoneVerified;
			}

			this.setState(newState, this.validateFields);
		} catch (e) {
			this.handleError(e, true);
		}
	};

	resetSoftwareToken = () => {
		this.initializeSoftwareToken();
		this.setState({ isSoftwareMfaEnabled: false, enableSoftwareMfa: true });
	};

	notifyPasswordChange = () => {
		this.notificationRef.current.addNotification({
			success: true,
			message: 'Password changed successfully',
		});
		this.setState({ displayReset: false });
	};

	handlePasswordReset = async () => {
		try {
			this.props.showLoader(true);
			await Auth.forgotPassword(this.principal.email);
			this.setState({ displayReset: true });
		} catch (err) {
			let message;
			switch (err && err.code) {
				case 'LimitExceededException': {
					message = 'Attempt limit exceeded, please try after some time.';
					break;
				}
				default: {
					message = 'Something went wrong. Please try again.';
					break;
				}
			}
			this.setState({
				passwordErrorMessage: message,
			});
		}
		this.props.showLoader();
	};

	notifyAuthError = () => {
		this.notificationRef.current.addNotification({
			message: 'To update MFA settings please login again.',
			success: false,
			forceCloseHandler: true,
			onClose: () => this.props.history.push({ pathname: '/login' }),
		});
	};

	handleError = (error, displayInPopup = false, errorOptions = {}) => {
		const { showLoader, handleError } = this.props;

		showLoader();
		if (error && error.isCanceled) return;
		if (error === 'not authenticated') {
			return this.notifyAuthError();
		}
		if ((error && !error.message && !error.code) || (error && error.message && error.code)) {
			return this.handleCognitoError(error, displayInPopup);
		} else {
			return handleError(error, errorOptions);
		}
	};

	yieldUint8Chunks = async data => {
		const reader = data.getReader();
		try {
			const { done, value } = await reader.read();

			if (done) {
				return;
			}

			return value;
		} finally {
			reader.releaseLock();
		}
	};

	handleCognitoError = async (error, displayInPopup = false) => {
		let err = error;
		let respBody = get(error, 'response.body');
		if (!err.message && !!respBody) {
			const unit8ChunksList = await this.yieldUint8Chunks(respBody);
			err = JSON.parse(new TextDecoder().decode(unit8ChunksList));
		}

		const defaultMessage = this.principal.redirectToSecurity ? (
			<span>
				Something went wrong. Please{' '}
				<a className="btn btn--link" href="/logout">
					try logging in again.
				</a>
			</span>
		) : (
			'Something went wrong. Please try again.'
		);

		let message = cognitoErrorMap[err.__type] || cognitoErrorMap[err.code] || err.message || defaultMessage;

		if (message.message) {
			message = message.message;
		}

		if (displayInPopup) {
			this.props.handleError({ ...error, message });
		} else {
			this.setState({
				errorMessage: message,
				successMessage: null,
			});
		}
	};

	getButtonClassName = displayConfirmCode => (displayConfirmCode ? 'ghost' : 'primary');

	getButtonLabel = displayConfirmCode => (displayConfirmCode ? 'Resend Verification Code' : 'Verify Phone Number');

	formValidation = challengeAnswer => {
		if (challengeAnswer.length <= 0) {
			this.setState({
				errorMessage: 'Please enter the code to verify',
				successMessage: null,
			});
			return false;
		}

		return true;
	};

	confirmVerificationCode = async () => {
		const { showLoader, makePendingRequest } = this.props;
		const { accessToken, confirmationCode, user } = this.state;
		const addNotification = get(this.notificationRef, 'current.addNotification', noop);

		if (!this.formValidation(confirmationCode)) {
			return;
		}

		try {
			showLoader(true);
			await makePendingRequest(
				appService.verifyUserAttribute(accessToken, 'phone_number', confirmationCode).then(() =>
					this.setState({
						displayConfirmCode: false,
						confirmationCode: '',
						phoneNumberVerified: true,
						prevSavedPhoneVerified: true,
					})
				),
				requestKeys.CONFIRM
			);
			await Auth.setPreferredMFA(user, 'SMS');

			await this.fetchData();

			addNotification({
				message: `Multi Factor Authentication Settings updated`,
				success: true,
			});
			if (this.principal.redirectToSecurity) {
				principalService.set({ ...this.principal, redirectToSecurity: false });
				this.props.history.push({ pathname: '/' });
			}
		} catch (e) {
			this.handleError(e);
		}
	};

	sendVerificationCode = async () => {
		const { showLoader, makePendingRequest } = this.props;
		const { accessToken } = this.state;
		const addNotification = get(this.notificationRef, 'current.addNotification', noop);

		try {
			showLoader(true);
			const {
				CodeDeliveryDetails: { DeliveryMedium: deliveryMedium, Destination: destination },
			} = await makePendingRequest(
				appService.getUserAttributeVerificationCode(accessToken, 'phone_number'),
				requestKeys.SEND
			);
			this.setState({ displayConfirmCode: true });
			showLoader();

			addNotification({
				message: `${deliveryMedium} sent to ${destination}.`,
				success: true,
			});
		} catch (e) {
			this.handleError(e);
		}
	};

	savePhoneNumberAndSendVerificationCode = async () => {
		const { showLoader, makePendingRequest } = this.props;
		const { phoneNumber } = this.state;

		try {
			showLoader(true);
			await makePendingRequest(this.updatePhoneNumber(phoneNumber), requestKeys.SAVE_VERIFY);
			this.sendVerificationCode();
		} catch (e) {
			this.handleError(e);
		}
	};

	renderSuccessMessages = () => {
		const { successMessage } = this.state;
		return <div className="type--color--success spc--bottom--lrg">{successMessage ? { successMessage } : null}</div>;
	};

	renderWarningMessages = () => {
		const { errorMessage } = this.state;

		return <div className="type--validation spc--bottom--lrg">{errorMessage ? { errorMessage } : null}</div>;
	};

	renderCountryDropdown = () => {
		return (
			<div className="form__group w--200">
				<div className="form__group__header">
					<label htmlFor="countryDialCode" className="form__group__label">
						Country
					</label>
				</div>
				<Select
					className="react-select-container"
					classNamePrefix="react-select"
					menuPlacement="auto"
					name="countryDialCode"
					id="countryDialCode"
					value={this.getCountryDialCode}
					options={dialCodesList}
					onChange={this.handleSelectChange}
					isDisabled={this.props.isLoading}
				/>
			</div>
		);
	};
	renderResetButton = () => (
		<button className="btn btn--sml btn--primary" onClick={() => this.resetSoftwareToken()}>
			Reset
		</button>
	);
	renderMfaSection = () => {
		const {
			challengeAnswer,
			mfaCode,
			enableSoftwareMfa,
			isSoftwareMfaEnabled,
			enableSmsMfa,
			phoneNumber,
			displayConfirmCode,
			confirmationCode,
			phoneNumberVerified,
			user,
			username,
		} = this.state;

		const str = 'otpauth://totp/Cardknox:' + username + '?secret=' + mfaCode;
		return (
			<div className="spc--bottom--xlrg">
				<div className="flex--primary flex--gap--sml spc--bottom--med">
					<div className="flex--primary h--32">
						<div>
							<input
								type="checkbox"
								className="input--radio"
								id="enableSoftwareMfa"
								name="enableSoftwareMfa"
								onChange={this.handleMfaChange}
								value="enableSoftwareMfa"
								checked={enableSoftwareMfa}
								disabled={!user}
							/>
							<label htmlFor="enableSoftwareMfa">Enable Software MFA (Preferred)</label>
						</div>
					</div>
					{isSoftwareMfaEnabled && enableSoftwareMfa && !this.props.isLoading && this.renderResetButton()}
				</div>
				<div className="form__group">
					<input
						type="checkbox"
						className="input--radio"
						id="enableSmsMfa"
						name="enableSmsMfa"
						onChange={this.handleMfaChange}
						value="enableSmsMfa"
						checked={enableSmsMfa}
						disabled={!user}
					/>
					<label htmlFor="enableSmsMfa">Enable SMS MFA</label>
				</div>

				{!isSoftwareMfaEnabled && enableSoftwareMfa && !this.props.isLoading && (
					<form className="form" onSubmit={this.handleSubmitTotp}>
						<div>
							{this.renderSuccessMessages()}
							<div className="notes notes--primary spc--bottom--lrg">
								<i className="icon"></i>
								<div className="type--p4">
									<p>
										Install an MFA app on your smartphone or computer to use Multi Factor Authentication (MFA) for
										Cardknox logins.
									</p>
									<p>(Some examples are Authy, Google Authenticator, LastPass Authenticator.)</p>
									<br />
									<p>
										Scan or Copy the <span className="type--wgt--medium">code below</span> and paste it into your MFA
										app.
									</p>
									<br />
									<p>
										The MFA app will display a <span className="type--wgt--medium">confirmation code</span> to paste
										into the field below.
									</p>
									<br />
									<p>
										To help you set up Software MFA, we have created a{' '}
										<a
											className="btn btn--link btn--link--underline"
											href="https://cdn.cardknox.com/share/cardknox_merchant_portal_mfa.mp4"
											rel="noopener noreferrer"
											target="_blank"
										>
											video
										</a>{' '}
										tutorial that walks you through the process step-by-step.
									</p>
								</div>
							</div>

							<div className="settings__mfa-code-card spc--bottom--lrg">
								<QRCodeSVG className="settings__mfa-code-card__qr" value={str} />
								<div className="settings__mfa-code-card__separator"></div>
								<input
									name="mfaCode"
									type="text"
									className="settings__mfa-code-card__input"
									disabled={true}
									value={mfaCode}
								/>
							</div>
							<div className="form__group">
								<div className="form__group__header">
									<p className="form__group__label">Confirmation code</p>
								</div>
								<input
									name="challengeAnswer"
									type="text"
									className="input input--med"
									placeholder="000000"
									value={challengeAnswer}
									onChange={this.handleMfaChange}
								/>
							</div>
							{this.renderWarningMessages()}
							<button
								type="submit"
								className="btn btn--med btn--primary"
								disabled={this.props.isLoading || !challengeAnswer}
							>
								Confirm Code and Save
							</button>
						</div>
					</form>
				)}
				{enableSmsMfa && (
					<div>
						{this.renderSuccessMessages()}
						<div className="flex--primary flex--top flex--gap--sml--alt flex--nowrap">
							{this.renderCountryDropdown()}
							<div className="form__group flex--grow--1">
								<div className="form__group__header">
									<label htmlFor="phoneNumber" className="form__group__label">
										Phone number
									</label>
									<i data-tooltip={phoneNumberTooltip} className="icon icon--tny icon--regular--info"></i>
								</div>
								<div>
									<input
										id="phoneNumber"
										placeholder="Phone number"
										name="phoneNumber"
										className={`input input--med ${this.validateField('phoneNumber')}`}
										value={phoneNumber || ''}
										onChange={this.handlePhoneNumberChange}
										disabled={this.props.isLoading}
									/>
									{phoneNumberVerified ? (
										<div className="flex--primary type--p2 type--color--success spc--top--tny">
											<i className="icon icon--sml icon--check--success" />
											<span className="type--p3 type--color--success">Verified</span>
										</div>
									) : (
										<Fragment>
											<button
												className={`btn btn--med btn--${this.getButtonClassName(displayConfirmCode)} spc--top--sml`}
												onClick={this.savePhoneNumberAndSendVerificationCode}
												disabled={this.props.isLoading || this.validateField('phoneNumber')}
											>
												{this.getButtonLabel(displayConfirmCode)}
											</button>
											{this.renderWarningMessages()}
										</Fragment>
									)}
								</div>
							</div>
						</div>

						<div className="spc--bottom--lrg">
							{displayConfirmCode && (
								<div className="form__group">
									<div className="form__group__header">
										<label htmlFor="confirmationCode" className="form__group__label">
											Confirmation Code
										</label>
									</div>
									<input
										id="confirmationCode"
										placeholder="000000"
										name="confirmationCode"
										className={`input input--med${this.validateField('confirmationCode')}`}
										value={confirmationCode || ''}
										onChange={this.handleSubmitConfirmationCode}
									/>
								</div>
							)}
							<p className="notes notes--primary spc--bottom--med">
								<i className="icon"></i>
								<p className="type--p4">
									Message and data rates may apply. If you choose to receive MFA passwords via text messages, we'll send
									you one text message per login attempt. To stop receiving these messages, reply 'STOP.'
								</p>
							</p>
							{displayConfirmCode && (
								<button
									className="btn btn--med btn--primary"
									onClick={this.confirmVerificationCode}
									disabled={this.props.isLoading}
								>
									Confirm
								</button>
							)}
						</div>
					</div>
				)}
				<div>{this.renderSaveButton()}</div>
			</div>
		);
	};
	renderPasswordRequirements = () => {
		const requirements = mapPasswordRequirements(this.state.password);
		if (!isEmpty(requirements)) {
			return (
				<p className="type--p4 type--color--text--light">
					Your password needs to contain at least:
					{map(requirements, (item, index) => (
						<span key={index}>{item}, </span>
					))}
				</p>
			);
		}
	};

	renderPasswordResetSection = () => {
		const { username, password, password2, code, errorMessage } = this.state;
		return (
			<Fragment>
				{!this.state.displayReset && (
					<div>
						<button className="btn btn--primary btn--med spc--bottom--sml" onClick={this.handlePasswordReset}>
							Reset Password
						</button>
					</div>
				)}
				{this.state.displayReset && (
					<Fragment>
						<h5 className="spc--bottom--lrg">Set new password</h5>
						<div className="spc--bottom--med">{this.renderPasswordRequirements()}</div>
						<ConfirmPasswordBody
							setComponentState={this.setComponentState}
							username={username}
							password={password}
							password2={password2}
							code={code}
							errorMessage={errorMessage}
							hideBackButton={true}
							skipRedirect={true}
							notifyPasswordChange={this.notifyPasswordChange}
							showLoader={this.props.showLoader}
							hideEmail={true}
							hideNewPasswordHeader={true}
						/>
					</Fragment>
				)}
			</Fragment>
		);
	};

	renderSaveButton = () => {
		const {
			enableSoftwareMfa,
			enableSmsMfa,
			isSoftwareMfaEnabled,
			isSmsMfaEnabled,
			phoneNumberVerified,
			mfaCode,
		} = this.state;

		const noMfa = !enableSoftwareMfa && !enableSmsMfa;
		const bothMfaMethodsAvailable = isSoftwareMfaEnabled && isSmsMfaEnabled;
		const bothMethodsVerified = phoneNumberVerified && !mfaCode;

		const canEnableSmsVerification = enableSmsMfa && phoneNumberVerified;

		return (
			<Fragment>
				{!noMfa && ((bothMfaMethodsAvailable && bothMethodsVerified) || canEnableSmsVerification) && (
					<button className="btn btn--med btn--primary" disabled={this.props.isLoading} onClick={() => this.save()}>
						Save
					</button>
				)}
			</Fragment>
		);
	};

	renderErrors = () => {
		const { errorMessages } = this.state;
		return map(errorMessages, ({ message, key }, index) => (
			<div key={`${index}.${key}`} className="type--validation spc--bottom--med">
				<p>{message}</p>
			</div>
		));
	};

	renderPasswordError = () => {
		const { passwordErrorMessage } = this.state;
		if (!passwordErrorMessage) return null;
		return (
			<div className="type--validation spc--bottom--med">
				<p>{passwordErrorMessage}</p>
			</div>
		);
	};
	renderSecurityConcernsText = () => {
		const headingStyles = {
			fontSize: '24px',
			fontWeight: 'bold',
		};

		return (
			<div className="card">
				<div className="card__header">
					<h4 style={headingStyles}>Mandatory Multi-Factor Authorization (MFA) for All Accounts</h4>
				</div>
				<div className="card__body">
					<p className="spc--bottom--sml">
						In an effort to offer maximum protection to our merchants and their customers, we have implemented a new
						security protocol that requires all accounts to have Multi-Factor Authorization (MFA) enabled. While in the
						past this was optional, due to a global uptick in phishing scams, it is now mandatory.
					</p>
					<p className="spc--bottom--sml">
						To help you set up Software MFA, we have created a{' '}
						<a
							className="btn btn--link"
							href="https://cdn.cardknox.com/share/cardknox_merchant_portal_mfa.mp4"
							rel="noopener noreferrer"
							target="_blank"
						>
							video
						</a>{' '}
						tutorial that walks you through the process step-by-step.
					</p>
					<p className="spc--bottom--sml">
						Contact our support team if you have any questions. Thank you for your cooperation in keeping our platform
						secure.
					</p>
				</div>
				<div className="card__footer"></div>
			</div>
		);
	};

	render() {
		const { isMfaRequired, isSamlLogin } = this.state;
		return (
			<div className="w--max--520">
				<Notification ref={this.notificationRef} />
				{isSamlLogin && (
					<p className="type--p2 type--color--text--light">No settings found to manage for current user</p>
				)}
				{!isSamlLogin && (
					<Fragment>
						{this.renderErrors()}
						{isMfaRequired && this.renderSecurityConcernsText()}

						<p className="type--p4 type--color--text--light spc--bottom--lrg">Setup Multi-Factor Authentication</p>
						{this.renderMfaSection()}

						{this.renderPasswordError()}

						{!isMfaRequired && (
							<Fragment>
								{!this.state.displayReset && <h5 className="spc--bottom--lrg">Set new password</h5>}
								{this.renderPasswordResetSection()}
							</Fragment>
						)}
					</Fragment>
				)}
			</div>
		);
	}
}

Security.propTypes = {
	location: object.isRequired,
	history: object.isRequired,
	showLoader: func.isRequired,
	handleError: func.isRequired,
	makePendingRequest: func.isRequired,
	isLoading: bool,
	handleBlockChange: func.isRequired,
};

export default withError(
	withLoader(
		withCancelable(withBlock(withStepup(Security, componentKeys.SETTINGS_SECURITY, false, { hideHeader: true })))
	)
);
