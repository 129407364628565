import React, { Component } from "react";
import { findIndex, each, noop, toLower, isEmpty } from "lodash";
import { bool, object, string } from "prop-types";

import { OutsideClick } from "../../../common/utilities";

class LinkFormatter extends Component {
    constructor(props) {
        super(props);

        LinkFormatter.instances.push(this);

        this.state = {
            isDisplayMenuOpen: false,
        };
    }

    static instances = [];

    componentDidUpdate = (prevProps) => {
        if (prevProps.isScrolling !== this.props.isScrolling) {
            this.closeDisplayMenu();
        }
    };

    componentWillUnmount() {
        const index = findIndex(LinkFormatter.instances, this);
        if (index > -1) {
            LinkFormatter.instances.splice(index, 1);
        }
    }

    copyToClipboard = () => {
        const {
            row: { websiteUrl },
        } = this.props;
        navigator.clipboard.writeText(websiteUrl);
        this.closeDisplayMenu();
    };

    displayMenu = () => {
        const {
            row: { openActions, websiteUrl },
        } = this.props;
        const { isDisplayMenuOpen } = this.state;

        const actions = isDisplayMenuOpen ? (
            <OutsideClick
                action={this.closeDisplayMenu}
                className="buttondropdown"
            >
                <ul className="buttondropdown__list">
                    <li
                        className="buttondropdown__item buttondropdown__item--withlink"
                        onClick={this.copyToClipboard}
                    >
                        <a className="buttondropdown__link">
                            Copy to clipboard
                        </a>
                    </li>
                    <li
                        className="buttondropdown__item buttondropdown__item--withlink"
                    >
                        <a
                            className="buttondropdown__link"
                            href={websiteUrl}
                            rel="noopener"
                            target="_blank"
                            onClick={this.closeDisplayMenu}
                        >
                            Go to paymentSITE
                        </a>
                    </li>
                </ul>
            </OutsideClick>
        ) : null;
        if (this.info) {
            const rect = this.info.getBoundingClientRect();
            openActions(
                {
                    width: (rect.left || 0) + (rect.width / 2 || 0),
                    height: (rect.top || 0) + 30,
                },
                actions
            );
        }
        if (isDisplayMenuOpen) {
            each(LinkFormatter.instances, (instance) => {
                if (instance.state.isDisplayMenuOpen && instance !== this) {
                    instance.setState({ isDisplayMenuOpen: false });
                }
            });
        }
    };

    openDisplayMenu = (e) => {
        e.stopPropagation();
        this.setState({ isDisplayMenuOpen: true }, this.displayMenu);
    };

    closeDisplayMenu = () => {
        setTimeout(
            () => this.setState({ isDisplayMenuOpen: false }, this.displayMenu),
            70
        );
    };

    openAddonsModal = (masterId, parentEquipmentId, parentEquipmentName, parentPlanName) => e => {
        const { row: { openAddonsModal } } = this.props;

        e.stopPropagation();
        openAddonsModal(masterId, parentEquipmentId, parentEquipmentName, parentPlanName);
    };

    renderNewAddOnButton = () => {
        const { row: { category, subequipment, id, equipmentId, name, planName, isActiveEquipment } } = this.props;
        const isGateway = toLower(category) === 'gateway';

        return isActiveEquipment && isGateway && !isEmpty(subequipment) && (
            <button
                type="button"
                className="btn btn--grid spc--left--sml"
                onClick={this.openAddonsModal(id, equipmentId, name, planName)}
            >
                <i className="icon icon--tiny icon--add--primary spc--right--nano"></i>
                Add-on
            </button>
        );
    };

    render = () => {
        const {
            value,
            row: { category },
        } = this.props;
        const isWebsite = toLower(category) === "website";

        return (
            <div className="flex--primary" onClick={isWebsite ? this.openDisplayMenu : noop} >
                <span ref={(el) => (this.info = el)} className={isWebsite ? "anchor anchor--primary" : ""}>
                    {value}
                </span>
                {this.renderNewAddOnButton()}
            </div>
        );
    };
}

LinkFormatter.propTypes = {
    isScrolling: bool,
    value: string,
    row: object,
};

export default LinkFormatter;
