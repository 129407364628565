import React, { Component } from "react";
import { get } from 'lodash';
import { signatureService } from "../../services";
import withLoader from "../../common/components/loader/loader-hoc";

import { PSClickWrap } from '@pactsafe/pactsafe-react-sdk';



class ClickWrapComponent extends Component {
    constructor(props) {
        super(props);
    }

    onTermsValid = async (...args) => {
        const { showLoader } = this.props;
        try {
            const downloadUrl = args[1].site.parameters.get('download_url');

            if (!downloadUrl) {
                return;
            }

            showLoader(true);
            const info = await signatureService.retrieveActivityInfo(this.props.appId);
            const ip = get(info, 'data.connection_data.remote_address', '');
            const timestamp = get(info, 'data.created_time', '');

            this.props.setInfoToApp(downloadUrl, ip, timestamp)
            showLoader();

        } catch (err) {
            showLoader();
            this.props.handleError(get(err, 'error.message', err));
        }
    };

    onTermsInvalid = () => {
        this.props.setInfoToApp('')
        this.props.showLoader();    //should this be done always?
    };

    onTermsError = (...args) => {
        console.log(`Error prop callback called for PSClickwrap with parameters: `, [...args]);
    };
    //'group-goplus'

    render() {
        return (
            <div id="downloadUrl" className="card--primary card--sml spc--bottom--med">
                <PSClickWrap
                    accessId={process.env.REACT_APP_SIGNATURE_ACCESS_ID}
                    groupKey={this.props.groupKey}
                    signerId={'pp' + this.props.appId.toString()}
                    confirmationEmail={false}
                    clickWrapStyle='scroll' forceScroll={true}
                    testMode={process.env.REACT_APP_USE_TESTMODE_PACTSAFE === '1'}
                    onValid={this.onTermsValid}
                    onInvalid={this.onTermsInvalid}
                    onError={this.onTermsError}
                    customData={this.props.customData}
                    allowDisagreed={this.props.allowDisagreed}
                />
            </div>
        );
    }
}


export default withLoader(ClickWrapComponent);
