import React from 'react';
import { Redirect } from 'react-router-dom';

import { mainLayout, publicLayout, MerchantLayout } from '../layouts';
import {
    LoginComponent,
    SamlLoginComponent,
    LogoutComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    ConfirmMFAComponent,
    ConfirmRegistrationComponent,
    ConfirmNewPasswordComponent,
    ChangePasswordComponent,
    Security,
} from '../components/Login';
import { InfoPage } from '../components/Info';
import { DropinPage } from '../components/Dropin';
import { MerchantGrid } from '../components/MerchantGrid';
import { LeadGrid } from '../components/LeadGrid';
import { TicketGrid } from '../components/TicketGrid';
import { EAppGrid } from '../components/EAppGrid';
import { EquipmentGrid } from '../components/EquipmentGrid';
import { MerchantDetails, MerchantRoot } from '../components/MerchantDetails';
import { LeadDetails } from '../components/LeadDetails';
import { NotesView } from '../components/MerchantNotes';
import { MerchantEquipmentComponent } from '../components/MerchantEquipment';
import  AchPlanComponent  from '../components/Equipment/ach-plan';
import {
    WrappedMpaLoader,
    MerchantMpaLoader,
    MerchantCompleteComponent,
    MerchantProcessingComponent,
    MerchantSignComponent,
    SetupFormComponent,
    WrappedEappConfirm,
    EAppCompleteComponent,
    GoPlusEappForm,
    GoPlusEAppConfirmComponent,
    AchEAppConfirmComponent,
    AchEappFormComponent,
    Rates,
    GoPlusMerchantMpaComponent,
    AchMerchantMpaComponent
} from '../components/EApp';
import sectionKeys from './sections';
import InvoiceGrid from '../components/InvoiceGrid/InvoiceGrid';
import { MerchantFiles } from '../components/MerchantFiles';
import BankingServices from '../components/BankingServices/BankingServices';
import BankReports from '../components/BankReports/BankReports';
import DownloadFiservApplication from '../components/EApp/DownloadFiservApplication';
import {EquipmentLookup} from '../components/EquipmentLookup';
import { ChangeApiUrlComponent } from '../components/ChangeApiUrl';
import ResidualsGrid from '../components/Residuals/ResidualsGrid';
import { MerchantAgreement } from '../components/MerchantAgreement';
import Settings from '../components/Settings/Settings';

const coBrandLayout = props => <MerchantLayout {...props} isCobrand={true} logoImgSrc="/static/media/logo-cobrand.png" />

const routes = [
    {
        path: '/info',
        component: InfoPage,
        layout: publicLayout,
        isPublic: true
    },
    {
        path: '/login',
        component: LoginComponent,
        layout: publicLayout,
        isPublic: true,
    },
    {
        path: '/saml-login',
        component: SamlLoginComponent,
        layout: publicLayout,
        isPublic: true,
    },
    {
        path: '/logout',
        component: LogoutComponent,
        layout: publicLayout,
        isPublic: true,
    },
    {
        path: '/register',
        component: RegisterComponent,
        layout: publicLayout,
        isPublic: true,
    },
    {
        path: '/forgot-password',
        component: ForgotPasswordComponent,
        layout: publicLayout,
        isPublic: true,
    },
    {
        path: '/confirm-registration',
        component: ConfirmRegistrationComponent,
        layout: publicLayout,
        isPublic: true,
    },
    {
        path: '/change-url',
        component: ChangeApiUrlComponent,
        layout: mainLayout,
        isPublic: false,
        section: sectionKeys.admin,
    },
    {
        path: '/confirm-mfa',
        component: ConfirmMFAComponent,
        layout: publicLayout,
        isPublic: true,
    },
    {
        path: '/confirm-new-password',
        component: ConfirmNewPasswordComponent,
        layout: publicLayout,
        isPublic: true,
    },
    {
        path: '/change-password',
        component: ChangePasswordComponent,
        layout: publicLayout,
        isPublic: true,
    },
    {
        path: '/dropin',
        component: DropinPage,
        layout: mainLayout,
        isPublic: false
    },
    {
        path: '/',
        exact: true,
        layout: mainLayout,
        component: () => <Redirect to="/merchants"/>,
        isDefault: true,
        isPublic: false,
        section: sectionKeys.merchants
    },
    {
        path: '/merchants',
        exact: true,
        layout: mainLayout,
        component: MerchantGrid,
        isDefault: false,
        isPublic: false,
        section: sectionKeys.merchants
    },
    {
        path: '/tickets',
        exact: true,
        layout: mainLayout,
        component: TicketGrid,
        isDefault: false,
        isPublic: false,
        section: sectionKeys.tickets
    },
    {
        path: '/leads',
        exact: true,
        layout: mainLayout,
        component: LeadGrid,
        isDefault: false,
        isPublic: false,
        section: sectionKeys.leads
    },
    {
        path: '/eapps',
        exact: true,
        layout: mainLayout,
        component: EAppGrid,
        isDefault: false,
        isPublic: false,
        section: sectionKeys.leads
    },
    {
        path: '/residuals',
        exact: true,
        layout: mainLayout,
        component: ResidualsGrid,
        isDefault: false,
        isPublic: false,
        section: sectionKeys.leads
    },
    {
        path: '/leads/account/:leadid',
        layout: mainLayout,
        component: LeadDetails,
        isDefault: false,
        isPublic: false,
        section: sectionKeys.leads
    },
    {
        path: '/merchants/:appid',
        layout: mainLayout,
        component: MerchantRoot,
        isDefault: false,
        isPublic: false,
        section: sectionKeys.merchants,
        children: [
            {
                path: '/merchants/:appid/account',
                component: MerchantDetails,
                isDefault: false,
                isPublic: false,
                section: sectionKeys.merchants
            },
            {
                path: '/merchants/:appid/notes',
                component: NotesView,
                isDefault: false,
                isPublic: false,
                section: sectionKeys.merchants
            },
            {
                path: '/merchants/:appid/files',
                component: MerchantFiles,
                isDefault: false,
                isPublic: false,
                section: sectionKeys.merchants
            },
            {
                path: '/merchants/:appid/summary',
                component: props => <WrappedEappConfirm {...props} title="App Summary" showEidsAndPci={true} displayLastFourOnly={true} showNavbar={false} showChangeLink={false} showSubmit={false} showSSN={false} />,
                isDefault: false,
                isPublic: false,
                section: sectionKeys.merchants
            },
            {
                path: '/merchants/:appid/ach-summary',
                component: props => <AchEAppConfirmComponent {...props} title="App Summary" showEidsAndPci={false} displayLastFourOnly={true} showNavbar={false} showChangeLink={false} showSubmit={false} showSSN={false} />,
                isDefault: false,
                isPublic: false,
                section: sectionKeys.merchants
            },
            {
                path: '/merchants/:appid/gosummary',
                component: props => <GoPlusEAppConfirmComponent {...props} title="Go App Summary" displayLastFourOnly={true} showSSN={false} />,
                isDefault: false,
                isPublic: false,
                section: sectionKeys.merchants
            },
            {
                path: '/merchants/:appid/achq-summary',
                component: props => <GoPlusEAppConfirmComponent {...props} title="ACHQ App Summary" displayLastFourOnly={true} showSSN={false} />,
                isDefault: false,
                isPublic: false,
                section: sectionKeys.merchants
            },
            {
                path: '/merchants/:appid/rates',
                component: Rates,
                isDefault: false,
                isPublic: false,
                section: sectionKeys.merchants
            },
            {
                path: '/merchants/:appid/invoices',
                component: InvoiceGrid,
                isDefault: false,
                isPublic: false,
                section: sectionKeys.merchants
            },
            {
                path: '/merchants/:appid/banking-services',
                component: BankingServices,
                isDefault: false,
                isPublic: false,
                section: sectionKeys.merchants
            },
            {
                path: '/merchants/:appid/bank-reports',
                component: BankReports,
                isDefault: false,
                isPublic: false,
                section: sectionKeys.merchants,
            },
            {
                path: '/merchants/:appid/equipment-new',
                component: props => <MerchantEquipmentComponent {...props} isEApp={false} useEquipmentTemplate={true} />,
                isDefault: false,
                isPublic: false,
                section: sectionKeys.merchants
            },
            {
                path: '/merchants/:appid/equipment',
                component: EquipmentGrid,
                isDefault: false,
                isPublic: false,
                section: sectionKeys.merchants
            },
            {
                path: '/merchants/:appid/tickets',
                component: TicketGrid,
                isDefault: false,
                isPublic: false,
                section: sectionKeys.merchants
            },
        ]
    },
    {
        path: '/merchant/mpa/',
        layout: coBrandLayout,
        component: MerchantMpaLoader,
        isDefault: false,
        isPublic: true,
        section: sectionKeys.merchanteapp,
    },
    {
        path: '/merchant/go-eapp/',
        layout: coBrandLayout,
        component: GoPlusMerchantMpaComponent,
        isDefault: false,
        isPublic: true,
        section: sectionKeys.merchanteapp,
    },
    {
        path: '/merchant/achq-eapp/',
        layout: coBrandLayout,
        component: GoPlusMerchantMpaComponent,
        isDefault: false,
        isPublic: true,
        section: sectionKeys.merchanteapp,
    },
    {
        path: '/merchant/ach-eapp/',
        layout: coBrandLayout,
        component: AchMerchantMpaComponent,
        isDefault: false,
        isPublic: true,
        section: sectionKeys.merchanteapp,
    },
    {
        path: '/merchant/download-fiserv-application',
        layout: coBrandLayout,
        component: DownloadFiservApplication,
        isDefault: false,
        isPublic: true,
        section: sectionKeys.merchanteapp
    },
    {
        path: '/merchant/sign',
        layout: coBrandLayout,
        component: MerchantSignComponent,
        isDefault: false,
        isPublic: true,
        section: sectionKeys.merchanteapp
    },
    {
        path: '/merchant/thankyou',
        layout: coBrandLayout,
        component: MerchantCompleteComponent,
        isDefault: false,
        isPublic: true,
        section: sectionKeys.merchanteapp
    },
    {
        path: '/merchant/processing',
        layout: coBrandLayout,
        component: MerchantProcessingComponent,
        isDefault: false,
        isPublic: true,
        section: sectionKeys.merchanteapp
    },
    {
        path: '/equipment',
        layout: mainLayout,
        component: EquipmentLookup,
        isDefault: false,
        isPublic: false,
        section: sectionKeys.merchants,
    },
    {
        // I didn't want to change the urls on these ones
        // as they are used in the generated links when sending
        // the form to the merchant
        path: '/eapp/:foo/:appid',
        layout: mainLayout,
        component: MerchantRoot,
        isDefault: false,
        isPublic: false,
        section: sectionKeys.merchants,
        children: [
            {
                path: '/eapp/cardknox-go/:appid',
                component: GoPlusEappForm,
                isDefault: false,
                isPublic: false,
                section: sectionKeys.merchants
            },
            {
                path: '/eapp/achq/:appid',
                component: GoPlusEappForm,
                isDefault: false,
                isPublic: false,
                section: sectionKeys.merchants
            },
            {
                path: '/eapp/cardknox-go-equipment/:appid',
                component: () => <MerchantEquipmentComponent initialTab="hardware" isGoPlus={true} />
            },
            {
                path: '/eapp/ach/:appid',
                component: AchEappFormComponent,
                isDefault: false,
                isPublic: false,
                section: sectionKeys.merchants
            },
            {
                path: '/eapp/ach-fees/:appid',
                component: AchPlanComponent,
                isDefault: false,
                isPublic: false,
                section: sectionKeys.merchants
            },
            {
                path: '/eapp/mpa/:appid',
                component: WrappedMpaLoader,
                isDefault: false,
                isPublic: false,
                section: sectionKeys.merchants
            },
            {
                path: '/eapp/setup/:appid',
                component: SetupFormComponent,
                isDefault: false,
                isPublic: false,
                section: sectionKeys.merchants
            },
            {
                path: '/eapp/equipment/:appid',
                component: () => <MerchantEquipmentComponent useEquipmentTemplate={true}/>,
                isDefault: false,
                isPublic: false,
                section: sectionKeys.merchants
            },
            {
                path: '/eapp/confirm/:appid',
                component: props => <WrappedEappConfirm {...props} displayRates={true} displayEquipmentInfo={true} />,
                isDefault: false,
                isPublic: false,
                section: sectionKeys.merchants
            },
            {
                path: '/eapp/ach-confirm/:appid',
                component: props => <AchEAppConfirmComponent {...props} displayRates={true} displayEquipmentInfo={true} />,
                isDefault: false,
                isPublic: false,
                section: sectionKeys.merchants
            },
            {
                path: '/eapp/complete/:appid',
                component: EAppCompleteComponent,
                isDefault: false,
                isPublic: false,
                section: sectionKeys.merchants
            },
        ]
    },
    {
        path: '/settings',
        layout: mainLayout,
        component: Settings,
        isDefault: false,
        isPublic: false,
        section: sectionKeys.settings,
    },
    {
		path: '/security',
		component: Security,
		layout: mainLayout,
	},
    {
        path: '/merchant/agreement',
        layout: coBrandLayout,
        component: MerchantAgreement,
        isPublic: true
    },]
;

export default routes;