import React, { Component, Fragment } from 'react';
import { appService } from '../../services';
import { Auth } from 'aws-amplify';
import { get } from 'lodash';
import { withCancelable } from '../../common/components/cancelable';
import { Notification } from '../../common/components/notifications';
import PropTypes from 'prop-types';
import { persistState, restoreState } from '../../helpers/persist-state';
import { localStorageKeys } from './stepupConstants';
import moment from 'moment';
import ChallengeInput from '../codeInput/CodeInput';
import Header from '../../common/components/header/Header';

class ConfirmMFAStepUp extends Component {
	constructor(props) {
		super(props);
		this.notificationRef = React.createRef();
		this.state = {
			mfaType: null,
			accessToken: null,
			destination: null,
			errorMessage: null,
		};
	}

	async componentDidMount() {
		const { history, location } = this.props;
		const urlParams = new URLSearchParams(location.search);
		urlParams.set('isStepup', 'true');
		history.replace({ search: urlParams.toString() });
		this.props.showLoader(true);
		if (!this.props.mfaType || this.props.mfaType === 'SMS_STEP_UP') {
			const user = await Auth.currentAuthenticatedUser();
			let mfaType = null;
			if (this.props.mfaType === 'SMS_STEP_UP') {
				const destination = get(user, 'attributes.phone_number', null);
				const accessToken = get(user, 'signInUserSession.accessToken.jwtToken', null);
				mfaType = 'SMS_STEP_UP';
				this.setState(
					{ destination, accessToken },
					() => this.props.invokeSendCodeOnAppear && this.sendVerificationCodeOnAppear()
				);
			} else {
				const { preferredMFA } = user;
				if (preferredMFA === 'SMS_MFA') {
					const destination = get(user, 'attributes.phone_number', null);
					const accessToken = get(user, 'signInUserSession.accessToken.jwtToken', null);
					mfaType = 'SMS_STEP_UP';
					this.setState(
						{ destination, accessToken },
						() => this.props.invokeSendCodeOnAppear && this.sendVerificationCodeOnAppear()
					);
				} else if (preferredMFA === 'SOFTWARE_TOKEN_MFA') {
					mfaType = 'SOFTWARE_TOKEN_STEP_UP';
				}
			}
			this.setState({ mfaType }, this.props.showLoader());
		} else {
			this.setState({ mfaType: this.props.mfaType }, this.props.showLoader());
		}
	}
	componentWillUnmount() {
		const { history, location } = this.props;
		const urlParams = new URLSearchParams(location.search);
		urlParams.delete('isStepup');
		history.replace({ search: urlParams.toString() });
	}
	sendVerificationCodeOnAppear = () => {
		const { componentKey } = this.props;
		const mfaTime = restoreState(localStorageKeys.SMS_MFA_CODE_SENT_TIME(componentKey));
		const momentMfaTime = moment(mfaTime, true);
		if (!momentMfaTime.isValid()) {
			this.sendVerificationCode(false);
			return;
		}

		const differenceInSeconds = moment().diff(momentMfaTime, 'seconds');
		if (differenceInSeconds >= 600) {
			this.sendVerificationCode(false);
		}
	};

	handleConfirmMfa = async () => {
		this.props.showLoader(true);
		const { mfaType } = this.state;
		try {
			await appService.respondToChallenge(mfaType, this.challengeInputRef.challengeAnswer, this.props.componentKey);
			this.props.markAsVerified();
		} catch (error) {
			this.setState({ errorMessage: error });
			this.challengeInputRef.clearInputs();
		} finally {
			this.props.showLoader(false);
		}
	};

	sendVerificationCode = async (showNotification = true) => {
		const { showLoader, makePendingRequest, componentKey } = this.props;
		const { destination, accessToken } = this.state;

		try {
			showLoader(true);
			await makePendingRequest(appService.getUserAttributeVerificationCode(accessToken, 'phone_number'), 'send');
			persistState(localStorageKeys.SMS_MFA_CODE_SENT_TIME(componentKey), moment().toISOString());
			showNotification &&
				this.notificationRef.current.addNotification({
					success: true,
					message: `Confirmation code successfully sent to ${destination}`,
				});
		} catch (e) {
			showNotification &&
				this.notificationRef.current.addNotification({
					success: false,
					message: `There was an error sending the confirmation code. Try again later or contact support.`,
				});
		} finally {
			showLoader(false);
		}
	};

	render() {
		const { isLoading, hideHeader } = this.props;
		const { errorMessage, mfaType, destination } = this.state;
		const forbidden = !isLoading && mfaType && mfaType === 'Forbidden';
		const otherError = !isLoading && mfaType && mfaType.indexOf('One or more errors occurred.') > -1;
		const invalidMfaType =
			!isLoading &&
			!forbidden &&
			!otherError &&
			mfaType !== 'SMS_STEP_UP' &&
			mfaType !== 'SOFTWARE_TOKEN_STEP_UP' &&
			mfaType !== 'MAYBE_SOFTWARE_TOKEN_STEP_UP';
		return (
			<Fragment>
				{hideHeader ? null : <Header />}
				<Notification ref={this.notificationRef} />
				<fieldset disabled={isLoading} className="l--content--mfa">
					<h2 className="spc--bottom--lrg">Confirm code</h2>
					<div className="spc--bottom--xxxlrg">
						{mfaType && mfaType === 'SMS_STEP_UP' ? (
							destination && (
								<p className="type--p2">
									A confirmation code has been sent to you by SMS (
									{'*'.repeat(destination.length - 4) + destination.substring(destination.length - 4)}).{' '}
									<span className="type--wgt--medium">Enter the code to continue.</span>
								</p>
							)
						) : (
							<p className="type--p2">Enter the confirmation code from your Multi-Factor Authentication (MFA) app.</p>
						)}
					</div>

					<div className="spc--bottom--xxxlrg">
						<ChallengeInput ref={ref => (this.challengeInputRef = ref)} onConfirm={this.handleConfirmMfa} />
					</div>

					{forbidden && (
						<p className="type--validation spc--bottom--xxxlrg">You do not have rights to view residuals.</p>
					)}
					{errorMessage && <p className="type--validation spc--bottom--xxxlrg">{errorMessage}</p>}
					{invalidMfaType && (
						<p className="type--validation spc--bottom--xxxlrg">
							Something went wrong when trying to access your MFA settings. Refresh the page and contact support if the
							problem persists.
						</p>
					)}
					{otherError && (
						<p className="type--validation spc--bottom--xxxlrg">
							An error occurred. Refresh the page and contact support if the problem persists.
						</p>
					)}
					<div>
						<button
							type="submit"
							disabled={this.props.isLoading || invalidMfaType || otherError || forbidden}
							onClick={this.handleConfirmMfa}
							className="btn btn--primary btn--lrg fullwidth spc--bottom--xlrg"
						>
							Confirm
						</button>
						{mfaType === 'SMS_STEP_UP' && (
							<div className="type--center">
								<button onClick={this.sendVerificationCode} className="btn btn--link btn--link--underline">
									Resend Code
								</button>
							</div>
						)}
					</div>
				</fieldset>
			</Fragment>
		);
	}
}

ConfirmMFAStepUp.propTypes = {
	mfaType: PropTypes.string,
	showLoader: PropTypes.func.isRequired,
	componentKey: PropTypes.string.isRequired,
	makePendingRequest: PropTypes.func.isRequired,
	invokeSendCodeOnAppear: PropTypes.bool,
	hideHeader: PropTypes.bool,
	isLoading: PropTypes.bool,
	history: PropTypes.shape({
		replace: PropTypes.func.isRequired,
		location: PropTypes.shape({
			search: PropTypes.string.isRequired,
		}).isRequired,
	}).isRequired,
	location: PropTypes.shape({
		search: PropTypes.string.isRequired,
	}).isRequired,
	markAsVerified: PropTypes.func.isRequired,
};

export default withCancelable(ConfirmMFAStepUp);
