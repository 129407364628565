import Schema from 'validate';

class BaseSchema extends Schema {
    constructor(...props) {
        super(...props)
        this.typecaster({
            Object: x => x,
        });
    }
}

export default BaseSchema;