import React, { Component } from 'react';

class DisplayBooleanComponent extends Component {
    render () {
        let className = '';
        let description = '';
        if (this.props.value === 1 || this.props.value === true) {
            className = 'type--bool type--bool--yes type--uppercase';
            description = 'Yes';
        } else {
            className = 'type--bool type--bool--no type--uppercase';
            description = 'No';
        }

        return(
            <span className={className}>{description}             
            </span>
        );
    }
}

export default DisplayBooleanComponent;