import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, filter, each, findIndex, replace, trim, includes, some, find, map, join, split, toLower } from 'lodash';
import GatewayPurchasePlanComponent from '../../../Equipment/gateway-purchase-plan';
import FrontendProcessorComponent from '../../../Equipment/frontend-processor';
import { EquipmentEditMethods } from '../../utilities/equipment-edit-methods';
import AddonListComponent from '../../../Equipment/addon-list';
import { modalNames, ModalWrapper } from '../../../../common/components/modal-wrapper';
import { Modal } from '../../../../common/components/modal';
import { NumericFormat } from 'react-number-format';

class GatewayEditTemplate extends Component {
    constructor(props){
        super(props);
        this.state = {
            additionalFees: cloneDeep(this.props.equipment.additionalFees),
            isAdditionalFeePopupOpen: false,
            modal: {
                name: modalNames.none,
                data: null
            },
        }
        this.equipmentEditMethods = new EquipmentEditMethods(this.props.onChange, () => cloneDeep(this.props.equipment), () => cloneDeep(this.props.equipmentDefault))
    }

    handleOpenAdditionalFeesPopup = (open = null, additionalFees = null) => {
        additionalFees = additionalFees || cloneDeep(this.props.equipment.additionalFees);
        const { isAdditionalFeePopupOpen } = this.state;
        this.setState({
            isAdditionalFeePopupOpen: !isAdditionalFeePopupOpen,
            additionalFees: cloneDeep(additionalFees)
        });
    };

    confirmAdditionalFeeChange = () => {
        const { additionalFees } = this.state;
        this.equipmentEditMethods.setAdditionalFees(additionalFees);
        this.handleOpenAdditionalFeesPopup(null, additionalFees)
    }

    handleAdditionalFeeChange = (feeId, updatedValue, isPercentage = false) => {
        const { additionalFees } = this.state;
        let additionalFeesClone = cloneDeep(additionalFees);
        const index = findIndex(additionalFees, fee => fee.feeId == feeId);

        if (index === -1) return;
        const fee = additionalFeesClone[index];

        const isValid = /^0*\.0*$/.test(updatedValue.value)
        if(isValid || (updatedValue.floatValue === undefined ||isNaN(updatedValue.floatValue))){ 
            fee.merchantPrice = null; 
        }
        else{
            fee.merchantPrice = isPercentage ? updatedValue.floatValue / 100 : updatedValue.floatValue;
        }
        additionalFeesClone[index] = fee;
        this.setState({ additionalFees: additionalFeesClone });
    }

    handleAdditionalFeeSelectChange = (...feeIds) => {
        const { additionalFees } = this.state;
        let additionalFeesClone = cloneDeep(additionalFees);
        each(feeIds, feeId => {
            const feeIndex = findIndex(additionalFees, fee => fee.feeId == feeId);
            if (feeIndex === -1) return;
            const fee = additionalFeesClone[feeIndex];
            fee.isSelected = !fee.isSelected;
            additionalFeesClone[feeIndex] = fee;
        })
        this.setState({ additionalFees: additionalFeesClone });
    }

    splitWords(name){
        if(name === "CryptoBucks"){
            return name;
        }
        return join(split(name, /([A-Z][a-z]+)/), " ");
    }

    renderPaymentSchedules = (equipment, equipmentDefault) => {
        return (
            <div id={`${equipmentDefault.name}_paymentSchedule`} className="gateway__top">
                
                <div className="gateway__top__item">
                    {Object.keys(equipmentDefault.paymentSchedules).map((value, index) => {
                        const uniqueKey = `${equipmentDefault.name}_paymentSchedule_${index}`;
                        return (
                            <div className="col spc--bottom--sml" key={uniqueKey}>
                                <input
                                    id={uniqueKey}
                                    type="radio"
                                    className="input--radio"
                                    name={`${equipmentDefault.name}_paymentSchedule`}
                                    value={value}
                                    checked={equipment.paymentSchedule === value}
                                    onChange={this.equipmentEditMethods.handleChange}
                                />
                                <label htmlFor={uniqueKey}>{equipmentDefault.paymentSchedules[value]}</label>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    renderPlatformAndSettlement = (equipment, equipmentDefault) => {
        return (
            <FrontendProcessorComponent equipment={equipmentDefault}
                merchantEquipment={equipment}
                handleChange={this.equipmentEditMethods.handleChange}
                handleTimeChange={this.equipmentEditMethods.handleTimeChange}
                optionSelect={this.equipmentEditMethods.optionSelect}
                optionSetMoreInfo={this.equipmentEditMethods.optionSetMoreInfo}/>            
        );
    }

    renderNotes = (equipment, equipmentDefault) => {
        return (
            <div className="gateway__top">
                <div className="gateway__top__item">
                    <textarea id={`${equipmentDefault.name}_notes`} className="input input--textarea spc--bottom--sml" rows="10" placeholder="Notes" name={equipmentDefault.name + '_notes'} value={equipment.notes} onChange={this.equipmentEditMethods.handleChange}></textarea>
                </div>
            </div>
        );
    }

    renderAdditionalFees() {
        const { isAdditionalFeePopupOpen, additionalFees } = this.state;
        let discountFees, specificTransactionFees;
        if (isAdditionalFeePopupOpen) {
            discountFees = filter(additionalFees, fee => toLower(fee.feeType) === 'discountfee');
            specificTransactionFees = filter(additionalFees, fee => {
                if (toLower(fee.feeType) !== 'specifictransactionfee'){
                    return false;
                }
                const baseFeeName = trim(replace(fee.feeName,'Transaction Fee',''));
                return !some(discountFees, df => includes(df.feeName, baseFeeName))
            });
        }
        return (
            <Modal isOpen={isAdditionalFeePopupOpen} onClose={() => this.handleOpenAdditionalFeesPopup()} className="popup__content">
                <div>
                    <div className="popup__header">
                        <h6>Alternative Transaction Fees</h6>
                    </div>
                    <div className="popup__body">
                        <table className="table table--secondary table--secondary--alternative-fees">
                            <col width="290" />
                            <col />
                            <col />
                            <thead>
                                <tr>
                                    <th>
                                        Transaction Type
                                    </th>
                                    <th className="type--uppercase type--center">
                                        Agent Cost
                                    </th>
                                    <th className="type--uppercase type--center">
                                        Merchant Cost
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {map(specificTransactionFees, fee => (
                                    <tr key={fee.feeId}>
                                        <td>
                                            <input
                                                type="checkbox"
                                                name="isSelected"
                                                className="input--check"
                                                checked={fee.isSelected}
                                                id={`${fee.feeId}.isSelected`}
                                                onChange={() => this.handleAdditionalFeeSelectChange(fee.feeId)}
                                            />
                                            <label
                                                className="label type--none"
                                                htmlFor={`${fee.feeId}.isSelected`}
                                            >
                                                {this.splitWords(replace(fee.feeName, ' Transaction Fee',''))}
                                            </label>
                                        </td>
                                        <td>
                                            <div className="item">
                                                <NumericFormat
                                                    prefix={'$'}
                                                    disabled={true}
                                                    decimalScale={3}
                                                    name="agent_cost"
                                                    value={fee.agentCost}
                                                    className="input input--med type--center"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <NumericFormat
                                                prefix={'$'}
                                                decimalScale={3}
                                                allowNegative={false}
                                                id={"fee_" + fee.feeId}
                                                thousandSeparator={true} 
                                                value={fee.merchantPrice}
                                                fixedDecimalScale={false}
                                                name={"fee_" + fee.feeId}
                                                disabled={!fee.isSelected}
                                                placeholder={fee.retailPrice}
                                                className="input input--med type--center"
                                                onValueChange={(values) => { this.handleAdditionalFeeChange(fee.feeId, values) }}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <table className="table table--secondary table--secondary--alternative-fees">
                            <colgroup>
                                <col width="290" />
                                <col />
                                <col />
                                <col />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th className="type--uppercase">
                                        Agent Cost
                                    </th>
                                    <th className="type--uppercase">
                                        Merchant Cost
                                    </th>
                                    <th className="type--uppercase">
                                        Percentage
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {map(discountFees, fee => {
                                    const transactionFee = find(additionalFees, additionalFee => toLower(additionalFee.feeType) === 'specifictransactionfee' && includes(fee.feeName, trim(replace(additionalFee.feeName,'Transaction Fee',''))));
                                    return (
                                        <tr key={fee.feeId}>
                                            <td>
                                                <input 
                                                    type="checkbox" 
                                                    name="isSelected" 
                                                    className="input--check" 
                                                    checked={fee.isSelected}
                                                    id={`${fee.feeId}.isSelected`} 
                                                    onChange={() => this.handleAdditionalFeeSelectChange(fee.feeId, transactionFee?.feeId)} 
                                                />
                                                <label className="label type--none" htmlFor={`${fee.feeId}.isSelected`}>
                                                    {this.splitWords(replace(transactionFee ? transactionFee.feeName : fee.feeName,' Transaction Fee',''))}
                                                </label>
                                            </td>
                                            <td>
                                                <div className="item">
                                                    {transactionFee && (
                                                        <NumericFormat
                                                            prefix={'$'}
                                                            disabled={true}
                                                            decimalScale={3}
                                                            name="agent_cost"
                                                            value={transactionFee.agentCost}
                                                            className="input input--med type--center"
                                                        />
                                                    )}
                                                </div>
                                            </td>
                                            <td>
                                                {transactionFee && (
                                                    <NumericFormat
                                                        decimalScale={3}
                                                        allowNegative={false}
                                                        fixedDecimalScale={false}
                                                        disabled={!fee.isSelected}
                                                        id={"fee_" + transactionFee.feeId}
                                                        value={transactionFee.merchantPrice}
                                                        name={"fee_" + transactionFee.feeId}
                                                        thousandSeparator={true} prefix={'$'}
                                                        placeholder={transactionFee.retailPrice}
                                                        className="input input--med type--center"
                                                        onValueChange={(values) => { this.handleAdditionalFeeChange(transactionFee.feeId, values) }}
                                                    />
                                                )}
                                            </td>
                                            <td>
                                                <NumericFormat
                                                    suffix="%"
                                                    decimalScale={2}
                                                    allowNegative={false}
                                                    id={"fee_" + fee.feeId}
                                                    fixedDecimalScale={false}
                                                    name={"fee_" + fee.feeId}
                                                    disabled={!fee.isSelected}
                                                    value={fee.merchantPrice * 100}
                                                    className="input input--med type--center"
                                                    placeholder={`${fee.retailPrice * 100}%`}
                                                    onValueChange={(values) => { this.handleAdditionalFeeChange(fee.feeId, values, true) }}
                                                />
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className="popup__footer popup__footer--styled">
                        <button className="btn btn--primary btn--med" onClick={this.confirmAdditionalFeeChange}>
                            Confirm
                        </button>
                    </div>
                </div>
            </Modal>
        );
    }

    openCloseModal = modal => this.setState({ modal });

    render() {
        let { equipment, equipmentDefault } = this.props;
        
        return (
                <React.Fragment>
                    <div className="spc--top--med separator separator--grey1 spc--bottom--med"></div>
                    {this.renderAdditionalFees()}
                    <section className="spc--bottom--lrg">
                        <h2 className="spc--bottom--med">
                            Purchase Type <span className="type--color--primary">*</span>
                        </h2>
                        <div className="gateway__top">
                            <div className="gateway__top__item">
                                {
                                    Object.keys(equipmentDefault.purchaseTypes).map((pt) => {
                                        if (toLower(pt) !== 'purchase') return null;
                                        return (
                                            <div className="spc--bottom--sml" key={`pt_${pt}`}>
                                                <input type="radio" className="input--check input--check--preview" name={equipmentDefault.name + "_purchaseType"} id={equipmentDefault.name + "_" + pt + "_pt"} onChange={this.equipmentEditMethods.selectPurchaseType}
                                                    value={pt} checked={equipment.purchaseType === pt} />
                                                <label htmlFor={equipmentDefault.name + "_" + pt + "_pt"}>{equipmentDefault.purchaseTypes[pt]}</label>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>                          
                    </section>
                    <section className="spc--bottom--lrg">
                        <h2 className="spc--bottom--med">
                            Payment Schedule <span className="type--color--primary">*</span>
                        </h2>
                        {this.renderPaymentSchedules(equipment, equipmentDefault)}
                    </section>
                    <section className="spc--bottom--lrg">
                        <h2 className="spc--bottom--med">Plans</h2>
                        <GatewayPurchasePlanComponent
                            gateway={equipmentDefault}
                            merchantGateway={equipment}
                            availablePurchasePlans={equipmentDefault.purchasePlans}
                            handleSelectPlan={this.equipmentEditMethods.selectPlan}
                            setIncludedTransactions={this.equipmentEditMethods.setIncludedTransactions}
                            handleOpenAdditionalFeesPopup={this.handleOpenAdditionalFeesPopup}
                            handleFeeChange={this.equipmentEditMethods.handleFeeChange} />
                    </section>
                    {equipment.frontendProcessors && Object.keys(equipment.frontendProcessors).length > 0 &&
                        <section id={`${equipment.name}_platform`} className="spc--bottom--lrg">
                            <h2 className="spc--bottom--med" >
                                Platform & Settlement <span className="type--color--primary">*</span>
                                <span className="datatooltip--base spc--left--tny">
                                    < i className="icon icon--tiny icon--info spc--left--sml cursor--pointer align--v--middle" data-tooltip="Automatic batching is generally scheduled at 15 minutes before the cutoff time."></i>
                                </span>
                            </h2>
                            {this.renderPlatformAndSettlement(equipment, equipmentDefault)}
                        </section>
                    }
                    <section className="table--gateway--secondary--no-sticky spc--bottom--lrg">
                        <ModalWrapper modal={this.state.modal} onModalClose={this.openCloseModal} />
                            <AddonListComponent gateway={equipmentDefault}
                                merchantGateway={equipment}
                                availablePurchasePlans={equipmentDefault.purchasePlans}
                                selectSubequipment={(e) => this.equipmentEditMethods.setGatewaySubequipment(e.target.value, e.target.checked)}
                                handleSubOptionSelect={this.equipmentEditMethods.subOptionSelect}
                                handleSubOptionChange={this.equipmentEditMethods.subOptionChange}
                                handleSubOptionMoreInfo={this.equipmentEditMethods.suboptionSetMoreInfo}
                                handleSubFeeChange={this.equipmentEditMethods.handleSubFeeChange}
                                handleSubNote={this.equipmentEditMethods.handleSubNote}
                                openCloseModal={this.openCloseModal}
                                handleGatewayOptionSelect={this.equipmentEditMethods.optionSelect}
                                handleGatewayChange={this.equipmentEditMethods.handleChange}
                                handleOptionMoreInfo={this.equipmentEditMethods.optionSetMoreInfo}
                                handleFeeChange={this.equipmentEditMethods.handleFeeChange}
                                classNames={{}}
                                errorListPaths={[]}
                            />
                        </section>
                    <section className="">
                        <h2 className="spc--bottom--med">Notes</h2>
                        {this.renderNotes(equipment, equipmentDefault)}
                    </section>
                </React.Fragment>
        );        
    }
}

GatewayEditTemplate.propTypes = {
    equipmentDefault: PropTypes.object,
    equipment: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
}

export default GatewayEditTemplate;