import React from 'react';
import { map, isEmpty } from 'lodash';

import { FileDropzoneComponent } from '../../common/components/file-dropzone';

const required = <span data-tooltip="Required" className="type--color--primary"> *</span>

function EquipmentFileUpload({ equipmentFiles, file, onDropFile, handleRemoveFile }) {
    return (
        map(equipmentFiles, ({ name, friendlyName, isRequired }) => (
            <div key={name}>
                <label className="label spc--bottom--sml">{friendlyName}{isRequired && required}</label>
                <FileDropzoneComponent
                    tag={name}
                    fileList={!isEmpty(file) ? { [name]: [{ file }] } : null}
                    onDrop={onDropFile}
                    onRemoveFile={handleRemoveFile}
                />
            </div>
        ))
    )
}

export default EquipmentFileUpload