import PropTypes from 'prop-types';
import { get, noop, each } from 'lodash';
import { withRouter } from 'react-router-dom';
import { appService } from '../../../services';
import { openFile } from '../../../common/utilities';
import { modalNames } from '../../../common/components/modal-wrapper';
import { BaseGridActionsComponent } from '../../../common/formatters';

class InvoicesGridActionsComponent extends BaseGridActionsComponent {
    static instances = [];
    constructor(props) {
        super(props, InvoicesGridActionsComponent.instances);
        this.menuItems = [
            {
                isVisible: true, 
                title: 'Email Invoice', 
                onClickHandler: this.emailInvoice, 
                iconClassName: 'icon icon--tiny icon--envelope align--v--sub', 
                href: ''
            },
            {
                isVisible: true, 
                title: 'Download Invoice', 
                onClickHandler: this.downloadInvoice, 
                iconClassName: 'icon icon--tiny icon--download align--v--sub', 
                href: ''
            },
        ];
    }

    emailInvoice = () => {
        const { dependentValues: { openCloseModal }, row } = this.props;
        this.closeDisplayMenu();
        openCloseModal({
            name: modalNames.invoiceEmail,
            data: {
                row,
                merchantEmail: this.props
            }
        })
    };

    downloadPrefilledDocument = (url, fileName ) => {
        const { row: { showLoader } } = this.props;
        showLoader(true);
        let msg = "";

        if (url.errorMessage) {
            msg = url.errorMessage;
        } else {
            openFile(url, fileName, false, false);
        }

        this.setState({ errorMessage: msg });
        showLoader();        
    };

    downloadInvoice = () => {
        const { row: { notificationRef, invoiceId, showLoader }, isCanadian } = this.props;
        showLoader(true);
        appService.getInvoiceFieldsData(invoiceId)
            .then((fieldsData) => {
                let fileName = isCanadian ? "CA Invoice Template" : "Invoice Template";
                const fields = {}
                each(fieldsData, (val, key) => fields[key] = key)
                appService.prefillDocumentFields(fieldsData, fileName, fields, true).then((blob) => {
                    this.downloadPrefilledDocument(URL.createObjectURL(blob), `Invoice_${invoiceId}.pdf`, false)
                });
                const addNotification = get(notificationRef, 'current.addNotification', noop);
                addNotification({
                    message: 'Successfully downloaded',
                    success: true,
                });
            }).catch(err => {
                const addNotification = get(notificationRef, 'current.addNotification', noop);
                addNotification({
                    message: 'Error downloading the file',
                    success: false,
                });
            }).finally(() =>{
                showLoader();
                this.closeDisplayMenu();
            });
    }

    closeDisplayMenu = () => {
        setTimeout(this.closeDisplayMenuNow, 70);
    };

    closeDisplayMenuNow = () => {
        this.setState({ isDisplayMenuOpen: false }, this.displayMenu);
    }
}

InvoicesGridActionsComponent.propTypes = {
    dependentValues: PropTypes.object,
    isScrolling: PropTypes.bool,
    history: PropTypes.object.isRequired,
};

export default withRouter(InvoicesGridActionsComponent);
