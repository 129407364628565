import React from 'react';
import { isElavonCad } from '../../utilities/processorType';
import { map } from 'lodash';

import ProcessorDropdownComponent from './processor-dropdown';

class CanadaProcessorDropdown extends ProcessorDropdownComponent {
    get options() {
        const options = [
            // {
            //     value: '',
            //     text: 'Please select',
            //     default: true,
            //     children: <React.Fragment></React.Fragment>
            // }
        ]
        map(this.props.processorList, (o) => {
            options.push({
                value: o.key,
                text: o.value,
                children: isElavonCad({ processorId: o.key }) ? <React.Fragment></React.Fragment> : this.renderProcessorReason()
            })
        })
        return options;
    }
}

export default CanadaProcessorDropdown;