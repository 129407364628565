import React, { Fragment, createRef } from 'react';
import { map, clone, isEmpty, toLower, every, noop, get } from 'lodash';
import { object, func, bool, string } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';

import { appService } from '../../services';
import { Toggle, ToggleContainer } from './../../common/components/toggle';
import { withContext, withLoader } from '../../common/components';
import { Notification } from '../../common/components/notifications';
import { MerchantContext } from '../MerchantDetails';
import { EappConfirm } from './EappConfirm';

const isExpand = true;

class GoPlusEAppConfirmComponent extends EappConfirm {
	constructor(props) {
		super(props);
		this.state = clone(this.initialState);

		this.errorRef = createRef();
		this.notificationRef = createRef();
	}

	get initialState() {
		return {
			appId: this.props.match.params.appid,
			goApp: {
				goPlusEApp: {
					appId: '',
					corporateName: '',
					dbaName: '',
					monthlySalesVolume: '',
					ownershipType: '',
					businessStartDate: '',
					tierName: '',
					processorName: '',
					processingInformation: {
						averageTicket: '',
						highTicket: '',
						monthlySalesVolume: '',
						sicCode: '',
						ccRate: '',
						ccRateAmex: '',
						perTranFee: '',
						perTranAmexFee: '',
						percentCardPresent: '',
						percentCardNotPresent: '',
						achTransactionFee: '',
						achPercentFee: '',
						achPercentFeeCap: '',
						achSetupFee: '',
						achUnauthorizedReturnFee: '',
						achMonthlyFee: '',
						achReturnFee: '',
						achEnabled: false,
					},
					percentCardPresent: '',
					percentCardNotPresent: '',
					agentName: '',
					submittedByName: '',
					agentEmail: '',
					submittedByEmail: '',
					agentId: '',
					telemarketerName: '',
					telemarketerId: '',
					agentReadonly: '',
					website: '',
					taxId: '',
					businessEmail: '',
					businessPhone: '',
					businessAddress: {
						streetAddress: '',
						city: '',
						state: '',
						zip: '',
						country: '',
					},
					mailingAddress: {
						streetAddress: '',
						city: '',
						state: '',
						zip: '',
						country: '',
					},
					productSold: '',
					bankingInformation: {
						bankName: '',
						accountType: '',
						routingNumber: '',
						accountNumber: '',
					},
					signerInformationList: [
						{
							ssn: '',
							dateOfBirth: '',
							firstName: '',
							lastName: '',
							address: {
								streetAddress: '',
								city: '',
								state: '',
								zip: '',
								country: '',
							},
							ownershipPercentage: '',
							appAdditionalOwnerID: '',
							cellPhone: '',
							title: '',
						},
					],
					signerInformation: {
						ssn: '',
						dateOfBirth: '',
						firstName: 'test',
						lastName: '',
						address: {
							streetAddress: '',
							city: '',
							state: '',
							zip: '',
							country: '',
						},
						ownershipPercentage: '',
						appAdditionalOwnerID: '',
						cellPhone: '',
						title: '',
					},
					signature: '',
					totalMinorOwnershipPercent: '',
					wasSubmitted: '',
					loggedInUsername: '',
					currentAction: '',
				},
				refNum: '',
				status: '',
			},
		};
	}

	componentDidMount() {
		this.loadDataFromService();
	}

	getApp(response) {
		return appService.getGoPlus(this.state.appId).then(goApp => {
			response.goApp = goApp;
		});
	}

	componentDidUpdate() {
		if (this.state.errorMessage && this.state.isNewError) {
			setTimeout(() => {
				if (this.errorRef.current) {
					this.errorRef.current.scrollIntoView({
						behavior: 'smooth',
						block: 'center',
					});
				}
			}, 200);
			this.setState({ isNewError: false });
		}
	}

	mapResponseToState = response => {
		this.setState(response);
	};

	renderSalesRep = () => {
		const { goApp } = this.state;
		return (
			<Toggle initialToggle={isExpand}>
				{({ isToggled, handleToggle }) =>
					!isEmpty(goApp) && (
						<Fragment>
							<div className="row">
								<div className="col col-sml-12 col-med-6 form__group">
									<div className="form__group__header">
										<p className="form__group__label">Tier</p>
									</div>
									<div className="input input--med input--disabled">{goApp.goPlusEApp.tierName}</div>
								</div>
							</div>
							<div className={`card${isToggled ? ' is-expanded' : ''} spc--bottom--lrg`}>
								<button className={`card__header ${isToggled ? 'is-expanded' : 'is-collapsed'}`} onClick={handleToggle}>
									<h5>Sales Rep</h5>
									<i className={`icon icon--med icon--chevron--${isToggled ? 'top' : 'right'}--primary`}></i>
								</button>
								{isToggled && (
									<div className="card__body">
										<div className="row">
											<div className="col col-sml-12 col-med-6 form__group">
												<div className="form__group__header">
													<p className="form__group__label">Sales Rep Name</p>
												</div>
												<div className="input input--med input--disabled">
													{goApp.goPlusEApp.submittedByName
														? goApp.goPlusEApp.submittedByName
														: goApp.goPlusEApp.agentName}
												</div>
											</div>
											<div className="col col-sml-12 col-med-6 form__group">
												<div className="form__group__header">
													<p className="form__group__label">Sales Rep Email Address</p>
												</div>
												<div className="input input--med input--disabled">
													{goApp.goPlusEApp.submittedByEmail
														? goApp.goPlusEApp.submittedByEmail
														: goApp.goPlusEApp.agentEmail}
												</div>
											</div>
											<div className="col col-sml-12 col-med-6 form__group">
												<div className="form__group__header">
													<p className="form__group__label">Rep 2</p>
												</div>
												<div className="input input--med input--disabled">{goApp.goPlusEApp.telemarketerName}</div>
											</div>
										</div>
									</div>
								)}
							</div>
						</Fragment>
					)
				}
			</Toggle>
		);
	};

	renderMpaForm = () => {
		const { goApp } = this.state;
		const mailingAddressSameAsLocationAddress = every(goApp.goPlusEApp.businessAddress, (value, key) => {
			return toLower(value) === toLower(goApp.goPlusEApp.businessAddress[key]);
		});
		return (
			<Toggle initialToggle={isExpand}>
				{({ isToggled, handleToggle }) =>
					!isEmpty(goApp) && (
						<Fragment>
							<div className={`card${isToggled ? ' is-expanded' : ''} spc--bottom--lrg`}>
								<button onClick={handleToggle} className="card__header card__header--expandable">
									<h5>Business Information</h5>
									<i className={`icon icon--lrg icon--chevron--${isToggled ? 'top' : 'right'}--primary`}></i>
								</button>
								{isToggled && (
									<div className="card__body">
										<div className="row">
											<div className="col col-sml-12 col-med-6 form__group">
												<div className="form__group__header">
													<p className="form__group__label">Company Legal Name</p>
												</div>
												<div className="input input--med input--disabled">{goApp.goPlusEApp.corporateName}</div>
											</div>
											<div className="col col-sml-12 col-med-6 form__group">
												<div className="form__group__header">
													<p className="form__group__label">DBA</p>
												</div>
												<div className="input input--med input--disabled">{goApp.goPlusEApp.dbaName}</div>
											</div>
											<div className="col col-sml-12 col-med-6 form__group">
												<div className="form__group__header">
													<p className="form__group__label">EIN</p>
												</div>
												<div className="input input--med input--disabled">{goApp.goPlusEApp.taxId}</div>
											</div>
											<div className="col col-sml-12 col-med-6 form__group">
												<div className="form__group__header">
													<p className="form__group__label">Website</p>
												</div>
												<div className="input input--med input--disabled">{goApp.goPlusEApp.website}</div>
											</div>
											<div className="col col-sml-12 col-med-6 form__group">
												<div className="form__group__header">
													<p className="form__group__label">Primary Business Phone Number</p>
												</div>
												<div className="input input--med input--disabled">{goApp.goPlusEApp.businessPhone}</div>
											</div>
											<div className="col col-sml-12 col-med-6 form__group">
												<div className="form__group__header">
													<p className="form__group__label">Business Email</p>
												</div>
												<div className="input input--med input--disabled">{goApp.goPlusEApp.businessEmail}</div>
											</div>
											<div className="col col-sml-12 col-med-6 form__group">
												<div className="form__group__header">
													<p className="form__group__label">Ownership Type</p>
												</div>
												<div className="input input--med input--disabled">{goApp.goPlusEApp.ownershipType}</div>
											</div>
											<div className="col col-sml-12 col-med-6 form__group">
												<div className="form__group__header">
													<p className="form__group__label">Business Start Date</p>
												</div>
												<div className="input input--med input--disabled">{goApp.goPlusEApp.businessStartDate}</div>
											</div>
											<div className="col col-sml-12 col-med-6 form__group">
												<div className="form__group__header">
													<p className="form__group__label">Products/Services</p>
												</div>
												<div className="input input--med input--disabled">{goApp.goPlusEApp.productSold}</div>
											</div>
										</div>
										<h6 className="spc--bottom--sml">Business Address</h6>
										<div className="row">
											<div className="col col-sml-12 col-med-6 form__group">
												<div className="form__group__header">
													<p className="labelform__group__">Address</p>
												</div>
												<div className="input input--med input--disabled">
													{goApp.goPlusEApp.businessAddress.streetAddress}
												</div>
											</div>
											<div className="col col-sml-12 col-med-6 form__group">
												<div className="form__group__header">
													<p className="form__group__label">City</p>
												</div>
												<div className="input input--med input--disabled">{goApp.goPlusEApp.businessAddress.city}</div>
											</div>
											<div className="col col-sml-12 col-med-6 form__group">
												<div className="form__group__header">
													<p className="form__group__label">State</p>
												</div>
												<div className="input input--med input--disabled">{goApp.goPlusEApp.businessAddress.state}</div>
											</div>
											<div className="col col-sml-12 col-med-6 form__group">
												<div className="form__group__header">
													<p className="form__group__label">Zip Code</p>
												</div>
												<div className="input input--med input--disabled">{goApp.goPlusEApp.businessAddress.zip}</div>
											</div>
										</div>
										<h6 className="spc--bottom--sml">Mailing Address</h6>
										<div className="form__group">
											<input
												name="mailingAddressSameAsLocationAddress"
												type="checkbox"
												id="mailingAddressSameAsLocationAddress"
												className="input--check input--check--preview"
												checked={mailingAddressSameAsLocationAddress}
												value={mailingAddressSameAsLocationAddress}
												onChange={noop}
											/>
											<label htmlFor="mailingAddressSameAsLocationAddress">Same As Business Address</label>
										</div>
										<div className="row">
											<div className="col col-sml-12 col-med-6 form__group">
												<div className="form__group__header">
													<p className="form__group__label">Address</p>
												</div>
												<div className="input input--med input--disabled">
													{goApp.goPlusEApp.mailingAddress.streetAddress}
												</div>
											</div>
											<div className="col col-sml-12 col-med-6 form__group">
												<div className="form__group__header">
													<p className="form__group__label">City</p>
												</div>
												<div className="input input--med input--disabled">{goApp.goPlusEApp.mailingAddress.city}</div>
											</div>
											<div className="col col-sml-12 col-med-6 form__group">
												<div className="form__group__header">
													<p className="form__group__label">State</p>
												</div>
												<div className="input input--med input--disabled">{goApp.goPlusEApp.mailingAddress.state}</div>
											</div>
											<div className="col col-sml-12 col-med-6 form__group">
												<div className="form__group__header">
													<p className="form__group__label">Zip Code</p>
												</div>
												<div className="input input--med input--disabled">{goApp.goPlusEApp.mailingAddress.zip}</div>
											</div>
										</div>
									</div>
								)}
							</div>
						</Fragment>
					)
				}
			</Toggle>
		);
	};

	renderField = (name, value, isText, format, isVisible = true) => {
		if (!isVisible) return null;
		let suffix,
			prefix = '';
		if (format === '%') suffix = format;
		if (format === '$') prefix = format;
		return (
			<div className="col col-sml-12 col-med-6 form__group">
				<div className="form__group__header">
					<p className="form__group__label">{name}</p>
				</div>
				{isText ? (
					<div className="input input--med input--disabled">{value}</div>
				) : (
					<NumericFormat
						className={`input input--med input--disabled`}
						value={value}
						inputType="numeric"
						prefix={prefix}
						suffix={suffix}
					/>
				)}
			</div>
		);
	};

	getTotalSales = (percentCardNotPresent, percentCardPresent) => {
		const total = parseFloat(percentCardNotPresent || 0) + parseFloat(percentCardPresent || 0);

		if (String(total).indexOf('.') > -1) {
			return total.toFixed(2);
		}

		return total;
	};

	renderProcessingInformation = () => {
		const { goApp } = this.state;
		const goPlusEApp = get(goApp, 'goPlusEApp', {});
		const isAchqOnly = toLower(goPlusEApp.processorName) === 'achq';
		return (
			<Fragment>
				{!isAchqOnly && this.renderCCProcessingInformation(goApp)}
				{this.renderACHProcessingInformation(goApp)}
			</Fragment>
		);
	};

	renderCCProcessingInformation = goApp => {
		const goPlusEApp = get(goApp, 'goPlusEApp', {});
		const processingInformation = get(goPlusEApp, 'processingInformation', {});
		const isFlatRate = get(processingInformation, 'rateStructure', '') === 'Flat Rate';
		const showInternational =
			get(processingInformation, 'visaInternationalRate', '0') != '0' ||
			get(processingInformation, 'mcInternationalRate', '0') != '0' ||
			get(processingInformation, 'discoverInternationalRate', '0') != '0';
		return (
			<Toggle initialToggle={isExpand}>
				{({ isToggled, handleToggle }) =>
					!isEmpty(goApp) && (
						<Fragment>
							<div className={`card${isToggled ? ' is-expanded' : ''} spc--bottom--lrg`}>
								<button className="card__header card__header--expandable" onClick={handleToggle}>
									<h5>Credit Card Processing Information</h5>
									<i className={`icon icon--lrg icon--chevron--${isToggled ? 'top' : 'right'}--primary`}></i>
								</button>
								{isToggled && (
									<div className="card__body">
										<div className="row ">
											{this.renderField('Average Ticket', processingInformation.averageTicket, false, '$')}
											{this.renderField('High Ticket', processingInformation.highTicket, false, '$')}
											{this.renderField('Monthly Sales Volume', processingInformation.monthlySalesVolume, false, '$')}
											{this.renderField('SIC Code', processingInformation.sicCode, false, null)}
											{this.renderField('Rate Structure', processingInformation.rateStructure, true, null)}
											{this.renderField('Per Tran Fee Type', processingInformation.tranFeeType, true, null)}

											{this.renderField('CC Rate', processingInformation.ccRate, false, '%', isFlatRate)}
											{this.renderField('CC Rate Amex', processingInformation.ccRateAmex, false, '%', isFlatRate)}
											{this.renderField('Per Tran Fee', processingInformation.perTranFee, false, '$', isFlatRate)}
											{this.renderField(
												'Per Tran Amex Fee',
												processingInformation.perTranAmexFee,
												false,
												'$',
												isFlatRate
											)}

											{this.renderField('Visa Rate', processingInformation.visaRate, false, '%', !isFlatRate)}
											{this.renderField('Mastercard Rate', processingInformation.mcRate, false, '%', !isFlatRate)}
											{this.renderField('Discover Rate', processingInformation.discoverRate, false, '%', !isFlatRate)}
											{this.renderField('Amex Rate', processingInformation.amexRate, false, '%', !isFlatRate)}

											{this.renderField(
												`Visa ${processingInformation.tranFeeType} Fee`,
												processingInformation.visaTranFee,
												false,
												'$',
												!isFlatRate
											)}
											{this.renderField(
												`Mastercard ${processingInformation.tranFeeType} Fee`,
												processingInformation.mcTranFee,
												false,
												'$',
												!isFlatRate
											)}
											{this.renderField(
												`Discover ${processingInformation.tranFeeType} Fee`,
												processingInformation.discoverTranFee,
												false,
												'$',
												!isFlatRate
											)}
											{this.renderField(
												`Amex ${processingInformation.tranFeeType} Fee`,
												processingInformation.amexTranFee,
												false,
												'$',
												!isFlatRate
											)}

											{this.renderField(
												`Visa International Rate`,
												processingInformation.visaInternationalRate,
												false,
												'%',
												showInternational
											)}
											{this.renderField(
												`Mastercard International Rate`,
												processingInformation.mcInternationalRate,
												false,
												'%',
												showInternational
											)}
											{this.renderField(
												`Discover International Rate`,
												processingInformation.discoverInternationalRate,
												false,
												'%',
												showInternational
											)}

											{this.renderField(
												`Percent of Card Present Sales (%)`,
												processingInformation.percentCardPresent,
												false,
												null
											)}
											{this.renderField(
												`Percent of Card-Not-Present Sales (%)`,
												processingInformation.percentCardNotPresent,
												false,
												null
											)}
											<div className="col col-sml-12 col-lrg-6 form__group">
												<div className="form__group__header">
													<p className="form__group__label">Total Card Sales (must equal 100%):</p>
												</div>
												<div
													id="totalSales"
													className={
														this.getTotalSales(
															processingInformation.percentCardPresent,
															processingInformation.percentCardNotPresent
														) == 100
															? 'form__total form__total--success'
															: 'form__total form__total--warning'
													}
												>
													{this.getTotalSales(
														processingInformation.percentCardPresent,
														processingInformation.percentCardNotPresent
													)}
													%
												</div>
											</div>
										</div>
									</div>
								)}
							</div>
						</Fragment>
					)
				}
			</Toggle>
		);
	};

	renderACHProcessingInformation = goApp => {
		const goPlusEApp = get(goApp, 'goPlusEApp', {});
		const isAchqOnly = toLower(goPlusEApp.processorName) === 'achq';
		const processingInformation = get(goPlusEApp, 'processingInformation', {});
		const isAchVisible = isAchqOnly || processingInformation.achEnabled;

		if (!isAchVisible) return null;
		return (
			<Toggle initialToggle={isExpand}>
				{({ isToggled, handleToggle }) =>
					!isEmpty(goApp) && (
						<div className={`card${isToggled ? ' is-expanded' : ''} spc--bottom--lrg`}>
							<button onClick={handleToggle} className="card__header">
								<h5>ACH Processing Information</h5>
								<i className={`icon icon--med icon--chevron--${isToggled ? 'top' : 'right'}--primary`}></i>
							</button>
							{isToggled && (
								<div className="card__body">
									<div className="row">
										{this.renderField('SIC Code', processingInformation.sicCode, true, '', isAchqOnly)}
										{this.renderField(`ACH Transaction Fee`, processingInformation.achTransactionFee, false, '$')}
										{this.renderField(`ACH Percent Fee`, processingInformation.achPercentFee, false, '$')}
										{this.renderField(`ACH Percent Fee Cap`, processingInformation.achPercentFeeCap, false, '$')}
										{this.renderField(`ACH Setup Fee`, processingInformation.achSetupFee, false, '$')}
										{this.renderField(
											`ACH Unauthorized Return Fee`,
											processingInformation.achUnauthorizedReturnFee,
											false,
											'$'
										)}
										{this.renderField(`ACH Monthly Service Fee`, processingInformation.achMonthlyFee, false, '$')}
										{this.renderField(`ACH Return Fee`, processingInformation.achReturnFee, false, '$')}
									</div>
								</div>
							)}
						</div>
					)
				}
			</Toggle>
		);
	};

	renderBankingInformation = () => {
		const { goApp } = this.state;
		return (
			<Toggle initialToggle={isExpand}>
				{({ isToggled, handleToggle }) =>
					!isEmpty(goApp) && (
						<Fragment>
							<div className={`card${isToggled ? ' is-expanded' : ''} spc--bottom--lrg`}>
								<button className="card__header card__header--expandable" onClick={handleToggle}>
									<h5>Banking Information</h5>
									<i className={`icon icon--lrg icon--chevron--${isToggled ? 'top' : 'right'}--primary`}></i>
								</button>
								{isToggled && (
									<div className="card__body">
										<div className="row">
											<div className="col col-sml-12 col-med-6 form__group">
												<div className="form__group__header">
													<p className="form__group__label">Bank Name </p>
												</div>
												<div className="input input--med input--disabled">
													{goApp.goPlusEApp.bankingInformation.bankName}
												</div>
											</div>
											<div className="col col-sml-12 col-med-6 form__group">
												<div className="form__group__header">
													<p className="form__group__label">Account Type </p>
												</div>
												<div className="input input--med input--disabled">
													{goApp.goPlusEApp.bankingInformation.accountType}
												</div>
											</div>
											<div className="col col-sml-12 col-med-6 form__group">
												<div className="form__group__header">
													<p className="form__group__label">Routing Number </p>
												</div>
												<div className="input input--med input--disabled">
													****{goApp.goPlusEApp.bankingInformation.routingNumber.slice(-4)}
												</div>
											</div>
											<div className="col col-sml-12 col-med-6 form__group">
												<div className="form__group__header">
													<p className="form__group__label">Account Number </p>
												</div>
												<div className="input input--med input--disabled">
													****{goApp.goPlusEApp.bankingInformation.accountNumber.slice(-4)}
												</div>
											</div>
										</div>
									</div>
								)}
							</div>
						</Fragment>
					)
				}
			</Toggle>
		);
	};

	renderSignerInformation = () => {
		const { goApp } = this.state;
		const { showSSN } = this.props;
		return (
			<Toggle initialToggle={isExpand}>
				{({ isToggled, handleToggle }) =>
					!isEmpty(goApp) && (
						<div className={`card${isToggled ? ' is-expanded' : ''} spc--bottom--lrg`}>
							<button className="card__header card__header--expandable" onClick={handleToggle}>
								<h5>Signer Information</h5>
								<i className={`icon icon--lrg icon--chevron--${isToggled ? 'top' : 'right'}--primary`}></i>
							</button>
							{isToggled &&
								map(goApp.goPlusEApp.signerInformationList, (signerInformation, index) => (
									<div className="card__body" key={index}>
										<div className="row">
											<div className="col col-sml-12 col-med-6 form__group">
												<div className="form__group__header">
													<p className="form__group__label">Total Minor Ownership Percent </p>
												</div>
												<div className="input input--med input--disabled">
													{goApp.goPlusEApp.totalMinorOwnershipPercent}
												</div>
											</div>
										</div>

										<h6 className="spc--bottom--lrg">Signer {index + 1}</h6>
										<div className="row">
											<div className="col col-sml-12 col-med-6 form__group">
												<div className="form__group__header">
													<p className="form__group__label">Ownership Percent </p>
												</div>
												<div className="input input--med input--disabled">{signerInformation.ownershipPercentage}</div>
											</div>
											{showSSN && (
												<div className="col col-sml-12 col-med-6 form__group">
													<div className="form__group__header">
														<p className="form__group__label">SSN </p>
													</div>
													<div className="input input--med input--disabled">{signerInformation.ssn}</div>
												</div>
											)}
											<div className="col col-sml-12 col-med-6 form__group">
												<div className="form__group__header">
													<p className="form__group__label">Date Of Birth </p>
												</div>
												<div className="input input--med input--disabled">{signerInformation.dateOfBirth} </div>
											</div>
											<div className="col col-sml-12 col-med-6 form__group">
												<div className="form__group__header">
													<p className="form__group__label">First Name </p>
												</div>
												<div className="input input--med input--disabled">{signerInformation.firstName}</div>
											</div>
											<div className="col col-sml-12 col-med-6 form__group">
												<div className="form__group__header">
													<p className="form__group__label">Last Name </p>
												</div>
												<div className="input input--med input--disabled">{signerInformation.lastName}</div>
											</div>
											<div className="col col-sml-12 col-med-6 form__group">
												<div className="form__group__header">
													<p className="form__group__label">Street Address </p>
												</div>
												<div className="input input--med input--disabled">
													{signerInformation.address.streetAddress}
												</div>
											</div>
											<div className="col col-sml-12 col-med-6 form__group">
												<div className="form__group__header">
													<p className="form__group__label">City </p>
												</div>
												<div className="input input--med input--disabled">{signerInformation.address.city}</div>
											</div>
											<div className="col col-sml-12 col-med-6 form__group">
												<div className="form__group__header">
													<p className="form__group__label">State </p>
												</div>
												<div className="input input--med input--disabled">{signerInformation.address.state}</div>
											</div>
											<div className="col col-sml-12 col-med-6 form__group">
												<div className="form__group__header">
													<p className="form__group__label">Zip </p>
												</div>
												<div className="input input--med input--disabled">{signerInformation.address.zip}</div>
											</div>
											<div className="col col-sml-12 col-med-6 form__group">
												<div className="form__group__header">
													<p className="form__group__label">Country </p>
												</div>
												<div className="input input--med input--disabled">{signerInformation.address.country}</div>
											</div>
										</div>
									</div>
								))}
						</div>
					)
				}
			</Toggle>
		);
	};

	render() {
		const { title } = this.props;
		const {
			appId,
			errorMessage,
			goApp: { dbaName },
		} = this.state;
		return (
			<div id="main-div" className="l--content--alt">
				<Notification ref={this.notificationRef} />
				{errorMessage ? (
					<div className="type--validation spc--bottom--lrg" ref={this.errorRef}>
						{errorMessage}
					</div>
				) : null}
				<ToggleContainer>
					<header>
						{dbaName && appId && (
							<h5 className="type--color--text--light spc--bottom--med">{`${dbaName} - ${appId}`}</h5>
						)}
						<div className="flex--secondary flex--gap--med spc--bottom--xxlrg">
							<h4>{title}</h4>
						</div>
					</header>
					<div className="w--max--790">
						{this.renderSalesRep()}
						{this.renderMpaForm()}
						{this.renderProcessingInformation()}
						{this.renderBankingInformation()}
						{this.renderSignerInformation()}
					</div>
				</ToggleContainer>
			</div>
		);
	}
}

GoPlusEAppConfirmComponent.defaultProps = {
	showNavbar: true,
	displayRates: false,
	displayLastFourOnly: false,
	showSSN: true,
};

GoPlusEAppConfirmComponent.propTypes = {
	match: object,
	history: object,
	showLoader: func,
	isLoading: bool,
	title: string,
	displayLastFourOnly: bool,
	showSSN: bool,
};

export default withLoader(withContext(withRouter(GoPlusEAppConfirmComponent), MerchantContext, 'merchant'));
