import { find, findIndex, filter } from 'lodash';

function validateOnBlur(schema, form, errorList, field) {
    const newState = { errorList: [...errorList] };
    const newErrorList = schema.validate({...form});
    const newFieldError = find(newErrorList, ({ path }) => path === field);
    const validatedErrorIndex = findIndex(newState.errorList, ({ path }) => path === field);

    if (validatedErrorIndex > -1) {
        if (newFieldError) {
            newState.errorList[validatedErrorIndex] = newFieldError;
        } else {
            newState.errorList = filter(newState.errorList, ({ path }) => path !== field);
        }
    } else if (newFieldError) {
        newState.errorList.push(newFieldError);
    }

    newState.errorListPaths = newState.errorList.map(e => e.path);
    return newState;
}

export default validateOnBlur;