import { getFilterValues, createFilters } from '../../../common/utilities';
import moment from 'moment';

export const residualsFilter = createFilters([
    {
        key: 'year',
        name: 'Year',
        values: {
            year: moment(new Date()).subtract(1, "month").year(),
            fieldName: 'year'
        },
    },
    {
        key: 'month',
        name: 'Month',
        values: {
            month: moment(new Date()).subtract(1, "month").month() + 1,
            fieldName: 'month'
        },
    },
    {
        key: 'agentId',
        name: 'Agent Id',
        values: {
            agentId: null,
            fieldName: 'agentId'
        },
    },
    {
        key: 'agentNumber',
        name: 'Agent Number',
        values: {
            agentNumber: null,
            fieldName: 'agentNumber'
        },
    },
    {
        key: 'agentName',
        name: 'Agent Name',
        values: {
            agentName: null,
            fieldName: 'agentName'
        },
    },
]);

export const compileFilter = filters => {
    const {
        year,
        month,
        agentId,
        agentName,
        agentNumber
    } = getFilterValues(filters);

    const filter = {};

    if (agentId.agentId != null) {
        filter.agentId = agentId.agentId;
    }
    if (agentNumber.agentNumber != null) {
        filter.agentNumber = agentNumber.agentNumber;
    }
    if (year.year != null) {
        filter.year = year.year;
    }
    if (month.month != null) {
        filter.month = month.month;
    }
    if (agentName.agentName != null) {
        filter.agentName = agentName.agentName;
    }

    return filter;
};