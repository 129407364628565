function openFile(url, fileName, preview, download) {
    if (preview) {
        const w = window.open(url, '_blank');
        const interval = window.setInterval(function () {
            if (w && !w.closed) return;
            window.URL.revokeObjectURL(url);
            clearInterval(interval);
        }, 1000);
        return download && w && w.print();
    }
    const a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    a.setAttribute('download', fileName);
    a.click();
    window.URL.revokeObjectURL(url);
}

export default openFile;