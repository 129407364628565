import React, {Component} from 'react';
import PropTypes from 'prop-types';

class MIDFilterComponent extends Component {
    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
    }

    componentDidUpdate() {
        if (this.mid) {
            this.mid.focus();
        }
    }
    filterChange = e => {
        const value = e.target.value ? e.target.value : null;

        this.onFilterChange(value)
    };

    onFilterChange(value) {
        this.props.onFilterChanged({
            id: 'mid',
            values: [{key:'mid', value: value}],
            emptyValue: null
        });
    };

    render() {
        const mid = this.props.filter.values.mid;

        return (
            <div className="pos--rel">
                <input
                    type="text"
                    placeholder="MID"
                    className="input input--number input--med"
                    value={mid === null ? '' : mid}
                    id="mid"
                    onChange={this.filterChange}
                    ref={this.inputRef}
                    autoFocus={true}
                />
            </div>
        );        
    }
}

MIDFilterComponent.propTypes = {
    filter: PropTypes.object.isRequired,
    onFilterChanged: PropTypes.func.isRequired
};

export default MIDFilterComponent;