import React, { Component, Fragment } from 'react';
import { object, func, bool } from 'prop-types';
import { Auth } from 'aws-amplify';
import { replace } from 'lodash';

import { appService } from '../../services';
import { withLoader } from '../../common/components';
import { withCancelable } from '../../common/components/cancelable';
import { Notification } from '../../common/components/notifications';
import { parseError, cognitoErrorMap, logClientError } from '../../common/utilities';
import UserContext from './UserContext';
import { getRedirectRoute } from './loginUtils';

const { REACT_APP_AGENT_SUPPORT_EMAIL } = process.env;

const messages = {
	SMS_MFA: destination => (
		<Fragment>
			A security code has been sent to you by SMS ({destination}). Enter the code to continue.
		</Fragment>
	),
	SOFTWARE_TOKEN_MFA: () => (
		<Fragment>
			Enter the confirmation code from your Multi-Factor Authentication (MFA) app.
		</Fragment>
	),
};

class ConfirmMFAComponent extends Component {
	constructor(props) {
		super(props);

		const user = UserContext.user;
		const username = (props.location.state && props.location.state.username) || null;
		const password = (props.location.state && props.location.state.password) || null;
		const challengeName = (props.location.state && props.location.state.challengeName) || null;
		const challengeDestination = (props.location.state && props.location.state.challengeDestination) || null;
		
		this.state = {
			user,
			username,
			password,
			rememberDevice: false,
			challengeName,
			challengeDestination,
			challengeAnswer: '',
			errorMessage: null,
			successMessage: null,
		};
		this.notificationRef = React.createRef();
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentWillMount() {
		if (!UserContext.user) {
			this.props.history.push('/');
		}
	}

	handleChange(event) {
		this.setState({
			[event.target.name]: event.target.value,
		});
	}

	toggleRemember = event => {
		this.setState({ rememberDevice: event.target.checked });
	};

	sendVerificationCode = async (e) => {
		e.preventDefault()
		e.stopPropagation()
		const { showLoader } = this.props;
		const { challengeDestination, username, password } = this.state;

		try {
			showLoader(true);
			let user = await Auth.signIn(username, password);
			UserContext.setUser(user);
			this.setState({ user });
            this.notificationRef.current.addNotification({
                success: true,
                message: `Confirmation code successfully sent to ${challengeDestination}`
            });

		} catch (e) {
            this.notificationRef.current.addNotification({
                success: false,
                message: `There was an error sending the confirmation code. Try again later or contact support.`
            });
		}finally{
            showLoader(false);
        }
	};

	async handleSubmit(event) {
		event.preventDefault();
		if (!this.formValidation()) {
			return;
		}

		const {
			user,
			challengeAnswer,
			username,
			challengeName,
			rememberDevice,
		} = this.state;
		const { showLoader, makePendingRequest } = this.props;

		showLoader(true);

		try {
			const authUser = await Auth.confirmSignIn(user, challengeAnswer, challengeName);
			const token =
				(authUser &&
					authUser.signInUserSession &&
					authUser.signInUserSession.idToken &&
					authUser.signInUserSession.idToken.jwtToken) ||
				false;

			if (token) {
				try {
					const auth = await makePendingRequest(appService.login(token, username));
					showLoader(false);

					if (auth.token) {
						if (rememberDevice) {
							await new Promise((resolve, reject) => {
								authUser.setDeviceStatusRemembered({
									onSuccess: function() {resolve()},
									onFailure: function(err) {reject(err)},
								});
							});
						} else {
							await new Promise((resolve, reject) => {
								authUser.setDeviceStatusNotRemembered({
									onSuccess: function() {resolve()},
									onFailure: function(err) {reject(err)},
								});
							});
						}
						this.redirect();
					}
				} catch (err) {
					if (err && err.isCanceled) {
						return;
					}

					const { stack } = parseError(err);
					showLoader(false);
					if (
						err &&
						err.ex &&
						err.ex.response &&
						(err.ex.response.status === 401 || err.ex.response.status === 403)
					) {
						this.setState({
							errorMessage: 'You are not authorized to access the page. Contact customer support: cs@cardknox.com',
						});
					}  else if (err && err.startsWith && err.startsWith('User authenticated but not found'))
                    {
                        this.setState({
                            errorMessage: <span>Email address is not registered for the Partner Portal. For assistance, reach out to <a className="anchor anchor--primary type--underline" href={`mailto:${REACT_APP_AGENT_SUPPORT_EMAIL}`}>Agent Support.</a></span>,
                        });
                    } else {
						this.setState({ errorMessage: logClientError(stack) });
					}
				}
			} else {
				this.setState({
					errorMessage: 'You are not authorized to access the page. Contact customer support: cs@cardknox.com',
				});
				showLoader(false);
			}
		} catch (err) {
			// Token is not verified
			let message = <span>Something went wrong. Please <a className="anchor anchor--primary" href="/logout">try logging in again.</a></span>;
			if (err && err.code) {
				message = cognitoErrorMap[err.code] || {message};
				message = replace(message.message, '[ERR]', err.message);
			}
			this.setState(
				{
					errorMessage: message,
					successMessage: null,
				},
				showLoader
			);
		}
	}

	formValidation = () => {
		const { challengeAnswer } = this.state;

		if (challengeAnswer.length <= 0) {
			this.setState({
				errorMessage: 'Please enter the code to verify',
				successMessage: null,
			});
			return false;
		}

		return true;
	};

	redirect() {
		const { history, location } = this.props;
		let redirectUrl = getRedirectRoute();

		if (location.state && location.state.returnUrl) {
			redirectUrl = location.state.returnUrl;
		}

		history.push(redirectUrl);
	}

	render() {
		const {
			challengeAnswer,
			errorMessage,
			successMessage,
			challengeName,
			challengeDestination,
			rememberDevice,
		} = this.state;

		return (
			<div>
				<form className="form" onSubmit={this.handleSubmit}>
					<div className="membership__section">
						<h2 className="membership__title">Confirm Code</h2>
						{challengeName && (
							<div className="membership__message">
								<p className="membership__message__description">{messages[challengeName](challengeDestination)}</p>
							</div>
						)}

						<div className="membership__spacer">
							<label className="membership__label">Confirmation code</label>
							<input
								name="challengeAnswer"
								type="text"
								className="input input--med"
								placeholder="000000"
								value={challengeAnswer}
								onChange={this.handleChange}
								autoFocus
							/>
						</div>
						<div className="membership__spacer">
							<input
								type="checkbox"
								id="rememberDevice"
								checked={rememberDevice}
								onChange={this.toggleRemember}
								className="input input--check input--check--sml"
							></input>
							<label htmlFor="rememberDevice">Remember this device for 30 days</label>
						</div>
						{errorMessage ? <div className="spc--bottom--med note note--warning">{errorMessage}</div> : null}
						{successMessage ? <div className="spc--bottom--med note note--success">{successMessage}</div> : null}
						<div className="membership__spacer">
							<button disabled={this.props.isLoading} type="submit" className="btn btn--primary btn--med spc--right--tny">
								<span className="spc--right--tny">Confirm</span>
								<span className="hide--to--sml--inline">Sign-In</span>
							</button>
							{
								challengeName === 'SMS_MFA' && 
								<button disabled={this.props.isLoading} onClick={this.sendVerificationCode} className="btn btn--primary btn--med">
									<span className="spc--right--tny">Resend Code</span>
								</button>
							}
						</div>
					</div>
				</form>
				<Notification ref={this.notificationRef} />
			</div>
		);
	}
}

ConfirmMFAComponent.propTypes = {
	location: object.isRequired,
	history: object.isRequired,
	showLoader: func.isRequired,
	isLoading: bool,
};

export default withCancelable(withLoader(ConfirmMFAComponent));
