import React, { Component, Fragment, createRef } from 'react';
import PropTypes from 'prop-types';
import { Data } from '../../common/components/react-data-grid-addons';
import { cloneDeep, each, find, unionBy, map, concat, toLower, get, filter, some, isEqual, noop, isEmpty, first, compact } from 'lodash';

import Schema from '../../validation/BaseSchema';
import { EquipmentColumns as Columns } from './column-filter/equipmentColumns';
import { equipmentFilter as Filter, compileFilter } from './filter/equipmentFilter';
import { appService } from '../../services/appService';
import { GridComponent, ToolbarComponent } from '../../common/components/grid';
import { ModalWrapper, modalNames } from './../../common/components/modal-wrapper';
import MainFilterComponent from '../../common/components/filter/MainFilter';
import { PopoverGridTooltip } from '../../common/components/tooltips';
import { categorizeEquipment, LoadMoreOptions } from '../../common/utilities';
import MerchantEquipmentFooter from '../MerchantEquipmentFooter/MerchantEquipmentFooter';
import { Notification } from '../../common/components/notifications';
import { gatewayTemplate, hardwareTemplate, softwareTemplate, subequipmentTemplate } from '../../validation';
import { varTemplate } from '../../validation/equipment.validation';
import {
    collapseSidebar,
    formatColumns,
    getPage,
    hasMoreData,
    mapData,
    resolveColumnName,
    handleEquipmentChange,
    createNewEquipment,
    mapCellArgs,
    onInfoHover,
    handleChange,
    onLoadMoreLimitChange,
} from '../../common/components/grid/commonGridMethods';
import withError from '../../common/components/error/error-hoc';
import { withContext } from '../../common/components';
import { MerchantContext } from '../MerchantDetails';
import { EquipmentRowRenderer } from './details';
import { equipmentDB, equipmentDatabaseKeys as keys } from '../../helpers/indexedDB';
import { parse } from 'query-string';

const schemaList = {
    gateway: new Schema(gatewayTemplate, { strip: false, typecast: true }),
    software: new Schema(softwareTemplate, { strip: false, typecast: true }),
    var: new Schema(varTemplate, { strip: false, typecast: true }),
};

const loadMoreOptionsWithAll = concat(LoadMoreOptions, [0]);

class EquipmentGrid extends Component {
    constructor(props) {
        super(props);
        this.state = cloneDeep(this.initialState);

        this.gridRef = createRef();
        this.notificationRef = createRef();

        this.setState = this.setState.bind(this);
        this.onLoadMoreLimitChange = onLoadMoreLimitChange.bind(this);
    }

    get initialState() {
        const { match } = this.props;
        const filters = unionBy(this.props.location.filters, Filter, 'key') || Filter;
        filters.push({ key: 'appId', values: { appId: this.props.match.params.appid }, clearable: false });

        const queryParams = parse(window.location.search);
        let openAddonEqpId = queryParams["_id"];

        return {
            appId: get(match, 'params.appid', ''),
            data: null,
            filteredRows: [],
            fullFilteredRows: [],
            filters: filters,
            activeFilters: cloneDeep(filters),
            inlineFilters: {},
            expanded: {},
            maxGridHeight: 0,
            columns: Columns,
            defaultColumns: cloneDeep(Columns),
            fetchingData: false,
            fetchingAdditionalData: false,
            showFilters: true,
            lastApiRefNum: null,
            activePage: 1,
            rowsPerPage: 20,
            totalRowCount: 0,
            modal: {
                name: modalNames.none,
                data: null,
            },
            isValid: true,
            appStatus: {
                isReadyToSubmit: false,
                isMpaComplete: false,
                isSetupFormComplete: false,
                isEquipmentComplete: false,
            },
            dirty: false,
            openAddonEqpId
        };
    }

    getSnapshotBeforeUpdate = (prevProps) =>
    prevProps.location.key !== this.props.location.key && !this.props.location.disableRefresh;

    componentDidUpdate(_, prevState, snapshot) {
        let dirty = false;

        if (snapshot) {
            this.setState(this.initialState, () => {
                this.gridRef.current.reset();
            });
        } else if (get(this.props, 'location.disableRefresh')) {
            this.checkIfShouldOpenAddonsModal();
        }

        if (!!this.state.merchantEquipment) {
            const merchEquipment = this.state.merchantEquipment.filter(e => e.isSelected);

            if (merchEquipment && merchEquipment.length > 0) {
                dirty = true;
            }
        }

        if (this.state.dirty !== dirty) {
            this.setState({ dirty });
        }

        if (!isEqual(this.state.merchantEquipment, prevState.merchantEquipment)) {
            this.validateEquipment(this.state.merchantEquipment, this.state.gatewayList, this.state.hardwareList, this.state.softwareList, this.state.varList);
            this.setErrorState();
        }
    }

    componentWillUnmount() {
        collapseSidebar(this.state.wasSidebarExpanded);
    }

    componentDidMount() {
        const sidebarExpanded = JSON.parse(sessionStorage.getItem('sidebarExpanded'));
        if (sidebarExpanded !== null) {
            sessionStorage.setItem('collapseSidebar', true);
            this.setState({ wasSidebarExpanded: sidebarExpanded !== null ? sidebarExpanded : true });
        }
    }

    fetchEquipmentList = async () => {
        const equipmentList = await equipmentDB.getEquipment(keys.equipmentList, this.state.appId);

        return new Promise(resolve => {
            if (!equipmentList) {
                this.loadEquipmentFromService(resolve);
            } else {
                this.equipmentList = equipmentList;
                this.mapEquipmentList(equipmentList).then(mappedEquipmentList =>{
                    this.setState({ ...mappedEquipmentList }, resolve);                
                })       
            }
        });
    };

    getSubEquipment = async equipmentId => {
        const equipmentList = await equipmentDB.getEquipment(keys.equipmentList, this.state.appId);
        let gatewayList = null;
        if (equipmentList) {
            gatewayList = equipmentList.filter(e => toLower(e.category) === 'gateway');
        }

        return get(find(gatewayList, { equipmentId: String(equipmentId) }), 'subequipment', null);
    };
    getFreeTransactions = fees => {
        const fee = fees.filter((fee) => fee.feeType == "TransactionFee")
        const amount = fee.length > 0 ? fee[0].freeTransactions : 0 
        return amount
    };


    mapMerchantEquipmentToState = (equipmentList, fromLocalStorage = false) => {
        let mappedEquipmentList = equipmentList;

        if (!fromLocalStorage) {
            const createEquipment = createNewEquipment({ ...this.state, merchantEquipment: [] }, this.findEquipment);
            mappedEquipmentList = compact(map(equipmentList, ({ equipmentId, id, isSelected = false }) => {
                const item = createEquipment(equipmentId, isSelected);

                if (!isEmpty(item)) {
                    item.id = id;
                    return item;
                }
            }));
        }

        this.setState({ merchantEquipment: mappedEquipmentList });
    };

    mapMerchantGatewaysToLocalStorage = () => {
        const { merchantEquipment } = this.state;
        const newGatewayList = filter(merchantEquipment, ({ category }) => toLower(category) === 'gateway');
        const eqpWithoutErrors = map(newGatewayList, ({ errors, ...keepAttrs }) => keepAttrs);
        equipmentDB.setEquipment(keys.merchantEquipment, { appId: this.state.appId, equipment: eqpWithoutErrors});
    };

    handlePageChange = (pageNumber) => {
        const onChange = handleChange((e, d = noop) => this.setState(e, d), this.state);
        onChange([{ key: 'activePage', value: pageNumber }]);
    };

    fetchData = async (filters) => {
        const filterData = compileFilter(filters);

        this.setState({
            fetchingData: true,
            data: null,
            filteredRows: [],
            fullFilteredRows: [],
            expanded: {},
            lastApiRefNum: null,
            totalRowCount: 0,
        });

        let lastApiRefNum = null;
        const { rowsPerPage, activePage, appId } = this.state;


        const [data] = await Promise.all([appService.getMerchantEquipment(filterData.appId), this.fetchEquipmentList(), this.loadAppStatus()]); //(filter);
        const merchEquipment = await equipmentDB.getEquipment(keys.merchantEquipment, appId)
        this.mapMerchantEquipmentToState(!isEmpty(merchEquipment) ? merchEquipment : get(data, 'equipmentList', []), !isEmpty(merchEquipment));

        if (this.gridRef.current)
        {
            lastApiRefNum = data.refNum;
            let dataObj = { xReportData: data.equipmentList };

            if (dataObj && dataObj.xReportData) {
                dataObj.xReportData = await Promise.all(map(dataObj.xReportData, this.mapRow));
            }

            const formattedColumns = formatColumns(
                this.state.columns,
                cloneDeep(filterData)
            );
            mapData(dataObj, (e, d = noop) => this.setState(e, d), this.gridRef);
            const filteredRows = data.tickets
                ? Data.Selectors.getRows({
                    rows: dataObj.xReportData,
                    filters: this.state.inlineFilters,
                })
                : [];

            const pagedFilteredRows = getPage(
                filteredRows,
                activePage,
                rowsPerPage
            );

            this.gridRef.current.scrollTo({ top: 0, left: 0 });


            this.setState(
                {
                    data: dataObj,
                    fullFilteredRows: filteredRows,
                    filteredRows: pagedFilteredRows,
                    fetchingData: false,
                    columns: formattedColumns,
                    lastApiRefNum: lastApiRefNum,
                    totalRowCount: filteredRows.length
                },
                () => {
                    if (!merchEquipment) {
                        this.mapMerchantGatewaysToLocalStorage();
                    }
                    if (this.gridRef.current) {
                        this.gridRef.current.handleInitialSort();
                    }
                    this.checkIfShouldOpenAddonsModal();
                }
            );
        }
    };

    checkIfShouldOpenAddonsModal = () => {
        const { location: { state }, history } = this.props;

        if (state && state.openAddonsModal) {
            const { data } = this.state;
            const row = find(get(data, 'xReportData', []), ({ id }) => id == state.parentEquipmentId);

            if (!isEmpty(row)) {
                const { id, equipmentId, name, planName } = row;
                this.openAddonsModal(id, equipmentId, name, planName);
                history.replace({ disableRefresh: true, state: null });
            }
        } else if (this.state.openAddonEqpId) {
            const { data } = this.state;
            const row = find(get(data, 'xReportData', []), ({ id }) => id == this.state.openAddonEqpId);
            if (!isEmpty(row) && !isEmpty(row.subequipment)) {
                const { id, equipmentId, name, planName } = row;
                this.openAddonsModal(id, equipmentId, name, planName);
            }
            this.setState({openAddonEqpId: null})
        }
    };

    findEquipment = (equipmentId, isSubequipment, isAccessory) => {
		const { hardwareList, gatewayList, softwareList, varList } = this.state;

		let selectedEqp =
			(hardwareList && hardwareList.find((h, i) => h.equipmentId == equipmentId)) ||
			(gatewayList && gatewayList.find((g, i) => g.equipmentId == equipmentId)) ||
			(softwareList && softwareList.find((g, i) => g.equipmentId == equipmentId)) ||
			(varList && varList.find((g, i) => g.equipmentId == equipmentId));

		if (isSubequipment) {
			each(gatewayList, ({ subequipment, equipmentId: parentEquipmentId }) => {
				const item = find(subequipment, subItem => subItem.equipmentId == equipmentId);
				if (item) {
					selectedEqp = { ...item, parentEquipmentId };
				}
			});
		}

		if (isAccessory) {
			each(hardwareList, ({ accessories }) => {
				const accessory = find(accessories, (e) => e.equipmentId == equipmentId);
				if (accessory) {
					selectedEqp = accessory;
				}
			});
		}

		return selectedEqp;
	};

    closeItemsModalAndValidate = () => {
        if (this.state.isItemsModalOpen && !some(this.state.merchantEquipment, 'isSelected')) {
            this.closeItemsModal();
        }
        this.setErrorState();
    };

    setErrorState = () => {
        const haveErrors = filter(get(this.state, 'merchantEquipment', []), e => e.isSelected && e.errors && e.errors.length > 0).length > 0
        this.setState({ isValid: !haveErrors });
    }

    openAddonsModal = (masterId, parentEquipmentId, parentEquipmentName, parentPlanName) => {
        const { data } = this.state;
        const existingAddons = filter(data.xReportData, item => item.parentEquipmentId == masterId);

        this.openCloseModal({
            name: modalNames.gatewayAddons,
            data: {
                masterId,
                parentEquipmentId,
                parentEquipmentName,
                parentPlanName,
                gatewayList: this.equipmentList,
                merchantEquipment: this.state.merchantEquipment,
                handleChange: handleEquipmentChange(this.findEquipment, (e, d = noop) => this.setState(e, d), this.state, this.closeItemsModalAndValidate),
                closeItemsModalAndValidate: this.closeItemsModalAndValidate,
                existingAddons,
                displayEquipmentFooter: true,
                renderEquipmentFooter: this.renderEquipmentFooter,
            },
        });
    };

    loadAppStatus = () => {
        this.setState({ fetchingAdditionalData: true });
        appService.getEappStatus(get(this.props.match, 'params.appid', ''))
            .then(
                appStatus => {
                    this.setState({ appStatus, fetchingAdditionalData: false });
                }
            ).catch(err => {
                this.setState({ fetchingAdditionalData: false });
                this.handleError(err);
            });
    };

    addNotification = notification => {
        const addNotification = get(this.notificationRef, 'current.addNotification', noop);

        return addNotification(notification);
    };

    handleError = err => {
        this.setState({ fetchingAdditionalData: false, fetchingData: false });
        this.props.handleError(err);
    };

    validateEquipment = (merchantEquipment, gatewayList, hardwareList, softwareList, varList) => {
        if (!isEmpty(merchantEquipment)) {
            let selectedEquipment = merchantEquipment.filter((e, i) => e.isSelected);
            let hardwareSchema = new Schema(hardwareTemplate, { strip: false, typecast: true });
            let subequipmentSchema = new Schema(subequipmentTemplate, { strip: false, typecast: true });

            each(selectedEquipment, (e) => {
                let item = this.findEquipment(e.equipmentId);

                if (e.parentEquipmentId) {
                    each(gatewayList, ({ subequipment }) => {
                        const sub = find(subequipment, subItem => subItem.equipmentId == e.equipmentId);
                        if (sub) {
                            item = { ...sub };
                        }
                    });
                }

                const schema = e.parentEquipmentId ? subequipmentSchema : (schemaList[toLower(e.category)] || hardwareSchema);

                e.errors = schema.validate(Object.assign({}, e,
                    {
                        settingsSource: Object.values(get(item, 'equipmentOptions', {})),
                        equipment: item,
                    }));

            });
        }
    }

    renderEquipmentFooter = () => {
        const {
            data,
            hardwareList,
            gatewayList,
            softwareList,
            varList,
            isValid,
            appStatus,
            dirty,
            appId,
            merchantEquipment,
            fetchingData,
            fetchingAdditionalData
        } = this.state;

        return (
            <MerchantEquipmentFooter
                appId={appId}
                merchantEquipment={merchantEquipment}
                existingMerchantEquipment={data.xReportData}
                equipmentList={this.equipmentList}
                hardwareList={hardwareList}
                gatewayList={gatewayList}
                softwareList={softwareList}
                varList={varList}
                isValid={isValid}
                appStatus={appStatus}
                isEApp={false}
                dirty={dirty}
                isLoading={fetchingData || fetchingAdditionalData}
                setErrorState={this.setErrorState}
                handleEquipmentChange={handleEquipmentChange(this.findEquipment, (e, d = noop) => this.setState(e, d), this.state, this.closeItemsModalAndValidate)}
                createNewEquipment={createNewEquipment(this.state, this.findEquipment)}
                updateState={this.updateState}
                handleSave={this.handleSave}
                findEquipment={this.findEquipment}
                isPopup={true}
            />
        );
    };

    updateState = async (newState, callback = noop) => {
        return new Promise(resolve => {
            this.setState(newState, () => {
                callback();
                resolve();
            });
        });
    };

    handleSave = e => {
        e.preventDefault();
        this.saveAndGoNext();
    }

    saveAndGoNext = () => {
        this.setState({ fetchingAdditionalData: true });
        const eqp = this.mapSelectedMerchantEquipment(filter(cloneDeep(this.state.merchantEquipment), ({ category }) => toLower(category) !== 'var'));
        const merchantVar = first(map(
            this.mapSelectedMerchantEquipment(
                filter(this.state.merchantEquipment, ({ category }) => toLower(category) === 'var')),
        ({ category, equipmentOptions, notes, equipmentId, attachment, purchaseType }) => ({ category, equipmentOptions, notes, equipmentId, attachment, purchaseType })));

        if (this.state.dirty) {
            if (!isEmpty(eqp)) {
                const data = [this.state.appId, eqp];
                appService.saveMerchantEquipment(...data)
                    .then(() => {
                        this.postSave();
                    }).catch(err => { this.handleError(err); });
            }
            if (!isEmpty(merchantVar)) {
                const data = [this.state.appId, merchantVar];
                appService.saveMerchantVar(...data)
                    .then(() => {
                        this.postSave();
                    }).catch(err => { this.handleError(err); });
            }
        }
    }

    postSave = () => {
        this.clearState(true);
        this.openCloseModal({ name: modalNames.none, data: null });
        this.setState({ fetchingAdditionalData: false });
        this.addNotification({
            message: 'Equipment saved successfully',
            success: true,
            onClose: get(this.gridRef, 'current.refreshGridData', noop),
        });
    }

    clearState(cartOnly = false) {
        if (!cartOnly) {
            equipmentDB.deleteEquipment(keys.equipmentList, this.state.appId);
        }
        equipmentDB.deleteEquipment(keys.merchantEquipment, this.state.appId);
    }

    mapSelectedMerchantEquipment = (eqp) => {
        eqp = eqp.filter((e, i) => e.isSelected);
        each(eqp, (e) => {
            if (e.accessories) {
                e.accessories = e.accessories.filter((a, i) => a.isSelected);
                each(e.accessories, a => {
                    a.shippingOption = eqp.shippingOption;
                    a.shippingSpeed = eqp.shippingSpeed;
                    a.shippingAddress = eqp.shippingAddress;
                });
            }
            if (!!e.subequipment) {
                each(e.subequipment, s => s.paymentSchedule = e.paymentSchedule);
            }
            if (e.additionalFees) {
                e.fees = [...e.fees, ...filter(e.additionalFees, x => x.isSelected)];
                delete e.additionalFees;
            }
        });
        return eqp;
    };

    mapRow = async (row) => {
        const subequipment = await this.getSubEquipment(row.equipmentId);
        return {
            ...row,
            isExpandable: true,
            refreshGridData: get(this.gridRef, 'current.refreshGridData', noop),
            onInfoHover: onInfoHover((e, d = noop) => this.setState(e, d), this.gridRef),
            openAddonsModal: this.openAddonsModal,
            subequipment: subequipment,
            freeTransactionAmount: this.getFreeTransactions(row.fees)
        }
    }


    mapEquipmentList = async equipment => {
        const varList = await equipmentDB.getEquipment(keys.varList, this.state.appId)
        return { ...categorizeEquipment(equipment), varList };
    }

    loadEquipmentFromService = (resolvePromise) => {
        const { appId } = this.state;

        appService.getEquipmentList(appId)
            .then(equipment => {
                this.mapEquipmentList(get(equipment, 'equipmentList')).then(mappedEquipmentList =>{
                    this.setState({ ...mappedEquipmentList }, () => {
                        equipmentDB.setEquipment(keys.equipmentList, { appId, equipment: equipment.equipmentList });
                        this.equipmentList = equipment.equipmentList;
                        resolvePromise();
                    });
                })
            })
            .catch(err => {
                this.handleError(err);
            });
    };

    onRowClick = (...params) => {
        if (params[0] < 0 || params[2].key === 'hidden' || params[2].key === 'dba') {
            return;
        }
        this.openCloseModal({
            name: modalNames.equipmentDetails,
            data: {
                row: params[1],
            },
        });
    };

    calculatePopupLeft = () => {
        return this.state.infoDimensions.width - 36;
    };

    calculatePopupTop = () => {
        let offset = 0;
        if (
            this.gridRef.current &&
            this.gridRef.current.gridHolderRef.current
        ) {
            offset =
                this.gridRef.current.gridHolderRef.current.getBoundingClientRect()
                    .y -
                this.gridRef.current.gridHolderRef.current.scrollTop -
                25;
        }
        return this.state.infoDimensions.height - offset;
    };

    renderTooltip = () =>
        this.state.tooltip ? (
            <div
                style={{
                    left: this.calculatePopupLeft(),
                    top: this.calculatePopupTop(),
                    position: 'absolute',
                    backgroundColor: '#fff',
                    zIndex: 99,
                }}
            >
                {this.state.tooltip}
            </div>
        ) : null;

    refetchData = () => {
        this.fetchData(this.state.activeFilters);
    };

    openCloseModal = (modalObj, ...rest) => {
        let state = {
            modal: modalObj,
        };
        this.setState(state);
    };

    renderTitle = ({ title, className }) => {
        const { merchant } = this.props;

        return (
            <div className={className}>
                {merchant && merchant.dba && merchant.appId && (
                    <div className="type--sml--plus type--uppercase type--color--text spc--bottom--sml">
                        {`${merchant.dba} - ${merchant.appId}`}
                    </div>
                )}
                {title}
            </div>
        );
    };

    renderHeader = ({ refreshGridData }) => (
        <div className=""></div>
    );

    renderGridHeader = ({ refreshGridData }) => (
        <div className=""></div>
    );

    render = () => {
        const {
            modal,
            fetchingAdditionalData,
            fetchingData,
            filteredRows,
            fullFilteredRows,
            columns,
            data,
            inlineFilters,
            expanded,
            filters,
            activeFilters,
            lastApiRefNum,
            defaultColumns,
            activePage,
            rowsPerPage,
            totalRowCount,
            tooltipProps,
        } = this.state;

        return (
            <Fragment>
                <div className="l--content--grid">
                <Notification ref={this.notificationRef} />
                <ModalWrapper
                    modal={modal}
                    onModalClose={this.openCloseModal}
                />
                <GridComponent
                    emptyMessage="No equipment found for this merchant"
                    fetchingData={fetchingData}
                    fetchingAdditionalData={fetchingAdditionalData}
                    filteredRows={filteredRows}
                    fullFilteredRows={fullFilteredRows}
                    loadMoreOptions={loadMoreOptionsWithAll}
                    onLoadMoreLimitChange={this.onLoadMoreLimitChange}
                    loadMoreLimit={rowsPerPage}
                    columns={columns}
                    fixHeader={true}
                        data={data}
                        resolveColumnName={resolveColumnName}
                    inlineFilters={inlineFilters}
                    components={{
                        title: this.renderTitle,
                        toolbar: ToolbarComponent,
                        header: this.renderHeader,
                        gridHeader: this.renderGridHeader,
                        //modal: ActionsModal,
                        filter: MainFilterComponent,
                        rowRenderer: EquipmentRowRenderer,
                        //gridBody: this.renderScheduleDetails,
                        tooltip: PopoverGridTooltip,
                        }}
                    onChange={handleChange((e, d = noop) => this.setState(e, d), this.state)}
                    rowKey="id"
                    isExpandable={true}
                    expanded={expanded}
                    title="Equipment"
                    enableExport={true}
                    enablePrint={true}
                    enableFilters={false}
                    type="equipment"
                    filters={filters}
                    activeFilters={activeFilters}
                    showFilters={true}
                    fetchData={this.refetchData}
                    lastApiRefNum={lastApiRefNum}
                    showResults={true}
                    mapCellArgs={mapCellArgs}
                    showExportDropdown={false}
                    showPrintDropdown={false}
                    filterColumns={true}
                    defaultColumns={defaultColumns}
                    onRowClick={this.onRowClick}
                    ref={this.gridRef}
                    useInlineFilters={true}
                    hasPaging={true}
                    hasMoreData={hasMoreData(rowsPerPage, totalRowCount)}
                    handlePageChange={this.handlePageChange}
                    pagination={{ activePage, rowsPerPage, totalRowCount }}
                    tooltipProps={tooltipProps}
                />
                </div>
            </Fragment>
        );
    };
}

EquipmentGrid.propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
};

export default withError(withContext(EquipmentGrid, MerchantContext, 'merchant'));
