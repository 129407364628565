import React, {Component} from 'react';

class PublicLayoutComponent extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <div className="membership">
                    <header className="membership__header">
                        <img src="/static/media/logo-dark.svg" className="membership__logo membership__logo--primary" />
                        {/* <img src="/static/media/logo-negative.svg" className="membership__logo membership__logo--secondary" />         */}
                    </header>
                    {/* <div className="membership--aside"> 
                        <div className="membership--aside__content">
                            <h2 className="membership--aside__title">
                                Secure &amp; Fast integrated payments
                            </h2>
                            <p className="membership--aside__description">
                                Experience payments designed to work seamlessly with your in-store, online and mobile platforms.
                            </p>
                        </div>
                        <div className="membership__companies">
                            <p className="spc--bottom--sml">
                                Trusted by companies
                            </p>
                            <div className="group">
                                <div className="membership__companies__item">
                                    <img src="/static/media/er.png" />
                                </div>
                                <div className="membership__companies__item">
                                    <img src="/static/media/gy.png" />
                                </div>
                                <div className="membership__companies__item">
                                    <img src="/static/media/je.png" />
                                </div>
                                <div className="membership__companies__item">
                                    <img src="/static/media/ml.png" />
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="membership--main">
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}

export default PublicLayoutComponent;
