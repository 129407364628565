import { email, phoneNumber } from './validators';

export const merchantDetailsTemplate = {
    phoneNumber: {
        type: String,
        use: { phoneNumber },
        message: '[**Phone Number**](javascript:void) is invalid'
    },
    businessEmail: {
        type: String,
        use: { email: email(true) },
        message: {
            email: '[**Email**](javascript:void) is invalid'
        }
    },
    alternateEmail: {
        type: String,
        use: { email: email(false) },
        message:{
            email: '[**Additional Email**](javascript:void) is invalid'
        }
    }
}


