import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { trim, toLower } from 'lodash';
import { withContext, withLoader } from './../../common/components';
import { appService } from '../../services/appService';
import principalService from '../../services/principalService';
import PaymentPointsUpdateContext from '../../common/components/user-account-panel/PaymentPointsUpdateContext';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';

const { REACT_APP_AGENT_SUPPORT_EMAIL } = process.env;

class Dropin extends React.Component {
	state = {
		principal: null,
		dropinUser: '',
		errorMessage: null,
		showDropinInput: false,
	};

	componentDidMount() {
		const data = principalService.get();

		this.setState({ principal: data });
	}

	handleChange = e => {
		const { name, value } = e.target;
		this.setState({ [name]: value });
	};

	reloadPageNewUser = () => {
		this.props.showLoader(false);
		this.props.getPoints();
		this.redirect();
	};

	handleSubmit = e => {
		e.preventDefault();
		let { dropinUser } = this.state;
		dropinUser = trim(toLower(dropinUser));

		if (!dropinUser) {
			this.setState({ errorMessage: 'Please enter username to impersonate' });
			return;
		}

		this.setState({ errorMessage: '' });
		this.props.showLoader(true);

		appService
			.dropin(dropinUser)
			.then(this.reloadPageNewUser)
			.catch(this.handleError);
	};

	handleCancelDropin = () => {
		this.setState({ errorMessage: '' });
		this.props.showLoader(true);

		appService
			.cancelDropin()
			.then(this.reloadPageNewUser)
			.catch(this.handleError);
	};

	handleError = err => {
		console.log('Error', err);
		let message = 'Something went wrong. Please try again.';
		if (err?.ex?.response?.status === 401 || err?.ex?.response?.status === 403) {
			message = 'You are not authorized to access the page. Contact customer support: cs@cardknox.com.';
		} else if (err?.startsWith?.('User not found')) {
			message = `Email address is not registered for the Partner Portal. For assistance, reach out to ${REACT_APP_AGENT_SUPPORT_EMAIL}.`;
		}
		this.setState({ errorMessage: message });
		this.props.showLoader(false);
	};

	redirect = () => {
		const { history, location } = this.props;
		let redirectUrl = '/';

		this.props.showLoader(false);

		if (location.state?.returnUrl) {
			redirectUrl = location.state.returnUrl;
		}

		history.push(redirectUrl);
	};

	toggleDropinInput = () => {
		this.setState(prevState => ({
			dropinUser: '',
			errorMessage: '',
			showDropinInput: !prevState.showDropinInput,
		}));
	};

	render() {
		const { dropinUser, errorMessage, principal, showDropinInput } = this.state;
		const { isLoading } = this.props;

		if (!principal?.isAdmin && !principal?.isDropinUser) return null;
		return (
			<Fragment>
				{principal?.isDropinUser && (
					<div className="input--box is-selected">
						{!errorMessage ? (
							<i className="icon icon--med icon--drop-in" />
						) : (
							<i
								className="icon icon--med icon--regular--warning datatooltip--auto datatooltip--down"
								data-tooltip={errorMessage}
							></i>
						)}
						<div className="flex--grow--1">
							<p className="type--p3 type--color--text--light">{principal.name}</p>
						</div>
						<button className="btn btn--link" onClick={this.handleCancelDropin}>
							<i className="icon icon--sml icon--close" />
						</button>
					</div>
				)}
				{!principal?.isDropinUser && (
					<form onSubmit={this.handleSubmit}>
						{!showDropinInput ? (
							<button
								type="button"
								className="input--box datatooltip--down-right"
								tabIndex="0"
								data-tooltip="Impersonate another agent"
								onClick={this.toggleDropinInput}
							>
								<i className="icon icon--med icon--drop-in" />
							</button>
						) : (
							<div className="input--box is-active">
								{!errorMessage ? (
									<button
										className="btn btn--link datatooltip--auto datatooltip--down"
										onClick={this.toggleDropinInput}
										data-tooltip="Close"
									>
										<i className="icon icon--sml icon--close" />
									</button>
								) : (
									<i
										className="icon icon--med icon--regular--warning datatooltip--auto datatooltip--down"
										data-tooltip={errorMessage}
									></i>
								)}
								<input
									name="dropinUser"
									type="text"
									className="input input--box__input"
									placeholder="Enter username"
									value={dropinUser}
									onChange={this.handleChange}
									autoFocus
									tabIndex="0"
								/>
								<button type="submit" className="btn btn--sml btn--primary" disabled={isLoading}>
									Impersonate
								</button>
							</div>
						)}
					</form>
				)}
			</Fragment>
		);
	}
}

Dropin.propTypes = {
	showLoader: PropTypes.func.isRequired,
	getPoints: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	isLoading: PropTypes.bool,
};

export default withRouter(withLoader(withContext(Dropin, PaymentPointsUpdateContext, 'getPoints')));
