import React from 'react';
import { noop } from 'lodash';

import USSetupConfirm from './USSetupConfirm';
import AmexRates from './amex-rates';

export default class CanadaSetupConfirm extends USSetupConfirm {
    renderPinDebit() {
        return (
            <div className="col col-sml-12 spc--bottom--sml">
                <label className="label">Setup Merchant With Pin Debit</label>
                <div className="flex flex--primary spc--bottom--sml">
                    {this.renderSetupPinDebitRadio(true, 'spc--right--med')}
                    {this.renderSetupPinDebitRadio(false)}
                </div>
                {this.renderPinDebitStructure()}
            </div>
        );
    }

    renderAmexRates(setup) {
        return (
            <div className="row">
                <AmexRates tooltip="Amex uses the same rate structure as Visa/MC" amexDetails={setup.amexDetails} renderRateStructure={this.renderRateStructure}/>
            </div>
        );
    }

    renderSetupPinDebitRadio(isTrue, className) {
        const { setup } = this.props;
        const id = `pricingStructure_shouldSetupPinDebit_${isTrue ? 'yes' : 'no'}`;
        return (
            <div className={className}>
                <input
                    disabled
                    className="input--radio"
                    type="radio"
                    id={id}
                    name={id}
                    checked={setup.pricingStructure.shouldSetupPinDebit === isTrue}
                    onChange={noop}
                />
                <label className="label" htmlFor={id}>{isTrue ? 'Yes' : 'No'}</label>
            </div>
        );
    }

    renderOnlineReportingSection() {
        return null;
    }

    renderPinDebitStructure() {
        const { setup } = this.props;
        if (!setup.pricingStructure.shouldSetupPinDebit) return null;
        return (
            <div className="row spc--top--sml grid-align-middle spc--bottom--med">
                {this.renderPinDebitInput('Interac Trans Fee', setup.pricingStructure.pinDebitTransactionFee)}
                {this.renderPinDebitInput('Interac Flash Trans Fee/Contactless', setup.pricingStructure.pinDebitInteracFlashTransFee)}
            </div>
        );
    }

    renderEidsEnrollment() {
        return null;
    }

    renderTransactionInformation() {
        return this.renderFakeInput({ name: 'Debit Refund Cap', value: this.props.setup.pricingStructure.debitRefundCap });
    }

    renderRetrievalCharge() {
        return null;
    }

    renderPciFee(setup) {
        return (
            <React.Fragment>
                <div className="col col-sml-12 col-lrg-6">
                    <div className="form__field spc--bottom--med">
                        <label className="label">
                            PCI Monthly Fee
                        </label>
                        <div className="input input--med input--fake input--currency input--currency--sml">
                            {setup.proposedFees
                                .pciMonthlyFee}
                        </div>
                    </div>
                </div>
                <div className="col col-sml-12 col-lrg-6">
                    <div className="form__field spc--bottom--med">
                        <label className="label">
                            Application Fee
                        </label>
                        <div className="input input--med input--fake input--currency input--currency--sml">
                            {setup.proposedFees
                                .applicationFee}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    shouldHideNonQual = name => name.indexOf('amex') !== 0;

    renderTransactionVolume() {
        const {
            setup: {
                processingDetails: {
                    averageTransactionAmount,
                    maxTransactionAmount,
                    yearlyVmcVolume,
                },
            },
        } = this.props;
        return (
            <React.Fragment>
                <div className="card--primary__subheader">
                    <strong>V/MC/AX Transaction Information:</strong>
                </div>
                <div className="row row-align-middle">
                    {this.renderVMField(yearlyVmcVolume, 'V/MC/AX Average Annual Volume')}
                    {this.renderVMField(averageTransactionAmount, 'V/MC/AX Average transaction dollar amount')}
                    {this.renderVMField(maxTransactionAmount, 'V/MC/AX highest transaction dollar amount')}
                </div>
            </React.Fragment>
        );
    }
}