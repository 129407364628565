export const modalNames = {
    none: null,
    newNote: 'nn',    
    confirmAction: 'ca',
    newLead: 'nl',
    reviewPricing: 'rp',
    pricingResults: 'pr',
    shareMPA: 'sm',
    fileUpload: 'fu',
    gatewayNote: 'gn',
    gatewayAddons: 'ga',
    newTicket: 'nt',
    equipmentDetails: 'ed',
    ticketDetails: 'td',
    sendEmail: 'se',
    newFile: 'nf',
    goPlusTier: 'gpt',
    groupedTotals: 'gt',
    invoiceEmail: 'ie',
    cloneEApp: 'ce',
};