import { each, some } from 'lodash';

import {
    FirstNameSelectionComponent,
    DBASelectionComponent,
    CorporateNameSelectionComponent,
    GeneralFilterComponent,
    LastNameSelectionComponent,
    LeadStatusFilterComponent,
    LeadStatusSelectionComponent,
    ReviewPricingStatusFilterComponent,
    ReviewPricingStatusSelectionComponent,
    LabelFilterComponent,
    LabelSelectionComponent,
    PhoneNumberFilterComponent,
    PhoneNumberSelectionComponent,
    EmailSelectionComponent,
    BusinessCountrySelectionComponent
} from '../../../common/filters';
import { getFilterValues, createFilters } from '../../../common/utilities';

export const leadsFilter = createFilters([
    {
        key: 'dba',
        name: 'DBA',
        component: GeneralFilterComponent,
        selectionComponent: DBASelectionComponent,
        values: {
            dba: null,
            fieldName: 'dba'
        },
    },
    {
        key: 'corporateName',
        name: 'Corporate Name',
        component: GeneralFilterComponent,
        selectionComponent: CorporateNameSelectionComponent,
        values: {
            corporateName: null,
            fieldName: 'corporateName'
        },
    },
    {
        key: 'leadStatus',
        name: 'Status',
        component: LeadStatusFilterComponent,
        selectionComponent: LeadStatusSelectionComponent,
        values: {
            New: false,
            Qualified: false,
            Contacted: false,
            'Pricing Analysis': false,
            'Waiting For Info': false,
            'Follow Up Later': false,
            Dead: false,
            //Unknown: false
        },
    },
    {
        key: 'reviewStatus',
        name: 'Review Status',
        component: ReviewPricingStatusFilterComponent,
        selectionComponent: ReviewPricingStatusSelectionComponent,
        values: {
            Complete: false,
            Incomplete: false,
            Pending: false,
            Waiting: false,
            NoReview: false,
            NotSubmitted: false
        },
    },
    {
        key: 'firstName',
        name: 'First Name',
        component: GeneralFilterComponent,
        selectionComponent: FirstNameSelectionComponent,
        values: {
            firstName: null,
            fieldName: 'firstName'
        },
    },
    {
        key: 'lastName',
        name: 'Last Name',
        component: GeneralFilterComponent,
        selectionComponent: LastNameSelectionComponent,
        values: {
            lastName: null,
            fieldName: 'lastName'
        },
    },    
    {
        key: 'phoneNumber',
        name: 'Phone Number',
        component: PhoneNumberFilterComponent,
        selectionComponent: PhoneNumberSelectionComponent,
        values: {
            phoneNumber: null,
        },
    },
    {
        key: 'email',
        name: 'Email',
        component: GeneralFilterComponent,
        selectionComponent: EmailSelectionComponent,
        values: {
            email: null,
            fieldName: 'email'
        },
    },
    {
        key: 'label',
        name: 'Label',
        component: LabelFilterComponent,
        selectionComponent: LabelSelectionComponent,
        values: {
            label: null,
        },
    },
    {
        key: 'businessCountry',
        name: 'Business Country',
        component: GeneralFilterComponent,
        selectionComponent: BusinessCountrySelectionComponent,
        values: {
            businessCountry: null,
            fieldName: 'businessCountry'
        },
    },
]);

export const compileFilter = filters => {
    const {
        dba,
        corporateName,
        leadStatus,
        reviewStatus,
        firstName,
        lastName,
        email,
        phoneNumber,
        label,
        businessCountry,
    } = getFilterValues(filters);

    const filter = {};

    if (dba.dba != null) {
        filter.DBA = dba.dba;
    }
    if (corporateName.corporateName != null) {
        filter.CorporateName = corporateName.corporateName;
    }
    if (some(leadStatus, p => p)) {
        filter.StatusList = parseStatusFilter(leadStatus);
    }

    if (some(reviewStatus, p => p)) {
        filter.ReviewStatusList = parseStatusFilter(reviewStatus);
    }

    if (firstName.firstName != null) {
        filter.FirstName = firstName.firstName;
    }

    if (lastName.lastName != null) {
        filter.LastName = lastName.lastName;
    }

    if (email.email != null) {
        filter.Email = email.email;
    }

    if (phoneNumber.phoneNumber != null) {
        filter.PhoneNumber = phoneNumber.phoneNumber;
    }

    if (label.label != null) {
        filter.LabelFilter = label.label;
    }
    if (businessCountry.businessCountry != null) {
        filter.businessCountry = businessCountry.businessCountry;
    }

    return filter;
};

const parseStatusFilter = (values) => {
    const statusFilterArr = [];

    each(values, (value, stat) => {
        if (value) {
            statusFilterArr.push(stat);
        }
    });

    return statusFilterArr.join(',');
};