import React, { Component } from 'react';
import { startCase, map, isEmpty, includes, every, find } from 'lodash';
import PropTypes from 'prop-types';


export class HardwareSidebarComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeKeys: [],
            categories: {
                all: '',
                terminals: 'terminal',
                pinPads: 'pinpad',
                cardReaders: 'cardreader',
                clover: 'clover',
                checkScanner: 'checkscanner',
            },
        };
    };

    onHover = (activeKeys = []) => () => this.setState({ activeKeys });

    getStyles = (activeKeys, categories, isHover) => {
        let styles = "nav--tertiary__link"

        if (every(activeKeys, key => includes(categories, key)) || (!activeKeys && !categories)) {
            styles += " is-active";
        }
        if(isHover) {
            styles += " nav--tertiary__link--hover";
        }
        return styles;
    }

    renderSubCategories = categoryKey => {
        const { optionsList, activeCategory, activeSubcategory } = this.props;
        const { activeKeys } = this.state;
        const options = optionsList[categoryKey];

        return !isEmpty(options) && (
            <ul className="nav nav--tertiary__level-1">
                {map(options, (brands, subcategoryKey) => (
                    <li
                        key={subcategoryKey} className="nav--tertiary__level-1__item"
                        onMouseEnter={this.onHover([categoryKey, subcategoryKey])}
                        onMouseLeave={this.onHover([categoryKey])}
                    >
                        <a
                            className={this.getStyles([activeCategory, activeSubcategory], [categoryKey, subcategoryKey])}
                            href="javascript:void(0);"
                            onClick={this.props.onClick({
                                category: categoryKey,
                                subcategory: subcategoryKey,
                            })}
                        >
                            {startCase(subcategoryKey)}
                        </a>
                        <i className="icon icon--nano icon--arrow--right--grey nav--tertiary__arrow"></i>
                        {includes(activeKeys, subcategoryKey) && this.renderBrands(categoryKey, subcategoryKey, brands)}
                    </li>
                ))}
            </ul>
        );
    };

    renderBrands = (categoryKey, subcategoryKey, brands) => !isEmpty(brands) && (
        <ul className="nav nav--tertiary__level-2">
            {map(brands, brand => (
                <li key={brand} className="nav--tertiary__level-2__item">
                    <a
                        className={this.getStyles([this.props.activeBrand], [brand])}
                        href="javascript:void(0);"
                        onClick={this.props.onClick({
                            category: categoryKey,
                            subcategory: subcategoryKey,
                            brand,
                        })}
                    >
                        {startCase(brand)}
                    </a>
                </li>
            ))}
        </ul>
    );

    render = () => {
        const { activeCategory, optionsList, applicableCategories } = this.props;
        const { categories, activeKeys } = this.state;
        return (
            <nav>
                <ul className="nav nav--tertiary">
                    {map(categories, (value, key) => (
                        applicableCategories.includes(value) && (
                            <li
                                key={key}
                                className="nav--tertiary__item"
                                onMouseEnter={this.onHover([value])}
                                onMouseLeave={this.onHover()}
                            >
                                <a className={`nav--tertiary__level-0 ${this.getStyles([activeCategory], [value], includes(activeKeys, value))}`} href="javascript:void(0);" onClick={this.props.onClick({ category: value })}>
                                    {startCase(key)}
                                </a>
                                {!isEmpty(optionsList[value]) && <i className="icon icon--nano icon--arrow--right--grey nav--tertiary__arrow"></i>}
                                {includes(activeKeys, value) && this.renderSubCategories(value)}
                            </li>
                        )
                    ))}
                </ul>
            </nav>
        );
    };
}


HardwareSidebarComponent.propTypes = {
    onClick: PropTypes.func.isRequired,
    optionsList: PropTypes.object.isRequired,
    activeCategory: PropTypes.string,
    activeSubcategory: PropTypes.string,
    activeBrand: PropTypes.string,
}
