export const exportOptions = {
    quoteStrings: '"',
    showLabels: true,
    useKeysAsHeaders: true
};

export const exportTypes = [
    {
        name: 'CSV',
        key: 'csv'
    }
];


