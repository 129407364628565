import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { modalNames } from './modal-names';
import { Modal } from '../modal';
import { default as ConfirmAction } from './confirmAction';
import { NewNotePopup } from '../../../components/MerchantNotes';
import { NewAccount } from '../../../components/NewAccount';
import { ReviewPricing } from '../../../components/ReviewPricing';
import { SendEmailPopup, ShareMPAForm } from '../../../components/EApp';
import { GatewayAddons } from '../../../components/Equipment';
import { FileUploadPopup } from '../../../components/FileUploadPopup';
import { ReviewSummary  } from '../../../components/ReviewPricingSummary';
import { NotePopup } from '../../../components/EApp';
import { NewTicket, TicketDetails } from '../../../components/TicketGrid/popup/';
import EquipmentDetailsPopup from '../../../components/EquipmentGrid/popup/EquipmentDetailsPopup';
import NewFilePopup from '../../../components/MerchantFiles/NewFilePopup';
import GoPlusTierPopup from '../../../components/EApp/popup/GoPlusTierPopup';
import BreakdownGrid from '../../../components/Residuals/BreakdownGrid';
import SendInvoiceEmailPopup from '../../../components/InvoiceGrid/popup/SendInvoiceEmailPopup';
import CloneAppPopup from '../../../components/CloneAppPopup/CloneAppPopup';

const modalComponents = {    
    [modalNames.newNote]: {
        component: NewNotePopup,
        props: {
            shouldCloseOnOverlayClick: false,
            className: 'popup__content popup__body--med'
        },
    },    
    [modalNames.confirmAction]: {
        component: ConfirmAction,
        props: {
            className: 'popup__content popup__body--med'
    },
    },
    [modalNames.newLead]: {
        component: NewAccount,
        props: {
            className: 'popup__content popup__body--xlrg'
    },
    },
    [modalNames.reviewPricing]: {
        component: ReviewPricing,
        props: {
            className: 'popup__content popup__body--xlrg'
    },
    },
    [modalNames.pricingResults]: {
        component: ReviewSummary, 
        props: {
            className: 'popup__content popup__body--xlrg'
    },
    },
    [modalNames.shareMPA]: {
        component: ShareMPAForm,
        props: {
            className: 'popup__content popup__body--lrg'
        }
    },
    [modalNames.fileUpload]: {
        component: FileUploadPopup,
        props: {}
    },
    [modalNames.gatewayNote]: {
        component: NotePopup,
        props: {
            shouldCloseOnOverlayClick: true,
            className: 'popup__content popup__body--med'
        },
    },

    [modalNames.sendEmail]: {
        component: SendEmailPopup,
        props: {
            shouldCloseOnOverlayClick: false,
            className: 'popup__content popup__body--med'
        },
    },

    [modalNames.gatewayAddons]: {
        component: GatewayAddons,
        props: {
            shouldCloseOnOverlayClick: false,
            className: 'popup__content popup__body--xxlrg'
        },
    },

    [modalNames.newTicket]: {
        component: NewTicket,
        props: {
            shouldCloseOnOverlayClick: false,
            className: 'popup__content popup__body--med'
        },
    },

    [modalNames.ticketDetails]: {
        component: TicketDetails,
        props: {
            className: 'popup__content'
        },
    },

    [modalNames.equipmentDetails]: {
        component: EquipmentDetailsPopup,
        props: {
            className: 'popup__content popup__body--med'
        },
    },

    [modalNames.newFile]: {
        component: NewFilePopup,
        props: {
            className: 'popup__content popup__body--med'
        },
    },

    [modalNames.goPlusTier]: {
        component: GoPlusTierPopup,
        props: {
            className: 'popup__content popup__body--med',
            shouldCloseOnOverlayClick: false,
            hideCloseButton: true,
        },
    },

    [modalNames.groupedTotals]: {
        component: BreakdownGrid,
        props: {
            className: 'popup__content popup__content--breakdown',
        },
    },
    
    [modalNames.invoiceEmail]: {
        component: SendInvoiceEmailPopup,
        props: {
            className: 'popup__content popup__content--med',
        },
    },
    
    [modalNames.cloneEApp]: {
        component: CloneAppPopup,
        props: {
            className: 'popup__content popup__content--med',
        },
    },
};

class ModalWrapperComponent extends Component {

    onModalClose = () => {
        this.props.onModalClose({
            name: modalNames.none,
            data: null,
        });
    };

    renderSpecificModal = () => {
        const { name, data } = this.props.modal;

        const modal = modalComponents[name];
        if (!modal) {
            return null;
        }
        
        return (
            <Modal isOpen={true} onClose={this.onModalClose} {...modal.props}>
                <modal.component closeModal={this.onModalClose} {...data} />
            </Modal>
        );
    };

    render = () => {
        return (
            <div>
                {this.renderSpecificModal()}
            </div>
        );
    };
}

ModalWrapperComponent.propTypes = {
    modal: PropTypes.shape({
        name: PropTypes.string,
        data: PropTypes.object
    }),
    onModalClose: PropTypes.func.isRequired
};

export default ModalWrapperComponent;