import React, { Component } from 'react';
import Menu, { SubMenu, Item as MenuItem } from 'rc-menu';
import PropTypes from 'prop-types';
import moment from 'moment';
import { find, startsWith, get } from 'lodash';

import DateFilter from './date-filter';

class DatePickerPredefinedComponent extends Component {
    constructor(props) {
        super(props);

        this.setDaySelection = this.setDaySelection.bind(this);
    }

    setDaySelection(selectionText) {
        const predefinedDate = find(this.props.predefinedDates, date => date.displayValue === selectionText);
        const days = parseInt(predefinedDate.value, 10);
        let startDate = moment().startOf('day').add(days, 'days');
        let endDate = moment().endOf('day');
        if (!predefinedDate.isRange) {
            endDate.add(days, 'days');
        }
        if (predefinedDate.customEndValue) {
            endDate.add(predefinedDate.customEndValue, 'days');
        }

        this.props.onActiveFilterChanged({
            id:'date',
            values: [
                {key: 'key', value: days},
                {key: 'startDate', value: startDate},
                {key: 'endDate', value: endDate}
            ],
            emptyValue: moment().startOf('day'),
            selectionText: selectionText
        })
    }

    render() {
        const { filter, maxDaysRange, predefinedDates, displayTime, activeKeys, onOpenChange, onApplyFilter } = this.props;
        const customTitle = startsWith(filter.selectionText, 'Custom') ? filter.selectionText : 'Custom';
        const title = get(find(predefinedDates, ({ value }) => Math.abs(value) === Math.abs(filter.values.key)), 'displayValue') || 'Custom';

        return (
            <Menu disabledOverflow={true} mode={'horizontal'} motion={'slide-up'} triggerSubMenuAction={'click'} openKeys={activeKeys} onOpenChange={onOpenChange} selectedKeys={[filter.values.key.toString()]} onClick={item => this.setDaySelection(item.key)}>
                <SubMenu title={title} key='date'>
                    {predefinedDates.map(date => (
                        <MenuItem key={date.displayValue}>{date.displayValue}</MenuItem>
                    ))}
                    <SubMenu title={customTitle} mode={'vertical-right'} key={'custom'}>
                        <MenuItem key="custom.menuItem" disabled>
                            <DateFilter displayTime={displayTime} filter={filter} maxDaysRange={maxDaysRange} from={filter.values.startDate.toDate()} to={filter.values.endDate.toDate()} onApplyFilter={onApplyFilter} onFilterChanged={this.props.onFilterChanged} onActiveFilterChanged={this.props.onActiveFilterChanged} />
                        </MenuItem>
                    </SubMenu>
                </SubMenu>
            </Menu>
        )
    }
}

DatePickerPredefinedComponent.propTypes = {
    filter: PropTypes.any,
    maxDaysRange: PropTypes.number.isRequired,
    onFilterChanged: PropTypes.func.isRequired,
    onActiveFilterChanged: PropTypes.func,
    onOpenChange: PropTypes.func.isRequired,
    onApplyFilter: PropTypes.func,
    predefinedDates: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.number.isRequired,
        displayValue: PropTypes.string.isRequired,
        isRange: PropTypes.bool.isRequired
    })).isRequired,
    displayTime: PropTypes.bool,
};

export default DatePickerPredefinedComponent;