import React, { Component } from 'react';
import PropTypes from 'prop-types';

class CustomerNameSelectionComponent extends Component {

    get cardholderNameValue() {
        return this.props.filter.values.cardholderName;
    }

    filterRemoved = e => {
        this.props.onFilterRemoved({
            id: 'cardholderName',
            emptyValue: null
        })
    };

    render() {
        return (
            <div className="filter__tag">
                <label className="filter__tag__label">{this.props.label}: </label>
                {this.cardholderNameValue}
                <button onClick={this.filterRemoved} className="btn btn--reset filter__tag__clear"></button>
            </div>
        );
    }
}

CustomerNameSelectionComponent.propTypes = {
    filter: PropTypes.object.isRequired,
    onFilterRemoved: PropTypes.func.isRequired,
    label: PropTypes.string,
};

CustomerNameSelectionComponent.defaultProps = {
    label: 'Cardholder Name',
};

export default CustomerNameSelectionComponent;
