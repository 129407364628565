import React from 'react';
import { find, sumBy, cloneDeep, map, some, each, includes, toLower, first, get } from 'lodash';

import { getThumbnailOverlay, renderIf } from "./../../common/utilities";
import { formatCurrency } from '../../helpers/currency-formatter';
import { HardwareSidebarComponent } from './hardware-side-bar';
import HardwareListItemComponent from './hardware-list-item';

class HardwareListComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filteredList: props.hardwareList,
            subs: [],
            searchInput: '',
            filterSettings: {},
            categoryOptions: this.getCategoryOptions,
        };
    }

    get getCategoryOptions() {
        const { hardwareList } = this.props;
        const options = {};
        each(hardwareList, ({ category, subcategory, brand }) => {
            category = toLower(category);
            subcategory = toLower(subcategory);
            brand = toLower(brand);
            
            if (category && !options[category]) {
                options[category] = {};
            }
            if (subcategory && !options[category][subcategory]) {
                options[category][subcategory] = [];
            }

            if (brand && options[category][subcategory] && !includes(options[category][subcategory], brand)) {
                options[category][subcategory].push(brand);
            }
        });

        return options;
    };

    hardwareSelectToggle = (equipmentId) => (e) => {
        let hardware = cloneDeep(this.props.merchantEquipment.find((e, i) => e.equipmentId == equipmentId));
        if (!!hardware) {
            hardware.isSelected = !hardware.isSelected;

            if (!hardware.isSelected && hardware.accessories) {
                hardware.accessories = map(hardware.accessories, item => {
                    return { ...item, isSelected: false, quantity: 0 };
                });
            }
        }
        else {
            hardware = this.props.createNewEquipment(equipmentId, true);         
        }
        this.props.onChange(hardware);
    }

    onViewEquipmentDetail = (equipmentId) => (e) => {
        this.scrollToTop();
        this.props.onShowEquipment(equipmentId);
    }

    scrollToTop = () => {
        const topElement = document.querySelector('#topOfPage');

        if (topElement) {
            topElement.scrollTop = 0;
        }
    }

    handleBackClick = () => {
        this.props.onShowEquipment(null);
    };

    submenuClick = (filters, clearOtherFilters = true) => () => {
        this.scrollToTop();
        this.onViewEquipmentDetail(null);
        this.setFilters(filters, clearOtherFilters);
        this.props.onShowEquipment();
    }

    setFilters = (filters, clearOtherFilters = false) => {
        const { hardwareList } = this.props;
        const { filterSettings } = this.state;

        let query = cloneDeep(filterSettings);
        let filteredList = [];
        let subs = [];

        if (clearOtherFilters) {
            query = {}; // reinitialize;
        }

        each(filters, (valueToFilter, fieldName) => {
            if (!!valueToFilter) {
                // for now putting single item in array, but might have case where we want multiple values possible
                query[fieldName] = [valueToFilter.toLowerCase()];
            } else if (query && query[fieldName]) {
                delete query[fieldName];
            }

            filteredList = hardwareList.filter((h) => {
                for (let key in query) {
                    //console.log('checking for key ' + key + ' with value ' + query[key]);
                    //console.log(' item value is ' + h[key]);
                    if (h[key] === undefined || !some(query[key], value => h[key].toLowerCase().includes(value))) {
                        return false;
                    }
                }
                return true;
            });

            if (filteredList.length > 0 && query.category) {
                let cat = query.category[0];
                //if (cat && (cat === 'terminal' || cat === 'pinpad' || cat === 'accessories')) {
                    subs = [...new Set(filteredList.map((e) => e.subcategory))];
                //}
            }
        });

        this.setState({ filterSettings: query, filteredList, subs });
    }

    handleChange = ({ target: { name, value } }) => {
        this.setState({ [name]: value }, () => this.setFilters({ name: value }, false));
    };

    render() {
        let { filteredList, subs, searchInput, filterSettings, categoryOptions } = this.state;
        const { physicalAddress, corporateAddress, hardwareList, merchantEquipment, selectedEquipmentId, downloadPrefilledAuthorizationForm, getAuthorizationFormName} = this.props;
        const categories = [''];
        hardwareList.map((hardware) => {
            const category = toLower(hardware.category)
            if (!categories.includes(category)) {
                categories.push(category)
            }
        });

        return (
            <div className="l--twocols">  
                    <div className="l--twocols--aside"> 
                        <HardwareSidebarComponent
                            onClick={this.submenuClick}
                            optionsList={categoryOptions}
                            activeCategory={first(filterSettings.category) || ''}
                            activeSubcategory={first(filterSettings.subcategory) || ''}
                            activeBrand={first(filterSettings.brand) || ''}
                            applicableCategories={categories}
                        />
                    </div>                
                {/*List View*/}
                {!selectedEquipmentId && (
                    <React.Fragment>
                        <div className="l--twocols--main">
                            <div>
                                <div className="flex flex--tertiary spc--bottom--med">
                                    <h4 className="header__title">Hardware List</h4>
                                    <div className="">
                                        <input
                                            className="input input--med input--search"
                                            placeholder="Search hardware"
                                            name="searchInput"
                                            id="searchInput"
                                            value={searchInput}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    {renderIf(filteredList.length == 0)(
                                        <div className="col col-sml-12"><div className="note note--default">No equipment found for the current filter</div></div>
                                    )}
                                    {subs.length > 0 ? 
                                         subs.map((sub, i) => {
                                             return <div key={sub} className="col col-sml-12 spc--bottom--med"><h5 onClick={this.submenuClick({ subcategory: sub }, false)}>{sub}</h5></div>})
                                     : null}

                                    {filteredList.map((h, i) => {   
                                        let merchantHardware = merchantEquipment.find((e, i) => e.equipmentId == h.equipmentId);
                                        const isBillAgentPaymentSchedule = merchantHardware && toLower(merchantHardware.paymentSchedule) === 'billagent';
                                        let accessoriesTotal = 0;
                                        const outOfStock = h.outOfStock;

                                        if (merchantHardware) {
                                            accessoriesTotal = sumBy(merchantHardware.accessories, ({ isSelected, quantity, fees }) => {
                                                if (isSelected && fees) {
                                                    const fee = find(fees, { feeType: 'OneTimeFee' });
                                                    return (isBillAgentPaymentSchedule ? fee.agentCost : (fee.merchantPrice || fee.retailPrice)) * quantity;
                                                }
                                                return 0;
                                            });
                                        }
                                        let hardwarePrice = isBillAgentPaymentSchedule ? 
                                            (get(merchantHardware, 'fees.0.agentCost') || h.purchasePlans[0].fees.find((f) => f.feeType == 'OneTimeFee').agentCost) :
                                            (get(merchantHardware, 'fees.0.merchantPrice') || h.purchasePlans[0].fees.find((f) => f.feeType == 'OneTimeFee').retailPrice);
                                        
                                    return (
                                        <React.Fragment key={i}>
                                            <div className="col col-sml-12 col-med-6 col-lrg-4 col-huge-3 product__card__wrap">
                                                <div className="product__card" >
                                                    {/* Using static image for styling purposes */}
                                                    {/* <img className="product__card__thumb" src={process.env.REACT_APP_CDN_URL + h.name.toLowerCase().replace(' ','_') + "/thumbnail.png"}/> */}

                                                    
                                                    <div className="product__card__thumb__wrap" onClick={this.onViewEquipmentDetail(h.equipmentId)}>
                                                        <div className={`product__card__thumb${getThumbnailOverlay(outOfStock, h.purchaseTypes)}`} style={{ backgroundImage: "url(" + process.env.REACT_APP_CDN_URL + h.name.toLowerCase().replace(' ','_').replace(/[^a-z0-9_-]/gi,'') + "/thumbnail.png" + ")" }} ></div>
                                                    </div>
                                                    <a className="product__card__title" href="javascript:void(0);" onClick={this.onViewEquipmentDetail(h.equipmentId)}>
                                                        {h.name}
                                                    </a>
                                                    <div className="product__card__description">{h.shortDescription || h.description}</div>
                                                    <div className="product__card__price">
                                                        <span className="product__card__icon"></span>
                                                        {formatCurrency(hardwarePrice + accessoriesTotal)}
                                                    </div>
                                                    {merchantHardware && merchantHardware.isSelected &&
                                                        <div>
                                                            <label className="note note--success spc--bottom--sml display--b">Added</label>
                                                            
                                                            <button className="btn btn--med btn--secondary" onClick={this.hardwareSelectToggle(h.equipmentId)}>Remove</button>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )
                                    })}
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )}
                {/*Detail view*/}
                {!!selectedEquipmentId && (
                    <React.Fragment>
                        <HardwareListItemComponent
                            hardware={hardwareList.filter((h) => h.equipmentId == selectedEquipmentId)[0]}
                            merchantHardware={merchantEquipment.find((e, i) => e.equipmentId == selectedEquipmentId)}
                            physicalAddress={physicalAddress}
                            corporateAddress={corporateAddress}
                            onChange={this.props.onChange}
                            createNewEquipment={this.props.createNewEquipment}
                            handleBackClick={this.handleBackClick}
                            downloadPrefilledAuthorizationForm={downloadPrefilledAuthorizationForm}
                            getAuthorizationFormName={getAuthorizationFormName}
                            optionIsAvailableForRules={this.props.optionIsAvailableForRules}
                        />
                        
                    </React.Fragment>
                )}
            </div>     
        );
    }
}

export default HardwareListComponent;