import { get, toLower } from 'lodash';
import { digitLength } from './validators';

const esanumber = (val, ctx) => toLower(get(ctx, 'amexDetails.status', '')) === 'existing' ? !!val : true;
const esanumberLength = (val, ctx) => toLower(get(ctx, 'amexDetails.status', '')) === 'existing' ? digitLength(10)(val) : true;
const ebtnumber = (val, ctx) => ctx.doesAcceptEbt ? !!val : true;
const ownershipType = (val) => val ? val !== "Unknown" : false;

export const MPARequiredTemplate = {
    amexDetails:{
        esaNumber: {
            type: String,
            use: { esanumber, esanumberLength },
            message: {
                esanumber: '[**Amex ESA Number**](javascript:void) is required',
                esanumberLength:'[**Amex ESA Number**](javascript:void) must be 10 digits'
            }
        },
    },
    doesAcceptEbt: {
        type: Boolean,
        required: false
    },
    ebtNumber: {
        type: String,
        use: { ebtnumber },
        message: '[**EBT Number**](javascript:void) is required'
    },
    dba: {
        required: true,
        type: String,
        message: '[**DBA**](javascript:void) is required'
    },
    businessInformation: {
        businessPhone: {
            required: true,
            type: String,
            message: '[**Business Phone Number**](javascript:void)'
        },
        businessAddress: {
            streetAddress: {
                required: true,
                type: String,
                message: '[**Business Address**](javascript:void)'
            },
            city: {
                required: true,
                type: String,
                message: '[**Business City**](javascript:void)'
            },
            state: {
                required: true,
                type: String,
                message: '[**Business State**](javascript:void)'
            },
            zip: {
                required: true,
                type: String,
                message: '[**Business Zip**](javascript:void)'
            },
        },
        ownershipType: {
            use:{ ownershipType },
            type: String,
            message: {
                ownershipType: '[**Ownership Type**](javascript:void) must be selected',
            }
        },
    },
    corporateName: {
        required: true,
        type: String,
        message: '[**Corporate Name**](javascript:void)'

    },
    corporateAddress: {
        streetAddress: {
            required: true,
            type: String,
            message: '[**Mailing Address**](javascript:void)'
        },
        city: {
            required: true,
            type: String,
            message: '[**Mailing City**](javascript:void)'
        },
        state: {
            required: true,
            type: String,
            message: '[**Mailing State**](javascript:void)'
        },
        zip: {
            required: true,
            type: String,
            message: '[**Mailing Zip**](javascript:void)'
        },
    },
    signerInformation: {
        firstName: {
            required: true,
            type: String,
            message: '[**Signer First Name**](javascript:void)'
        },
        lastName: {
            required: true,
            type: String,
            message: '[**Signer Last Name**](javascript:void)'
        },
        address: {
            streetAddress: {
                required: true,
                type: String,
                message: {
                    required: '[**Signer Home Address**](javascript:void)',
                } 
            },
            city: {
                required: true,
                type: String,
                message: '[**Signer City**](javascript:void)'
            },
            state: {
                required: true,
                type: String,
                message: '[**Signer State**](javascript:void)'
            },
            zip: {
                required: true,
                type: String,
                message: '[**Signer Zip**](javascript:void)'
            },
        },

        phoneNumber: {
            required: true,
            type: String,
            message: '[**Signer Phone Number**](javascript:void)'
        },
        socialSecurityNumber: {
            required: true,
            type: String,
            message: '[**Signer Social Security Number**](javascript:void)'
        },
        dateOfBirth: {
            required: true,
            type: String,
            message: '[**Signer DOB**](javascript:void)'
        }
    },
    goodsOrServicesDescription: {
        required: true,
        type: String,
        message: '[**Goods/Services Sold**](javascript:void)'
    },
    taxID: {
        required: true,
        type: String,
        message: '[**Tax ID**](javascript:void)'
    },
    bankAccountInformation: {
        bankName: {
            required: true,
            type: String,
            message: '[**Bank Name**](javascript:void)'
            },
        accountNumber: {
            required: true,
            type: String,
            message: '[**Bank Account Number**](javascript:void)'
        },
        routingNumber: {
            required: true,
            type: String,
            message: '[**Bank Routing Number**](javascript:void)'
        }
    }
}
