import PropTypes from 'prop-types';
import React from 'react';
import { DisplayBooleanComponent } from '../../../common/formatters';

class PciStatusComponent extends DisplayBooleanComponent {
    constructor(props) {
        super(props);
    }
    render = () => {
        if (this.props.row.processorName == "CK-Go" || this.props.row.processorName == "ACHQ") {
            return(
                <span className='type--bool type--bool--na type--uppercase'>N/A</span>
            );
        }
        else {
             return  super.render();
        }
}
}
export default PciStatusComponent;

PciStatusComponent.propTypes = {
    value: PropTypes.string,
    row: PropTypes.object
};