import React from 'react';
import PropTypes from 'prop-types';

import ToggleFilterComponent from './ToggleFilter';
class HiddenFilterComponent extends ToggleFilterComponent {
    renderInput = () => {
        if (this.props.column.filterable === false) {
            return <span/>;
        }
        let currentVal = this.state.filterTerm;
        return (
            <span className="filter__btn--hide">
            {currentVal.toString() == "false" ?
                (<button onClick={this.handleChange} data-val="true" className="btn btn--med btn--ghost" title="Show hidden accounts"><i className="icon icon--xsml icon--view align--v--sub" data-val="true" /></button>)
                : (<button onClick={this.handleChange} data-val="false" className="btn btn--med btn--ghost" title="Hide hidden accounts"><i className="icon icon--xsml icon--eye align--v--sub" data-val="false" /></button>)
             }
            </span>
        );
    };
}

HiddenFilterComponent.defaultProps = {
    exclusiveOnTrue: false,
}

HiddenFilterComponent.propTypes = {
    onChange: PropTypes.func.isRequired,
    column: PropTypes.object,
    exclusiveOnTrue: PropTypes.bool,
};

export default HiddenFilterComponent;