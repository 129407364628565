import React, { Component } from 'react';
import { map, isEmpty, findIndex, get, find, filter, includes, toLower, flatMap, uniqBy, some, orderBy } from 'lodash';

import { getThumbnailOverlay } from '../../common/utilities';
import { formatCurrency } from '../../helpers/currency-formatter';
import { withLoader } from '../../common/components';
import withError from '../../common/components/error/error-hoc';
import { appService } from '../../services/appService';

const equipmentTypes = [
    { key: 'gateway', label: 'Gateway' },
    { key: 'addOns', label: 'Gateway add ons' },
    { key: 'hardware', label: 'Hardware' },
    { key: 'accessories', label: 'Accessories' },
    { key: 'software', label: 'Software' },
];
class EquipmentLookup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            equipmentType: '',
            submittedEquipmentType: '',
            inlineFilter: '',
            filteredEquipments: [],
            gateway: [],
            addOns: [],
            hardware: [],
            accessories: [],
            errorMessage: '',
        };
    }
    componentDidMount() {
        this.fetchEquipment();
    }
    handleChange = ({ target: { value, name }}) => {
        this.setState({
            [name]: value,
        });
    };
    handleExpand = (equipmentId) => {
        const equipmentList = this.state[this.state.submittedEquipmentType];
        const index = findIndex(equipmentList, { equipmentId });
        const newEquipments = [...equipmentList];
        newEquipments[index] = {
            ...equipmentList[index],
            isExpanded: !equipmentList[index].isExpanded,
        };
        this.setState({
            [this.state.submittedEquipmentType]: newEquipments,
        }, this.filterEquipment);
    };
    fetchEquipment = async () => {
        const { showLoader } = this.props;
        showLoader(true);
        try {
            let { equipmentList } = await appService.getEquipmentList();
            equipmentList = orderBy(equipmentList, 'name');
            const gateway = filter(equipmentList, { category: 'Gateway' });
            const software = filter(equipmentList, { category: 'Software' });
            const addOns = orderBy(uniqBy(flatMap(gateway, ({ subequipment }) => subequipment || []), 'equipmentId'), 'name');
            const hardwareList = filter(equipmentList, ({ category, purchasePlans }) => !includes(['Gateway', 'Software', 'Var'], category) && some(purchasePlans, ({ fees }) => some(fees, { feeType: 'OneTimeFee' })))
            const hardware = filter(hardwareList, ({ category }) => category !== 'Accessories');
            const accessories = filter(hardwareList, { category: 'Accessories' });
            this.setState({ gateway, addOns, hardware, software, accessories });
        } catch (err) {
            this.props.handleError(err);
        }
        showLoader(false);
    }
    filterType = () => {
        this.setState({
            submittedEquipmentType: this.state.equipmentType,
        }, this.filterEquipment);
    };
    filterEquipment = (e) => {
        if (e) e.preventDefault();
        const { inlineFilter, submittedEquipmentType } = this.state;
        const equipmentList = this.state[submittedEquipmentType];
        const filteredEquipments = filter(equipmentList, ({ name }) => !inlineFilter || includes(toLower(name), toLower(inlineFilter)));
        this.setState({
            filteredEquipments,
        });
    };
    render() {
        const { equipmentType, submittedEquipmentType, inlineFilter, filteredEquipments } = this.state;
        const { isLoading } = this.props;
        return (
            <div id="main-div" className="l--content l--content--equipment">
                <div className="header header--grid">
                    <h2 className="header__title">Equipment Lookup</h2>
                </div>

                <div className="row">
                    <div className="col col-sml-12 col-lrg-4 spc--bottom--med">
                        <div className="card--primary card--primary--equipment-lookup">
                            <div className="card--primary--equipment-lookup__header">
                                <div className="type--wgt--bold spc--bottom--med">Select options</div>
                            </div>
                            <div className="card--primary--equipment-lookup__body">
                                <div className="form__field">
                                    <label htmlFor="equipmentType" className="label">Equipment Type <span>*</span></label>
                                    <select className="input input--med input--select" value={equipmentType} onChange={this.handleChange} name="equipmentType" id="equipmentType">
                                        {equipmentType === '' && <option value="">Please select</option>}
                                        {map(equipmentTypes, ({ key, label }) => <option key={key} value={key}>{label}</option>)}
                                    </select>
                                </div>
                                <div className="type--right">
                                    <button className="btn btn--med btn--primary spc--bottom--med w--150p" disabled={isLoading || !equipmentType} onClick={this.filterType} type="button">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col col-sml-12 col-lrg-8 spc--bottom--med">
                        <div className="card--primary card--primary--equipment-lookup">
                            <div className="accessories__filter">
                                <form className="flex--primary" onSubmit={this.filterEquipment}>
                                    <label htmlFor="inlineFilter" className="type--nowrap label type--color--primary spc--right--sml spc--bottom--xsml">Filter</label>
                                    <input className="input input--med w--max--300 spc--bottom--xsml align--h--left" value={inlineFilter} name="inlineFilter" id="inlineFilter" onChange={this.handleChange} type="text" placeholder="Select"></input>
                                    <button className="btn btn--med btn--primary spc--bottom--xsml w--150p" disabled={isLoading} type="submit">Filter</button>
                                </form>
                            </div>
                            <div>
                                <EquipmentLookupTable equipmentType={submittedEquipmentType} equipmentList={this.state[submittedEquipmentType]} filteredEquipments={filteredEquipments} onExpand={this.handleExpand} />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

function EquipmentLookupTable({ equipmentList, filteredEquipments, onExpand, equipmentType }) {
    if (isEmpty(equipmentList)) return <div className="type--center type--color--light spc--bottom--lrg spc--top--lrg">No Equipments Yet</div>;
    if (isEmpty(filteredEquipments)) return <div className="type--center type--color--light spc--bottom--lrg spc--top--lrg">No Equipments Matching The Filter</div>;
    const showImage = !includes(['gateway', 'addOns', 'software'], equipmentType);
    return (
        <table className="table table--primary table--equipment-lookup">
            <thead>
                <tr>
                    <th>Equipment Name</th>
                    <th>Agent Cost</th>
                    <th>Merchant Cost</th>
                    {showImage && <th>Image</th>}
                </tr>
            </thead>
            <tbody>
                {map(filteredEquipments, ({ equipmentId, name, outOfStock, purchasePlans, purchaseTypes, isExpanded, description }, index) => {
                    const agentCost = get(find(get(purchasePlans, '0.fees'), { feeType: 'OneTimeFee'}), 'agentCost', 0);
                    const merchantCost = get(find(get(purchasePlans, '0.fees'), { feeType: 'OneTimeFee' }), 'retailPrice', 0);
                    return (
                        <React.Fragment key={equipmentId}>
                            <tr className={index % 2 ? 'odd' : 'even'}>
                                <td>
                                    <div className="flex--primary">
                                        <div>
                                            <button className="btn btn--sml btn--clear" type="button" onClick={() => onExpand(equipmentId)}>
                                                <i className={`icon icon--micro icon--arrow--${isExpanded ? 'up' : 'down'}--primary`}></i>
                                            </button>
                                        </div>
                                        <div>
                                            <span className="display--b">{name}</span>
                                            <div className={`type--color--primary type--uppercase type--nano type--wgt--bold ${getThumbnailOverlay(outOfStock, purchaseTypes) ? '' : 'display--n'}`}>Out of Stock</div>
                                        </div>
                                    </div>
                                </td>
                                <td>{formatCurrency(agentCost)}</td>
                                <td>{formatCurrency(merchantCost)}</td>
                                {showImage && <td><div className={`${getThumbnailOverlay(outOfStock, purchaseTypes)} accessories__list__item__thumbnail`} style={{ backgroundImage: "url(" + process.env.REACT_APP_CDN_URL + name.toLowerCase().replace(' ', '_').replace(/[^a-z0-9_-]/gi, '') + "/thumbnail.png)" }} alt={name}></div></td>}
                            </tr>
                        {isExpanded && (
                                <tr>
                                    <td colSpan={showImage ? 3 : 2}>{description}</td>
                                </tr>
                            )}
                        </React.Fragment>
                    )}
                )}
            </tbody>
        </table>
    )
}


export default withError(withLoader(EquipmentLookup));