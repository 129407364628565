import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ZipSelectionComponent extends Component {

    get zipValue() {
        return this.props.filter.values.zip;
    }

    filterRemoved = e => {
        this.props.onFilterRemoved({
            id: 'zip',
            emptyValue: null
        });
    };

    render() {
        return (
            <div className="filter__tag">
                <label className="filter__tag__label">Zip: </label>
                {this.zipValue}
                <button onClick={this.filterRemoved} className="btn btn--reset filter__tag__clear"></button>
            </div>
        );
    }
}

ZipSelectionComponent.propTypes = {
    filter: PropTypes.object.isRequired,
    onFilterRemoved: PropTypes.func.isRequired
};

export default ZipSelectionComponent;