import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class DBALinkComponent extends Component {

    
    render() {
        let status = this.props.dependentValues.statusDisplayName;
        let detailsLink = `/merchants/${this.props.dependentValues.appId}/account`;
        if (status === 'Incomplete') {
            detailsLink = '/eapp/mpa/' + this.props.dependentValues.appId
        }
        return (
            <span>
                <Link to={{
                    pathname: detailsLink
                }}                    
                    className="merchant-to-details-link" href="javascript:void(0)"
                    title="View merchant details"
                >{this.props.value} 
                </Link>
            </span>           
        );
    }
}

DBALinkComponent.propTypes = {
    value: PropTypes.string,
    dependentValues: PropTypes.shape({
        appId: PropTypes.number,
    }),
};

export default DBALinkComponent;