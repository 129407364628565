import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const VerifyAddressPopup = ({ originalAddress, recommendedAddress, addressType, updateAddress, setAddressResolved }) => {
    return (
        <Fragment>
            <div className="modal__header">
                <h4>Verify Your {addressType} Address</h4>
            </div>
            <div className="modal__body">
                <div className="row">
                    <div className="col col-sml-6 form__group">
                        <h5>Original Address:</h5><br />
                        {originalAddress.streetAddress}<br />
                        {originalAddress.city}, {originalAddress.state} {originalAddress.zip}
                    </div>
                    <div className="col col-sml-6 form__group">
                        <h5>Recommended Address:</h5><br />
                        {recommendedAddress.streetAddress}<br />
                        {recommendedAddress.city}, {recommendedAddress.state} {recommendedAddress.zip}<br />
                    </div>
                </div>
            </div>
            <div className="modal__footer">
                <button
                    className="btn btn--med btn--primary"
                    onClick={() => updateAddress(recommendedAddress, addressType)}
                >
                    Update
                </button>
                <button
                    className="btn btn--med btn--primary"
                    onClick={() => setAddressResolved(addressType)}
                >
                    Don't Update
                </button>
            </div>
        </Fragment>
    );
};

VerifyAddressPopup.propTypes = {
    originalAddress: PropTypes.object.isRequired,
    recommendedAddress: PropTypes.object.isRequired,
    addressType: PropTypes.string.isRequired,
    updateAddress: PropTypes.func.isRequired,
    setAddressResolved: PropTypes.func.isRequired,
};

export default VerifyAddressPopup;