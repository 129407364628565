import { cloneDeep } from 'lodash';
import { MPARequiredTemplate } from './MPARequiredTemplate.validation';

const isSignerInformationSet = (ctx) => {
	return ctx.signerInformation.identificationInformation.identificationType ||
		ctx.signerInformation.identificationInformation.identificationNumber ||
		ctx.signerInformation.identificationInformation.identificationIssueDate ||
		ctx.signerInformation.identificationInformation.identificationExpirationDate ||
		ctx.signerInformation.identificationInformation2.identificationType ||
		ctx.signerInformation.identificationInformation2.identificationNumber ||
		ctx.signerInformation.identificationInformation2.identificationIssueDate ||
		ctx.signerInformation.identificationInformation2.identificationExpirationDate;
}

const ssnRequired = (val, ctx) => isSignerInformationSet(ctx) ? true : !!val;
const idRequired = (val, ctx) => isSignerInformationSet(ctx) ? !!val : true;
const signerAgreement = (val) => (val === true)
export const CanadaMPARequiredTemplate = cloneDeep(MPARequiredTemplate);

CanadaMPARequiredTemplate.businessInformation.businessAddress.state.message =
	'[**Business Province**](javascript:void)';
CanadaMPARequiredTemplate.corporateAddress.state.message = '[**Mailing Province**](javascript:void)';
CanadaMPARequiredTemplate.signerInformation.address.state.message = '[**Signer Province**](javascript:void)';
CanadaMPARequiredTemplate.businessInformation.businessAddress.zip.message =
	'[**Business Postal Code**](javascript:void)';
CanadaMPARequiredTemplate.corporateAddress.zip.message = '[**Mailing Postal Code**](javascript:void)';
CanadaMPARequiredTemplate.signerInformation.address.zip.message = '[**Signer Postal Code**](javascript:void)';
delete CanadaMPARequiredTemplate.bankAccountInformation.bankName;
delete CanadaMPARequiredTemplate.bankAccountInformation.routingNumber;

CanadaMPARequiredTemplate.signerInformation.socialSecurityNumber = {
	type: String,
	required: false,
	use: { ssnRequired },
	message: '[**Social Insurance Number**](javascript:void)',
};

CanadaMPARequiredTemplate.signerInformation.identificationInformation.identificationType = {
	use: { idRequired },
	type: String,
	message: '[**ID Document #1 Type**](javascript:void)',
};
CanadaMPARequiredTemplate.signerInformation.identificationInformation.identificationNumber = {
	use: { idRequired },
	type: String,
	message: '[**ID Document #1 Number**](javascript:void)',
};
CanadaMPARequiredTemplate.signerInformation.identificationInformation.identificationIssueDate = {
	use: { idRequired },
	type: Date,
	message: '[**ID Document #1 Issue Date**](javascript:void)',
};
CanadaMPARequiredTemplate.signerInformation.identificationInformation.identificationExpirationDate = {
	use: { idRequired },
	type: Date,
	message: '[**ID Document #1 Expiration Date**](javascript:void)',
};
CanadaMPARequiredTemplate.signerInformation.identificationInformation2.identificationType = {
	use: { idRequired },
	type: String,
	message: '[**ID Document #2 Type**](javascript:void)',
};
CanadaMPARequiredTemplate.signerInformation.identificationInformation2.identificationNumber = {
	use: { idRequired },
	type: String,
	message: '[**ID Document #2 Number**](javascript:void)',
};
CanadaMPARequiredTemplate.signerInformation.identificationInformation2.identificationIssueDate = {
	use: { idRequired },
	type: Date,
	message: '[**ID Document #2 Issue Date**](javascript:void)',
};
CanadaMPARequiredTemplate.signerInformation.identificationInformation2.identificationExpirationDate = {
	use: { idRequired },
	type: Date,
	message: '[**ID Document #2 Expiration Date**](javascript:void)',
};
CanadaMPARequiredTemplate.bankAccountInformation.institutionNumber = {
	required: true,
	type: String,
	message: '[**Bank Institution Number**](javascript:void)',
};
CanadaMPARequiredTemplate.bankAccountInformation.transitNumber = {
	required: true,
	type: String,
	message: '[**Bank Transit Number**](javascript:void)',
};
CanadaMPARequiredTemplate.businessInformation.businessEmail = {
	required: true,
	type: String,
	message: '[**Business Email**](javascript:void)',
};
CanadaMPARequiredTemplate.signerInformation.signerAgreement = {
	use:{signerAgreement },
	type: Boolean,
	message: '[**Signer Agreement**](javascript:void)',
};
