import USMpaConfirm from './USMpaConfirm';
import React from 'react'

export default class AchMpaConfirm extends USMpaConfirm {

    renderAdditionalPaymentDetails(mpa){
        return (
            <div>
                <div className="card--primary__subheader">
                    <strong>Additional Transaction Details</strong>
                    {this.props.renderChangeLink(this.props.redirectToMpa)}
                </div>
                <div className="row">
                            {this.renderField(mpa, 'generalTransactionInfo.maxSingle', 'Max Single')}
                    {this.renderField(mpa, 'generalTransactionInfo.maxDaily', 'Daily Check Amount')}
                    {this.renderField(mpa, 'generalTransactionInfo.maxDailyAmount', 'Max Daily Volume')}
                    {this.renderField(mpa, 'generalTransactionInfo.maxPeriod', 'Monthly Check Amount')}
                    {this.renderField(mpa, 'generalTransactionInfo.maxPeriodAmount', 'Max Monthly Volume')}
                            {this.renderField(mpa, 'generalTransactionInfo.averageTransactionAmount', 'Expected Average Amount')}
                </div>
            </div>
                
        );
    }
    renderAlternateBankInfo=(mpa)=>{
        const showAlternate =  (mpa.bankAccountInformation.alternateAccountNumber || mpa.bankAccountInformation.alternateRoutingNumber);
        return showAlternate && (
            <div className="row grid-align-middle">
                {this.renderField(mpa, 'bankAccountInformation.alternateBankName', 'Alternate Bank Name')}
                {this.renderField(mpa, 'bankAccountInformation.alternateAccountNumber', 'Alternate Account DDA #', this.renderLastFourOrValue, 'fs-mask')}
                {this.renderRoutingNumber(mpa, 'bankAccountInformation.alternateRoutingNumber', 'Alternate Bank Routing #')}
            </div>
        );
    }
    
    renderBusinessEmail(mpa){
        return this.renderField(mpa, 'businessInformation.businessEmail', 'Contact Email')
    }
    renderAdditionalSignerInfo(mpa) {
        return (
            <div className="col col-sml-12">
                <div className="row">
                    {this.renderField(mpa, 'signerInformation.ownershipPercentage', 'Ownership Percentage')}
                    {this.renderField(mpa, 'signerInformation.email', 'Signer Email')}
                    {this.renderField(mpa, 'signerInformation.title', 'Signer Title')}
                </div>
            </div>
        );
    }
    renderDriversLicense =() =>{
        return this.renderUploadDocumentRow('DriversLicense', 'Drivers License')
    }

}