import './styles/css/app.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import unregisterServiceWorker from './unregisterServiceWorker';
import {split, find, includes} from 'lodash';
// workaround for menu items to work properly
import Trigger from 'rc-trigger';
Trigger.defaultProps.autoDestroy = true;
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
const renderApp = (renderSuccessApp, renderError) => {
  const hostWithSubdomain = ['.partner.cardknox.com', '.devpartner.cardknox.com'];
  const hostSplitBy = find(hostWithSubdomain, (host) => includes(window.location.host, host));
  if (!hostSplitBy || split(window.location.host, hostSplitBy)[0] === 'stg1-us-east-2') {
    renderSuccessApp(); // renders app if url is not subdomain
    return;
  }
  let cobrandDomain = split(window.location.host, hostSplitBy)[0];
  const cobrandSOT = `${process.env.REACT_APP_NOT_SECURE_CDN_URL}cobrand/${cobrandDomain}/csscolors.css`;
  fetch(cobrandSOT).then((response) => {
    if(!response.ok) {
      renderError(); // renders error page if subdomain is invalid
    }
    else{
      renderSuccessApp(); // renders app if subdomain is valid
    }
  }).catch(()=> {
     renderError(); // renders error page if subdomain is invalid
  })
}
renderApp(
  () => {
    root.render(
    <BrowserRouter basename={baseUrl}>
      <App />
    </BrowserRouter>
  )
  unregisterServiceWorker();
},
  () => root.render(<h1>404 Not Found</h1>)
);