import React, { Fragment, Component, createRef } from 'react';
import { map, isEmpty, get, noop } from 'lodash';

import { withContext, withLoader } from "./../../common/components";
import { appService } from '../../services/appService';
import { ModalWrapper, modalNames } from './../../common/components/modal-wrapper';
import { Notification } from '../../common/components/notifications';
import { MerchantContext } from '../MerchantDetails';
import { openFile } from '../../common/utilities';
import { canPreviewFile } from '../../common/utilities/canPreviewFile';

class MerchantFilesComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dirty: false,
            files: null,
            modal: {
                name: modalNames.none,
                data: null,
            }
        }

        this.notificationRef = createRef();
    }

    get addNotification() {
        return get(this.notificationRef, 'current.addNotification', noop);
    };

    componentDidMount() {
        this.fetchData();
    }

    fetchData = () => {
        const {
            showLoader,
            match: { params: { appid: appId } },
        } = this.props;

        showLoader(true);

        appService.getEApp(appId)
            .then(({ files }) => {
                this.setState({ files }, showLoader);
            })
            .catch(err => {
                console.log('getEApp error', err);
                showLoader();
                this.setState({ errorMessage: 'An error occurred: ' + err });
            });
    }

    handleFileDownload = (parentId, fileId, fileName, isDownload = false) => () => {
        const { showLoader } = this.props;
        showLoader(true);
        appService.getMerchantFile(parentId, fileId, fileName)
            .then((url) => {
                if (url.errorMessage) {
                    this.setState({ errorMessage: 'An error occurred: ' + url.errorMessage });
                } else {
                    openFile(url, fileName, !isDownload, false);
                    if (isDownload) {
                        const addNotification = get(this.notificationRef, 'current.addNotification', noop);
                        addNotification({
                            message: 'Successfully downloaded',
                            success: true,
                        });
                    }
                }
                showLoader();
            }).catch(err => {
                showLoader();
                this.setState({ errorMessage: 'An error occurred: ' + err });
            });
    }

    onDelete = ({ fileId, fileName, noteId }) => () => {
        this.openCloseModal({
            name: modalNames.confirmAction,
            data: {
                question: 'Are you sure you want to delete ' + fileName + '?',
                onConfirm: this.deleteFile(fileName, fileId, noteId)
            }
        });
    }

    deleteFile = (fileName, fileId, noteId) => () => {
        const { showLoader, history } = this.props;

        showLoader(true);

        appService.deleteMerchantFile(fileId, noteId)
            .then(({ refNum: ref }) => {
                this.addNotification({
                    ref,
                    success: true,
                    message: `${fileName} successfully deleted`,
                    onClose: () => history.go(),
                });
                showLoader();
            })
            .catch((ex) => {
                console.log('Error deleting file');
                console.log(ex);
                this.setState({ errorMessage: ex }, showLoader);
            })
    }

    openCloseModal = modal => this.setState({ modal });

    renderNewFile = () => {
        const { match: { params: { appid: appId } }, history } = this.props;
        return (
            <button
                type="button"
                className="btn btn--med btn--ghost spc--left--sml align--v--middle"
                onClick={() => this.openCloseModal({
                    name: modalNames.newFile,
                    data: {
                        appId,
                        history,
                        addNotification: this.addNotification,
                    },
                })}
            >
                <i className="icon icon--xsml icon--addnote align--v--middle"></i>
            </button>
        );
    }

    renderFileDownloadButtons = (parentId, fileId, fileName) => (
        <Fragment>
            {canPreviewFile(fileName) &&
                <button
                onClick={this.handleFileDownload(parentId, fileId, fileName)}
                    className="btn btn--med btn--icon"
                    title="View"
                >
                    <i className="icon icon--tiny align--v--sub icon--view" />
                </button>
            }
            <button
                onClick={this.handleFileDownload(parentId, fileId, fileName, true)}
                className="btn btn--med btn--icon"
                title="Download"
            >
                <i className="icon icon--tiny align--v--sub icon--download" />
            </button>
        </Fragment>
    );

    renderFiles = () => {
        const { files } = this.state;

        return isEmpty(files) ? (
            <tr>
                <td className="is-message">
                    <div className="note note--default note--no-margin">No files found. Add a new file to view it in the list.</div>
                </td>
            </tr>
        ) : (
                map(files, ({ parentId, fileName, fileTag, fileDescription, fileId }, index) => {
                return (
                    <tr key={fileId}>
                        <td>{fileName}</td>
                        <td>{fileTag}</td>
                        <td className="is-note">{fileDescription}</td>
                        <Fragment>
                            <td>{this.renderFileDownloadButtons(parentId, fileId, fileName)}</td>
                         </Fragment>
                    </tr>
                );
            }));
    };

    render() {
        const { files, errorMessage } = this.state;
        const { merchant } = this.props;

        return (
            <div id="main-div" className="l--content">
                <Notification ref={this.notificationRef} />
                <ModalWrapper modal={this.state.modal} onModalClose={this.openCloseModal} />
                {errorMessage && (
                    <div className="note note--warning">
                        {errorMessage}
                    </div>
                )}
                <header className="header">
                    <div className="header__title__wrap">
                        <div className="header__title">
                            {merchant && merchant.dba && merchant.appId && (
                                <div className="type--sml--plus type--uppercase type--color--text spc--bottom--sml">
                                    {`${merchant.dba} - ${merchant.appId}`}
                                </div>
                            )}
                            <span>Files</span>
                            {this.renderNewFile()}
                        </div>
                    </div>
                </header>
                {!isEmpty(files) && (
                    <div className="table--files__wrap table--files__feature">
                        <div className="table--files">
                            <table className="table">
                                <tbody>
                                    {this.renderFiles(files)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default withLoader(withContext(MerchantFilesComponent, MerchantContext, 'merchant'));