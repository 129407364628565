import React from 'react';
import PropTypes from 'prop-types';

export default class CheckboxContainer extends React.Component{
  constructor(props){
       super(props);

       this.state = {
        checkbox: !!this.props.checked
       }
  }

  handleCheckbox = (e) => {
      this.setState({ checkbox: e.target.checked })
      if (this.props.onCheckChanged)
          this.props.onCheckChanged(e);
  }


    render() {
        const { divClass, checkboxClass, labelClass, labelText, checkboxName, checked } = this.props;
        return (
            <React.Fragment>
                <div className={divClass}>
                    <input type="checkbox" name={checkboxName} id={checkboxName}  onChange={this.handleCheckbox} checked={!!checked} className={checkboxClass}  />
                    <label className={labelClass} htmlFor={checkboxName}>{labelText}</label>
                </div>
                {!!checked && this.props.children}  
            </React.Fragment>
        )
    }
}

CheckboxContainer.propTypes = {
    checkboxName: PropTypes.string.isRequired,
    onCheckChanged: PropTypes.func,
    checked: PropTypes.bool,
    labelText: PropTypes.string.isRequired,
    divClass: PropTypes.string,
    checkboxClass: PropTypes.string,
    labelClass: PropTypes.string
}

CheckboxContainer.defaultProps = {
    checked: false,
    divClass: 'spc--bottom--tny',
    checkboxClass: 'checkbox',
    labelClass: 'label'
}