import moment from 'moment';
import { isEmpty, get, has, toLower } from 'lodash';
import { addressValidation } from './address.validation';
import { ascii, maxLength, digitLength, addressPOBox, phoneNumber, ownershipTypeCorpName, email } from './validators';

const outputFormat = process.env.REACT_APP_DISPLAY_DATE_FORMAT;
function matchLength(val, regex, lengthToMatch) {
    if (isEmpty(val)) return true;
    const match = val.match(regex);
    if (!match) return false;
    return match.length === lengthToMatch;
}

const isNumeric = val => val ?  /^\d+$/.test(val) : true;

const requireSecondaryBankInfo = (val, ctx) => has(ctx, 'enableSecondaryBankAccountInfo') ? !(ctx.enableSecondaryBankAccountInfo && (!!val === false)) : true;
const requireAlternateBankInfo = (val, ctx) => has(ctx, 'enableAlternativeBankAccountInfo') ? !(ctx.enableAlternativeBankAccountInfo && (!!val === false)) : true;
const phonenumber = val => matchLength(val, /\d/g, 10);
const taxid = val => matchLength(val, /\d/g, 9);
const esanumber = (val, ctx) => toLower(get(ctx, 'amexDetails.status', '')) === 'existing' ? !!val : true;
const esanumberLength = (val, ctx) => toLower(get(ctx, 'amexDetails.status', '')) === 'existing' ? digitLength(10)(val) : true;
const ebtnumber = (val, ctx) => ctx.doesAcceptEbt ? !!val : true;
const dob = val => !val ? true : moment(val, outputFormat).isAfter(moment(new Date(0, 0, 0, 0)));
const date = val => {
    if (!val) {
        return true;
    }
    const value = moment(val).format(outputFormat);

    return moment(value, outputFormat, true).isValid() && dob(value)
};
const is18YearsOld = val => !val ? true : moment(val, outputFormat).isSameOrBefore(moment().subtract(18, 'years'));

export const merchantMPATemplate = {
    dba: {
        use: { ascii },
        type: String,
        required: true,
        message: {
            ascii: '[**DBA**](javascript:void) contains invalid characters',
            required: '[**DBA**](javascript:void) is required',
        },
    },
    contactPhoneNumber: {
        use: { phoneNumber, ascii },
        type: String,
        message: {
            phoneNumber: '[**Contact Phone Number**](javascript:void) is invalid',
            ascii: '[**Contact Phone Number**](javascript:void) contains invalid characters',
            required: '[**Contact Phone Number**](javascript:void) is required',


        },
    },
    businessStartDate: {
        type: String,
        use: { date },
        message: {
            required: '[**Business Start Date**](javascript:void) is required',
            date: '[**Business Start Date**](javascript:void) is invalid',
        },
    },
    businessInformation: {
        businessPhone: {
            type: String,
            use: { phonenumber, ascii },
            message:
            {
                required: '[**Business Phone Number**](javascript:void) is required',
                phonenumber: '[**Business Phone Number**](javascript:void) is invalid',
                ascii: '[**Business Phone Number**](javascript:void) contains invalid characters',
            },
        },
        businessEmail: {
            type: String,
            use: { ascii, email: email(false) },
            message:
            {
                required: '[**Business Email**](javascript:void) is required',
                ascii: '[**Business Email**](javascript:void) contains invalid characters',
                email: '[**Business Email**](javascript:void) is invalid',
            },
        },
        businessAddress: addressValidation('Business ', 64, 64, 'Street '),
        website: {
            use: { ascii, length: maxLength(64) },
            type: String,
            message: {
                ascii: '[**Website**](javascript:void) contains invalid characters',
                length: '[**Website**](javascript:void) is invalid. Maximum number of characters allowed is 64.',
            },
        },
        businessFax: {
            use: { ascii, phoneNumber },
            type: String,
            message: {
                ascii: '[**Business Fax Number**](javascript:void) contains invalid characters',
                phoneNumber: '[**Business Fax Number**](javascript:void) must be 10 characters long',
            },
        },
        ownershipType: {
            use:{ ownershipTypeCorpName: ownershipTypeCorpName('SoleOwnership') },
            type: String,
            message: {
                ownershipTypeCorpName: '[**Ownership Type**](javascript:void) Sole Ownership is not allowed on Incorporated Business or Limited Liability Company',
            }
        },
    },
    corporateName: {
        use: { ascii },
        type: String,
        message: {
            ascii: '[**Corporate Name**](javascript:void) contains invalid characters',
            required: '[**Corporate Name**](javascript:void) is required',
        },
    },
    corporateAddress: addressValidation('Mailing ', 128, 50),
    bankAccountInformation: {
        bankName: {
            use: { ascii, length: maxLength(64) },
            type: String,
            message: {
                required: '[**Bank Name**](javascript:void) is required',
                ascii: '[**Bank Name**](javascript:void) contains invalid characters',
                length: '[**Bank Name**](javascript:void) is invalid. Maximum number of characters allowed is 64.',
            },
        },
        accountNumber: {
            use: { ascii, accountNumber: digitLength(6, 17) },
            type: String,
            message: {
                ascii: '[**Bank Account Number**](javascript:void) contains invalid characters',
                required: '[**Bank Account Number**](javascript:void) is required',
                accountNumber: '[**Bank Account Number**](javascript:void) is invalid',
            },
        },
        routingNumber: {
            use: { ascii, length: maxLength(64) },
            type: String,
            message: {
                required: '[**Bank Routing Number**](javascript:void) is required',
                ascii: '[**Bank Routing Number**](javascript:void) is required',
                length: '[**Bank Routing Number**](javascript:void) is invalid. Maximum number of characters allowed is 64.',
            },
        },
        secondaryBankName: {
            use: { secondaryBankAccountInformationRequired: requireSecondaryBankInfo},
            type: String,
            message: {
                secondaryBankAccountInformationRequired: '[**Secondary Bank Name**](javascript:void) is required',
            }
        },
        secondaryAccountNumber: {
            use: { secondaryBankAccountInformationRequired: requireSecondaryBankInfo },
            type: String,
            message: {
                secondaryBankAccountInformationRequired: '[**Secondary Bank Account Number**](javascript:void) is required'
            }
        },
        secondaryRoutingNumber: {
            use: { secondaryBankAccountInformationRequired: requireSecondaryBankInfo },
            type: String,
            message: {
                secondaryBankAccountInformationRequired: '[**Secondary Bank Routing Number**](javascript:void) is required'
            }
        },    
        alternateBankName: {
            use: { alternativeBankAccountInformationRequired: requireAlternateBankInfo },
            type: String,
            message: {
                alternativeBankAccountInformationRequired: '[**Alternative Bank Name**](javascript:void) is required'
            }
        },
        alternateAccountNumber: {
            use: { alternativeBankAccountInformationRequired: requireAlternateBankInfo },
            type: String,
            message: {
                alternativeBankAccountInformationRequired: '[**Alternative Bank Account Number**](javascript:void) is required'
            }
        },
        alternateRoutingNumber: {
            use: { alternativeBankAccountInformationRequired: requireAlternateBankInfo },
            type: String,
            message: {
                alternativeBankAccountInformationRequired: '[**Alternative Bank Routing Number**](javascript:void) is required'
            }
        }

    },
    signerInformation: {
        firstName: {
            use: { ascii, length: maxLength(64) },
            type: String,
            message: {
                required: '[**Signer First Name**](javascript:void) is required',
                ascii: '[**Signer First Name**](javascript:void) contains invalid characters',
                length: '[**Signer First Name**](javascript:void) is invalid. Maximum number of characters allowed is 64.',
            }
        },
        email: {
            type: String,
            message: {
                required: '[**Signer First Name**](javascript:void) is required',
            }
        },
        lastName: {
            use: { ascii, length: maxLength(64) },
            type: String,
            message: {
                required: '[**Signer Last Name**](javascript:void) is required',
                ascii: '[**Signer Last Name**](javascript:void) contains invalid characters',
                length: '[**Last Name**](javascript:void) is invalid. Maximum number of characters allowed is 64.',
            }
        },
        address: {
            ...addressValidation('', 64, 32, 'Street '),
            streetAddress: {
                use: { ascii, length: maxLength(64), addressPOBox },
                type: String,
                message: {
                    required: `[**Street Address**](javascript:void) is required`,
                    ascii: `[**Street Address**](javascript:void) contains invalid characters`,
                    length: `[**Street Address**](javascript:void) is invalid. Maximum number of characters allowed is 64.`,
                    addressPOBox: `[**Street Address**](javascript:void) cannot be a PO Box address`,
                }
            },
        },
        phoneNumber: {
            type: String,
            use: { phonenumber, ascii },
            message:
            {
                required: '[**Phone Number**](javascript:void) is required',
                phonenumber: '[**Phone Number**](javascript:void) is invalid',
                ascii: '[**Phone Number**](javascript:void) contains invalid characters',
            }
        },
        cellPhoneNumber: {
            use: { phoneNumber, ascii },
            type: String,
            message: {
                phoneNumber: '[**Signer Cell Number**](javascript:void) is invalid',
                ascii: '[**Signer Cell Number**](javascript:void) contains invalid characters'
            },
        },
        socialSecurityNumber: {
            type: String,
            use: { taxid, ascii },
            message: {
                required: '[**Social Security Number**](javascript:void) is required',
                taxid: '[**Social Security Number**](javascript:void) is invalid',
                ascii: '[**Social Security Number**](javascript:void) contains invalid characters',
            },
        },
        dateOfBirth: {
            type: Date,
            use: { dob, is18YearsOld },
            message: {
                required: '[**Date of Birth**](javascript:void) is required',
                type: '[**Date of Birth**](javascript:void) is invalid',
                dob: '[**Date of Birth**](javascript:void) is required',
                is18YearsOld: '[**Signer**](javascript:void) must be at least 18 years old'
            }
        },
    },
    goodsOrServicesDescription: {
        type: String,
        use: { length: maxLength(64), ascii },
        message: {
            required: '[**Description Of Goods**](javascript:void) is required',
            length: '[**Description Of Goods**](javascript:void) is invalid. Maximum number of characters allowed is 64.',
            ascii: '[**Description Of Goods**](javascript:void) contains invalid characters',
        },
    },
    taxID: {
        type: String,
        use: { taxid, ascii },
        message: {
            required: '[**Tax ID**](javascript:void) is required',
            taxid: '[**Tax ID**](javascript:void) is invalid',
            ascii: '[**Tax ID**](javascript:void) contains invalid characters',
        }
    },
    amexDetails:{
        esaNumber: {
            type: String,
            use: { esanumber, esanumberLength },
            message: {
                esanumber: '[**Amex ESA Number**](javascript:void) is required',
                esanumberLength:'[**Amex ESA Number**](javascript:void) must be 10 digits'
            }
        },
    },
    doesAcceptEbt: {
        type: Boolean,
        required: false
    },
    ebtNumber: {
        type: String,
        use: { ebtnumber, length: maxLength(10), isNumeric },
        message: {
            ebtnumber: '[**EBT Number**](javascript: void) is required',
            length: '[**EBT Number**](javascript:void) is invalid. Maximum number of characters allowed is 10.',
            isNumeric: '[**EBT Number**](javascript:void) must be numeric.',
        },
    },
    accountNotes: {
        use: { ascii, length: maxLength(1500) },
        type: String,
        message: {
            ascii: '[**Other Notes**](javascript:void) contain invalid characters',
            length: '[**Other Notes**](javascript:void) are invalid. Maximum number of characters allowed is 1500.',
        },
    },
}


