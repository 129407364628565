import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Dropzone from 'react-dropzone';
import { isEmpty, map, memoize } from 'lodash';
import { fileTypes, getFileTypeExtensions } from "./fileTypeConstants";

class ImageDropzoneComponent extends React.Component {
    handleDrop = (tag) => (acceptedFiles) => {
        this.props.onDrop(tag, acceptedFiles);
    }

    fileTypeExtensions = memoize(getFileTypeExtensions);

    render() {
        const { fileList, tag, validateFileSize } = this.props;
        const maxSize = 10485760// 5 MB // 10 = 10485760

        return (
            <Dropzone
                accept={this.fileTypeExtensions(this.props.allowedFileTypes)}
                multiple={false}
                onDrop={this.handleDrop(tag)}
                minSize={0}
                disabled={this.props.disabled || (fileList && fileList.length > 0)}
                maxSize={maxSize}>
                {({ getRootProps, getInputProps, isDragActive, isDragReject, rejectedFiles }) => {
                    const isFileTooLarge = validateFileSize && rejectedFiles && rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;
                    return (
                        <div {...getRootProps()} className="file-upload__container"
                        >
                            {fileList && fileList.length > 0 ?
                                <div className="file-upload__preview">
                                    <img src={URL.createObjectURL(fileList[0].file)} alt="icon" />
                                    <button className="file-upload__delete-btn" onClick={this.props.onRemoveFile}>
                                        <i className="icon icon--micro icon--delete--white"></i>
                                    </button>
                                </div> :
                                <Fragment>
                                    <input {...getInputProps()} />

                                    {!isDragActive && (
                                        <div className="file-upload__btn">
                                            <div className="file-upload__btn__icon">
                                                <i className='icon icon--sml icon--upload' />
                                            </div>
                                            <p className="type--wgt--medium">Choose a file or drag it here</p>
                                            <p className="type--wgt--light">Max 10MB file (png, jpeg)</p>
                                        </div>
                                    )}
                                    {isDragActive && !isDragReject && (
                                        <div className="file-upload__btn file-upload__drop">
                                            <p className="type--wgt--medium">Drop file here</p>
                                        </div>
                                    )}
                                    {isDragReject && (
                                        <div className="file-upload__btn file-upload__drop--invalid">
                                            <p className="type--wgt--medium">File type not accepted, sorry!</p>
                                        </div>
                                    )}
                                    {(!isEmpty(rejectedFiles) && !isFileTooLarge) && (
                                        <div className="spc--top--tny">
                                            <p className="type--color--warning type--wgt--medium">File type not accepted, sorry!</p>
                                        </div>
                                    )}
                                    {isFileTooLarge && (
                                        <div className="file-upload__btn file-upload__drop--invalid">
                                            <p className="type--wgt--medium">File is too large.</p>
                                        </div>
                                    )}
                                </Fragment>
                            }
                        </div>
                    )
                }}
            </Dropzone>
        );
    }
}

ImageDropzoneComponent.propTypes = {
    onRemoveFile: PropTypes.func,
    onDrop: PropTypes.func.isRequired,
    fileList: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array
    ]),
    tag: PropTypes.string,
    validateFileSize: PropTypes.bool,
    disabled: PropTypes.bool,
    allowedFileTypes: PropTypes.array
};

ImageDropzoneComponent.defaultProps = {
    tag: '',
    disabled: false,
    validateFileSize: true,
    allowedFileTypes: map(fileTypes, (val, key) => val)
}

export default ImageDropzoneComponent;