import { some, toUpper, toLower } from 'lodash';
import { principalService } from '../../services';

const specialCharactersRegex = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

export const mapPasswordRequirements = password => {
	const requirements = [];

	if (password.length < 8) {
		requirements.push('8 characters');
	}

	if (!some(password, char => char === toUpper(char))) {
		requirements.push('1 uppercase character');
	}

	if (!some(password, char => char === toLower(char))) {
		requirements.push('1 lowercase character');
	}

	if (!some(password, char => !isNaN(parseInt(char)))) {
		requirements.push('1 number');
	}

	if (!specialCharactersRegex.test(password)) {
		requirements.push('1 special character');
	}

	return requirements;
};

export const getRedirectRoute = () => {
	const principal = principalService.get();
	return some(principal.roles, role => role !== "Residuals") ? "/merchants" : "/residuals";
}
