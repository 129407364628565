import React, { Component, Fragment } from 'react';
import { map, isEmpty } from 'lodash';
import { string } from 'prop-types';

import { Toggle, ToggleContainer } from './../../common/components/toggle';
import { appService } from '../../services';
import { withLoader } from '../../common/components';
import GeneralInformation from '../ReviewPricingSummary/general-information';
import PricingBreakdown from '../ReviewPricingSummary/pricing-breakdown';
import ProfitBreakdown from '../ReviewPricingSummary/profit-breakdown';
import AdditionalNotes from '../ReviewPricingSummary/additional-notes';
import AccountRecap from '../ReviewPricingSummary/account-recap';

const isExpand = true;

const tabs = [
    {
        key: 'General',
        label: 'General',
    },
    {
        key: 'CurrentPricingBreakdown',
        label: 'Current Pricing Breakdown',
    },
    {
        key: 'ProfitBreakdown',
        label: 'Profit Breakdown',
    },
    {
        key: 'AdditionalNotes',
        label: 'Additional Notes',
    },
    {
        key: 'AccountRecap',
        label: 'Account Recap',
    },
];

class ReviewPricingResults extends Component {
    constructor() {
        super();

        this.state = {
            selectedTab: 'General',
            summary: null,
        };
    };

    componentDidMount() {
        const leadId  = this.props.leadId;
            this.props.showLoader(true);

            appService.getReviewPricingSummary(leadId)
                .then(
                    lead => {
                        this.props.showLoader(false);
                        this.setState({ summary: lead });
                    }
                ).catch(err => {
                    console.log('getReviewPricingSummary error', err);
                    this.props.showLoader(false);
                    this.setState({ errorMessage: 'An error occurred: ' + err });
                });
        }

    handleTabChange = selectedTab => {
        this.setState({ selectedTab });
    };

    renderSelectedTab = () => (
        <div>
            {this[`render${this.state.selectedTab}`]()}
        </div>
    );

    renderAccountRecap = () => {
        const { summary: { accountInformation } } = this.state;

        return !isEmpty(accountInformation) && <AccountRecap hideTitle={true} wrapperClass="spc--top--sml" accountInfo={accountInformation} />;
    };

    renderAdditionalNotes = () => {
        const { summary } = this.state;

        return !isEmpty(summary) && <AdditionalNotes hideTitle={true} wrapperClass="spc--top--sml" notes={summary} />
    };

    renderProfitBreakdown = () => {
        const { summary: { profitBreakdown } } = this.state;

        return !isEmpty(profitBreakdown) && <ProfitBreakdown hideTitle={true} wrapperClass="spc--top--sml" profit={profitBreakdown} />;
    };

    renderCurrentPricingBreakdown = () => {
        const { summary: { pricingBreakdown } } = this.state;

        return !isEmpty(pricingBreakdown) && <PricingBreakdown hideTitle={true} wrapperClass="spc--top--sml" pricing={pricingBreakdown} />
    };

    renderGeneral = () => !isEmpty(this.state.summary) && <GeneralInformation hideTitle={true} wrapperClass="spc--top--sml" lead={this.state.summary} />;

    render() {
        const { selectedTab, summary } = this.state;

        return !isEmpty(summary) && (
            <ToggleContainer>
                <Toggle initialToggle={isExpand}>
                    {({ isToggled, handleToggle }) => (
                        <Fragment>
                            <div className="card--primary card--sml card--primary--popup spc--bottom--med">
                                <div
                                    className={`flex--primary ${
                                        isToggled ? "spc--bottom--med" : ""
                                    }`}
                                >
                                    <h6>Pricing Analysis Results</h6>
                                    <i
                                        className={`spc--left--auto cursor--pointer icon icon--arrow icon--tiny icon--arrow--down--primary card--expand ${
                                            isToggled ? "rotate--180" : ""
                                        }`}
                                        onClick={handleToggle}
                                    ></i>
                                </div>
                                <div
                                    className={`details__content ${
                                        isToggled ? "" : "display--n"
                                    }`}
                                >
                                    <ul className="tabs--primary">
                                        {map(tabs, ({ key, label }) => {
                                            const isSelected = key === selectedTab;
                                            return (
                                                <li className={`tabs--primary__item type--sml--alt${isSelected ? ' is-active' : ''}`} key={key} onClick={() => this.handleTabChange(key)}>{label}</li>
                                            )
                                        })}
                                    </ul>
                                    {this.renderSelectedTab()}
                                </div>
                            </div>
                        </Fragment>
                    )}
                </Toggle>
            </ToggleContainer>
        );
    };
};

ReviewPricingResults.propTypes = {
    leadId: string,
};

export default withLoader(ReviewPricingResults)