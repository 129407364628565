import { get, noop } from 'lodash';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { modalNames } from '../../../common/components/modal-wrapper';
import { BaseGridActionsComponent } from '../../../common/formatters';

class MerchantGridActionsComponent extends BaseGridActionsComponent {
    static instances = [];
    constructor(props) {
		super(props, MerchantGridActionsComponent.instances);

        this.menuItems = [
            {
                isVisible: true, 
                title: 'Drop in as merchant', 
                onClickHandler: noop,
                iconClassName: '', 
                href: `${process.env.REACT_APP_MERCHANT_PORTAL_URL}/login?dropinDBA=${encodeURIComponent(this.props.row.dba)}`
            },
            {
                isVisible: true,
                title: () => `${get(this.props, 'dependentValues.isHidden', false) ? 'Unhide' : 'Hide'} merchant`, 
                onClickHandler: this.handleIsHiddenChange, 
                iconClassName: '', 
                href: ''
            },
            {
                isVisible: true, 
                title: 'Clone App', 
                onClickHandler: this.cloneApp, 
                iconClassName: '', 
                href: ''
            },
        ]
	}

    handleIsHiddenChange = async  () =>{
        const {appId, isHidden, hide, unhide, refetchData} = this.props.dependentValues;
        this.closeDisplayMenu(()=> {
            if(isHidden){
                unhide(appId);
            }else{
                hide(appId);
            }
            refetchData();
        });
    }

    cloneApp = () => {
        const { dependentValues: { openCloseModal }, row } = this.props;
        this.closeDisplayMenu();
        openCloseModal({
            name: modalNames.cloneEApp,
            data: {
                data: row,
                appType:'App'
            },
        })
    };

}

MerchantGridActionsComponent.propTypes = {
    dependentValues: PropTypes.shape({
        appId: PropTypes.number,
    }),
    isScrolling: PropTypes.bool,
};

export default withRouter(MerchantGridActionsComponent);
