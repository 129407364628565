import React, { createRef } from 'react';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import { withLoader } from '../../common/components';
import withBlock from '../../common/components/block/block-hoc';
import { IdentificationTypeDropdownComponent } from '../../common/components/identification-type-dropdown';
import { CanadaMerchantMPATemplate, CanadaMPAInvalidTemplate, CanadaMPARequiredTemplate } from '../../validation';
import BaseMPA from './BaseMPA';
import withMerchantMpa from './withMerchantMpa';
import withMpa from './withMpa';
import SingleDatePicker from '../../common/filters/date-picker/single-date-picker';

class CanadaMPA extends BaseMPA {
	identificationIssueDateRef = createRef();
	identificationExpirationDateRef = createRef();

	renderRoutingNumber(mpa) {
		return (
			<div className="col col-sml-12">
				<div className="row ">
					{this.renderField(
						mpa,
						'bankAccountInformation.institutionNumber',
						'Institution Number',
						{ maxLength: 3 },
						'fs-mask'
					)}
					{this.renderField(mpa, 'bankAccountInformation.transitNumber', 'Transit Number', { maxLength: 5 }, 'fs-mask')}
				</div>
			</div>
		);
	}

	renderAdditionalPaymentDetails(mpa, isToggled, handleToggle) {
		return (
			<div className={`card${isToggled ? ' is-expanded' : ''} spc--bottom--lrg`}>
				{this.renderCardHeader('Additional Payment Details', isToggled, handleToggle)}
				{isToggled && (
					<div className="card__body">
						<div className="row">{this.renderAmexEsa(mpa)}</div>
					</div>
				)}
			</div>
		);
	}

	renderIdentificationInfo(mpa) {
		return (
			<div className="col col-sml-12">
				<div className="row">
				<p className="col col-sml-12 type--p2 type--p2--medium spc--bottom--lrg">ID Document #1</p>
					<div className="col col-sml-12 col-med-6 form__group">
						<div className="form__group__header">
							<p className="form__group__label">Identification Type</p>
							{this.props.requiredFields['signerInformation.identificationInformation.identificationType'] && (
								<span className="form__group__required" data-tooltip="Required">
									*
								</span>
							)}
						</div>
						<IdentificationTypeDropdownComponent
							className={`input input--med input--select${this.props.validateField(
								'signerInformation.identificationInformation.identificationType'
							)}`}
							id="signerInformation_identificationInformation_identificationType"
							value={mpa.signerInformation.identificationInformation.identificationType}
							onChange={this.props.isMerchant ? this.props.handleChange : this.props.handleSignerInformationChange}
							disabledValue={mpa.signerInformation.identificationInformation2.identificationType}
						/>
					</div>
					<div className="col col-sml-12 col-med-6 form__group">
						<div className="form__group__header">
							<p className="form__group__label">Identification Number</p>
							{this.props.requiredFields['signerInformation.identificationInformation.identificationNumber'] && (
								<span className="form__group__required" data-tooltip="Required">
									*
								</span>
							)}
						</div>
						<input
							type="text"
							className={`input input--med${this.props.validateField('signerInformation.identificationInformation.identificationNumber')}`}
							name="signerInformation_identificationInformation_identificationNumber"
							value={mpa.signerInformation.identificationInformation.identificationNumber}
							onChange={this.props.isMerchant ? this.props.handleChange : this.props.handleSignerInformationChange}
						/>
					</div>
					{this.renderIdentificationIssueDate(mpa, this.props.requiredFields['signerInformation.identificationInformation.identificationIssueDate'], 'identificationInformation')}
					{this.renderIdentificationExpirationDate(mpa, this.props.requiredFields['signerInformation.identificationInformation.identificationExpirationDate'], 'identificationInformation')}
					
					<p className="col col-sml-12 type--p2 type--p2--medium spc--bottom--lrg">ID Document #2</p>
					<div className="col col-sml-12 col-med-6 form__group">
						<div className="form__group__header">
							<p className="form__group__label">Identification Type</p>
							{this.props.requiredFields['signerInformation.identificationInformation2.identificationType'] && (
								<span className="form__group__required" data-tooltip="Required">
									*
								</span>
							)}
						</div>
						<IdentificationTypeDropdownComponent
							className={`input input--med input--select${this.props.validateField(
								'signerInformation.identificationInformation2.identificationType'
							)}`}
							id="signerInformation_identificationInformation2_identificationType"
							value={mpa.signerInformation.identificationInformation2.identificationType}
							onChange={this.props.isMerchant ? this.props.handleChange : this.props.handleSignerInformationChange}
							disabledValue={mpa.signerInformation.identificationInformation.identificationType}
						/>
					</div>
					<div className="col col-sml-12 col-med-6 form__group">
						<div className="form__group__header">
							<p className="form__group__label">Identification Number </p>
							{this.props.requiredFields['signerInformation.identificationInformation2.identificationNumber'] && (
								<span className="form__group__required" data-tooltip="Required">
									*
								</span>
							)}
						</div>
						<input
							type="text"
							className={`input input--med${this.props.validateField('signerInformation.identificationInformation2.identificationNumber')}`}
							name="signerInformation_identificationInformation2_identificationNumber"
							value={mpa.signerInformation.identificationInformation2.identificationNumber}
							onChange={this.props.isMerchant ? this.props.handleChange : this.props.handleSignerInformationChange}
						/>
					</div>
					{this.renderIdentificationIssueDate(mpa, this.props.requiredFields['signerInformation.identificationInformation2.identificationIssueDate'], 'identificationInformation2')}
					{this.renderIdentificationExpirationDate(mpa, this.props.requiredFields['signerInformation.identificationInformation2.identificationExpirationDate'], 'identificationInformation2')}
				</div>
			</div>
		);
	}

	renderIdentificationIssueDate(mpa, required, field) {
		return (
			<div id={`signerInformation_${field}_identificationIssueDate_div`} className="col col-sml-12 col-med-6 form__group">
				<div className="form__group__header">
					<p className="form__group__label">Identification Issue Date</p>
					{required && <span className="form__group__required">*</span>}
					<i
						className="icon icon--tny icon--regular--info datatooltip--right datatooltip--200"
						data-tooltip={'Issue Date must precede Expiration Date'}
					></i>
				</div>
				<SingleDatePicker
					name={`signerInformation_${field}_identificationIssueDate`}
					onChange={date =>{
						const handler = this.props.isMerchant ? this.props.handleChange : this.props.handleSignerInformationChange
						handler({ target: { name: `signerInformation_${field}_identificationIssueDate`, value: date } })
						}
					}
					validateField={() => this.props.validateField(`signerInformation.${field}.identificationIssueDate`)}
					value={get(mpa, `signerInformation.${field}.identificationIssueDate`, '')}
					disabledDays={{before: null, after: null}}
				/>
			</div>
		);
	}

	renderIdentificationExpirationDate(mpa, required, field) {
		return (
			<div className="col col-sml-12 col-med-6 form__group">
				<div id={`signerInformation_${field}_identificationExpirationDate_div`} className="form__group__header">
					<p className="form__group__label">Identification Expiration Date </p>
					{required && (
						<span className="form__group__required" data-tooltip="Required">
							*
						</span>
					)}
				</div>
				<SingleDatePicker
					name={`signerInformation_${field}_identificationExpirationDate`}
					onChange={date =>{
							const handler = this.props.isMerchant ? this.props.handleChange : this.props.handleSignerInformationChange
							handler({ target: { name: `signerInformation_${field}_identificationExpirationDate`, value: date }})
						}
					}
					validateField={() => this.props.validateField(`signerInformation.${field}.identificationExpirationDate`)}
					value={get(mpa, `signerInformation.${field}.identificationExpirationDate`, '')}
					disabledDays={{before: null, after: null}}
				/>
			</div>
		);
	}
	renderSignerAgreement(mpa) {
		const checked = get(mpa, 'signerInformation.signerAgreement')
		return (
			<div className="input--check--enable-form form__group__header" id="signerInformation_signerAgreement_div">
							<input
					id="signerAgreement"
					type="checkbox"
					className="input--check form__group__header"
					name="signerInformation_signerAgreement"
					onChange={this.props.handleChange}
					checked={checked}
							/>
				<label htmlFor="signerAgreement">I certify that the signer listed above is listed on the business' corporate documents.</label>
				<span className="form__group__required" data-tooltip="Required">*</span>
						</div>)
			
	}
	renderSocialSecurityNumberLabel() {
		return 'Social Insurance Number';
	}
}

export const CanadaAgentMPA = withBlock(
	withLoader(
		withMpa(CanadaMPA, CanadaMPAInvalidTemplate, CanadaMPARequiredTemplate, {
			'bankAccountInformation.institutionNumber': true,
			'bankAccountInformation.transitNumber': true,
		})
	)
);
export const CanadaMerchantMPA = withRouter(withLoader(withMerchantMpa(CanadaMPA, CanadaMerchantMPATemplate)));
