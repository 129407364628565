import React from 'react';
import PropTypes from 'prop-types';
import { toLower, get } from 'lodash';
import { withExpander } from "./../../common/components";
import { isFd, isGoPlus, isAchq, renderIf } from "./../../common/utilities";
import { MerchantStatusComponent } from "../MerchantGrid/formatters";
import { DisplayDateComponent, DisplayBooleanComponent } from "../../common/formatters";
import { modalNames } from './../../common/components/modal-wrapper';
import { getProcessorDisplayName } from '../../common/utilities/processorDisplayName';

class AccountSummary extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modal: {
                name: modalNames.none,
                data: null
            }
        };
    }

    renderInput(name, value, isEditable, labelClass, inputClass, LabelComponent) {
        if (isEditable) {
            return (
                <input type="text" className={inputClass} id={name} name={name} value={value} onChange={this.props.onChange} onBlur={this.props.onBlur}/>
            );
        }
        return (
            <label className={labelClass} name={name} id={name}>{LabelComponent ? <LabelComponent value={value} /> : value}</label>
        );
    }

    renderBaseField(name, labelText, value, isEditable, labelClass, inputClass, LabelComponent) {
        return (
            <div className="row row-align-middle spc--bottom--med">
                <div className="col col-sml-12 col-med-6 col-lrg-4">
                    <label className="label" htmlFor={name}>{labelText}: </label>
                </div>
                <div className="col col-sml-12 col-med-6 col-lrg-8">
                    {this.renderInput(name, value, isEditable, labelClass, inputClass, LabelComponent)}
                </div>
            </div>
        )
    }

    renderField(name, labelText, key, isEditable = false, labelClass = 'label--value', inputClass = 'input input--med', LabelComponent = null) {
        const data = isEditable ? this.props.merchantDetails : this.props.merchant;
        return this.renderBaseField(name, labelText, get(data, key), isEditable, labelClass, inputClass, LabelComponent);
    }

    renderBooleanField(name, labelText, key) {
        return this.renderField(name, labelText, key, undefined, undefined, undefined, DisplayBooleanComponent);
    }

    renderAdditionalEmail() {
        const { isEditDetails, merchant, merchantDetails } = this.props;
        if (isEditDetails) return (
            <div className="row row-align-middle spc--bottom--med">
                <div className="col col-sml-6 col-lrg-4">
                    <label className="label" htmlFor="merchantDetails_alternateEmail">Additional Email: </label>
                </div>
                <div className="col col-sml-6 col-lrg-8">
                    <input type="text" className="input input--med" id="merchantDetails_alternateEmail" name="merchantDetails_alternateEmail" value={merchantDetails.alternateEmail} onChange={this.props.onChange} onBlur={this.props.onBlur} />
                </div>
            </div>
        );
        return merchant.alternateEmail && (
            <div className="row row-align-middle spc--bottom--med">
                <div className="col col-sml-12 col-med-6 col-lrg-4">
                    <label className="label" htmlFor="lblAdditionalEmail">Additional Email: </label>
                </div>
                <div className="col col-sml-12 col-med-6 col-lrg-8">
                    <label className="label--value" name="lblAdditionalEmail" id="lblAdditionalEmail" >{merchant.alternateEmail}</label>
                </div>

            </div>
        )
    }

    render() {
        const { merchant, isExpanded, isEditDetails } = this.props;
        return (
            <div className="card--primary card--sml">
                <div className="card--primary__header is-expanded">
                    <h6 className="spc--bottom--sml">Account Summary</h6>
                    {isEditDetails ? (
                        <div>
                            <button className="btn btn--sml btn--ghost spc--right--tny" onClick={this.props.onCancel}>Cancel</button>
                            <button className="btn btn--sml btn--primary spc--bottom--sml" onClick={this.props.onSave}>Save</button>
                        </div>
                    ) : (
                        <button className="btn btn--sml btn--primary spc--bottom--sml" onClick={this.props.onEdit}>Edit</button>
                    )}
                </div>
                <div className="details__content">
                    <div className="row grid-align-middle">
                        <div className="col col-sml-12 col-med-6">
                            {this.renderField("lblMID", "MID", 'mid')}
                            {merchant.shouldShowTerminalId && this.renderField("lblTID", "TID", 'terminalId')}
                            {this.renderField("lblDBA", "DBA", 'dba')}

                            <div className="row row-align-middle spc--bottom--med">
                                <div className="col col-sml-12 col-med-6 col-lrg-4">
                                    <label className="label" htmlFor="lblStatus">Status: </label>
                                </div>
                                <div className="col col-sml-12 col-med-6 col-lrg-8">
                                    <label className="label--value" name="lblStatus" id="lblStatus" >
                                        <MerchantStatusComponent value={merchant.statusDisplayName} dependentValues={{ statusTitle: merchant.statusTitle, statusDisplayName: merchant.statusDisplayName }} />
                                    </label>
                                </div>
                            </div>

                            {this.renderBaseField("lblProcessor", "Processor", getProcessorDisplayName(merchant.processorName), false, 'label--value')}
                            {this.renderField("lblFrontEnd", "Front End", 'frontEndProcessor')}
                            {!isGoPlus(merchant) && !isAchq(merchant) && this.renderBooleanField("lblPCI", "PCI Compliant", 'pciCompliance.isCompliant')}
                        </div>
                        <div className="col col-sml-12 col-med-6">
                            {this.renderField("merchantDetails_businessContactName", "Main Contact", 'businessContactName', isEditDetails)}
                            {this.renderField("lblAddress", "Address", 'businessAddress.streetAddress')}
                            {this.renderField("merchantDetails_phoneNumber", "Phone", 'phoneNumber', isEditDetails)}
                            {this.renderField("merchantDetails_businessEmail", "Email", 'businessEmail', isEditDetails, 'label--value type--break--word')}
                            {this.renderAdditionalEmail()}
                            {isFd(merchant) && this.renderBooleanField("lblIsEidsEnrolled", "EIDS", 'isEidsEnrolled')}
                        </div>
                    </div>
                    {renderIf(isExpanded)(
                        <React.Fragment>
                            <div className="separator--grey1 spc--bottom--med"></div>
                            <div className="row">
                                <div className="col col-sml-12 col-med-6">
                                    {this.renderField("lblAgent", "Rep 1", 'agentName')}
                                    {this.renderField("lblTelemarketer", "Rep 2", 'telemarketerName')}
                                    {this.renderField("lblAppId", "App ID", 'appId')}

                                    <div className="row row-align-middle spc--bottom--med">
                                        <div className="col col-sml-6 col-lrg-4">
                                            <label className="label" htmlFor="lblApprovedDate">Approval Date: </label>
                                        </div>
                                        <div className="col col-sml-6 col-lrg-8">
                                            <label className="label--value" name="lblApprovedDate" id="lblApprovedDate" ><DisplayDateComponent value={merchant.dateApproved} /></label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-sml-12 col-med-6">
                                    <div className="row row-align-middle spc--bottom--med">
                                        <div className="col col-sml-6 col-lrg-4">
                                            <label className="label" htmlFor="lblSubmittedDate">Date Submitted: </label>
                                        </div>
                                        <div className="col col-sml-6 col-lrg-8">
                                            <label className="label--value" name="lblSubmittedDate" id="lblSubmittedDate" ><DisplayDateComponent value={merchant.dateSigned} /></label>
                                        </div>
                                    </div>
                                    <div className="row row-align-middle spc--bottom--med">
                                        <div className="col col-sml-6 col-lrg-4">
                                            <label className="label" htmlFor="lblSignedDate">Date Signed: </label>
                                        </div>
                                        <div className="col col-sml-6 col-lrg-8">
                                            <label className="label--value" name="lblSignedDate" id="lblSignedDate" ><DisplayDateComponent value={merchant.dateSigned} /></label>
                                        </div>
                                    </div>
                                    <div className="row row-align-middle spc--bottom--med">
                                        <div className="col col-sml-6 col-lrg-4">
                                            <label className="label" htmlFor="lblTaxable">Taxable: </label>
                                        </div>
                                        <div className="col col-sml-6 col-lrg-8">
                                            <label className="label--value" name="lblTaxable" id="lblTaxable" >N/A</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </div>

            </div>
        );
    }
}

AccountSummary.propTypes = {
    merchant: PropTypes.object,
    merchantDetails: PropTypes.object,
    isExpanded: PropTypes.bool,
    isEditDetails: PropTypes.bool,
    onBlur: PropTypes.func,
    onEdit: PropTypes.func,
    onSave: PropTypes.func,
    onChange: PropTypes.func,
    onCancel: PropTypes.func,
    openCloseModal: PropTypes.func,
};

export default withExpander(AccountSummary);