import React, {Component} from 'react';
import PropTypes from 'prop-types';

const ExcelColumn = {
    name: PropTypes.node.isRequired,
    key: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    filterable: PropTypes.bool
};

class StringFilterComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterTerm: ''
        };
    }

    componentDidMount() {
        const columnKey = this.props.column.key;
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const value = params.get(`_${columnKey}`);
        if (value) {
            this.setState({filterTerm: value});
            this.props.onChange({filterTerm: value, column: this.props.column});
        }
    }

    clearFilters = () => {
        const e = {
            target: {
                value: undefined
            }
        };
        this.handleChange(e);

        this.setState({
            filterTerm: ''
        });
    };

    handleChange = e => {
        let val = e.target.value;
        this.setState({filterTerm: val });
        this.props.onChange({filterTerm: val, column: this.props.column});
    };

    renderInput = () => {
        if (this.props.column.filterable === false) {
            return <span/>;
        }
        let inputKey = 'header-filter-' + this.props.column.key;
        return (
            <input key={inputKey} type="text" className="transaction-grid-inline-filter filter-string form-control input input--med" placeholder="" value={this.state.filterTerm} onChange={this.handleChange}/>
        );
    };

    render() {
        return (
            <div>
                <div className="form-group">
                    {this.renderInput()}
                </div>
                {this.props.column.filterable === true && (this.state.filters || this.state.filterTerm) ? (
                    <span className="grid__multiselect__reset" onClick={this.clearFilters}>&times;</span>
                ) : null}
            </div>
        );
    }
}

StringFilterComponent.propTypes = {
    onChange: PropTypes.func.isRequired,
    column: PropTypes.shape(ExcelColumn)
};

export default StringFilterComponent;