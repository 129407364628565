import React, { Component } from 'react';
import { map } from 'lodash';

class ReviewPricingStatusFilterComponent extends Component {
    constructor(props) {
        super(props);
    }

    filterChanged = (event) => {
        let props = this.props;

        let values = props.filter.values;
        
        const key = event.target.name;
        const checked = event.target.checked;

        let valueArr = map(values, (v, k) => {
            return { key: k, value: v };
        });
        
        const objIndex = valueArr.findIndex(o => o.key == key);
        if (objIndex != -1)  valueArr[objIndex].value = checked;
        
        this.props.onFilterChanged({
            id: 'reviewStatus',
            values: valueArr,
            emptyValue: false
        });        
    }

    render() {
        const { filter } = this.props;

        return (
            <div>
                
                <div className="spc--bottom--sml">
                    <input type="checkbox" name="Complete" className="input input--check" checked={filter.values.Complete} value={'Complete'} id="complete" onChange={this.filterChanged}
                    />
                    <label htmlFor="complete">Complete</label>
                </div>
                <div className="spc--bottom--sml">
                    <input type="checkbox" name="NoReview" className="input input--check" checked={filter.values.NoReview} value={'NoReview'} id="NoReview" onChange={this.filterChanged}
                    />
                    <label htmlFor="NoReview">NoReview</label>
                </div>
                <div className="spc--bottom--sml">
                    <input type="checkbox" name="Pending" className="input input--check" checked={filter.values.Pending} value={'Pending'} id="pending" onChange={this.filterChanged}
                    />
                    <label htmlFor="pending">Pending</label>
                </div>
                <div className="spc--bottom--sml">
                    <input type="checkbox" name="Waiting" className="input input--check" checked={filter.values.Waiting} value={'Waiting'} id="waiting" onChange={this.filterChanged}
                    />
                    <label htmlFor="waiting">Waiting</label>
                </div>
                <div className="spc--bottom--sml">
                    <input type="checkbox" name="Incomplete" className="input input--check" checked={filter.values.Incomplete} value={'Incomplete'} id="incomplete" onChange={this.filterChanged}
                    />
                    <label htmlFor="incomplete">Incomplete</label>
                </div>
                <div className="spc--bottom--sml">
                    <input type="checkbox" name="NotSubmitted" className="input input--check" checked={filter.values.NotSubmitted} value={'NotSubmitted'} id="notsubmitted" onChange={this.filterChanged}
                    />
                    <label htmlFor="notsubmitted">NotSubmitted</label>
                </div>
            
            </div>
            );
    }
}

export default ReviewPricingStatusFilterComponent;