import { addressValidation } from './address.validation';
import { ascii, maxLength, digitLength, addressPOBox, ownershipTypeCorpName, phoneNumber, email } from './validators';

const isNumeric = val => val ? /^\d+$/.test(val) : true;
export const MPAInvalidTemplate = {
    businessInformation: {
        businessEmail: {
            type: String,
            use: { ascii, length: maxLength(128), email: email(true) },
            message: {
                ascii: '[**Business Email**](javascript:void) contains invalid characters',
                length: '[**Business Email**](javascript:void) is invalid. Maximum number of characters allowed is 128.',
                email: '[**Business Email**](javascript:void) is invalid.',
            },
        },
        businessPhone: {
            use: { phoneNumber, ascii },
            type: String,
            message: {
                phoneNumber: '[**Business Phone Number**](javascript:void) is invalid',
                ascii: '[**Business Phone Number**](javascript:void) contains invalid characters'

            },
        },
        businessFax: {
            use: { ascii, phoneNumber },
            type: String,
            message: {
                ascii: '[**Business Fax Number**](javascript:void) contains invalid characters',
                phoneNumber: '[**Business Fax Number**](javascript:void) must be 10 characters long',
            },
        },
        website: {
            use: { ascii, length: maxLength(64) },
            type: String,
            message: {
                ascii: '[**Website**](javascript:void) contains invalid characters',
                length: '[**Website**](javascript:void) is invalid. Maximum number of characters allowed is 64.',
            },
        },
        businessAddress: addressValidation('Business ', 64, 64),
        ownershipType: {
            use:{ ownershipTypeCorpName: ownershipTypeCorpName('SoleOwnership') },
            type: String,
            message: {
                ownershipTypeCorpName: '[**Ownership Type**](javascript:void) Sole Ownership is not allowed on Incorporated Business or Limited Liability Company',
            }
        },
    },
    ebtNumber: {
        type: String,
        use: {length: maxLength(10), isNumeric },
        message: {
            length: '[**EBT Number**](javascript:void) is invalid. Maximum number of characters allowed is 10.',
            isNumeric: '[**EBT Number**](javascript:void) must be numeric.',
        },
    },
    corporateName: {
        use: { ascii, length: maxLength(128) },
        type: String,
        message: {
            ascii: '[**Corporate Name**](javascript:void) contains invalid characters',
            length: '[**Corporate Name**](javascript:void) is invalid. Maximum number of characters allowed is 128.'
        },
    },
    contactPhoneNumber: {
        use: { phoneNumber, ascii },
        type: String,
        message: {
            phoneNumber: '[**Contact Phone Number**](javascript:void) is invalid',
            ascii: '[**Contact Phone Number**](javascript:void) contains invalid characters'

        },
    },
    corporateAddress: addressValidation('Mailing ', 128, 50),
    taxID: {
        use: { ascii, digitLength: digitLength(9) },
        type: String,
        message: {
            ascii: '[**Tax ID**](javascript:void) contains invalid characters',
            digitLength: '[**Tax ID**](javascript:void) is invalid',
        },
    },
    dba: {
        use: { ascii },
        type: String,
        message: {
            ascii: '[**DBA**](javascript:void) contains invalid characters',
        },
    },
    signerInformation: {
        firstName: {
            use: { ascii, length: maxLength(64) },
            type: String,
            message: {
                ascii: '[**Signer First Name**](javascript:void) contains invalid characters',
                length: '[**Signer First Name**](javascript:void) is invalid. Maximum number of characters allowed is 64.',
            }
        },
        lastName: {
            use: { ascii, length: maxLength(64) },
            type: String,
            message: {
                ascii: '[**Signer Last Name**](javascript:void) contains invalid characters',
                length: '[**Signer Last Name**](javascript:void) is invalid. Maximum number of characters allowed is 64.',
            }
        },
        address: {
            ...addressValidation('Signer ', 64, 32, 'Home '),
            streetAddress: {
                use: { ascii, length: maxLength(64), addressPOBox },
                type: String,
                message: {
                    ascii: `[**Signer Home Address**](javascript:void) contains invalid characters`,
                    length: `[**Signer Home Address**](javascript:void) is invalid. Maximum number of characters allowed is 64.`,
                    addressPOBox: `[**Signer Home Address**](javascript:void) cannot be a PO Box address`,
                }
            },
        },
        phoneNumber: {
            use: { phoneNumber, ascii },
            type: String,
            message: {
                phoneNumber: '[**Signer Phone Number**](javascript:void) is invalid',
                ascii: '[**Signer Phone Number**](javascript:void) contains invalid characters'
            },
        },
        cellPhoneNumber: {
            use: { phoneNumber, ascii },
            type: String,
            message: {
                phoneNumber: '[**Signer Cell Number**](javascript:void) is invalid',
                ascii: '[**Signer Cell Number**](javascript:void) contains invalid characters'
            },
        },
        socialSecurityNumber: {
            type: String,
            use: { ascii },
            message: {
                ascii: '[**Signer Social Security Number**](javascript:void) contains invalid characters',
            },
        },
    },
    goodsOrServicesDescription: {
        use: { length: maxLength(64), ascii },
        type: String,
        message: {
            ascii: '[**Goods/Services Sold**](javascript:void) contains invalid characters',
            length: '[**Goods/Services Sold**](javascript:void) is invalid. Maximum number of characters allowed is 64.',
        },
    },
    bankAccountInformation: {
        bankName: {
            use: { ascii, length: maxLength(64) },
            type: String,
            message: {
                ascii: '[**Bank Name**](javascript:void) contains invalid characters',
                length: '[**Bank Name**](javascript:void) is invalid. Maximum number of characters allowed is 64.',
            },
        },
        routingNumber: {
            use: { ascii, length: maxLength(64) },
            type: String,
            message: {
                ascii: '[**Bank Routing #**](javascript:void) contains invalid characters',
                length: '[**Bank Routing #**](javascript:void) is invalid. Maximum number of characters allowed is 64.',
            },
        },
        accountNumber: {
            use: { ascii, accountNumber: digitLength(6, 17) },
            type: String,
            message: {
                ascii: '[**Bank Account #**](javascript:void) contains invalid characters',
                accountNumber: '[**Bank Account #**](javascript:void) is invalid',
            },
        },
    },
    accountNotes: {
        use: { ascii, length: maxLength(1500) },
        type: String,
        message: {
            ascii: '[**Other Notes**](javascript:void) contain invalid characters',
            length: '[**Other Notes**](javascript:void) are invalid. Maximum number of characters allowed is 1500.',
        },
    },
}