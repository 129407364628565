import React from 'react';
import { string, bool } from 'prop-types';

class AdditionalNotes extends React.Component {
    render() {
        const { notes, wrapperClass, hideTitle } = this.props;

        return (
            <div className={wrapperClass}>
                <div className="form--popup">   
                    {!hideTitle && <h6 className="spc--bottom--med">Current Pricing Breakdown</h6>}
                    <div className="details__content">
                        <div className="row grid-align-middle">
                            <div className="col col-sml-12 col-med-6">
                                <div className="spc--bottom--med form__field">
                                    <label className="label" htmlFor="lblSicCode">Sic Code</label>
                                    <label className="input input--med input--fake" name="lblSicCode" id="lblSicCode" >{notes.sicCode}</label>
                                </div>
                            </div>
                            <div className="col col-sml-12 col-med-6">
                                <div className="spc--bottom--med form__field">
                                    <label className="label" htmlFor="lblDuesAndAssessments">Dues and Assessments </label>
                                    <label className="input input--med input--fake" name="lblDuesAndAssessments" id="lblDuesAndAssessments" >{notes.duesAndAssessments}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
            
    }
}

AdditionalNotes.defaultProps = {
    wrapperClass: 'card--primary card--sml card--primary--popup',
    hideTitle: false,
};

AdditionalNotes.propTypes = {
    wrapperClass: string,
    hideTitle: bool,
};

export default AdditionalNotes;