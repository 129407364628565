const sectionKeys = {
    merchants: 'merchants',
    leads: 'leads',
    tickets: 'tickets',
    merchanteapp: 'merchanteapp',
    admin: 'admin',
    settings: 'settings',
    validpartner: 'validpartner',
    cobrand: 'cobrand',
};

export default sectionKeys;