import React from 'react';
import { cloneDeep, each, isEmpty } from 'lodash';
import { FileDropzoneComponent } from '../../common/components/file-dropzone';
import { defaultImplicitParse, defaultReactOutput } from "simple-markdown";

import { YesNoRadio } from "../../common/components/yes-no-radio";
import Schema from '../../validation/BaseSchema';
import { reviewPricingTemplate, defaultReviewPricingTemplate, EnumReviewPricingRushCredits } from "../../validation";

import { appService } from '../../services/appService';
import { withLoader } from "./../../common/components";
import { handleRemoveFile } from '../../common/utilities/commonFileHandlingMethods';

class ReviewPricing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            merchantInfo: Object.assign({}, defaultReviewPricingTemplate, props.merchantInfo),
            highlightMissingFields: false,
            files: {}
        };
        this.merchantInfoSchema = new Schema(reviewPricingTemplate, { strip: false, typecast: true });

        this.handleRemoveFile = handleRemoveFile.bind(this);
    }


    handleUpdateFileDescription = (fileType, i, description) => {
        let fullFileList = cloneDeep(this.state.files);
        fullFileList[fileType][i].fileDescription = description;
        this.setState({ files: fullFileList });
    }

    onDropFile = (fileType, acceptedFiles) => {
        let fullFileList = cloneDeep(this.state.files);
        let newFilesList = acceptedFiles.map((itm, i) => {
            return { file: itm, fileDescription: '' };
        });

        if (fullFileList[fileType])
            fullFileList[fileType].push.apply(fullFileList[fileType], newFilesList);
        else
            fullFileList[fileType] = newFilesList;

        this.setState({ files: fullFileList });
    }

    validateForm = () => {
        const validateList = this.merchantInfoSchema.validate(Object.assign({}, this.state.merchantInfo));
        if (isEmpty(this.state.files)) {
            validateList.push({ expose: true, message: "[**Merchants Statement**](javascript:void) is required", path: "merchantStatement" });
        }
        return validateList;
    }

    handleSubmit = (e) => {

        const errorList = this.validateForm()
        if (errorList.length) {
            this.setState({ highlightMissingFields: true });
            return;
        }
        this.props.showLoader(true);
        const reviewPricing = this.state.merchantInfo;
        appService.saveLeadFiles(reviewPricing.leadId, this.state.files)
            .then(() => { if (reviewPricing.appId) return appService.saveMerchantFiles(reviewPricing.appId, this.state.files) })
            .then(() => { return appService.submitReviewPricing(reviewPricing) })
            .then(() => {
                this.props.showLoader(false);
                this.setState({ successMessage: 'Submitted successfully.' });
            })
            .catch(err => {
                console.log('save files error', err);
                this.props.showLoader(false);
                this.setState({ errorMessage: 'An error occurred: ' + err, isNewError: true });
            });
    }

    handleChange = e => {
        let { name, value } = e.target;
        try { value = JSON.parse(value); } catch (ex) { }
        this.setState({ merchantInfo: Object.assign({}, this.state.merchantInfo, { [name]: +value || value }) });
    }

    handleCheckedChange = e => {
        const { name, checked } = e.target;
        this.setState({ merchantInfo: Object.assign({}, this.state.merchantInfo, { [name]: checked }) });
    }

    handleYesNoChange = (e, defaultValues, defaultValue) => {
        const newState = { merchantInfo: Object.assign({}, this.state.merchantInfo, { [e.target.name]: e.target.checked }) };

        if (e.target.checked === defaultValue) {
            each(defaultValues, (name, value) => {
                newState.merchantInfo[name] = value;
            });
        }

        this.setState(newState);
    }

    scrollTo = (id) => {
        this.setState({ highlightMissingFields: true });
        const elem = document.getElementById(id);
        elem && elem.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    getRequiredClassNameText = (field) => {
        const errorList = this.validateForm();
        const errorListPaths = errorList.map(e => e.path);
        return this.state.highlightMissingFields && errorListPaths.includes(field) ? ' required--field' : '';
    }

    render() {
        const { merchantInfo, errorMessage, successMessage } = this.state;
        const errorList = this.validateForm();
        const errorListPaths = errorList.map(e => e.path);
        return (
            successMessage ? (
                <div className="popup__body">
                    <div className="note note--success note--no-margin">
                        {successMessage}
                    </div>
                </div>
            ) :
                (
                    <React.Fragment>
                        <header className="popup__header">
                            <h6>Pricing Analysis For {merchantInfo.dba || ''}</h6>
                        </header>
                        <form className="popup__body" onSubmit={this.submit}>
                            <div className="row grid-align-middle">
                                <div className="col col-sml-12 col-lrg-6">
                                    <div id="dbadiv" className={"required form__field spc--bottom--med" + this.getRequiredClassNameText('merchantDBA')} >
                                        <label htmlFor="dba" className="label">Merchant DBA</label>
                                        <input className="input input--med" value={merchantInfo.dba} name="dba" onChange={this.handleChange} />
                                    </div>
                                </div>
                                <div className="col col-sml-12 col-lrg-6">
                                    <div id="corporateNamediv" className="form__field spc--bottom--med">
                                        <label className="label">Merchant name on statement <span className="grayedout">(if diff. than DBA)</span></label>
                                        <input className="input input--med" value={merchantInfo.corporateName} name="corporateName" onChange={this.handleChange} />
                                    </div>
                                </div>
                            </div>
                            <p className="message message--default spc--bottom--med">Statements are required to be uploaded to request a review.</p>
                            <div id="merchantStatement" className={"required form__field spc--bottom--med file-upload--sml" + this.getRequiredClassNameText('merchantStatement')}>
                                <label className="required label type--none">Upload Statement(s)</label>
                                <FileDropzoneComponent multiple={true}
                                    tag='MerchantStatement'
                                    showDescription={true}
                                    fileList={this.state.files}
                                    onDrop={this.onDropFile}
                                    onRemoveFile={this.handleRemoveFile}
                                    onUpdateDescription={this.handleUpdateFileDescription}
                                    validateFileSize={false}
                                />
                            </div>
                            <div className="row grid-align-middle">
                                <div className="col col-sml-12 col-lrg-6">
                                    <div id="descriptionOfGoodsdiv" className={"required form__field spc--bottom--med" + this.getRequiredClassNameText('descriptionOfGoods')} >
                                        <label className="label">Description of goods / services sold</label>
                                        <input className="input input--med" name="descriptionOfGoods" value={merchantInfo.descriptionOfGoods} onChange={this.handleChange} />
                                    </div>
                                </div>
                                <div className="col col-sml-12 col-lrg-6">
                                    <div id="percentageKeyeddiv" className={"required form__field spc--bottom--med" + this.getRequiredClassNameText('percentageKeyed')} >
                                        <label className="label">Percentage keyed</label>
                                        <input type="number" min="0" className="input input--med" name="percentageKeyed" value={merchantInfo.percentageKeyed} onChange={this.handleChange} />
                                    </div>
                                </div>
                                <div className="col col-sml-12 col-lrg-6">
                                    <div id="percentageSwipeddiv" className={"required form__field spc--bottom--med" + this.getRequiredClassNameText('percentageSwiped')} >
                                        <label className="label">Percentage swiped</label>
                                        <input type="number" min="0" className="input input--med" name="percentageSwiped" value={merchantInfo.percentageSwiped} onChange={this.handleChange} />
                                    </div>
                                </div>
                                <div className="col col-sml-12 col-lrg-12">
                                    <div id="representsAverageMonthdiv" className="required spc--bottom--med" >
                                        <label className="label spc--bottom--sml">Does this represent an average month of swiping?</label>
                                        <YesNoRadio name="representsAverageMonth" yes={merchantInfo.representsAverageMonth} onChange={e => this.handleYesNoChange(e, ['averageMonthlyVolume'], true)} />
                                    </div>
                                </div>
                            </div>
                            <div className="row grid-align-middle">
                                {merchantInfo.representsAverageMonth ? '' :

                                    <div className="col col-sml-12 col-lrg-6">
                                        <div id="averageMonthlyVolumediv" className={"required form__field spc--bottom--med" + this.getRequiredClassNameText('averageMonthlyVolume')} >
                                            <label className="label">Please specify avg. monthly volume</label>
                                            <input type="number" min="0" step="0.01" className="input input--med input--currency" name="averageMonthlyVolume" value={merchantInfo.averageMonthlyVolume} onChange={this.handleChange} />
                                        </div>
                                    </div>

                                }
                            </div>

                            <div id="preauthAndCapturediv" className="spc--bottom--med required form__field">
                                <label className="label">Pre-authorization and captures</label>
                                <YesNoRadio name="preauthAndCapture" yes={merchantInfo.preauthAndCapture} onChange={e => this.handleYesNoChange(e, ['daysBetweenAuthAndCapture'], false)} />
                            </div>

                            {true}
                            {merchantInfo.preauthAndCapture && (
                                <React.Fragment>
                                    <div className="row grid-align-middle">
                                        <div className="col col-sml-12 col-lrg-8">
                                            <div id="daysBetweenAuthAndCapturediv" className={"required form__field spc--bottom--med" + this.getRequiredClassNameText('daysBetweenAuthAndCapture')} >
                                                <label className="label">How many days between authorization and capture?</label>
                                                <input type="number" min="0" className="input input--med" name="daysBetweenAuthAndCapture" value={merchantInfo.daysBetweenAuthAndCapture} onChange={this.handleChange} />
                                            </div>
                                        </div>
                                    </div>

                                    <div id="captureSameAsAuthdiv" className="required form__field spc--bottom--med" >
                                        <label className="label">Is capture amount same as the authorization amount?</label>
                                        <YesNoRadio name="isCaptureSameAsAuth" yes={merchantInfo.isCaptureSameAsAuth} onChange={this.handleYesNoChange} />
                                    </div>

                                    {merchantInfo.captureSameAsAuth ||
                                        <div id="isCaptureGreaterThanAuthdiv">
                                            <div className={"required spc--bottom--med" + this.getRequiredClassNameText('isCaptureGreaterThanAuth')} >
                                                <label className="label spc--bottom--tny">The capture amount is</label>
                                                <div className="row grid-align-middle">
                                                    <div className="col col-sml-12 col-lrg-6">
                                                        <div className="form__field">
                                                            <input className="input--radio" type="radio" id="isCaptureGreaterThanAuthGreater" name="isCaptureGreaterThanAuth" onChange={this.handleChange} value={true} checked={merchantInfo.isCaptureGreaterThanAuth} />
                                                            <label className="label" htmlFor="isCaptureGreaterThanAuthGreater">Greater than the authorization amount</label>
                                                        </div>
                                                    </div>
                                                    <div className="col col-sml-12 col-lrg-6">
                                                        <div className="form__field">
                                                            <input className="input--radio" type="radio" id="isCaptureGreaterThanAuthLess" name="isCaptureGreaterThanAuth" onChange={this.handleChange} value={false} checked={!merchantInfo.isCaptureGreaterThanAuth} />
                                                            <label className="label" htmlFor="isCaptureGreaterThanAuthLess">Less than the authorization amount</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </React.Fragment>
                            )}
                            <div id="doesProcessTipsdiv" className="required spc--bottom--med form__field" >
                                <label className="label">Process tips</label>
                                <YesNoRadio name="doesProcessTips" yes={merchantInfo.doesProcessTips} onChange={this.handleYesNoChange} />
                            </div>
                            <div id="hasAmexEsadiv" className="form__field spc--bottom--med">
                                <label className="label">Does the merchant currently have Amex ESA?</label>
                                <YesNoRadio name="hasAmexEsa" yes={merchantInfo.hasAmexEsa} onChange={this.handleYesNoChange} />
                            </div>
                            {merchantInfo.hasAmexEsa && (
                                <React.Fragment>
                                    <p className="spc--bottom--med">Please provide Amex ESA statement for possible additional savings</p>
                                    <div className="form__field spc--bottom--med required file-upload--sml">
                                        {/* TODO: add to validate */}
                                        <label className="label type--none">Upload Statement(s)</label>
                                        <FileDropzoneComponent multiple={true}
                                            tag='MerchantStatement'
                                            showDescription={true}
                                            fileList={this.state.files}
                                            onDrop={this.onDropFile}
                                            onRemoveFile={this.handleRemoveFile}
                                            onUpdateDescription={this.handleUpdateFileDescription}
                                        />

                                    </div>
                                </React.Fragment>
                            )}

                            {merchantInfo.market === 'Canada' && (
                                <div id="currencydiv" className="form__field spc--bottom--med required">
                                    <label className="label">Currency</label>
                                    <div className="spc--bottom--tny">
                                        <input type="radio" className="input--radio" id="idcurrencyCad" value="CAD" name="currency" checked={merchantInfo.currency === "CAD"} onChange={this.handleChange} />
                                        <label className="label" htmlFor="idcurrencyCad">CAD</label>
                                    </div>
                                    <div className="spc--bottom--tny">
                                        <input type="radio" className="input--radio" id="idcurrencyUsd" value="USD" name="currency" checked={merchantInfo.currency === "USD"} onChange={this.handleChange} />
                                        <label className="label" htmlFor="idcurrencyUsd">USD</label>
                                    </div>
                                </div>
                            )}

                            <label className="label spc--bottom--sml">Did you ensure the following?</label>

                            <div id="areStatementsRecentdiv" className="required spc--bottom--med" >
                                <div className="form__field spc--bottom--sml">
                                    <input className="input--check" type="checkbox" checked={merchantInfo.areStatementsRecent} name="areStatementsRecent" id="areStatementsRecent" onChange={this.handleCheckedChange} />
                                    <label className="label" htmlFor="areStatementsRecent">
                                        <span className="type--color--warning">* </span>
                                        Statements provided are recent
                                    </label>
                                    <p className="type--sml type--color--text">Reviewing old statements is baseless because it will give inaccurate results. In an event that you are unable to obtain a recent statement, please make a note of it. Otherwise, we will not be able to complete the review.</p>
                                </div>
                            </div>

                            <div id="hasAllPagesOfStatementdiv" className="required spc--bottom--med" >
                                <div className="form__field spc--bottom--sml">
                                    <input className="input--check" type="checkbox" checked={merchantInfo.hasAllPagesOfStatement} name="hasAllPagesOfStatement" id="hasAllPagesOfStatement" onChange={this.handleCheckedChange} />
                                    <label className="label" htmlFor="hasAllPagesOfStatement">
                                        <span className="type--color--warning">* </span>
                                        All pages of the statements are attached
                                    </label>
                                    <p className="type--sml type--color--text">Common scenario is that merchant provides only even or odd pages.</p>
                                </div>
                            </div>

                            <div id="notesdiv" className="spc--bottom--med">
                                <p className="message message--default spc--bottom--sml">
                                    If you discussed the account with anyone from Agent Support beforehand, please note exactly what was discussed.
                                    Do not take for granted that we will know what you had in mind.
                                </p>
                                <input className="input input--med" name="notes" value={merchantInfo.notes} onChange={this.handleChange} />
                            </div>

                            <div id="rushdiv" className="required spc--bottom--med" >
                                <label className="label required spc--bottom--tny">Is this review a rush?</label>
                                <YesNoRadio name="isRushRequest" yes={merchantInfo.isRushRequest} onChange={this.handleYesNoChange} />
                            </div>

                            {merchantInfo.isRushRequest && (
                                <div className="" >
                                    <div id="rushReasondiv" className={"required spc--bottom--med" + this.getRequiredClassNameText('rushReason')} >
                                        <label className="label spc--bottom--tny">Select one of the below:</label>
                                        <div className="row grid-align-middle">
                                            {EnumReviewPricingRushCredits.map((v, i) => (
                                                <div className="col col-sml-12 col-lrg-6">
                                                    <div className="form__field" key={i}>
                                                        <input type="radio" className="input--radio" id={"rushReasonPoints" + i} name="rushReason" onChange={this.handleChange} value={v} checked={merchantInfo.rushReason === v} />
                                                        <label className="label" htmlFor={"rushReasonPoints" + i}>{v}</label>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )}
                            {errorListPaths.length ? (

                                <div className="note note--warning">
                                    {errorList.map((elem, i) => <div key={i} onClick={() => this.scrollTo(elem.path + 'div')}>
                                        <i className="icon icon--nano icon--text-top icon--alert spc--right--tny"></i>{defaultReactOutput(defaultImplicitParse(elem.message))}
                                    </div>)}
                                </div>

                            ) : null}
                            {errorMessage ? (
                                <div className="note note--warning">
                                    {errorMessage}
                                </div>
                            ) : null}
                        </form>
                        <div className="popup__footer popup__footer--styled">
                            <button disabled={this.props.isLoading} onClick={this.handleSubmit} className={'btn btn--primary btn--med' + (!errorListPaths.length ? '' : ' btn--not-allowed')}>Submit Form</button>
                        </div>
                    </React.Fragment>
                )
        );
    }

}

export default withLoader(ReviewPricing);
