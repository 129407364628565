import Amplify from 'aws-amplify';
import { get } from 'lodash';
import awsExports from 'aws-exports';

import { getApiConfiguration } from '../common/utilities/apiConfiguration'

const apiConfiguration = getApiConfiguration();
const overrideConfiguration = {
    userPoolId: get(apiConfiguration, 'userPoolId', awsExports.userPoolId),
    userPoolWebClientId: get(apiConfiguration, 'userPoolWebClientId', awsExports.userPoolWebClientId),
}

Amplify.configure({
	Auth: {
        region: 'us-west-2',
        identityPoolRegion: 'us-west-2',
        mandatorySignIn: true,
        cookieStorage: {
           domain: '.cardknox.com',
           path: '/',
           expires: 30,
           secure: true
        },
        ...awsExports,
        ...overrideConfiguration,
    },
});
