import { some, each } from 'lodash';
import moment from 'moment';

import {
    MIDFilterComponent,
    MIDSelectionComponent,
    TicketNumberFilterComponent,
    TicketNumberSelectionComponent,
    TicketIssueFilterComponent,
    TicketIssueSelectionComponent,
    PriorityFilterComponent,
    PrioritySelectionComponent,
    DBAFilterComponent,
    DBASelectionComponent,    
    TicketStatusFilterComponent,
    TicketStatusSelectionComponent,
    DatePickerSelectionComponent,
    predefinedDates,

    DateFilterComponent
} from '../../../common/filters';
import { createFilters, getFilterValues, getDate } from '../../../common/utilities';

export const ticketsFilter = createFilters([
    {
        key: 'date',
        name: 'Modified Date',
        hasSelection: true,
        defaultHasSelection: true,
        clearable: false,
        selectionComponent: DatePickerSelectionComponent,        
        props: {
            displayTime: false,
            maxDaysRange: Infinity,
            predefinedDates:predefinedDates,
        },
        values: {
            startDate: moment().startOf('day').add(-30, 'day'),
            endDate: moment().endOf('day'),
            key: '-30'
        },
        defaultValues: {
            startDate: moment()
                .startOf('day')
                .add(-30, 'day'),
            endDate: moment().endOf('day'),
            key: '-30'
        }
    },  
    {
        key: 'ticketNumber',
        name: 'Ticket Number',
        component: TicketNumberFilterComponent,
        selectionComponent: TicketNumberSelectionComponent,
        standalone: true,
        values: {
            ticketNumber: null
        },
        defaultValues: {
            ticketNumber: null
        }
    },
    {
        key: 'mid',
        name: 'MID',
        component: MIDFilterComponent,
		selectionComponent: MIDSelectionComponent,
        values: {
            mid: null
        },
        defaultValues: {
            mid: null
        }
    },
    {
        key: 'dba',
        name: 'DBA',
        component: DBAFilterComponent,
		selectionComponent: DBASelectionComponent,
        values: {
            dba: null
        },
        defaultValues: {
            dba: null
        }
    },
    {
        key: 'status',
        name: 'Status',
        hasSelection: true,
        component: TicketStatusFilterComponent,
		selectionComponent: TicketStatusSelectionComponent,
        values: {
            New: true,
            'Closed - Resolved': false,
            'Closed - Unresolved': false,
            'Deleted [Closed]': false,
            'Waiting For Agent': false,
            'Waiting For Sub Ticket': false,
            'Needs Follow Up': false            
        },
        defaultValues: {
            New: false,
            'Closed - Resolved': false,
            'Closed - Unresolved': false,
            'Deleted [Closed]': false,
            'Waiting For Agent': false,
            'Waiting For Sub Ticket': false,
            'Needs Follow Up': false  
        }
    },
    {
        key: 'priority',
        name: 'Priority',
        component: PriorityFilterComponent,
        selectionComponent: PrioritySelectionComponent,
        values: {
            Low: false,
            Medium: false,
            High: false
        },
        defaultValues: {
            New: false,
            Medium: false,
            High: false
        }
    },
    {
        key: 'ticketIssue',
        name: 'Issue',
        component: TicketIssueFilterComponent,
        selectionComponent: TicketIssueSelectionComponent,
        values: {
            ticketIssue: null
        },
        defaultValues: {
            ticketIssue: null
        }
    },
    {
        key: 'openeddate',
        name: 'Date Opened',
        hasSelection: false,
        defaultHasSelection: false,
        selectionComponent: DatePickerSelectionComponent,
        component: DateFilterComponent,
        props: {
            displayTime: false,
            maxDaysRange: Infinity,
            startKey: 'openedStartDate',
            endKey: 'openedEndDate',
        },
        values: { openedStartDate: null, openedEndDate: null },
        defaultValues: {
            openedStartDate: null,
            openedEndDate: null,
        }
    },
    {
        key: 'followupdate',
        name: 'Follow Up Date',
        hasSelection: false,
        defaultHasSelection: false,
        selectionComponent: DatePickerSelectionComponent,
        component: DateFilterComponent,
        props: {
            displayTime: false,
            maxDaysRange: Infinity,
            startKey: 'followUpStartDate',
            endKey: 'followUpEndDate',
        },
        values: { followUpStartDate: null, followUpEndDate: null },
        defaultValues: {
            followUpStartDate: null,
            followUpEndDate: null,
        }
    },
]);

export const compileFilter = (filters) => {
    const { 
        date,
        mid,
        dba,
        status,
        ticketNumber,
        priority,
        ticketIssue,
        openeddate,
        followupdate,
        appId
    } = getFilterValues(filters);

    const filter = {};

    filter.startDate = getDate(date.startDate, process.env.REACT_APP_DISPLAY_DATE_FORMAT);
    filter.endDate = getDate(date.endDate, process.env.REACT_APP_DISPLAY_DATE_FORMAT);

    if (mid && mid.mid != null) {
        filter.mid = mid.mid;
    }

    if (appId && appId.appId != null) {
        filter.appId = appId.appId;
    }

    if (dba && dba.dba != null) {
        filter.dba = dba.dba;
    }

    if (ticketNumber.ticketNumber != null) {
        filter.ticketNumber = ticketNumber.ticketNumber;
    }

    if (ticketIssue.ticketIssue != null) {
        filter.issue = ticketIssue.ticketIssue;
    }

    if (some(status, (p) => p)) {
        filter.statusList = parseListFilter(status);
    }

    if (some(priority, (p) => p)) {
        filter.priorityList = parseListFilter(priority);
    }

    if (openeddate.openedStartDate != null) {
        filter.createdDateStart = getDate(openeddate.openedStartDate, process.env.REACT_APP_DISPLAY_DATE_FORMAT);
        filter.createdDateEnd = getDate(openeddate.openedEndDate, process.env.REACT_APP_DISPLAY_DATE_FORMAT);
    }

    if (followupdate.followUpStartDate != null) {
        filter.followUpDateStart = getDate(followupdate.followUpStartDate, process.env.REACT_APP_DISPLAY_DATE_FORMAT);
        filter.followUpDateEnd = getDate(followupdate.followUpEndDate, process.env.REACT_APP_DISPLAY_DATE_FORMAT);
    }
    /*
    if (status.status != null) {
        filter.StatusList = status.status;
    }*/    
    return filter;
};

const parseListFilter = (values) => {
    const listFilterArr = [];

    each(values, (value, stat) => {
        if (value) {
            listFilterArr.push(stat);
        }
    });

    return listFilterArr.join(',');
};