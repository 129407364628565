import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';

import DropdownContainer from '../dropdown-container/dropdown-container';

const GridFooter = ({
	gridFooterRef,
	isLoadMoreEnabled,
	loadMoreOptions,
	fetchingAdditionalData,
	fetchingData,
	filteredRows,
	type,
	openCloseModal,
	CustomComponent,
	onLoadMoreLimitChange,
	loadMoreLimit,
	renderGridPagination,
	renderResultsAndRefNum
}) => {
	const handleChange = ({ target: { value } }) => onLoadMoreLimitChange(value);

	return (
		<div ref={gridFooterRef} className="react-grid__footer">
			{renderGridPagination()}
			{isLoadMoreEnabled && (
				<div className="flex--primary flex--gap--sml">
					<DropdownContainer
						value={loadMoreLimit}
						onChange={handleChange}
						options={map(loadMoreOptions, value => ({ value, text: value === 0 ? 'All' : value, default: false }))}
						disabled={fetchingAdditionalData}
						selectClass="input input--sml input--select w-80p"
						labelHeaderClass="display--n"
						labelClass="display--n"
					/>
					<p className="type--p3 type--color--text--light hide--to--sml">results</p>
				</div>
			)}
			{renderResultsAndRefNum()}
			{fetchingAdditionalData && <div className="loader--progress" />}
			{!fetchingData && filteredRows.length > 0 && (
				<CustomComponent openCloseModal={openCloseModal} type={type} data={filteredRows} />
			)}
		</div>
	);
};

GridFooter.propTypes = {
	gridFooterRef: PropTypes.any,
	isLoadMoreEnabled: PropTypes.bool,
	loadMoreOptions: PropTypes.any,
	fetchingAdditionalData: PropTypes.bool,
	fetchingData: PropTypes.bool,
	filteredRows: PropTypes.array,
	type: PropTypes.string,
	openCloseModal: PropTypes.func,
	CustomComponent: PropTypes.any,
	onLoadMoreLimitChange: PropTypes.func,
	loadMoreLimit: PropTypes.any,
	renderGridPagination: PropTypes.func,
	renderResultsAndRefNum: PropTypes.func,
};

export default GridFooter;
