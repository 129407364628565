const apiConfigurationLocalStorageKey = 'override-api-configuration';
const defaultConfiguration = {
    apiUrl: process.env.REACT_APP_PORTAL_API,
};
if (window.origin === 'https://stg1-us-east-2.partner.cardknox.com/') {
    defaultConfiguration.apiUrl = 'https://stg-us-east-2.partnerapi.cardknox.com/api';
}
let apiConfiguration = JSON.parse(localStorage.getItem(apiConfigurationLocalStorageKey)) || defaultConfiguration;

export function getApiUrl() {
    return apiConfiguration.apiUrl;
}

export function getApiConfiguration() {
    return apiConfiguration;
}

export function setApiConfiguration(newApiConfiguration) {
    if (newApiConfiguration) {
        localStorage.setItem(apiConfigurationLocalStorageKey, JSON.stringify(newApiConfiguration));
        apiConfiguration = newApiConfiguration;
    } else {
        localStorage.removeItem(apiConfigurationLocalStorageKey);
        apiConfiguration = defaultConfiguration;
    }
}