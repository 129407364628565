import moment from 'moment';

export default [
    {
        value: 0,
        displayValue: 'Today',
        isRange: false,
    },
    {
        value: -1,
        displayValue: 'Yesterday',
        isRange: false,
    },
    {
        value: -parseInt(moment().format('DD'), 10) + 1,
        displayValue: 'This month',
        isRange: true,
        isCalculated: true,
        customEndValue: moment().endOf('month').diff(moment(), 'days'),
    },
    {
        value: -7,
        displayValue: 'Last 7 days',
        isRange: true,
    },
    {
        value: -30,
        displayValue: 'Last 30 days',
        isRange: true,
    },
    {
        value: -60,
        displayValue: 'Last 60 days',
        isRange: true,
    },
    {
        value: -90,
        displayValue: 'Last 90 days',
        isRange: true,
    },
];