import { _sharedFunctions } from './_sharedFunctions';

const handleResponse = _sharedFunctions.handleResponse;
const appRequestHeaders = _sharedFunctions.appRequestHeaders;

export const _viewFunctions = {
    getAgentViews,
    saveAgentView,
    deleteAgentView,
    updateDefaultAgentView,
};


function saveAgentView(name, view, viewType) {
    const requestBody = JSON.stringify({
        ViewName: name,
        ViewJson: JSON.stringify(view),
        ViewType: viewType,
    });

    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/agent/SaveAgentView' }).then(handleResponse);
}

function updateDefaultAgentView(name, viewType) {
    const requestBody = JSON.stringify({
        ViewName: name,
        ViewType: viewType,
    });

    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/agent/UpdateDefaultAgentView' }).then(handleResponse);
}

function getAgentViews(viewType) {
    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: JSON.stringify(viewType), action: '/agent/GetAgentViewsByType' }).then(handleResponse);
}

function deleteAgentView(name, viewType) {
    const requestBody = JSON.stringify({
        ViewName: name,
        ViewType: viewType,
    });
    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/agent/DeleteAgentView' }).then(handleResponse);
}