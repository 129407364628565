import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { has } from 'lodash';

class LabelComponent extends Component {

    get labelClasses() {
        return { 
            'cold lead': 'type--label type--label--grey type--uppercase',
            'warm lead': 'type--label type--label--orange type--uppercase',
            'hot lead': 'type--label type--label--red type--uppercase',
            'customer': 'type--label type--label--green type--uppercase',
        }
    }

    constructor(props) {
        super(props);
        this.getStyle = this.getStyle.bind(this);
    }

    getStyle = () => {
        if (has(this.labelClasses, this.props.value.toLowerCase())) {
            return this.labelClasses[this.props.value.toLowerCase()];
        } else {
            return 'type--label type--label--grey type--uppercase';
        }
    };

    closePopup = (e) => {
        this.props.dependentValues.onInfoHover({}, null);
    };

    render = () => {
        const { value } = this.props;
        //console.log(dependentValues);
        return value ? (
            <span ref={el => this.info = el} className={this.getStyle()}>
               {value}  
            </span>
        ) : null;
    }
}

export default LabelComponent;

export const PredefinedLeadLabels = [ 
    { value: 'COLD LEAD', label: 'COLD LEAD' },
    { value: 'WARM LEAD', label: 'WARM LEAD' },
    { value: 'HOT LEAD', label: 'HOT LEAD' },
    { value: 'CUSTOMER', label: 'CUSTOMER' },
];

LabelComponent.propTypes = {
    value: PropTypes.string    
};