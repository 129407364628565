import { noop } from "lodash";

const createTab = (title, rolesWithAccess, link, icon, type, rolesWithoutAccess = []) => {
  let tab = { title, rolesWithAccess, link, icon, type, rolesWithoutAccess };
  if (tab.type === "OnClickHandler") {
    tab.onClick = noop;
  }
  return tab;
};

const tabTypes = {
  navLink: "NavLink",
  onClickHandler: "OnClickHandler",
  outsideLink: "OutsideLink"
}

const sidebarTabs = [
  createTab("Merchant Accounts", ["isAdmin", "isAgent"], "/merchants", "merchants", tabTypes.navLink),
  createTab("Leads", ["isAdmin", "isAgent"], "/leads", "customers", tabTypes.navLink),
  createTab("EApps", ["isAdmin", "isAgent"], "/eapps", "user-target", tabTypes.navLink),
  createTab("New Account", ["isAdmin", "isAgent"], null, "add", tabTypes.onClickHandler, ["isLinkedUserSupportRep"]),
  createTab("Tickets", ["isAdmin", "isAgent"], "/tickets", "invoices", tabTypes.navLink),
  createTab("Equipment Lookup", ["isAdmin", "isAgent"], "/equipment", "equipment", tabTypes.navLink),
  createTab("Residuals", ["isResidual"], "/residuals", "dots-horizontal", tabTypes.navLink, ["isDropinUser"]),
  createTab("Drop In (Impersonate)", ["isAdmin", "isDropinUser"], "/dropin", "fraudwatch", tabTypes.navLink),
  createTab("Feedback", ["isAdmin", "isAgent"], "https://app.smartsheet.com/b/form/cf8d598785bd400fa11b477057702450", "support", tabTypes.outsideLink),
  createTab("User Guide", ["isAdmin", "isAgent"], "https://docs.cardknox.com/cardknox-products/partner-portal", "user-guide", tabTypes.outsideLink),
  createTab("Settings", ["isAdmin", "isAgent"],"/settings", "settings-alt", tabTypes.navLink, ["isDropinUser"]),
];

export default sidebarTabs;
