import React, { Component, Fragment } from 'react';
import { func, object } from 'prop-types';

import { emailService } from '../../services';
import { emailTemplates } from '../../common/utilities';

class SendEmailPopup extends Component {
    constructor() {
        super();

        this.state = {
            isLoading: false,
            emailBody: '',
            isValidEmailBody: true,
            errorMessage: null,
        };
    };

    sendEmail = async () => {
        this.showLoader(true);
		try {
            const { closeModal, addNotification, templateParams } = this.props;

            if (!this.validateInput()) {
                return;
            }

			await emailService.send(emailTemplates.requestAccountUpdate, { ...templateParams, emailBody: this.state.emailBody });
			addNotification({
				message: 'Email sent successfully.',
				success: true,
            });
            closeModal();
		} catch (e) {
			this.setState({ errorMessage: e.message });
		}
        this.showLoader();
    };

    showLoader = (value = false) => this.setState({ isLoading: value });

    handleChange = ({ target: { value, name } }) => this.setState({ [name]: value }, this.validateInput);

    validateInput = () => {
        try {
            const newState = {
                isValidEmailBody: this.state.emailBody.length > 0,
                errorMessage: null,
            };

            this.setState(newState);

            if (!newState.isValidEmailBody) {
                throw Error('Message is required.');
            }

            return newState.isValidEmailBody;
        } catch (e) {
            this.setState({ errorMessage: e.message, isLoading: false });
        }
    };

    render = () => {
        const { closeModal } = this.props;
        const { isLoading, emailBody, errorMessage, isValidEmailBody } = this.state;

        return (
            <div className="popup">
                <div className="popup__header">
                    <div className="popup__header__title">Request Account Update</div>
                </div>
                    <div className="popup__body">
                        {isLoading ? (
                            <div className="spc--bottom--lrg">
                                <div className="loader__spinner"></div>
                            </div>
                        ) : (
                            <Fragment>
                                {errorMessage && (
                                    <div className="spc--top--sml spc--bottom--sml note note--warning">
                                        {errorMessage}
                                    </div>
                                )}
                                <div className="">
                                    <label htmlFor="emailBody">
                                        Message
                                        <span className="type--color--primary"> *</span>
                                    </label>
                                    <textarea
                                        rows="5"
                                        cols="15"
                                        id="emailBody"
                                        name="emailBody"
                                        className={`input input--textarea${isValidEmailBody ? '' : ' is-invalid'}`}
                                        value={emailBody}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </Fragment>
                        )}
                    </div>
                <div className="popup__footer popup__footer--styled">
                    <div className="type--right">
                        <button
                            type="button"
                            tabIndex="-1"
                            className="btn btn--med btn--ghost spc--right--tny"
                            onClick={closeModal}
                        >
                            Close
                        </button>
                        <button
                            type="button"
                            tabIndex="-1"
                            className="btn btn--med btn--primary"
                            onClick={this.sendEmail}
                            disabled={isLoading}
                        >
                            Send Email
                        </button>
                    </div>
                </div>
            </div>
        );
    };
};

SendEmailPopup.propTypes = {
    closeModal: func.isRequired,
    addNotification: func.isRequired,
    templateParams: object.isRequired,
};

export default SendEmailPopup;