import React, {Component} from 'react';
import PropTypes from 'prop-types';

class DBAFilterComponent extends Component {

    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
    }

    filterChange = e => {
        const value = e.target.value ? e.target.value : null;

        this.onFilterChange(value)
    };

    onFilterChange(value) {
        this.props.onFilterChanged({
            id: 'dba',
            values: [{key: 'dba', value: value}],
            emptyValue: null
        });
    }

    componentDidUpdate = () => {
        if (this.inputRef.current) {
            this.inputRef.current.focus();
        }
    };    

    render() {
        const dba = this.props.filter.values.dba;

        return (
            <div className="pos--rel">
                <input
                    type="text"
                    placeholder="DBA"
                    className="input input--number input--med"
                    value={dba === null ? '' : dba}
                    id="company"
                    onChange={this.filterChange}
                    ref={this.inputRef}
                    autoFocus={true}
                />
            </div>
        );
    }
}

DBAFilterComponent.propTypes = {
    filter: PropTypes.object.isRequired,
    onFilterChanged: PropTypes.func.isRequired
};

export default DBAFilterComponent;