import PropTypes from 'prop-types';
import { BaseStatusComponent } from '../../../common/formatters';

class EAppStatusComponent extends BaseStatusComponent {
    constructor(props) {
        super(props);
        this.statusClasses = {
            'eappstarted': this.styleActive,
            'eappsubmitted': this.styleActive,

            'pendingfdsig': this.stylePending,
            'pendingelavonsig': this.stylePending,
            'pendunderwriting': this.stylePending,
            'pendbankapproval': this.stylePending,
        }
    }
}

export default EAppStatusComponent;

EAppStatusComponent.propTypes = {
    value: PropTypes.string,
    dependentValues: PropTypes.shape({
        statusTitle: PropTypes.string,
        statusDisplayName: PropTypes.string,
    }),
};