import { map, first } from 'lodash';
import { authHeader } from '../helpers/auth-header';

//import config from 'config';
import { _sharedFunctions } from './_sharedFunctions';

const handleResponse = _sharedFunctions.handleResponse;
const appRequestHeaders = _sharedFunctions.appRequestHeaders;

export const _merchantFunctions = {
    /* Merchants */
    getMerchants,
    getMerchantDetails,
    getMerchantNotes,
    addNote,
    editNote,
    deleteNote,
    hideMerchant,
    unhideMerchant,
    saveContact,
    saveMerchantDetails,
    submitBankingServicesRequest,
    updateBankingServicesRequest,
    getElavonSettlementMonths,
    getElavonStatementMonths,
    getElavonSettlementsForMonth,
    getElavonStatementForMonth,
    updateAppsSignatureRequest,
    UpdateRequiredAccountInformation,
    SendInvoiceEmail,
    GetTemplateEquipment
};

function getMerchants(MerchantFilter, rowsPerPage, activePage) {   
    const pageNumber = activePage || 1;
    const pageSize = rowsPerPage || 20;
    const requestBody = JSON.stringify({
        MerchantFilter: MerchantFilter,
        PageSize: pageSize,
        PageNumber: pageNumber
    });
    
    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/agent/GetMerchants' }).then(handleResponse);
}

function getElavonSettlementMonths(mid) {
    const requestBody = JSON.stringify(mid);
    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/merchant/GetElavonSettlementMonths' }).then(handleResponse);
}

function getElavonStatementMonths(mid) {
     const requestBody = JSON.stringify(mid);
     return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/merchant/GetElavonStatementMonths' }).then(handleResponse);
}

function getElavonSettlementsForMonth(mid, year, month) {
     const requestBody = JSON.stringify({ mid, bankReportYear: year, bankReportMonth: month });
     return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/merchant/GetElavonSettlementsForMonth' }).then(handleResponse);
}

function getElavonStatementForMonth(mid, year, month) {
    const requestBody = JSON.stringify({ mid: mid, bankReportYear: year, bankReportMonth: month });
    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/merchant/GetElavonStatementForMonth' }).then(handleResponse);
}

function getMerchantDetails(appId) {    
    const requestBody = JSON.stringify(appId);
    
    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/merchant/GetAccountDetails' }).then(handleResponse);
}

function getMerchantNotes(appId) {    
    const requestBody = JSON.stringify(appId);

    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/merchant/Notes' }).then(handleResponse);    
}

function addNote(appId, note) {
    const requestBody = JSON.stringify({ parentId: appId, noteText: note });

    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/merchant/AddNote' }).then(handleResponse);
}

function editNote(appId, noteId, note) {
    const requestBody = JSON.stringify({ parentId: appId, noteId: noteId,  noteText: note });

    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/merchant/EditNote' }).then(handleResponse);
}

function deleteNote(appId, noteId) {
    const requestBody = JSON.stringify({ parentId: appId, noteId: noteId });

    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/merchant/DeleteNote' }).then(handleResponse);
}

function hideMerchant(appId) {
    const requestBody = JSON.stringify(appId);

    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/merchant/Hide' }).then(handleResponse);    
}

function unhideMerchant(appId) {
    const requestBody = JSON.stringify(appId);

    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/merchant/Unhide' }).then(handleResponse); 
}

function saveContact(appId, contact) {
    //const requestBody = JSON.stringify({ appId, emailAddress: contact.email });

    //return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/merchant/SaveContact' }).then(handleResponse);  
}

function saveMerchantDetails(merchantDetails) {
    const requestBody = JSON.stringify(merchantDetails)
    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/merchant/SaveMerchantDetails' }).then(handleResponse);
}

function submitBankingServicesRequest(data) {
    let formData = _sharedFunctions.objectToFormData(data);
    
    return _sharedFunctions.callApi({ headerMethod: authHeader, body: formData, action: '/merchant/SubmitBankingServicesRequest' }).then(handleResponse);
}


function updateBankingServicesRequest(appId, ticketId, fileList) {
    const data = {
        appId: appId,
        ticketid: ticketId,
        Files: map(fileList, (value) => first(value).file),
    };

    let formData = _sharedFunctions.objectToFormData(data);

    return _sharedFunctions.callApi({ headerMethod: authHeader, body: formData, action: '/merchant/UpdateBankingServicesRequest' }).then(handleResponse);  
}

function updateAppsSignatureRequest(email, token){
    const data = {
        email
    }
    let requestBody = JSON.stringify(data);
    let requestHeaders = _sharedFunctions.appRequestHeadersForMerchant(token, false);
    return _sharedFunctions.callApi({ headerMethod: () => requestHeaders, body: requestBody, action: '/merchant/UpdateAppsSignatureRequest' }).then(handleResponse);  
}

function UpdateRequiredAccountInformation(data, token){
    let requestBody = JSON.stringify(data);
    let requestHeaders = _sharedFunctions.appRequestHeadersForMerchant(token, false);
    return _sharedFunctions.callApi({ headerMethod: () => requestHeaders, body: requestBody, action: '/merchant/UpdateRequiredAccountInformation' }).then(handleResponse);  
}

function SendInvoiceEmail(data){
    let requestBody = JSON.stringify(data);
    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/merchant/SendInvoiceEmail' }).then(handleResponse);  
}

function GetTemplateEquipment(appId){
    let requestBody = JSON.stringify(appId);
    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/agent/GetTemplateEquipment' }).then(handleResponse);  
}