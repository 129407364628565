import React from 'react';
import { FileDropzoneComponent } from '../../common/components/file-dropzone';
import PropTypes from 'prop-types';
import { withLoader } from "./../../common/components";
import { cloneDeep, startCase, isEmpty, every, keys } from 'lodash';
import { handleRemoveFile } from '../../common/utilities/commonFileHandlingMethods';

class FileUploadPopup extends React.Component {
    constructor(props) {
        super(props);
        const { tagList } = this.props;

        this.state = {
            fileTags: tagList.join('_'),
            files: {},
            error: '',
            uploading: false
        };

        this.handleRemoveFile = handleRemoveFile.bind(this);
    }

    get isFilesEmpty() {
        const { files } = this.state;

        return isEmpty(files) || every(keys(files), key => isEmpty(files[key]));
    }

    handleDropFile = (fileType, acceptedFiles) => {
        const { fileTags } = this.state;

        if (!fileTags) {
            return;
        }

        let newFilesList = acceptedFiles.map((itm, i) => {
            return { file: itm, fileDescription: '' };
        });
        let files = cloneDeep(this.state.files);

        if (files[fileType])
            files[fileType].push.apply(files[fileType], newFilesList);
        else
            files[fileType] = newFilesList;

        this.setState({ files });
    }

    onSave = (e) => {
        let { fileTags, files } = this.state;
        this.setState({ uploading: true });
        this.props.showLoader(true);
        this.props.handleSave(fileTags, files).then(() => {
            this.props.showLoader(false);
            this.setState({ uploading: false });
        });        
    }

    trim = (str, chars) => str.split(chars).filter(Boolean).join(chars);

    tagSelect = (e) => {
        let { fileTags, files } = this.state;
        let error = '';
        let newFileTags = '_' + fileTags + '_'; // for ease of use
        if (e.target.checked) {
            newFileTags = newFileTags + e.target.value;
        }
        else {
            newFileTags = newFileTags.replace('_' + e.target.value + '_', '');
        }
        newFileTags = this.trim(newFileTags, '_');
        if (!newFileTags) {
            error = 'Please choose file type';
        }
        else {
            let oldKey = Object.keys(files).length > 0 ? Object.keys(files)[0] : '';
            if (oldKey && files[oldKey]) {
                files[newFileTags] = files[oldKey];
                delete files[oldKey];
            }            
        }  
        this.setState({ fileTags: newFileTags, error });
    }

    render() {
        const { fileTags, error, uploading } = this.state;
        const disableConfirmAndSignButton = this.isFilesEmpty;
        const disableButton = uploading || !!error || disableConfirmAndSignButton;
        return (
            <React.Fragment>
                <div className="popup__body">
                    <div className="spc--bottom--sml">
                        <h2 className="">
                            File Upload
                        </h2>
                    </div>
                    {error ? (
                        <div className="note note--warning">
                            {error}
                        </div>
                    ) : null}
                    {this.props.tagList && 
                        <div className="spc--bottom--med">
                        {
                            this.props.tagList.map((t) => {
                                return <div className = "form__field">
                                    <input type="checkbox" className="input--check"
                                        value={t} id={'check' + t} checked={fileTags.indexOf(t) > -1} onChange={this.tagSelect} />
                                    <label htmlFor={'check' + t}>{startCase(t)}</label>
                                </div>
                            })
                        }
                        </div>
                    }
                    <div className="spc--bottom--med">
                        <FileDropzoneComponent multiple={false}
                            showDescription={false}
                            tag={fileTags}
                            fileList={this.state.files}
                            onDrop={this.handleDropFile}
                            onRemoveFile={this.handleRemoveFile}
                            validateFileSize={false}
                        />
                    </div>
                </div>
                <div className="popup__footer popup__footer--styled">
                    <button
                        onClick={this.onSave}
                        disabled={disableButton}
                        data-tooltip={disableConfirmAndSignButton ? 'Upload required files to save and proceed.' : null}
                        className="btn btn--primary btn--med"
                    >
                        Save
                    </button>
                </div>
            </React.Fragment>
        );
    }
}

FileUploadPopup.propTypes = {
    tagList: PropTypes.array,    
    handleSave: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired
};

export default withLoader(FileUploadPopup);