import React from 'react';
import PropTypes from 'prop-types';
import { NumericFormat as NumberFormat } from 'react-number-format';

class TotalAmountCountComponent extends React.Component {
	render() {
		const { value } = this.props;
		const className = `type--right ${this.props.boldify ? 'type--wgt--bold' : ''}`;
        return (
            <div className={className}>
                <NumberFormat
					prefix="$"
					value={value}
					displayType="text"
					thousandSeparator={true}
					decimalScale={2}
					fixedDecimalScale={true}
				/>
			</div>
		);
	}
}
TotalAmountCountComponent.propTypes = {
	value: PropTypes.number.isRequired,
	row: PropTypes.object,
	boldify: PropTypes.any,
};

export default TotalAmountCountComponent;
