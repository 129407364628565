import React, { Component } from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { NavLink, matchPath } from 'react-router-dom';
import { appService } from '../../services/appService';

const steps = {
    mpa: '/eapp/mpa',
    setup: '/eapp/setup',
    equipment: '/eapp/equipment',
    confirm: '/eapp/confirm'
};

export class NavbarComponent extends Component {
    constructor(props) {
        super(props);
        this.state = { appStatus: null, loading: false, setup: null };
    }

    componentDidMount() {
        if (this.props.loadOnMount) {
            this.loadAppStatus();
        }
    }

    loadAppStatus = (resolve = noop) => {
        this.setState({ loading: true });
        return Promise.all([
            appService.getEappStatus(this.props.appId),
        ]).then(
            ([appStatus, setup]) => {
                this.setState({ appStatus, setup, loading: false }, resolve);
                if (this.props.setIsReadyToSubmit !== undefined) {
                    this.props.setIsReadyToSubmit(appStatus);
                }
            }
        ).catch(err => {
            console.log('getEappStatus error', err);
            this.setState({ loading: false });
        })
    }

    isComplete(navPath) {
        const { loading, appStatus } = this.state;

        if (!loading && !!appStatus) {
            if (navPath.indexOf(steps.mpa) > -1) {
                return appStatus.isMpaComplete ? appStatus.isMpaComplete : appStatus.isDocumentationComplete; //Once the API is published we can remove this check and just return isMpaComplete --SW 1/31 
            }
            else if (navPath.indexOf(steps.setup) > -1) {
                return appStatus.isSetupFormComplete;
            }
            else if (navPath.indexOf(steps.equipment) > -1) {
                return appStatus.isEquipmentComplete;
            }
        }
        return false;
    }

    isActiveOrComplete(navPath) {
        //console.log(this.props.location.pathname);
        let active = matchPath(this.props.location.pathname, navPath);
        if (active) return true;

        return this.isComplete(navPath);
    }

    render = () => {
        const appid = this.props.appId;
        const { loading, appStatus } = this.state;
        let readyToSubmit = !loading && appStatus && appStatus.isReadyToSubmit;

        return (
            <React.Fragment>
                <div className="wizard--primary__wrap spc--bottom--lrg">
                    <ul className="wizard--primary">
                        <React.Fragment>

                            <li className={this.isActiveOrComplete(steps.mpa) ? 'wizard--primary__item is-active' : 'wizard--primary__item'}>
                                <div className="wizard--primary__icon">
                                    {this.isComplete(steps.mpa) ? <i className="icon icon--check icon--xsml" /> :
                                        <i className="wizard--primary__icon--warning datatooltip--wizard-warn" />
                                    }
                                </div>

                                <NavLink className="wizard--primary__link" activeClassName="is-active" to={steps.mpa + '/' + appid} exact>
                                    MPA
                                </NavLink>
                            </li>
                            <li className={this.isActiveOrComplete(steps.mpa) && this.isActiveOrComplete(steps.setup) ? 'wizard--primary__item is-active' : 'wizard--primary__item'}>
                                <i className="icon icon--sml align--v--middle wizard--primary__dots" />
                            </li>
                            <li className={this.isActiveOrComplete(steps.setup) ? 'wizard--primary__item is-active' : 'wizard--primary__item'}>
                                <div className="wizard--primary__icon">
                                    {this.isComplete(steps.setup) ? <i className="icon icon--check icon--xsml" /> : <i className="wizard--primary__icon--warning" />}
                                </div>
                                <NavLink className="wizard--primary__link" activeClassName="is-active" to={steps.setup + '/' + appid} exact>
                                    Setup Form
                                </NavLink>
                            </li>
                            <li className={this.isActiveOrComplete(steps.setup) && this.isActiveOrComplete(steps.equipment) ? 'wizard--primary__item is-active' : 'wizard--primary__item'}>
                                <i className="icon icon--sml align--v--middle wizard--primary__dots" />
                            </li>
                            <li className={this.isActiveOrComplete(steps.equipment) ? 'wizard--primary__item is-active' : 'wizard--primary__item'}>
                                <div className="wizard--primary__icon">
                                    {this.isComplete(steps.equipment) && <i className="icon icon--check icon--xsml" />}
                                </div>
                                <NavLink className="wizard--primary__link" activeClassName="is-active" to={steps.equipment + '/' + appid} exact>
                                    Equipment
                                </NavLink>
                            </li>
                            <li className={this.isActiveOrComplete(steps.equipment) && this.isActiveOrComplete(steps.confirm) ? 'wizard--primary__item is-active' : 'wizard--primary__item'}>
                                <i className="icon icon--sml align--v--middle wizard--primary__dots" />
                            </li>
                            <li className={this.isActiveOrComplete(steps.confirm) ? 'wizard--primary__item is-active' : 'wizard--primary__item'}>
                                <div className="wizard--primary__icon"></div>
                                {readyToSubmit &&
                                    <NavLink className="wizard--primary__link" activeClassName="is-active" to={steps.confirm + '/' + appid} exact>
                                        Form Confirmation
                                    </NavLink>
                                }
                                {!readyToSubmit && <span className="wizard--primary__link">Form Confirmation</span>}
                            </li>
                        </React.Fragment>
                    </ul>
                </div>
            </React.Fragment>
        );
    };
}

NavbarComponent.defaultProps = {
    loadOnMount: true,
};

NavbarComponent.propTypes = {
    location: PropTypes.shape({ pathname: PropTypes.string }).isRequired,
    setIsReadyToSubmit: PropTypes.func,
    setup: PropTypes.object,
    loadOnMount: PropTypes.bool,
};