import React, {Component} from 'react';
import PropTypes from 'prop-types';

class ZipFilterComponent extends Component {

    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
    }

    filterChange = e => {
        const value = e.target.value ? e.target.value : null;

        this.onFilterChange(value)
    };

    onFilterChange(value) {
        this.props.onFilterChanged({
            id: 'zip',
            values: [{key: 'zip', value: value}],
            emptyValue: null
        });
    };

    componentDidUpdate = () => {
        if (this.inputRef.current) {
            this.inputRef.current.focus();
        }
    };    

    render() {
        const zip = this.props.filter.values.zip;

        return (
            <div className="pos--rel">
                <input
                    type="number"
                    placeholder="Zip"
                    className="input input--number input--med"
                    value={zip === null ? '' : zip}
                    id="zip"
                    onChange={this.filterChange}
                    ref={this.inputRef}
                    autoFocus={true}
                />
            </div>
        );
    }
}

ZipFilterComponent.propTypes = {
    filter: PropTypes.object.isRequired,
    onFilterChanged: PropTypes.func.isRequired
};

export default ZipFilterComponent;