export const posSystemList = [
    {
        category: 'POS System.',
        name: 'VRP.',
        supportedIndustries: 'Clothing/shoes/advanced inventory.',
        specialty: 'Robust custom reports/system flexibility.',
        url: 'https://www.visualretailplus.com/',
        supportedProcessors: 'Fiserv (any EMV certification that Cardknox is certified for).',
        supportedGateways: 'Cardknox.',
        supportedDevices: 'Verifone devices (VX805, MX915, MX925).',
        cost: `$1,750 purchase cost per station. $250 annual fee per station (annual fee waived first year). Licensed discounts available for mom-and-pop shops and 10+ stations. Setup, training, and support charged per minute, but must purchase blocks in advance.`,
        resellerCommision: '10% of licenses only.',
        billingPlan: 'Upfront and leasing options available.',
        setupProcess: 'Merchant works directly with VRP to set up and migrate inventory.',
        setupAndSupportBy: 'VRP.',
        additionalInformation: `VRP sells hardware (if needed). Support available for regular business hours. After-hours support available for emergencies only.`,
        addonsAndIntegrations: `Quickbooks, Shopify, and mobile inventory. Tooltip: Note that add-ons could incur extra fees..`,
        note: '',
    },
    {
        category: 'POS System.',
        name: 'Super Menu POS.',
        supportedIndustries: 'Restaurants.',
        specialty: 'Custom software.',
        url: 'http://www.supermenupos.com/',
        supportedProcessors: 'Fiserv (any EMV certification that Cardknox is certified with).',
        supportedGateways: 'Cardknox.',
        supportedDevices: 'Verifone Devices (VX805, MX915, MX925).',
        cost: `Purchase cost is $500 per license. Setup and training is an hourly rate (billed by HiFi and included in the proposal). Support is an hourly rate billed by HiFi.`,
        resellerCommision: 'Agent receives $100.',
        billingPlan: `50% upfront,
        50% upon installation/delivery
        .`,
        setupProcess: 'Merchant works with HiFi to set up and build (or import) the menu.',
        setupAndSupportBy: 'HiFi.',
        additionalInformation: `Hardware sold by HiFi.`,
        addonsAndIntegrations: `UberEats and other food delivery integrations / Kiosk available / Online ordering is fully integrated.`,
        note: '',
    },
    {
        category: 'POS System.',
        name: 'PC America.',
        supportedIndustries: 'Mini market/Wine stores/accessories/chocolate and nuts/bakery.',
        specialty: 'Quick checkout, off-the-shelf system.',
        url: 'https://www.hcomm.us/en/retail-pos/pcamerica',
        supportedProcessors: 'Fiserv Buypass/Nashville/North.',
        supportedGateways: 'Fiserv direct, no gateway middleware.',
        supportedDevices: 'PAX S300.',
        cost: `Software has a one-time licensing fee. Support via PC America is paid annually upfront. For HiFi support, a fee schedule is associated.`,
        resellerCommision: 'Mark up.',
        billingPlan: `50% upfront,
        50% upon installation/delivery.`,
        setupProcess: 'Merchant works with HiFi to set up and build (or import) the menu. Software support available 24/7 from pcAmerica.',
        setupAndSupportBy: 'HiFi or pcAmerica.',
        additionalInformation: `Quick checkout.`,
        addonsAndIntegrations: `UberEats and other food delivery integrations / Kiosk available / Online ordering is fully integrated.`,
        note: '',
    },
    {
        category: 'POS System.',
        name: 'Booker.',
        supportedIndustries: 'Salon/spa.',
        specialty: 'Booking management/inventory.',
        url: 'https://www.booker.com/',
        supportedProcessors: 'Fiserv via Clover Mini.',
        supportedGateways: 'Clover direct, no gateway middleware.',
        supportedDevices: 'Clover Mini.',
        cost: `https://www.booker.com/pricing-service-businesses.`,
        resellerCommision: 'N/A',
        billingPlan: `Monthly.`,
        setupProcess: 'After submitting a lead to Fidelity, contact Booker directly.',
        setupAndSupportBy: 'Booker.',
        additionalInformation: `They refuse to refrain from soliciting our merchants. However, they are the only solution in the industry that Fidelity/Cardknox can work with.`,
        addonsAndIntegrations: '',
        note: '',
    },
    {
        category: 'POS System.',
        name: 'Quickbooks POS.',
        supportedIndustries: 'Retail/judaica/clothing.',
        specialty: 'Retail.',
        url: 'https://quickbooks.intuit.com/point-of-sale/',
        supportedProcessors: 'Dynamic.',
        supportedGateways: `Cardknox
        FideliPAY
        Auth.net
        CardPointe.`,
        supportedDevices: 'Verosa or Instant Accept Plugin (connected hardware varies on plugin selection).',
        cost: `One-time license fee.`,
        resellerCommision: '10% of license.',
        billingPlan: `Negotiated with dealer.`,
        setupProcess: 'Via local support partner.',
        setupAndSupportBy: 'Local dealer.',
        additionalInformation: `Easy to learn. Services a wide variety and some advanced retail.`,
        addonsAndIntegrations: `Quick.`,
        note: '',
    },
    {
        category: 'POS System.',
        name: 'Revel.',
        supportedIndustries: 'Fast Food.',
        specialty: 'Chain stores/kiosks.',
        url: 'https://revelsystems.com/',
        supportedProcessors: 'Processors supported via gateway.',
        supportedGateways: `FideliPAY
        FreedomPay for EMV.`,
        supportedDevices: 'Varies based on gateway selection.',
        cost: `Hardware and setup along with monthly fees.`,
        resellerCommision: '$150 per license.',
        billingPlan: 'Upfront and payment plans available.',
        setupProcess: 'After submitting a lead to Fidelity, contact Revel directly.',
        setupAndSupportBy: 'Revel.',
        additionalInformation: '',
        addonsAndIntegrations: '',
        note: '',
    },
    {
        category: 'POS System.',
        name: 'Linga.',
        supportedIndustries: 'Fine dining/fast food.',
        specialty: 'Chain stores/kiosk/affordable.',
        url: 'https://www.lingapos.com/',
        supportedProcessors: 'Fiserv.',
        supportedGateways: 'NMI.',
        supportedDevices: 'PAX S300.',
        cost: `Software is $49/month per station when paid annually. Monthly and package plans available. The hardware Linga PAX can be purchased via Fidelity. However, any issues with the device must be resolved with Linga.`,
        resellerCommision: 'N/A',
        billingPlan: `Upfront - payment plans available if merchant qualifies.`,
        setupProcess: 'Linga Direct after lead submitted via Fidelity.',
        setupAndSupportBy: 'Linga.',
        additionalInformation: `-Customer can use existing iPads
        -Ability to store card info for customer
        .`,
        addonsAndIntegrations: '',
        note: '',
    },
    {
        category: 'POS System.',
        name: 'Lightspeed.',
        supportedIndustries: 'Retail/restaurant.',
        specialty: 'Retail/restaurant/book store.',
        url: 'https://www.lightspeedhq.com/',
        supportedProcessors: `-Fiserv
        -TSYS.`,
        supportedGateways: '',
        supportedDevices: '',
        cost: `$99/month.`,
        resellerCommision: 'N/A',
        billingPlan: `Monthly.`,
        setupProcess: 'After submitting a lead to Fidelity, contact Lightspeed directly.',
        setupAndSupportBy: 'Lightspeed.',
        additionalInformation: `iPad support.`,
        addonsAndIntegrations: '',
        note: '',
    },
    {
        category: 'POS System.',
        name: 'Vend.',
        supportedIndustries: 'Fashion Boutiques/Homeware.',
        specialty: 'Retail.',
        url: 'https://www.vendhq.com',
        supportedProcessors: `-Fiserv
        -TSYS
        -Elavon.`,
        supportedGateways: 'CardpointeeConduit.',
        supportedDevices: '',
        cost: `$99/month when billed annually. $129/month when billed monthly.`,
        resellerCommision: 'N/A',
        billingPlan: `Annual and month-to-month pricing available.`,
        setupProcess: 'After submitting a lead to Fidelity, contact Vend directly.',
        setupAndSupportBy: 'Vend.',
        additionalInformation: `Cloud-based system that integrates with Shopify and other ecommerce platforms. 14-day trial period.`,
        addonsAndIntegrations: '',
        note: '',
    },
    {
        category: 'POS System.',
        name: 'Curbngo.',
        supportedIndustries: 'Restaurants.',
        specialty: 'Kiosk and mobile apps.',
        url: 'http://curbngo.com/',
        supportedProcessors: 'Stripe (Cardknox soon).',
        supportedGateways: 'Stripe (Cardknox soon).',
        supportedDevices: '',
        cost: `$150/month per device. No upfront fee. Includes hardware.`,
        resellerCommision: 'N/A',
        billingPlan: `Monthly.`,
        setupProcess: 'After submitting a lead to Fidelity, contact Curbngo directly.',
        setupAndSupportBy: 'Curbngo.',
        additionalInformation: `Intuitive software.`,
        addonsAndIntegrations: '',
        note: '',
    },
];