import React from 'react';
import { withLoader } from "./../../common/components";

class MerchantProcessingComponent extends React.Component {
    componentDidMount() {
        this.props.showLoader(true);
    }

    componentWillUnmount() {
        this.props.showLoader(false);
    }

    render() {
        return (
            <div id="main-div" className="l--content">
                <div className="note note--default">
                    Thank you - please wait while we process your signature documents...
                </div>                
            </div>
        );
    }
}

export default withLoader(MerchantProcessingComponent);