import React, {Component} from 'react';
import PropTypes from 'prop-types';

class EAppStatusSelectionComponent extends Component {
    renderFilterText = () => {
        const { values } = this.props.filter;
        let valuesArray = Object.entries(values);

        return valuesArray.reduce((txt, val) => {
            if (!val[1]) return txt;
            let newVal = txt ? txt + ', ' + val[0] : val[0];

            if(newVal === 'Incomplete')
                newVal = 'eAppStarted';
            if(newVal === 'Pending')
                newVal = 'eAppSubmitted';

            return newVal;
        }, '');
    };

    handleFilterRemoved = () => {
        this.props.onFilterRemoved({
            id: 'merchantStatus',
            emptyValue: false
        })
    }

    render() {
        return (
            <div className="filter__tag">
                {this.renderFilterText()}
                <button onClick={this.handleFilterRemoved} className="btn btn--reset filter__tag__clear">
                </button>
            </div>
        );
    }
}

EAppStatusSelectionComponent.propTypes = {
    filter: PropTypes.object,
    onFilterRemoved: PropTypes.func,
};

export default EAppStatusSelectionComponent;