import React from 'react';
import PropTypes from 'prop-types';
import { find, each, filter, some, isArray, findIndex } from 'lodash';

import { OutsideClick } from './../utilities';

class MultiselectCardknoxFilter extends React.Component {
	constructor(props) {
		super(props);
		this.getOptions = this.getOptions.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.filterValues = this.filterValues.bind(this);
		this.state = {
			options: this.getOptions(),
			rawValue: '',
			placeholder: '',
			isInputFocus: false,
			inputLabel: null,
			filteredOptions: [],
			searchString: '',
		};
		this.lastOptionRef = React.createRef();
	}

	componentWillReceiveProps(newProps) {
		this.setState({ options: this.getOptions(newProps) });
	}

	getOptions(newProps) {
		let props = newProps || this.props;
		let options = props.getValidFilterValues(props.column.key, props.column.filterKey);
		options = options.map(o => {
			if (typeof o === 'string') {
				return { value: o, label: o, isDefault: false };
			} else if (typeof o === 'object') {
				return { value: o.key, label: o.label, isDefault: o.isDefault };
			}
			return o;
		});
		return options;
	}

	columnValueEqualsSearchTerms(columnValue, filterTermValue, isDefault) {
		if (columnValue !== undefined && filterTermValue !== undefined) {
			let strColumnValue = columnValue.toString();
			if (isDefault) {
				const { options } = this.state;
				const otherOptions = filter(options, option => !option.isDefault);
				return (
					columnValue !== '' &&
					!some(otherOptions, ({ value }) => this.columnValueEqualsSearchTerms(columnValue, value, false))
				);
			} else {
				if (isArray(filterTermValue)) {
					const checkValue = some(filterTermValue, item => {
						return strColumnValue.trim().toLowerCase() === item.trim().toLowerCase();
					});
					return checkValue;
				} else {
					return strColumnValue.trim().toLowerCase() === filterTermValue.toString().trim().toLowerCase();
				}
			}
		}
		return false;
	}

	filterValues(row, columnFilter, columnKey) {
		let include = true;
		if (columnFilter === null) {
			include = false;
		} else if (columnFilter.filterTerm) {
			// && !isEmptyArray(columnFilter.filterTerm)
			if (columnFilter.filterTerm.length) {
				include = columnFilter.filterTerm.some(filterTerm => {
					return this.columnValueEqualsSearchTerms(row[columnKey], filterTerm.value, filterTerm.isDefault) === true;
				});
			} else {
				include = this.columnValueEqualsSearchTerms(
					row[columnKey],
					columnFilter.filterTerm.value,
					columnFilter.filterTerm.isDefault
				);
			}
		}
		return include;
	}

	handleChange(value) {
		let filters = value;
		this.setState({ filters });
		this.props.onChange({
			filterTerm: filters,
			column: this.props.column,
			rawValue: value,
			filterValues: this.filterValues,
		});
	}

	/* Our logic below */

	addValueToFilter = item => {
		const isMulti =
			this.props.multiSelection !== undefined && this.props.multiSelection !== null ? this.props.multiSelection : true;
		let filters;
		if (isMulti) {
			filters = this.state.filters || [];
		} else {
			filters = [];
		}
		if (!some(filters, ({ value }) => value === item.value)) {
			filters.push(item);
			this.handleChange(filters);
		}
		this.updateInputLabel(filters);
	};

	removeValueFromFilter = item => {
		let filters = this.state.filters;
		const index = findIndex(filters, ({ value }) => value === item.value);
		filters.splice(index, 1);
		if (filters.length < 1) {
			this.handleChange(undefined);
		} else {
			this.handleChange(filters);
		}
		this.updateInputLabel(filters);
	};

	updateInputLabel = filters => {
		let value;
		if (filters.length === 1) {
			value = filters[0].label;
		} else if (filters.length > 1) {
			value = `${filters.length} items`;
		} else {
			value = null;
		}

		this.setState({
			inputLabel: value,
		});
	};

	handleInputChange = e => {
		const { options } = this.state;
		const searchString = e.target.value;
		let filteredOptions;
		if (searchString && searchString.length) {
			filteredOptions = [];
			for (let option of options) {
				if (filteredOptions.indexOf(option) === -1) {
					let x = option.label.toLowerCase().indexOf(e.target.value.toLowerCase());
					if (x > -1) {
						filteredOptions.push(option);
					}
				}
			}
		} else {
			filteredOptions = options;
		}

		this.setState({
			filteredOptions: filteredOptions,
			searchString: searchString,
		});
	};

	renderOptionForDropdown = (item, filters, isLast) => {
		if (typeof filters === 'undefined') {
			filters = [];
		}

		let funcToCall, activeClass, checked;
		const exists = !!find(filters, { ...item });
		if (!exists) {
			funcToCall = this.addValueToFilter;
			activeClass = '';
			checked = false;
		} else {
			funcToCall = this.removeValueFromFilter;
			activeClass = 'is-active';
			checked = true;
		}

		return (
			<div key={item.value} className={`grid__multiselect__item ${activeClass}`}>
				<input
					id={item.value}
					ref={isLast ? this.lastOptionRef : null}
					type="checkbox"
					className="input--check"
					checked={checked}
					onChange={() => funcToCall(item)}
				/>
				<label htmlFor={item.value} className="align--v--middle">
					{item.label}
				</label>
			</div>
		);
	};

	renderOptionsDropDown = () => {
		const { options, filters, searchString } = this.state;

		let filteredOptions;
		if (!searchString) {
			filteredOptions = options;
		} else {
			filteredOptions = this.state.filteredOptions;
		}

		if (filteredOptions && filteredOptions.length > 0) {
			let output = [];
			each(filteredOptions, (option, index) => {
				const isLast = index === filteredOptions.length - 1;
				output.push(this.renderOptionForDropdown(option, filters, isLast));
			});
			return output;
		} else {
			return (
				<div className="grid__multiselect__item ">
					<div className="align--v--middle">No options</div>
				</div>
			);
		}
	};

	focusInput = () => {
		if (this.inputRef) {
			this.inputRef.focus();
		}
	};

	handleFocus = () => {
		this.setState({ isInputFocus: true });
	};

	blurInput = () => {
		this.setState({
			isInputFocus: false,
			searchString: '',
		});
	};

	clearFilters = () => {
		this.handleChange(undefined);
		this.setState({
			filters: undefined,
			filteredOptions: [],
			searchString: '',
			inputLabel: null,
		});
	};

	handleTab = e => {
		if (e.key == 'Tab' && document.activeElement === this.lastOptionRef.current) {
			this.blurInput();
		}
	};

	render() {
		return (
			<div onKeyDown={this.handleTab}>
				<React.Fragment>
					<OutsideClick action={this.blurInput}>
						<div className="pos--rel">
							<div className="form-group">
								<input
									ref={el => (this.inputRef = el)}
									type="text"
									name={`filter-${this.props.column.key}`}
									placeholder={this.state.inputLabel ? '' : this.state.placeholder}
									className="input input--med input-sm-multiselect"
									onFocus={this.handleFocus}
									onChange={this.handleInputChange}
									value={this.state.searchString}
								/>
							</div>

							{!this.state.isInputFocus || !this.state.searchString ? (
								<span onClick={this.focusInput} className="grid__multiselect__result">
									{this.state.inputLabel}
								</span>
							) : null}
							{this.state.isInputFocus ? <div className="grid__multiselect">{this.renderOptionsDropDown()}</div> : null}
						</div>
					</OutsideClick>
				</React.Fragment>
			</div>
		);
	}
}

MultiselectCardknoxFilter.propTypes = {
	onChange: PropTypes.func.isRequired,
	column: PropTypes.any,
	getValidFilterValues: PropTypes.func,
	multiSelection: PropTypes.bool,
};

export default MultiselectCardknoxFilter;
