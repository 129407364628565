import React, { Component, Fragment } from "react";
import {map, range, includes, cloneDeep, find, slice, filter, get, toLower } from 'lodash'
import moment from "moment";
import Menu, { SubMenu, Item as MenuItem } from 'rc-menu';
import { NavLink } from "react-router-dom";
import { appService } from "../../services/appService";

const monthsConstants = [
    { key: 0, name: "January", selected: false },
    { key: 1, name: "February", selected: false },
    { key: 2, name: "March", selected: false },
    { key: 3, name: "April", selected: false },
    { key: 4, name: "May", selected: false },
    { key: 5, name: "June", selected: false },
    { key: 6, name: "July", selected: false },
    { key: 7, name: "August", selected: false },
    { key: 8, name: "September", selected: false },
    { key: 9, name: "October" , selected: false},
    { key: 10, name: "November", selected: false },
    { key: 11, name: "December", selected: false }
  ]

class ResidualsFilterComponent extends Component{
    constructor(props){
        super(props)

        this.state = {
            dateFilter:{
                years: null,
                months: null,
                lastMonthDate: null,
                selectedYear: null,
                year: null,
                month: null
            },
            agents: [],
            selectedAgent: null,
            agentsCopy: [],
            agentSearchQuery: null,
			filters: cloneDeep(props.filters),
        }
    }

    async componentDidMount() {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const agentId = params.get('agentId');
        let lastMonthThatHasData = await appService.getLastAvailableMonth(agentId);
        let currentDate = moment(new Date(lastMonthThatHasData.year, lastMonthThatHasData.month-1));
        let currentYear = currentDate.year();
        let years = range(currentYear, 2020, -1)
        let lastMonthDate = currentDate;
        let lastMonthYear = lastMonthDate.year();
        let months = monthsConstants;
        let lastMonth = find(months, month => month.key === lastMonthDate.month())
        lastMonth.selected = true;

        this.setState({dateFilter: { months, years, selectedYear: lastMonthYear, lastMonthDate: lastMonthDate, year: lastMonthYear, month: lastMonthDate.month(), }}, () => {
            this.props.enableFetch()
            this.onApplyDateFilter()
        })
    }

    componentWillReceiveProps(nextProps){
        const {agents} = this.state;
        if(agents.length === 0){
            this.setState({agents: nextProps.agents, agentsCopy: nextProps.agents})
        }
    }

    selectMonth = (item, lastMonth = false) => {
        const {dateFilter} = this.state;
        let dateFilterClone = cloneDeep(dateFilter);
        map(dateFilterClone.months, month => {
            if(month.key === item.key){
                month.selected = !month.selected || lastMonth;
            }else{
                month.selected = false;
            }
            return month;
        });
        if (lastMonth) {dateFilterClone.selectedYear = dateFilter.lastMonthDate.year();}
        this.setState({ dateFilter: dateFilterClone }, () => lastMonth && this.onApplyDateFilter())
    }

    syncFilters = () => {
		this.setState({
			filters: cloneDeep(this.props.filters),
		});
	};

    handleYearChange = (year) =>{
        const {dateFilter} = this.state;
        let dateFilterClone = cloneDeep(dateFilter);
        dateFilterClone.selectedYear = year
        map(dateFilterClone.months, month => {
            month.selected = false;
            return month;
        })
        this.setState({dateFilter: dateFilterClone});
    }
    
    onApplyDateFilter = () => {
        const { dateFilter } = this.state;
        let dateFilterClone = cloneDeep(dateFilter);
        let filtersClone = cloneDeep(this.props.filters);
        let yearFilter = find(filtersClone, (filterC) => filterC.key === "year")
        let monthFilter = find(filtersClone, (filterC) => filterC.key === "month")
        
        let selectedMonth = find(dateFilterClone.months, "selected");

        if(selectedMonth){
            dateFilterClone.year = dateFilterClone.selectedYear;
            dateFilterClone.month = selectedMonth.key;
            yearFilter.values.year = dateFilterClone.year;
            monthFilter.values.month = dateFilterClone.month + 1;
        }else{
            dateFilterClone.year = null;
            dateFilterClone.month = null;
            yearFilter.values.year = null;
            monthFilter.values.month = null;
        }

        this.setState({dateFilter: dateFilterClone, selectedAgent: null })
        this.props.updateFilters({
            filters: filtersClone,
            activeFilters: filtersClone,
        })
    }
    
    onResetDateFilter = () => {
        const {dateFilter} = this.state;
        let dateFilterClone = cloneDeep(dateFilter);
        let selectedMonth = find(dateFilterClone.months, "selected");
        if(selectedMonth){
            selectedMonth.selected = false;
        }
        dateFilterClone.year = null;
        dateFilterClone.month = null;
        this.setState({dateFilter: dateFilterClone, selectedAgent: null })

        let filtersClone = cloneDeep(this.props.filters);
        let yearFilter = find(filtersClone, (filterC) => filterC.key === "year")
        let monthFilter = find(filtersClone, (filterC) => filterC.key === "month")
        yearFilter.values.year = null;
        monthFilter.values.month = null;
        
        this.props.updateFilters({
            filters: filtersClone,
            activeFilters: filtersClone,
        })
        
    }

    isMonthDisabled = (index) => {
        const {dateFilter:{selectedYear, lastMonthDate}} = this.state;
        return selectedYear === lastMonthDate.year() && index > lastMonthDate.month();
    } 

    handleAgentChange = (agentNumber) =>{
        const { agents, selectedAgent} = this.state;
        let nextSelectedAgent = null
        if(agentNumber !== get(selectedAgent,"agentNumber", null)){
            nextSelectedAgent = find(agents, agent => agent.agentNumber === agentNumber);
        }
        this.setState({selectedAgent: nextSelectedAgent}, () => this.props.setResidualsData(nextSelectedAgent))
    }

    handleChangeAgentSearchQuery = (e) => {
        const { agentsCopy } = this.state;

        let searchQuery = get(e, "target.value", null);
        let filteredAgents = filter(agentsCopy, (agent) => includes(toLower(agent.name), toLower(searchQuery)))
        if(searchQuery)
            this.setState({agentSearchQuery: searchQuery, agents: cloneDeep(filteredAgents)})
        else{    
            this.setState({agentSearchQuery: searchQuery, agents: cloneDeep(agentsCopy)})
        }
    }


    renderMonthSelectionFilter = () => {
        const {dateFilter:{months, years, year, month, lastMonthDate, selectedYear}} = this.state;

        return(
            <Menu disabledOverflow={true} mode={'horizontal'} motion={'slide-up'} triggerSubMenuAction={'click'}>
                <SubMenu title={year !== null && month !== null ? `${month+1}/${year}` : "Select month"}>
                    <MenuItem key={lastMonthDate} onClick={() => this.selectMonth({key: lastMonthDate.month()}, true)}>Last month</MenuItem>
                    <SubMenu title={"Custom"} mode={'vertical-right'} key={'custom'}>
                        <div className="popover__header">
                            <button className="popover__header__button" disabled={!includes(years, selectedYear - 1)} onClick={() => this.handleYearChange(selectedYear - 1)} value={"previous"}>
                                <i className="icon icon--micro icon--arrow--left--grey"></i>
                            </button>
                            <span>{selectedYear}</span>
                            <button className="popover__header__button" disabled={!includes(years, selectedYear + 1)} onClick={() => this.handleYearChange(selectedYear + 1)} value={"next"}>
                                <i className="icon icon--micro icon--arrow--right--grey"></i>
                            </button>
                        </div>
                        <div className="popover__body popover__body--months">
                            {map(months, (month) => 
                                <MenuItem key={month.key} disabled>
                                    <button disabled={this.isMonthDisabled(month.key)} onClick={(e) => {
                                        this.selectMonth(month)

                                    } } className={`${month.selected ? "is-selected":""}`}>{slice(month.name, 0, 3)}</button>
                                </MenuItem>)}
                        </div>
                        
                        <div className="popover__footer">
                            <MenuItem key="date_button_reset" style={null}>
                                <button type="button" onClick={this.onResetDateFilter} className="btn btn--reset">
                                    Reset
                                </button>
                            </MenuItem>
                            <MenuItem key="date_button_done">
                                <button
                                    type="button"
                                    onClick={this.onApplyDateFilter}
                                    className="type--wgt--bold type--uppercase type--color--primary"
                                >
                                    Done
                                </button>
                            </MenuItem>
                        </div>
                    </SubMenu>
                </SubMenu>
            </Menu>
        )      
    }

    renderAgentSelectionFilter = () =>{
        const { agents, agentSearchQuery, selectedAgent } = this.state;
        const { isLoadingAgents, singleAgentName } = this.props;

        return (
            <Fragment>
                {singleAgentName ? 
                <div className="filter__select--single">{singleAgentName}</div> : (            
                <Menu disabledOverflow={true} mode={'horizontal'} motion={'slide-up'} triggerSubMenuAction={'click'}>
                <SubMenu title={selectedAgent ? selectedAgent.name : "My Residuals"}>
                    <MenuItem disabled className="popover--reset">
                    <div className="popover__header">
                        <input
                            type="text"
                            name="columnFilter"
                            className="input input--sml input--search"
                            placeholder="Search"
                            id={`$search`}
                            value={agentSearchQuery}
                            onChange={this.handleChangeAgentSearchQuery}
                        />
                    </div>
                    <div className="popover__body popover__body--list">
                        {isLoadingAgents ?
                            (
                            <MenuItem key="loading" disabled={true}>
                                <span>Loading...</span>
                            </MenuItem>) : 
                            (
                            <Fragment>
                                {map(agents, (agent, i) => {
                                    return (
                                        <div key={i} className={`item ${ get(selectedAgent,"agentNumber", null) === agent.agentNumber  ? "is-selected" : ""}`} id={agent.agentNumber}>
                                            <div className="label" onClick={() => this.handleAgentChange(agent.agentNumber)}>
                                                <label
                                                    name={agent.id}
                                                    id={`agent-select-${agent.agentNumber}`}
                                                />
                                                <label htmlFor={`agent-select-${agent.agentNumber}`}>{agent.name}</label>
                                            </div>
                                            <NavLink activeClassName="is-active" className="display--f" to={{pathname:`/residuals?agentId=${agent.id}&agentName=${encodeURIComponent(agent.name)}&agentNumber=${agent.agentNumber}`}} target={"_blank"}>
                                                <i className={`icon icon--nano icon--open-new`}></i>
                                            </NavLink>
                                        </div>
                                    );
                                })}
                            </Fragment>
                        )}
                    </div>
                </MenuItem>
            </SubMenu>
            </Menu>)}
            </Fragment>
        )
    }

    render = () => {
        return <Fragment>
            <div className="filter__date filter__date--square spc--bottom--sml spc--right--xsml" tabIndex={0}>
                {this.renderMonthSelectionFilter()}
            </div>
            <div className="filter__select filter__select--view spc--bottom--sml">
                {this.renderAgentSelectionFilter()}
            </div>
        </Fragment>
    }
}
export default ResidualsFilterComponent;