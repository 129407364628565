import React from 'react';
import moment from 'moment';
import { map } from 'lodash'
import MonthSelector from './MonthSelector';

class ElavonStatements extends React.Component {

    renderStatementData() {
        const { statementData, disclaimer } = this.props.data;

        return (<div className="">
            <p className="type--color--grey4 spc--bottom--med">
                {disclaimer}
            </p>
            <pre>{statementData}</pre>
        </div>
        )
    }

    render() {
        const { months, currentMonth, handleMonthSelect } = this.props;
        return (
            <div>
                <div className="accessories__filter">
                    <MonthSelector months={months} currentMonth={currentMonth} onChange={handleMonthSelect} />
                </div>
                {this.props.data ? this.renderStatementData() : null}
            </div>
        );
    }
}

export default ElavonStatements;
