import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get, uniqueId, inRange, cloneDeep, size, split, map, last, join } from 'lodash';

class ChallengeInput extends Component {
	constructor(props) {
		super(props);
		this.state = {
			inputs: [
				{ key: uniqueId('input'), value: '', ref: React.createRef() },
				{ key: uniqueId('input'), value: '', ref: React.createRef() },
				{ key: uniqueId('input'), value: '', ref: React.createRef() },
				{ key: uniqueId('input'), value: '', ref: React.createRef() },
				{ key: uniqueId('input'), value: '', ref: React.createRef() },
				{ key: uniqueId('input'), value: '', ref: React.createRef() },
			],
		};
		this.elements = Array(6)
			.fill()
			.map(() => React.createRef());
	}

	get challengeAnswer() {
		return join(map(this.state.inputs, 'value'), '');
	}

	focusFirstInput = () => {
		if (this.elements[0]) {
			this.elements[0].current.focus();
		}
	};

	clearInputs = () => {
		this.setState(
			{
				inputs: this.state.inputs.map(input => ({ ...input, value: '' })),
			},
			this.focusFirstInput
		);
	};

	handleKeyDown = (e, i) => {
		if (inRange(e.key, 0, 10)) {
			this.setState(
				prevState => {
					const inputs = cloneDeep(prevState.inputs);
					inputs[i].value = e.key;
					return { inputs };
				},
				() => {
					if (i === this.state.inputs.length - 1) {
						const confirmButton = document.querySelector('button[type="submit"]');
						if (confirmButton) {
							confirmButton.focus();
						}
					} else if (this.elements[i + 1]) {
						this.elements[i + 1].current.focus();
					}
				}
			);
		} else if (e.key === 'Backspace') {
			const currentValue = get(this.state.inputs[i], 'value', '');
			this.setState(
				prevState => {
					const inputs = cloneDeep(prevState.inputs);
					inputs[i].value = '';
					return { inputs };
				},
				() => {
					if (this.elements[i - 1] && !currentValue) {
						this.elements[i - 1].current.focus();
					}
				}
			);
		}
	};

	handlePaste = async e => {
		e && e.preventDefault();
		const paste = e.clipboardData.getData('text');
		if (!/^\d+$/.test(paste)) {
			return;
		}
		const pasteArray = split(paste, '');
		if (size(pasteArray) === size(this.elements)) {
			const inputs = map(pasteArray, value => ({ value }));
			this.setState({ inputs }, async () => {
				last(this.elements).current.focus();
				if (!this.props.disablePasteSubmit) {
					await this.props.onConfirm(e);
				}
			});
		}
	};

	componentDidMount() {
		if (!this.props.disablePasteSubmit) {
			window.addEventListener('paste', this.handlePaste);
		}
	}

	componentWillUnmount() {
		if (!this.props.disablePasteSubmit) {
			window.removeEventListener('paste', this.handlePaste);
		}
	}

	render() {
		return (
			<div className="input--security">
				{this.state.inputs.map((input, i) => (
					<input
						autoFocus={i === 0}
						type="number"
						className="input"
						placeholder="0"
						value={input.value}
						key={input.key}
						ref={this.elements[i]}
						onKeyDown={e => this.handleKeyDown(e, i)}
						onPaste={this.handlePaste}
					/>
				))}
			</div>
		);
	}
}

ChallengeInput.propTypes = {
	onConfirm: PropTypes.func.isRequired,
	disablePasteSubmit: PropTypes.bool,
};

export default ChallengeInput;
