import React, {Component} from 'react';
import PropTypes from 'prop-types';

class TicketNumberSelectionComponent extends Component {

    get ticketNumberValue() {
        return this.props.filter.values.ticketNumber;
    }

    filterRemoved = e => {
        this.props.onFilterRemoved({
            id: 'ticketNumber',
            emptyValue: null
        });
    };

    render() {
        return (
            <div className="filter__tag">
                <label className="filter__tag__label">Ticket Number: </label>
                <div className="filter__tag__value">
                    {this.ticketNumberValue}
                </div>
                <button onClick={this.filterRemoved} className="btn btn--reset filter__tag__clear"></button>
            </div>
        );
    }
}

TicketNumberSelectionComponent.propTypes = {
    filter: PropTypes.object.isRequired,
    onFilterRemoved: PropTypes.func.isRequired
};

export default TicketNumberSelectionComponent;