import { cloneDeep } from 'lodash';
import { MPARequiredTemplate } from './MPARequiredTemplate.validation'

const ssnRequired = (val, ctx) => ctx.signerInformation.identificationType || ctx.signerInformation.identificationNumber || ctx.signerInformation.identificationIssueDate || ctx.signerInformation.identificationExpirationDate ? true : !!val;
const idRequired = (val, ctx) => ctx.signerInformation.identificationType || ctx.signerInformation.identificationNumber || ctx.signerInformation.identificationIssueDate || ctx.signerInformation.identificationExpirationDate ? !!val : true;

export const CanadaMPARequiredTemplate = cloneDeep(MPARequiredTemplate);

CanadaMPARequiredTemplate.businessInformation.businessAddress.state.message = '[**Business Province**](javascript:void)';
CanadaMPARequiredTemplate.corporateAddress.state.message = '[**Mailing Province**](javascript:void)';
CanadaMPARequiredTemplate.signerInformation.address.state.message = '[**Signer Province**](javascript:void)';
CanadaMPARequiredTemplate.businessInformation.businessAddress.zip.message = '[**Business Postal Code**](javascript:void)';
CanadaMPARequiredTemplate.corporateAddress.zip.message = '[**Mailing Postal Code**](javascript:void)';
CanadaMPARequiredTemplate.signerInformation.address.zip.message = '[**Signer Postal Code**](javascript:void)';
delete CanadaMPARequiredTemplate.bankAccountInformation.bankName;
delete CanadaMPARequiredTemplate.bankAccountInformation.routingNumber;

CanadaMPARequiredTemplate.signerInformation.socialSecurityNumber = {
    type: String,
    required: false,
    use: { ssnRequired },
    message: '[**Social Insurance Number**](javascript:void)'
}

CanadaMPARequiredTemplate.signerInformation.identificationType = {
    use: { idRequired },
    type: String,
    message:  '[**Identification Type**](javascript:void)'
}
CanadaMPARequiredTemplate.signerInformation.identificationNumber = {
    use: { idRequired },
    type: String,
    message: '[**Identification Number**](javascript:void)'
}
CanadaMPARequiredTemplate.signerInformation.identificationIssueDate = {
    use: { idRequired },
    type: Date,
    message: '[**Identification Issue Date**](javascript:void)'
}
CanadaMPARequiredTemplate.signerInformation.identificationExpirationDate = {
    use: { idRequired },
    type: Date,
    message: '[**Identification Expiration Date**](javascript:void)'
}
CanadaMPARequiredTemplate.bankAccountInformation.institutionNumber = {
    required: true,
    type: String,
    message: '[**Bank Institution Number**](javascript:void)'
}
CanadaMPARequiredTemplate.bankAccountInformation.transitNumber = {
    required: true,
    type: String,
    message: '[**Bank Transit Number**](javascript:void)'
}
