import { toLower, trim, includes } from 'lodash';
import moment from 'moment';
import {
	url,
	incorrectCharacters,
	maxLength,
	digitLength,
	ascii,
	phoneNumber,
	addressPOBox,
	ownershipTypeCorpName,
	alphaOnly,
	email,
	MASKED_VALUE,
} from './validators';
import { addressValidation } from './address.validation';

const outputFormat = process.env.REACT_APP_DISPLAY_DATE_FORMAT;
const containsSpaces = val => val.indexOf(' ') === -1;
const isNotGovernmentOrPublic = (_, ctx) => !includes(['GovernmentEntity', 'PubliclyTraded'], ctx.ownershipType);
const triggerValidationIfMaskedChanged = validationMethod => (val, ctx, path) =>
	val === MASKED_VALUE ? true : validationMethod(val, ctx, path);
const validateIf = (validator, predicate) => (val, ctx) => (predicate(val, ctx) ? validator(val, ctx) : true);
const date = val => {
	if (!val) {
		return true;
	}

	return moment(val, outputFormat, true).isValid() && dob(val);
};
const dob = val => {
	if (!val) {
		return true;
	}

	return moment(val, outputFormat).isAfter(moment(new Date(0, 0, 0, 0)));
};
const is18YearsOld = val => {
	if (!val) {
		return true;
	}

	return (
		moment(val, outputFormat).isBefore(moment().subtract(18, 'years')) ||
		moment(val, outputFormat).isSame(moment().subtract(18, 'years'))
	);
};
const agentName = val => {
	if (!val) {
		return true;
	}

	return toLower(trim(val)) !== 'unknown';
};
function goAddressValidation(prefix) {
	const validation = addressValidation(prefix, 64, 64);
	const { length, ...zipValidators } = validation.zip.use;
	return {
		...validation,
		zip: {
			...validation.zip,
			use: { ...zipValidators, digitLength: digitLength(5) },
			message: {
				...validation.zip.message,
				digitLength: `[**${prefix}Zip Code**](javascript:void) is invalid`,
			},
		},
	};
}

export const goPlusEappFormTemplate = {
	agentName: {
		type: String,
		required: true,
		use: { agentName },
		message: {
			required: '[**Fidelity Sales Rep Name**](javascript:void) is required',
			agentName: '[**Fidelity Sales Rep Name**](javascript:void) is invalid',
		},
	},
	agentEmail: {
		type: String,
		required: true,
		use: { email: email(false) },
		message: {
			required: '[**Fidelity Sales Rep Email Address**](javascript:void) is required',
			email: '[**Fidelity Sales Rep Email Address**](javascript:void) is invalid',
		},
	},
	corporateName: {
		type: String,
		use: { ascii },
		message: {
			ascii: '[**Company Legal Name**](javascript:void) contains invalid characters',
		},
	},
	dbaName: {
		type: String,
		required: true,
		use: { ascii },
		message: {
			required: '[**DBA**](javascript:void) is required',
			ascii: '[**DBA**](javascript:void) contains invalid characters',
		},
	},
	taxId: {
		type: String,
		use: { taxid: digitLength(9) },
		message: {
			taxid: '[**EIN**](javascript:void) is invalid',
		},
	},
	website: {
		type: String,
		use: { url, incorrectCharacters, length: maxLength(64) },
		message: {
			url: '[**Website**](javascript:void) is invalid',
			incorrectCharacters:
				"The [**url**](javascript:void) contains one of the following sets of characters which are not allowed; 'google', 'index', 'gmail', or 'yahoo'.",
			length: '[**Website**](javascript:void) is invalid. Maximum number of characters allowed is 64.',
		},
	},
	businessPhone: {
		type: String,
		use: { phoneNumber },
		message: {
			phoneNumber: '[**Primary Business Phone number**](javascript:void) is invalid',
		},
	},
	businessEmail: {
		type: String,
		required: true,
		use: { email: email(false) },
		message: {
			required: '[**Business Email**](javascript:void) is required',
			email: '[**Business Email**](javascript:void) is invalid',
		},
	},
	ownershipType: {
		type: String,
		use: { ownershipTypeCorpName: ownershipTypeCorpName('SoleProprietor') },
		message: {
			ownershipTypeCorpName:
				'[**Ownership Type**](javascript:void) Sole Proprietor is not allowed on Incorporated Business or Limited Liability Company',
		},
	},
	businessStartDate: {
		type: String,
		use: { date },
		message: {
			date: '[**Business Start Date**](javascript:void) is invalid',
		},
	},
	businessAddress: goAddressValidation('Business '),
	mailingAddress: goAddressValidation('Mailing '),
	productSold: {
		type: String,
		use: { length: maxLength(64), ascii },
		message: {
			length: '[**Products/Services Sold**](javascript:void) is invalid. Maximum number of characters allowed is 64.',
			ascii: '[**Products/Services Sold**](javascript:void) contains invalid characters',
		},
	},
	bankingInformation: {
		bankName: {
			type: String,
			use: { ascii },
			message: {
				ascii: '[**Bank Name**](javascript:void) contains invalid characters',
			},
		},
		routingNumber: {
			type: String,
			use: { routingNumber: triggerValidationIfMaskedChanged(digitLength(9)) },
			message: {
				routingNumber: '[**Routing Number**](javascript:void) is invalid',
			},
		},
		accountNumber: {
			type: String,
			use: { accountNumber: triggerValidationIfMaskedChanged(digitLength(6, 17)) },
			message: {
				accountNumber: '[**Account Number**](javascript:void) is invalid',
			},
		},
	},
	signerInformationList: [
		{
			firstName: {
				type: String,
				use: { containsSpaces, length: maxLength(24), alphaOnly },
				message: {
					containsSpaces: '[**First Name**](javascript:void) cannot contain spaces',
					length: '[**First Name**](javascript:void) is invalid. Maximum number of characters allowed is 24.',
					alphaOnly: '[**First Name**](javascript:void) is invalid. Only alphabetic characters allowed.',
				},
			},
			lastName: {
				type: String,
				use: { containsSpaces, length: maxLength(24), alphaOnly },
				message: {
					containsSpaces: '[**Last Name**](javascript:void)  cannot contain spaces',
					length: '[**Last Name**](javascript:void) is invalid. Maximum number of characters allowed is 24.',
					alphaOnly: '[**Last Name**](javascript:void) is invalid. Only alphabetic characters allowed.',
				},
			},
			ssn: {
				type: String,
				use: {
					ssn: triggerValidationIfMaskedChanged(validateIf(digitLength(9), isNotGovernmentOrPublic)),
					ascii: triggerValidationIfMaskedChanged(validateIf(ascii, isNotGovernmentOrPublic)),
				},
				message: {
					ssn: '[**Signer SSN**](javascript:void) is invalid',
					ascii: '[**Signer SSN**](javascript:void) is invalid',
				},
			},
			dateOfBirth: {
				type: String,
				use: {
					dob: triggerValidationIfMaskedChanged(validateIf(dob, isNotGovernmentOrPublic)),
					is18YearsOld: triggerValidationIfMaskedChanged(validateIf(is18YearsOld, isNotGovernmentOrPublic)),
				},
				message: {
					dob: '[**Date of Birth**](javascript:void) is invalid',
					is18YearsOld: '[**Date of Birth**](javascript:void) must be at least 18 years old',
				},
			},
			cellPhone: {
				type: String,
				use: { phoneNumber },
				message: {
					phoneNumber: '[**Signer Cell Phone**](javascript:void) is invalid',
				},
			},
			address: {
				...goAddressValidation(''),
				streetAddress: {
					use: { ascii, length: maxLength(64), addressPOBox },
					type: String,
					message: {
						required: `[**Address**](javascript:void) is required`,
						ascii: `[**Address**](javascript:void) contains invalid characters`,
						length: `[**Address**](javascript:void) is invalid. Maximum number of characters allowed is 64.`,
						addressPOBox: `[**Address**](javascript:void) cannot be a PO Box address`,
					},
				},
			},
		},
	],
};

const range = (min, max) => val => {
	if (!val) {
		val = 0;
	}
	return val >= min && val <= max;
};

export const goPlusAchqEappFormTemplate = {
	...goPlusEappFormTemplate,
	processingInformation: {
		achTransactionFee: {
			type: String,
			use: { range: range(0, 10.0) },
			message: {
				range: '[**ACH Transaction Fee**](javascript:void) must be between 0 and 10.0',
			},
		},
		achPercentFee: {
			type: String,
			use: { range: range(0, 10.0) },
			message: {
				range: '[**ACH Percent Fee**](javascript:void) must be between 0 and 10.0',
			},
		},
		achPercentFeeCap: {
			type: String,
			use: { range: range(0, 9999.0) },
			message: {
				range: '[**ACH Percent Fee Cap**](javascript:void) must be between 0 and 9,999',
			},
		},
		achSetupFee: {
			type: String,
			use: { range: range(0, 250.0) },
			message: {
				range: '[**ACH Setup Fee**](javascript:void) must be between 0 and 250.0',
			},
		},
		achReturnFee: {
			type: String,
			use: { range: range(0.01, 50.0) },
			message: {
				range: '[**ACH Return Fee**](javascript:void) must be between 0.01 and 50.0',
			},
		},
		achUnauthorizedReturnFee: {
			type: String,
			use: { range: range(0.01, 50.0) },
			message: {
				range: '[**ACH Unauthorized Return Fee**](javascript:void) must be between 0.01 and 50.0',
			},
		},
		achMonthlyFee: {
			type: String,
			use: { range: range(0, 100.0) },
			message: {
				range: '[**ACH Monthly Fee**](javascript:void) must be between 0 and 100.0',
			},
		},
		sicCode: {
			type: String,
			required: true,
			use: { digitLength: digitLength(4) },
			message: {
				required: `[**SIC Code**](javascript:void) is required`,
				digitLength: '[**SIC Code**](javascript:void) must be a four digit number',
			},
		},
	},
};
