import React from "react";
import PropTypes from "prop-types";

import TooltipFormatterComponent from "../../MerchantGrid/formatters/TooltipFormatter";

class TicketGridActionsComponent extends TooltipFormatterComponent {
    constructor(props) {
        super(props, TicketGridActionsComponent.instances);
    }

    static instances = [];

    tooltipClass = "datatooltip--fake";

    get tooltip() {
        return "New Ticket";
    }

    closePopup = (e) => {
        this.props.dependentValues.onInfoHover({}, null);
    };

    render() {
        const { row: { openNewTicketPopup } } = this.props;

        return (
            <button
                ref={this.elementRef}
                onMouseEnter={this.displayPopup}
                onMouseLeave={this.closePopupLater}
                className="btn btn--anchor btn--sml spc--left--sml align--v--middle"
                onClick={openNewTicketPopup}
            >
                <i className="icon icon--xxsml icon--addnote" />
            </button>
        );
    }
}

TicketGridActionsComponent.propTypes = {
    dependentValues: PropTypes.object,
};

export default TicketGridActionsComponent;
