import React from "react";
import { get } from 'lodash';
import PropTypes from "prop-types";

const expandedClassName = (isDetails, isExpandable) => {
    return `${
        isExpandable ? "primary" : "grey"
    } ${isDetails ? "is-expanded" : ""}`;
};

const ExpandableComponent = ({ value, row: { isExpandable, isDetails }, dependentValues }) => {
    const className = get(dependentValues, 'className', '');

    return (
        <div className={className}>
            <i className={expandedClassName(isDetails, isExpandable)}></i>{" "}
            {value}
        </div>
    );
};

ExpandableComponent.propTypes = {
    value: PropTypes.string,
    row: PropTypes.object,
    dependentValues: PropTypes.object,
};

export default ExpandableComponent;
