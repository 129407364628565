import React from 'react';
import { Switch } from 'react-router-dom';
import { find } from 'lodash';
import Route from './Route';

const NoMatch = ({ location }) => (
  <div className="membership__section">
    <h3>Not found <code>{location.pathname}</code></h3>
    <div><a href="/">&laquo; Back to homepage</a></div>
  </div>
)

const findDefault = (routes) => {
  const defaultRoute = find(routes, route => route.isDefault);
  if (defaultRoute) {
    return defaultRoute.path;
  }
  return null;
};

const renderRoutes = (routes) => {
  if (!routes) {
    return null;
  }
  const defaultRoute = findDefault(routes);
  return (
    <Switch>
          {routes.map((routeData, i) => (
        <Route key={i} {...routeData} defaultRoute={defaultRoute} />
      ))}
      <Route component={NoMatch} defaultRoute={defaultRoute} />
    </Switch>
  );
};

export default renderRoutes;
