import React from "react";
import PropTypes from "prop-types";

class IdentificationTypeDropdownComponent extends React.Component {
  handleChange = (e) => {
    const { onChange } = this.props;
    onChange(e);
  };

  render() {
    const { id, className, value } = this.props;
    return (
      <select id={id} name={id} className={className} onChange={this.handleChange} value={value}>
        <option value=''>Please select</option>
        <option value='HealthInsuranceCard'>Health Insurance Card</option>
        <option value='DriverLicense'>Driver License</option>
        <option value='NexusCard'>Nexus Card</option>
        <option value='Passport'>Passport</option>
      </select>
    );
  }
}

IdentificationTypeDropdownComponent.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  value: PropTypes.string
};

export default IdentificationTypeDropdownComponent;