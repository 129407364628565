import React from 'react';
import { toLower, find, get, map } from 'lodash';

export const renderEquipmentOption = (accessoryName, equipmentOptions, stateAccessory, handleChange, renderEmptyOption = false, selectId = null) => {
    let loweredAccessoryName = toLower(accessoryName);
    const isQuantity = loweredAccessoryName.indexOf('quantity') > -1;
    if (isQuantity) {
        const relevantQuantityOption = find(equipmentOptions, e => toLower(e.friendlyName) === loweredAccessoryName && e.dependentOnValue === get(stateAccessory, 'equipmentOptions.CaseOrRoll', loweredAccessoryName === 'quantity' && get(equipmentOptions, 'CaseOrRoll.defaultValue', { name: 'Case Quantity' })));
        if (!relevantQuantityOption) {
            return;
        }
        loweredAccessoryName = toLower(relevantQuantityOption.name)
    }
    const option = find(equipmentOptions, e => toLower(e.name) === loweredAccessoryName);
    const value = get(stateAccessory, isQuantity ? 'quantity' : `equipmentOptions.${accessoryName}`, '');

    if (!option) {
        return;
    }

    const { name, options } = option;
    const id = selectId || `${isQuantity ? '' : 'equipmentOptions_'}${isQuantity ? 'quantity' : name}`;

    return (
        <select
            id={id}
            name={id}
            className="input input--med input--select"
            onChange={handleChange}
            value={value || ''}
        >
            {renderEmptyOption && <option value="">Please Select...</option>}
            {map(options, o => <option key={o.value} value={o.value}>
                {o.friendlyName || o.value}
            </option>)}
        </select>
    );
};