import gatewaysWithFreeTransactions from '../.././../common/constants/gatewaysWithFreeTransactions';
import { cloneDeep, compact, isNaN, some, find, toLower, each, get, first, keys, last, map, has, filter, noop, set, mapKeys } from 'lodash';


export class EquipmentEditMethods{
    constructor(onChange, getEquipmentClone, getEquipmentDefaultClone){
        this.onChange = (equipment) =>{
            equipment.isSelected = true;
            onChange(equipment);
        } 
        this.getEquipmentClone = getEquipmentClone;
        this.getEquipmentDefaultClone = getEquipmentDefaultClone;
    }

    get equipmentClone(){
        return this.getEquipmentClone()
    }
    get equipmentDefaultClone(){
        return this.getEquipmentDefaultClone()
    }

    setOneTimeFeeMerchantPriceToAgentCost = (equipment) => {
        let oneTimeFee = find(equipment.fees, fee  => toLower(fee.feeType) === "onetimefee");
        if(oneTimeFee){
            oneTimeFee.merchantPrice = oneTimeFee.agentCost;
        }
    }

    handleChange = (event, eqp = null) => {
        let equipment = eqp || this.equipmentClone
        let equipmentDefault = this.equipmentDefaultClone
        let itemToSet, itemKey;
        let strName = event.target.name;
        if (strName.startsWith(equipmentDefault.name + "_"))
            strName = strName.substr(equipmentDefault.name.length + 1);

        if (strName.indexOf('_') > 0) {
            let keyList = compact(strName.split('_'));
            itemToSet = keyList.reduce((prev, curItem, idx) => {
                if (idx < keyList.length - 1) {
                    return prev[curItem];
                }
                return prev;
            }, equipment);
            itemKey = keyList[keyList.length - 1];
        }
        else {
            itemToSet = equipment;
            itemKey = strName;
        }
        let newVal = event.target.value;
        if (event.target.type && event.target.type === 'checkbox')
            newVal = event.target.checked;

        if(toLower(strName) === "paymentschedule"){
            if(toLower(newVal) === "billagent"){
                if(toLower(this.equipmentDefaultClone.category) === 'gateway'){
                    this.setOneTimeFeeMerchantPriceToAgentCost(equipment);
                }
                each(equipment.subequipment, sub => {
                    this.setOneTimeFeeMerchantPriceToAgentCost(sub);
                });
            }
        }
        
        if(toLower(strName) === "shippingoption"){
            if(toLower(newVal) === "pickup"){
                equipment.saturdayDelivery = false;
                equipment.shippingSpeed = '';
            }
        }

        itemToSet[itemKey] = newVal;

        this.onChange(equipment);
    }

    subOptionSelect = (equipmentId) => (e) => {
        const equipment = this.equipmentClone;
        let eqp = equipment.subequipment.find(s => s.equipmentId == equipmentId);
        if (!eqp)
            return;
    
        if (!eqp.equipmentOptions) {
            eqp.equipmentOptions = {};
        }
    
        if (e.target.checked) {
            eqp.equipmentOptions[e.target.value] = '1';
        } else if (Object.keys(eqp.equipmentOptions).includes(e.target.value)) {
            delete eqp.equipmentOptions[e.target.value];
        }
    
        this.onChange(equipment);
    }
    subOptionChange = (equipmentId) => (e) => {
        const equipment = this.equipmentClone;
        let eqp = equipment.subequipment.find(s => s.equipmentId == equipmentId);
        if (!eqp)
            return;
        if (!eqp.equipmentOptions) {
            eqp.equipmentOptions = {};
        }
        let inputName = e.target.name;
        let optionName = inputName.substr(inputName.indexOf('_equipmentOptions__') + '_equipmentOptions__'.length);
        eqp.equipmentOptions[optionName] = e.target.value; 
        
        this.onChange(equipment);
    }
    
    optionSelect = (event) => {
        const equipment = this.equipmentClone;
        if (!equipment.equipmentOptions) {
            equipment.equipmentOptions = {};
        }
        if (event.target.checked) {
            equipment.equipmentOptions[event.target.value] = '1';
        } else if (Object.keys(equipment.equipmentOptions).includes(event.target.value)) {
            delete equipment.equipmentOptions[event.target.value];
        }    
        this.onChange(equipment);
    }
    
    optionSetMoreInfo = (optKey, optValue) => (event) => {
        const equipment = this.equipmentClone;
        equipment.equipmentOptions[optKey] = `${optValue}|${event.target.value}`;
        this.onChange(equipment);
    }
    
    suboptionSetMoreInfo = (equipmentId, optKey, optValue) => (event) => {
        const equipment = this.equipmentClone;
        let eqp = equipment.subequipment.find(s => s.equipmentId == equipmentId);
        if (!eqp)
            return;
    
        eqp.equipmentOptions[optKey] = `${optValue}|${event.target.value}`;
        this.onChange(equipment);
    }
    
    selectPlan = (event) => {
        const equipment = this.equipmentClone;
        const equipmentDefaultClone = this.equipmentDefaultClone;
        const purchasePlan = find(equipmentDefaultClone.purchasePlans,
          (p, i) => p.planId == event.target.value
        );
        if (event.target.checked) {
          equipment.purchasePlanId = event.target.value;
          if (purchasePlan?.defaultFreeTransactions && some(gatewaysWithFreeTransactions, plan => plan === toLower(equipment.name))) {
            equipment.numberOfFreeTransactions = purchasePlan.defaultFreeTransactions;
          } else {
            equipment.numberOfFreeTransactions = null;
          }
        }
        equipment.subequipment = []
        this.copyFees(purchasePlan, equipment, equipment.purchaseType);;
        this.onChange(equipment);
      };
    
    selectPurchaseType = (event) => {
        const equipmentDefault = this.equipmentDefaultClone;
        const equipment = this.equipmentClone;
        
        equipment.purchaseType = event.target.value;
        
        // add fees
        this.copyFees(equipmentDefault.purchasePlans.find((p, i) => p.planId == equipment.purchasePlanId), equipment, equipment.purchaseType);
        this.onChange(equipment);
    }
    
    copyFees(sourceObj, destObj, purchaseType) {
        if (!sourceObj || !destObj) {
            return;
        }    
        destObj.fees = cloneDeep(sourceObj.fees.filter((f)=>f.purchaseTypes.includes(purchaseType)));
        destObj.additionalFees = cloneDeep(sourceObj.additionalFees.filter((f) =>f.purchaseTypes.includes(purchaseType)));
        [...destObj.fees, ...destObj.additionalFees].forEach(fee => {
            fee.merchantPrice = fee.retailPrice;
        });
        if(toLower(get(destObj, "paymentSchedule", '')) === 'billagent'){
            this.setOneTimeFeeMerchantPriceToAgentCost(destObj)
        }
    }
    
    setIncludedTransactions = event => {
        const equipment = this.equipmentClone;
        equipment.numberOfFreeTransactions = event.target.checked ? parseInt(event.target.value, 10) : 0;
        this.onChange(equipment);
    }
    
    handleSubNote = (subequipmentId) => (note) => {
        const equipment = this.equipmentClone;
        let eqp = equipment.subequipment.find((e, i) => e.equipmentId == subequipmentId);
        if (!eqp) return;
    
        eqp.notes = note;
        this.onChange(equipment);
    }
    
    handleSubFeeChange = (subequipmentId, feeId, updatedValue) => {
        if (!feeId) return; // happens when unselecting a subequipment
        const equipment = this.equipmentClone;
        let eqp = equipment.subequipment.find((e, i) => e.equipmentId == subequipmentId);
        if (!eqp) return;
        let fee = eqp.fees.find((fee, i) => fee.feeId == feeId);
        if (!fee) return;
        const isValidValue = /^0*\.0*$/.test(updatedValue.value)
        if(isValidValue || (updatedValue.floatValue === undefined ||isNaN(updatedValue.floatValue))){ 
            fee.merchantPrice = null; 
        }
        else {
            fee.merchantPrice = updatedValue.floatValue;
        }
        this.onChange(equipment);
    }

    setAdditionalFees = (additionalFees) => {
        const equipment = this.equipmentClone;
        equipment.additionalFees = additionalFees;
        this.onChange(equipment);
    }
    
    handleFeeChange = (planId, feeId, updatedValue) => {
        const equipment = this.equipmentClone;
        let fee = equipment.fees.find((fee, i) => fee.feeId == feeId); 
    
        // can be just switching plans, so another plan's fees are triggering a value change, but don't need to update state in this case
        if (!fee) return;
    
        const isValidValue = /^0*\.0*$/.test(updatedValue.value)
        if(isValidValue || (updatedValue.floatValue === undefined ||isNaN(updatedValue.floatValue)))
        {
            fee.merchantPrice = null;
        }
        else
        {
            fee.merchantPrice = updatedValue.floatValue;
        }
        this.onChange(equipment);
    }
    
    handleTimeChange = (time) => {
        const equipment = this.equipmentClone;
        equipment.batchoutTime = time;
        this.onChange(equipment);
    }

    adjustQuantity = (event, fromInput = false) => {
        const equipment = this.equipmentClone;
        if(fromInput) {
            const {value} = event.target;
            if(value === "") {
                equipment.quantity = 1;
            } else if(value > 0 && value <= 20) {
                equipment.quantity = parseInt(event.target.value);
            }
        } else if (event.target.name === "increase") {
            if (equipment.quantity < 20) {
                equipment.quantity += 1;
            }
        } else if (event.target.name === "decrease") {
            if (equipment.quantity > 1) {
                equipment.quantity -= 1;
            }
        }

        this.onChange(equipment);
    };
    
    updateAccessories = (accessories) => {
        const equipment = this.equipmentClone;
        equipment.accessories = accessories;
        this.onChange(equipment);
    }

    createEquipment = (eqp) => {
        let purchaseType = eqp.purchaseTypes && keys(eqp.purchaseTypes)[0];
        const planGetter = eqp.name === 'Cardknox' ? last : first;
        let purchasePlan = planGetter(eqp.purchasePlans, plan => plan.purchaseTypes.includes(purchaseType));
        const platforms = eqp.frontendProcessors ? keys(eqp.frontendProcessors) : [];
        const equipment = {
            equipmentId: eqp.equipmentId,
            frontendProcessors: first(platforms),
            quantity: 1,
            country: eqp.country,
            category: eqp.category,
            name: eqp.name,
            isSelected: false,
            isExpanded: false,
            purchaseType,
            purchasePlanId: get(purchasePlan, 'planId'),
            paymentSchedule: "",
            fees: cloneDeep(get(purchasePlan, 'fees', []).filter((f) => f.purchaseTypes.includes(purchaseType))),
            additionalFees: cloneDeep(get(purchasePlan, 'additionalFees', []).filter((f) => f.purchaseTypes.includes(purchaseType))),
            equipmentOptions: {},
            shippingAddress: {},
            subequipment: [],
        };
        if (purchasePlan?.defaultFreeTransactions) {
            equipment.numberOfFreeTransactions = toLower(eqp.category) !== "gateway" || some(gatewaysWithFreeTransactions, name => name === toLower(eqp.name)) ? purchasePlan.defaultFreeTransactions : "0";
        }
        each([...equipment.fees, ...equipment.additionalFees], (fee) => {
            fee.merchantPrice = fee.retailPrice;
        });
        each(eqp.equipmentOptions, (option) => {
            if (!!option.defaultValue && !option.dependentOnName) {
                equipment.equipmentOptions[option.name] = option.defaultValue;
            }
        });
        return equipment;
    }

    setGatewaySubequipment(equipmentId, selected) {
        const equipment = this.equipmentClone;
        const equipmentDefault = this.equipmentDefaultClone;
        let subequip = find(equipmentDefault.subequipment, ((e, i) => e.equipmentId == equipmentId));
        equipment.subequipment = equipment.subequipment || [];

        if (!subequip) return;
        if (selected) {
            let subEqpToAdd = this.createEquipment(subequip);
            if(toLower(equipment.paymentSchedule) === 'billagent'){
                this.setOneTimeFeeMerchantPriceToAgentCost(subEqpToAdd);
            }
            equipment.subequipment.push(subEqpToAdd);
        }
        else {
            equipment.subequipment = equipment.subequipment.filter((e, i) => e.equipmentId != equipmentId);
        }
        this.onChange(equipment);
    }
}

export class ApplyValuesFromTemplateMethods{
    static updateFees = (copyFrom, copyTo) => {
        map(copyFrom.fees, (fee) => {
            let feeToUpdate = find(copyTo.fees, f => f.feeId == fee.feeId);
            if(feeToUpdate)
                feeToUpdate.merchantPrice = fee.merchantPrice;
        });
    }
    static updateAdditionalFees = (copyFrom, copyTo) => {
        map(copyFrom.fees, (fee) => {
            let feeToUpdate = find(copyTo.additionalFees, f => f.feeId == fee.feeId);
            if(feeToUpdate){
                feeToUpdate.merchantPrice = fee.merchantPrice;
                feeToUpdate.isSelected = true;
            }
        });
    }
    static updateSubequipment = (copyFrom, copyTo, defaultEquipment, createEquipment) => {
        if(copyFrom.subequipment && copyFrom.subequipment.length > 0){
            copyTo.subequipment = [];
            each(copyFrom.subequipment, (sub) => {
                if(some(defaultEquipment.subequipment, subEqp => subEqp.equipmentId == sub.equipmentId)){
                    if(createEquipment.length === 1){
                        const defaultSubEqp = find(defaultEquipment.subequipment, subEqp => subEqp.equipmentId == sub.equipmentId)
                        copyTo.subequipment.push(createEquipment(defaultSubEqp));
                    }else{
                        copyTo.subequipment.push(createEquipment(sub.equipmentId, true, true, false, copyFrom.equipmentId, copyFrom.purchasePlanId));
                    }
                }
            });
        }
        map(copyFrom.subequipment, (se) => {
            let subequipmentToUpdate = find(copyTo.subequipment, s => se.equipmentId == s.equipmentId);
            if(!subequipmentToUpdate) return;
            map(se.fees, (fee) => {
                let feeToUpdate = find(subequipmentToUpdate.fees, f => f.feeId == fee.feeId);
                if(!feeToUpdate) return;
                if(feeToUpdate){
                    feeToUpdate.merchantPrice = fee.merchantPrice;
                }
            });
            subequipmentToUpdate.isSelected = true;
            subequipmentToUpdate.notes = se.notes;
            subequipmentToUpdate.equipmentOptions = se.equipmentOptions;
            subequipmentToUpdate.isEquipmentAddedFromAgentTemplate = true;
        });
    }

    static copyPropertiesValues = (copyFrom, copyTo, propertiesList) => {
     each(propertiesList, (property) => {
        if(has(copyFrom, property)){
            copyTo[property] = copyFrom[property]
        }
     })
    }

    static setupPurchasePlanAndFees = (copyFrom, copyTo, defaultEquipment) => {
        if(copyFrom.purchasePlanId !== copyTo.purchasePlanId){
            let purchasePlan = find(defaultEquipment.purchasePlans, pp => pp.planId == copyFrom.purchasePlanId);
            if(purchasePlan){
                copyTo.fees = cloneDeep(purchasePlan.fees);
                this.copyPropertiesValues(copyFrom, copyTo, ['numberOfFreeTransactions', 'purchasePlanId'])
            }
        }
        copyTo.fees = filter(copyTo.fees, f => f.purchaseTypes.includes(copyTo.purchaseType));
    }

    static updateAccessories = (copyFrom, copyTo, defaultEquipment, createNewEquipment = noop) => {
        const accessoriesFromTemplate = []
        map(copyFrom.accessories, (accessory) => {
            let defaultEquipmentAccessory = find(defaultEquipment.accessories, acc => acc.equipmentId == accessory.equipmentId);
            if(!defaultEquipmentAccessory) return;

            let accessoryToUpdate = null; 
            if(createNewEquipment.length > 1){
                accessoryToUpdate = createNewEquipment(accessory.equipmentId, true, false, true)
            }else{
                accessoryToUpdate = cloneDeep(find(defaultEquipment.accessories, acc => acc.equipmentId == accessory.equipmentId));
                accessoryToUpdate.equipmentOptions = {};
                if(!accessoryToUpdate.fees){
                    let fees = filter(get(accessoryToUpdate.purchasePlans[0], 'fees', []), { feeType: 'OneTimeFee' })
                    accessoryToUpdate.fees = fees;
                }
            }
            accessoryToUpdate.isSelected = true;
            this.copyPropertiesValues(accessory, accessoryToUpdate, ['quantity'])
            this.updateEquipmentOptions(accessory, accessoryToUpdate, defaultEquipmentAccessory.equipmentOptions)
            this.updateFees(accessory, accessoryToUpdate)
            accessoriesFromTemplate.push(accessoryToUpdate)
        })
        if(accessoriesFromTemplate.length > 0) copyTo.accessories = accessoriesFromTemplate;
    }
    static updateHardwareProperties = (defaultEquipment, equipmentToUpdate, templateEquipment, createNewEquipment) => {
        if(has(defaultEquipment.frontendProcessors, templateEquipment.platform)){
            equipmentToUpdate.platform = templateEquipment.platform;
            equipmentToUpdate.batchoutTime = templateEquipment.batchOutTime;
        }
        equipmentToUpdate.isSelected = true;
        this.copyPropertiesValues(templateEquipment, equipmentToUpdate, ['paymentSchedule', 'quantity', 'notes', 'shippingOption', 'shippingSpeed', 'saturdayDelivery'])
        this.updateEquipmentOptions(templateEquipment, equipmentToUpdate, defaultEquipment.equipmentOptions)
        this.updateAccessories(templateEquipment, equipmentToUpdate, defaultEquipment, createNewEquipment)
        this.updateFees(templateEquipment, equipmentToUpdate)
    }
    static updateAccessoryProperties = (defaultEquipment, equipmentToUpdate, templateEquipment) => {
        this.updateEquipmentOptions(templateEquipment, equipmentToUpdate, defaultEquipment.equipmentOptions)
        this.copyPropertiesValues(templateEquipment, equipmentToUpdate, ['paymentSchedule', 'quantity', 'notes', 'shippingOption', 'shippingSpeed', 'saturdayDelivery'])
        equipmentToUpdate.isSelected = true;
        this.updateFees(templateEquipment, equipmentToUpdate)
    }
    static updateGatewayProperties = (defaultEquipment, equipmentToUpdate, templateEquipment, createNewEquipment) => {
        if(has(defaultEquipment.frontendProcessors, templateEquipment.platform)){
            equipmentToUpdate.platform = templateEquipment.platform;
            equipmentToUpdate.batchoutTime = templateEquipment.batchOutTime;
        }
        equipmentToUpdate.isSelected = true;
        this.copyPropertiesValues(templateEquipment, equipmentToUpdate, ['paymentSchedule', 'quantity', 'notes'])
        this.updateEquipmentOptions(templateEquipment, equipmentToUpdate, defaultEquipment.equipmentOptions);
        this.setupPurchasePlanAndFees(templateEquipment, equipmentToUpdate, defaultEquipment)
        this.updateFees(templateEquipment, equipmentToUpdate)
        this.updateAdditionalFees(templateEquipment, equipmentToUpdate)
        this.updateSubequipment(templateEquipment, equipmentToUpdate, defaultEquipment, createNewEquipment)
    }

    static updateEquipmentOptions = (copyFrom, copyTo, availableOptions) => {
        mapKeys(availableOptions, (option) => {
            if(has(copyFrom.equipmentOptions, option.name)){
                set(copyTo.equipmentOptions, option.name, get(copyFrom.equipmentOptions, option.name))
            }
        });
    }
}