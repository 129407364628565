import React, {Component} from 'react';

class TicketStatusSelectionComponent extends Component {

    
    filterText = () => {
        const values = this.props.filter.values;
        let valuesArr = Object.entries(values);

        let stringDisplay = valuesArr.reduce((txt, val, i) => {
            // each val is an array with [0] = key and [1] = bool val
            if (!val[1]) return txt;

            let newVal = txt ? txt + ', ' + val[0] : val[0];
            return newVal;
        }, '');
        return stringDisplay;
    };

    filterRemoved = () => {
        this.props.onFilterRemoved({
            id: 'status',
            emptyValue: false
        })
    }

    render() {
        return (
            <div className="filter__tag">
                {this.filterText()}
                <button onClick={this.filterRemoved} className="btn btn--reset filter__tag__clear"></button>
            </div>
        );
    }
}

export default TicketStatusSelectionComponent;