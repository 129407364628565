import React, { Component } from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { appService } from '../../services/appService';

const steps = {
	mpa: '/eapp/mpa',
	setup: '/eapp/setup',
	equipment: '/eapp/equipment',
	confirm: '/eapp/confirm',
};

export class NavbarComponent extends Component {
	constructor(props) {
		super(props);
		this.state = { appStatus: null, loading: false, setup: null };
	}

	componentDidMount() {
		if (this.props.loadOnMount) {
			this.loadAppStatus();
		}
	}

	loadAppStatus = (resolve = noop) => {
		this.setState({ loading: true });
		return Promise.all([appService.getEappStatus(this.props.appId)])
			.then(([appStatus, setup]) => {
				this.setState({ appStatus, setup, loading: false }, resolve);
				if (this.props.setIsReadyToSubmit !== undefined) {
					this.props.setIsReadyToSubmit(appStatus);
				}
			})
			.catch(err => {
				console.log('getEappStatus error', err);
				this.setState({ loading: false });
			});
	};

	isComplete(navPath) {
		const { loading, appStatus } = this.state;

		if (!loading && !!appStatus) {
			if (navPath.indexOf(steps.mpa) > -1) {
				return appStatus.isMpaComplete ? appStatus.isMpaComplete : appStatus.isDocumentationComplete; //Once the API is published we can remove this check and just return isMpaComplete --SW 1/31
			} else if (navPath.indexOf(steps.setup) > -1) {
				return appStatus.isSetupFormComplete;
			} else if (navPath.indexOf(steps.equipment) > -1) {
				return appStatus.isEquipmentComplete;
			}
		}
		return false;
	}

	render = () => {
		const appid = this.props.appId;
		const { loading, appStatus } = this.state;
		let readyToSubmit = !loading && appStatus && appStatus.isReadyToSubmit;

		return (
			<React.Fragment>
				<ul className="wizard--primary wizard--primary--leads">
					<React.Fragment>
						<li className="wizard--primary__item">
							<NavLink className="wizard--primary__card" activeClassName="is-active" to={steps.mpa + '/' + appid} exact>
								{this.isComplete(steps.mpa) ? (
									<i className="icon icon--lrg icon--regular--check--primary" />
								) : (
									<i className="icon icon--lrg icon--reports" />
								)}
							</NavLink>
							<p className="wizard--primary__title">MPA</p>
						</li>
						<li className="wizard--primary__separator"></li>
						<li className="wizard--primary__item">
							<NavLink
								className="wizard--primary__card"
								activeClassName="is-active"
								to={steps.setup + '/' + appid}
								exact
							>
								{this.isComplete(steps.setup) ? (
									<i className="icon icon--lrg icon--regular--check--primary" />
								) : (
									<i className="icon icon--lrg icon--setup" />
								)}
							</NavLink>
							<p className="wizard--primary__title">Setup Form</p>
						</li>
						<li className="wizard--primary__separator"></li>
						<li className="wizard--primary__item">
							<NavLink
								className="wizard--primary__card"
								activeClassName="is-active"
								to={steps.equipment + '/' + appid}
								exact
							>
								{this.isComplete(steps.equipment) ? (
									<i className="icon icon--lrg icon--regular--check--primary" />
								) : (
									<i className="icon icon--lrg icon--management" />
								)}
							</NavLink>
							<p className="wizard--primary__title">Equipment</p>
						</li>
						<li className="wizard--primary__separator"></li>
						<li className="wizard--primary__item">
							{readyToSubmit ? (
								<React.Fragment>
									<NavLink
										className="wizard--primary__card"
										activeClassName="is-active"
										to={steps.confirm + '/' + appid}
										exact
									>
										<i className="icon icon--lrg icon--dashboard" />
									</NavLink>
									<p className="wizard--primary__title">Form Confirmation</p>
								</React.Fragment>
							) : (
								<React.Fragment>
									<div className="wizard--primary__card">
										<i className="icon icon--lrg icon--dashboard" />
									</div>
									<p className="wizard--primary__title">Form Confirmation</p>
								</React.Fragment>
							)}
						</li>
					</React.Fragment>
				</ul>
			</React.Fragment>
		);
	};
}

NavbarComponent.defaultProps = {
	loadOnMount: true,
};

NavbarComponent.propTypes = {
	location: PropTypes.shape({ pathname: PropTypes.string }).isRequired,
	setIsReadyToSubmit: PropTypes.func,
	setup: PropTypes.object,
	loadOnMount: PropTypes.bool,
};
