import React from 'react';
import { string, bool } from 'prop-types';

import { renderIf } from "./../../common/utilities";
import {
    DisplayBooleanComponent
} from "../../common/formatters";

class AccountRecap extends React.Component {
    render() {
        const { accountInfo, wrapperClass, hideTitle } = this.props;

        return (

            <div className={wrapperClass}>
                <div className="form--popup">   
                    {!hideTitle &&<h6 className="spc--bottom--med">Account Recap </h6>}
                    <div className="details__content">
                        <div className="row grid-align-middle">
                            <div className="col col-sml-12 col-med-6">
                                <div className="spc--bottom--med form__field">
                                    <label className="label" htmlFor="lblGoodsOrServicesSold">Goods or Services Sold </label>
                                    <label className="input input--med input--fake" name="lblGoodsOrServicesSold" id="lblGoodsOrServicesSold" >{accountInfo.goodsOrServicesSold}</label>
                                </div>
                            </div>
                            <div className="col col-sml-12 col-med-6">
                                <div className="spc--bottom--med form__field">
                                    <label className="label" htmlFor="lblPercentKeyed">Percent Keyed</label>
                                    <label className="input input--med input--fake" name="lblPercentKeyed" id="lblPercentKeyed" >{accountInfo.percentKeyed}</label>
                                </div>
                            </div>
                            <div className="col col-sml-12 col-med-6">
                                <div className="spc--bottom--med form__field">
                                    <label className="label" htmlFor="lblPercentSwiped">Percent Swiped</label>
                                    <label className="input input--med input--fake" name="lblPercentSwiped" id="lblPercentSwiped" >{accountInfo.percentSwiped}</label>
                                </div>
                            </div>
                            <div className="col col-sml-12 col-med-6">
                                <div className="spc--bottom--med form__field">
                                    <label className="label" htmlFor="lblAverageTicketCombined">Average Ticket</label>
                                    <label className="input input--med input--fake" name="lblAverageTicketCombined" id="lblAverageTicketCombined" >{accountInfo.averageTicketCombined}</label>
                                </div>
                            </div>
                        </div>
                        <div className="row grid-align-middle">
                            <div className="col col-sml-12 col-med-6">
                                <div className="card--primary__subtitle">Average Monthly Volume</div>
                                <div className="row grid-align-middle">
                                    <div className="col col-sml-12 col-med-6">
                                        <div className="spc--bottom--med form__field">
                                            <label className="label" htmlFor="lblAverageMonthlyBankcardVolume">V/MC</label>
                                            <label className="input input--med input--fake" name="lblAverageMonthlyBankcardVolume" id="lblAverageMonthlyBankcardVolume" >{accountInfo.averageMonthlyBankcardVolume}</label>
                                        </div>
                                    </div>
                                    <div className="col col-sml-12 col-med-6">
                                        <div className="spc--bottom--med form__field">
                                            <label className="label" htmlFor="lblAverageMonthlyAmexVolume">AMEX</label>
                                            <label className="input input--med input--fake" name="lblAverageMonthlyAmexVolume" id="lblAverageMonthlyAmexVolume" >{accountInfo.averageMonthlyAmexVolume}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-sml-12 col-med-6">
                                <div className="card--primary__subtitle">Average Ticket</div>
                                <div className="row grid-align-middle">
                                    <div className="col col-sml-12 col-med-6">
                                        <div className="spc--bottom--med form__field">
                                            <label className="label" htmlFor="lblAverageBankcardTicket">V/MC</label>
                                            <label className="input input--med input--fake" name="lblAverageBankcardTicket" id="lblAverageBankcardTicket" >{accountInfo.averageBankcardTicket}</label>
                                        </div>
                                    </div>
                                    <div className="col col-sml-12 col-med-6">
                                        <div className="spc--bottom--med form__field">
                                            <label className="label" htmlFor="lblAverageAmexTicketAmex">AMEX</label>
                                            <label className="input input--med input--fake" name="lblAverageAmexTicketAmex" id="lblAverageAmexTicketAmex" >{accountInfo.averageAmexTicket}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row grid-align-middle">
                            <div className="col col-sml-12 col-med-6">
                                <div className="form__field">
                                    <label className="label" htmlFor="lblPinDebitMonthlyVolume">PIN Debit Monthly Volume </label>
                                    <label className="input input--med input--fake" name="lblPinDebitMonthlyVolume" id="lblPinDebitMonthlyVolume" >{accountInfo.pinDebitMonthlyVolume}</label>
                                </div>
                            </div>
                            <div className="col col-sml-12 col-med-6">
                                <div className="form__field">
                                    <label className="label" htmlFor="lblTotalMonthlyVolume">Total Monthly Volume </label>
                                    <label className="input input--med input--fake" name="lblTotalMonthlyVolume" id="lblTotalMonthlyVolume" >{accountInfo.totalMonthlyVolume}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

AccountRecap.defaultProps = {
    wrapperClass: 'card--primary card--sml card--primary--popup',
    hideTitle: false,
};

AccountRecap.propTypes = {
    wrapperClass: string,
    hideTitle: bool,
};

export default AccountRecap;