import React, { Component, Fragment, createRef } from "react";
import { map, get, find, filter, clone, isEmpty, toLower, some, includes } from "lodash";
import { object, func, bool, string } from 'prop-types';
import moment from "moment";
import { withRouter } from "react-router-dom";

import { appService, principalService } from "../../services";
import { NavbarComponent } from "./nav-bar";
import { Toggle, ToggleContainer } from './../../common/components/toggle';
import { withContext, withLoader } from "../../common/components";
import { Modal } from "../../common/components/modal";
import { Notification } from "../../common/components/notifications";
import { getFilesForTag } from "../../common/utilities/commonFileHandlingMethods";
import PaymentPointsContext from '../../common/components/user-account-panel/PaymentPointsContext';
import { DisplayBooleanComponent } from "../../common/formatters";
import { MerchantContext } from "../MerchantDetails";
import USMpaConfirm from "./USMpaConfirm";
import CanadaMpaConfirm from "./CanadaMpaConfirm";
import CanadaSetupConfirm from "./CanadaSetupConfirm";
import USSetupConfirm from "./USSetupConfirm";
import { isFd } from "../../common/utilities";
import { getProcessorDisplayName } from "../../common/utilities/processorDisplayName";

const isExpand = true;

const dateFormat = process.env.REACT_APP_DISPLAY_DATE_FORMAT;

class EAppConfirmComponent extends Component {
    constructor(props) {
        super(props);
        this.state = clone(this.initialState);

        this.errorRef = createRef();
        this.notificationRef = createRef();

        this.getFilesForTag = getFilesForTag.bind(this);
    }

    get initialState() {
        const principal = principalService.get();
        return {
            errorMessage: null,
            appId: this.props.match.params.appid,
            isOpenSubmitApp: false,
            isRequestingRush: false,
            rushReason: '',
            loggedInAgentId: principal.agentId,
            loggedInEmail: principal.email,
            mpa: {
                refNum: "",
                agentName: "",
                signerInformation: {
                    socialSecurityNumber: "",
                    dateOfBirth: "",
                    firstName: "",
                    lastName: "",
                    email: "",
                    title: "",
                    phoneNumber: "",
                    cellPhoneNumber: "",
                    address: {
                        streetAddress: "",
                        city: "",
                        state: "",
                        zip: "",
                        country: "",
                    },
                },
                corporateAddress: {
                        streetAddress: "",
                        city: "",
                        state: "",
                        zip: "",
                        country: "",
                },
                corporateName: "",
                businessInformation: {                    
                    businessAddress: {
                        streetAddress: "",
                        city: "",
                        state: "",
                        zip: "",
                        country: "",
                    },
                    businessPhone: "",
                    businessFax: "",
                    businessEmail: "",
                    website: "",
                    ownershipType: "",
                },
                bankAccountInformation: {
                    accountNumber: "",
                    routingNumber: "",
                    bankName: "",
                    secondaryAccountNumber: "",
                    secondaryRoutingNumber: "",
                    secondaryBankName: "",
                },
                businessStartDate: "",
                goodsOrServicesDescription: "",
                taxID: "",
                shouldShowTaxInfo: "",
                doesAcceptEbt: false,
                ebtNumber: "",
                isNewAccount: false,
                amexDetails:{
                    status: "",
                    esaNumber: "",
                },
                accountNotes: "",
                files: [],
                isTaxable: false,
                appId: "",
                leadId: "",
                agentId: "",
                businessContactName: "",
                ownerName: "",
                mid: "",
                dba: "",
                processorName: "",
                processorId: "",
                isPciCompliant: "",
                statusTitle: "",
                statusDisplayName: "",
                dateSigned: "",
                dateApproved: "",
                submittedBy: ""
            },
        };
    }

    componentDidMount() {
        this.loadDataFromService();
    }

    loadDataFromService = () => {
        const { displayEquipmentInfo } = this.props;
        this.props.showLoader(true);
        const response = {};
        const promises = [
            this.getApp(response),
        ];

        if(displayEquipmentInfo){
            promises.push(this.getEquipment(response))
        }

        Promise.all(promises)
            .then(() => {
                this.props.showLoader(false);
                this.mapResponseToState(response);
            })
            .catch(() => {
                this.props.showLoader(false);
            });
    };

    getEquipment(response){
        return( appService
                .getGatewayList(this.state.appId)
                .then((gateways) => {
                    response.gatewayList = gateways.equipmentList;
                })
                .catch((err) => {
                    console.log("getGatewayList error", err);
                    this.setState({
                        errorMessage: "An error occurred: " + err,
                        isNewError: true,
                    });
                    return Promise.reject(err);
                }),
            appService
                .getHardwareList(this.state.appId)
                .then((hardware) => {
                    response.hardwareList = hardware.equipmentList;
                })
                .catch((err) => {
                    console.log("getHardwareList error", err);
                    this.setState({
                        errorMessage: "An error occurred: " + err,
                        isNewError: true,
                    });
                    return Promise.reject(err);
                }),
            appService
                .getMerchantEquipment(this.state.appId)
                .then((eqp) => {
                    response.existingMerchantEquipment = eqp.equipmentList;
                })
                .catch((err) => {
                    console.log("getMerchantEquipment error", err);
                    this.setState({
                        errorMessage: "An error occurred: " + err,
                        isNewError: true,
                    });
                })
        );
    }
    getApp(response) {
        return appService.getEApp(this.state.appId).then((res) => {
            res.signerInformation.ownershipPercentage = res.signerInformation.ownershipPercentage || '';
            response.mpa = res;
            response.setup = res;
        })
    }

    componentDidUpdate() {
        if (this.state.errorMessage && this.state.isNewError) {
            setTimeout(() => {
                if (this.errorRef.current) {
                    this.errorRef.current.scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                    });
                }
            }, 200);
            this.setState({ isNewError: false });
        }
    }

    mapResponseToState = (response) => {
        const newState = { ...response };
        newState.mpa = this.mapResponseToMpa(newState);
        this.setState(newState);
    };

    mapResponseToMpa = ({ mpa }) => {
        return {
            ...mpa,
            businessStartDate: moment(mpa.businessStartDate).format(dateFormat),
            signerInformation: {
                ...mpa.signerInformation,
                dateOfBirth: moment(mpa.signerInformation.dateOfBirth).format(
                    dateFormat
                ),
            },
        };
    };

    redirectToMerchantAccount = () => {
        if (this.state.mpa.submittedBy != this.state.mpa.agentId && this.state.mpa.submittedBy === this.state.loggedInAgentId) {
            this.props.history.push(`/merchants`);
        }
        else {
            this.props.history.push(`/merchants/${this.state.appId}/account`);
        }
    }

    submitApp = () => {
        const { showLoader } = this.props;
        const { appId, isRequestingRush, rushReason } = this.state;
        showLoader(true);
        appService.submitApplication(appId, isRequestingRush, rushReason).then(({ refNum, status }) => 
        {
            showLoader();
            this.closeSubmitApp();
            this.notificationRef.current.addNotification({
                message: 'Application submitted successfully',
                ref: refNum,
                success: toLower(status) === 's',
                onClose: this.redirectToMerchantAccount,
            });
        }).catch(err => {
            showLoader();
            this.closeSubmitApp();
            this.setState({
                errorMessage: "An error occurred: " + err,
                isNewError: true,
            });
        });
    };

    handleChange = ({ target: { name, value, checked, type } }) => this.setState({ [name]: type === 'checkbox' ? checked : value });

    closeSubmitApp = () => this.setState({ isOpenSubmitApp: false });

    openSubmitApp = () => this.setState({ isOpenSubmitApp: true, isRequestingRush: false, rushReason: '' });

    redirect = pathname => this.props.history.push({ pathname });

    redirectToMpa = () => this.redirect(`/eapp/mpa/${this.state.appId}`);

    redirectToSetup = () => this.redirect(`/eapp/setup/${this.state.appId}`);

    redirectToEquipment = () => this.redirect(`/eapp/equipment/${this.state.appId}`);

    hasSufficientPoints = () => this.props.agentPoints.points >= 25;
    
    canSubmitRush = () => this.hasSufficientPoints() || this.isAgentCardknoxRep();

    renderHardwareAndAccessories () {
        const hardware = filter(this.state.existingMerchantEquipment, ({ category }) => toLower(category) !== 'gateway');

        return (
            <Toggle initialToggle={isExpand}>
                {({ isToggled, handleToggle }) => !isEmpty(hardware) && (
                    <div>
                        <div className="card--primary card--sml spc--bottom--med">
                            <div
                                className={`card--primary__header ${isToggled
                                    ? "is-expanded"
                                    : "is-collapsed"
                                    }`}
                            >
                                <h6 className="spc--bottom--sml">Hardware and Accessories</h6>
                                <div className="card--primary__header__expand" onClick={handleToggle}>
                                    <i
                                        className={`icon icon--arrow icon--tiny icon--arrow--down--primary ${isToggled ? "rotate--180" : ""
                                            }`}
                                    ></i>
                                </div>
                            </div>
                            <div
                                className={`details__content ${isToggled ? "" : "display--n"
                                    }`}
                            >
                                <div className="card--primary__subheader">
                                    <strong>Hardware</strong>
                                    {this.renderChangeLink(this.redirectToEquipment)}
                                </div>
                                {map(hardware, ({ name, quantity, oneTimeFeeAmount }) => (
                                    <div key={name} className="row row-align-middle spc--bottom--med">
                                        <div className="col col-sml-12 col-lrg-4 spc--bottom--sml">
                                            <div
                                                className="checkout__img checkout__img__thumb spc--right--med"
                                                style={{
                                                    backgroundImage:
                                                        "url(" +
                                                        process
                                                            .env
                                                            .REACT_APP_CDN_URL +
                                                        toLower(
                                                            name
                                                        )
                                                            .replace(
                                                                " ",
                                                                "_"
                                                            )
                                                            .replace(
                                                                /[^a-z0-9_-]/gi,
                                                                ""
                                                            ) +
                                                        "/thumbnail.png" +
                                                        ")",
                                                }}
                                                alt={
                                                    name
                                                }
                                            ></div>
                                            <label className="type--medium">
                                                {name}
                                            </label>
                                        </div>
                                        <div className="col col-sml-12 col-lrg-3 spc--bottom--sml">
                                            <label className="type--wgt--semibold type--uppercase type--color--grey4">
                                                Quantity
                                            </label>
                                            <div className="input input--med input--fake">
                                                {quantity}
                                            </div>
                                        </div>
                                        <div className="col col-sml-12 col-lrg-3 spc--bottom--sml">
                                            <label className="type--wgt--semibold type--uppercase type--color--grey4">
                                                Merchant Price
                                            </label>
                                            <div className="input input--med input--fake input--currency input--currency--sml">
                                                {oneTimeFeeAmount}
                                            </div>
                                        </div>
                                        <div className="col col-sml-12 col-lrg-2 spc--bottom--sml">
                                            <label className="type--wgt--semibold type--uppercase type--color--grey4">
                                                Total Price
                                            </label>
                                            <div className="input input--med input--fake input--currency input--currency--sml">
                                                {quantity * oneTimeFeeAmount}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            </Toggle>
        );
    }

    renderGatewayPurchasePlan = ({ planName, fees, defaultFreeTransactions, includedFeatures }) => {
        const setupFee = get(find(fees, { feeType: 'OneTimeFee' }), 'retailPrice');
        const monthlyFee = get(find(fees, { feeType: 'MonthlyFee' }), 'retailPrice');
        const transactionFee = get(find(fees, { feeType: 'TransactionFee' }), 'retailPrice');

        return (
            <Fragment>
                <div className="card--primary__subheader" style={{borderTop: 'none'}}>
                    <strong>{planName}</strong>
                    {this.renderChangeLink(this.redirectToEquipment)}
                </div>
                <div className="row">
                    <div className="col col-sml-12 col-med-6 col-xlrg-3">
                        <div className="form__field spc--bottom--med">
                            <label className="label">Setup Fee</label>
                            <div className="input input--med input--fake input--currency input--currency--sml">
                                {setupFee}
                            </div>
                        </div>
                    </div>
                    <div className="col col-sml-12 col-med-6 col-xlrg-3">
                        <div className="form__field spc--bottom--med">
                            <label className="label">Monthly Fee</label>
                            <div className="input input--med input--fake input--currency input--currency--sml">
                                {monthlyFee}
                            </div>
                        </div>
                    </div>
                    <div className="col col-sml-12 col-med-6 col-xlrg-3">
                        <div className="form__field spc--bottom--med">
                            <label className="label">Transaction</label>
                            <div className="input input--med input--fake input--currency input--currency--sml">
                                {transactionFee}
                            </div>
                        </div>
                    </div>
                    <div className="col col-sml-12 col-med-6 col-xlrg-3">
                        <div className="form__field spc--bottom--med">
                            <label className="label">
                                Transactions included
                            </label>
                            <div className="input input--med input--fake">
                                {defaultFreeTransactions}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="separator--grey1 spc--bottom--sml"></div>
                {this.renderGatewayIncludedFeatures(includedFeatures)}
            </Fragment>
        );
    };

    renderGatewayIncludedFeatures = includedFeatures => (
        <Fragment>
            <div className="card--primary__subheader" style={{borderTop: 'none'}}>
                <strong>Features</strong>
                {this.renderChangeLink(this.redirectToEquipment)}
            </div>
            <div className="flex--primary">
                {map(includedFeatures, (value, key) => (
                    <div key={`${key}.${value}`} className="form__field spc--bottom--sml spc--right--lrg">
                        <label className="label label--ib">
                            {value}
                        </label>
                        <div className="gateway__icon spc--left--tny"></div>
                    </div>
                ))}
            </div>
        </Fragment>
    );

    renderGatewaysAndAddons = () => {
        const { existingMerchantEquipment, gatewayList } = this.state;
        let existingGatewayList = [];

        if (existingMerchantEquipment) {
            existingGatewayList = filter(existingMerchantEquipment, {
                category: "Gateway",
            });
        }

        const hasGateways = some(gatewayList, ({ equipmentId }) => some(existingGatewayList, item => item.equipmentId == equipmentId));

        return (
            <Toggle initialToggle={isExpand}>
                {({ isToggled, handleToggle }) => !hasGateways ? null : (
                    <Fragment>
                        <div className="card--primary card--sml spc--bottom--med">
                            <div
                                className={`card--primary__header flex--primary ${
                                    isToggled
                                        ? "separator--grey1 spc--bottom--med"
                                        : "padd--bottom--no"
                                }`}
                            >
                                <h6>Gateways and Add-ons</h6>
                                <i
                                    className={`spc--left--auto cursor--pointer icon icon--arrow icon--tiny icon--arrow--down--primary card--expand ${
                                        isToggled ? "rotate--180" : ""
                                    }`}
                                    onClick={handleToggle}
                                ></i>
                            </div>
                            <div
                                className={`details__content ${
                                    isToggled ? "" : "display--n"
                                }`}
                            >
                                {map(existingGatewayList, ({ equipmentId, planName }) => {
                                    const gatewayInfo = find(gatewayList, item => item.equipmentId == equipmentId);

                                    return gatewayInfo && (
                                        <Fragment>
                                            <div className="row row-align-middle">
                                                <div className="col col-sml-12 col-lrg-6">
                                                    <div className="form__field spc--bottom--med">
                                                        <label className="label">
                                                            Equipment ID
                                                        </label>
                                                        <div className="input input--med input--fake">
                                                            {equipmentId}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="separator--grey1 spc--bottom--sml"></div>
                                            {this.renderGatewayPurchasePlan(find(gatewayInfo.purchasePlans, { planName }) || find(gatewayInfo.purchasePlans, pp => includes(pp.planName, planName)))}
                                        </Fragment>
                                    );
                                })}
                            </div>
                        </div>
                    </Fragment>
                )}
            </Toggle>
        );
    };

    renderAccountInformation = () => {
        const { mpa, setup } = this.state;
        const { showEidsAndPci, merchant } = this.props;

        return (
            <Toggle initialToggle={isExpand}>
                {({ isToggled, handleToggle }) => (
                    <Fragment>
                        <div className="card--primary card--sml spc--bottom--med">
                            <div
                                className={`card--primary__header ${
                                    isToggled
                                        ? "is-expanded"
                                        : "is-collapsed"
                                }`}
                            >
                                <h6 className="spc--bottom--sml">Account Information</h6>
                                <div className="card--primary__header__expand" onClick={handleToggle}>
                                    <i className={`icon icon--arrow icon--tiny icon--arrow--down--primary ${isToggled ? "rotate--180" : ""}`}></i>
                                </div>
                            </div>
                            <div
                                className={`details__content ${
                                    isToggled ? "" : "display--n"
                                }`}
                            >
                                <div className="row">
                                    <div className="col col-sml-12 col-med-6">
                                        <div className="row spc--bottom--med">
                                            <div className="col col-sml-6 col-lrg-6 col-xlrg-5">
                                                <label
                                                    className="label"
                                                    htmlFor="lblMID"
                                                >
                                                    App ID{" "}
                                                </label>
                                            </div>
                                            <div className="col col-lrg-6 col-xlrg-7">
                                                <label
                                                    className="label--value"
                                                    name="lblMID"
                                                    id="lblMID"
                                                >
                                                    {mpa.appId}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row spc--bottom--med">
                                            <div className="col col-sml-6 col-lrg-6 col-xlrg-5">
                                                <label
                                                    className="label"
                                                    htmlFor="lblDBA"
                                                >
                                                    DBA/Merchant Name{" "}
                                                </label>
                                            </div>
                                            <div className="col col-lrg-6 col-xlrg-7">
                                                <label
                                                    className="label--value"
                                                    name="lblDBA"
                                                    id="lblDBA"
                                                >
                                                    {mpa.dba}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row spc--bottom--med">
                                            <div className="col col-sml-6 col-lrg-6 col-xlrg-5">
                                                <label
                                                    className="label"
                                                    htmlFor="lblProcessor"
                                                >
                                                    Processor{" "}
                                                </label>
                                            </div>
                                            <div className="col col-lrg-6 col-xlrg-7">
                                                <label
                                                    className="label--value"
                                                    name="lblProcessor"
                                                    id="lblProcessor"
                                                >
                                                    {getProcessorDisplayName(mpa.processorName)}
                                                </label>
                                            </div>
                                        </div>

                                        {showEidsAndPci && (
                                            <div className="row spc--bottom--med">
                                                <div className="col col-sml-6 col-lrg-6 col-xlrg-5">
                                                    <label
                                                        className="label"
                                                        htmlFor="lblPCI"
                                                    >
                                                        PCI Compliant{" "}
                                                    </label>
                                                </div>
                                                <div className="col col-lrg-6 col-xlrg-7">
                                                    <label
                                                        className="label--value"
                                                        name="lblPCI"
                                                        id="lblPCI"
                                                    >
                                                        <DisplayBooleanComponent value={mpa.isPciCompliant} />
                                                    </label>
                                                </div>
                                            </div>
                                        )}

                                        <div className="row spc--bottom--med">
                                            <div className="col col-sml-6 col-lrg-6 col-xlrg-5">
                                                <label
                                                    className="label"
                                                    htmlFor="lblOwner"
                                                >
                                                    Owner Name{" "}
                                                </label>
                                            </div>
                                            <div className="col col-lrg-6 col-xlrg-7">
                                                <label
                                                    className="label--value"
                                                    name="lblOwner"
                                                    id="lblOwner"
                                                >
                                                    {mpa.ownerName}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col col-sml-12 col-med-6">
                                        <div className="row spc--bottom--med">
                                            <div className="col col-sml-6 col-lrg-6 col-xlrg-5">
                                                <label
                                                    className="label"
                                                    htmlFor="lblContact"
                                                >
                                                    Main Contact{" "}
                                                </label>
                                            </div>
                                            <div className="col col-lrg-6 col-xlrg-7">
                                                <label
                                                    className="label--value"
                                                    name="lblContact"
                                                    id="lblContact"
                                                >
                                                    {mpa.businessContactName}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row spc--bottom--med">
                                            <div className="col col-sml-6 col-lrg-6 col-xlrg-5">
                                                <label
                                                    className="label"
                                                    htmlFor="lblAddress"
                                                >
                                                    Address{" "}
                                                </label>
                                            </div>
                                            <div className="col col-lrg-6 col-xlrg-7">
                                                <label
                                                    className="label--value"
                                                    name="lblAddress"
                                                    id="lblAddress"
                                                >
                                                    {mpa.businessInformation.businessAddress.streetAddress}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row spc--bottom--med">
                                            <div className="col col-sml-6 col-lrg-6 col-xlrg-5">
                                                <label
                                                    className="label"
                                                    htmlFor="lblPhone"
                                                >
                                                    Phone{" "}
                                                </label>
                                            </div>
                                            <div className="col col-lrg-6 col-xlrg-7">
                                                <label
                                                    className="label--value"
                                                    name="lblPhone"
                                                    id="lblPhone"
                                                >
                                                    {mpa.businessInformation.businessPhone}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row spc--bottom--med">
                                            <div className="col col-sml-6 col-lrg-6 col-xlrg-5">
                                                <label
                                                    className="label"
                                                    htmlFor="lblEmail"
                                                >
                                                    Email{" "}
                                                </label>
                                            </div>
                                            <div className="col col-lrg-6 col-xlrg-7">
                                                <label
                                                    className="label--value type--break--word"
                                                    name="lblEmail"
                                                    id="lblEmail"
                                                >
                                                    {mpa.businessInformation.businessEmail}
                                                </label>
                                            </div>
                                        </div>
                                        {showEidsAndPci && isFd(setup) && (
                                            <div className="row spc--bottom--med">
                                                <div className="col col-sml-6 col-lrg-6 col-xlrg-5">
                                                    <label
                                                        className="label"
                                                        htmlFor="lblIsEidsEnrolled"
                                                    >
                                                        EIDS{" "}
                                                    </label>
                                                </div>
                                                <div className="col col-lrg-6 col-xlrg-7">
                                                    <label
                                                        className="label--value"
                                                        name="lblIsEidsEnrolled"
                                                        id="lblIsEidsEnrolled"
                                                    >
                                                        <DisplayBooleanComponent value={merchant && merchant.isEidsEnrolled} />
                                                    </label>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )}
            </Toggle>
        );
    };

    renderSetupForm = () => {
        const { mpa, setup } = this.state;
        if (isEmpty(mpa) || isEmpty(setup)) return null;
        const { displayRates, showSubmit } = this.props;
        const { renderChangeLink, redirectToSetup } = this;
        const props = {
            mpa,
            setup,
            displayRates,
            showSubmit,
            renderChangeLink,
            redirectToSetup,
        }

        if (mpa.isCanadian) {
            return <CanadaSetupConfirm {...props} />;
        }
        return <USSetupConfirm {...props} />
    };

    renderMpaForm = () => {
        const { mpa } = this.state;
        if (isEmpty(mpa)) return null;
        const props = {
            mpa,
            renderChangeLink: this.renderChangeLink,
            redirectToMpa: this.redirectToMpa,
            displayLastFourOnly: this.props.displayLastFourOnly,
            getFilesForTag: this.getFilesForTag,
            showSSN: this.props.showSSN,
        }

        if (mpa.isCanadian) {
            return <CanadaMpaConfirm {...props} />;
        }
        return <USMpaConfirm {...props} />
    };


    isAgentCardknoxRep = () => {
        const cardknoxDomain = "cardknox.com";
        const domain = this.state.loggedInEmail.split("@")[1];

        if (domain === cardknoxDomain) {
            return true;
        }
    }
    renderSubmitAppModal = () => {
        const { isOpenSubmitApp, isRequestingRush, rushReason } = this.state;

        return (
            <Modal shouldCloseOnOverlayClick={false} isOpen={isOpenSubmitApp} onClose={this.closeSubmitApp}>
                <div>
                    <div className="popup__header">
                        <h6>Submit App</h6>
                    </div>
                        {this.isAgentCardknoxRep() ? (
                            null):
                    <div className="popup__body">
                        <div className="type--sml--alt" ref={this.errorRef} >
                                <p>Requesting a rush deducts 25 payment points. 
                                Sufficient payment</p>
                                <p>points are needed to be able to submit a rush request</p>
                        </div>
                    </div>
                        }
                    <div className="popup__footer popup__footer--styled">
                        {this.canSubmitRush() ? (
                            <div className="fullwidth spc--bottom--sml">
                                <div className="spc--bottom--sml">
                                    <input
                                        type="checkbox"
                                        name="isRequestingRush"
                                        className="input input--check"
                                        checked={isRequestingRush} value='isRequestingRush'
                                        id="isRequestingRush"
                                        onChange={this.handleChange}
                                        />
                                    <label htmlFor="isRequestingRush">Request Rush</label>
                                </div>
                                <input
                                    type="text"
                                    placeholder="Rush Reason"
                                    className="input input--number input--med"
                                    value={rushReason}
                                    id="rushReason"
                                    name="rushReason"
                                    onChange={this.handleChange}
                                    disabled={!isRequestingRush}
                                />
                            </div>
                        ) : null}
                        <button
                            className="btn btn--ghost btn--med"
                            onClick={this.closeSubmitApp}
                        >
                            Close
                        </button>
                        <button
                            className="btn btn--primary btn--med spc--left--tny"
                            onClick={this.submitApp}
                            disabled={this.props.isLoading}
                        >
                            Submit App
                        </button>
                    </div>
                </div>
            </Modal>
        );
    };

renderConfirmButton = () => {
    return (
        <button
            type="button"
            onClick={this.openSubmitApp}
            className="btn btn--med btn--primary"
        >
            Confirm App
        </button>
    );
};

renderChangeLink = handleRedirect => this.props.showChangeLink && (
    <div>
        <a
            className="anchor--primary type--sml type--wgt--medium cursor--pointer"
            onClick={handleRedirect}
        >
            Change
            </a>
    </div>
);
renderHeader = ()=>{
    const {errorMessage, appId} = this.state;
    return <React.Fragment>
            <Notification ref={this.notificationRef} />
            {this.props.showNavbar && (
                <div className="spc--bottom--med">
                    <NavbarComponent
                        appId={appId}
                        location={this.props.location}
                    />
                </div>
            )}
            {errorMessage ? (
                <div className="note note--warning" ref={this.errorRef}>
                    {errorMessage}
                </div>
            ) : null}
</React.Fragment>         
}

render() {
    const { showSubmit, title, displayEquipmentInfo } = this.props;
    const { appId, mpa } = this.state;
    const merchantDba = get(mpa, 'dba');
    return (
        <div id="main-div" className="l--content l--content--lrg">
        {this.renderHeader()}
            <div className="l--content--med">
                <ToggleContainer>
                    <div className="header header--mpa">
                        <div className="header__title spc--right--auto">
                            {merchantDba && appId && (
                                <div className="spc--bottom--sml">
                                    {`${merchantDba} - ${appId}`}
                                </div>
                            )}
                            <h3>{title}</h3>
                        </div>
                    </div>
                    {this.renderAccountInformation()}
                    {this.renderMpaForm()}
                    {this.renderSetupForm()}
                    {displayEquipmentInfo && this.renderGatewaysAndAddons()}
                    {displayEquipmentInfo && this.renderHardwareAndAccessories()}
                    {showSubmit && (
                        <div className="type--right">
                            {this.renderConfirmButton()}
                            {this.renderSubmitAppModal()}
                        </div>
                    )}
                </ToggleContainer>
            </div>
        </div>
    );
}
}

EAppConfirmComponent.defaultProps = {
    showNavbar: true,
    showChangeLink: true,
    showSubmit: true,
    showSSN: true,
    displayRates: false,
    displayEquipmentInfo: false,
    title: 'Form Confirmation',
    displayLastFourOnly: false,
};

EAppConfirmComponent.propTypes = {
    match: object,
    history: object,
    showLoader: func,
    isLoading: bool,
    showNavbar: bool,
    showChangeLink: bool,
    showSubmit: bool,
    displayRates: bool,
    displayEquipmentInfo: bool,
    title: string,
    displayLastFourOnly: bool,
    showEidsAndPci: bool,
    showSSN: bool,
    agentPoints: object,
};

export const WrappedEappConfirm = withLoader(withContext(withContext(withRouter(EAppConfirmComponent), PaymentPointsContext, "agentPoints"), MerchantContext, "merchant"));
export const EappConfirm = EAppConfirmComponent;
