import React, { Component } from 'react';
import PropTypes from 'prop-types';

class CorporateNameSelectionComponent extends Component {

    get corporateName() {
        return this.props.filter.values.corporateName;
    }

    filterRemoved = () => {
        this.props.onFilterRemoved({
            id: 'corporateName',
            emptyValue: null
        });
    };

    render() {
        return (
            <div className="filter__tag">
                <label className="filter__tag__label">Corporate Name: </label>
                {this.corporateName}
                <button onClick={this.filterRemoved} className="btn btn--reset filter__tag__clear"></button>
            </div>
        );
    }
}

CorporateNameSelectionComponent.propTypes = {
    filter: PropTypes.object.isRequired,
    onFilterRemoved: PropTypes.func.isRequired
};

export default CorporateNameSelectionComponent;
