import React from 'react';
import PropTypes from 'prop-types';
import { withExpander } from "./../../common/components";
import { renderIf } from "./../../common/utilities";
import {
    DisplayDateComponent,
    DisplayBooleanComponent    
} from "../../common/formatters";

class PCICompliance extends React.Component {
    render() {
        const { pciInfo, isExpanded } = this.props;
        
        return (
            <div className="card--primary card--sml">
                <div className="card--primary__header is-expanded">
                    <h6 className="spc--bottom--sml">PCI Compliance</h6>
                </div>

                <div className="details__content">
                    <div className="row">
                        <div className="col col-sml-12 col-med-6 spc--bottom--med">
                            <div className="row row-align-middle">
                                <div className="col col-sml-6 col-lrg-6">
                                    <label className="label" htmlFor="lblCompliant">Compliant: </label>
                                </div>
                                <div className="col col-sml-6 col-lrg-6">
                                    <label>
                                        <span className="label--value" name="lblCompliant" id="lblCompliant">
                                            <DisplayBooleanComponent value={pciInfo.isCompliant} />
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="col col-sml-12 col-med-6 spc--bottom--med">
                            <div className="row row-align-middle">
                                <div className="col col-sml-6 col-lrg-6">
                                    <label className="label" htmlFor="lblComplianceStatus">Compliance Status: </label>
                                </div>
                                <div className="col col-sml-6 col-lrg-6">
                                    <label>
                                        <label className="label--value" name="lblComplianceStatus" id="lblComplianceStatus" >{pciInfo.complianceStatus}</label>
                                    </label>
                                </div>
                            </div>                            
                        </div>
                        <div className="col col-sml-12 col-med-6 spc--bottom--med">
                            <div className="row row-align-middle">
                                <div className="col col-sml-6 col-lrg-6">
                                    <label className="label" htmlFor="lblSAQType">SAQ Type: </label>
                                </div>
                                <div className="col col-sml-6 col-lrg-6">
                                    <label>
                                        <label className="label--value" name="lblSAQType" id="lblSAQType" >{pciInfo.saqValidationType}</label>
                                    </label>
                                </div>
                            </div>                            
                        </div>
                    </div>
                    {renderIf(isExpanded)(
                        <React.Fragment>
                        <div className="border--bottom spc--bottom--med"></div>
                        <div className="row">
                            <div className="col col-sml-12 col-med-6">                                
                                <div className="row row-align-middle spc--bottom--med">
                                    <div className="col col-sml-6 col-lrg-8">
                                        <label className="label" htmlFor="lblLastSelfAssessmentDate">Last Compliant Self Assessment: </label>
                                    </div>
                                    <div className="col col-sml-6 col-lrg-4">
                                        <span className="label--value" name="lblLastSelfAssessmentDate" id="lblLastSelfAssessmentDate" >
                                            <DisplayDateComponent value={pciInfo.lastSelfAssessmentDate} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-sml-12 col-med-6">
                                <div className="row row-align-middle spc--bottom--med">
                                    <div className="col col-sml-6 col-lrg-8">
                                        <label className="label" htmlFor="lblLastScanDate">Last Compliant Scan: </label>
                                    </div>
                                    <div className="col col-sml-6 col-lrg-4">
                                        <span className="label--value" name="lblLastScanDate" id="lblLastScanDate" >
                                            <DisplayDateComponent value={pciInfo.lastScanDate} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                                <div className="col col-sml-12 col-med-6">
                                    <div className="row row-align-middle spc--bottom--med">
                                        <div className="col col-sml-6 col-lrg-8">
                                            <label className="label" htmlFor="lblNonCompliantBillable">Non Compliant Billable:</label>
                                        </div>
                                        <div className="col col-sml-6 col-lrg-4">
                                            <label className="input input--med input--fake input--fake--status"> 
                                                <span className="label--value" name="lblNonCompliantBillable" id="lblNonCompliantBillable">
                                                    <DisplayBooleanComponent value={pciInfo.isNonCompliantBillable} />
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-sml-12 col-med-6">
                                    <div className="row row-align-middle spc--bottom--med">
                                        <div className="col col-sml-6 col-lrg-8">
                                            <label className="label" htmlFor="lblAnnualFee">Annual Fee: </label>
                                        </div>
                                        <div className="col col-sml-6 col-lrg-4">
                                            <label className="label--value" name="lblAnnualFee" id="lblAnnualFee" >{pciInfo.annualFee}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </div>
                
            </div>     
        );
    }
}

export default withExpander(PCICompliance);