import React, { Component } from 'react';
import PropTypes from 'prop-types';

class AddressSelectionComponent extends Component {

    get addressValue() {
        return this.props.filter.values.address;
    }

    filterRemoved = e => {
        this.props.onFilterRemoved({
            id: 'address',
            emptyValue: null
        });
    };

    render() {
        return (
            <div className="filter__tag">
                <label className="filter__tag__label">Address: </label>
                {this.addressValue}
                <button onClick={this.filterRemoved} className="btn btn--reset filter__tag__clear"></button>
            </div>
        );
    }
}

AddressSelectionComponent.propTypes = {
    filter: PropTypes.object.isRequired,
    onFilterRemoved: PropTypes.func.isRequired
};

export default AddressSelectionComponent;