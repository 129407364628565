import React, { Component, Fragment, createRef } from "react";
import {
    find,
    filter,
    cloneDeep,
    map,
    get,
    orderBy,
    toLower,
    upperFirst,
    uniqBy,
    findIndex,
    includes,
    startsWith,
    some,
    noop,
} from "lodash";
import { func, array, object } from "prop-types";
import { withRouter } from "react-router-dom";

import { emailTemplates, posSystemList } from "../../common/utilities";
import { modalNames } from "../../common/components/modal-wrapper/modal-names";
import principalService from "../../services/principalService";
import emailService from "../../services/emailService";
import { ModalWrapper } from "../../common/components/modal-wrapper";
import { withContext, withLoader } from "../../common/components";
import { Notification } from "../../common/components/notifications";
import { MerchantContext } from "../MerchantDetails";
import { equipmentDB, equipmentDatabaseKeys as keys } from "../../helpers/indexedDB";

class PosSystemListComponent extends Component {
    constructor(props) {
        super(props);

        const principal = principalService.get();

        this.state = {
            filteredList: this.initialFilteredList,
            expanded: {},
            filterSettings: this.initialFilterSettings,
            fromEmail: principal.email,
            fromName: principal.name,
            modal: {
                name: modalNames.none,
                data: null,
            },
            merchant: {},
            errorMessage: null,
        };

        this.notificationRef = createRef();
    }

    get initialFilteredList() {
        return map(
            orderBy(posSystemList, ({ name }) => toLower(name), ["asc"]),
            (item, index) => ({ ...item, index, isSelected: false })
        );
    }

    get initialFilterSettings() {
        return {
            name: "",
            supportedIndustries: "",
            supportedGateways: "",
        };
    };

    get supportedIndustriesOptions() {
        return orderBy(
            uniqBy(posSystemList, "supportedIndustries"),
            ({ supportedIndustries }) => toLower(supportedIndustries),
            ["asc"]
        );
    }

    get supportedGatewaysOptions() {
        return orderBy(
            uniqBy(posSystemList, "supportedGateways"),
            ({ supportedGateways }) => toLower(supportedGateways),
            ["asc"]
        );
    }

    openCloseModal = (modalObj) => {
        let state = {
            modal: modalObj,
        };
        this.setState(state);
    };

    sendInquireEmail = async () => {
        try {
            const { merchantPosSystemList, clearMerchantPosSystemList, merchant: { appId: appId, mid, dba } } = this.props;
            const {
                fromEmail,
                fromName,
            } = this.state;
            const addNotification = get(
                this.notificationRef,
                "current.addNotification",
                noop
            );
            const selectedPosSystems = map(
                filter(merchantPosSystemList, ({ isSelected }) => isSelected),
                ({ name }) => name
            );

            await emailService.send(emailTemplates.posSystemInquire, {
                toEmail: process.env.REACT_APP_TECH_SUPPORT_EMAIL,
                fromEmail,
                fromName,
                appId,
                mid,
                dba,
                posSystems: selectedPosSystems,
            });

            equipmentDB.deleteEquipment(keys.merchantPosSystemList, appId);
            this.setState({ filteredList: this.initialFilteredList, filterSettings: this.initialFilterSettings, expanded: {} });
            clearMerchantPosSystemList();

            addNotification({
                message: "Sent successfully.",
                success: true,
            });
        } catch (e) {
            this.setState({ errorMessage: e.message });
        }
    };

    openConfirmSendEmailPopup = () =>
        this.setState({
            modal: {
                name: modalNames.confirmAction,
                data: {
                    question:
                        "Are you sure you want to inquire the selected POS Systems?",
                    onConfirm: this.sendInquireEmail,
                },
            },
        });

    toggleSelect = (name) => () => {
        let posSystem = cloneDeep(
            this.props.merchantPosSystemList?.find((e) => e.name === name)
        );

        if (!!posSystem) {
            posSystem.isSelected = !posSystem.isSelected;
        } else {
            posSystem = find(this.initialFilteredList, { name });
            posSystem.isSelected = true;
        }

        delete posSystem.index;

        this.props.onChange(posSystem);
    };

    setFilter(fieldName, valueToFilter, clearOtherFilters = false) {
        const { filterSettings } = this.state;

        let query = cloneDeep(filterSettings);
        let filteredList = [];

        if (clearOtherFilters) query = {};

        query[fieldName] = toLower(valueToFilter);

        filteredList = filter(this.initialFilteredList, (item) => {
            for (let key in query) {
                if (
                    item[key] === undefined ||
                    !includes(toLower(item[key]), query[key]) ||
                    (startsWith(key, "supported") &&
                        query[key] &&
                        toLower(item[key]) !== query[key])
                ) {
                    return false;
                }
            }
            return true;
        });

        this.setState({
            filterSettings: query,
            filteredList: orderBy(filteredList, ({ name }) => toLower(name), ["asc"]),
        });
    }

    handleFiltersChange = ({ target: { name, value } }) =>
        this.setFilter(name, value);

    handleChange = (name) => ({ target: { name: key, value } }) => {
        const { merchantPosSystemList } = this.props;
        let index = findIndex(merchantPosSystemList, { name });
        let posSystem = null;

        if (index > -1) {
            posSystem = {
                ...merchantPosSystemList[index],
                [key]: value,
            };
        } else {
            posSystem = find(this.initialFilteredList, { name });
            posSystem[key] = value;
        }

        this.props.onChange(posSystem);
    };

    renderRowDetails = ({
        supportedDevices,
        resellerCommision,
        billingPlan,
        setupProcess,
        setupAndSupportBy,
        additionalInformation,
        addonsAndIntegrations,
        cost,
        url,
    }) => (
        <div className="accessories__list__item--expanded">
            <div className="flex--primary border--top type--base padd--sml">
                <label className="type--sml type--uppercase type--color--text--regular type--wgt--bold spc--right--sml">
                    Supported Devices
                </label>
                <span className="type--sml--alt">{supportedDevices}</span>
            </div>
            <div className="flex--primary border--top type--base padd--sml">
                <label className="type--sml type--uppercase type--color--text--regular type--wgt--bold spc--right--sml">Cost</label>
                <span className="type--sml--alt">{cost}</span>
            </div>
            <div className="flex--primary border--top type--base padd--sml">
                <label className="type--sml type--uppercase type--color--text--regular type--wgt--bold spc--right--sml">
                    Reseller Commission
                </label>
                <span className="type--sml--alt">{resellerCommision}</span>
            </div>
            <div className="flex--primary border--top type--base padd--sml">
                <label className="type--sml type--uppercase type--color--text--regular type--wgt--bold spc--right--sml">
                    Billing Plan
                </label>
                <span className="type--sml--alt">{billingPlan}</span>
            </div>
            <div className="flex--primary border--top type--base padd--sml">
                <label className="type--sml type--uppercase type--color--text--regular type--wgt--bold spc--right--sml">
                    Setup Process
                </label>
                <span className="type--sml--alt">{setupProcess}</span>
            </div>
            <div className="flex--primary border--top type--base padd--sml">
                <label className="type--sml type--uppercase type--color--text--regular type--wgt--bold spc--right--sml">
                    Setup and Support by
                </label>
                <span className="type--sml--alt">{setupAndSupportBy}</span>
            </div>
            <div className="flex--primary border--top type--base padd--sml">
                <label className="type--sml type--uppercase type--color--text--regular type--wgt--bold spc--right--sml">
                    Additional Information
                </label>
                <span className="type--sml--alt">{additionalInformation}</span>
            </div>
            <div className="flex--primary border--top type--base padd--sml">
                <label className="type--sml type--uppercase type--color--text--regular type--wgt--bold spc--right--sml">
                    Add-ons & Integrations
                </label>
                <span className="type--sml--alt">{addonsAndIntegrations}</span>
            </div>
        </div>
    );

    renderTableBody = (row) => {
        const {
            name,
            supportedIndustries,
            specialty,
            supportedProcessors,
            supportedGateways,
            note,
        } = row;
        const { expanded } = this.state;
        const { merchantPosSystemList } = this.props;
        let merchantPosSystem = find(
            merchantPosSystemList,
            (e) => e.name === name
        );
        const isExpanded = expanded.name === name;

        return (
            <Fragment>
                <div className="equipment__list equipment__list--pos">
                <div className="equipment__list__item select">
                        <div className="equipment__list__th"></div>
                        <div className="equipment__list__td">
                            {merchantPosSystem &&
                            merchantPosSystem.isSelected ? (
                                <div className="accessories__list__item__added-check">
                                    <input
                                        type="checkbox"
                                        className="input input--check"
                                        id={`added.${name}`}
                                        checked={!!merchantPosSystem.isSelected}
                                        value={!!merchantPosSystem.isSelected}
                                        onChange={this.toggleSelect(name)}
                                    />
                                    <label htmlFor={`added.${name}`}>
                                        Added
                                    </label>
                                </div>
                            ) : (
                                <div className="accessories__list__item__add-check">
                                    <input
                                        type="checkbox"
                                        className="input input--check"
                                        id={`select.${name}`}
                                        checked={false}
                                        value={false}
                                        onChange={this.toggleSelect(name)}
                                    />
                                    <label htmlFor={`select.${name}`}>
                                        Add
                                    </label>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="equipment__list__item name">
                        <div className="equipment__list__th">Name</div>
                        <div className="equipment__list__td cursor--pointer">
                            <div
                                className="accessories__list__item__thumbnail"
                                style={{
                                    backgroundImage:
                                        "url(" +
                                        process.env.REACT_APP_CDN_URL +
                                        name
                                            .toLowerCase()
                                            .replace(" ", "_")
                                            .replace(/[^a-z0-9_-]/gi, "") +
                                        "/thumbnail.png" +
                                        ")",
                                }}
                            ></div>
                            <a className="type--wgt--bold type--base">{name}</a>
                        </div>
                    </div>
                    <div className="equipment__list__item">
                        <div className="equipment__list__th">Industries</div>
                        <div className="equipment__list__td top">
                            <ul className="list list--primary">
                                <li className="item">
                                    {supportedIndustries}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="equipment__list__item">
                        <div className="equipment__list__th">Specialty</div>
                        <div className="equipment__list__td top">
                            <ul className="list list--primary">
                                <li className="item">
                                    {specialty}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="equipment__list__item">
                        <div className="equipment__list__th">Processors</div>
                        <div className="equipment__list__td top">
                            <ul className="list list--primary">
                                <li className="item">
                                    {supportedProcessors}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="equipment__list__item">
                        <div className="equipment__list__th">
                            Supported Gateways
                        </div>
                        <div className="equipment__list__td top">
                            <ul className="list list--primary">
                                <li className="item">
                                    {supportedGateways}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="equipment__list__item">
                        <div className="equipment__list__th">Note</div>
                        <div className="equipment__list__td top">
                            <input
                                className="input input--med"
                                placeholder="Note"
                                name="note"
                                id={`${name}.note`}
                                value={get(merchantPosSystem, "note", note)}
                                onChange={this.handleChange(name)}
                                onClick={(e) => e.stopPropagation()}
                            />
                        </div>
                    </div>
                    <div className="equipment__list__item expand">
                        <div className="equipment__list__th right"></div>
                        <div className="equipment__list__td right">
                            {merchantPosSystem &&
                            merchantPosSystem.isSelected ? (
                                <div className="accessories__list__item__added-check">
                                    <input
                                        type="checkbox"
                                        className="input input--check"
                                        id={`added.${name}`}
                                        checked={!!merchantPosSystem.isSelected}
                                        value={!!merchantPosSystem.isSelected}
                                        onChange={this.toggleSelect(name)}
                                    />
                                    <label htmlFor={`added.${name}`}>
                                        Added
                                    </label>
                                </div>
                            ) : (
                                <div className="accessories__list__item__add-check">
                                    <input
                                        type="checkbox"
                                        className="input input--check"
                                        id={`select.${name}`}
                                        checked={false}
                                        value={false}
                                        onChange={this.toggleSelect(name)}
                                    />
                                    <label htmlFor={`select.${name}`}>
                                        Add
                                    </label>
                                </div>
                            )}
                            <i
                                className={`icon icon--arrow icon--tiny icon--arrow--right--primary cursor--pointer spc--left--sml spc--right--sml${
                                    isExpanded ? " rotate--90" : ""
                                }`}
                                onClick={this.onRowClick(row.name)}
                            ></i>
                        </div>
                    </div>
                </div>
                {isExpanded && this.renderRowDetails(row)}
            </Fragment>
        );
    };

    onRowClick = (name) => () => {
        let expanded = {};

        if (name !== this.state.expanded.name) {
            expanded = find(this.state.filteredList, { name });
        }

        this.setState({ expanded });
    };

    renderRow = (row, index) => (
        <div
            className="card--tertiary card--med accessories__list__item spc--bottom--sml"
            key={index}
        >
            {this.renderTableBody(row)}
        </div>
    );

    render() {
        const { isLoading, merchantPosSystemList } = this.props;
        const { filteredList, filterSettings, errorMessage } = this.state;
        const hasSelectedPosSystems = some(
            merchantPosSystemList,
            ({ isSelected }) => isSelected
        );
        const disabled = isLoading || !hasSelectedPosSystems;

        return (
            <div className="l--twocols">
                <Notification ref={this.notificationRef} />
                <ModalWrapper
                    modal={this.state.modal}
                    onModalClose={this.openCloseModal}
                />
                <div className="fullwidth">
                    <div className="message message--default spc--bottom--sml">
                        <p>
                            <span className="type--wgt--bold">Note:</span> The POS system(s) you add here are not automatically added to the merchant's account. After you submit the eApp, the internal team reviews your selections and will contact you for additional details and to confirm compatibility.
                        </p>
                    </div>
                    <div>
                        <button
                            className="btn btn--med btn--primary spc--bottom--med"
                            onClick={this.openConfirmSendEmailPopup}
                            disabled={disabled}
                        >
                            Inquire Selected POS Systems
                        </button>
                    </div>
                    {errorMessage && (
                        <div className="spc--top--sml spc--bottom--sml note note--warning">
                            {errorMessage}
                        </div>
                    )}
                    <div className="accessories__filter">
                        <label className="type--nowrap label spc--right--med type--color--primary spc--bottom--xsml">
                            Filter by
                        </label>
                        <div className="w--max--300 inputgroup spc--bottom--xsml spc--right--med">
                            <div className="inputgroup--aside">
                                <label className="type--nowrap label spc--right--sml">
                                    Industry Type
                                </label>
                            </div>
                            <div className="inputgroup--main">
                                <select
                                    className="input input--med input--select"
                                    name="supportedIndustries"
                                    id="supportedIndustries"
                                    onChange={this.handleFiltersChange}
                                    value={
                                        filterSettings.supportedIndustries
                                    }
                                >
                                    <option value="">All</option>
                                    {map(
                                        this.supportedIndustriesOptions,
                                        ({ supportedIndustries }, index) =>
                                            supportedIndustries && (
                                                <option
                                                    key={`${supportedIndustries}.${index}`}
                                                    value={toLower(
                                                        supportedIndustries
                                                    )}
                                                >
                                                    {upperFirst(
                                                        supportedIndustries
                                                    )}
                                                </option>
                                            )
                                    )}
                                </select>
                            </div>
                        </div>
                        <div className="w--max--300 inputgroup spc--bottom--xsml spc--right--med">
                            <div className="inputgroup--aside">
                                <label className="type--nowrap label spc--right--sml">
                                    Supported Gateways
                                </label>
                            </div>
                            <div className="inputgroup--main">
                                <select
                                    className="input input--med input--select"
                                    name="supportedGateways"
                                    id="supportedGateways"
                                    onChange={this.handleFiltersChange}
                                    value={filterSettings.supportedGateways}
                                >
                                    <option value="">All</option>
                                    {map(
                                        this.supportedGatewaysOptions,
                                        ({ supportedGateways }, index) =>
                                            supportedGateways && (
                                                <option
                                                    key={`${supportedGateways}.${index}`}
                                                    value={toLower(
                                                        supportedGateways
                                                    )}
                                                >
                                                    {upperFirst(
                                                        supportedGateways
                                                    )}
                                                </option>
                                            )
                                    )}
                                </select>
                            </div>
                        </div>
                    </div>

                    <div>
                        {filteredList.length === 0 ? (
                            <div className="note note--default">
                                No POS Systems found for the current filter
                            </div>
                        ) : (
                            map(filteredList, (row, index) =>
                                this.renderRow(row, index)
                            )
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

PosSystemListComponent.propTypes = {
    merchantPosSystemList: array,
    onChange: func,
    merchant: object,
};

export default withLoader(withRouter(withContext(PosSystemListComponent, MerchantContext, 'merchant')));
