import React from 'react';
import { withContext, withLoader } from './../../common/components';
import { appService } from '../../services/appService';
import { renderIf } from './../../common/utilities';
import { cloneDeep, find, get, noop } from 'lodash';
import { ModalWrapper, modalNames } from './../../common/components/modal-wrapper';
import { DisplayDateComponent } from '../../common/formatters';
import { Notification } from '../../common/components/notifications';
import { MerchantContext } from '../MerchantDetails';
import MerchantInfoHeader from '../MerchantEquipment/components/MerchantInfoHeader';

class NotesView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			notesList: null,
			editingNoteId: -1,
			editingNote: {},
			modal: {
				name: modalNames.none,
				data: null,
			},
		};

		this.notificationRef = React.createRef();
	}

	componentDidMount() {
		this.fetchData();
	}

	fetchData = () => {
		const { appid } = this.props.match.params;

		this.props.showLoader(true);
		//console.log('getting notes');
		appService
			.getMerchantNotes(appid)
			.then(notesObj => {
				this.props.showLoader(false);
				this.setState({ notesList: notesObj.notes });
			})
			.catch(err => {
				console.log('getMerchantNotes error', err);
				this.props.showLoader(false);
				this.setState({ errorMessage: 'An error occurred: ' + err });
			});
	};

	onEdit = note => () => {
		note.originalNoteText = note.noteText;
		this.setState({ editingNoteId: note.noteId, editingNote: note });
	};

	onTextChange = e => {
		let note = cloneDeep(this.state.editingNote);
		note.noteText = e.target.value;

		this.setState({ editingNote: note });
		//console.log('new note text ' + note.noteText);
	};

	onSaveEdit = () => {
		//console.log('saving');
		this.props.showLoader(true);
		const { appid } = this.props.match.params;
		const note = this.state.editingNote;

		appService
			.editNote(appid, note.noteId, note.noteText)
			.then(() => {
				this.props.showLoader(false);
				let newNotesList = cloneDeep(this.state.notesList);
				let editedNote = find(newNotesList, item => item.noteId == note.noteId);
				editedNote.noteText = note.noteText;
				this.setState({ notesList: newNotesList, editingNoteId: -1, editingNote: {} });
				const addNotification = get(this.notificationRef, 'current.addNotification', noop);
				addNotification({
					message: 'Saved successfully',
					success: true,
				});
			})
			.catch(err => {
				console.log('editNote error', err);
				this.props.showLoader(false);
				this.setState({ errorMessage: 'An error occurred: ' + err });
			});
	};

	onCancelEdit = note => () => {
		//console.log('cancelling');

		let newNotesList = cloneDeep(this.state.notesList);
		let editedNote = find(newNotesList, item => item.noteId == note.noteId);
		editedNote.noteText = note.originalNoteText;
		this.setState({ notesList: newNotesList, editingNoteId: -1, editingNote: {} });
	};

	onDelete = note => () => {
		//console.log('deleting');
		this.props.showLoader(true);
		const { appid } = this.props.match.params;

		appService
			.deleteNote(appid, note.noteId)
			.then(() => {
				const addNotification = get(this.notificationRef, 'current.addNotification', noop);
				this.props.showLoader(false);
				let newNotesList = this.state.notesList.filter(item => item.noteId !== note.noteId);
				this.setState({ notesList: newNotesList });
				addNotification({
					message: 'Deleted successfully',
					success: true,
				});
			})
			.catch(err => {
				console.log('deleteNote error', err);
				this.props.showLoader(false);
				this.setState({ errorMessage: 'An error occurred: ' + err });
			});
	};

	openCloseModal = (modalObj, ...rest) => {
		let state = {
			modal: modalObj,
		};
		this.setState(state);
		if (modalObj.name === modalNames.none) {
			this.fetchData();
		}
	};

	renderNewNote = () => {
		const { appid } = this.props.match.params;
		return (
			<button
				type="button"
				className="btn btn--med btn--primary"
				onClick={() => this.openCloseModal({ name: modalNames.newNote, data: { appid: appid, hideViewAll: true } })}
			>
				<i className="icon icon--sml icon--add--white"></i>
				<span>Add Note</span>
			</button>
		);
	};

	renderNotesList(notes) {
		return !notes || !notes.length ? (
			<tr>
				<td colSpan={4} className="pos--rel">
					<div className="table--emptystate">
						<div className="table--emptystate--img"></div>
						<div className="table--emptystate--title">0 Results</div>
						<p className="table--emptystate--text">No notes found. Add a new note to view it in the list.</p>
					</div>
				</td>
			</tr>
		) : (
			notes.map(note => {
				return (
					<tr key={note.noteId}>
						<td className="type--nowrap">
							<DisplayDateComponent value={note.enteredDate} />
						</td>
						<td>{note.enteredBy}</td>
						{renderIf(this.state.editingNoteId == note.noteId)(
							<React.Fragment>
								<td>
									<input
										type="text"
										className="input input--sml"
										value={this.state.editingNote.noteText}
										onChange={this.onTextChange}
										id={'txtNote_' + note.noteId}
									/>
								</td>
								<td className="type--right">
									<div className="flex--primary flex--nowrap flex--right">
										<button
											onClick={this.onSaveEdit}
											className="btn btn--med btn--link datatooltip--left datatooltip--auto"
											data-tooltip="Save"
										>
											<i className="icon icon--sml icon--regular--check" />
										</button>
										<button
											onClick={this.onCancelEdit(note)}
											className="btn btn--med btn--link datatooltip--left datatooltip--auto"
											data-tooltip="Cancel"
										>
											<i className="icon icon--sml icon--regular--error" />
										</button>
									</div>
								</td>
							</React.Fragment>
						)}
						{renderIf(this.state.editingNoteId != note.noteId)(<td className="is-note">{note.noteText}</td>)}
						{this.state.editingNoteId != note.noteId && (note.canEdit === 1 || note.canEdit === true) && (
							<React.Fragment>
								<td className="type--right">
									<div className="flex--primary flex--nowrap flex--right">
										<button
											onClick={this.onEdit(note)}
											className="btn btn--med btn--link datatooltip--left datatooltip--auto"
											data-tooltip="Edit"
										>
											<i className="icon icon--sml icon--edit--light" />
										</button>
										<button
											onClick={this.onDelete(note)}
											className="btn btn--med btn--link datatooltip--left datatooltip--auto"
											data-tooltip="Delete"
										>
											<i className="icon icon--sml icon--delete--light" />
										</button>
									</div>
								</td>
							</React.Fragment>
						)}
					</tr>
				);
			})
		);
	}

	render() {
		const { notesList, errorMessage } = this.state;
		const { merchant } = this.props;

		return (
			<React.Fragment>
				<Notification ref={this.notificationRef} />
				<ModalWrapper modal={this.state.modal} onModalClose={this.openCloseModal} />
				<div className="l--content" ref={this.detailsRef}>
					{errorMessage ? <div className="type--validation">{errorMessage}</div> : null}
					{notesList ? (
						<React.Fragment>
							<header>
								<MerchantInfoHeader merchant={merchant} />
								<div className="flex--secondary spc--bottom--xlrg">
									<h4>Notes</h4>
									{this.renderNewNote()}
								</div>
							</header>

							<div className="table__wrapper">
								<table className="table table--fixed table--primary">
									<colgroup>
										<col width={200} />
										<col width={230} />
										<col width={200} />
										<col width={120} />
									</colgroup>
									<thead>
										<tr>
											<th>Date Created</th>
											<th>Name</th>
											<th>Note Name</th>
											<th></th>
										</tr>
									</thead>
									<tbody>{this.renderNotesList(notesList)}</tbody>
								</table>
							</div>
						</React.Fragment>
					) : null}
				</div>
			</React.Fragment>
		);
	}
}

export default withLoader(withContext(NotesView, MerchantContext, 'merchant'));
