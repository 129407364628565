import React from 'react';
import Draggable from './Draggable';
import '../../react-data-grid-common/themes/react-data-grid-header.css';

const style = {
  position: 'absolute',
  top: 0,
  right: 0,
  width: 6,
  height: '100%'
};

class ResizeHandle extends React.Component {
  render() {
    return (
      <Draggable {...this.props}
        className="react-grid-HeaderCell__resizeHandle"
        style={style}
      />
    );
  }
}

export default ResizeHandle;
