//import config from 'config';
import principalService from '../services/principalService';
import { _sharedFunctions } from './_sharedFunctions';
import { _eappFunctions } from './_eappFunctions';
import { _leadFunctions } from './_leadFunctions';
import { _merchantFunctions } from './_merchantFunctions';
import { _ticketFunctions } from './_ticketFunctions';
import { _viewFunctions } from './_viewFunctions';
import { _residualsFunctions } from './_residualsFunctions';
import { _mfaStepUpFunctions } from './_mfaStepUpFunctions';
import { getApiUrl } from '../common/utilities/apiConfiguration';
import { Auth } from 'aws-amplify';
import { includes, toLower, has } from 'lodash';
import { authHeader } from '../helpers/auth-header';
import { logSuccessfulLogin } from '../common/utilities/loginAttemptsHandler';

const handleResponse = _sharedFunctions.handleResponse;
const appRequestHeaders = _sharedFunctions.appRequestHeaders;

export const appService = {
    apiInfo,
    isDev,
    login,
    logout: _sharedFunctions.logout,
    dropin,
    getPoints,
    getAccountBoardingSandboxKey,
    getAgentSettings,
    getCobrandingSettings,
    saveAgentSettings,
    saveCobrandingSettings,
    cancelDropin,
    logClientError,
    /* cognito */
    verifyUserAttribute,
    getUserAttributeVerificationCode,
    /*leads*/
    getLeads: _leadFunctions.getLeads,
    getLeadDetails: _leadFunctions.getLeadDetails,
    deleteLeadFile: _leadFunctions.deleteLeadFile,
    saveAccountDetails: _leadFunctions.saveAccountDetails,
    saveLeadDetails: _leadFunctions.saveLeadDetails,
    saveLeadFiles: _leadFunctions.saveLeadFiles,
    submitReviewPricing: _leadFunctions.requestPricingAnalysis,
    convertLeadToApp: _leadFunctions.convertLeadToApp,
    archiveLead: _leadFunctions.archiveLead,
    unarchiveLead: _leadFunctions.unarchiveLead,
    getReviewPricingSummary: _leadFunctions.getPricingAnalysisSummary,
    getTierDetails: _leadFunctions.getTierDetails,
    /* eApp */
    getEApps: _eappFunctions.getEApps,
    getEApp: _eappFunctions.getEApp,
    getEAppForMerchant: _eappFunctions.getEAppForMerchant,
    getGoPlus: _eappFunctions.getGoPlus,
    getEappStatus: _eappFunctions.getEappStatus,
    updateSignatureStatus: _eappFunctions.updateSignatureStatus,
    completeSignature: _eappFunctions.completeSignature,
    downloadPrefilledMPA: _eappFunctions.downloadPrefilledMPA,
    downloadPrefilledFile: _eappFunctions.downloadPrefilledFile,
    prefillDocumentFields: _eappFunctions.prefillDocumentFields,
    getMerchantFiservApplicationFile: _eappFunctions.getMerchantFiservApplicationFile,
    shareMPAForm: _eappFunctions.shareMPAForm,
    sendMerchantAchqTerms: _eappFunctions.sendMerchantAchqTerms,
    getMerchantFile: _eappFunctions.getMerchantFile,
    deleteMerchantFile: _eappFunctions.deleteMerchantFile,
    getMerchantSetupForm: _eappFunctions.getMerchantSetupForm,
    getProcessorListByAppId: _eappFunctions.getProcessorListByAppId,
    getProcessorListByCountry: _eappFunctions.getProcessorListByCountry,
    saveEApp: _eappFunctions.saveEApp,
    saveAchEApp: _eappFunctions.saveAchEApp,
    saveMerchantSetupForm: _eappFunctions.saveMerchantSetupForm,
    saveMerchantFiles: _eappFunctions.saveMerchantFiles,
    saveMerchantAchPlan:_eappFunctions.saveMerchantAchPlan,
    getAchPlan:_eappFunctions.getAchPlan,
    getMerchantPlan:_eappFunctions.getMerchantPlan,
    getEquipmentList: _eappFunctions.getEquipmentList,
    getEquipmentListByProcessor: _eappFunctions.getEquipmentListByProcessor,
    getVarList: _eappFunctions.getVarList,
    getHardwareList: _eappFunctions.getHardwareList,
    getGatewayList: _eappFunctions.getGatewayList,
    getAddonList: _eappFunctions.getAddonList,
    validateSerialNumber: _eappFunctions.validateSerialNumber,
    cancelEquipment: _eappFunctions.cancelEquipment,
    getShippingRates: _eappFunctions.getShippingRates,
    submitApplication: _eappFunctions.submitApplication,
    getMerchantEquipment: _eappFunctions.getMerchantEquipment,
    saveMerchantEquipment: _eappFunctions.saveMerchantEquipment,
    saveEquipmentTemplate: _eappFunctions.saveEquipmentTemplate,
    saveMerchantVar: _eappFunctions.saveMerchantVar,
    getAssociatedPartners: _eappFunctions.getAssociatedPartners,
    submitGoPlus: _eappFunctions.submitGoPlus,
    getGoPlusTiers: _eappFunctions.getGoPlusTiers,
    getAllowAchqApplication: _eappFunctions.getAllowAchqApplication,
    saveGoPlus: _eappFunctions.saveGoPlus,
    prepareMerchantFileForTransfer: _eappFunctions.prepareMerchantFileForTransfer,
    getPlaidLinkToken: _eappFunctions.getPlaidLinkToken,
    exchangePlaidAccessToken: _eappFunctions.exchangePlaidAccessToken,
    cloneEApp: _eappFunctions.cloneEApp,
    getGoPlusUnderwritingLevels: _eappFunctions.getGoPlusUnderwritingLevels,
    /* Merchants */
    getMerchants: _merchantFunctions.getMerchants,
    getMerchantDetails: _merchantFunctions.getMerchantDetails,
    getMerchantNotes: _merchantFunctions.getMerchantNotes,
    getInvoices: _eappFunctions.getInvoices,
    getInvoiceFieldsData: _eappFunctions.getInvoiceFieldsData,
    addNote: _merchantFunctions.addNote,
    editNote : _merchantFunctions.editNote,
    deleteNote: _merchantFunctions.deleteNote,
    hideMerchant: _merchantFunctions.hideMerchant,
    unhideMerchant: _merchantFunctions.unhideMerchant,
    saveContact: _merchantFunctions.saveContact,
    submitBankingServicesRequest: _merchantFunctions.submitBankingServicesRequest,
    updateBankingServicesRequest: _merchantFunctions.updateBankingServicesRequest,
    getElavonSettlementMonths: _merchantFunctions.getElavonSettlementMonths,
    getElavonSettlementsForMonth: _merchantFunctions.getElavonSettlementsForMonth,
    getElavonStatementMonths: _merchantFunctions.getElavonStatementMonths,
    getElavonStatementForMonth: _merchantFunctions.getElavonStatementForMonth,
    updateAppsSignatureRequest: _merchantFunctions.updateAppsSignatureRequest,
    UpdateRequiredAccountInformation: _merchantFunctions.UpdateRequiredAccountInformation,
    SendInvoiceEmail: _merchantFunctions.SendInvoiceEmail,
    GetTemplateEquipment: _merchantFunctions.GetTemplateEquipment,
/* Tickets */
    getTickets: _ticketFunctions.getTickets,
    getTicketDetails: _ticketFunctions.getTicketDetails,
    addNoteToTicket: _ticketFunctions.addNoteToTicket,
    createNewTicket: _ticketFunctions.createNewTicket,
    /* Views */
    saveAgentView: _viewFunctions.saveAgentView,
    updateDefaultAgentView: _viewFunctions.updateDefaultAgentView,
    getAgentViews: _viewFunctions.getAgentViews,
    deleteAgentView: _viewFunctions.deleteAgentView,
    /* Residuals */
    getAgentResidualsPermissions: _residualsFunctions.getAgentResidualsPermissions,
    getAssociatedAgentsResidualsData: _residualsFunctions.getAssociatedAgentsResidualsData,
    getResidualDataForAMonth: _residualsFunctions.getResidualDataForAMonth,
    /* MFA Step Up */
    respondToChallenge: _mfaStepUpFunctions.respondToChallenge,
    getLastAvailableMonth: _residualsFunctions.getLastAvailableMonth,
};

function apiInfo() {
    const requestBody = '';

    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/info/about' }).then(handleResponse);     
}

function isDev() {// remove referrences when ready to go live
    return (window.location.hostname === 'localhost' || window.location.hostname.includes('devpartner'));
}

function getUserDataAsync(user) {
    return new Promise((resolve, reject) => {
        user.getUserData((err, data) => {
            if (err) {
                reject(err);
            }
            resolve(data)
        }, { bypassCache: true });
    })
}

function getUserAttributeVerificationCode(accessToken, attributeName) {
    const data = {
        AccessToken: accessToken,
        AttributeName: attributeName,
    };

    return cognitoRequest('GetUserAttributeVerificationCode', data)
};

function verifyUserAttribute(accessToken, attributeName, code) {
    const data = {
        AccessToken: accessToken,
        AttributeName: attributeName,
        Code: code,
    };

    return cognitoRequest('VerifyUserAttribute', data);
};

function cognitoRequest(url, data) {
    return fetch(`${process.env.REACT_APP_COGNITO_URL}${url}`, cognitoOptions(url, data)).then(handleResponse)
}

function cognitoOptions(xAmzTarget, data) {
    return {
        method: 'POST',
        body: JSON.stringify(data),
        headers: cognitoHeaders(xAmzTarget),
    };
};

function cognitoHeaders(xAmzTarget) {
    let headers = new Headers();

    headers.set('Authority', 'cognito-idp.us-west-2.amazonaws.com');
    headers.set('Content-Type', 'application/x-amz-json-1.1');
    headers.set('x-amz-target', `AWSCognitoIdentityProviderService.${xAmzTarget}`);
    headers.set('x-amz-user-agent', 'aws-amplify/0.1.x js');

    return headers;
};

async function login(cognitoToken, username) {
    const loginHeader = new Headers({ 'Authorization': 'Bearer ' + cognitoToken, 'Content-Type': 'application/json' });
    const requestOptions = {
        method: 'POST',
        headers: loginHeader,
        body: JSON.stringify({ username })
    };
    await logSuccessfulLogin(username, cognitoToken);
    
    return fetch(getApiUrl() + '/agent/login', requestOptions)
    .then(handleLoginResponse)
        .then(async auth => {
            // login successful if there's a user in the response
            if (auth) {
                const user = await Auth.currentAuthenticatedUser();
                let isSamlLogin = false;
                if (user && user.attributes && user.attributes.identities
                    && includes(toLower(user.attributes.identities), '"providertype":"saml"')) {
                        isSamlLogin = true;
                    }
                    const { PreferredMfaSetting } = await getUserDataAsync(user);
                    const redirectToSecurity = !isSamlLogin && (!PreferredMfaSetting || PreferredMfaSetting === 'NOMFA');
                    principalService.set({...auth, redirectToSecurity, isSamlLogin });
                    // store user details and basic auth credentials in local storage 
                    // to keep user logged in between page refreshes
                    // localStorage.setItem('user', JSON.stringify(user));
            }

            return auth;
        });
}
function getPoints() {
    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: '', action: '/agent/GetAgentPoints' })
        .then(handleResponse)
        .then(points => { return points; })
}
function getAccountBoardingSandboxKey() {
    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: '', action: '/agent/GetAccountBoardingSandboxKey' })
        .then(handleResponse);
}
function getAgentSettings() {
    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: '', action: '/agent/GetAgentSettings' })
        .then(handleResponse);
}
function getCobrandingSettings() {
    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: '', action: '/agent/GetCobrandingSettings' })
        .then(handleResponse);
}
function saveCobrandingSettings(request) {
    const data = {
        logo: request.logo,
        icon: request.icon,
        fromName: request.fromName,
        colorPrimary: request.colorPrimary,
        colorSidebar: request.colorSidebar,
        partnerDomain: request.partnerDomain
    }
    const formData = _sharedFunctions.objectToFormData(data);
    return _sharedFunctions.callApi({ headerMethod: authHeader, body: formData, action: '/agent/SaveCobrandingSettings' })
        .then(handleResponse);
}
function saveAgentSettings(request) {
    const data = {
        apiWebhookUrl: request.apiWebhookUrl
    }
    if(has(request, 'defaultEappType')){
        data.defaultEappType = request.defaultEappType;
    }
    if(has(request, 'skipChargebackEmail')){
        data.skipChargebackEmail = request.skipChargebackEmail;
    }
    if(has(request, 'skipDebitBlockEmail')){
        data.skipDebitBlockEmail = request.skipDebitBlockEmail;
    }
    if(has(request, 'skipPaymentSiteSetupEmail')){
        data.skipPaymentSiteSetupEmail = request.skipPaymentSiteSetupEmail;
    }
    if (has(request, 'bccSolaOnMerchantEmails')) {
        data.bccSolaOnMerchantEmails = request.bccSolaOnMerchantEmails;
    }

    const formData = _sharedFunctions.objectToFormData(data);
    return _sharedFunctions.callApi({ headerMethod: authHeader, body: formData, action: '/agent/SaveAgentSettings' })
        .then(handleResponse);
}

function dropin(dropinUser) {
    const requestBody = '';
    const action = '/agent/dropinAs/' + dropinUser;

    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: action })
        .then(handleResponse)
        .then(user => {
            // login successful if there's a user in the response
            if (user) {
                principalService.set(user);
                // store user details and basic auth credentials in local storage 
                // to keep user logged in between page refreshes
                //localStorage.setItem('user', JSON.stringify(user));
            }
            return user;
        })
        /*.catch(ex => {
            return Promise.reject( new HttpServiceError({ ex, requestOptions, response: ex.response }));
        })*/;
}

function cancelDropin() {
    const requestBody = '';

    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/agent/cancelDropin' })
        .then(handleResponse)
        .then(user => {
            if (user) {
                principalService.set(user);                
            }
            return user;
        });
}

function logClientError(subject, errorMessage) {
    const requestBody = JSON.stringify({
        subject,
        errorMessage,
    });
    return _sharedFunctions.callApi({ headerMethod: appRequestHeaders, body: requestBody, action: '/agent/LogError' })
        .then(handleResponse);
}

function handleLoginResponse(response) {
    return handleResponse(response, true);
}
