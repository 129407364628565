import principalService from '../services/principalService';
import { Auth } from 'aws-amplify';
import { get } from 'lodash'

const getToken = async (tokenType) => {
    let token = '';
    try {
        let user = await Auth.currentAuthenticatedUser();
        if (user && user.signInUserSession) {
            token = get(user, `signInUserSession.${tokenType}.jwtToken` );
        }
    } catch(ex){ } // not cognito authenticated - if api needs to be authenticated, it will return forbidden result
    return token;
}

export async function authHeader() {
    // return authorization header with basic auth credentials
    let ppuser = principalService.get();
    let token = await getToken("idToken");
    let accessToken = await getToken("accessToken");

    let headers = {};
    if (token) {
        headers['Authorization'] = 'Bearer ' + token;
    }
    if (ppuser && ppuser.token) {
        headers['X-PP-Authorization'] = 'Bearer ' + ppuser.token;
    }
    if (accessToken) {
        headers['X-PP-Access-Token'] = 'Bearer ' + accessToken;
    }
    if (Object.keys(headers).length > 0) {
        return new Headers( headers );
    } else {
        return new Headers ();
    }
}