import React, { Component } from 'react';
import PropTypes from 'prop-types';

class GeneralFilterComponent extends Component {

    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
    }

    filterChange = e => {
        const value = e.target.value ? e.target.value : null;
        this.onFilterChange(value)
    };

    onFilterChange(value) {
        const fieldName = this.props.filter.values.fieldName
        this.props.onFilterChanged({
            id: fieldName,
            values: [{ key: fieldName, value: value }],
            emptyValue: null
        });
    }

    componentDidUpdate = () => {
        if (this.inputRef.current) {
            this.inputRef.current.focus();
        }
    };

    render() {
        const fieldName = this.props.filter.values.fieldName
        const filterVal = this.props.filter.values[fieldName];
        return (
            <div className="pos--rel">
                <input
                    type="text"
                    placeholder={this.props.filter.name}
                    className="input input--number input--med"
                    value={filterVal === null ? '' : filterVal}
                    id={fieldName}
                    onChange={this.filterChange}
                    ref={this.inputRef}
                    autoFocus={true}
                />
            </div>
        );
    }
}

GeneralFilterComponent.propTypes = {
    filter: PropTypes.object.isRequired,
    onFilterChanged: PropTypes.func.isRequired
};

export default GeneralFilterComponent;