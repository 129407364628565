import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
	cloneDeep,
	compact,
	transform,
	isEmpty,
	get,
	each,
	isNaN,
	map,
	filter,
	findIndex,
	trim,
	includes,
	some,
	find,
	replace,
	join,
	split,
	toLower,
	startsWith,
} from 'lodash';
import { focusField, renderIf, scrollTo } from './../../common/utilities';
import { Modal } from '../../common/components/modal';
import GatewayPurchasePlanComponent from './gateway-purchase-plan';
import AddonListComponent from './addon-list';
import FrontendProcessorComponent from './frontend-processor';
import { ClickableErrorMessage } from '../../common/components/error/ClickableErrorMessage';
import EquipmentFileUpload from './EquipmentFileUpload';
import { NumericFormat as NumberFormat } from 'react-number-format';
import gatewaysWithFreeTransactions from '../../common/constants/gatewaysWithFreeTransactions';
import { ToggleContainer, Toggle } from '../../common/components/toggle';

const isExpand = false;

class GatewayListItemComponent extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isExpanded: this.props.isExpanded,
			isAdditionalFeePopupOpen: false,
			additionalFees: [],
		};

		this.baseScrollTo = scrollTo(props.gateway);
		this.baseFocusField = focusField(props.gateway);
	}

	scrollTo = id => {
		if (startsWith(id, 'additionalFees_')) return;
		return this.baseScrollTo(id);
	};

	focusField = id => {
		if (!startsWith(id, 'additionalFees_')) {
			return this.baseFocusField(id);
		}
		this.handleOpenAdditionalFeesPopup(true);
		requestAnimationFrame(() => {
			this.baseFocusField(`fee_${this.props.merchantGateway.additionalFees[id.split('_')[1]].feeId}`);
		});
	};

	gatewaySelect = (e, unselectOther = true) => {
		let gateway;
		if (
			this.props.merchantGateway &&
			this.props.merchantGateway.isSelected &&
			e.target.value === this.props.gateway.equipmentId &&
			!e.target.checked
		) {
			gateway = cloneDeep(this.props.merchantGateway);
			gateway.isSelected = false;
		} else if (
			(!this.props.merchantGateway || !this.props.merchantGateway.isSelected) &&
			e.target.value === this.props.gateway.equipmentId &&
			e.target.checked
		) {
			gateway =
				cloneDeep(this.props.merchantGateway) || this.props.createNewEquipment(this.props.gateway.equipmentId, true);
			gateway.isSelected = true;
			this.toggleSection(true);
		}

		if (gateway) {
			this.props.gatewaySelect(gateway, gateway.isSelected && unselectOther);
		}
	};

	subOptionSelect = equipmentId => e => {
		let gateway = cloneDeep(this.props.merchantGateway);
		let eqp = gateway.subequipment.find(s => s.equipmentId == equipmentId);
		if (!eqp) return;

		if (!eqp.equipmentOptions) {
			eqp.equipmentOptions = {};
		}

		if (e.target.checked) {
			eqp.equipmentOptions[e.target.value] = '1'; // true?
		} else if (Object.keys(eqp.equipmentOptions).includes(e.target.value)) {
			delete eqp.equipmentOptions[e.target.value];
		}

		this.props.onChange(gateway);
	};
	subOptionChange = equipmentId => e => {
		let gateway = cloneDeep(this.props.merchantGateway);
		let eqp = gateway.subequipment.find(s => s.equipmentId == equipmentId);
		if (!eqp) return;
		if (!eqp.equipmentOptions) {
			eqp.equipmentOptions = {};
		}
		let inputName = e.target.name;
		let optionName = inputName.substr(inputName.indexOf('_equipmentOptions__') + '_equipmentOptions__'.length);
		//console.log(optionName);
		eqp.equipmentOptions[optionName] = e.target.value;

		this.props.onChange(gateway);
	};

	optionSelect = e => {
		let gateway = cloneDeep(this.props.merchantGateway);
		if (!gateway.equipmentOptions) {
			gateway.equipmentOptions = {};
		}
		if (e.target.checked) {
			gateway.equipmentOptions[e.target.value] = '1'; // true?
		} else if (Object.keys(gateway.equipmentOptions).includes(e.target.value)) {
			delete gateway.equipmentOptions[e.target.value];
		}
		this.props.onChange(gateway);
	};

	optionSetMoreInfo = (optKey, optValue) => e => {
		let gateway = cloneDeep(this.props.merchantGateway);
		gateway.equipmentOptions[optKey] = `${optValue}|${e.target.value}`;
		this.props.onChange(gateway);
	};

	suboptionSetMoreInfo = (equipmentId, optKey, optValue) => e => {
		let gateway = cloneDeep(this.props.merchantGateway);
		let eqp = gateway.subequipment.find(s => s.equipmentId == equipmentId);
		if (!eqp) return;

		eqp.equipmentOptions[optKey] = `${optValue}|${e.target.value}`;
		this.props.onChange(gateway);
	};

	selectPlan = e => {
		let gateway = cloneDeep(this.props.merchantGateway);
		const purchasePlan = this.props.gateway.purchasePlans.find((p, i) => p.planId == e.target.value);
		if (e.target.checked) {
			gateway.purchasePlanId = e.target.value;
			if (
				purchasePlan?.defaultFreeTransactions &&
				some(gatewaysWithFreeTransactions, plan => plan === toLower(gateway.name))
			) {
				gateway.numberOfFreeTransactions = purchasePlan.defaultFreeTransactions;
			} else {
				gateway.numberOfFreeTransactions = null;
			}
		}
		// add fees
		this.copyFees(purchasePlan, gateway);

		// reselect subequipment - fees can be plan-dependent
		this.props.merchantGateway.subequipment.forEach((sub, i) => {
			this.setGatewaySubequipment(gateway, sub.equipmentId, false);
			this.setGatewaySubequipment(gateway, sub.equipmentId, true);
		});

		this.props.onChange(gateway);
	};

	selectPurchaseType = e => {
		let gateway = cloneDeep(this.props.merchantGateway);
		gateway.purchaseType = e.target.value;

		// add fees
		this.copyFees(
			this.props.gateway.purchasePlans.find((p, i) => p.planId == gateway.purchasePlanId),
			gateway,
			gateway.purchaseType
		);

		this.props.onChange(gateway);
	};

	copyFees(sourceObj, destObj, purchaseType) {
		if (!sourceObj || !destObj) {
			return;
		}
		purchaseType = purchaseType || this.props.merchantGateway.purchaseType;

		destObj.fees = cloneDeep(sourceObj.fees.filter(f => f.purchaseTypes.includes(purchaseType)));
		destObj.additionalFees = cloneDeep(sourceObj.additionalFees.filter(f => f.purchaseTypes.includes(purchaseType)));
		[...destObj.fees, ...destObj.additionalFees].forEach(fee => {
			fee.merchantPrice = fee.retailPrice;
		});
		if (toLower(get(destObj, 'paymentSchedule', '')) === 'billagent') {
			this.setOneTimeFeeMerchantPriceToAgentCost(destObj);
		}
	}

	setIncludedTransactions = e => {
		let gateway = cloneDeep(this.props.merchantGateway);
		gateway.numberOfFreeTransactions = e.target.checked ? parseInt(e.target.value, 10) : 0;
		this.props.onChange(gateway);
	};

	handleSubNote = subequipmentId => note => {
		let gateway = cloneDeep(this.props.merchantGateway);
		let eqp = gateway.subequipment.find((e, i) => e.equipmentId == subequipmentId);
		if (!eqp) return;

		eqp.notes = note;
		this.props.onChange(gateway);
	};

	handleSubFeeChange = (subequipmentId, feeId, updatedValue) => {
		if (!feeId) return; // happens when unselecting a subequipment
		let gateway = cloneDeep(this.props.merchantGateway);
		let eqp = gateway.subequipment.find((e, i) => e.equipmentId == subequipmentId);
		if (!eqp) return;

		let fee = eqp.fees.find((fee, i) => fee.feeId == feeId);
		//console.log('in handleSubFeeChange for plan ' + planId);
		if (!fee) return;

		const isValidValue = /^0*\.0*$/.test(updatedValue.value);
		if (isValidValue || updatedValue.floatValue === undefined || isNaN(updatedValue.floatValue)) {
			fee.merchantPrice = null;
		} else {
			fee.merchantPrice = updatedValue.floatValue;
		}
		this.props.onChange(gateway);
	};

	handleOpenAdditionalFeesPopup = isAdditionalFeePopupOpen => {
		const { additionalFees } = this.props.merchantGateway;

		this.setState({
			isAdditionalFeePopupOpen,
			additionalFees: cloneDeep(additionalFees),
		});
	};

	handleFeeChange = (planId, feeId, updatedValue) => {
		//console.log('fee change for plan ' + planId + ' fee ' + feeId);
		let gateway = cloneDeep(this.props.merchantGateway);
		let fee = gateway.fees.find((fee, i) => fee.feeId == feeId);

		// can be just switching plans, so another plan's fees are triggering a value change, but don't need to update state in this case
		if (!fee) return;

		const isValidValue = /^0*\.0*$/.test(updatedValue.value);
		if (isValidValue || updatedValue.floatValue === undefined || isNaN(updatedValue.floatValue)) {
			fee.merchantPrice = null;
		} else {
			fee.merchantPrice = updatedValue.floatValue;
		}
		this.props.onChange(gateway);
	};

	confirmAdditionalFeeChange = () => {
		const gateway = cloneDeep(this.props.merchantGateway);
		gateway.additionalFees = this.state.additionalFees;
		this.props.onChange(gateway);
		this.handleOpenAdditionalFeesPopup(false);
	};

	handleAdditionalFeeChange = (feeId, updatedValue, isPercentage = false) => {
		const additionalFees = [...this.state.additionalFees];
		const feeIndex = findIndex(this.state.additionalFees, fee => fee.feeId == feeId);

		if (feeIndex === -1) return;
		const fee = {
			...this.state.additionalFees[feeIndex],
		};

		const isValidValue = /^0*\.0*$/.test(updatedValue.value);
		if (isValidValue || updatedValue.floatValue === undefined || isNaN(updatedValue.floatValue)) {
			fee.merchantPrice = null;
		} else {
			fee.merchantPrice = isPercentage ? updatedValue.floatValue / 100 : updatedValue.floatValue;
		}
		additionalFees[feeIndex] = fee;
		this.setState({
			additionalFees,
		});
	};

	handleAdditionalFeeSelectChange = (...feeIds) => {
		const additionalFees = [...this.state.additionalFees];
		each(feeIds, feeId => {
			const feeIndex = findIndex(this.state.additionalFees, fee => fee.feeId == feeId);
			if (feeIndex === -1) return;
			const fee = {
				...this.state.additionalFees[feeIndex],
			};
			fee.isSelected = !fee.isSelected;
			additionalFees[feeIndex] = fee;
		});
		this.setState({
			additionalFees,
		});
	};

	setGatewaySubequipment(gateway, equipmentId, selected) {
		let subequip = this.props.gateway.subequipment.find((e, i) => e.equipmentId == equipmentId);
		gateway.subequipment = gateway.subequipment || [];

		if (!subequip) return;

		if (selected) {
			let sub = this.props.createNewEquipment(
				equipmentId,
				selected,
				true,
				false,
				gateway.equipmentId,
				gateway.purchasePlanId
			);
			if (toLower(gateway.paymentSchedule) === 'billagent') {
				this.setOneTimeFeeMerchantPriceToAgentCost(sub);
			}
			gateway.subequipment.push(sub);
		} else {
			gateway.subequipment = gateway.subequipment.filter((e, i) => e.equipmentId != equipmentId);
		}
	}

	selectSubequipment = e => {
		let equipmentId = e.target.value;
		let selected = e.target.checked;
		let gateway = cloneDeep(this.props.merchantGateway);
		this.setGatewaySubequipment(gateway, equipmentId, selected);
		this.props.onChange(gateway);
	};

	handleTimeChange = time => {
		let gateway = cloneDeep(this.props.merchantGateway);
		gateway.batchoutTime = time;
		this.props.onChange(gateway);
	};
	handleRemoveFile = () => {
		const item = cloneDeep(this.props.merchantGateway);
		item.attachment.file = {};
		this.props.onChange(item);
	};

	onDropFile = (fileType, acceptedFiles) => {
		const item = cloneDeep(this.props.merchantGateway);
		// will really be only a single file, but param type is an array
		item.attachment = {};
		each(acceptedFiles, f => (item.attachment.file = f));
		console.log(item.attachment.file);
		this.props.onChange(item);
	};

	setOneTimeFeeMerchantPriceToAgentCost = equipment => {
		let oneTimeFee = find(equipment.fees, fee => toLower(fee.feeType) === 'onetimefee');
		if (oneTimeFee) {
			oneTimeFee.merchantPrice = oneTimeFee.agentCost;
		}
	};

	onMerchantSubEqpChange = (equipmentId, value, field) => {
		let gateway = cloneDeep(this.props.merchantGateway);
		let eqp = gateway.subequipment.find((e, i) => e.equipmentId == equipmentId);
		if (!eqp) return;

		if (field === 'purchaseType' && value !== 'rollover' && get(eqp, 'originalEquipmentId')) {
			eqp.originalEquipmentId = '';
		}

		eqp[field] = value;
		this.props.onChange(gateway);
	};

	handleChange = e => {
		//console.log(e.target.name);
		let gateway = cloneDeep(this.props.merchantGateway);
		let itemToSet, itemKey;
		let strName = e.target.name;
		if (strName.startsWith(this.props.gateway.name + '_')) strName = strName.substr(this.props.gateway.name.length + 1);

		if (strName.indexOf('_') > 0) {
			let keyList = compact(strName.split('_'));
			itemToSet = keyList.reduce((prev, curItem, idx) => {
				if (idx < keyList.length - 1) {
					return prev[curItem];
				}
				return prev;
			}, gateway);
			itemKey = keyList[keyList.length - 1];
		} else {
			itemToSet = gateway;
			itemKey = strName;
		}
		//console.log('form item was ' + e.target.name);
		//console.log('updating ' + itemKey + ' property of the ' + itemToSet + ' object');
		let newVal = e.target.value;
		if (e.target.type && e.target.type === 'checkbox') newVal = e.target.checked;

		if (toLower(strName) === 'paymentschedule') {
			if (toLower(newVal) === 'billagent') {
				this.setOneTimeFeeMerchantPriceToAgentCost(gateway);
				each(gateway.subequipment, sub => {
					this.setOneTimeFeeMerchantPriceToAgentCost(sub);
				});
			}
		}

		itemToSet[itemKey] = newVal;

		this.props.onChange(gateway);
	};

	toggleSection = (isExpanded = !this.state.isExpanded) => this.setState({ isExpanded });

	get softwareSubequipment() {
		return this.props.gateway.subequipment.filter(e => e.category.toLowerCase() !== 'plugin');
	}
	get pluginSubequipment() {
		return this.props.gateway.subequipment.filter(e => e.category.toLowerCase() === 'plugin');
	}
	get availablePurchasePlans() {
		if (!this.props.merchantGateway) return this.props.gateway.purchasePlans;

		return this.props.gateway.purchasePlans.filter(plan =>
			plan.purchaseTypes.includes(this.props.merchantGateway.purchaseType)
		);
	}
	get equipmentFiles() {
		const { equipmentOptions } = this.props.gateway;
		return transform(equipmentOptions, (acc, item, key) => {
			if (item.dataType !== 'file') return;
			acc[key] = item;
		});
	}

	renderPurchaseTypes = (gateway, merchantGateway) => {
		return (
			<div className="input--check--enable-form">
				{Object.keys(gateway.purchaseTypes).map(pt => {
					let checked = merchantGateway.purchaseType === pt;
					return (
						<div key={pt}>
							<div>
								<input
									type="radio"
									className="input--check"
									name={gateway.name + '_purchaseType'}
									id={gateway.name + '_' + pt + '_pt'}
									onChange={this.selectPurchaseType}
									value={pt}
									checked={merchantGateway.purchaseType === pt}
								/>
								<label htmlFor={gateway.name + '_' + pt + '_pt'}>{gateway.purchaseTypes[pt]}</label>
							</div>
							{pt === 'rollover' && (
								<React.Fragment>
									<div className="gateway__top__separator"></div>
									<div>
										<div className="row">
											<div className="col col-sml-12 col-med-5">
												<label>Original EquipmentID</label>
											</div>
											<div className="col col-sml-12 col-med-7">
												<input
													type="text"
													className="input input--med w--max--300"
													placeholder="EquipmentId"
													name={gateway.name + '_originalEquipmentId'}
													value={merchantGateway.originalEquipmentId}
													onChange={this.handleChange}
													disabled={!checked}
												/>
											</div>
										</div>
									</div>
								</React.Fragment>
							)}
						</div>
					);
				})}
			</div>
		);
	};

	renderPaymentSchedules = (gateway, merchantGateway) => {
		const paymentSchedulesKeys = Object.keys(gateway.paymentSchedules);
		if (paymentSchedulesKeys.length === 1) {
			merchantGateway.paymentSchedule = paymentSchedulesKeys[0];
		}
		return (
			<div id={`${gateway.name}_paymentSchedule_wrapper`} className="input--check--enable-form">
				{paymentSchedulesKeys.map((value, index) => {
					const uniqueKey = `${gateway.name}_paymentSchedule_${index}`;
					return (
						<div key={uniqueKey}>
							<input
								id={uniqueKey}
								type="radio"
								className="input--radio"
								name={`${gateway.name}_paymentSchedule`}
								value={value}
								checked={merchantGateway.paymentSchedule === value}
								onChange={this.handleChange}
							/>
							<label htmlFor={uniqueKey}>{gateway.paymentSchedules[value]}</label>
						</div>
					);
				})}
			</div>
		);
	};

	renderPlatformAndSettlement = (gateway, merchantGateway) => {
		return (
			<FrontendProcessorComponent
				equipment={gateway}
				merchantEquipment={merchantGateway}
				handleChange={this.handleChange}
				handleTimeChange={this.handleTimeChange}
				optionSelect={this.optionSelect}
				optionSetMoreInfo={this.optionSetMoreInfo}
			/>
		);
	};

	renderNotes = (gateway, merchantGateway) => {
		return (
			<Fragment>
				<div className="form__group__header">
					<p className="form__group__label">Issue</p>
				</div>
				<textarea
					id={`${gateway.name}_notes`}
					className="input input--textarea spc--bottom--lrg"
					rows="4"
					placeholder="Notes"
					name={gateway.name + '_notes'}
					value={merchantGateway.notes}
					onChange={this.handleChange}
				></textarea>
			</Fragment>
		);
	};

	splitWords(name) {
		if (name === 'CryptoBucks') {
			return name;
		}
		return join(split(name, /([A-Z][a-z]+)/), ' ');
	}

	renderAdditionalFees() {
		const { isAdditionalFeePopupOpen, additionalFees } = this.state;
		let discountFees, specificTransactionFees;
		if (isAdditionalFeePopupOpen) {
			discountFees = filter(additionalFees, fee => fee.feeType === 'DiscountFee');
			specificTransactionFees = filter(additionalFees, fee => {
				if (fee.feeType !== 'SpecificTransactionFee') return false;
				const baseFeeName = trim(replace(fee.feeName, 'Transaction Fee', ''));
				return !some(discountFees, discountFee => includes(discountFee.feeName, baseFeeName));
			});
		}
		return (
			<Modal
				isOpen={isAdditionalFeePopupOpen}
				onClose={() => this.handleOpenAdditionalFeesPopup(false)}
				className="modal__content"
			>
				<div>
					<div className="modal__header">
						<h4>Alternative Transaction Fees</h4>
					</div>
					<div className="modal__body">
						<table className="table table--primary table--fixed">
							<col width="200" />
							<col />
							<col />
							<thead>
								<tr>
									<th>Transaction Type</th>
									<th>Agent Cost</th>
									<th>Merchant Cost</th>
								</tr>
							</thead>
							<tbody>
								{map(specificTransactionFees, fee => (
									<tr key={fee.feeId}>
										<td>
											<input
												className="input--check"
												onChange={() => this.handleAdditionalFeeSelectChange(fee.feeId)}
												name="isSelected"
												id={`${fee.feeId}.isSelected`}
												checked={fee.isSelected}
												type="checkbox"
											/>
											<label htmlFor={`${fee.feeId}.isSelected`}>
												{this.splitWords(replace(fee.feeName, ' Transaction Fee', ''))}
											</label>
										</td>
										<td>
											<NumberFormat
												value={fee.agentCost}
												prefix={'$'}
												decimalScale={3}
												className="input input--sml"
												name="agent_cost"
												disabled={true}
											/>
										</td>
										<td>
											<NumberFormat
												value={fee.merchantPrice}
												allowNegative={false}
												placeholder={fee.retailPrice}
												thousandSeparator={true}
												prefix={'$'}
												decimalScale={3}
												fixedDecimalScale={false}
												className="input input--sml"
												id={'fee_' + fee.feeId}
												name={'fee_' + fee.feeId}
												disabled={!fee.isSelected}
												onValueChange={values => {
													this.handleAdditionalFeeChange(fee.feeId, values);
												}}
											/>
										</td>
									</tr>
								))}
							</tbody>
						</table>
						<table className="table table--primary table--fixed">
							<colgroup>
								<col width="200" />
								<col />
								<col />
								<col />
							</colgroup>
							<thead>
								<tr>
									<th></th>
									<th>Agent Cost</th>
									<th>Merchant Cost</th>
									<th>Percentage</th>
								</tr>
							</thead>
							<tbody>
								{map(discountFees, fee => {
									const transactionFee = find(
										additionalFees,
										additionalFee =>
											additionalFee.feeType === 'SpecificTransactionFee' &&
											includes(fee.feeName, trim(replace(additionalFee.feeName, 'Transaction Fee', '')))
									);
									return (
										<tr key={fee.feeId}>
											<td>
												<input
													className="input--check"
													onChange={() => this.handleAdditionalFeeSelectChange(fee.feeId, transactionFee?.feeId)}
													name="isSelected"
													id={`${fee.feeId}.isSelected`}
													checked={fee.isSelected}
													type="checkbox"
												/>
												<label htmlFor={`${fee.feeId}.isSelected`}>
													{this.splitWords(
														replace(transactionFee ? transactionFee.feeName : fee.feeName, ' Transaction Fee', '')
													)}
												</label>
											</td>
											<td>
												{transactionFee && (
													<NumberFormat
														value={transactionFee.agentCost}
														prefix={'$'}
														decimalScale={3}
														className="input input--sml"
														name="agent_cost"
														disabled={true}
													/>
												)}
											</td>
											<td>
												{transactionFee && (
													<NumberFormat
														value={transactionFee.merchantPrice}
														allowNegative={false}
														placeholder={transactionFee.retailPrice}
														thousandSeparator={true}
														prefix={'$'}
														decimalScale={3}
														fixedDecimalScale={false}
														className="input input--sml"
														id={'fee_' + transactionFee.feeId}
														name={'fee_' + transactionFee.feeId}
														disabled={!fee.isSelected}
														onValueChange={values => {
															this.handleAdditionalFeeChange(transactionFee.feeId, values);
														}}
													/>
												)}
											</td>
											<td>
												<NumberFormat
													value={fee.merchantPrice * 100}
													allowNegative={false}
													placeholder={`${fee.retailPrice * 100}%`}
													suffix="%"
													decimalScale={2}
													fixedDecimalScale={false}
													className="input input--sml"
													id={'fee_' + fee.feeId}
													name={'fee_' + fee.feeId}
													disabled={!fee.isSelected}
													onValueChange={values => {
														this.handleAdditionalFeeChange(fee.feeId, values, true);
													}}
												/>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
					<div className="modal__footer">
						<button className="btn btn--med btn--primary" onClick={this.confirmAdditionalFeeChange}>
							Confirm
						</button>
					</div>
				</div>
			</Modal>
		);
	}

	render() {
		const { isExpanded } = this.state;
		let { gateway, merchantGateway, downloadPrefilledGatewaySetupForm } = this.props;
		if (!merchantGateway) return null;
		const file = get(merchantGateway, 'attachment.file');
		const errorList = merchantGateway.errors;
		// make sure error object is valid -- otherwise ignore it
		// this is because it seems JSON.stringify doesn't handle this object properly and saves it empty
		let errorListPaths = errorList && errorList.length > 0 && errorList.map(e => e.path);

		return (
			<div className={`card card--equipment ${isExpanded ? 'is-expanded' : ''}`}>
				{this.renderAdditionalFees()}
				<button className="card--equipment__header" onClick={() => this.toggleSection()}>
					<div className="spc--right--lrg">
						<input
							className="input--radio input--radio--no-label"
							type="checkbox"
							name={`${merchantGateway.equipmentId}.isSelected`}
							id={`${merchantGateway.equipmentId}.isSelected`}
							value={merchantGateway.equipmentId}
							checked={merchantGateway.isSelected}
							onChange={this.gatewaySelect}
						/>
						<label htmlFor={`${merchantGateway.equipmentId}.isSelected`}>Label</label>
					</div>
					<div className="flex--grow--1">
						<h5>{gateway.name}</h5>
						{gateway.description && (
							<p className="spc--top--sml spc--bottom--sml type--p3 type--color--text--light">{gateway.description}</p>
						)}
					</div>
					<i className={`icon icon--lrg icon--chevron--${this.state.isExpanded ? 'down' : 'right'} spc--left--sml`}></i>
				</button>
				{merchantGateway.isSelected && errorListPaths ? (
					<ul className="type--validation__wrapper spc--top--sml">
						{' '}
						{errorList.map((elem, i) => {
							let errorPrefix = '';
							let pathArr = elem.path.split('.');
							if (pathArr[0] == 'fees') {
								errorPrefix = merchantGateway.fees[pathArr[1]].feeName;
							} else if (pathArr[0] == 'subequipment') {
								let equipId = merchantGateway.subequipment[pathArr[1]].equipmentId;
								errorPrefix = gateway.subequipment.find(s => s.equipmentId == equipId).name;
							}
							errorPrefix = errorPrefix && '**' + errorPrefix + '**: ';
							return (
								<ClickableErrorMessage
									key={elem.path}
									index={i}
									errorPrefix={errorPrefix}
									elem={elem}
									scrollTo={this.scrollTo}
									focusField={this.focusField}
								/>
							);
						})}
					</ul>
				) : null}
				{renderIf(isExpanded)(
					<div className="spc--top--xxlrg">
						<section className="spc--bottom--xxxlrg">
							<h5 className="spc--bottom--med">
								Purchase Type<span className="type--color--error">*</span>
							</h5>
							{this.renderPurchaseTypes(gateway, merchantGateway)}
						</section>
						<section className="spc--bottom--lrg">
							<h5 className="spc--bottom--med">
								Payment Schedule<span className="type--color--error">*</span>
							</h5>
							{this.renderPaymentSchedules(gateway, merchantGateway)}
						</section>
						<section className="spc--bottom--lrg">
							<h5 className="spc--bottom--lrg">Plans</h5>
							<GatewayPurchasePlanComponent
								gateway={gateway}
								merchantGateway={merchantGateway}
								availablePurchasePlans={this.availablePurchasePlans}
								handleSelectPlan={this.selectPlan}
								setIncludedTransactions={this.setIncludedTransactions}
								handleOpenAdditionalFeesPopup={this.handleOpenAdditionalFeesPopup}
								handleFeeChange={this.handleFeeChange}
							/>
						</section>
						{!isEmpty(this.equipmentFiles) && (
							<div className="spc--bottom--lrg">
								<section>
									<h5 className="spc--bottom--med">Setup Form</h5>
									<div className="spc--bottom--med">
										<div
											className="display--if"
											data-tooltip={
												!merchantGateway.isSelected ? 'Equipment must be Added to Cart before downloading.' : null
											}
										>
											<button
												onClick={() => downloadPrefilledGatewaySetupForm()}
												className="btn btn--med btn--primary spc--bottom--med"
												disabled={!merchantGateway.isSelected}
											>
												Download Setup Form
											</button>
										</div>
										<div className="row">
											<div className="col col-sml-12 col-lrg-6">
												<EquipmentFileUpload
													equipmentFiles={this.equipmentFiles}
													file={file}
													onDropFile={this.onDropFile}
													handleRemoveFile={this.handleRemoveFile}
												/>
											</div>
										</div>
									</div>
								</section>
							</div>
						)}

						<section className="spc--bottom--lrg">
							<AddonListComponent
								gateway={gateway}
								merchantGateway={merchantGateway}
								availablePurchasePlans={this.availablePurchasePlans}
								selectSubequipment={this.selectSubequipment}
								handleSubOptionSelect={this.subOptionSelect}
								handleSubOptionChange={this.subOptionChange}
								handleSubOptionMoreInfo={this.suboptionSetMoreInfo}
								handleSubFeeChange={this.handleSubFeeChange}
								handleSubNote={this.handleSubNote}
								openCloseModal={this.props.openCloseModal}
								handleGatewayOptionSelect={this.optionSelect}
								handleGatewayChange={this.handleChange}
								handleOptionMoreInfo={this.optionSetMoreInfo}
								handleFeeChange={this.handleFeeChange}
								classNames={{}}
								errorListPaths={errorListPaths}
								onMerchantSubEqpChange={this.onMerchantSubEqpChange}
							/>
						</section>
						<ToggleContainer>
							{gateway.frontendProcessors && Object.keys(gateway.frontendProcessors).length > 0 && (
								<Toggle initialToggle={isExpand}>
									{({ isToggled, handleToggle }) => (
											<div className={`card${isToggled ? ' is-expanded' : ''} spc--bottom--xlrg`}>
												<button className="card__header card__header--expandable" onClick={handleToggle}>
													<section id={`${gateway.name}_platform_wrapper`}>
														<div className="flex--primary flex--gap--sml--alt spc--bottom--xsml">
															<h5>Platform & Settlement</h5>
															<i
																className="icon icon--sml icon--regular--info datatooltip--w--230"
																data-tooltip="Automatic batching is generally scheduled at 15 minutes before the cutoff time."
															></i>
														</div>
													</section>
													<i className={`icon icon--lrg icon--chevron--${isToggled ? 'down' : 'right'}--primary`}></i>
												</button>
												{isToggled && this.renderPlatformAndSettlement(gateway, merchantGateway)}
											</div>
									)}
								</Toggle>
							)}
						</ToggleContainer>
						<section>
							<h5 className="spc--bottom--med">Notes</h5>
							{this.renderNotes(gateway, merchantGateway)}
						</section>
					</div>
				)}{' '}
			</div>
		);
	}
}

GatewayListItemComponent.propTypes = {
	merchantGateway: PropTypes.object,
	gateway: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	gatewaySelect: PropTypes.func.isRequired,
};

export default GatewayListItemComponent;
