import React from 'react';

import { LeadDetails } from "../LeadDetails/";

const NewAccount = ({ closeModal, history, addNotification }) => {
    return (
        <LeadDetails
            closeModal={closeModal}
            history={history}
            addNotification={addNotification}
        />
    );
}

export default NewAccount;