import React, { Component } from 'react';
import PropTypes from 'prop-types';

class LastNameSelectionComponent extends Component {

    get lastNameValue() {
        return this.props.filter.values.lastName;
    }

    filterRemoved = e => {
        this.props.onFilterRemoved({
            id: 'lastName',
            emptyValue: null
        });
    };

    render() {
        return (
            <div className="filter__tag">
                <label className="filter__tag__label">Last Name: </label>
                {this.lastNameValue}
                <button onClick={this.filterRemoved} className="btn btn--reset filter__tag__clear"></button>
            </div>
        );
    }
}

LastNameSelectionComponent.propTypes = {
    filter: PropTypes.object.isRequired,
    onFilterRemoved: PropTypes.func.isRequired
};

export default LastNameSelectionComponent;