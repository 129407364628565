import React, {Component} from 'react';
import PropTypes from 'prop-types';

class ActiveFilterComponent extends Component {

	filterChanged = event => {
        this.props.onFilterChanged({
            id: 'active',
            values: [{ key: event.target.value, value: event.target.checked }],
            emptyValue: false,
        });
	};

	render() {
        const { active, inactive } = this.props.filter.values;
		return (
            <div>
                <div className="spc--bottom--sml">
                    <input type="checkbox" name="active" className="input input--check" checked={active} value='active' id="active" onClick={this.filterChanged} />
                    <label htmlFor="active">Active</label>
                </div>
                <div className="spc--bottom--sml">
                    <input type="checkbox" name="inactive" className="input input--check" checked={inactive} value='inactive' id="inactive" onClick={this.filterChanged} />
                    <label htmlFor="inactive">Inactive</label>
                </div>
            </div>
		);
	}
}

ActiveFilterComponent.propTypes = {
	filter: PropTypes.object.isRequired,
	onFilterChanged: PropTypes.func.isRequired
};

export default ActiveFilterComponent;