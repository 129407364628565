import React from 'react';
import { noop, replace, some, isObject } from 'lodash';
import { StateDropdownComponent } from '../state-dropdown';


export default function AddressComponent({address, required, namePrefix, onChange, validateField = noop, showName = false, streetPrefix = "Street", requiredFields = {}, isCanada, disabled})
{
    function isRequired(field) {
        return required || requiredFields[`${replace(namePrefix, '_', '.')}.${field}`] || some(requiredFields, reqField => !isObject(reqField) && reqField === `${replace(namePrefix, '_', '.')}.${field}`);
    }
    return (
        <div className="row grid-align-middle">
            {showName && <div className="col col-sml-12 col-lrg-6">
                <div id={namePrefix + "_name_div"} className={isRequired('name') ? 'required form__field spc--bottom--med' : 'form__field spc--bottom--med'}>
                    <label className="label">Name </label>
                    <input type="text" className={`input input--med ${validateField(namePrefix + "_name")}`} name={namePrefix + "_name"} id={namePrefix + "_name"} value={address.name} disabled={disabled} onChange={onChange}></input>
                </div>
            </div>}
            <div className="col col-sml-12 col-lrg-6">
                <div id={namePrefix + "_streetAddress_div"} className={isRequired('streetAddress') ? 'required form__field spc--bottom--med' : 'form__field spc--bottom--med'}>
                    <label className="label">{streetPrefix} Address </label>
                    <input type="text" className={`input input--med ${validateField(namePrefix + "_streetAddress")}`} name={namePrefix + "_streetAddress"} id={namePrefix + "_streetAddress"} disabled={disabled} value={address.streetAddress} onChange={onChange}></input>
                </div>
            </div>
            <div className="col col-sml-12 col-lrg-6">
                <div id={namePrefix + "_city_div"} className={isRequired('city') ? 'required form__field spc--bottom--med' : 'form__field spc--bottom--med'}>
                    <label className="label">City </label>
                    <input type="text" className={`input input--med ${validateField(namePrefix + "_city")}`} name={namePrefix + "_city"} id={namePrefix + "_city"} disabled={disabled} value={address.city} onChange={onChange}></input>
                </div>
            </div>
            <div className="col col-sml-12 col-lrg-6">
                <div id={namePrefix + "_state_div"} className={isRequired('state') ? 'required form__field spc--bottom--med' : 'form__field spc--bottom--med'}>
                    <label className="label">{isCanada ? 'Province' : 'State'} </label>
                    <StateDropdownComponent className={`input input--med input--select ${validateField(namePrefix + "_state")}`} id={namePrefix + "_state"} selectedState={address.state} disabled={disabled} onChange={onChange} isCanada={isCanada}/>
                </div>
            </div>
            <div className="col col-sml-12 col-lrg-6">
                <div id={namePrefix + "_zip_div"} className={isRequired('zip') ? 'required form__field spc--bottom--med' : 'form__field spc--bottom--med'}>
                    <label className="label">{isCanada ? 'Postal Code' : 'ZIP'} </label>
                    <input type="text" className={`input input--med ${validateField(namePrefix + "_zip")}`} name={namePrefix + "_zip"} id={namePrefix + "_zip"} value={address.zip} disabled={disabled} onChange={onChange}></input>
                </div>
            </div>
        </div>
        );    
}
