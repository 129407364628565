import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { BaseGridActionsComponent } from '../../../common/formatters';
import { appService } from '../../../services';
import { modalNames } from '../../../common/components/modal-wrapper';

class EAppGridActionsComponent extends BaseGridActionsComponent {
    static instances = [];

	constructor(props) {
        super(props, EAppGridActionsComponent.instances);        
        this.menuItems = [
            {
                isVisible: () => !this.props.dependentValues.isHidden, 
                title: 'Archive EApp',
                onClickHandler: this.archiveLead, 
                iconClassName: '', 
                href: ''
            },
            {
                isVisible: () => this.props.dependentValues.isHidden, 
                title: 'Unarchive EApp',
                onClickHandler: this.unarchiveLead, 
                iconClassName: '', 
                href: ''
            },
            {
                isVisible: () => this.props.row.processorName, 
                title: 'Clone EApp',
                onClickHandler: this.cloneEapp, 
                iconClassName: '', 
                href: ''
            },
        ];
	}

    archiveLead = async () => {
        const { dependentValues: { showLoader, refreshGridData, appId } } = this.props;
        try {
            showLoader(true);
            this.closeDisplayMenu();
            await appService.hideMerchant(appId)
            refreshGridData();
        }
        catch (error) {
            console.log('archive eapp error', error);
            this.setState({ successMessage: '', errorMessage: 'An error occurred: ' + error, isNewError: true });
        }
        finally{
            showLoader(false);
        }
    }
    
    unarchiveLead = async () => {
        const { dependentValues: { showLoader, refreshGridData, appId } } = this.props;
        try {
            showLoader(true);
            this.closeDisplayMenu();
            await appService.unhideMerchant(appId)
            refreshGridData();
        }
        catch (error) {
            console.log('unarchive eapp error', error);
            this.setState({ successMessage: '', errorMessage: 'An error occurred: ' + error, isNewError: true });
        }
        finally{
            showLoader(false);
        }
    }

    cloneEapp = () => {
        const { dependentValues: { openCloseModal }, row } = this.props;
        this.closeDisplayMenu();
        openCloseModal({
            name: modalNames.cloneEApp,
            data: {
                data: row,
                appType: 'eApp'
            },
        })
    };
}

EAppGridActionsComponent.propTypes = {
    dependentValues: PropTypes.shape({
        appId: PropTypes.number,
    }),
    isScrolling: PropTypes.bool,
};

export default withRouter(EAppGridActionsComponent);
