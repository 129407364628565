import { toLower } from 'lodash';
import { decode } from 'jsonwebtoken';

import { appService } from '../../services';
import { withLoader } from "../../common/components";
import { defaultInvalidDates } from '../../common/utilities';
import { MpaLoader } from './MpaLoader';
import { CanadaMerchantMPA } from './CanadaMPA';
import { USMerchantMPA } from './USMPA';


class MerchantMpaLoader extends MpaLoader {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            isExpired: false,
        };
    }
    get componentProps() {
        return {
            ...super.componentProps,
            isExpired: this.state.isExpired,
        };
    }
    loadMPAFromService = () => {
        this.props.showLoader(true);
        const token = this.props.location.search.substr(1);
        return appService.getEAppForMerchant(token)
            .then(mpa => {
                this.props.showLoader(false);
                if (mpa.signatureDoc.status === 'Signed') {
                    this.setState({ errorMessage: 'Signature documents have already been submitted successfully' });
                } else {
                    this.setState({ mpa: defaultInvalidDates(mpa, '') });
                }
            }).catch(err => {
                console.log('getEAppForMerchant error', err);
                this.props.showLoader(false);
                let errorMessage = 'An error occurred: ' + err;
                if (toLower(err) === "unauthorized" || err == 401) {
                    if (decode(token, { complete: true }).payload.actort) {
                        this.setState({
                            isExpired: true
                        });
                        return;
                    }
                    errorMessage = "The link you followed has expired. To complete your application, please reach out to your agent to request a new eApp link."
                }
                this.setState({ errorMessage });
            });
    }

    getComponent = isCanadian => isCanadian ? CanadaMerchantMPA : USMerchantMPA;

    renderHeader() {
        return null;
    }
}

export default withLoader(MerchantMpaLoader);
