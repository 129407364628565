import React, { Component } from 'react';
import { map } from 'lodash';

class LeadStatusFilterComponent extends Component {
    constructor(props) {
        super(props);
    }

    filterChanged = (event) => {
        let props = this.props;

        let values = props.filter.values;
        
        const key = event.target.name;
        const checked = event.target.checked;

        let valueArr = map(values, (v, k) => {
            return { key: k, value: v };
        });
        
        const objIndex = valueArr.findIndex(o => o.key == key);
        
        valueArr[objIndex].value = checked;
        
        this.props.onFilterChanged({
            id: 'leadStatus',
            values: valueArr,
            emptyValue: false
        });        
    }

    render() {
        const { filter } = this.props;
        let values = this.props.filter.values;
        return (
            <div>
                {
                    map(values, (v, k) => {
                        return (
                            <div key={k} className="spc--bottom--sml">
                                <input type="checkbox" name={k} className="input input--check" checked={v} value={k} id={k} onChange={this.filterChanged} />
                                <label htmlFor={k}>{k}</label>
                            </div>
                        );   
                    })
                    
                }
                {/*
                 <div className="spc--bottom--sml">
                    <input type="checkbox" name="Reviewed" className="input input--check" checked={filter.values.Reviewed} value={'Reviewed'} id="reviewed" onChange={this.filterChanged}
                    />
                    <label htmlFor="reviewed">Reviewed</label>
                </div>
                <div className="spc--bottom--sml">
                    <input type="checkbox" name="NoReview" className="input input--check" checked={filter.values.NoReview} value={'NoReview'} id="NoReview" onChange={this.filterChanged}
                    />
                    <label htmlFor="NoReview">NoReview</label>
                </div>
                <div className="spc--bottom--sml">
                    <input type="checkbox" name="Pending" className="input input--check" checked={filter.values.Pending} value={'Pending'} id="pending" onChange={this.filterChanged}
                    />
                    <label htmlFor="pending">Pending</label>
                </div>
                <div className="spc--bottom--sml">
                    <input type="checkbox" name="Incomplete" className="input input--check" checked={filter.values.Incomplete} value={'Incomplete'} id="incomplete" onChange={this.filterChanged}
                    />
                    <label htmlFor="incomplete">Incomplete</label>
                </div>
                <div>
                    <input type="checkbox" name="Unknown" className="input input--check" checked={filter.values.Unknown} value={'Unknown'} id="unknown" onChange={this.filterChanged} />
                    <label htmlFor="unknown">Unknown</label>
                </div> 
                */ }
            </div>
            );
    }
}

export default LeadStatusFilterComponent;