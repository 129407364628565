import React from 'react';
import PropTypes from 'prop-types';
import { compact, get, map, some, toLower } from 'lodash';
import { NumericFormat as NumberFormat } from 'react-number-format';
import gatewaysWithFreeTransactions from '../../common/constants/gatewaysWithFreeTransactions';

const GatewayPurchasePlanComponent = (props) => {
    const { gateway, merchantGateway, availablePurchasePlans, handleSelectPlan, setIncludedTransactions, handleFeeChange, handleOpenAdditionalFeesPopup } = props;    

    return (
        <div className="table--gateway--primary__wrapper">
            <table className="table--gateway table--gateway--primary">
                <thead>
                    <tr>
                        <th className="sticky" colSpan={2}>Product / Service</th>
                        {
                            availablePurchasePlans.map((plan, idx) => {
                                return (
                                    <th colSpan={2} className={`table--gateway__fields sticky centered${merchantGateway.purchasePlanId == plan.planId ? ' is-selected' : ''}`} key={idx}>
                                        <input className="input--radio" type="radio" name={gateway.name + "_selectedPlan"} id={gateway.name + "_" + plan.planId + "_opt"}
                                            value={plan.planId} checked={merchantGateway.purchasePlanId == plan.planId} onChange={handleSelectPlan} />
                                        <label htmlFor={gateway.name + "_" + plan.planId + "_opt"}>{plan.planName}</label>
                                    </th>
                                );
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th className="table--gateway__group__wrapper" colSpan={2}>
                            <div className="table--gateway__group">
                                <div className="title--tertiary">{gateway.name}</div>
                            </div>
                        </th>
                        {map(availablePurchasePlans, (plan) => (
                            <th colSpan={2} className={`table--gateway__group__wrapper${merchantGateway.purchasePlanId == plan.planId ? ' is-selected' : ''}`} key={plan.planId}>
                                <div className="table--gateway__group">
                                    <span className="title">Agent Cost</span>
                                    <span className="title">Merchant Cost</span>
                                </div>
                            </th>
                        ))}
                    </tr>
                    {renderPlanFees(gateway, merchantGateway, availablePurchasePlans, handleFeeChange, handleOpenAdditionalFeesPopup)}
                    {renderIncludedTransactions(gateway, merchantGateway, availablePurchasePlans, setIncludedTransactions)}
                    {renderIncludedFeatures(availablePurchasePlans, merchantGateway)}
                </tbody>
            </table>
        </div>
    );    
}

const renderPlanFees = (gateway, merchantGateway, availablePurchasePlans, handleFeeChange, handleOpenAdditionalFeesPopup) => {
    const mergedFees = [].concat.apply([], availablePurchasePlans.map((p, i) => p.fees));
    const distinctFeeNames = [...new Set(mergedFees.map(item => item.feeName))];
    const currentPlan = availablePurchasePlans.find(plan => plan.planId == merchantGateway.purchasePlanId);

    return (
        <React.Fragment>
            {
                distinctFeeNames.map((feeName, idx) => {
                    if (mergedFees.find((fee) => (fee.feeName === feeName && Object.keys(fee.dependencySettings || {}).length > 0))) {
                        // this fee type has conditions - take care of it later
                        return null;
                    }
                    return (
                        <tr key={idx}>
                            <th colSpan={2}>
                                <div className="flex--primary flex--nowrap">
                                    <p className="display--ib type--nowrap spc--right--sml">{feeName}</p> 
                                    {currentPlan?.additionalFees?.length > 0 && feeName === 'Transaction Fee' ? (<button className="btn btn--anchor type--wgt--bold" onClick={() => handleOpenAdditionalFeesPopup(true)}><i className="icon icon--nano icon--add--primary spc--right--nano"></i>Markup Transaction Fees</button>) : null}
                                </div>
                            </th>
                            {availablePurchasePlans.map((plan, i) => {
                                let planFee = plan.fees.find((f) => f.feeName === feeName && f.purchaseTypes.includes(merchantGateway.purchaseType));
                                if (planFee) {
                                    const minFee = get(planFee, 'minFee')
                                    const maxFee = get(planFee, 'maxFee')
                                    const isMerchantPriceDisabled = (minFee > 0 && maxFee > 0) && minFee === maxFee;
                                    const isOneTimeFeeAndDisabled = merchantGateway.purchasePlanId == plan.planId && toLower(merchantGateway.paymentSchedule) === 'billagent' && toLower(planFee.feeType) === 'onetimefee';
                                    let merchantFee = merchantGateway.fees.find((f, i) => f.feeId == planFee.feeId);
                                    let merchantPrice = merchantFee && Object.keys(merchantFee).includes('merchantPrice') ? merchantFee.merchantPrice : planFee.retailPrice;
                                    const agentCost = merchantFee && Object.keys(merchantFee).includes('agentCost') ? merchantFee.agentCost : planFee.agentCost;
                                    const originalPrice = `$${planFee.retailPrice}`;
                                    let tooltip = isOneTimeFeeAndDisabled ? `Merchant Cost is not editable when Payment Schedule is Bill Agent.` : null;
                                    return (
                                        <td className={merchantGateway.purchasePlanId == plan.planId ? ' is-selected' : ''} colSpan={2} key={i}>
                                            <div className="table--gateway__fee">
                                                <div className="item">
                                                    <NumberFormat
                                                        value={agentCost}
                                                        prefix={'$'}
                                                        decimalScale={3}
                                                        className="input input--med type--center"
                                                        name="agent_cost"
                                                        disabled={true}
                                                    />
                                                </div>
                                                <div className={`${isMerchantPriceDisabled ? "flex--primary flex--nowrap" : ""} item`}>
                                                    <div
                                                        className="datatooltip--250 datatooltip--left"
                                                        data-tooltip={tooltip}
                                                    >
                                                        <NumberFormat
                                                            id={"fee_" + planFee.feeId}
                                                            value={merchantPrice}
                                                            allowNegative={false}
                                                            placeholder={originalPrice}
                                                            thousandSeparator={true} prefix={'$'}
                                                            decimalScale={3}
                                                            fixedDecimalScale={false}
                                                            className="input input--med type--center"
                                                            name={"fee_" + planFee.feeId}
                                                            disabled={(merchantGateway.purchasePlanId != plan.planId) || isMerchantPriceDisabled || isOneTimeFeeAndDisabled}
                                                            onValueChange={(values) => { handleFeeChange(planFee.planId, planFee.feeId, values) }}
                                                        />
                                                    </div>
                                                    {isMerchantPriceDisabled && !isOneTimeFeeAndDisabled && <i className="icon icon--tiny icon--info datatooltip--200 spc--left--tny flex--shrink--0" data-tooltip={`Merchant cost must equal ${minFee}`}/>}
                                                </div>
                                            </div>
                                        </td>
                                    );
                                }
                                else {
                                    return (
                                        <td className={merchantGateway.purchasePlanId == plan.planId ? ' is-selected' : ''} colSpan={2} key={i}>
                                            <div className="table--gateway__fee">
                                                <div className="item">
                                                    <NumberFormat
                                                        value={0}
                                                        prefix={'$'}
                                                        decimalScale={3}
                                                        className="input input--med type--center"
                                                        name="agent_cost"
                                                        disabled={true}
                                                    />
                                                </div>
                                                <div className="item">
                                                    <NumberFormat
                                                        value={0}
                                                        prefix={'$'}
                                                        decimalScale={3}
                                                        className="input input--med type--center"
                                                        name="fee_disabled"
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                    );
                                }
                            })}
                        </tr>
                    );
                })
            }
        </React.Fragment>
    );
}

const renderIncludedTransactions = (gateway, merchantGateway, availablePurchasePlans, setIncludedTransactions) => {
    const planWithFreeTransactions = availablePurchasePlans.find(plan => plan.defaultFreeTransactions > 0 && some(gatewaysWithFreeTransactions, name => name === toLower(gateway.name)));
    if (!planWithFreeTransactions) return null;
    const transNumber = planWithFreeTransactions.defaultFreeTransactions;
    return (
        <tr>
            <th colSpan={2}>Free {transNumber} Transactions</th>
            {
                availablePurchasePlans.map(plan => {
                    const isSelected = parseInt(merchantGateway.purchasePlanId, 10) === plan.planId;
                    if (!plan.defaultFreeTransactions) {
                        return (
                            <td className={isSelected ? 'is-selected' : ''} colSpan={2} key={plan.planId} />
                        )
                    }
                    const name = `${gateway.name}.${plan.planId}.numberOfFreeTransactions`;
                    return (
                        <td className={`type--center ${isSelected ? 'is-selected' : ''}`} colSpan={2} key={plan.planId}>
                            <input className="input--toggle"
                                type="checkbox"
                                id={name}
                                name={name}
                                value={transNumber}
                                checked={isSelected && merchantGateway.numberOfFreeTransactions > 0}
                                onChange={setIncludedTransactions}
                                disabled={!isSelected}
                            />
                            <label className="label" htmlFor={name}></label>
                        </td>
                    );
                })
            }
        </tr>
    );

}

const renderIncludedFeatures = (availablePurchasePlans, merchantGateway) => {
    const mergedFeatures = compact([].concat.apply([], availablePurchasePlans.map((p, i) => get(p, 'includedFeatures.featureDescriptions'))));
    const distinctFeatures = [...new Set(mergedFeatures)];
    if (distinctFeatures.length === 0)
        return null;

    return (
        <React.Fragment>
            <tr>
                <th className="table--gateway__group fullwidth" colSpan={2 * (availablePurchasePlans.length + 1)}><div className="title--tertiary">Features</div></th>
            </tr>
            {
                distinctFeatures.map((featureName, idx) => {
                    return (
                        <tr key={idx} className="type--center">
                            <th colSpan={2}>{featureName}</th>
                            {availablePurchasePlans.map((plan, i) => {
                                const planFeature = plan.includedFeatures && plan.includedFeatures.featureDescriptions && plan.includedFeatures.featureDescriptions.find((f) => f === featureName);
                                const content = planFeature ? <div className="gateway__icon"></div> : '-';
                                return (
                                    <td className={merchantGateway.purchasePlanId == plan.planId ? ' is-selected' : ''} colSpan={2} key={i}>{content}</td>
                                )
                            })}
                        </tr>
                    );
                })
            }
        </React.Fragment>

    );
}

GatewayPurchasePlanComponent.propTypes = {
    handleSelectPlan: PropTypes.func.isRequired,
    setIncludedTransactions: PropTypes.func.isRequired,
    handleFeeChange: PropTypes.func.isRequired,
    gateway: PropTypes.object.isRequired,
    merchantGateway: PropTypes.object,
    availablePurchasePlans: PropTypes.array.isRequired
}

export default GatewayPurchasePlanComponent;