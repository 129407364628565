import React, { Component, Fragment } from 'react';
import { map, isEmpty, some, noop, startsWith } from 'lodash';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { ModalWrapper, modalNames } from './../../common/components/modal-wrapper';
import principalService from '../../services/principalService';
import { UserAccountPanel } from './../../common/components/user-account-panel';
import sidebarTabs from "./sidebarTabs"

class SidebarComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            principal: null,
            modal: {
                name: modalNames.none,
                data: null,
            },
        };
    }

    componentWillMount() {
        const data = principalService.get();
        this.setState({
            principal: data
        });
    }

    openCloseModal = (modalObj, ...rest) => {
        let state = {
            modal: modalObj
        };
        this.setState(state);
    };

    getRefreshLink = link => `${link}${startsWith(this.props.history.location.pathname, link) ? '?refresh=true' : ''}`;
    expandedClass = className => (!this.props.isExpanded ? `${className} is-collapsed` : className);
    onPath = (path) => (_, { pathname }) => {
        return startsWith(pathname, path);
    } 

    CreateNavLinkTab = (tab) =>{
        return (                            
            <li className="nav__item">
                <NavLink className={this.expandedClass("nav__link")} activeClassName="is-active" isActive={this.onPath(tab.link)} to={this.getRefreshLink(tab.link)} exact>
                    <i className={`icon icon--xsml nav__icon nav__icon--${tab.icon}`}></i>
                    <span className="nav__link__text">{tab.title}</span>
                </NavLink>
            </li>)
    }

    CreateOnClickHandlerTab = (tab) =>{
        if(tab.title === "New Account"){
            tab.onClick = () =>
            this.props.history.push({
                pathname: '/leads',
                openNewAccountPopup: true
            });
        }
        return (                            
            <li className="nav__item">
                <div onClick={tab.onClick} className={this.expandedClass("nav__link")} to="/" >
                    <i className={`icon icon--xsml nav__icon nav__icon--${tab.icon}`}></i>
                    <span className="nav__link__text">{tab.title}</span>
                </div>
            </li>)
    }
 
    CreateOutsideLinkTab = (tab) =>{
        return (                            
            <li className="nav__item">
                <a className={this.expandedClass("nav__link")} href={tab.link} target="_blank">
                    <i className={`icon icon--xsml nav__icon nav__icon--${tab.icon}`}></i>
                    <span className="nav__link__text">{tab.title}</span>
                </a>
            </li>)
    }

    resolveTabRenderMethod = (type) =>{
        switch (type) {
            case "NavLink":
                return this.CreateNavLinkTab;
            case "OnClickHandler":
                return this.CreateOnClickHandlerTab;
            case "OutsideLink":
                return this.CreateOutsideLinkTab;
            default:
                return noop;
        }

    }

    render = () => {
        const { state: { principal }, expandedClass } = this;

        return (
            <Fragment>
                <ModalWrapper modal={this.state.modal} onModalClose={this.openCloseModal} />
                <div className="sidebar">
                    <div className={expandedClass("sidebar__header")}>
                        <a href="/" className="sidebar__header__image"></a>
                        <span className="sidebar__header__title">
                            Partner Portal
                        </span>
                    </div>
                    <UserAccountPanel />
                    <ul className="nav">
                        {map(sidebarTabs, (tab,i) =>{
                            let principalHasTabAccess = 
                                (isEmpty(tab.rolesWithAccess) || some(tab.rolesWithAccess, role => principal[`${role}`])) &&
                                (isEmpty(tab.rolesWithoutAccess) || !some(tab.rolesWithoutAccess, role => principal[`${role}`]))
                            if(!principalHasTabAccess){
                                return null;
                            }
                            let renderMethod = this.resolveTabRenderMethod(tab.type);
                            return <Fragment key={i}>{renderMethod(tab)}</Fragment>;
                        })}
                    </ul>
                </div>
            </Fragment>
        );
    };
}

SidebarComponent.prototypes = {
    isExpanded: PropTypes.bool.isRequired,
    addNotification: PropTypes.func.isRequired,
};

export default SidebarComponent;