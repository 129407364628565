const urlRegex = /^(https?:\/\/|www\.)([\w-]+\.)+\w{2,4}(:\d+)?(\/[~\w#%./-]+)?(\?[\w% /&[\];=-]+)?\b$/;
const apiWebhookUrl = (value) => {
    return value ? urlRegex.test(value) : true;
}
export const partnerSettingsTemplate = {
    apiWebhookUrl: {
        type: String,
        use: { apiWebhookUrl },
        message: {
            apiWebhookUrl: '[**Webhook URL**](javascript:void) is invalid',
        },
    },
};