import { forEach, findIndex, startsWith, split } from 'lodash';
import {
    StringFilterComponent as StringFilter,
    MultiselectCardknoxFilterComponent as MultiselectCardknoxFilter
} from "../../../common/column-filters";
import { EAppStatusComponent } from "../formatters";
import createGridColumn from '../../../common/components/grid/createGridColumn';

import { LeadColumns } from '../../LeadGrid/column-filter/leadColumns';
const leadColumnsReplacement = [
    {
        toReplaceKey: 'PLACE_AT_INDEX_5',
        replacement: createGridColumn("processorName", "Processor", 150, {
            getRowMetaData: (row) => row,
            filterRenderer: MultiselectCardknoxFilter,
        }),
    },
    {
        toReplaceKey: 'processor',
        replacement: null,
    },
    {
        toReplaceKey: 'email',
        replacement: createGridColumn("businessEmail", "Email", 150, {
            filterRenderer: StringFilter
        }),
    },
    {
        toReplaceKey: 'leadStatus',
        replacement: createGridColumn("statusDisplayName", "Status", 150, {
            formatter: EAppStatusComponent,
            getRowMetaData: (row) => row,
            filterRenderer: MultiselectCardknoxFilter,
        }),
    },
    {
        toReplaceKey: 'market',
        replacement: createGridColumn("businessCountry", "Business Country", 150, {
            filterRenderer: StringFilter,
        })
    },
    {
        toReplaceKey: 'lastName',
        replacement: createGridColumn("ownerName", "Owner Name", 150, {
            filterRenderer: StringFilter,
        })
    },
    {
        toReplaceKey: 'firstName',
        replacement: null,
    },
    {
        toReplaceKey: 'reviewStatus',
        replacement: null,
    },
    {
        toReplaceKey: 'label',
        replacement: null,
    },
    {
        toReplaceKey: 'title',
        replacement: null,
    },
];

const leadColumnsCopy = [...LeadColumns];

forEach(leadColumnsReplacement, ({toReplaceKey, replacement}) => {
    if(startsWith(toReplaceKey, 'PLACE_AT_INDEX_')){
        const newIndex = parseInt(split(toReplaceKey, 'PLACE_AT_INDEX_')[1])
        leadColumnsCopy.splice(newIndex, 0, replacement);
        return;
    }
    let index = findIndex(leadColumnsCopy, ({key}) => key === toReplaceKey)
    if(index !== -1){
        if(replacement){
            leadColumnsCopy[index] = replacement;
        }else{
            leadColumnsCopy.splice(index, 1);
        }
    }
})
export const EAppColumns = leadColumnsCopy;
