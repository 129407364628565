import React, { Component } from "react";
import { each, cloneDeep, isEmpty, filter, toLower } from "lodash";
import { array, func, object } from "prop-types";

import VarListItemComponent from "./var-list-item";

class VarListComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errorMessage: null,
        };
    };

    componentWillMount() {
        let { varList, merchantEquipment } = this.props;
        let newVarList = filter(merchantEquipment, ({ category }) => toLower(category) === 'var');
        each(varList, (item) => {
            let merchantVar = merchantEquipment.find(
                (e, i) => e.equipmentId == item.equipmentId
            );
            if (!merchantVar) {
                let newVar = this.props.createNewEquipment(
                    item.equipmentId,
                    false
                );
                newVarList.push(newVar);
            }
        });
        this.props.onListChange(newVarList);
    }

    componentDidUpdate() {
        if (isEmpty(this.props.merchantEquipment)) {
            this.componentWillMount();
        }
    }

    VarSelect = (varItem, unselectOther) => {
        if (unselectOther) {
            let newVarList = cloneDeep(this.props.merchantEquipment);
            each(newVarList, (item) => {
                if (
                    item.category == "Var" &&
                    item.equipmentId != varItem.equipmentId
                ) {
                    item.isSelected = false;
                }
            });
            this.props.onListChange(newVarList);
        }
        this.props.onChange(varItem);
    };

    onChange = (varItem, selectVar = true) => {
        if (selectVar) {
            varItem.isSelected = true;
        }
        this.props.onChange(varItem);
    };

    render() {
        const { varList, merchantEquipment, selectedEquipmentId, downloadPrefilledVarSheet } = this.props;
        const { errorMessage } = this.state;

        return (
            <div>
                <div>
                    <h2 className="spc--bottom--sml">Submit a VAR request</h2>
                    <div className="message message--default spc--bottom--sml">
                        <p>
                            A VAR Request is for any Gateway or Software that requires coding from the bank/processor, which Deployments will obtain
                        </p>
                        <p>
                            from the bank/processor, and provide to the Agent (who is supposed to provide to the merchant).
                        </p>
                    </div>
                </div>
                {errorMessage && (
                    <div className="spc--top--sml spc--bottom--sml note note--warning">
                        {errorMessage}
                    </div>
                )}
                {isEmpty(varList) ? (
                    <div className="emptystate">
                        <div className="emptystate__title">
                                0 Results
                        </div>
                        <p className="emptystate__message">
                            No VAR found for this merchant
                        </p>
                    </div>
                ) : varList.map((varItem, idx) => {

                    let merchantVar = merchantEquipment.find(
                        (e) => e.equipmentId == varItem.equipmentId
                    );

                    return (
                        <div
                            key={idx}
                            className="card--shaded card--sml spc--bottom--med"
                        >
                            <VarListItemComponent
                                varItem={varItem}
                                isExpanded={
                                    varList.length == 1 ||
                                    selectedEquipmentId == varItem.equipmentId
                                }
                                merchantVar={merchantVar}
                                onChange={this.onChange}
                                createNewEquipment={
                                    this.props.createNewEquipment
                                }
                                openCloseModal={this.props.openCloseModal}
                                varSelect={this.VarSelect}
                                downloadPrefilledVarSheet={downloadPrefilledVarSheet}
                            />
                        </div>
                    );
                })}
            </div>
        );
    }
}

VarListComponent.propTypes = {
    merchantVarList: array,
    onChange: func,
    downloadPrefilledVarSheet: func.isRequired,
};

export default VarListComponent;
